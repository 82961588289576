import React, { memo } from 'react';
import { Handle } from 'reactflow';
import { Tooltip } from '@mui/material';
import datatypeIcon from '../../../../assets/images/datatype.png';
import concatIcon from '../../../../assets/images/concat.png';
import datetimeIcon from '../../../../assets/images/datetime.png';
import handleNullIcon from '../../../../assets/images/handlenull.png';
import mathTransformationIcon from '../../../../assets/images/math_transformation.png';
import oneHotEncoderIcon from '../../../../assets/images/one_hot_encoder.png';
import labelEncoderIcon from '../../../../assets/images/label_encoder.png';
import resampleIcon from '../../../../assets/images/resample.png';
import scalerIcon from '../../../../assets/images/scaler.png';
import dropIcon from '../../../../assets/images/drop.png';
import outlierIcon from '../../../../assets/images/outlier.png';
import imbalancedDataIcon from '../../../../assets/images/imbalance.png';
import binningIcon from '../../../../assets/images/binning.png';

const DLTransformNode = ({ data, isConnectable }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip
          title={
            <>
              <div style={{ whiteSpace: 'pre-line' }}>
                Incoming edges: {data.Incoming !== undefined ? data.Incoming : 0}
              </div>
              <div style={{ whiteSpace: 'pre-line' }}>
                Outgoing edges: {data.Outgoing !== undefined ? data.Outgoing : 0}
              </div>
              <div style={{ whiteSpace: 'pre-line' }}>{data.label}</div>
            </>
          }
          placement="top"
        >
          <div
            style={{
              width: '150px',
              border: '2px solid black',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fcefb4',
            }}
          >
            <Handle type="source" position="right" isConnectable={isConnectable} />

            <Handle
              type="target"
              position="left"
              onConnect={(params) => console.log('', params)}
              isConnectable={isConnectable}
            />
            {data.mode === 'Datatype Conversion' && (
              <>
                <img src={datatypeIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Concat' && (
              <>
                <img src={concatIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Date Time Feature' && (
              <>
                <img src={datetimeIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Handle Null' && (
              <>
                <img
                  src={handleNullIcon}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Math Transformation' && (
              <>
                <img
                  src={mathTransformationIcon}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'OneHot Encoder' && (
              <>
                <img
                  src={oneHotEncoderIcon}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Label Encoder' && (
              <>
                <img
                  src={labelEncoderIcon}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Drop' && (
              <>
                <img src={dropIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Outlier' && (
              <>
                <img src={outlierIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Imbalanced Data' && (
              <>
                <img
                  src={imbalancedDataIcon}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Binning' && (
              <>
                <img src={binningIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Scaler' && (
              <>
                <img src={scalerIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Resample' && (
              <>
                <img src={resampleIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default memo(DLTransformNode);
