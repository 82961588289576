import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import './domainTable.css';
import { DateConverter } from '../../utils/DateConverter';
import RedChip from '../../reusable-component/RedChip';
import GreenChip from '../../reusable-component/GreenChip';
import YellowChip from '../../reusable-component/YellowChip';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import BasicMenu from '../../reusable-component/BasicMenu';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { AuthContext } from '../../contexts/AuthProvider';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
    textAlign: 'center',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function LLMTable({ tableData, currentPage, setPage }) {
  const { LLMId, setLLMId, LLMName, setLLMName } = useContext(AuthContext);
  //   const [LLMId, setLLMId] = useState('');

  const [currentSelectedLLMId, setCurrentSelectedLLMId] = useState(LLMId);
  // const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLLMId(sessionStorage.getItem('LLMId'));
    setCurrentSelectedLLMId(sessionStorage.getItem('LLMId'));
    setLLMName(sessionStorage.getItem('LLMName'));
    console.log(sessionStorage.getItem('LLMName'));
  }, []);

  console.log({ LLMName });

  const SetLLM = (row) => {
    setLLMId(row.LLM_id);
    setCurrentSelectedLLMId(row.LLM_id);
    setLLMName(row.LLM_title_name);
    sessionStorage.setItem('LLMId', row.LLM_id);
    sessionStorage.setItem('LLMName', row.LLM_title_name);
  };

  const RemoveLLM = () => {
    setLLMId(null);
    setLLMName(null);
    setCurrentSelectedLLMId(null);
    sessionStorage.removeItem('LLMId');
    sessionStorage.removeItem('LLMName');
  };

  const handleToggleLLM = (row) => {
    if (currentSelectedLLMId === row.LLM_id) {
      RemoveLLM();
      return;
    }
    SetLLM(row);
  };

  return (
    <Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          sx={{
            minWidth: 650,
            borderSpacing: '0px 4px',
            borderCollapse: 'separate',
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>LLM</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow
                  key={row.LLM_id}
                  className="rowHover"
                  style={{
                    backgroundColor: `${row.LLM_title_name === LLMName ? '#e6f4fc' : ''}`,
                    border: `${row.LLM_title_name === LLMName ? '1px solid red' : ''}`,
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    handleToggleLLM(row);
                  }}
                >
                  <StyledTableCell>{row.LLM_name}</StyledTableCell>
                  <StyledTableCell>{row.created_on}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingIcon />}
    </Box>
  );
}
