import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper, Button, Typography, Tabs, Tab } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MdAdd } from 'react-icons/md';
import SearchAndFilter from '../../reusable-component/SearchAndFilter';
import { WORKFLOW_PAGE, ENGAGEMENT_PAGE, CLIENT_PAGE } from '../../utils/commonConstants';
import { getWorkflowById, getWorkflowByUserGroupAndEngagementIdApi } from "../../api's/WorkflowApi";
import ClientNoData from '../../components/Client/ClientNoData';
import PageHeaderTable from '../../reusable-component/PageHeaderTable';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import AddWorkflowModal from '../../reusable-component/AddWorkflowModal';
import { AuthContext } from '../../contexts/AuthProvider';
import AccessModal from '../../reusable-component/AccessModal';
import AccordionTable from '../../reusable-component/AccordionTable';
import WorkflowAccordionTable from '../../components/Engagements/WorkflowAccordionTable';
import { getDataProcessor } from "../../api's/DataProcessorApi";
import convertToRequiredFormat from '../../components/react-flow/JsonConverter';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import { getAllInChainWorkflowApi, saveInChainWorkflowApi } from "../../api's/InChainWorkflowApi";
import useDebounce from '../../hooks/useDebounce';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EngagementDetails({ headerTableData }) {
  const [mode, setMode] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState(0);
  const { workflow: workflowInfo } = useContext(WorkflowContext);
  const rowsPerPage = 5;
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [workflowTableData, setWorkflowTableData] = useState([]);
  const [openWorkflowModal, setOpenWorkflowModal] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const { userRole, domainName, userGroup } = useContext(AuthContext);
  const [dropdowns, setDropdowns] = useState([]);

  const { userId } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [masterJson, setMasterJson] = useState({
    jsonpath: [],
  });

  const handleSearch = () => {
    // console.log('Hii');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleMode = (event) => {
    setMode(event.target.value);
  };

  const handleStatus = (event) => {
    sessionStorage.setItem('filtered_engagementdetails_status', event.target.value);
    setStatus(event.target.value);
  };

  const handleOnChange = (event) => {
    sessionStorage.setItem('filtered_engagementdetails_search', event.target.value);
    setSearch(event.target.value);
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem('filtered_engagementdetails_status');
    sessionStorage.removeItem('filtered_engagementdetails_search');
    setMode('');
    setStatus('');
    setMasterJson({ jsonpath: [] });
    setSearch('');
    // fetchWorkflowTableData();
    setFilteredTableData(workflowTableData);
    setPage(1);
  };

  const handleWorkflowModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenWorkflowModal(!openWorkflowModal);
    }
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  const data = {
    search,
    handleSearch,
    handleOnChange,
    region: mode,
    handleRegion: handleMode,
    handleClearFilter,
    handleStatus,
    status,
    firstFilterText: ENGAGEMENT_PAGE.engagementFilterByMode,
    secondFilterText: CLIENT_PAGE.clientFilterByStatus,
    clearFilterText: CLIENT_PAGE.clientClearFilterButton,
    firstFilterValues: {
      first: 'Real time',
      second: 'Batch',
    },
    secondFilterValues: {
      filterData: ['approved', 'pending'],
    },
    isFirstFilterVisible: false,
    isSecondFilterVisible: true,
    isThirdFilterVisible: false,
  };

  const headerData = {
    firstHeader: 'ENGAGEMENT',
    secondHeader: 'DOMAIN',
    thirdHeader: 'CLIENT',
    fourthHeader: 'TOTAL WORKFLOWS',
    fifthHeader: 'CREATED BY',
    sixthHeader: 'CREATED ON',
    seventhHeader: 'STATUS',
    firstHeaderText: headerTableData.engagement_name,
    secondHeaderText: headerTableData.domain_name,
    thirdHeaderText: headerTableData.client_name,
    fourthHeaderText: headerTableData.workflow_count,
    fifthHeaderText: headerTableData.created_by,
    sixthHeaderText: headerTableData.created_at,
    seventhHeaderText: headerTableData.status,
    domainName,
    route: '/engagement',
    type: 'engagement',
  };

  const fetchFilterTableData = (responseTableData) => {
    const filtered_status = sessionStorage.getItem('filtered_engagementdetails_status') || '';
    const filtered_search = sessionStorage.getItem('filtered_engagementdetails_search') || '';
    const filtered_search_lowercase = filtered_search.toLowerCase() || '';

    const filteredData = responseTableData.filter((data) => {
      return (
        (!filtered_status || data.status === filtered_status) &&
        (!filtered_search || data.workflow_name.toLowerCase().startsWith(filtered_search_lowercase))
      );
    });

    setFilteredTableData(filteredData);
    setPage(1);
  };

  const version = JSON.parse(sessionStorage.getItem('version'));

  const fetchWorkflowTableData = async () => {
    setLoading(true);
    try {
      const { data, status } = await getWorkflowByUserGroupAndEngagementIdApi(userGroup, headerTableData.engagement_id);

      if (status === 200) {
        if (version === '1.0') {
          const filterByVersion = data.filter(
            (item) => item.process_type === 'Data Ingestion' || item.process_type === 'Data Transformation'
          );
          setWorkflowTableData(filterByVersion);
          // setFilteredTableData(filterByVersion);
          fetchFilterTableData(filterByVersion);
        } else {
          setInChainFunction(data);
          // setWorkflowTableData(
          //   data.map((item, index) => {
          //     return { ...item, id: index, x: 0, y: 0, inChain: false };
          //   })
          // );
          // // setFilteredTableData(data);
          // fetchFilterTableData(
          //   data.map((item, index) => {
          //     return { ...item, id: index, x: 0, y: 0, inChain: false };
          //   })
          // );
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchWorkflowTableData();
  }, []);

  useEffect(() => {
    const filtered_status = sessionStorage.getItem('filtered_engagementdetails_status') || '';
    const filtered_search = sessionStorage.getItem('filtered_engagementdetails_search') || '';
    setStatus(filtered_status);
    setSearch(filtered_search);
  }, []);

  const debouncedFilteredData = useDebounce(fetchFilterTableData, 500, [search, status, workflowTableData]);

  useEffect(() => {
    debouncedFilteredData(workflowTableData);
  }, [debouncedFilteredData]);

  // useEffect(() => {
  //   setFilteredTableData(workflowTableData.filter((workflowTableData) => workflowTableData.status === status));
  // }, [status]);

  // useEffect(() => {
  //   setFilteredTableData(workflowTableData.filter((workflowTableData) => workflowTableData.execution_mode === mode));
  // }, [mode]);

  // useEffect(() => {
  //   setFilteredTableData(
  //     workflowTableData.filter((workflowTableData) => workflowTableData.workflow_name.toLowerCase().startsWith(search.toLowerCase()))
  //   );
  // }, [search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearch('');
    // setClient('');
    setMode('');
    setMasterJson({ jsonpath: [] });
    setPage(1);
    // fetchTableData();
    // setFilteredTableData(workflowTableData);
  };
  const pad2 = (n) => {
    return n < 10 ? `0${n}` : n;
  };

  const runDataProcessorWorkflow = async (row, jsonArray) => {
    setLoading(true);
    try {
      const result = await getDataProcessor(row.workflow_id, row.execution_mode);
      const fetchedWorkflow = await getWorkflowById(row.workflow_id);
      const workflowData = fetchedWorkflow.data;
      const dataProcessorJson = convertToRequiredFormat(result.data.form_data, '', '');
      const matchAndMerge = dataProcessorJson.steps.some((item) => item.method_name === 'MatchAndMerge')
        ? dataProcessorJson.steps?.filter((item) => item.method_name === 'MatchAndMerge')
        : dataProcessorJson;

      const updatedDataProcessorJson = {
        name: '',
        transformer_execution_details: {},
        spark_config: {},
        udf: {},
        steps: matchAndMerge,
      };

      if (result.status === 200 && updatedDataProcessorJson) {
        let trackingId = '';
        const date = new Date();
        trackingId = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(
          date.getDate()
        )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(date.getMilliseconds())}`;

        const batch_table_id = `${workflowData.engagement.client.client_name}_${workflowData.engagement.engagement_name}_${workflowData.workflow_name}_dataProcessor_${trackingId}`;

        const meta = row.executionInfoDto.find((ele) => ele.workflow_type === 'Data Processor');
        meta.workflow_type = 'Data Processor';
        meta.execution_engine = workflowData.connectionName;
        meta.executed_by_id = userId;
        meta.execution_id = batch_table_id.replaceAll(' ', '');

        // create masterJson
        const str = `${row.client_name}/${row.engagement_name}/${row.workflow_name}/Data_Processor/Scripts/${trackingId}/${row.workflow_name}.json`;
        jsonArray.push(str);
        // await updateExecution(meta, workflowData.workflow_id);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const setInChainFunction = async (data) => {
    setLoading(true);
    try {
      const allInChainWorkflow = await getAllInChainWorkflowApi();

      let newWorkflowTableData = data.map((item, index) => ({
        ...item,
        id: index,
        x: 0,
        y: 0,
        inChain: false,
      }));

      if (allInChainWorkflow.status === 200) {
        const desiredObj = allInChainWorkflow.data?.find((obj) => obj.engagement_id === headerTableData.engagement_id);

        if (desiredObj) {
          const workflowIdToData = data.reduce((map, item) => {
            map[item.workflow_id] = item;
            return map;
          }, {});

          newWorkflowTableData = desiredObj.workflow_id.map((workflowId, index) => ({
            ...(workflowIdToData[workflowId] || {}),
            id: index,
            x: 0,
            y: 0,
            inChain: desiredObj.inChain[index],
          }));
        }
      }

      setWorkflowTableData(newWorkflowTableData);
      fetchFilterTableData(newWorkflowTableData);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong in setting in chain!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRunChainWorkflows = async () => {
    let flagInChain = false;
    const jsonArray = [];
    const inChainArray = [];
    const workflowIdArray = [];

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < dropdowns.length; i++) {
      workflowIdArray.push(dropdowns[i].workflow_id);
      if (dropdowns[i].inChain === true) {
        await runDataProcessorWorkflow(dropdowns[i], jsonArray);
        flagInChain = true;
        inChainArray.push(true);
      } else {
        inChainArray.push(false);
      }
    }
    /* eslint-enable no-await-in-loop */
    if (flagInChain === false) {
      enqueueSnackbar('Add atleast one workflow in chain !!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      setLoading(true);
      try {
        const inChainData = {
          engagement_id: headerTableData.engagement_id,
          workflow_id: workflowIdArray,
          inChain: inChainArray,
        };
        const result = await saveInChainWorkflowApi(inChainData);

        if (result.status !== 200 && result.status !== 201) {
          enqueueSnackbar('Something went wrong !!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        enqueueSnackbar('Error while saving chain workflows order !', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      setLoading(false);
    }
    setMasterJson({ jsonpath: jsonArray });
  };

  return (
    <>
      <Box>
        <PageHeaderTable data={headerData} />
        <Box sx={{ paddingTop: '10px', paddingLeft: '15px' }}>
          <Grid container direction="row" display="flex">
            <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
              {WORKFLOW_PAGE.workflowPageTitle}
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#00043C', marginRight: '10px', '&:hover': { backgroundColor: '#00043C' } }}
                onClick={handleRunChainWorkflows}
              >
                🔗 RUN
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
                onClick={handleWorkflowModal}
              >
                <MdAdd />
                Add New Workflow
              </Button>
            </Grid>
          </Grid>
          <AddWorkflowModal
            handleModal={handleWorkflowModal}
            open={openWorkflowModal}
            fetchTableData={fetchWorkflowTableData}
            tabValue={value}
            data={headerTableData}
            engagementId={headerTableData.engagement_id}
            engagementName={headerTableData.engagement_name}
            clientName={headerTableData.client_name}
            processType="Ingestion"
          />
          <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />

          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#47D7AC',
                },
              }}
              sx={{ '& .MuiTab-root.Mui-selected': { color: '#00043C' } }}
            >
              <Tab label="Workflows" {...a11yProps(0)} sx={{ fontWeight: 'bold' }} />
              {/* <Tab label="Chain Workflow" {...a11yProps(1)} sx={{ fontWeight: 'bold' }} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {workflowTableData.length > 0 && <SearchAndFilter data={data} />}
            {filteredTableData.length > 0 ? (
              <WorkflowAccordionTable
                data={filteredTableData}
                type="all"
                headerTableDataEngagementId={headerTableData.engagement_id}
                fetchWorkflowTableData={fetchWorkflowTableData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleRunChainWorkflows={handleRunChainWorkflows}
                dropdowns={dropdowns}
                setDropdowns={setDropdowns}
              />
            ) : (
              !loading && (
                <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                  <ClientNoData
                    title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
                    text={WORKFLOW_PAGE.noWorkflowToReview}
                    addBtnText={WORKFLOW_PAGE.addNewWorkflowButton}
                    handleOnClick={handleWorkflowModal}
                    isAddBtn
                  />
                </Paper>
              )
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {workflowTableData.length > 0 && <SearchAndFilter data={data} />}
            {filteredTableData.length > 0 ? (
              <WorkflowAccordionTable
                data={filteredTableData}
                type="all"
                fetchWorkflowTableData={fetchWorkflowTableData}
                rowsPerPage={rowsPerPage}
                page={page}
                setFilteredTableData={setFilteredTableData}
                handleRunChainWorkflows={handleRunChainWorkflows}
                dropdowns={dropdowns}
                setDropdowns={setDropdowns}
              />
            ) : (
              !loading && (
                <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                  <ClientNoData
                    title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
                    text={WORKFLOW_PAGE.noWorkflowToReview}
                    addBtnText={WORKFLOW_PAGE.addNewWorkflowButton}
                    handleOnClick={handleWorkflowModal}
                    isAddBtn
                  />
                </Paper>
              )
            )}
          </TabPanel>
        </Box>
        {/* <PaginationFooter workflowTableData={filteredTableData} page={page} handleChangePage={handleChangePage} /> */}
      </Box>
      {loading && <LoadingIcon />}
    </>
  );
}
