import { Tooltip } from '@mui/material';
import React, { memo } from 'react';
import { Handle } from 'reactflow';
import cleansingIcon from '../../../../assets/images/cleansing.png';
import udfIcon from '../../../../assets/images/udf1.png';
import analyticIcon from '../../../../assets/images/analytics.png';

const DLOthersNode = ({ data, isConnectable }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip
        title={
          <>
            <div style={{ whiteSpace: 'pre-line' }}>
              Incoming edges: {data.Incoming !== undefined ? data.Incoming : 0}
            </div>
            <div style={{ whiteSpace: 'pre-line' }}>
              Outgoing edges: {data.Outgoing !== undefined ? data.Outgoing : 0}
            </div>
            <div style={{ whiteSpace: 'pre-line' }}>{data.label}</div>
          </>
        }
        placement="top"
      >
        <div
          style={{
            width: '150px',
            border: '2px solid black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fcefb4',
          }}
        >
          <Handle
            type="target"
            position="left"
            onConnect={(params) => console.log('', params)}
            isConnectable={isConnectable}
          />
          <Handle type="source" position="right" isConnectable={isConnectable} />
          {data.mode === 'Data Cleansing' && (
            <>
              <img src={cleansingIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Udf' && (
            <>
              <img src={udfIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'C360' && (
            <>
              <img src={analyticIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default memo(DLOthersNode);
