import axios from 'axios';
import { CHATGPT_URL } from '../CHATGPT_URL';
import { logoutApi } from './AuthApi';
import BASEURL from '../BaseUrl';


const getChatgptUrl = async () => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

  const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
    headers: { Authorization: `Bearer ${parseToken}` },
  });
  if (res1.status === 200) {
    return res1.data.query_url
  }
}

export const getClientList = async () => {
  try {
    const CHATGPT_URL = await getChatgptUrl();
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${CHATGPT_URL}/list_clients`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getEngagementList = async (client_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const CHATGPT_URL = await getChatgptUrl();
    return await axios.get(`${CHATGPT_URL}/list_batches`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { client_name },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowList = async (client_name, batch_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const CHATGPT_URL = await getChatgptUrl();
    return await axios.get(`${CHATGPT_URL}/list_jobs`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { client_name, batch_name },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getDataSetList = async (client_name, batch_name, job_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const CHATGPT_URL = await getChatgptUrl();
    return await axios.get(`${CHATGPT_URL}/list_dataset`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { client_name, batch_name, job_name },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getFileByLanguageList = async (language) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const CHATGPT_URL = await getChatgptUrl();
    return await axios.get(`${CHATGPT_URL}/${language}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getPdfFileList = async (client_name, batch_name, job_name, language) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const CHATGPT_URL = await getChatgptUrl();
    return await axios.get(`${CHATGPT_URL}/list_pdf_files`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { client_name, batch_name, job_name, language },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
