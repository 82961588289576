import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { fetchHtmlApi, fetchHtmlJsonApi } from "../api's/DataProcessorApi";
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { WorkflowContext } from '../contexts/WorkflowProvider';
import DataProfilingHtml from './html-reports/DataProfilingHtml';
import DataValidationHtml from './html-reports/DataValidationHtml';

const RenderHtml = ({ htmlData, nodeId, handleCloseModal }) => {
  const [html, setHtml] = useState();
  const [nodeName, setNodeName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);

  const nodeTypeHtml = {
    profiling: 'profiling',
    validation: 'validation',
    sample_view: 'metrics',
    training: 'metrics',
  };

  const fetchHtml = async () => {
    let node;

    try {
      const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
      getAllNodes.map((el) => {
        if (el.step_name === 'DataValidation' && el.nodeId === nodeId) {
          node = el;
          setNodeName(node.formField.alias);
        }
        if (el.step_name === 'Training' && el.nodeId === nodeId) {
          node = el;
          setNodeName(node.formField.alias);
        }
      });

      const nodeTypePath = {
        profiling: `${node.formField.profilingPath}`,
        validation: `${node.formField.path}`,
        sample_view: `${node.formField.path}/metrics`,
        training: `${node.formField.path}/metrics`,
      };

      const path = nodeTypePath[htmlData];

      const originalText = AWS_CONFIG_TYPE ? path.replace('dep-qa/', '') : path.replace('dep-develop/', '');
      let pathType;

      if (htmlData === 'validation') {
        pathType = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Data_Processor/Output/Data_Profiling/${node.formField.dataFrame}/validation/validation.json`;
      } else if (htmlData === 'profiling') {
        pathType = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Data_Processor/Output/Data_Profiling/${node.formField.dataFrame}/profiling/profiling.json`;
      } else {
        pathType = `${originalText}/${nodeTypeHtml[htmlData]}.html`;
      }

      let response;

      if (htmlData === 'validation' || htmlData === 'profiling') {
        response = await fetchHtmlJsonApi(pathType, AWS_CONFIG_TYPE, workflow.engagement.client.client_id);
      } else {
        response = await fetchHtmlApi(pathType, workflow.workflow_id);
      }

      if (response.status === 200) {
        setHtml(response.data);
      }
    } catch (error) {
      handleCloseModal();
      console.log(error);
      enqueueSnackbar(`${htmlData} Not Done`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    fetchHtml();
  }, []);

  return (
    <div>
      {htmlData !== 'profiling' && htmlData !== 'validation' && <div dangerouslySetInnerHTML={{ __html: html }} />}
      {html && htmlData === 'profiling' && (
        <DataProfilingHtml nodeName={nodeName} profilingJson={html} handleCloseModal={handleCloseModal} />
      )}
      {html && htmlData === 'validation' && (
        <DataValidationHtml validationJson={html} nodeName={nodeName} handleCloseModal={handleCloseModal} />
      )}
    </div>
  );
};

export default RenderHtml;
