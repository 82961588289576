import React from 'react';
import { Box } from '@mui/material';
import PageHeaderTable from '../../reusable-component/PageHeaderTable';
import AWSLogsTable from '../logs/AWSLogsTable';
import EmrLogCluster from '../logs/emr-logs/EmrLogCluster';
import LogsList from '../logs/azure-logs/LogList';
import LogAnalyticsList from '../logs/azure-logs/LogAnalyticsList';

export default function ViewLogs({ headerTableData, processType }) {
  const headerData = {
    firstHeader: 'WORKFLOW',
    secondHeader: 'CLIENT',
    thirdHeader: 'ENGAGEMENT',
    fourthHeader: 'EXECUTION MODE',
    fifthHeader: 'CREATED BY',
    sixthHeader: 'CREATED ON',
    seventhHeader: 'STATUS',
    firstHeaderText: headerTableData?.workflow_name,
    secondHeaderText: headerTableData?.client_name,
    thirdHeaderText: headerTableData?.engagement_name,
    fourthHeaderText: headerTableData?.execution_mode,
    fifthHeaderText: headerTableData?.created_by,
    sixthHeaderText: headerTableData?.created_at,
    seventhHeaderText: headerTableData?.status,
    workflow: headerTableData?.workflow_data,
    route: `/workflow-${processType}`,
  };
  return (
    <Box>
      <PageHeaderTable data={headerData} />
      {headerTableData.cloudEnvironment === 'AWS' ? (
        <AWSLogsTable headerTableData={headerTableData} processType={processType} />
      ) : headerTableData.cloudEnvironment === 'AZURE' ? (
        <LogAnalyticsList headerTableData={headerTableData} processType={processType} />
      ) : (
        <LogAnalyticsList headerTableData={headerTableData} processType={processType} />
      )}
    </Box>
  );
}
