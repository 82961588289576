import { Paper, TextField, IconButton, Grid, Button, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getOpenaiData, updateOpenaiData } from "../../api's/ChatGPTApi";
import { getWorkflowByUserGroupAndEngagementIdApi } from "../../api's/WorkflowApi";
import { getEngagementByUserGroupAndClientIdApi } from "../../api's/EngagementApi";
import { getAllClientsApi, getClientByUserGroup } from "../../api's/ClientApi";
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { AuthContext } from '../../contexts/AuthProvider';
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { getFiles } from "../../api's/OpenApi";

const ChatGPTInput = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [apiType, setApiType] = useState('azure');
  const [apiBase, setApiBase] = useState('https://depchatgpt.openai.azure.com/');
  const [apiVersion, setApiVersion] = useState('2022-12-01');
  const [apiKey, setApiKey] = useState('214e75806da6403589886c59f1a8a92c');
  const [loading, setLoading] = useState(false);

  const { userRole } = useContext(AuthContext);
  const [pdf_file_paths, setpdf_file_paths] = useState([]);
  const [client, setClient] = useState();
  const [engagement, setEngagement] = useState();
  const [workflow, setWorkflow] = useState();
  const [locationPath, setlocationPath] = useState('TalkToDB');
  const [pdfFile, setPdfFile] = useState('');

  const [AllClient, setAllClient] = useState([]);
  const [AllBatch, setAllBatch] = useState([]);
  const [AllTable, setAllTable] = useState([]);

  const handleOnChange = (event, source) => {
    switch (source) {
      case 'client': {
        setClient(event.target.value);
        break;
      }
      case 'engagement': {
        setEngagement(event.target.value);
        break;
      }
      case 'workflow': {
        setWorkflow(event.target.value);
        break;
      }
      case 'locationPath': {
        setlocationPath(event.target.value);
        break;
      }
      case 'pdfFile': {
        setPdfFile(event.target.value);
        break;
      }
      default:
        break;
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const getFieldsData = async () => {
    setLoading(true);
    try {
      const response = await getOpenaiData('db');
      if (response.status === 200) {
        setApiType(response.data.api_type);
        setApiBase(response.data.api_base);
        setApiVersion(response.data.api_version);
        setApiKey(response.data.api_key);
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong! Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   getFieldsData();
  // }, []);

  const handleSaveBtn = async () => {
    setLoading(true);
    try {
      const data = {
        api_type: apiType,
        api_base: apiBase,
        api_version: apiVersion,
        api_key: apiKey,
        openai_type: 'db',
      };
      const res = await updateOpenaiData(data);
      if (res.status === 200) {
        getFieldsData();
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong! Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  const getClient = async () => {
    const response = userRole === 'Admin' ? await getAllClientsApi() : await getClientByUserGroup('Group1');
    if (response.status === 200) {
      const clients = response.data.filter((el) => el.status === 'approved');
      setAllClient(clients);
    }
  };

  const getBatch = async (clientName) => {
    const sp = clientName.split('_____');
    const response = await getEngagementByUserGroupAndClientIdApi('Group1', sp[0]);
    if (response.status === 200) {
      const engagements = response.data.filter((el) => el.status === 'approved');
      setAllBatch(engagements);
    }
  };

  const getTable = async (batchName) => {
    try {
      const sp1 = batchName.split('_____');
      const response = await getWorkflowByUserGroupAndEngagementIdApi('Group1', sp1[0]);
      if (response.status === 200) {
        const workflows = response.data.filter((el) => el.status === 'approved');
        setAllTable(workflows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClient();
  }, []);

  useEffect(() => {
    if (client) {
      getBatch(client);
    }
  }, [client]);

  useEffect(() => {
    if (engagement) {
      getTable(engagement);
    }
  }, [engagement]);

  useEffect(() => {
    if (workflow && locationPath) {
      // const prefix = `${client.split('_____')[1]}/${engagement.split('_____')[1]}/${workflow}/Data_Processor/Input`;
      // console.log({ prefix });
      // fetchFiles(prefix, 'csv');
      // console.log({ locationPath });
      console.log({ locationPath });
    }
  }, [workflow, locationPath]);

  const fetchFiles = async () => {
    const prefix = `${client.split('_____')[1]}/${engagement.split('_____')[1]}/${workflow}/${locationPath}`;
    const extension = 'pdf';

    const response = await getFiles(prefix, extension, AWS_CONFIG_TYPE);
    if (response.status === 200) {
      setpdf_file_paths(response.data);
    }
  };

  useEffect(() => {
    console.log(pdfFile);
  }, [pdfFile]);

  return (
    // <Grid container spacing={2}>
    //   <Grid item xs={2.5} md={2.5}>
    //     {/* <Item> */}
    //     <TextField
    //       id="api_type"
    //       size="small"
    //       label="API Type"
    //       name="api_type"
    //       // InputProps={{
    //       //   endAdornment: <FiSearch onClick={data.handleSearch} sx={{ cursor: 'pointer' }} />,
    //       // }}
    //       // onChange={handleOnChange}
    //       // disabled
    //       onChange={(e) => handleOnChange(e, 'apiType')}
    //       value={apiType}
    //       variant="outlined"
    //       sx={{ background: '#ffffff', mt: 0.5 }}
    //     />
    //     {/* </Item> */}
    //   </Grid>
    //   <Grid item xs={3} md={3}>
    //     {/* <Item> */}
    //     <TextField
    //       id="api_base"
    //       size="small"
    //       label="API Base"
    //       name="api_base"
    //       // InputProps={{
    //       //   endAdornment: <FiSearch onClick={data.handleSearch} sx={{ cursor: 'pointer' }} />,
    //       // }}
    //       // onChange={handleOnChange}
    //       // disabled
    //       onChange={(e) => handleOnChange(e, 'apiBase')}
    //       value={apiBase}
    //       variant="outlined"
    //       sx={{ background: '#ffffff', mt: 0.5 }}
    //     />
    //     {/* </Item> */}
    //   </Grid>
    //   <Grid item xs={2.5} md={2.5}>
    //     {/* <Item> */}
    //     <TextField
    //       id="api_version"
    //       size="small"
    //       label="API Version"
    //       name="api_version"
    //       // InputProps={{
    //       //   endAdornment: <FiSearch onClick={data.handleSearch} sx={{ cursor: 'pointer' }} />,
    //       // }}
    //       // onChange={handleOnChange}
    //       // disabled
    //       onChange={(e) => handleOnChange(e, 'apiVersion')}
    //       value={apiVersion}
    //       variant="outlined"
    //       sx={{ background: '#ffffff', mt: 0.5 }}
    //     />
    //     {/* </Item> */}
    //   </Grid>
    //   <Grid item xs={3} md={3}>
    //     {/* <Item> */}
    //     <TextField
    //       id="api_key"
    //       size="small"
    //       label="API Key"
    //       name="api_key"
    //       // InputProps={{
    //       //   endAdornment: <FiSearch onClick={data.handleSearch} sx={{ cursor: 'pointer' }} />,
    //       // }}
    //       // onChange={handleOnChange}
    //       // disabled
    //       onChange={(e) => handleOnChange(e, 'apiKey')}
    //       value={apiKey}
    //       variant="outlined"
    //       sx={{ background: '#ffffff', mt: 0.5 }}
    //     />
    //     {/* </Item> */}
    //   </Grid>
    //   <Grid item xs={1} md={1}>
    //     {/* <TextField
    //         id="api_key"
    //         size="small"
    //         label="API Key"
    //         name="api_key"
    //         // InputProps={{
    //         //   endAdornment: <FiSearch onClick={data.handleSearch} sx={{ cursor: 'pointer' }} />,
    //         // }}
    //         // onChange={handleOnChange}
    //         onChange={(e) => handleOnChange(e, 'apiKey')}
    //         value={apiKey}
    //         variant="outlined"
    //         sx={{ background: '#ffffff', mt: 0.5 }}
    //       /> */}
    //     <Button
    //       size="small"
    //       type="submit"
    //       variant="contained"
    //       // disabled
    //       sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' }, marginTop: '10%' }}
    //       onClick={handleSaveBtn}
    //     >
    //       Save
    //     </Button>
    //   </Grid>
    //   {loading && <LoadingIcon />}
    // </Grid>

    <Grid container spacing={2}>
      <Grid item xs={2.5} md={2}>
        <TextField
          id="outlined-select-client"
          select
          required
          label="Client"
          name="client"
          value={client}
          onChange={(e) => handleOnChange(e, 'client')}
          fullWidth
          size="small"
        >
          {AllClient &&
            AllClient.map((ele) => {
              return (
                <MenuItem key={ele.client_id} value={`${ele.client_id}_____${ele.client_name}`}>
                  {ele.client_name}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid>
      <Grid item xs={3} md={2}>
        <TextField
          id="outlined-select-batch"
          select
          required
          label="Engagement"
          name="batch"
          value={engagement}
          onChange={(e) => handleOnChange(e, 'engagement')}
          fullWidth
          size="small"
        >
          {AllBatch &&
            AllBatch.map((ele) => {
              return (
                <MenuItem key={ele.engagement_id} value={`${ele.engagement_id}_____${ele.engagement_name}`}>
                  {ele.engagement_name}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid>
      <Grid item xs={2.5} md={2}>
        <TextField
          id="tableNameRead"
          select
          required
          label="Workflow"
          name="tableNameRead"
          value={workflow}
          onChange={(e) => handleOnChange(e, 'workflow')}
          fullWidth
          size="small"
        >
          {AllTable &&
            AllTable.map((ele) => {
              return (
                <MenuItem key={ele.workflow_name} value={ele.workflow_name}>
                  {ele.workflow_name}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid>
      <Grid item xs={2.5} md={2}>
        <TextField
          id="tableNameRead"
          required
          label="location"
          name="locationPath"
          value={locationPath}
          onChange={(e) => handleOnChange(e, 'locationPath')}
          fullWidth
          size="small"
        />
      </Grid>
      {/* <Grid item xs={0.5} md={0.5}>
        <IconButton onClick={fetchFiles} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Grid>

      <Grid item xs={2.5} md={2}>
        <TextField
          id="tableNameRead"
          select
          required
          label="PDF files"
          name="tableNameRead"
          value={pdfFile}
          onChange={(e) => handleOnChange(e, 'pdfFile')}
          fullWidth
          size="small"
        >
          {pdf_file_paths &&
            pdf_file_paths.map((ele) => {
              return (
                <MenuItem key={ele.value} value={ele.label}>
                  {ele.label}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid> */}
      <Grid item xs={1} md={1}>
        <Button
          size="small"
          type="submit"
          variant="contained"
          // disabled
          sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' }, marginTop: '10%' }}
          onClick={handleSaveBtn}
        >
          Save
        </Button>
      </Grid>
      {loading && <LoadingIcon />}
    </Grid>
  );
};

export default ChatGPTInput;
