export default async function GetNodes() {
  const position = { x: 0, y: 0 };
  const allNodes = [];
  const getAllDataFromLocalStorage = JSON.parse(sessionStorage.getItem('allNodes'));
  console.log(getAllDataFromLocalStorage);

  getAllDataFromLocalStorage.forEach((ele) => {
    if (
      ele.nodeName === 'Write' ||
      ele.nodeName === 'SnowFlake' ||
      ele.nodeName === 'OpenSearch' ||
      ele.nodeName === 'WriteSFTP' ||
      ele.nodeName === 'Postgres' ||
      ele.nodeName === 'Ingestion Write'
    ) {
      ele.fetchedHeader?.forEach((item) => {
        const idx = ele.headerName.find((itemHeader) => itemHeader.header === item.header);
        const datalabel = item.header;
        allNodes.push({
          id: `${ele.nodeId}__${item.header}`,
          data: { label: datalabel, mode: ele.nodeName },
          position,
          type: 'InnerNode',
          isMainBlock: false,
          isChecked: idx !== undefined,
        });
      });
      allNodes.push({
        id: `${ele.nodeId}`,
        data: { label: `${ele.formField.alias}`, mode: ele.nodeName },
        position,
        type: 'WriteNode',
        isMainBlock: true,
        isChecked: true,
      });
      // allNodes.push({
      //   id: `${ele.nodeId}`,
      //   data: {
      //     label: ele.toggleType !== 'S3' ? ele.toggleType : 'write',
      //     mode: ele.nodeName,
      //   },
      //   position,
      //   type: 'WriteNode',
      //   isMainBlock: true,
      //   isChecked: true,
      // });
    } else if (
      ele.nodeName === 'Read' ||
      ele.nodeName === 'ReadCSV' ||
      ele.nodeName === 'ReadPDF' ||
      ele.nodeName === 'CopyADLS' ||
      ele.nodeName === 'ReadPublishedData' ||
      ele.nodeName === 'ReadDelimited' ||
      ele.nodeName === 'ReadOracle' ||
      ele.nodeName === 'ReadPostgres' ||
      ele.nodeName === 'ReadMySql' ||
      ele.nodeName === 'ReadApi' ||
      ele.nodeName === 'ReadSnowflake' ||
      ele.nodeName === 'Read Real-Time' ||
      ele.nodeName === 'Read MDM' ||
      ele.nodeName === 'FileUploader'
    ) {
      ele.fetchedHeader.forEach((item) => {
        const idx = ele.headerName.find((itemHeader) => itemHeader.header === item.header);
        let datalabel = item.header;
        if (item.alias !== '') {
          datalabel = item.alias;
        }
        allNodes.push({
          id: `${ele.nodeId}__${item.header}`,
          data: { label: datalabel, mode: ele.nodeName },
          position,
          type: 'InnerNode',
          isMainBlock: false,
          isChecked: idx !== undefined,
        });
      });
      allNodes.push({
        id: `${ele.nodeId}`,
        data: { label: `${ele.headerName[0].tableAlias}`, mode: ele.nodeName },
        position,
        type: 'ReadNode',
        isMainBlock: true,
      });
    } else if (
      ele.nodeName === 'Join' ||
      ele.nodeName === 'Sort' ||
      ele.nodeName === 'Filter' ||
      ele.nodeName === 'Union' ||
      ele.nodeName === 'Coalesce' ||
      ele.nodeName === 'Execute SQL' ||
      ele.nodeName === 'KPI' ||
      ele.nodeName === 'Aggregation'
    ) {
      ele.fetchedHeader.forEach((item) => {
        const idx = ele.headerName.find((itemHeader) => itemHeader.header === item.header);
        let datalabel = item.header;
        if (
          (ele.nodeName === 'Sort' || ele.nodeName === 'Execute SQL') &&
          item.alias !== '' &&
          item.alias !== undefined
        ) {
          datalabel = item.alias;
        }
        allNodes.push({
          id: `${ele.nodeId}__${item.header}`,
          data: { label: datalabel, mode: ele.nodeName },
          position,
          type: 'InnerNode',
          isMainBlock: false,
          isChecked: idx !== undefined,
        });
      });
      allNodes.push({
        id: `${ele.nodeId}`,
        data: { label: `${ele.headerName[0]?.tableAlias}`, mode: ele.nodeName },
        position,
        type: 'DataTransformNode',
        isMainBlock: true,
        isChecked: true,
      });
    } else if (
      ele.nodeName === 'Datatype Conversion' ||
      ele.nodeName === 'Concat' ||
      ele.nodeName === 'Date Time Feature' ||
      ele.nodeName === 'Handle Null' ||
      ele.nodeName === 'Math Transformation' ||
      ele.nodeName === 'OneHot Encoder' ||
      ele.nodeName === 'Label Encoder' ||
      ele.nodeName === 'Drop' ||
      ele.nodeName === 'Outlier' ||
      ele.nodeName === 'Imbalanced Data' ||
      ele.nodeName === 'Binning' ||
      ele.nodeName === 'Scaler' ||
      ele.nodeName === 'Resample'
    ) {
      ele.fetchedHeader.forEach((item) => {
        const idx = ele.headerName.find((itemHeader) => itemHeader.header === item.header);
        const datalabel = item.header;
        allNodes.push({
          id: `${ele.nodeId}__${item.header}`,
          data: { label: datalabel, mode: ele.nodeName },
          position,
          type: 'InnerNode',
          isMainBlock: false,
          isChecked: idx !== undefined,
        });
      });
      allNodes.push({
        id: `${ele.nodeId}`,
        data: { label: `${ele.formField.alias}`, mode: ele.nodeName },
        position,
        type: 'TransformNode',
        isMainBlock: true,
        isChecked: true,
      });
    } else if (ele.nodeName === 'Training' || ele.nodeName === 'Prediction') {
      ele.fetchedHeader.forEach((item) => {
        const idx = ele.headerName.find((itemHeader) => itemHeader.header === item.header);
        const datalabel = item.header;
        allNodes.push({
          id: `${ele.nodeId}__${item.header}`,
          data: { label: datalabel, mode: ele.nodeName },
          position,
          type: 'InnerNode',
          isMainBlock: false,
          isChecked: idx !== undefined,
        });
      });
      allNodes.push({
        id: `${ele.nodeId}`,
        data: { label: `${ele.formField.alias}`, mode: ele.nodeName },
        position,
        type: 'ModelNode',
        isMainBlock: true,
        isChecked: true,
      });
    } else if (ele.nodeName === 'Source' || ele.nodeName === 'DataValidation') {
      ele.fetchedHeader.forEach((item) => {
        const idx = ele.headerName.find((itemHeader) => itemHeader.header === item.header);
        const datalabel = item.df;
        allNodes.push({
          id: `${ele.nodeId}__${item.df}`,
          data: { label: datalabel, mode: ele.nodeName },
          position,
          type: 'InnerNode',
          isMainBlock: false,
          isChecked: idx !== undefined,
        });
      });
      allNodes.push({
        id: `${ele.nodeId}`,
        data: { label: `${ele.formField.alias}`, mode: ele.nodeName },
        position,
        type: 'DataQualityNode',
        isMainBlock: true,
        isChecked: true,
      });
    } else if (ele.nodeName === 'Email') {
      ele.fetchedHeader.forEach((item) => {
        const idx = ele.headerName.find((itemHeader) => itemHeader.header === item.header);
        const datalabel = item.header;
        allNodes.push({
          id: `${ele.nodeId}__${item.header}`,
          data: { label: datalabel, mode: ele.nodeName },
          position,
          type: 'InnerNode',
          isMainBlock: false,
          isChecked: idx !== undefined,
        });
      });
      allNodes.push({
        id: `${ele.nodeId}`,
        data: { label: `${ele.formField.alias}`, mode: ele.nodeName },
        position,
        type: 'EmailNode',
        isMainBlock: true,
        isChecked: true,
      });
    } else {
      ele.headerName.forEach((item) => {
        allNodes.push({
          id: `${ele.nodeId}__${item.header}`,
          data: { label: item.header, mode: ele.nodeName },
          position,
          type: 'InnerNode',
          isMainBlock: false,
          isChecked: true,
        });
      });
      allNodes.push({
        id: `${ele.nodeId}`,
        data: { label: `${ele.nodeName}`, mode: ele.nodeName },
        position,
        type: 'OthersNode',
        isMainBlock: true,
        isChecked: true,
      });
    }
  });

  return allNodes;
}
