import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Typography,
  Toolbar,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  ButtonGroup,
  TextField,
  MenuItem,
  Grid,
  Modal,
  Tooltip,
  Box,
  Divider,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LoadingIcon from '../../../../reusable-component/LoadingIcon';
import InputField from '../../../../reusable-component/InputField';
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';
import { AWS_CONFIG_TYPE } from '../../../../BaseUrl';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
  path: '',
  format: '',
  persist: false,
  alias: '',
  snowflake_mode: '',
  snowflake_db_table: '',
  persist_type: '',
  partition: false,
  overwrite: false,
  df: '',
  p_key: '',
  index: '',
  database: '',
  tablename: '',
  db_type: '',
  mode: '',
  schema: '',
  distinct_rows: false,
  sample_view: true,
};

const NodeModal_IngestionWrite = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { workflow } = useContext(WorkflowContext);

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [tableName, setTableName] = useState([]);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);
  const [dataFrameCat, setDataFrameCat] = useState([]);
  const [dataFrameName, setDataFrameName] = useState([]);

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const handleDFChange = (obj, bool) => {
    const selectedIndex = dataFrameCat.findIndex((object) => object.df === obj.df);
    if (selectedIndex !== -1 && !bool) {
      setDataFrameCat((current) =>
        current.map((object) => {
          if (object.df === obj.df) {
            return { ...obj };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && bool) {
      setDataFrameCat((current) =>
        current.map((object) => {
          if (object.df === obj.df) {
            return { ...obj, file_path: `${obj.file_path}/${obj.dataFrame}/` };
          }

          return object;
        })
      );
    }
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    const tablename = [];

    if (store) {
      store.forEach((node) => {
        if (
          node.nodeName !== 'Write' &&
          (node.nodeName === 'Read' ||
            node.nodeName === 'ReadPostgres' ||
            node.nodeName === 'ReadCSV' ||
            node.nodeName === 'ReadPDF' ||
            node.nodeName === 'ReadSnowflake')
        ) {
          tablename.push(node.nodeId);
        }
      });

      setTableName(tablename);
    }

    if (store && findSrcNodeId) {
      const data_frame = [];
      const data_frame_name = [];
      store.forEach((node) => {
        const id = findSrcNodeId.find((item) => item === node.nodeId);
        if (
          id &&
          node.nodeName !== 'Write' &&
          (node.nodeName === 'Read' ||
            node.nodeName === 'ReadPostgres' ||
            node.nodeName === 'ReadCSV' ||
            node.nodeName === 'ReadPDF' ||
            node.nodeName === 'ReadSnowflake')
        ) {
          data_frame_name.push(node.formField.alias);
        }
      });

      setDataFrameName(data_frame_name);

      tablename.forEach((el, i) => {
        const id = findSrcNodeId.find((item) => item === el);
        if (id) {
          const data = {
            df: `data frame ${i + 1}`,
            distinct_rows: false,
            format: 'csv',
            overwrite: false,
            dataFrame: '',
            priority: i + 1,
            file_path: `${AWS_CONFIG_TYPE ? 'dep-qa' : 'dep-develop'}/${workflow.engagement.client.client_name}/${
              workflow.engagement.engagement_name
            }/${workflow.workflow_name}/Data_Processor/Output`,
          };
          data_frame.push(data);
        }
      });

      setDataFrameCat(data_frame);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setDataFrameCat(node.sorting);
          setTempHeader(node.headerName);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setDataFrameCat([]);
    getLocalData();
  }, [nodeId]);

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      const data_frame = [];
      const data_frame_name = [];
      store.forEach((node) => {
        const id = findSrcNodeId.find((item) => item === node.nodeId);
        if (
          id &&
          node.nodeName !== 'Write' &&
          (node.nodeName === 'Read' ||
            node.nodeName === 'ReadPostgres' ||
            node.nodeName === 'ReadCSV' ||
            node.nodeName === 'ReadPDF')
        ) {
          data_frame_name.push(node.formField.alias);
        }
      });

      setDataFrameName(data_frame_name);

      tableName.forEach((el, i) => {
        const id = findSrcNodeId.find((item) => item === el);
        if (id) {
          const data = {
            df: `data frame ${i + 1}`,
            distinct_rows: false,
            format: 'csv',
            overwrite: false,
            dataFrame: '',
            priority: i + 1,
            file_path: `${AWS_CONFIG_TYPE ? 'dep-qa' : 'dep-develop'}/${workflow.engagement.client.client_name}/${
              workflow.engagement.engagement_name
            }/${workflow.workflow_name}/Data_Processor/Output`,
          };
          data_frame.push(data);
        }
      });

      setDataFrameCat(data_frame);

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setHeaderName(node.headerName);
            // setDataFrameCat(node.sorting);

            const nonExistingHeader = [];
            node.sorting.forEach((item) => {
              const index = dataFrameCat.findIndex((x) => x.df === item.df);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            data_frame.forEach((el, i) => {
              const index = nonExistingHeader.findIndex((x) => x.df === el.df);

              if (index === -1) {
                nonExistingHeader.push({
                  df: `data frame ${i + 1}`,
                  distinct_rows: false,
                  format: 'csv',
                  overwrite: false,
                  dataFrame: '',
                  priority: i + 1,
                  file_path: `${AWS_CONFIG_TYPE ? 'dep-qa' : 'dep-develop'}/${workflow.engagement.client.client_name}/${
                    workflow.engagement.engagement_name
                  }/${workflow.workflow_name}/Data_Processor/Output`,
                });
              }
            });

            setDataFrameCat(nonExistingHeader);
          }
        });
      }
    }

    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    // let y_axis = 0;
    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    fetchedHeader.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    setHeaderName(newHeaderName);

    const newDataFrameSorted = dataFrameCat;

    newDataFrameSorted.sort((a, b) => a.priority - b.priority);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField: {
        ...formField,
        index:
          formField.index.toLowerCase() ||
          `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`.toLowerCase(),
      },
      disabled: true,
      step_name: nodeName,
      sorting: newDataFrameSorted,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '70%', marginLeft: '30%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  onClick={refreshAllState}
                  aria-label="close"
                  sx={{ color: '#00043C' }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid item xs={4}>
                <InputField
                  name="alias"
                  label="Alias"
                  value={formField.alias}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                  required
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formField.sample_view}
                      onChange={handleChecked}
                      disabled={disableForm}
                    />
                  }
                  label="Sample View"
                  sx={{ pt: 2 }}
                />
              </Grid>
            </Grid>
            {dataFrameCat.map((data, i) => (
              <>
                <Divider>
                  <Chip label={`Data Frame ${i + 1}`} size="small" />
                </Divider>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Distinct Rows"
                      size="small"
                      value={data.distinct_rows}
                      onChange={(event) => {
                        handleDFChange(
                          {
                            df: data.df,
                            distinct_rows: event.target.value,
                            format: data.format,
                            overwrite: data.overwrite,
                            dataFrame: data.dataFrame,
                            priority: data.priority,
                            file_path: data.file_path,
                          },
                          false
                        );
                      }}
                      disabled={disableForm}
                      style={{ width: '100%', marginTop: '15px' }}
                    >
                      <MenuItem value="true">true</MenuItem>
                      <MenuItem value="false">false</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Format"
                      variant="outlined"
                      value={data.format}
                      onChange={(event) => {
                        handleDFChange(
                          {
                            df: data.df,
                            distinct_rows: data.distinct_rows,
                            format: event.target.value,
                            overwrite: data.overwrite,
                            dataFrame: data.dataFrame,
                            priority: data.priority,
                            file_path: data.file_path,
                          },
                          false
                        );
                      }}
                      name="format"
                      size="small"
                      fullWidth
                      sx={{ mt: 2 }}
                      disabled={disableForm}
                      required
                      InputProps={{
                        style: {
                          fontWeight: 600,
                        },
                      }}
                    >
                      <MenuItem value="txt">txt</MenuItem>
                      <MenuItem value="doc">doc</MenuItem>
                      <MenuItem value="png">png</MenuItem>
                      <MenuItem value="csv">csv</MenuItem>
                      <MenuItem value="gif">gif</MenuItem>
                      <MenuItem value="streaming">streaming</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Overwrite"
                      variant="outlined"
                      value={data.overwrite}
                      name="overwrite"
                      onChange={(event) => {
                        handleDFChange(
                          {
                            df: data.df,
                            distinct_rows: data.distinct_rows,
                            format: data.format,
                            overwrite: event.target.value,
                            dataFrame: data.dataFrame,
                            priority: data.priority,
                            file_path: data.file_path,
                          },
                          false
                        );
                      }}
                      sx={{ mt: 2 }}
                      fullWidth
                      size="small"
                      disabled={disableForm}
                      required
                    >
                      <MenuItem value>true</MenuItem>
                      <MenuItem value={false}>false</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      select
                      name="df"
                      label={`Data Frame`}
                      value={data.dataFrame}
                      onChange={(event) => {
                        handleDFChange(
                          {
                            df: data.df,
                            distinct_rows: data.distinct_rows,
                            format: data.format,
                            overwrite: data.overwrite,
                            dataFrame: event.target.value,
                            priority: data.priority,
                            file_path: data.file_path,
                          },
                          true
                        );
                      }}
                      size="small"
                      sx={{ mt: 2 }}
                      disabled={disableForm}
                      required
                      fullWidth
                      InputProps={{
                        style: {
                          fontFamily: "'EB Garamond', serif ",
                          fontWeight: 600,
                        },
                      }}
                      InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                    >
                      {dataFrameName.map((node, i) => {
                        if (node !== undefined) {
                          return (
                            <MenuItem value={node} key={i}>
                              {node}
                            </MenuItem>
                          );
                        }
                      })}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      select
                      name="df"
                      label="Priority"
                      value={data.priority}
                      onChange={(event) => {
                        handleDFChange(
                          {
                            df: data.df,
                            distinct_rows: data.distinct_rows,
                            format: data.format,
                            overwrite: data.overwrite,
                            dataFrame: data.dataFrame,
                            priority: event.target.value,
                            file_path: data.file_path,
                          },
                          false
                        );
                      }}
                      size="small"
                      sx={{ mt: 2 }}
                      disabled={disableForm}
                      required
                      fullWidth
                      InputProps={{
                        style: {
                          fontFamily: "'EB Garamond', serif ",
                          fontWeight: 600,
                        },
                      }}
                      InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                    >
                      {tableName.map((node, i) => {
                        if (node !== undefined) {
                          return (
                            <MenuItem value={i + 1} key={i}>
                              {i + 1}
                            </MenuItem>
                          );
                        }
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      name="path"
                      label="File Path"
                      value={data.file_path}
                      onChange={(event) => {
                        handleDFChange(
                          {
                            df: data.df,
                            distinct_rows: data.distinct_rows,
                            format: data.format,
                            overwrite: data.overwrite,
                            dataFrame: data.dataFrame,
                            priority: data.priority,
                            file_path: event.target.value,
                          },
                          false
                        );
                      }}
                      size="small"
                      disabled={disableForm}
                      required
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </>
            ))}
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeModal_IngestionWrite;
