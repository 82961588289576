import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { getAllClientsApi, getClientByUserGroup } from "../../api's/ClientApi";
import { getEngagementByUserGroupAndClientIdApi } from "../../api's/EngagementApi";
import { createWorkflowApi, getWorkflowByUserGroupAndEngagementIdApi } from "../../api's/WorkflowApi";
import { AuthContext } from '../../contexts/AuthProvider';
import { getConnectionData, updateConnectionData } from "../../api's/ChatGPTApi";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 30,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  KeyboardEvent: false,
};

export default function AddConnectionModal({ open, close }) {
  const navigate = useNavigate();
  const { userRole, userGroup } = useContext(AuthContext);

  const [dbType, setDbType] = useState('');
  const [catalog, setCatalog] = useState('');
  const [schema, setSchema] = useState('');
  const [host, setHost] = useState('');
  const [dbName, setDbName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  // const [selectedprocessType, setSelectedprocessType] = useState('');
  // const [clientTableData, setClientTableData] = useState([]);
  // const [engagementTableData, setEngagementTableData] = useState([]);
  // const [workflowTableData, setWorkflowTableData] = useState([]);
  // const [isChecked, setIsChecked] = useState('');
  // const [engagementId, setEngagementId] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  // const fetchClientTableData = async () => {
  //   try {
  //     const response = userRole === 'Admin' ? await getAllClientsApi() : await getClientByUserGroup(userGroup);
  //     if (response.status === 200) {
  //       setClientTableData(response.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     enqueueSnackbar('Something went wrong!', {
  //       variant: 'error',
  //       autoHideDuration: 3000,
  //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
  //     });
  //   }
  // };

  // const fetchEngagementTableData = async (group, clientId) => {
  //   const { data, status } = await getEngagementByUserGroupAndClientIdApi(group, clientId);
  //   try {
  //     if (status === 200) {
  //       setEngagementTableData(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     enqueueSnackbar(error.message, {
  //       variant: 'error',
  //       autoHideDuration: 3000,
  //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
  //     });
  //   }
  // };

  // const fetchWorkflowTableData = async (group, engagementId) => {
  //   try {
  //     const { data, status } = await getWorkflowByUserGroupAndEngagementIdApi(group, engagementId);

  //     if (status === 200) {
  //       setWorkflowTableData(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     enqueueSnackbar('Something went wrong!', {
  //       variant: 'error',
  //       autoHideDuration: 3000,
  //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
  //     });
  //   }
  // };

  const fetchConnectionData = async () => {
    try {
      const response = await getConnectionData('dep_postgres');

      if (response.status === 200) {
        setDbType(response.data.db_type);
        setCatalog(response.data.catalog);
        setSchema(response.data.schema);
        setHost(response.data.host);
        setDbName(response.data.db_name);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 404) {
        enqueueSnackbar('Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const createNewConnection = async (connectionData) => {
    setLoading(true);
    try {
      console.log(connectionData);
      const response = await updateConnectionData('dep_postgres', connectionData);

      if (response.status === 200) {
        fetchConnectionData();
        enqueueSnackbar('Connection added successfully!', {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        close();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 404) {
        enqueueSnackbar('Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchConnectionData();
  }, []);

  // useEffect(() => {
  //   const clientId = clientTableData
  //     .filter((clientTableData) =>
  //       clientTableData.client_name === selectedClientName || clientTableData.client_name === client
  //         ? clientTableData.client_id
  //         : null
  //     )
  //     .map((item) => {
  //       return item.client_id;
  //     });
  //   if (clientId.length > 0) {
  //     fetchEngagementTableData(userGroup, clientId.join());
  //   }
  // }, [selectedClientName, client]);

  // useEffect(() => {
  //   const group = userGroup;
  //   console.log(engagementTableData);
  //   const engagement_id = engagementTableData
  //     .filter((engagementTableData) =>
  //       engagementTableData.engagement_name === selectedEngagementName ? engagementTableData.engagement_id : null
  //     )
  //     .map((item) => {
  //       return item.engagement_id;
  //     });
  //   if (engagement_id.length > 0) {
  //     fetchWorkflowTableData(group, engagement_id.join());
  //   }
  // }, [selectedEngagementName]);

  // useEffect(() => {
  //   setFilteredTableData(tableData.filter((tableData) => tableData.client_name.startsWith(search)));
  // }, []);

  // const handleChecked = (event) => {
  //   setIsChecked(event.target.checked);
  //   if (!event.target.checked) {
  //     setSelectedClientName('');
  //     setSelectedEngagementName('');
  //     setSelectedWorkflowName('');
  //   }
  // };

  const handleAddNewConnection = () => {
    const data = {
      db_type: dbType,
      catalog,
      schema,
      host,
      db_name: dbName,
      username,
      password,
    };
    createNewConnection(data);
  };

  // const handleExecutionMode = (event) => {
  //   setExecutionMode(event.target.value);
  // };

  const handleCancel = () => {
    setDbType('');
    setCatalog('');
    setSchema('');
    setHost('');
    setUsername('');
    setPassword('');
    close();
  };

  const handleChange = (event, source) => {
    switch (source) {
      case 'dbType': {
        setDbType(event.target.value);
        break;
      }
      case 'catalog': {
        setCatalog(event.target.value);
        // const engagement_id = engagementTableData
        //   .filter((engagementTableData) => engagementTableData.engagement_name === event.target.value)
        //   .map((item) => {
        //     return item.engagement_id;
        //   });
        // setEngagementId(engagement_id.join());
        break;
      }
      case 'schema': {
        setSchema(event.target.value);
        break;
      }
      case 'host': {
        setHost(event.target.value);
        break;
      }
      case 'dbName': {
        setDbName(event.target.value);
        break;
      }
      case 'username': {
        setUsername(event.target.value);
        break;
      }
      case 'password': {
        setPassword(event.target.value);
        break;
      }
      default:
        break;
    }
  };

  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box>
            <Typography variant="p" component="p" sx={{ fontWeight: '550', textTransform: 'uppercase' }}>
              CONNECTION DETAILS
            </Typography>
          </Box>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={4}>
              <Typography
                variant="p"
                component="p"
                display="flex"
                alignContent="flex-start"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Db Type*
              </Typography>
              <TextField
                size="small"
                required
                id="dbType"
                name="dbType"
                autoComplete="dbType"
                value={dbType}
                disabled
                sx={{ backgroundColor: '#2E5BFF14' }}
                onChange={(e) => handleChange(e, 'dbType')}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="p"
                component="p"
                display="flex"
                alignContent="flex-start"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Catalog*
              </Typography>
              <TextField
                size="small"
                required
                id="catalog"
                name="catalog"
                autoComplete="catalog"
                value={catalog}
                sx={{ backgroundColor: '#2E5BFF14' }}
                onChange={(e) => handleChange(e, 'catalog')}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="p"
                component="p"
                display="flex"
                alignContent="flex-start"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Schema*
              </Typography>
              <TextField
                size="small"
                required
                id="schema"
                name="schema"
                autoComplete="schema"
                value={schema}
                sx={{ backgroundColor: '#2E5BFF14' }}
                onChange={(e) => handleChange(e, 'schema')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={4}>
              <Typography
                variant="p"
                component="p"
                display="flex"
                alignContent="flex-start"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Host*
              </Typography>
              <TextField
                size="small"
                required
                id="host"
                name="host"
                autoComplete="host"
                value={host}
                sx={{ backgroundColor: '#2E5BFF14' }}
                onChange={(e) => handleChange(e, 'host')}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="p"
                component="p"
                display="flex"
                alignContent="flex-start"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Db Name*
              </Typography>
              <TextField
                size="small"
                required
                id="dbName"
                name="dbName"
                autoComplete="dbName"
                value={dbName}
                sx={{ backgroundColor: '#2E5BFF14' }}
                onChange={(e) => handleChange(e, 'dbName')}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="p"
                component="p"
                display="flex"
                alignContent="flex-start"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Username*
              </Typography>
              <TextField
                size="small"
                required
                id="username"
                name="username"
                autoComplete="username"
                value={username}
                sx={{ backgroundColor: '#2E5BFF14' }}
                onChange={(e) => handleChange(e, 'username')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={4}>
              <Typography
                variant="p"
                component="p"
                display="flex"
                alignContent="flex-start"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Password*
              </Typography>
              <TextField
                size="small"
                required
                id="password"
                name="password"
                autoComplete="password"
                value={password}
                sx={{ backgroundColor: '#2E5BFF14' }}
                onChange={(e) => handleChange(e, 'password')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Button
              size="small"
              type="submit"
              variant="contained"
              sx={{
                marginRight: '8px',
                backgroundColor: '#ffffff',
                '&:hover': { backgroundColor: '#ffffff' },
                color: '#00043C',
                border: '1px solid black',
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {/* {!loading ? ( */}
            <Button
              size="small"
              type="submit"
              variant="contained"
              sx={{
                marginRight: '8px',
                backgroundColor: '#00043C',
                '&:hover': { backgroundColor: '#00043C' },
                color: '#ffffff',
              }}
              onClick={handleAddNewConnection}
            >
              {!loading ? 'Add Connection' : <CircularProgress size={25} sx={{ color: '#ffffff' }} />}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
