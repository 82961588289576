import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextField, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { AllFilesOfS3, AllFoldersOfS3, executeRuleEngine, getRuleEngineListApi } from "../../api's/RuleEngineApi";
import { updateExecution } from "../../api's/ExecutionApi";
import { getWorkflowById } from "../../api's/WorkflowApi";
import { getCustomRuleApi } from "../../api's/CustomRuleApi";
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { AuthContext } from '../../contexts/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function RunModal({ workflowId, openRuleEngineModal, handleCloseRuleEngineModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const [rowLevelOperation, setRowLevelOperation] = useState({
    ignoreBlankLines: false,
    skipHeaders: false,
    skipTrailers: false,
    columnShift: true,
    junkRecords: true,
    lineBreak: true,
  });
  const { userId } = useContext(AuthContext);
  const [folders, setFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [workflow, setWorkflow] = useState({});
  const [loading, setLoading] = useState(false);
  const [delimiter, setDelimiter] = useState(',');
  const [customRules, setCustomRules] = useState([]);
  const [ruleEngineList, setRuleEngineList] = useState([]);

  const handleChange = (event) => {
    setRowLevelOperation({
      ...rowLevelOperation,
      [event.target.name]: event.target.checked,
    });
  };

  const { ignoreBlankLines, skipHeaders, skipTrailers, columnShift, junkRecords, lineBreak } = rowLevelOperation;
  const error =
    [ignoreBlankLines, skipHeaders, skipTrailers, columnShift, junkRecords, lineBreak].filter((v) => v).length !== 2;

  const version = JSON.parse(sessionStorage.getItem('version'));

  const fetchWorkflowDetails = async () => {
    const response = await getWorkflowById(workflowId);

    if (response.status === 200) {
      getAllTimestampFolders(response.data);

      if (version === '1.0') {
        const filterByVersion = response.data.filter(
          (item) => item.process_type === 'Data Ingestion' || item.process_type === 'Data Transformation'
        );
        setWorkflow(filterByVersion);
      } else {
        setWorkflow(response.data);
      }
      const result = await getCustomRuleApi();
      const ruleList = await getRuleEngineListApi(workflowId);

      if (ruleList.status === 200 && result.status === 200) {
        setCustomRules(result.data);
        setRuleEngineList(ruleList.data);
      }
    }
  };

  useEffect(() => {
    if (openRuleEngineModal) {
      fetchWorkflowDetails();
      setFiles([]);
      setFolders([]);
      setSelectedFolders('');
      setSelectedFiles('');
    }
  }, [openRuleEngineModal]);

  const getAllTimestampFolders = async (workflow) => {
    const res = await AllFoldersOfS3(
      workflow.engagement.client.client_id,
      workflow.engagement.engagement_name,
      workflow.workflow_name,
      AWS_CONFIG_TYPE
    );

    if (res.status === 200) {
      setFolders(res.data);
    }
  };

  const getAllFiles = async (prefix) => {
    setFiles([]);
    const res = await AllFilesOfS3(workflow.engagement.client.client_id, prefix, AWS_CONFIG_TYPE);

    if (res.status === 200) {
      setFiles(res.data);
    }
  };

  const pad2 = (n) => {
    return n < 10 ? `0${n}` : n;
  };

  const createRuleJson = (ruleEngineData) => {
    let main = {};

    const json = ruleEngineData.fields;
    const jsonObj = new Object();

    json.map((el) => {
      customRules.forEach((item) => {
        el.rulename &&
          el.rulename.forEach((ele) => {
            if (ele === item.rulename) {
              const argkey = item.argkey.split(',');
              const argvalue = item.argvalue.split(',');
              const obj = {};
              argkey.map((element, argkey_idx) => {
                obj[element] = argvalue[argkey_idx];
              });

              if (el.fieldrules) {
                const custfieldrules = {
                  rulename: item.rulename,
                  args: [obj],
                };

                const index = el.fieldrules.findIndex((x) => x.rulename === item.rulename);

                if (index === -1) {
                  if (el.fieldrules) {
                    el.fieldrules = [...el.fieldrules, custfieldrules];
                  } else {
                    // el.fieldrules = [custfieldrules];
                  }
                }
              } else {
                const custfieldrules = {
                  rulename: item.rulename,
                  args: [obj],
                };
                el['fieldrules'] = [custfieldrules];
              }
            }
          });
      });
    });

    console.log(ruleEngineData);
    if (ruleEngineData.jsonFields?.column_add.length > 0) {
      const newAddColArr = ruleEngineData.jsonFields.column_add.map((ele) => {
        json.map((el) => {
          if (ele.fieldName === el.fieldname) {
            ele.fieldType = el.type;
          }
        });
      });
      jsonObj['column_add'] = newAddColArr;
    }

    const swap_field = [];

    let clone2;
    if (ruleEngineData.jsonFields?.swap_field.length > 0) {
      ruleEngineData.jsonFields.swap_field.map((ele) => {
        const first_one = ele.swap_col_one.fieldname;
        const second_one = ele.swap_col_two.fieldname;
        const obj = {};

        let index1, index2;
        clone2.find((el, i) => {
          if (el.fieldname === first_one) {
            obj['swap_col_one'] = i;
            index1 = i;
          }
        });
        clone2.find((el, i) => {
          if (el.fieldname === second_one) {
            obj['swap_col_two'] = i;
            index2 = i;
          }
        });

        swap_field.push(obj);

        function swap(x, y) {
          const z = clone2[y];
          clone2[y] = clone2[x];
          clone2[x] = z;
        }
        swap(index1, index2);
      });
    }

    if (ruleEngineData.jsonFields?.swap_field.length > 0) {
      jsonObj['swap_field'] = swap_field;
    }

    const del_col_seq = [];

    if (ruleEngineData.jsonFields?.swap_field.length > 0) {
      ruleEngineData.jsonFields.del_col_seq.map((ele) => {
        clone2.map((el, i) => {
          if (ele.fieldname === el.fieldname) {
            del_col_seq.push(i);
          }
        });
      });
    }

    if (ruleEngineData.jsonFields?.del_col_seq.length > 0) {
      jsonObj['del_col_seq'] = del_col_seq;
    }

    jsonObj['columnshift'] = rowLevelOperation.columnShift;

    jsonObj['junkrecords'] = rowLevelOperation.junkRecords;

    jsonObj['linebreak'] = rowLevelOperation.lineBreak;

    jsonObj['ignoreblanklines'] = rowLevelOperation.ignoreBlankLines;

    jsonObj['skipheaders'] = rowLevelOperation.skipHeaders;

    jsonObj['skiptrailers'] = rowLevelOperation.skipTrailers;

    jsonObj['delimiter'] = delimiter || ',';

    jsonObj['workflow_id'] = workflowId;

    jsonObj['execution_mode'] = workflow.execution_mode;

    const timestamp = folders.find((el) => el.value === selectedFolders);
    const file = files.find((el) => el.value === selectedFiles);

    if (timestamp) {
      jsonObj['rule_engine_tracking_id'] = `${timestamp.label}`;
    } else {
      jsonObj['rule_engine_tracking_id'] = '';
    }

    main = {
      jsonObject: {
        fields: [...json],
        ...jsonObj,
      },
      filename: ruleEngineData.filename,
      workflow_id: ruleEngineData.workflow_id,
    };

    return main;
  };

  const runRuleEngine = async () => {
    setLoading(true);
    const ruleEngineJSON = [];

    console.log('W');
    console.log(ruleEngineList);
    ruleEngineList.forEach((item) => {
      ruleEngineJSON.push(createRuleJson(item));
    });
    console.log('sd');
    try {
      const splitFile = selectedFiles.split('/');
      const timestamp_Id = `${splitFile[5]}/${splitFile[6]}`;

      let trackingId = '';
      const date = new Date();
      trackingId = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(
        date.getDate()
      )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(date.getMilliseconds())}`;

      const batch_table_id = `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}_ruleEngine_${trackingId}`;

      const meta = {
        execution_id: batch_table_id.replaceAll(' ', ''),
        executed_by_id: userId,
        execution_engine: workflow.connectionName,
        workflow_type: 'Rule Engine',
      };

      await updateExecution(meta, workflow.workflow_id);

      const input = {
        workflow_id: workflow.workflow_id,
        execution_id: batch_table_id.replaceAll(' ', ''),
        timestamp_Id,
        rowLevelOperation,
        delimiter,
        ruleEngineJSON,
        cloudEnvironment: workflow.cloudEnvironment,
      };

      const response = await executeRuleEngine(input);

      if (response.status === 200) {
        enqueueSnackbar('Table Rule is running!', {
          variant: 'Success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar(error.response.data || 'Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    handleCloseRuleEngineModal();
    setLoading(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleCloseRuleEngineModal}
        aria-labelledby="customized-dialog-title"
        open={openRuleEngineModal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseRuleEngineModal}>
          Run Rule Engine
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={ignoreBlankLines} onChange={handleChange} name="ignoreBlankLines" />}
                  label="Ignore Blank Lines"
                />
                <FormControlLabel
                  control={<Checkbox checked={skipHeaders} onChange={handleChange} name="skipHeaders" />}
                  label="Skip Headers"
                />
                <FormControlLabel
                  control={<Checkbox checked={skipTrailers} onChange={handleChange} name="skipTrailers" />}
                  label="Skip Trailers"
                />
              </FormGroup>
            </FormControl>
            <FormControl required error={error} component="fieldset" sx={{ m: 3 }} variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={columnShift} onChange={handleChange} name="columnShift" />}
                  label="Column Shift"
                />
                <FormControlLabel
                  control={<Checkbox checked={junkRecords} onChange={handleChange} name="junkRecords" />}
                  label="Junk Records"
                />
                <FormControlLabel
                  control={<Checkbox checked={lineBreak} onChange={handleChange} name="lineBreak" />}
                  label="Line Break"
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-error"
              label="Delimiter"
              value={delimiter}
              onChange={(e) => setDelimiter(e.target.value)}
            />
            <TextField
              id="outlined-select-currency"
              select
              label="Timestamp"
              helperText="Please select timestamp"
              value={selectedFolders}
              onChange={(e) => {
                setSelectedFolders(e.target.value);
                getAllFiles(e.target.value);
              }}
              disabled={folders.length <= 0}
            >
              {folders.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          {!loading ? (
            <Button
              variant="contained"
              sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
              autoFocus
              onClick={runRuleEngine}
            >
              Run
            </Button>
          ) : (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
