import { createContext, useState } from 'react';

export const ChatGPTContext = createContext(null);

const ChatGPTProvider = ({ children }) => {
  const [chatGPTModel, setChatGPTModel] = useState('text-davinci-002');
  const [chatGPTContext, setChatGPTContext] = useState('Setting Up Presence');
  const [file, setFile] = useState('All');

  return (
    <ChatGPTContext.Provider
      value={{ chatGPTModel, setChatGPTModel, chatGPTContext, setChatGPTContext, file, setFile }}
    >
      {children}
    </ChatGPTContext.Provider>
  );
};

export default ChatGPTProvider;
