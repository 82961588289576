import { Chip } from '@mui/material';
import React from 'react';

const BlueChip = ({ title }) => {
  return (
    <Chip
      label={title}
      size="small"
      style={{
        color: '#0768AF',
        fontSize: '10px',
        backgroundColor: '#DAFAFC',
        fontWeight: 'bold',
      }}
    />
  );
};

export default BlueChip;
