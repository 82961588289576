import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const getPendingUserApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/user-mgmt/get-pending-user`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const approveUserApi = async (user_id, logged_in_user_role) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/user-mgmt/approve-user`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { user_id, logged_in_user_role },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const rejectUserApi = async (user_id, logged_in_user_role) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/user-mgmt/reject-user`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { user_id, logged_in_user_role },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const updateUserRoleApi = async (user_id, new_role) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/user-mgmt/update-user-role`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { user_id, new_role },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const createUserApi = async (data) => {
  try {
    return await axios.post(`${BASEURL}/user-mgmt/create-user`, data);
  } catch (error) {
    // if (error.response.status === 401) {
    // await logoutApi();
    // }
    console.log(error);
    throw error;
  }
};

export const authenticateUserApi = async (data) => {
  try {
    return await axios.post(`${BASEURL}/user-mgmt/authenticate`, data);
  } catch (error) {
    // if (error.response.status === 401) {
    // await logoutApi();
    // }
    console.log(error);
    throw error;
  }
};


