import React, { useEffect, useCallback } from 'react';

const debounce = (func, wait = 500) => {
    let timeout;
    function executedFunction(...args) {
        // the function returned from debounce
        const later = () => {
            func(...args); // calls the function
        };
        timeout = setTimeout(later, wait);
    }
    executedFunction.cancel = function () {
        // so can be cancelled
        clearTimeout(timeout); // clears the timeout
    };
    return executedFunction;
};

function useDebounce(callback, delay = 1000, deps = []) {
    const debouncedCallback = useCallback(debounce(callback, delay), [delay, ...deps]);

    useEffect(() => {
        return () => {
            debouncedCallback.cancel(); // cancel any pending calls
        };
    }, [delay, ...deps]);
    // return the debounce function so we can use it
    return debouncedCallback;
}

export default useDebounce;
