import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { FaEdit, FaInfoCircle, FaTrashAlt } from 'react-icons/fa';
import ReactECharts from 'echarts-for-react';
import { Tooltip } from '@mui/material';
import { updateTileTitle } from "../../api's/ReportingApi";
import ViewTable from '../reports/ViewTable';

function DoughnutChart({ props, handleDeleteTile }) {
  const [isToolsVisible, setIsToolsVisible] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isViewTable, setIsViewTable] = useState(false);
  const nameRegex = /^[a-zA-Z0-9 ]{1,50}$/;
  const titleInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const option = {
    title: {
      // text: title !== '' ? title : `Analysis of ${props?.data[0]?.yaxis_name} by ${props?.data[0]?.xaxis_name}`,
      left: 'center',
    },
    toolbox: {
      show: true,
      feature: {
        // dataZoom: {
        //   yAxisIndex: 'none',
        // },
        // dataView: {
        //   readOnly: false,
        // },
        // magicType: {
        //   type: ['line', 'bar'],
        //   show: true, // Ensure the magicType feature is shown
        // },
        restore: {},
        saveAsImage: {},
        myCustomEditTool: {
          show: true,
          title: 'Edit Title',
          icon: [
            'path://M18 2 L22 6 L12 16 L8 16 L8 12 L18 2 Z',
            'path://M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34',
          ].join(' '),
          onclick() {
            setIsEditingTitle(true);
          },
        },
        myCustomDataView: {
          show: true,
          title: 'Data View',
          icon: [
            'path://M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z',
            'path://M14 3v5h5M16 13H8M16 17H8M10 9H8',
          ].join(' '),
          onclick() {
            setIsViewTable(true);
          },
        },
        myCustomDeleteTile: {
          show: true,
          title: 'Delete Tile',
          icon: [
            'path://M3 6 L5 6 L21 6',
            'path://M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2',
            'path://M10 11 L10 17',
            'path://M14 11 L14 17',
          ].join(' '),
          onclick() {
            handleDeleteTile(props.rg_id);
          },
        },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      top: '16px',
    },
    series: [
      {
        name: 'Data',
        type: 'pie',
        radius: ['30%', '60%'],
        data: props?.data[0]?.data?.xaxis_values.map((label, index) => ({
          value: props?.data[0]?.data?.yaxis_values[index],
          name: label,
        })),
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
          },
        },
        labelLine: {
          show: false,
        },
      },
    ],
  };

  const handleEditTile = async () => {
    setIsEditingTitle(false);
    if (title.trim() === '') {
      setTitle(props.title);
      return;
    }
    if (!title.match(nameRegex)) {
      setTitle(props.title);
      if (title.length > 50) {
        enqueueSnackbar('ONLY 50 CHARACTERS ARE ALLOWED!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('NO SPECIAL CHARACTER ALLOWED!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      return;
    }

    try {
      const response = await updateTileTitle(props.rg_id, title);
    } catch (error) {
      setTitle(props.title);
      enqueueSnackbar('Error while saving Title!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (isEditingTitle && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [isEditingTitle]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setIsEditingTitle(true);
    }
  };

  return (
    <div
      style={{ position: 'relative', width: '480px', height: '400px' }}
      onMouseEnter={() => setIsToolsVisible(true)}
      onMouseLeave={() => setIsToolsVisible(false)}
    >
      {isEditingTitle ? (
        <input
          type="text"
          value={title}
          ref={titleInputRef}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={() => handleEditTile()}
          style={{
            position: 'absolute',
            top: '28px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '5px',
            fontSize: '16px',
            zIndex: 10,
          }}
        />
      ) : (
        <div
          role="button"
          tabIndex={0}
          style={{
            position: 'absolute',
            top: '26px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '5px',
            fontSize: '18px',
            cursor: 'default',
            zIndex: 10,
          }}
          onClick={() => setIsEditingTitle(true)}
          onKeyDown={handleKeyDown}
        >
          {title !== '' ? title : `Analysis of ${props?.data[0]?.yaxis_name} by ${props?.data[0]?.xaxis_name}`}
        </div>
      )}
      <ReactECharts option={option} style={{ height: 400, width: 480 }} />
      {/* {isToolsVisible && (
        <>
          <Tooltip title="Edit Title" placement="right">
            <button
              style={{
                position: 'absolute',
                top: '12%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
                padding: '0',
                outline: 'none',
              }}
              onClick={() => {
                setIsEditingTitle(true);
              }}
            >
              <FaEdit />
            </button>
          </Tooltip>
          <Tooltip title="View Data">
            <button
              style={{
                position: 'absolute',
                top: '19%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
                padding: '0',
                outline: 'none',
              }}
              onClick={() => {
                setIsViewTable(true);
              }}
            >
              <FaInfoCircle />
            </button>
          </Tooltip>
          <Tooltip title="Delete Tile">
            <button
              style={{
                position: 'absolute',
                top: '90%',
                right: '20px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
                padding: '0',
                outline: 'none',
              }}
              onClick={() => {
                handleDeleteTile(props?.rg_id);
              }}
            >
              <FaTrashAlt />
            </button>
          </Tooltip>
        </>
      )} */}

      {isViewTable && (
        <ViewTable
          props={props?.data[0]}
          fileName={props?.fileName}
          setIsViewTable={setIsViewTable}
          title={props.title}
        />
      )}
    </div>
  );
}

export default DoughnutChart;
