import axios from 'axios';
import BASEURL, { AWS_CONFIG_TYPE } from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const getDirectoryData = async (directory) => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  // console.log('directory: ', directory);
  try {
    return await axios.post(`${BASEURL}/directory/directory-content`, directory, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getSelectedFileData = async (data) => {
  console.log('selectedfileData: ', data);
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
      return await axios.post(`${res1.data.query_url}/query_${AWS_CONFIG_TYPE ? 's3' : 'adls'}`, data);
    }
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getSqlTableData = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
      return await axios.post(`${res1.data.query_url}/fetchresult`, data);
    }
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
export const getSqlQueryData = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
      return await axios.post(`${res1.data.query_url}/generatesql`, data);
    }
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
export const getChatHistoryData = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/openai/history`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const clearChatHistoryData = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
      return await axios.post(`${res1.data.query_url}/clearchat`);
    }
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const addChatGptData = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.post(`${BASEURL}/openai/add-chat-data`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const updateOpenaiData = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.put(`${BASEURL}/openai/update-openai`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getOpenaiData = async (openai_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.get(`${BASEURL}/openai/get-openai-api`, {
      params: { openai_type },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getConnectionData = async (db_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.get(`${BASEURL}/database/get-database/${db_name}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const updateConnectionData = async (db_name, data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.put(`${BASEURL}/database/update-database/${db_name}`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const englishChatGptResponse = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
      return await axios.post(`${res1.data.url}/data`, data);
    }
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const arabicChatGptResponse = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
      return await axios.post(`${res1.data.url}/talk_to_document`, data);
    }
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchChatGptFileApi = async (language) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
      return await axios.get(`${res1.data.url}/list_files`, {
        params: { language },
      });
    }
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const updateVote = async (data, id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.put(`${BASEURL}/chat-gpt/update-vote`, data, {
      params: { id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getAllModalApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/modal/get-all-modals`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const uploadFileForChatGPTApi = async (formdata) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
      return await axios.post(`${res1.data.url}/upload`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
