import React, { useState, memo, useEffect, useContext } from 'react';
import { MenuItem, TextField, Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { getDataProcessor } from "../api's/DataProcessorApi";
import { getDirectoryData } from "../api's/ChatGPTApi";
import { getAllClientsApi, getClientByUserGroup } from "../api's/ClientApi";
import { getEngagementByUserGroupAndClientIdApi } from "../api's/EngagementApi";
import { getWorkflowByUserGroupAndEngagementIdApi } from "../api's/WorkflowApi";
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { AuthContext } from '../contexts/AuthProvider';
import {
  getClientList,
  getPdfFileList,
  getDataSetList,
  getEngagementList,
  getWorkflowList,
  getFileByLanguageList,
} from "../api's/DocumentDropdownApi";

function DocumentDropdown({
  setPdfPath,
  setPath,
  setSelectedFile,
  language,
  setIsDisabled,
  formField,
  setFormField,
  INITIALSTATE,
}) {
  const [client, setClient] = useState();
  const [engagement, setEngagement] = useState();
  const [workflowData, setWorkflowData] = useState();
  const [workflow, setWorkflow] = useState();
  const [fullPath, setFullPath] = useState(null);
  const [locationPath, setlocationPath] = useState('TalkToDB');
  const [AllClient, setAllClient] = useState([]);
  const [AllBatch, setAllBatch] = useState([]);
  const [AllTable, setAllTable] = useState([]);
  const [AllPdfFile, setAllPdfFile] = useState([]);
  const [WriteTable, setWriteTable] = useState([]);
  const [IngestionWriteTable, setIngestionWriteTable] = useState([]);
  //   const [path, setPath] = useState(null);
  const { userRole, userGroup } = useContext(AuthContext);

  const handleAutoFetch = async (value) => {
    try {
      let str = value.replace(/^[^/]*\//, '');
      if (str.endsWith('/')) {
        str = str.slice(0, -1);
      }
      //   const PdfPath2 = AWS_CONFIG_TYPE
      //     ? `s3://dep-qa/${str}`
      //     : `https://depazuredev.blob.core.windows.net/dep-develop/${str}`;
      //   setPdfPath(PdfPath2);
      const data = {
        filepath: str,
        cloudEnvironment: workflow.cloudEnvironment,
      };
      const response = await getDirectoryData(data);
      if (response.status === 200) {
        const PdfObject = response.data.find((obj) => obj.filename.endsWith('.Pdf'));
        const PdfPath = AWS_CONFIG_TYPE
          ? `s3://dep-qa/${PdfObject.filepath}`
          : `https://depazuredev.blob.core.windows.net/dep-develop/${PdfObject.filepath}`;
        // const new_path = PdfObject.filepath.replace(/\/[^/]+$/, '');
        setPath(PdfPath);
        setFormField({
          ...formField,
          path: PdfPath,
        });
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      // handleResetForm();
    }
  };

  const handleOnChange = (event, source) => {
    switch (source) {
      case 'client': {
        setSelectedFile('All');
        setIsDisabled(true);
        setClient(event.target.value);
        setFormField({
          ...formField,
          clientName: event.target.value,
        });
        break;
      }
      case 'engagement': {
        setSelectedFile('All');
        setIsDisabled(true);
        setEngagement(event.target.value);
        setFormField({
          ...formField,
          batchName: event.target.value,
        });
        break;
      }
      case 'workflow': {
        setSelectedFile('All');
        setIsDisabled(false);
        setWorkflow(event.target.value);
        setFormField({
          ...formField,
          tableNameRead: event.target.value,
        });
        break;
      }
      case 'writealias': {
        setSelectedFile(event.target.value);
        setIsDisabled(false);
        setFormField({
          ...formField,
          dataSetName: event.target.value,
        });
        break;
      }
      case 'file': {
        setFormField({
          ...formField,
          file: event.target.value,
        });
        break;
      }
      case 'locationPath': {
        setlocationPath(event.target.value);
        break;
      }
      default:
        break;
    }
  };

  const getClient = async () => {
    // const response = userRole === 'SUPER_USER' ? await getAllClientsApi() : await getClientByUserGroup(userGroup);
    const response = await getClientList();
    console.log(response);
    if (response.status === 200) {
      const clients = response.data.clients;
      setAllClient(clients);
      // setAllTable([]);
    }
  };

  const getBatch = async (clientName) => {
    try {
      const response =
        // clientName === 'file_english' || clientName === 'file_arabic'
        //   ? await getFileByLanguageList(clientName) :
        await getEngagementList(clientName);
      if (response.status === 200) {
        const engagements = response.data.batches;
        setAllBatch(engagements);
      }
    } catch (error) {
      console.log(error);
      setAllBatch([]);
      setAllTable([]);
      setWriteTable([]);
    }
  };

  const getTable = async (clientName, batchName) => {
    try {
      const response = await getWorkflowList(clientName, batchName);
      if (response.status === 200) {
        const workflows = response.data.jobs;
        setAllTable(workflows);
      }
    } catch (error) {
      console.log(error);
      setAllTable([]);
      setWriteTable([]);
    }
  };

  const getWriteAlias = async (client_name, batch_name, workflow) => {
    try {
      const response = await getPdfFileList(client_name, batch_name, workflow, language);
      if (response.status === 200) {
        const datasets = response.data.pdf_files;
        // setWriteTable(datasets);
        setWriteTable(['All', ...datasets]);
      }
    } catch (error) {
      console.log(error);
      setWriteTable([]);
    }
  };

  const getPdfFiles = async (client_name, batch_name, workflow, df) => {
    try {
      const response = await getPdfFileList(client_name, batch_name, workflow, df);
      if (response.status === 200) {
        const result = response.data.pdf_files;
        setAllPdfFile(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClient();
  }, []);

  useEffect(() => {
    if (formField.clientName) {
      getBatch(formField.clientName);
      setFormField({ ...formField, batchName: '', tableNameRead: '', dataSetName: '', trackingId: '', file: '' });
    }
  }, [formField.clientName]);

  useEffect(() => {
    if (formField.batchName) {
      getTable(formField.clientName, formField.batchName);
      setFormField({ ...formField, tableNameRead: '', dataSetName: '', trackingId: '', file: '' });
    }
  }, [formField.batchName]);

  useEffect(() => {
    if (formField.tableNameRead) {
      getWriteAlias(formField.clientName, formField.batchName, formField.tableNameRead);
      setFormField({ ...formField, dataSetName: '', trackingId: '', file: '' });
    }
  }, [formField.tableNameRead, language]);

  useEffect(() => {
    if (formField.dataSetName) {
      // handleAutoFetch(formField.dataSetName);
      getPdfFiles(formField.clientName, formField.batchName, formField.tableNameRead, formField.dataSetName);
      setFormField({ ...formField, trackingId: '', file: '' });
    }
  }, [formField.dataSetName]);

  useEffect(() => {
    if (formField.file) {
      handleAutoFetch(formField.file);
      setFormField({ ...formField, trackingId: '' });
    }
  }, [formField.file]);

  useEffect(() => {
    setFormField(INITIALSTATE);
  }, []);

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
    setAllBatch([]);
    setAllTable([]);
    setWriteTable([]);
    setAllPdfFile([]);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-client"
            select
            required
            label="Client"
            name="clientName"
            value={formField.clientName}
            onChange={(e) => handleOnChange(e, 'client')}
            fullWidth
            size="small"
          >
            {AllClient &&
              AllClient.map((ele) => {
                return (
                  <MenuItem key={ele.id} value={ele}>
                    {ele}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        {formField.clientName === 'file_english' ||
          (formField.clientName === 'file_arabic' && WriteTable.length > 0 && (
            <Grid item xs={2.5} md={3}>
              <TextField
                id="dataSetName"
                select
                required
                label="File"
                name="dataSetName"
                value={formField.dataSetName}
                onChange={(e) => handleOnChange(e, 'writealias')}
                fullWidth
                size="small"
              >
                {WriteTable &&
                  WriteTable.map((ele) => {
                    return (
                      <MenuItem key={ele.id} value={ele}>
                        {ele}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
          ))}
        {formField.clientName && AllBatch.length > 0 && (
          <Grid item xs={2.5} md={3}>
            <TextField
              id="outlined-select-batch"
              select
              required
              label="Engagement"
              name="batchName"
              value={formField.batchName}
              onChange={(e) => handleOnChange(e, 'engagement')}
              fullWidth
              size="small"
            >
              {AllBatch &&
                AllBatch.map((ele) => {
                  return (
                    <MenuItem key={ele.id} value={ele}>
                      {ele}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
        )}

        {formField.batchName && AllTable.length > 0 && (
          <Grid item xs={2.5} md={3}>
            <TextField
              id="tableNameRead"
              select
              required
              label="Workflow"
              name="tableNameRead"
              value={formField.tableNameRead}
              onChange={(e) => handleOnChange(e, 'workflow')}
              fullWidth
              size="small"
            >
              {AllTable &&
                AllTable.map((ele) => {
                  return (
                    <MenuItem key={ele.id} value={ele}>
                      {ele}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
        )}

        {formField.tableNameRead && WriteTable.length > 0 && (
          <Grid item xs={2.5} md={3}>
            <TextField
              id="dataSetName"
              select
              required
              label="File"
              name="dataSetName"
              value={formField.dataSetName || 'All'}
              onChange={(e) => handleOnChange(e, 'writealias')}
              fullWidth
              size="small"
            >
              {/* <MenuItem value="All">All</MenuItem> */}
              {WriteTable &&
                WriteTable.map((ele) => {
                  return (
                    <MenuItem key={ele.id} value={ele}>
                      {ele}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
        )}

        {/* <Grid item xs={2.5} md={3}>
          <TextField
            id="file"
            select
            required
            label="File"
            name="file"
            value={formField.file}
            onChange={(e) => handleOnChange(e, 'file')}
            fullWidth
            size="small"
          >
            {AllPdfFile &&
              AllPdfFile.map((ele) => {
                return (
                  <MenuItem key={ele.id} value={ele}>
                    {ele}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default DocumentDropdown;
