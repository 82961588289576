import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const getContainerLogList = async (prefix) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-container-log-list`, {
      params: { prefix },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getContainerLogDetails = async (path) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-container-log-details`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { path },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getFlowBuilderLogStreamApi = async (prefix) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-prefixlog/dataProcessor/${prefix}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
export const getFlowBuilderLogStreamApiStatus = async (execution_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/dataProcessor/status`, {
      params: { id: execution_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getFlowBuilderLogEventApi = async (logstream) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-logs/dataProcessor/${logstream}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getFlowBuilderEmrLogListApi = async (batch, Job) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/emr-log-list/${batch}/${Job}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getFlowBuilderEmrLogClustersApi = async (logPath) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/logs/emr-log-cluster`, logPath, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getRuleEngineLogStreamApi = async (prefix) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-prefixlog/ruleEngine/${prefix}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getRuleEngineLogStreamApiStatus = async (execution_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/ruleEngine/status`, {
      params: { id: execution_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchJobStatus = async (id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/pre-processor/fetch-preprocess/${id}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 403) {
      await logoutApi();
    }

    throw error;
  }
};

export const getRuleEngineLogEventApi = async (logstream) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-logs/ruleEngine/${logstream}`, {
      params: { lambdaLog: false },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getPreprocessLogStreamApi = async (prefix) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-prefixlog/preprocessor/${prefix}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getPreprocessLogEventApi = async (logstream) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-logs/preprocessor/${logstream}`, {
      params: { lambdaLog: false },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getPreprocessLambdaLogEventApi = async (logstream) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-logs/preprocessor/${logstream}`, {
      params: { lambdaLog: true },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getPreprocessContentLogEventApi = async (logstream) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-logs/preprocessor/${logstream}`, {
      params: { contentLog: true },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getPreprocessStructureLogEventApi = async (logstream) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/get-logs/preprocessor/${logstream}`, {
      params: { structurLog: true },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getPreprocessorLogStreamApiStatus = async (executionid) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/logs/preProcessor/status`, {
      params: { id: executionid },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getAWSLogstreams = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.get(`${BASEURL}/logs/get-aws-logstreams`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getAWSLogsByLogstreams = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.get(`${BASEURL}/logs/get-aws-logs-by-logstream`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getAzureLogsList = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.get(`${BASEURL}/execution-mapping/get-mapping-by-workflowType-clientInfo`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getAzureLogsData = async (query) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.get(`${BASEURL}/log-analytics/get-logs`, {
      params: { query },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
export const getSnowflakeLogsData = async (execution_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.get(`${BASEURL}/snowflake/logs`, {
      params: { execution_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
