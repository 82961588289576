import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import SingleValueCard from './SingleValueCard';

function StatCards({ allTilesDataSet, handleDeleteTile }) {
  // const [data, setData] = useState(['a', 'b', 'c', 'd', 'e', 'f', 'g']);
  // const [data, setData] = useState([
  //   { title: 'Total Sales of Product A, Product B', content: '312', color: '#FF4D4F' },
  //   { title: 'Total Count of all products', content: '89', color: '#52C41A' },
  //   { title: 'Year 2023 revenue', content: '74', color: '#1677FF' },
  //   { title: 'Total Sales of Product D', content: '32', color: '#FF4D4F' },
  //   { title: 'Total Count of all products', content: '93', color: '#1677FF' },
  //   { title: 'Total Count of all products', content: '44', color: '#52C41A' },
  // ]);
 

  const [data, setData] = useState();
  console.log({ allTilesDataSet });
  console.log({ data });
  useEffect(() => {
    const filteredData = allTilesDataSet.filter((tile) => tile.chart_type && tile.chart_type.startsWith('Stats')).sort((a, b) => a.rg_id - b.rg_id);
    setData(filteredData);
  }, [allTilesDataSet]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    const sourceIndex = e.dataTransfer.getData('text/plain');
    const newData = [...data];
    const [draggedItem] = newData.splice(sourceIndex, 1);
    newData.splice(targetIndex, 0, draggedItem);
    setData(newData);
  };

  return (
    <>
      {/* <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
        {data.map((item, index) => (
          <div
            key={index}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            style={{
              padding: '10px',
              backgroundColor: 'lightblue',
              border: '1px solid blue',
              cursor: 'grab',
            }}
          >
            {item}
          </div>
        ))}
      </div> */}
      <Row gutter={[16, 16]} justify="center" align="middle">
        {data?.map((item, index) => (
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            justify="center"
            align="middle"
            key={item.rg_id}
            // key={index}
            // draggable
            // onDragStart={(e) => handleDragStart(e, index)}
            // onDragOver={handleDragOver}
            // onDrop={(e) => handleDrop(e, index)}
          >
            <SingleValueCard item={item} handleDeleteTile={handleDeleteTile} />
          </Col>
        ))}
      </Row>
      {/* <Row gutter={[16, 16]} justify="center" align="middle">
        {data?.map((item, index) => (
          <Col xl={4} lg={4} md={12} sm={12} xs={12} justify="center" align="middle">
            <SingleValueCard title={item.title} content={item.content} />
          </Col>
        ))}
      </Row> */}
    </>
  );
}

export default StatCards;
