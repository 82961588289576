import React, { useState, useEffect } from 'react';
import { Box, Paper, Dialog, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataLineageParent from './DataLineageParent';
import GetNodes from './DataLineageNodesFunction';
import { GetEdges } from './DataLineageEdgesFunction';

const DataLineageGraph = ({ open, handleClose }) => {
  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);

  const getNodesAndEdges = async () => {
    const allNodes = await GetNodes();
    const { allEdges, NodesProperty } = await GetEdges(allNodes);

    allNodes.map((nds) => {
      if (nds.isMainBlock) {
        // const obj = {
        //   ...nds,
        //   data: {
        //     ...nds.data,
        //     incoming: NodesProperty['Incoming'][nds.id],
        //     outgoing: NodesProperty['Outgoing'][nds.id],
        //   },
        // };
        nds.data['Incoming'] = NodesProperty['Incoming'][nds.id];
        nds.data['Outgoing'] = NodesProperty['Outgoing'][nds.id];
      }
      return nds;
    });

    setInitialNodes(allNodes);
    setInitialEdges(allEdges);
  };

  useEffect(() => {
    getNodesAndEdges();
  }, []);
  return (
    <>
      <Paper elevation={1}>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          // TransitionComponent={Transition}
        >
          <AppBar color="inherit" sx={{ position: 'sticky', top: 0 }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Data Lineage
              </Typography>
            </Toolbar>
          </AppBar>
          <Box>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 20,
                color: '#003566',
                fontWeight: 'bold',
              }}
            >
              {initialEdges.length > 0 && <DataLineageParent graphNodes={initialNodes} graphEdges={initialEdges} />}
            </div>
          </Box>
        </Dialog>
      </Paper>
    </>
  );
};

export default DataLineageGraph;
