import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import { TableCell, Button, Stack, Typography, Modal, Box, Divider, Link, Tooltip, Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
// import { TbReload } from 'react-icons/tb';
import { IoReloadSharp } from 'react-icons/io5';
import RefreshIcon from '@mui/icons-material/Refresh';
import { MdSchedule, MdKeyboardArrowDown, MdKeyboardArrowUp, MdKeyboardArrowRight } from 'react-icons/md';
import { VscDebugStart } from 'react-icons/vsc';
import { GrUnorderedList } from 'react-icons/gr';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';

import { DateConverter } from '../utils/DateConverter';
import GreenChip from './GreenChip';
import YellowChip from './YellowChip';
import RedChip from './RedChip';
import BlueChip from './BlueChip';
import BasicMenu from './BasicMenu';
import InputField from './InputField';
import { getDataProcessor, getNodeStatus, runDataProcessorApi, scheduleWorkflow } from "../api's/DataProcessorApi";
import convertToRequiredFormat from '../components/react-flow/JsonConverter';
import { fetchExecutionListApi, updateExecution } from "../api's/ExecutionApi";
import { getWorkflowById } from "../api's/WorkflowApi";
import LoadingIcon from './LoadingIcon';
import RunModal from '../components/ruleEngine/RunModal';
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { getPreprocessorById, runPreProcessorApi } from "../api's/PreprocessorApi";
import { WorkflowContext } from '../contexts/WorkflowProvider';
import FileDialog from './FileDialog';
import { AuthContext } from '../contexts/AuthProvider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: '#eee',
  color: '#000 !important',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

export default function AccordionTable({ fetchTableData, data, type, rowsPerPage, page, processType }) {
  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setWorkflow } = useContext(WorkflowContext);
  const rotationAngleRef = useRef(0);
  // const [openData, setOpenData] = useState(false);

  const handleReloadClick = () => {
    // Rotate 360 degrees clockwise on each click
    rotationAngleRef.current += 360;
    // Update the rotation directly using the ref
    document.getElementById('reloadIcon').style.transform = `rotate(${rotationAngleRef.current}deg)`;
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      background: 'none !important',
      color: '#8798AD',
      padding: '8px',
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
      textAlign: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '10px',
      textAlign: 'center',
    },
  }));

  const StyledTableRow = withStyles((theme) =>
    createStyles({
      root: {
        border: '1px solid white',
        background: '#FFFFFF',
        boxShadow: '0px 2px 6px #0000000A',
        borderRadius: '5px !important',
      },
    })
  )(TableRow);

  Row.propTypes = {
    row: PropTypes.shape({
      workflow_name: PropTypes.string.isRequired,
      process_type: PropTypes.string.isRequired,
      engagement_name: PropTypes.string.isRequired,
      client_name: PropTypes.string.isRequired,
      created_by: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      execution_mode: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      executionInfoDto: PropTypes.arrayOf(
        PropTypes.shape({
          workflow_type: PropTypes.string.isRequired,
          execution_engine: PropTypes.string.isRequired,
          last_executed_on: PropTypes.string.isRequired,
          execution_status: PropTypes.string.isRequired,
          executed_by: PropTypes.string.isRequired,
          created_by: PropTypes.string.isRequired,
          scheduled_on: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  };

  function Row(props) {
    const { enqueueSnackbar } = useSnackbar();
    const { row } = props;
    const [openData, setOpenData] = useState(false);
    const [value, setValue] = useState('30 5 5 4 1,6');
    const [open, setOpen] = React.useState(false);
    const [openRuleEngineModal, setOpenRuleEngineModal] = React.useState(false);

    const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);

    const [forceUpdate, setForceUpdate] = useState(0);

    const handleForceUpdate = () => {
      setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
      setOpenData(true);
    };

    // const memoizedData = useMemo(() => isFileDialogOpen, [isFileDialogOpen]);

    const handleOpenFileDialog = () => {
      setIsFileDialogOpen(true);
    };
    const handleCloseFileDialog = () => {
      setIsFileDialogOpen(false);
    };

    const handleClickOpenRuleEngineModal = () => {
      setOpenRuleEngineModal(true);
    };
    const handleCloseRuleEngineModal = () => {
      setOpenRuleEngineModal(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (value) => {
      setOpen(false);
    };

    const pad2 = (n) => {
      return n < 10 ? `0${n}` : n;
    };

    const type =
      row?.process_type === 'Data Quality'
        ? 'data-quality'
        : row?.process_type === 'Data Copy'
        ? 'data-copy'
        : row?.process_type === 'Data Transformation'
        ? 'data-transformation'
        : row?.process_type === 'Reporting'
        ? 'reporting'
        : row?.process_type === 'Data Ingestion'
        ? 'ingestion'
        : row?.process_type === 'Modelling & Analytics'
        ? 'modelling-analytics'
        : row?.process_type === 'XPerience360'
        ? 'xperience360'
        : row?.process_type === 'Customer Segmentation'
        ? 'customer-segmentation'
        : 'data';

    const handleViewLogs = (workflow_type) => {
      navigate(`/workflow-${type}/logs/data-processor`, {
        state: {
          workflow_id: row.workflow_id,
          workflow_name: row.workflow_name,
          client_name: row.client_name,
          engagement_name: row.engagement_name,
          // execution_mode: row.execution_mode,
          created_by: row.created_by,
          created_at: row.created_at,
          status: row.status,
          workflow_type,
          cloudEnvironment: row.cloudEnvironment,
        },
      });
    };

    const runDataProcessorWorkflow = async () => {
      setLoading(true);
      try {
        const result = await getDataProcessor(row.workflow_id, row.execution_mode);
        const fetchedWorkflow = await getWorkflowById(row.workflow_id);
        const workflowData = fetchedWorkflow.data;
        const dataProcessorJson = convertToRequiredFormat(result.data.form_data, '', '');
        const matchAndMerge = dataProcessorJson.steps.some((item) => item.method_name === 'MatchAndMerge')
          ? dataProcessorJson.steps.filter((item) => item.method_name === 'MatchAndMerge')
          : dataProcessorJson;

        const updatedDataProcessorJson = {
          name: '',
          transformer_execution_details: {},
          spark_config: {},
          udf: {},
          steps: matchAndMerge,
        };

        if (result.status === 200 && updatedDataProcessorJson) {
          let trackingId = '';
          const date = new Date();
          trackingId = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(
            date.getDate()
          )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(
            date.getMilliseconds()
          )}`;

          const batch_table_id = `${workflowData.engagement.client.client_name}_${workflowData.engagement.engagement_name}_${workflowData.workflow_name}_dataProcessor_${trackingId}`;

          const meta = row.executionInfoDto.find((ele) => ele.workflow_type === 'Data Processor');
          meta.workflow_type = 'Data Processor';
          meta.execution_engine = workflowData.connectionName;
          meta.executed_by_id = userId;
          meta.execution_id = batch_table_id.replaceAll(' ', '');

          const data = {
            client_id: workflowData.engagement.client.client_id,
            engagement_id: workflowData.engagement.engagement_id,
            engagement_name: workflowData.engagement.engagement_name,
            workflow_id: workflowData.workflow_id,
            workflow_name: workflowData.workflow_name,
            dataProcessorJson: dataProcessorJson.steps.some((item) => item.method_name === 'MatchAndMerge')
              ? updatedDataProcessorJson
              : dataProcessorJson,
            ui_time: trackingId,
            execution_id: batch_table_id.replaceAll(' ', ''),
            cloudEnvironment: workflowData.cloudEnvironment,
          };

          await updateExecution(meta, workflowData.workflow_id);
          await runDataProcessorApi(data);

          enqueueSnackbar('Workflow Running!', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      } catch (error) {
        if (error.response.status === 403) {
          enqueueSnackbar('You have only Read Permission !!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else {
          enqueueSnackbar('Internal Server Error!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
      setLoading(false);
    };

    async function runPreProcessorWorkflow() {
      const fetchedWorkflow = await getWorkflowById(row.workflow_id);
      const workflow = fetchedWorkflow.data;
      const id = `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`;

      let preprocessor;
      try {
        preprocessor = await getPreprocessorById(id);
      } catch (error) {
        enqueueSnackbar('Preprocessor not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      }

      if (preprocessor.status === 200) {
        let trackingId = '';
        const date = new Date();
        trackingId = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(
          date.getDate()
        )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(date.getMilliseconds())}`;

        const execution_id = `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}_preProcessor_${trackingId}`;

        const data = {
          workflow_id: workflow.workflow_id,
          execution_id,
          aws_config_type: AWS_CONFIG_TYPE,
          jsonArray: preprocessor.data.file_details,
          cloudEnvironment: workflow.cloudEnvironment,
        };

        const meta = row.executionInfoDto.find((ele) => ele.workflow_type === 'Pre Processor');
        meta.workflow_type = 'Pre Processor';
        meta.execution_engine = workflow.connectionName;
        meta.executed_by_id = userId;
        meta.execution_id = execution_id.replaceAll(' ', '');

        try {
          await updateExecution(meta, workflow.workflow_id);
          const response = await runPreProcessorApi(data);

          if (response.status === 200) {
            enqueueSnackbar('Preprocessor is running!', {
              variant: 'success',
              autoHideDuration: 3000,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }
        } catch (error) {
          if (error.response.status === 403) {
            enqueueSnackbar('You have only Read Permission !!', {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          } else if (error.response.status === 500) {
            enqueueSnackbar('Internal Server Error', {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }
        }
      }
    }

    const handleScheduleWorkflow = async (e) => {
      // setLoading(true);
      try {
        const result = await getDataProcessor(row.workflow_id, row.execution_mode);
        const fetchedWorkflow = await getWorkflowById(row.workflow_id);
        const workflowData = fetchedWorkflow.data;
        const dataProcessorJson = convertToRequiredFormat(result.data.form_data, '', '');
        const matchAndMerge = dataProcessorJson.steps.some((item) => item.method_name === 'MatchAndMerge')
          ? dataProcessorJson.steps.filter((item) => item.method_name === 'MatchAndMerge')
          : dataProcessorJson;

        const updatedDataProcessorJson = {
          name: '',
          transformer_execution_details: {},
          spark_config: {},
          udf: {},
          steps: matchAndMerge,
        };

        if (result.status === 200 && updatedDataProcessorJson) {
          let trackingId = '';
          const date = new Date();
          trackingId = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(
            date.getDate()
          )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(
            date.getMilliseconds()
          )}`;

          const batch_table_id = `${workflowData.engagement.client.client_name}_${
            workflowData.engagement.engagement_name
          }_${workflowData.workflow_name}_${
            workflowData.workflow_type === 'Rule Engine' ? 'ruleEngine' : 'dataProcessor'
          }_${trackingId}`;

          const dataprocessorData = {
            client_id: workflowData.engagement.client.client_id,
            engagement_id: workflowData.engagement.engagement_id,
            engagement_name: workflowData.engagement.engagement_name,
            workflow_id: workflowData.workflow_id,
            workflow_name: workflowData.workflow_name,
            dataProcessorJson: dataProcessorJson.steps.some((item) => item.method_name === 'MatchAndMerge')
              ? updatedDataProcessorJson
              : dataProcessorJson,
            ui_time: trackingId,
            execution_id: batch_table_id.replaceAll(' ', ''),
            cloudEnvironment: workflowData.cloudEnvironment,
          };

          await scheduleWorkflow(dataprocessorData, value, userId);

          // if (response.status === 200) {
          enqueueSnackbar('Workflow Scheduled!', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          // }
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          enqueueSnackbar('You have only Read Permission !!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else {
          enqueueSnackbar('Internal Server Error!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
      // setLoading(false);
    };

    const navigateToWorkflow = async (row) => {
      sessionStorage.removeItem('allNodes');
      sessionStorage.removeItem('node');
      sessionStorage.removeItem('elementCount');
      sessionStorage.removeItem('saved_node');
      sessionStorage.removeItem('edges');
      setLoading(true);
      const response = await getWorkflowById(row.workflow_id);
      setWorkflow(response.data);
      let result, nodeStatus;
      if (processType !== 'reporting') {
        try {
          result = await getDataProcessor(row.workflow_id, row.execution_mode);
          nodeStatus = await getNodeStatus(
            response.data.engagement.client.client_name,
            response.data.engagement.engagement_name,
            response.data.workflow_name,
            response.data.cloudEnvironment
          );
        } catch (error) {
          console.log(error);
        }
      }

      if (response.status === 200) {
        if (result) {
          let elemCount = 0;

          const newSavedElement = [];

          result.data.nodes.forEach((el) => {
            el['nodeId'] = `${el.nodeId}`;
            elemCount++;
            newSavedElement.push(el.id);
          });

          sessionStorage.setItem('allNodes', JSON.stringify(result.data.form_data));
          sessionStorage.setItem('elementCount', elemCount);
          sessionStorage.setItem('node', JSON.stringify(result.data.nodes));
          sessionStorage.setItem('edges', JSON.stringify(result.data.edges));
          sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
          nodeStatus && sessionStorage.setItem('nodeStatus', JSON.stringify(nodeStatus.data));
        }

        navigate(`/workflow-${type}/onboarding`, {
          state: {
            workflow_name: row.workflow_name,
            client_name: row.client_name,
            engagement_name: row.engagement_name,
            // execution_mode: row.execution_mode,
            created_by: row.created_by,
            created_at: row.created_at,
            status: row.status,
            workflow_data: response.data,
          },
        });
      }
      setLoading(false);
    };

    const handleRefreshWorkflow = async () => {
      try {
        const response = await fetchExecutionListApi(row.workflow_id);
        handleForceUpdate();
        if (response.status === 200) {
          row.executionInfoDto = response.data;
          // setOpenData(false);
          enqueueSnackbar('Status Refreshed!', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 500) {
          enqueueSnackbar('Internal Server Error', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
    };

    return (
      <>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpenData(!openData)}>
              {openData ? <MdKeyboardArrowDown size={15} /> : <MdKeyboardArrowRight size={15} />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell>
            {row.status === 'approved' ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigateToWorkflow(row);
                }}
                sx={{ textDecoration: 'none', color: '#1976D2' }}
              >
                {row.workflow_name}
              </Link>
            ) : (
              row.workflow_name
            )}
          </StyledTableCell>
          <StyledTableCell>{row.process_type}</StyledTableCell>
          <StyledTableCell>{row.engagement_name}</StyledTableCell>
          <StyledTableCell>{row.client_name}</StyledTableCell>
          {/* <StyledTableCell>{row.execution_mode}</StyledTableCell> */}
          <StyledTableCell>{row.created_by}</StyledTableCell>
          <StyledTableCell>{DateConverter(row.created_at)}</StyledTableCell>
          <StyledTableCell>
            {row.status === 'approved' ? (
              <GreenChip title="Approved" />
            ) : row.status === 'pending' ? (
              <YellowChip title="Pending" />
            ) : (
              <RedChip title="Rejected" />
            )}
          </StyledTableCell>
          <StyledTableCell>
            <BasicMenu
              row={row}
              fetchData={fetchTableData}
              type="Workflow"
              handleRefreshWorkflow={handleRefreshWorkflow}
            />
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={openData} timeout="auto" unmountOnExit>
              {row.executionInfoDto.length === 0 ? (
                <Typography
                  variant="h5"
                  component="h5"
                  gutterBottom
                  display="flex"
                  alignContent="center"
                  justifyContent="center"
                  fontWeight={600}
                >
                  No data to display
                </Typography>
              ) : (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>WORKFLOW TYPE</StyledTableCell>
                      <StyledTableCell>EXECUTION ENGINE</StyledTableCell>
                      <StyledTableCell align="left">LAST EXECUTED ON</StyledTableCell>
                      <StyledTableCell align="left">EXECUTION STATUS</StyledTableCell>
                      <StyledTableCell align="left">EXECUTED BY</StyledTableCell>
                      <StyledTableCell align="left">CREATED BY</StyledTableCell>
                      <StyledTableCell align="left">SCHEDULED FOR</StyledTableCell>
                      <StyledTableCell align="left">ACTION</StyledTableCell>
                      <StyledTableCell align="left">LOGS</StyledTableCell>
                      <StyledTableCell align="left">BI</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.executionInfoDto.map((historyRow) => (
                      <StyledTableRow key={historyRow.workflow_id}>
                        <StyledTableCell>{historyRow.workflow_type}</StyledTableCell>
                        {row.cloudEnvironment === 'SNOWFLAKE' && (
                          <StyledTableCell>{'snowflake' || '--'}</StyledTableCell>
                        )}
                        {row.cloudEnvironment === 'AZURE' && <StyledTableCell>{'data bricks' || '--'}</StyledTableCell>}
                        {row.cloudEnvironment === 'AWS' && <StyledTableCell>{'emr' || '--'}</StyledTableCell>}
                        <StyledTableCell>
                          {(historyRow.last_executed_on && DateConverter(historyRow.last_executed_on)) || '--'}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            flex: 1,
                            alignItems: 'center',
                            border: 'none',
                          }}
                        >
                          <Stack direction="row" spacing={1}>
                            {historyRow.execution_status.toLowerCase() === 'completed' ? (
                              <GreenChip title="Completed" />
                            ) : historyRow.execution_status.toLowerCase() === 'saved' ? (
                              <BlueChip title="Saved" />
                            ) : historyRow.execution_status.toLowerCase() === 'running' ? (
                              <YellowChip title="Running" />
                            ) : (
                              <RedChip title="Failed" />
                            )}
                            <Tooltip title="Refresh Status">
                              <RefreshIcon
                                onClick={handleRefreshWorkflow}
                                fontSize="small"
                                style={{ cursor: 'pointer', paddingTop: '3px' }}
                              />
                            </Tooltip>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="left">{historyRow.executed_by || '--'}</StyledTableCell>
                        <StyledTableCell align="left">{historyRow.created_by}</StyledTableCell>
                        <StyledTableCell align="left">{historyRow.scheduled_on || '--'}</StyledTableCell>
                        {/* {historyRow.workflow_type !== 'Pre Processor' && ( */}
                        <>
                          <StyledTableCell align="left">
                            <Stack direction="row" spacing={1}>
                              <Tooltip title="Run">
                                {!loading ? (
                                  <Button
                                    sx={{
                                      backgroundColor: 'white',
                                      border: 1,
                                      borderColor: 'black',
                                      maxWidth: '40px',
                                      maxHeight: '28px',
                                      minWidth: '40px',
                                      minHeight: '28px',
                                    }}
                                    onClick={() => {
                                      if (historyRow.workflow_type === 'Pre Processor') {
                                        runPreProcessorWorkflow();
                                      }

                                      if (historyRow.workflow_type === 'Data Processor') {
                                        runDataProcessorWorkflow();
                                      }

                                      if (historyRow.workflow_type === 'Rule Engine') {
                                        handleClickOpenRuleEngineModal();
                                      }
                                    }}
                                  >
                                    <VscDebugStart size={20} color="black" />
                                  </Button>
                                ) : (
                                  <LoadingButton loading variant="outlined">
                                    Submit
                                  </LoadingButton>
                                )}
                              </Tooltip>
                              <Tooltip title="Schedule">
                                <Button
                                  sx={{
                                    backgroundColor: 'white',
                                    border: 1,
                                    borderColor: 'black',
                                    maxWidth: '40px',
                                    maxHeight: '28px',
                                    minWidth: '40px',
                                    minHeight: '28px',
                                  }}
                                  onClick={handleClickOpen}
                                >
                                  <MdSchedule size={20} color="black" />
                                </Button>
                              </Tooltip>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Tooltip title="Logs">
                              <Button
                                sx={{
                                  backgroundColor: 'white',
                                  border: 1,
                                  borderColor: 'black',
                                  maxWidth: '40px',
                                  maxHeight: '28px',
                                  minWidth: '40px',
                                  minHeight: '28px',
                                }}
                                onClick={() => handleViewLogs(historyRow.workflow_type)}
                              >
                                <GrUnorderedList size={20} color="black" />
                              </Button>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Tooltip title="Business Intelligence">
                              <InsertChartOutlinedTwoToneIcon
                                style={{ display: 'flex', alignItems: 'center' }}
                                fontSize="large"
                                onClick={handleOpenFileDialog}
                              />
                              {/* <Button
                                sx={{
                                  backgroundColor: 'white',
                                  border: 1,
                                  borderColor: 'black',
                                  maxWidth: '40px',
                                  maxHeight: '28px',
                                  minWidth: '40px',
                                  minHeight: '28px',
                                }}
                                onClick={() => handleViewLogs(historyRow.workflow_type)}
                              >
                                <GrUnorderedList size={20} color="black" />
                              </Button> */}
                            </Tooltip>
                          </StyledTableCell>
                        </>
                        {/* )} */}
                        {isFileDialogOpen && (
                          <FileDialog
                            isFileDialogOpen={isFileDialogOpen}
                            handleCloseFileDialog={handleCloseFileDialog}
                            workflow={row}
                          />
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ zIndex: '1' }}
        >
          <Box sx={style}>
            <Typography variant="h6" gutterBottom>
              Schedule Workflow
            </Typography>
            <InputField label="Cron Expression" value={value} onChange={(e) => setValue(e.target.value)} />
            <Divider>OR</Divider>
            <Cron
              value={value}
              setValue={setValue}
              className="my-project-cron my-project-cron-select-dropdown my-project-cron-select my-project-cron-select-dropdown-week-days"
            />

            <Button
              size="small"
              type="submit"
              variant="contained"
              sx={{
                marginTop: '7px',
                marginRight: '7px',
                backgroundColor: '#00043C',
                '&:hover': { backgroundColor: '#00043C' },
              }}
              onClick={handleScheduleWorkflow}
            >
              Save & Submit
            </Button>
          </Box>
        </Modal>

        <RunModal
          workflowId={row.workflow_id}
          openRuleEngineModal={openRuleEngineModal}
          handleCloseRuleEngineModal={handleCloseRuleEngineModal}
        />
      </>
    );
  }

  return (
    <>
      <TableContainer>
        <Table
          aria-label="collapsible table"
          style={{ minWidth: 650, borderSpacing: '0px 4px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell> </StyledTableCell>
              <StyledTableCell>Workflow Name</StyledTableCell>
              <StyledTableCell>Process Type</StyledTableCell>
              <StyledTableCell>ENGAGEMENT NAME</StyledTableCell>
              <StyledTableCell>CLIENT NAME</StyledTableCell>
              {/* <StyledTableCell>EXECUTION MODE</StyledTableCell> */}
              <StyledTableCell>CREATED BY</StyledTableCell>
              <StyledTableCell>CREATED ON</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>ACTION</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingIcon />}
    </>
  );
}
