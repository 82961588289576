import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { approveClientApi } from "../api's/ClientApi";
import { approveDomainApi } from "../api's/DomainApi";
import { approveEngagementApi } from "../api's/EngagementApi";
import { approveUserApi } from "../api's/UserApi";
import { approveWorkflowApi } from "../api's/WorkflowApi";
import { AuthContext } from '../contexts/AuthProvider';

export default function ApproveModal({ handleApproveModal, openApproveModal, fetchData, type, id, message }) {
  const { enqueueSnackbar } = useSnackbar();
  const { userRole } = useContext(AuthContext);

  const handleApproveDomain = async () => {
    try {
      const response = await approveDomainApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Domain Approved Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleApproveModal();
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to approve domain!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.statu === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleApproveUser = async () => {
    try {
      const response = await approveUserApi(id, userRole);
      if (response.status === 200) {
        enqueueSnackbar('User Approved Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleApproveModal();
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to approve user!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 403) {
        enqueueSnackbar('You are not allowed to perform this action !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.statu === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleApproveWorkflow = async () => {
    try {
      const response = await approveWorkflowApi(id);

      if (response.status === 200) {
        enqueueSnackbar('Workflow Approved Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleApproveModal();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to approve workflow!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.statu === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleApproveEngagement = async () => {
    try {
      const response = await approveEngagementApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Engagement Approved Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleApproveModal();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to approve engagement!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.statu === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleApproveClient = async () => {
    try {
      const response = await approveClientApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Client Approved Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleApproveModal();
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to approve client!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.statu === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleOnApprove = (type) => {
    switch (type) {
      case 'user':
        return handleApproveUser();
      case 'workflow':
        return handleApproveWorkflow();
      case 'engagement':
        return handleApproveEngagement();
      case 'client':
        return handleApproveClient();
      case 'domain':
        return handleApproveDomain();
      default:
    }
  };

  return (
    <div>
      <Dialog
        open={openApproveModal}
        onClose={handleApproveModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ coloe: '#43425D' }}>
          Approval Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: '#444444' }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleApproveModal}
            sx={{ color: '#43425D', border: '1px solid #43425D' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleOnApprove(type)}
            autoFocus
            sx={{ backgroundColor: '#27CC00', '&:hover': { backgroundColor: '#27CC00' } }}
          >
            Yes, Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ApproveModal.propTypes = {
  handleApproveModal: PropTypes.func,
  openApproveModal: PropTypes.bool,
  fetchData: PropTypes.func,
  type: PropTypes.string,
  id: PropTypes.string,
  message: PropTypes.string,
};
