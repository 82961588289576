import { Box } from '@mui/material';
import React from 'react';
import { MdDone } from 'react-icons/md';

const AddClientDetailEngagement = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '30px',
        height: '30px',
        backgroundColor: props?.text === '2' && !props?.engagementIcon && !props?.clientIcon ? '#ffffff' : '#00043C',
        borderRadius: '50%',
        border: 1,
      }}
    >
      <p
        style={{
          margin: 'auto',
          color: props?.text === '2' && !props?.engagementIcon && !props?.clientIcon ? '#00043C' : '#ffffff',
          fontWeight: '700',
        }}
      >
        {(props?.clientIcon && props?.text === '1') || (props?.engagementIcon && props?.text === '2') ? (
          <MdDone />
        ) : (
          props.text
        )}
      </p>
    </Box>
  );
};

export default AddClientDetailEngagement;
