import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FiMaximize, FiMinimize } from 'react-icons/fi';
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { downloadFilesOfS3 } from "../../api's/DataProcessorApi";
import { updateTileTitle } from "../../api's/ReportingApi";
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import ReportLLM from './ReportLLM';

function ReportTable({
  data,
  rg_id,
  path,
  handleDeleteTile,
  initialTitle,
  report_workflow_id,
  tile_id,
  fullViewMode,
  setIsViewTable,
}) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const nameRegex = /^[a-zA-Z0-9 ]{1,50}$/;
  const titleInputRef = useRef(null);
  // console.log({ data });
  const handleEditTile = async () => {
    setIsEditingTitle(false);
    if (title.trim() === '') {
      setTitle(initialTitle);
      return;
    }
    if (!title.match(nameRegex)) {
      setTitle(initialTitle);
      if (title.length > 50) {
        enqueueSnackbar('ONLY 50 CHARACTERS ARE ALLOWED!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('NO SPECIAL CHARACTER ALLOWED!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      return;
    }

    try {
      const response = await updateTileTitle(rg_id, title);
    } catch (error) {
      setTitle(initialTitle);
      enqueueSnackbar('Error while saving Title!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (isEditingTitle && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [isEditingTitle]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setIsEditingTitle(true);
    }
  };

  const handleSetData = () => {
    const headers = [{ field: 'id', headerName: 'Id', width: 100 }];
    data.headers.map((el, i) => {
      headers.push({
        field: el.title,
        headerName: el.dataIndex,
        width: 150,
      });
    });
    setColumns(headers);

    const values = [];
    data.data.map((el, i) => {
      values.push({
        // id: uuidv4(),
        id: i + 1,
        ...el,
      });
    });
    setRows(values);
  };

  const handleFullScreenModal = () => {
    setIsFullScreen(!isFullScreen);
    if (fullViewMode === 'true') {
      setIsViewTable(false);
    }
  };

  useEffect(() => {
    handleSetData();
    if (fullViewMode === 'true') {
      setIsFullScreen(true);
    }
  }, []);

  const handleDownloadFile = async () => {
    enqueueSnackbar('File Downloading Started.', {
      variant: 'warning',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    const sampleCSVLocation = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${
      path.split('/')[2]
    }/Data_Processor/SampleRecords/${path.split('/')[5]}/Output/${data.fileName}`;

    const payload = {
      file_path: sampleCSVLocation,
      client_id: workflow.engagement.client.client_id,
      aws_config_type: AWS_CONFIG_TYPE,
    };
    try {
      await downloadFilesOfS3(payload);
      enqueueSnackbar('File Downloaded.', {
        variant: 'success',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Internal Server Error.', {
        variant: 'warning',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const fullscreenCSS = isFullScreen
    ? {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%', // Use full viewport height
        zIndex: '1000', // Ensure it's on top
        backgroundColor: 'white',
        padding: '16px 50px 50px 50px',
        overflowY: 'auto',
      }
    : {
        width: '500px',
        height: '400px',
        paddingBottom: '30px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        border: '1px solid lightgrey',
      };

  return (
    <Box sx={fullscreenCSS}>
      {isFullScreen && <ReportLLM report_workflow_id={report_workflow_id} tile_path={path} />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '0px',
          padding: '0 15px 0 0',
          backgroundColor: '#EFF5FB',
        }}
      >
        <span style={{ color: '#777', fontFamily: 'Raleway, sans-serif', alignSelf: 'center', margin: '0 0 0 15px' }}>
          {isEditingTitle ? (
            <input
              type="text"
              value={title}
              ref={titleInputRef}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={() => handleEditTile()}
            />
          ) : (
            <div>{title !== '' ? title : `Analysis of ${data?.yaxis_name} by ${data?.xaxis_name}`}</div>
          )}
        </span>

        <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <IconButton
            edge="end"
            onClick={() => {
              setIsEditingTitle(true);
            }}
            aria-label="fullscreen"
            sx={{ fontSize: '1rem' }}
          >
            <FaEdit />
          </IconButton>
          <IconButton edge="end" onClick={handleFullScreenModal} aria-label="fullscreen" sx={{ fontSize: '1rem' }}>
            {isFullScreen ? <FiMinimize /> : <FiMaximize />}
          </IconButton>
          <IconButton edge="end" onClick={handleDownloadFile} aria-label="fullscreen" sx={{ fontSize: '1rem' }}>
            <BsDownload />
          </IconButton>
          <IconButton
            edge="end"
            onClick={() => {
              handleDeleteTile(rg_id);
              setIsFullScreen(false);
            }}
            aria-label="close"
            sx={{ fontSize: '1rem' }}
          >
            <FaTrashAlt />
          </IconButton>
        </div>
      </div>

      <DataGrid
        rows={rows}
        columns={columns}
        sx={{
          // '& .MuiDataGrid-columnHeaders': {
          //   backgroundColor: '#1976d2', // Change this to your desired color
          //   color: '#ffffff', // Change this to your desired text color
          // },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#EFF5FB', // Change this to your desired color
            // color: '#ffffff', // Change this to your desired text color
            // marginTop: isFullScreen ? '-2em' : '',
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[15]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default ReportTable;
