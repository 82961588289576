import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Grid,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  Chip,
  CardActions,
  CardContent,
  Button,
  Stack,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TiTick } from 'react-icons/ti';
import { RxCross2 } from 'react-icons/rx';
import large_icon from '../../assets/images/large_icon.png';
import useStyles from '../renderHtmlStyles';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
// import { validationJson } from './jsonfile';

const DataValidationHtml = ({ nodeName, handleCloseModal, validationJson }) => {
  const [results, setResults] = useState([]);
  const [filter, setFilter] = useState('showAll');
  const [menu, setMenu] = useState('All');
  const { workflow } = useContext(WorkflowContext);

  const classes = useStyles();
  const myRef = useRef(null);

  const statistics = Object.entries(validationJson.statistics)?.map((item) => ({ type: item[0], value: item[1] }));

  const columns = ['All', ...validationJson.results?.map((item) => item.expectation_config.kwargs.column)];

  const handleFilter = (event) => {
    setFilter(event.target.value);
  };

  const handleMenu = (type) => {
    setMenu(type);
  };

  useEffect(() => {
    if (menu === 'All') {
      setResults(validationJson.results);
    } else {
      setResults(validationJson.results.filter((item) => item.expectation_config.kwargs.column === menu));
    }
  }, [menu]);

  useEffect(() => {
    if (filter === 'showAll') {
      setResults(validationJson.results);
    } else if (filter === 'failed') {
      setResults(validationJson.results.filter((item) => !item.success));
    }
  }, [filter]);

  return (
    <Box sx={{ padding: '10px 0 10px 20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
      <Box sx={{ paddingRight: '20px', maxWidth: 250 }}>
        <div
          style={{
            textAlign: 'center',
            height: '60px',
            padding: '15px 0',
            marginBottom: 3,
            background: '#13294b',
          }}
        >
          <img src={large_icon} alt="logo" width="100" />
        </div>
        <Card sx={{ minWidth: 200, maxHeight: '70vh', overflow: 'auto' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Table of Contents
            </Typography>

            {columns?.map((item) => (
              <CardActions>
                <Button
                  size="small"
                  onClick={() => handleMenu(item)}
                  style={{ justifyContent: 'flex-start', fontWeight: menu === item && 'bold' }}
                >
                  {item}
                </Button>
              </CardActions>
            ))}
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '85vh',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Stack
          sx={{
            position: 'sticky',
            top: -1,
            background: '#f8f8ff',
            padding: '5px',
            zIndex: 1,
          }}
        >
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={(e) => {
              e.stopPropagation();
              handleCloseModal();
            }}
            sx={{ position: 'absolute', top: 14, right: 22, background: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4">Expectation Validation Result</Typography>
          <Typography variant="body1" gutterBottom color="text.secondary" sx={{ pt: 2 }}>
            Path:
            {`${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/${nodeName}/Profiling/${validationJson.meta.validation_time}`}
          </Typography>
          <FormControl sx={{ maxWidth: 200, my: 1 }}>
            <InputLabel id="demo-simple-select-label">Validation Filter</InputLabel>
            <Select
              labelId="validationFilter"
              id="demo-simple-select-helper"
              value={filter}
              onChange={handleFilter}
              size="small"
              label="Validation Filter"
              name="Validation Filter"
              sx={{ background: '#ffffff' }}
            >
              <MenuItem value="showAll">Show All</MenuItem>
              <MenuItem value="failed">Failed Only</MenuItem>
            </Select>
          </FormControl>
          <Divider />
        </Stack>
        <Box>
          <div className={classes.mainSection} ref={myRef}>
            <Typography variant="h5" gutterBottom>
              Overview
            </Typography>
            <Typography variant="body1" gutterBottom>
              Expectation Suite: {validationJson.meta.expectation_suite_name}
              <br />
              Status: {validationJson.success ? 'Success' : 'Failed'}
            </Typography>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body1" gutterBottom sx={{ my: 1 }}>
                Statistics
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                  <TableBody>
                    {statistics?.map((row) => (
                      <TableRow key={row.type} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.type === 'success_percent' ? `${row.value?.toFixed(2)}%` : row.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          {results?.map((item) => (
            <>
              <div className={classes.mainSection} ref={myRef}>
                <Typography variant="h6" gutterBottom>
                  {item.expectation_config.kwargs.column}
                </Typography>
              </div>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Expectation</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Observed Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={item.expectation_config.kwargs.column}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.success ? <TiTick color="green" size={25} /> : <RxCross2 color="red" size={25} />}
                      </TableCell>
                      <TableCell width={650}>
                        {item.expectation_config.kwargs.min_value
                          ? `${item.expectation_config.expectation_type} ${item.expectation_config.kwargs.min_value} and ${item.expectation_config.kwargs.max_value}`
                          : item.expectation_config.expectation_type}
                        <br />
                        {item.result.unexpected_count > 0
                          ? `${
                              item.result.unexpected_count
                            } unexpected values found. ≈${item.result.unexpected_percent?.toFixed(2)}% of ${
                              item.result.element_count
                            } total rows.`
                          : ''}
                        {item.result.unexpected_count > 0 && (
                          <>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Unexpected Value</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Count</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.result.partial_unexpected_counts?.map((ele, index) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <>
                                    <TableCell>{ele.value}</TableCell>
                                    <TableCell>{ele.count}</TableCell>
                                  </>
                                </TableRow>
                              ))}
                            </TableBody>
                          </>
                        )}
                        {item.expectation_config.kwargs.regex ? (
                          <Chip size="small" label={item.expectation_config.kwargs?.regex} />
                        ) : null}
                      </TableCell>
                      <TableCell>{item.result?.unexpected_percent}% unexpected</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DataValidationHtml;
