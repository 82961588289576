import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Card,
  Stack,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  Link,
  ListItem,
  Chip,
  CardActions,
  CardContent,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  List,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import large_icon from '../../assets/images/large_icon.png';
import useStyles from '../renderHtmlStyles';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
// import { profilingJson } from './jsonfile';
import '../sidebar/sidebar.css';

// function createVariableData(variableTypes, value) {
//   return { variableTypes, value };
// }

// const variableData = [
//   createVariableData('int', 0),
//   createVariableData('float', 0),
//   createVariableData('string', 3),
//   createVariableData('datetime', 0),
//   createVariableData('bool', 0),
//   createVariableData('unknown', 0),
// ];

const DataProfilingHtml = ({ nodeName, handleCloseModal, profilingJson }) => {
  const [showExpectation, setShowExpectation] = useState(false);
  const [filter, setFilter] = useState('All');
  const [results, setResults] = useState([]);

  const { workflow } = useContext(WorkflowContext);
  const classes = useStyles();

  function calculateTotalMissingPercentage(data) {
    let totalDistinctCount = 0;
    let totalMissingCount = 0;

    // Iterate over each object in the data array
    data.forEach((columnData) => {
      const result = columnData.result;

      // Check if element_count and missing_count are not null, undefined, or NaN
      if (Number.isFinite(result.element_count) && Number.isFinite(result.missing_count)) {
        totalDistinctCount += result.element_count;
        totalMissingCount += result.missing_count;
      }
    });

    // Check if totalDistinctCount is greater than 0 to avoid division by zero
    const totalMissingPercentage = totalDistinctCount > 0 ? (totalMissingCount / totalDistinctCount) * 100 : 0;

    return totalMissingPercentage;
  }

  // Call the function and log the result
  const totalMissingPercentage = calculateTotalMissingPercentage(results);

  const columnObservedValues = {};
  const stringDataTypes = [
    'char',
    'fixedstring',
    'nchar',
    'ntext',
    'nvarchar',
    'string',
    'stringtype',
    'stringtype()',
    'text',
    'varchar',
    'str',
  ];

  const missingCountSum = profilingJson.results.reduce((sum, item) => {
    // Ensure item.result and item.result.missing_count exist before adding to the sum
    if (item.result && typeof item.result.missing_count === 'number') {
      sum += item.result.missing_count;
      // console.log(item.result.missing_count, ' ');
    }
    return sum;
  }, 0);

  // console.log('Sum of missing_count:', missingCountSum);

  profilingJson.results.forEach((result) => {
    if (result.expectation_config.kwargs.type_list) {
      const column = result.expectation_config.kwargs.column;
      const observedValue = result.result.observed_value;

      const isStringType = stringDataTypes.includes(observedValue.toLowerCase());

      columnObservedValues[column] = isStringType ? 'string' : observedValue;
    }
  });

  function createVariableData(variableType, value) {
    return { variableType, value };
  }

  const variableData = [
    createVariableData('int', 0),
    createVariableData('float', 0),
    createVariableData('string', 0),
    createVariableData('datetime', 0),
    createVariableData('bool', 0),
    createVariableData('unknown', 0),
  ];

  Object.values(columnObservedValues).forEach((dataType) => {
    const lowercasedType = dataType.toLowerCase();

    const foundType = variableData.find((entry) => entry.variableType === lowercasedType);

    if (foundType) {
      foundType.value++;
    } else if (['int', 'float', 'datetime', 'bool'].includes(lowercasedType)) {
      variableData[0].value++;
    } else if (stringDataTypes.includes(lowercasedType)) {
      variableData[2].value++;
    } else {
      variableData[5].value++;
    }
  });

  // console.log('Data type counts:', variableData);

  const handleExpectation = () => {
    setShowExpectation(!showExpectation);
  };

  const handleFilter = (type) => {
    setFilter(type);
  };

  // const filteredResults = Object.keys(profilingJson.meta.expectation_suite_meta.columns)
  //   ?.map((ele) =>
  //     profilingJson.results.filter((item) => item.expectation_config.kwargs.column === ele && !item.success)
  //   )
  //   ?.map((item) => item[0]);

  const filteredResults = profilingJson?.results.filter(
    (item) =>
      item.expectation_config.expectation_type === 'expect_column_unique_value_count_to_be_between' && item.success
  );

  const filteredResultsToBeSet = profilingJson?.results.filter(
    (item) => item.expectation_config.expectation_type === 'expect_column_values_to_be_in_set' && !item.success
  );
  console.log({ filteredResults });
  console.log({ filteredResultsToBeSet });

  const columns = ['All', ...filteredResults?.map((item) => item.expectation_config.kwargs.column)];

  const expectation = profilingJson.results?.map((item) => item.expectation_config.expectation_type);

  const expectationList = expectation.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});

  const expectationTypes = Object.entries(expectationList)?.map((item) => ({ type: item[0], value: item[1] }));

  useEffect(() => {
    if (filter === 'All') {
      setResults(filteredResults);
    } else {
      setResults(filteredResults.filter((item) => item.expectation_config.kwargs.column === filter));
    }
  }, [filter]);

  console.log({ results });
  return (
    <Box sx={{ padding: '10px 0 10px 20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
      <Box sx={{ paddingRight: '20px', maxWidth: 250 }}>
        <div
          style={{
            textAlign: 'center',
            height: '60px',
            padding: '15px 0',
            marginBottom: 3,
            background: '#13294b',
          }}
        >
          <img src={large_icon} alt="logo" width="100" />
        </div>
        <Card sx={{ minWidth: 200, maxHeight: '70vh', overflow: 'auto' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Table of Contents
            </Typography>

            {columns?.map((item) => (
              <CardActions>
                <Button
                  size="small"
                  onClick={() => handleFilter(item)}
                  style={{ justifyContent: 'flex-start', fontWeight: filter === item && 'bold' }}
                >
                  {item}
                </Button>
              </CardActions>
            ))}
          </CardContent>
        </Card>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '85vh',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Stack
          sx={{
            position: 'sticky',
            top: -1,
            background: '#f8f8ff',
            padding: '10px',
            zIndex: 1,
          }}
        >
          <IconButton
            aria-label="expand row"
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              handleCloseModal();
            }}
            sx={{ position: 'absolute', top: 14, right: 22, background: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4">Data Asset Profile</Typography>
          <Typography variant="body1" gutterBottom color="text.secondary" sx={{ pt: 2 }}>
            Path:
            {`${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/${nodeName}/Profiling/${profilingJson.meta.validation_time}`}
          </Typography>
          <Divider />
        </Stack>
        <Box>
          <div className={classes.mainSection}>
            <Typography variant="h5" gutterBottom>
              Overview
            </Typography>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body1" gutterBottom sx={{ my: 1 }}>
                Dataset Info
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Number of variables
                      </TableCell>
                      <TableCell align="right">
                        {results?.map((item) => item.expectation_config.kwargs.column).length}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Number of observations
                      </TableCell>
                      <TableCell align="right">{filteredResults[0]['result']['element_count']} </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Missing cells
                      </TableCell>
                      <TableCell align="right">
                        {totalMissingPercentage === 0 ? 0 : totalMissingPercentage?.toFixed(3)}%
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" gutterBottom sx={{ my: 1 }}>
                Variable Types
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                  <TableBody>
                    {variableData?.map((row) => (
                      <TableRow key={row.variableType} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row.variableType}
                        </TableCell>
                        <TableCell align="right">{row.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Link href="#" variant="body1" underline="none" onClick={handleExpectation} sx={{ py: 2 }}>
            Show Expectation Types...
          </Link>
          {showExpectation && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                <TableBody>
                  {expectationTypes?.map((row) => (
                    <TableRow key={row.type} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.type}
                      </TableCell>

                      <TableCell align="right">
                        <Chip label={row.value} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {results?.map((item) => (
            <>
              <div className={classes.mainSection}>
                <Typography variant="h5" gutterBottom>
                  {item.expectation_config.kwargs.column}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Type: {columnObservedValues[item.expectation_config.kwargs.column]}
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom sx={{ my: 1 }}>
                    Properties
                  </Typography>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            Distinct (n)
                          </TableCell>
                          <TableCell align="right">{item.result.observed_value}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>Distinct (%)</TableCell>
                          <TableCell align="right">
                            {Number((item.result.observed_value / item.result.element_count) * 100).toFixed(5)}%
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>Missing (n)</TableCell>
                          <TableCell align="right">{item.result.missing_count ?? 0}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>Missing (%)</TableCell>
                          <TableCell align="right">{item.result.missing_percent?.toFixed(3) ?? 0}%</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom sx={{ my: 1, ml: 2 }}>
                    Example Values
                  </Typography>
                  <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {filteredResultsToBeSet
                      .filter(
                        (new_item) =>
                          new_item.expectation_config.kwargs.column === item.expectation_config.kwargs.column
                      )
                      .map((filteredItem) => {
                        let icon;
                        return (
                          <ListItem sx={{ width: 'fit-content', paddingRight: 0 }}>
                            {filteredItem.result.partial_unexpected_list?.map((data) => (
                              <Chip
                                icon={icon}
                                label={data}
                                size="small"
                                sx={{ background: '#00043C', color: '#fff' }}
                              />
                            ))}
                          </ListItem>
                        );
                      })}
                  </List>
                </Grid>
                {/* <Grid item xs={6}> 
                 <Typography variant="body1" gutterBottom sx={{ my: 1, ml: 2 }}>
                    Example Values
                  </Typography>
                  <Paper
                    elevation={0}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      listStyle: 'none',
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {filteredResultsToBeSet
                      .filter(
                        (new_item) =>
                          new_item.expectation_config.kwargs.column === item.expectation_config.kwargs.column
                      )
                      .map((filteredItem) => {
                        let icon;
                        return (
                          <ListItem sx={{ width: 'fit-content', paddingRight: 0 }}>
                            {filteredItem.result.partial_unexpected_list?.map((data) => (
                              <Chip
                                icon={icon}
                                label={data}
                                size="small"
                                sx={{ background: '#00043C', color: '#fff' }}
                              />
                            ))}
                          </ListItem>
                        );
                      })}
                  </Paper>
                </Grid> */}
              </Grid>
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DataProfilingHtml;
