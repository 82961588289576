import { IconButton, Tooltip, Slide, Modal, Box } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ImProfile } from 'react-icons/im';
import { GrValidate } from 'react-icons/gr';
import { FiMaximize, FiMinimize } from 'react-icons/fi';
import { BsDownload } from 'react-icons/bs';
import { DataGrid } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { Handle } from 'reactflow';
import { useSnackbar } from 'notistack';
import DescriptionIcon from '@mui/icons-material/Description';
import writeIcon from '../../assets/images/pencil.png';
import ViCellIcon from '../../assets/images/vicell.png';
import DatabaseIcon from '../../assets/images/Database.png';
import openSearchIcon from '../../assets/images/openSearch.png';
import executeSqlIcon from '../../assets/images/gear.png';
import cosmosIcon from '../../assets/images/cosmos.png';
import joinIcon from '../../assets/images/link.png';
import sortIcon from '../../assets/images/sorting.png';
import filterIcon from '../../assets/images/filter.png';
import selectIcon from '../../assets/images/select.png';
import workflowIcon from '../../assets/images/workflow.png';
import emailIcon from '../../assets/images/mail.png';
import cleansingIcon from '../../assets/images/cleansing.png';
import analyticIcon from '../../assets/images/analytics.png';
import udfIcon from '../../assets/images/udf1.png';
import ApiIcon from '../../assets/images/api.png';
import coalesce from '../../assets/images/merger.png';
import unionIcon from '../../assets/images/union.png';
import datatypeIcon from '../../assets/images/datatype.png';
import concatIcon from '../../assets/images/concat.png';
import readIcon from '../../assets/images/books.png';
import readSqlIcon from '../../assets/images/sql.png';
import readOracleIcon from '../../assets/images/readOracle.png';
import readPostgresIcon from '../../assets/images/readPostgres.png';
import readCsvIcon from '../../assets/images/csv.png';
import instrumentIcon from '../../assets/images/instrument.png';
import readPdfIcon from '../../assets/images/readPdf3.png';
import copyDocIcon from '../../assets/images/copyDoc.png';
import writeSFTPIcon from '../../assets/images/writeSFTP.png';
import readPublishedDataIcon from '../../assets/images/readPublishedData.png';
import readDelimitedIcon from '../../assets/images/delimited.png';
import readMdmIcon from '../../assets/images/mdm.png';
import datetimeIcon from '../../assets/images/datetime.png';
import handleNullIcon from '../../assets/images/handlenull.png';
import ingestionWrite from '../../assets/images/Data_Ingestion.png';
import tableLevelMetadataIngestion from '../../assets/images/tableLevelMetadataIngestion.png';
import columnLevelMetadataIngestion from '../../assets/images/columnLevelMetadataIngestion.png';
import convertToRequiredFormat from './JsonConverter';
import snowflakeReadIcon from '../../assets/images/snowflake_read.png';
import kpiIcon from '../../assets/images/kpi.png';
import snowFlakeIcon from '../../assets/images/snowFlake.png';
import readRealTimeIcon from '../../assets/images/realtime-icon.png';
import trainingIcon from '../../assets/images/training.png';
import predictionIcon from '../../assets/images/prediction.png';
import profilingIcon from '../../assets/images/profiling.png';
import mathTransformationIcon from '../../assets/images/math_transformation.png';
import labelEncoderIcon from '../../assets/images/label_encoder.png';
import BenchlingWriteIcon from '../../assets/images/benchlingWrite.png';
import oneHotEncoderIcon from '../../assets/images/one_hot_encoder.png';
import dataValidationIcon from '../../assets/images/datavalidation.png';
import dropIcon from '../../assets/images/drop.png';
import outlierIcon from '../../assets/images/outlier.png';
import imbalancedDataIcon from '../../assets/images/imbalance.png';
import binningIcon from '../../assets/images/binning.png';
import scalerIcon from '../../assets/images/scaler.png';
import resampleIcon from '../../assets/images/resample.png';
import fuzzyLogicIcon from '../../assets/images/fuzzy_logic.jpg';
import custSegmentationIcon from '../../assets/images/customer-segment.png';
import analysisIcon from '../../assets/images/analysis.png';
import ruleEngineIcon from '../../assets/images/rule_engine.png';
import manualMergeIcon from '../../assets/images/merge.png';
import plotIcon from '../../assets/images/plot_icon.png';
import fileUploaderIcon from '../../assets/images/UploadFile.png';
import readFromFolder from '../../assets/images/readFromFolder.png';
import generateTranscript from '../../assets/images/generateTranscript.png';
import embedding from '../../assets/images/embedding.png';

import { WorkflowContext } from '../../contexts/WorkflowProvider';
import ResultModal from './ResultModal';
import {
  getCSVFileList,
  createSampleJsonApi,
  fetchSampleRecordsApi,
  runEmrForSampleRecordApi,
  downloadFilesOfS3,
} from "../../api's/DataProcessorApi";
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import HtmlModal from './HtmlModal';

function CustomNode({ data, isConnectable, id }) {
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);
  const [updated, setUpdated] = useState('unknown');
  const [executionTime, setExecutionTime] = useState(0);
  const [errorDetails, setErrorDetails] = useState(null);
  const [sampleData, setSampleData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [htmlData, setHtmlData] = useState('');
  const [openResultModal, setOpenResultModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [sampleViewHeader, setSampleViewHeader] = useState([]);
  const [sampleViewData, setSampleViewData] = useState([]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: '#fff',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    height: isFullScreen ? '100%' : '70%',
    width: isFullScreen ? '100%' : '70%',

    '::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },

    '::-webkit-scrollbar-track': {
      backgroundColor: '#ddd',
    },

    '::-webkit-scrollbar-thumb': {
      background: '#a4a4a4',
      borderRadius: '25px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  };

  const updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];
  const nodeStatus = JSON.parse(sessionStorage.getItem('nodeStatus')) || [];

  const [addColumnModalOpen, setAddColumnModalOpen] = useState(false);

  const handleAddColumnModalOpen = () => {
    setAddColumnModalOpen(true);
  };

  const handleAddColumnModalClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAddColumnModalOpen(false);
  };

  const handleFullScreenModal = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    nodeStatus.forEach((el) => {
      if (el.node_id === id) {
        (el.status === 'Running' || el.status === 'RUNNING') && setUpdated('running');
        (el.status === 'Completed' || el.status === 'COMPLETED') && setUpdated('completed');
        (el.status === 'Failed' || el.status === 'FAILED') && setUpdated('failed');
        setExecutionTime(el.execution_time);
        setErrorDetails(el.error_details);
      }
    });

    return () => {
      setUpdated('unknown');
    };
  }, [nodeStatus]);

  useEffect(() => {
    updateNodes.forEach((el) => {
      if (el === id) {
        setUpdated('updated');
      }
    });

    return () => {
      setUpdated('unknown');
    };
  }, [updateNodes]);

  const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

  const handleDownloadFile = async () => {
    enqueueSnackbar('File Downloading Started.', {
      variant: 'warning',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    const sampleCSVLocation = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Data_Processor/SampleRecords/${id}/Output/${sampleData.fileName}`;

    const data = {
      file_path: sampleCSVLocation,
      client_id: workflow.engagement.client.client_id,
      aws_config_type: AWS_CONFIG_TYPE,
    };
    try {
      await downloadFilesOfS3(data);
      enqueueSnackbar('File Downloaded.', {
        variant: 'success',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Internal Server Error.', {
        variant: 'warning',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const fetchSampleView = async (type) => {
    enqueueSnackbar('We are fetching result.', {
      variant: 'warning',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    setSampleData({});

    // if (type === 'run-emr') {
    //   enqueueSnackbar('EMR is running! Result available in few minutes', {
    //     variant: 'warning',
    //     autoHideDuration: 3000,
    //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //   });
    // }
    const getAllDataFromLocalStorage = JSON.parse(sessionStorage.getItem('allNodes'));

    const nodeExist = getAllDataFromLocalStorage.findIndex((el) => el.nodeId === id);

    if (nodeExist === -1) {
      enqueueSnackbar("Data for this node doesn't exist!!", {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    // const sourceList = [id];
    // getNodesID(id, sourceList);

    // const nodesData = [];

    // sourceList.forEach((item) => {
    //   const nodes = getAllDataFromLocalStorage.find((el) => el.nodeId === item);
    //   if (nodes) nodesData.push(nodes);
    // });

    // nodesData.sort((a, b) => a.y_axis - b.y_axis);

    // let count = 1;
    // nodesData.forEach((el) => {
    //   el.formField.step_no = count;
    //   count++;
    // });

    // nodesData.sort((a, b) => a.formField.step_no - b.formField.step_no);

    // const formData = convertToRequiredFormat(nodesData, '', '', id);

    // if (formData.steps[formData.steps.length - 1] === null) {
    //   formData.steps.pop();
    // }

    const sampleCSVLocation = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Data_Processor/SampleRecords/${id}/Output/`;
    // const sampleCSVLocation = `Delivery/TruckArrival/TruckArrival/Data_Processor/output/20221207135511795/part-00000-d688c11c-827d-4a61-820d-b1621f599930-c000.csv`;

    // const execution_id = `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${
    //   workflow.workflow_name
    // }_dataProcessor_${new Date().getTime()}`;

    // const sampleJsonLocation = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Data_Processor/SampleRecords/${id}/Scripts/${id}.json`;

    const data = {
      client_id: workflow.engagement.client.client_id,
      client_name: workflow.engagement.client.client_name,
      engagement_id: workflow.engagement.engagement_id,
      engagement_name: workflow.engagement.engagement_name,
      workflow_id: workflow.workflow_id,
      workflow_name: workflow.workflow_name,
      csvPath: sampleCSVLocation,
      bucket_name: workflow.engagement.client.bucket_name,
      data_region: workflow.engagement.client.data_region,
      aws_config_type: AWS_CONFIG_TYPE,
    };

    // try {
    //   await createSampleJsonApi(data);
    // } catch (error) {
    //   console.log(error);
    //   enqueueSnackbar('Server error! Something went wrong!', {
    //     variant: 'error',
    //     autoHideDuration: 3000,
    //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //   });
    // }

    if (type === 'run-emr') {
      await runEmrForSampleRecordApi(data);
    } else {
      try {
        const response = await fetchSampleRecordsApi(data);

        setSampleData(response.data);

        const headers = [{ field: 'id', headerName: 'Id', width: 150 }];
        response.data.headers.map((el, i) => {
          headers.push({
            field: el.title,
            headerName: el.dataIndex,
            width: 150,
          });
        });

        setSampleViewHeader(headers);

        const values = [];
        response.data.data.map((el) => {
          values.push({
            id: uuidv4(),
            ...el,
          });
        });
        setSampleViewData(values);

        response.status === 200 && Object.keys(response.data).length !== 0 && handleAddColumnModalOpen();

        if (Object.keys(response.data).length === 0) {
          enqueueSnackbar('Sample view not done! Please run the flow..', {
            variant: 'warning',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          return;
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 404) {
          enqueueSnackbar('Sample view not done! Please run the flow..', {
            variant: 'warning',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }

        if (error.response.status === 403) {
          enqueueSnackbar('You have only Read Permission !!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }

        if (error.response.status === 500) {
          enqueueSnackbar(error.response.data || 'Internal Server Error !!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseResultModal = () => {
    setOpenResultModal(false);
  };

  const handleOpenModal = (e, nodeType, htmlData) => {
    let isPresent = false;
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    getAllNodes.map((el) => {
      if (el.step_name === nodeType) {
        isPresent = true;
      }
    });

    if (isPresent) {
      setOpenModal(true);
      setHtmlData(htmlData);
    } else {
      enqueueSnackbar(`${nodeType} Not Done`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleOpenResultModal = (e, nodeType) => {
    let isPresent = false;
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    getAllNodes.map((el) => {
      if (el.step_name === nodeType) {
        isPresent = true;
      }
    });

    const path = getAllNodes.find((el) => el.nodeId === id)?.formField?.path;
    const value = path?.substring(path.indexOf('dep-develop/'), path.length).replace('dep-develop/', '');
    const pathPrefix = value?.endsWith('/') ? value : `${value}/prediction`;

    if (isPresent) {
      setOpenResultModal(true);
      getFile(pathPrefix);
    } else {
      enqueueSnackbar(`${nodeType} Not Done`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getFile = async (pathPrefix) => {
    const data = {
      prefix: pathPrefix,
      client_id: workflow.engagement.client.client_id,
      aws_config_type: AWS_CONFIG_TYPE,
    };
    try {
      setLoading(true);
      const response = await getCSVFileList(data);
      if (response.status === 200) {
        setFileList(response.data);
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Prediction Result not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const getNodesID = (nodeid, sourceList) => {
    let nodeType;
    JSON.parse(sessionStorage.getItem('node')).forEach((el) => {
      if (nodeid === el.id) {
        nodeType = el.data.initNode;
      }
    });

    if (nodeType === 'read') {
      sourceList.push(nodeid);
      return sourceList;
    }

    const edgesFromLocalStorage = JSON.parse(sessionStorage.getItem('edges'));
    const sourceid = [];

    edgesFromLocalStorage.forEach((el) => {
      if (el.target === nodeid) {
        sourceid.push(el.source);
      }
    });

    sourceid.forEach((el) => {
      sourceList.push(el);
      getNodesID(el, sourceList);
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '20px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {data.initNode === 'DataValidation' && (
          <div>
            <Tooltip title="Data Profiling" placement="left">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  // data.onNodesChange([{ id, type: 'remove' }]);
                  handleOpenModal(e, data.initNode, 'profiling');
                }}
              >
                <ImProfile style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Validation Result" placement="right">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  // data.onNodesChange([{ id, type: 'remove' }]);
                  handleOpenModal(e, data.initNode, 'validation');
                }}
              >
                <GrValidate style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {data.initNode === 'Training' && (
          <div>
            <Tooltip title="Train Result" placement="right">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  // data.onNodesChange([{ id, type: 'remove' }]);
                  handleOpenModal(e, data.initNode, 'training');
                }}
              >
                <ImProfile style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {/* {data.initNode === 'Prediction' && (
          <div>
            <Tooltip title="Prediction Result" placement="right">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  // data.onNodesChange([{ id, type: 'remove' }]);
                  handleOpenResultModal(e, data.initNode);
                }}
              >
                <AiOutlineFileText style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        )} */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '10px 20px',
            border: '3px solid ',
            borderRadius: '5px',
            borderColor: `${
              (updated === 'unknown' && '#ddd') ||
              (updated === 'updated' && '#7400b8') ||
              (updated === 'completed' && '#70e000') ||
              (updated === 'running' && '#fb8500') ||
              (updated === 'failed' && '#d00000')
            }`,
            alignItems: 'center',
          }}
        >
          <Tooltip
            title={
              <>
                <div style={{ whiteSpace: 'pre-line' }}>{data.label}</div>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {executionTime === 0 ? `` : `Execution Time: ${executionTime} ms`}
                </div>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {errorDetails === '' || errorDetails === null ? `` : `Error: ${errorDetails}`}
                </div>
              </>
            }
            placement="top"
          >
            <div>
              {data.initNode !== 'CopyDoc' && (
                <Handle
                  type="target"
                  position="left"
                  style={{ padding: '7px', backgroundColor: '#aaa' }}
                  onConnect={(params) => console.log('', params)}
                  isConnectable={isConnectable}
                />
              )}
              {data.initNode === 'Read' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={readIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'ReadCSV' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readCsvIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Instrument' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={instrumentIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'ReadPDF' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readPdfIcon}
                    width="50"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '0px', borderRadius: '25px' }}
                  />
                </div>
              )}
              {data.initNode === 'CopyDoc' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={copyDocIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'ReadPublishedData' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readPublishedDataIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'ReadStreaming' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readDelimitedIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'ReadDelimited' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readDelimitedIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'ReadOracle' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readOracleIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'ReadPostgres' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readPostgresIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'ReadCosmos' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={cosmosIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'ReadApi' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={ApiIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'ReadMySql' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readSqlIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'ReadSnowflake' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={snowflakeReadIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Read Real-Time' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readRealTimeIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Read MDM' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readMdmIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Write' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={writeIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'WriteToBenchling' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={BenchlingWriteIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'AutoRefresh' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={BenchlingWriteIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'WriteSFTP' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={writeSFTPIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}{' '}
              {data.initNode === 'SnowFlake' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={snowFlakeIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'OpenSearch' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={openSearchIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Postgres' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={DatabaseIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'C360' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={analyticIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Execute SQL' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={executeSqlIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'KPI' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={kpiIcon} width="45" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '5px' }} />
                </div>
              )}
              {data.initNode === 'Join' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={joinIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Sort' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={sortIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Filter' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={filterIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Union' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={unionIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Email' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={emailIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'Data Cleansing' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={cleansingIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Aggregation' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={selectIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'ViCell' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={ViCellIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Source' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={workflowIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Plot' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={plotIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {/* data.initNode !== 'C360' &&
                data.initNode !== 'Write' &&
                data.initNode !== 'SnowFlake' &&
                data.initNode !== 'OpenSearch' &&
                data.initNode !== 'Ingestion Write' &&
                data.initNode !== 'Postgres' && ( */}
              {data.initNode !== 'CopyDoc' && (
                <Handle
                  type="source"
                  position="right"
                  id="a"
                  style={{
                    padding: '7px',
                    backgroundColor: '#aaa',
                  }}
                  isConnectable={isConnectable}
                />
              )}
              {data.initNode === 'Udf' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={udfIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'Coalesce' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={coalesce} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                  {/* <p style={{ color: '#aaa' }}>{data.initNode}</p> */}
                </div>
              )}
              {data.initNode === 'OneHot Encoder' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={oneHotEncoderIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Label Encoder' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={labelEncoderIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Drop' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={dropIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'Outlier' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={outlierIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Imbalanced Data' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={imbalancedDataIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Datatype Conversion' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={datatypeIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Concat' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={concatIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'Date Time Feature' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={datetimeIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Handle Null' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={handleNullIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Ingestion Write' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={ingestionWrite}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Ingest Table Metadata' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={tableLevelMetadataIngestion}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Ingest Column Metadata' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={columnLevelMetadataIngestion}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Training' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={trainingIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Prediction' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={predictionIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Binning' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={binningIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Scaler' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={scalerIcon} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'Resample' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={resampleIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Profiling' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={profilingIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Math Transformation' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={mathTransformationIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'DataValidation' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={dataValidationIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Data Cleaning & Validation' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={ruleEngineIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Fuzzy Match' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={fuzzyLogicIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Match & Merge' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={manualMergeIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Customer Segmentation' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={custSegmentationIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Analysis' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={analysisIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'FileUploader' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={fileUploaderIcon}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Generate Transcript' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={generateTranscript}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
              {data.initNode === 'Embedding' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={embedding} width="30" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                </div>
              )}
              {data.initNode === 'Read From Folder' && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={readFromFolder}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px' }}
                  />
                </div>
              )}
            </div>
          </Tooltip>
        </div>
        {sessionStorage.getItem('isLabelVisible') === 'true' && (
          <Tooltip
            title={data.label}
            // slotProps={{
            //   popper: {
            //     sx: {
            //       [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
            //         marginBottom: '70px',
            //       },
            //     },
            //   },
            // }}
            placement="right"
          >
            <label
              style={{
                display: 'inline-block',
                maxWidth: '100px', // Adjust as needed
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {data.label}
            </label>
          </Tooltip>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Tooltip title="Delete" placement="left">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                data.onNodesChange([{ id, type: 'remove' }]);
              }}
            >
              <DeleteIcon style={{ fontSize: '20px' }} />
            </IconButton>
          </Tooltip>
          {/* {updated === 'completed' &&
            data.initNode !== 'Match & Merge' &&
            data.initNode !== 'DataValidation' &&
            data.initNode !== 'Training' && ( */}
          <Tooltip title="Sample View" placement="right">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                fetchSampleView('fetch-result');
                // handleOpenModal(e, data.initNode, 'sample_view');
              }}
            >
              <DescriptionIcon style={{ fontSize: '20px' }} />
            </IconButton>
          </Tooltip>
          {/* )} */}
        </div>
      </div>
      <Modal
        open={addColumnModalOpen}
        onClose={(e) => {
          handleAddColumnModalClose(e);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', columnGap: '10px' }}>
            <h2>
              Last Modified: <span style={{ fontSize: '16px' }}> {sampleData.ModifiedDate}</span>
            </h2>
            <Tooltip title="Sample View" placement="right">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  fetchSampleView('run-emr');
                }}
                style={{ padding: '5px 10px !important', border: '1px solid', marginLeft: 'auto' }}
              >
                <RestartAltIcon fontSize="14px" />
              </IconButton>
            </Tooltip>
            <IconButton edge="end" onClick={handleFullScreenModal} aria-label="fullscreen">
              {isFullScreen ? <FiMinimize /> : <FiMaximize />}
            </IconButton>
            <IconButton edge="end" onClick={handleDownloadFile} aria-label="fullscreen">
              <BsDownload />
            </IconButton>
            <IconButton
              edge="end"
              onClick={(e) => {
                handleAddColumnModalClose(e);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={sampleViewData}
              columns={sampleViewHeader}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[15]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      </Modal>
      {openModal && (
        <HtmlModal openModal={openModal} handleCloseModal={handleCloseModal} htmlData={htmlData} nodeId={id} />
      )}
      {
        <ResultModal
          openResultModal={openResultModal}
          handleCloseResultModal={handleCloseResultModal}
          fileList={fileList}
          loading={loading}
          setLoading={setLoading}
        />
      }
    </>
  );
}

export default memo(CustomNode);
