import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkflowContext } from '../../../contexts/WorkflowProvider';
import PageHeaderTable from '../../../reusable-component/PageHeaderTable';
import WorkflowTabs from '../../../reusable-component/WorkflowTabs';
import { CLIENT_ONBOARD } from '../../../utils/commonConstants';
import { AuthContext } from '../../../contexts/AuthProvider';
import LoadingIcon from '../../../reusable-component/LoadingIcon';

const WorkflowOnboard = ({ headerTableData, processType }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setWorkflow } = useContext(WorkflowContext);
  const { domainName } = useContext(AuthContext);
  const headerData = {
    firstHeader: 'WORKFLOW',
    secondHeader: 'DOMAIN',
    thirdHeader: 'CLIENT',
    fourthHeader: 'ENGAGEMENT',
    fifthHeader: 'CREATED BY',
    sixthHeader: 'CREATED ON',
    seventhHeader: 'STATUS',
    firstHeaderText: headerTableData?.workflow_name,
    secondHeaderText: headerTableData?.workflow_data.engagement.client.depDomainEntity.domain_name,
    thirdHeaderText: headerTableData?.client_name,
    fourthHeaderText: headerTableData?.engagement_name,
    fifthHeaderText: headerTableData?.created_by,
    sixthHeaderText: headerTableData?.created_at,
    seventhHeaderText: headerTableData?.status,
    workflow: headerTableData?.workflow_data,
    domainName,
    route: `/workflow-${processType}`,
  };

  const handleWorkflowContext = () => {
    setWorkflow(headerData.workflow);
  };

  useEffect(() => {
    handleWorkflowContext();
  }, []);

  return (
    <>
      <Box>
        <PageHeaderTable data={headerData} />
        <Box sx={{ width: '100%', marginTop: 2 }}>
          <WorkflowTabs processType={processType} />
        </Box>
        {/* <Grid container direction="row" display="flex" mt={1}>
        <Grid item xs={6} direction="row" justifyContent="flex-start">
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
            onClick={() => navigate('/workflow')}
          >
            {CLIENT_ONBOARD.cancelButton}
          </Button>
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
            onClick={() => navigate('/workflow')}
          >
            {CLIENT_ONBOARD.previousButton}
          </Button>
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
            onClick={() => navigate('/client')}
          >
            skip
          </Button>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
          >
            {CLIENT_ONBOARD.saveAndNextButton}
          </Button>
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
          >
            Preview
          </Button>
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginTop: '7px',
              marginRight: '7px',
              backgroundColor: '#00043C',
              '&:hover': { backgroundColor: '#00043C' },
            }}
          >
            {CLIENT_ONBOARD.submitButton}
          </Button>
        </Grid>
      </Grid> */}
      </Box>
      {loading && <LoadingIcon />}
    </>
  );
};

export default WorkflowOnboard;
