export const TRANSFORMATION_RULES = {
  data: [
    {
      ruleId: '01bcd171-563c-4f6c-bc36-67c835a0c921',
      ruleName: 'extract_prefix_udf',
      backendRuleName: 'extract_prefix_udf',
      createdOn: '2024-06-07T09:26:41.990789Z',
    },
    {
      ruleId: '03bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_experiment_udf',
      backendRuleName: 'extract_experiment_udf',
      createdOn: '2024-06-07T09:26:41.990789Z',
    },
    {
      ruleId: '04bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_infection_day_udf',
      backendRuleName: 'extract_infection_day_udf',
      createdOn: '2024-06-07T09:26:41.990789Z',
    },
    {
      ruleId: '05bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_passage_number_udf',
      backendRuleName: 'extract_passage_number_udf',
      createdOn: '2024-06-07T09:26:41.990789Z',
    },
    {
      ruleId: '06bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_revision_number_udf',
      backendRuleName: 'extract_revision_number_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '07bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_infection_flag_udf',
      backendRuleName: 'extract_infection_flag_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '08bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_vessel_id_udf',
      backendRuleName: 'extract_vessel_id_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '09bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_vessel_type_udf',
      backendRuleName: 'extract_vessel_type_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '12bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_skip_entry_udf',
      backendRuleName: 'extract_skip_entry_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '14bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_infection_hour_udf',
      backendRuleName: 'extract_infection_hour_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '15bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_program_udf',
      backendRuleName: 'extract_program_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '16bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_seed_train_udf',
      backendRuleName: 'extract_seed_train_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '02bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_and_write_udf',
      backendRuleName: 'extract_and_write_udf',
      createdOn: '2024-06-07T09:26:41.990789Z',
    },
    {
      ruleId: '10bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_condition_id_udf',
      backendRuleName: 'extract_condition_id_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '11bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_experiment_day_udf',
      backendRuleName: 'extract_experiment_day_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '17bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'extract_osm_udf',
      backendRuleName: 'extract_osm_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
    {
      ruleId: '18bcd171-563c-4f6c-bc36-66c835a0c921',
      ruleName: 'date_time_udf',
      backendRuleName: 'date_time_udf',
      createdOn: '2024-07-31T05:55:12.128186Z',
    },
  ],
};
