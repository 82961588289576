import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Paper, Typography, Tab, Tabs, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiGrid from '@mui/material/Grid';
import { MdAdd } from 'react-icons/md';
import { getEngagementByUserGroupAndClientIdApi } from "../../api's/EngagementApi";
import { getWorkflowByUserGroupAndClientIdApi } from "../../api's/WorkflowApi";
import { CLIENT_PAGE, ENGAGEMENT_PAGE, WORKFLOW_PAGE } from '../../utils/commonConstants';
import SearchAndFilter from '../../reusable-component/SearchAndFilter';
import EngagementTable from '../../components/Client/EngagementTable';
import ClientNoData from '../../components/Client/ClientNoData';
import WorkflowTable from '../../components/Client/WorkflowTable';
import AddEngagementModal from '../../reusable-component/AddEngagementModal';
import PageHeaderTable from '../../reusable-component/PageHeaderTable';
import AddWorkflowModal from '../../reusable-component/AddWorkflowModal';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { AuthContext } from '../../contexts/AuthProvider';
import AccessModal from '../../reusable-component/AccessModal';
import useDebounce from '../../hooks/useDebounce';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ClientDetails({ headerTableData }) {
  const [mode, setMode] = useState('');
  const [status, setStatus] = useState(() => {
    const storedValue = sessionStorage.getItem('filtered_clientdetails_status');
    return storedValue !== null ? storedValue : '';
  });
  const [search, setSearch] = useState(() => {
    const storedValue = sessionStorage.getItem('filtered_clientdetails_search');
    return storedValue !== null ? storedValue : '';
  });
  const [status1, setStatus1] = useState(() => {
    const storedValue = sessionStorage.getItem('filtered_clientdetails_status1');
    return storedValue !== null ? storedValue : '';
  });
  const [search1, setSearch1] = useState(() => {
    const storedValue = sessionStorage.getItem('filtered_clientdetails_search1');
    return storedValue !== null ? storedValue : '';
  });
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem('tab_value_clientDetails');
    return storedValue !== null ? parseInt(storedValue, 10) : 0;
  });
  const [tableData, setTableData] = useState([]);
  const [workflowTableData, setWorkflowTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filteredWorkflowTableData, setFilteredWorkflowTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openWorkflowModal, setOpenWorkflowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const { userRole, userGroup, domainName } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  // const {
  //   state: { clientRow = [] },
  // } = useLocation();
  const handleOpenModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleWorkflowModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenWorkflowModal(!openWorkflowModal);
    }
  };

  const handleSearch = () => {
    console.log('Hii');
  };

  const handleMode = (event) => {
    setMode(event.target.value);
  };

  const handleStatus = (event) => {
    if (value === 0) {
      sessionStorage.setItem('filtered_clientdetails_status', event.target.value);
      setStatus(event.target.value);
    } else if (value === 1) {
      sessionStorage.setItem('filtered_clientdetails_status1', event.target.value);
      setStatus1(event.target.value);
    }
  };

  const handleOnChange = (event) => {
    if (value === 0) {
      sessionStorage.setItem('filtered_clientdetails_search', event.target.value);
      setSearch(event.target.value);
    } else if (value === 1) {
      sessionStorage.setItem('filtered_clientdetails_search1', event.target.value);
      setSearch1(event.target.value);
    }
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem('filtered_clientdetails_status');
    sessionStorage.removeItem('filtered_clientdetails_search');
    sessionStorage.removeItem('filtered_clientdetails_status1');
    sessionStorage.removeItem('filtered_clientdetails_search1');
    setMode('');
    setStatus('');
    setSearch('');
    setStatus1('');
    setSearch1('');
    setFilteredTableData(tableData);
    setFilteredWorkflowTableData(workflowTableData);
    setPage(1);
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };
  const fetchFilterWorkflowTableData = (responseTableData) => {
    const filtered_status = sessionStorage.getItem('filtered_clientdetails_status1') || '';
    const filtered_search = sessionStorage.getItem('filtered_clientdetails_search1') || '';
    const filtered_search_lowercase = filtered_search.toLowerCase() || '';

    const filteredData = responseTableData.filter((data) => {
      return (
        (!filtered_status || data.status === filtered_status) &&
        (!filtered_search || data.workflow_name.toLowerCase().startsWith(filtered_search_lowercase))
      );
    });

    setFilteredWorkflowTableData(filteredData);
    setPage(1);
  };

  const fetchFilterTableData = (responseTableData) => {
    const filtered_status = sessionStorage.getItem('filtered_clientdetails_status') || '';
    const filtered_search = sessionStorage.getItem('filtered_clientdetails_search') || '';
    const filtered_search_lowercase = filtered_search.toLowerCase() || '';

    const filteredData = responseTableData.filter((data) => {
      return (
        (!filtered_status || data.status === filtered_status) &&
        (!filtered_search || data.engagement_name.toLowerCase().startsWith(filtered_search_lowercase))
      );
    });

    setFilteredTableData(filteredData);
    setPage(1);
  };

  const fetchTableData = async () => {
    setLoading(true);
    const response = await getEngagementByUserGroupAndClientIdApi(userGroup, headerTableData.client_id);
    try {
      if (response.status === 200) {
        setTableData(response.data);
        // setFilteredTableData(response.data);
        fetchFilterTableData(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Engagement not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
    fetchWorkflowTableData();
  }, []);

  useEffect(() => {
    const filtered_status = sessionStorage.getItem('filtered_clientdetails_status') || '';
    const filtered_search = sessionStorage.getItem('filtered_clientdetails_search') || '';
    setStatus(filtered_status);
    setSearch(filtered_search);
  }, []);

  const debouncedFilteredData = useDebounce(fetchFilterTableData, 500, [search, status, tableData]);

  useEffect(() => {
    debouncedFilteredData(tableData);
  }, [debouncedFilteredData]);

  useEffect(() => {
    const filtered_status = sessionStorage.getItem('filtered_clientdetails_status1') || '';
    const filtered_search = sessionStorage.getItem('filtered_clientdetails_search1') || '';
    setStatus1(filtered_status);
    setSearch1(filtered_search);
  }, []);

  const debouncedWorkflowFilteredData = useDebounce(fetchFilterWorkflowTableData, 500, [
    search1,
    status1,
    workflowTableData,
  ]);

  useEffect(() => {
    debouncedWorkflowFilteredData(workflowTableData);
  }, [debouncedWorkflowFilteredData]);

  // useEffect(() => {
  //   status
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.status === status))
  //     : setFilteredTableData(tableData);
  // }, [status, tableData]);

  // useEffect(() => {
  //   setFilteredTableData(tableData.filter((tableData) => tableData.engagement_name.startsWith(search)));
  // }, [search, tableData]);

  // useEffect(() => {
  //   mode
  //     ? setFilteredWorkflowTableData(
  //         workflowTableData.filter((tableData) => tableData.execution_mode === mode && tableData.status === status)
  //       )
  //     : status
  //     ? setFilteredWorkflowTableData(workflowTableData.filter((tableData) => tableData.status === status))
  //     : setFilteredWorkflowTableData(workflowTableData);
  // }, [status, workflowTableData]);

  // useEffect(() => {
  //   status
  //     ? setFilteredWorkflowTableData(
  //         workflowTableData.filter((tableData) => tableData.execution_mode === mode && tableData.status === status)
  //       )
  //     : mode
  //     ? setFilteredWorkflowTableData(workflowTableData.filter((tableData) => tableData.execution_mode === mode))
  //     : setFilteredWorkflowTableData(workflowTableData);
  // }, [mode, workflowTableData]);

  // useEffect(() => {
  //   setFilteredWorkflowTableData(workflowTableData.filter((tableData) => tableData.workflow_name.startsWith(search)));
  // }, [search, workflowTableData]);

  const version = JSON.parse(sessionStorage.getItem('version'));

  const fetchWorkflowTableData = async () => {
    setLoading(true);
    const response = await getWorkflowByUserGroupAndClientIdApi(userGroup, headerTableData.client_id);
    try {
      if (response.status === 200) {
        if (version === '1.0') {
          const filterByVersion = response.data.filter(
            (item) => item.process_type === 'Data Ingestion' || item.process_type === 'Data Transformation'
          );
          setWorkflowTableData(filterByVersion);
          // setFilteredWorkflowTableData(filterByVersion);
          fetchFilterWorkflowTableData(filterByVersion);
        } else {
          setWorkflowTableData(response.data);
          // setFilteredWorkflowTableData(response.data);
          fetchFilterWorkflowTableData(response.data);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const Grid = styled(MuiGrid)(({ theme }) => ({
    width: '100%',
    ...theme.typography.body1,
    '& [role="separator"]': {
      margin: theme.spacing(0, 2),
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem('tab_value_clientDetails', newValue.toString());
    if (newValue === 0) {
      sessionStorage.removeItem('filtered_clientdetails_status1');
      sessionStorage.removeItem('filtered_clientdetails_search1');
      setFilteredTableData(tableData);
    } else if (newValue === 1) {
      sessionStorage.removeItem('filtered_clientdetails_status');
      sessionStorage.removeItem('filtered_clientdetails_search');
      setFilteredWorkflowTableData(workflowTableData);
    }
    setMode('');
    setStatus('');
    setSearch('');
    setStatus1('');
    setSearch1('');
  };

  const data = {
    search: value === 0 ? search : search1,
    handleSearch,
    handleOnChange,
    region: mode,
    handleRegion: handleMode,
    handleClearFilter,
    handleStatus,
    status: value === 0 ? status : status1,
    firstFilterText: ENGAGEMENT_PAGE.engagementFilterByMode,
    secondFilterText: CLIENT_PAGE.clientFilterByStatus,
    clearFilterText: CLIENT_PAGE.clientClearFilterButton,
    firstFilterValues: {
      first: 'Real Time',
      second: 'Batch',
    },
    secondFilterValues: {
      filterData: ['approved', 'pending'],
    },
    // isFirstFilterVisible: value === 1,
    isFirstFilterVisible: false,
    isSecondFilterVisible: true,
    isThirdFilterVisible: false,
  };

  const headerData = {
    firstHeader: 'CLIENT',
    secondHeader: 'REGION',
    thirdHeader: 'TOTAL ENGAGEMENTS',
    fourthHeader: 'TOTAL WORKFLOWS',
    fifthHeader: 'CREATED BY',
    sixthHeader: 'CREATED ON',
    seventhHeader: 'STATUS',
    firstHeaderText: headerTableData.client_name,
    secondHeaderText: headerTableData.region,
    thirdHeaderText: headerTableData.engagement_count,
    fourthHeaderText: headerTableData.workflow_count,
    fifthHeaderText: headerTableData.created_by,
    sixthHeaderText: headerTableData.created_at,
    seventhHeaderText: headerTableData.status,
    domainName,
    route: '/client',
  };

  return (
    <Box>
      <PageHeaderTable data={headerData} />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 1.5,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#47D7AC',
            },
          }}
          sx={{ '& .MuiTab-root.Mui-selected': { color: '#00043C' } }}
        >
          <Tab label={`Engagements(${tableData.length})`} {...a11yProps(0)} sx={{ fontWeight: 'bold' }} />
          <Tab label={`Workflows(${workflowTableData.length})`} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          <Grid container direction="row" display="flex">
            <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
              {ENGAGEMENT_PAGE.engagementPageTitle}
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
                onClick={handleOpenModal}
              >
                <MdAdd />
                {ENGAGEMENT_PAGE.addNewEngagementButton}
              </Button>
            </Grid>
          </Grid>
          {tableData.length > 0 && <SearchAndFilter data={data} />}
          {filteredTableData.length !== 0 ? (
            <EngagementTable
              tableData={filteredTableData}
              fetchTableData={fetchTableData}
              currentPage={page}
              setPage={setPage}
            />
          ) : (
            !loading && (
              <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                <ClientNoData
                  title={ENGAGEMENT_PAGE.engagementPageNoDataToDisplay}
                  text={ENGAGEMENT_PAGE.noEngagementToReview}
                  addBtnText={ENGAGEMENT_PAGE.addNewEngagementButton}
                  handleOnClick={handleOpenModal}
                  isAddBtn
                />
              </Paper>
            )
          )}
          {loading && <LoadingIcon />}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container direction="row" display="flex">
            <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
              {WORKFLOW_PAGE.workflowPageTitle}
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
                onClick={handleWorkflowModal}
              >
                <MdAdd />
                Add New Workflow
              </Button>
            </Grid>
          </Grid>
          <AddWorkflowModal
            handleModal={handleWorkflowModal}
            open={openWorkflowModal}
            fetchTableData={fetchWorkflowTableData}
            processType="Ingestion"
          />
          {workflowTableData.length > 0 && <SearchAndFilter data={data} />}
          {filteredWorkflowTableData.length !== 0 ? (
            <WorkflowTable
              tableData={filteredWorkflowTableData}
              fetchWorkflowTableData={fetchWorkflowTableData}
              currentPage={page}
              setPage={setPage}
            />
          ) : (
            <Paper elevation={3} sx={{ height: '330px', marginTop: '10px' }}>
              <ClientNoData
                title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
                text={WORKFLOW_PAGE.noWorkflowToReview}
                addBtnText={WORKFLOW_PAGE.addNewWorkflowButton}
                handleOnClick={handleWorkflowModal}
                isAddBtn
              />
            </Paper>
          )}
          {loading && <LoadingIcon />}
        </TabPanel>
        <AddEngagementModal handleCloseModal={handleCloseModal} openModal={openModal} fetchData={fetchTableData} />
        <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
      </Box>
    </Box>
  );
}
