export const LOGIN_FORM = {
  formLabelDefault: 'Sign In',
  formLabelFreeSignIn: 'Get started for free',
  formLabelDefaultText: 'Plese enter your credentials to proceed.',
  formLabelText: 'Free forever. No credit card needed.',
  formInputUserName: 'USERNAME',
  formInputEmail: 'EMAIL ID',
  formInputFullName: 'FULL NAME',
  formInputPassword: 'PASSWORD',
  formForgotPassword: 'Forgot password ?',
  formInputAccessRights: 'ACCESS RIGHTS',
  formInputAccessRightsOptionReadOnly: 'Reader',
  formInputAccessRightsOptionReadAndExecuter: 'Executer',
  formInputAccessRightsOptionReadExecuterAndEdit: 'Read, Executer & Edit',
  formInputAccessRightsOptionAdmin: 'Admin',
  formTermsAndCondition: 'By registering, I agree to Data Engineering Platform',
  formTermsOfService: ' Terms of Service ',
  and: 'and',
  privacyPolicy: ' Privacy Policy.',
  havingAccount: 'Already have an account? ',
  notHavingAccount: "Don't have an account? ",
  formSignIn: 'Sign In',
  formSignUp: 'Sign Up',
  formCreateAccount: 'Create Account',
  formAWS: 'AWS',
  formAzure: 'Azure',
  formSnowflake: 'Snowflake',
};

export const CLIENT_PAGE = {
  clientPageTitle: 'All Clients',
  addNewClientButton: 'Add New Client',
  clientFilterByDomain: 'Filter by Domain',
  clientFilterByRegion: 'Filter by Region',
  clientFilterByStatus: 'Filter by Status',
  clientClearFilterButton: 'Clear Filter',
  clientPageNoDataToDisplay: 'No data to display!',
  noClientToReview: 'You don’t have any clients to review. Start adding client to see the data.',
};

export const DOMAIN_PAGE = {
  domainPageTitle: 'All Domains',
  addNewDomainButton: 'Add New Domain',
  addDomainModal: 'Add Domain',
  domainFilterByRegion: 'Filter by Region',
  domainFilterByStatus: 'Filter by Status',
  domainClearFilterButton: 'Clear Filter',
  domainPageNoDataToDisplay: 'No data to display!',
  noDomainToReview: 'You don’t have any domains to review. Start adding domain to see the data.',
};

export const WORKFLOW_PAGE = {
  workflowPageTitle: 'All Workflows',
  addNewWorkflowButton: 'Add New Workflow',
  workflowFilterByEngagement: 'Filter by Engagement',
  workflowFilterByDomain: 'Filter by Domain',
  workflowFilterByClient: 'Filter by Client',
  workflowClearFilterButton: 'Clear Filter',
  workflowPageNoDataToDisplay: 'No data to display!',
  noWorkflowToReview: 'You don’t have any workflows to review. Start adding workflow to see the data.',
  workflowTabTitle: 'Workflows',
};

export const CLIENT_ONBOARD = {
  clientOnboardTitle: 'Client Onboarding',
  clientOnboardText: 'This information will be sent to Admin for approval.',
  clientDetails: 'Client Details',
  engagementDetail: 'Engagement Detail',
  clientDetailText: 'Please fill the details and click on save & next to proceed to next step.',
  engagementDetailText: 'Please fill the details and click on submit to send the details for admin approval.',
  formInputClientName: 'CLIENT NAME',
  formInputDataRegion: 'DATA REGION',
  formInputDomain: 'DOMAIN TYPE',
  formInputEngagementType: 'ENGAGEMENT TYPE',
  formInputEngagementName: 'ENGAGEMENT NAME',
  previousButton: 'Previous',
  cancelButton: 'Cancel',
  saveAndNextButton: 'Save & Next',
  submitButton: 'Submit',
};

export const CLIENT_MODAL = {
  clientModalTitle: 'Client Submitted Successfully !',
  clientModalText: 'You will be notified via email & notification once the client is approved.',
};

export const ENGAGEMENT_PAGE = {
  engagementPageTitle: 'All Engagements',
  engagementTabTitle: 'Engagements',
  addNewEngagementButton: 'Add New Engagement',
  addEngagementModal: 'Add Engagement',
  engagementFilterByMode: 'Filter by Mode',
  engagementPageNoDataToDisplay: 'No data to display!',
  noEngagementToReview: 'You don’t have any engagements to review. Start adding engagement to see the data.',
};

export const APPROVAL_PAGE = {
  approvalsPageTitle: 'Approvals',
};

export const CONNECTIONS_PAGE = {
  connectionsPageTitle: 'Connections',
  engagementTabTitle: 'Engagements',
  addNewconnectiontButton: 'Add New Connection',
  connectionsFilterByType: 'Filter by Type',
  connectionsFilterByUser: 'Filter by User',
  connectionModalTitle: 'Create Connection',
  connectionEditModalTitle: 'Update Connection',
};

export const USER_PAGE = {
  userPageNoDataToDisplay: 'No data to display!',
  noUserToReview: 'You don’t have any user to review. Start adding users to see the data.',
};

export const MAIN = {
  xperience360: 'XPerience360',
  dep: 'Data Engineering Platform',
};
