import React, { useEffect, useRef, useState } from 'react';
import {
  Paper,
  Stack,
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  Avatar,
} from '@mui/material';
import { FaFileUpload } from 'react-icons/fa';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import SendIcon from '@mui/icons-material/Send';
import { GiBroom } from 'react-icons/gi';
import {
  getChatHistoryData,
  addChatGptData,
  englishChatGptResponse,
  clearChatHistoryData,
  updateVote,
  arabicChatGptResponse,
  fetchChatGptFileApi,
} from "../../api's/ChatGPTApi";
import Feedback from './Feedback';
import question from '../../assets/images/question_1.png';
import chatGPTLogo from '../../assets/images/ChatGPT_logo.png';
import useStyles from './ChatGPTQueryCss';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import UploadModal from './UploadModal';
import DocumentDropdown from '../../reusable-component/DocumentDropdown';

const INITIALSTATE = {
  path: '',
  format: 'Pdf',
  alias: '',
  clientName: '',
  batchName: '',
  tableNameRead: '',
  dataSetName: 'All',
  trackingId: '',
  file: '',
};

const ChatGPTQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [chatHistoryData, setChatHistoryData] = useState([]);
  const [Question, setQuestion] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [feedbackType, setFeedbackType] = useState('');
  const [comment, setComment] = useState('');
  const [answerId, setAnswerId] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('English');
  const [selectedFile, setSelectedFile] = useState('All');
  const [fileList, SetFileList] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formField, setFormField] = useState(INITIALSTATE);
  const classes = useStyles();

  const theme = process.env.REACT_APP_THEME;

  const handleUploadPDF = () => {
    setOpenUploadModal(true);
  };

  const handleCloseModal = () => {
    setOpenUploadModal(false);
    fetchFiles();
  };

  const handleModal = (type) => {
    setOpenModal(!openModal);
    setFeedbackType(type);
    setComment('');
  };

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.value);
  };

  const getChatGptHistory = async () => {
    setLoading(true);
    try {
      const response = await getChatHistoryData();
      if (response.status === 200) {
        setChatHistoryData(response.data);
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const addChatGPTData = async (data) => {
    setLoading(true);

    try {
      const response = await addChatGptData(data);
      if (response.status === 201) {
        getChatGptHistory();
        setQuestion('');
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }

    setLoading(false);
  };

  const generateChatGptResponse = async (Question) => {
    setLoading(true);
    const last_text = chatHistoryData.length === 0 ? '' : chatHistoryData[chatHistoryData.length - 1].answer;
    try {
      const data = {
        text: Question,
        last_text,
      };
      const res = await englishChatGptResponse(data);
      if (res.status === 200) {
        const data1 = {
          question: Question,
          answer: res.data.result,
          tag: res.data.tag,
          last_text,
        };
        await addChatGPTData(data1);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setQuestion('');
    setLoading(false);
  };

  const generateArabicResponse = async (Question) => {
    setLoading(true);
    const last_text = chatHistoryData.length === 0 ? '' : chatHistoryData[chatHistoryData.length - 1].answer;
    try {
      const data = {
        user_prompt: Question,
        client: formField.clientName,
        engagement: formField.batchName,
        workflow: formField.tableNameRead,
        file_name: selectedFile,
        language,
      };
      const res = await arabicChatGptResponse(data);
      if (res.status === 200) {
        const data1 = {
          question: Question,
          answer: res.data.response,
          tag: res.data.tag,
          last_text,
        };
        await addChatGPTData(data1);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setQuestion('');
    setLoading(false);
  };

  const handleFetchResult = () => {
    if (language === 'English') {
      generateArabicResponse(Question);
    } else {
      generateArabicResponse(Question);
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await fetchChatGptFileApi(language);

      if (response?.status === 200) {
        SetFileList(response.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFiles();
    getChatGptHistory();
  }, [language]);

  const handleEnterPress = (event) => {
    if (event.charCode === 13) {
      handleFetchResult(Question);
    }
  };

  const handleClearChat = async () => {
    setLoading(true);
    try {
      const res = await clearChatHistoryData();
      if (res.status === 200) {
        // getChatGptHistory();
        setChatHistoryData([]);
      }
    } catch (error) {
      enqueueSnackbar('Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  const handleUpdateVote = async (type, id, voted) => {
    setLoading(true);
    if (voted) {
      return;
    }
    setAnswerId(id);
    handleModal(type);
    const data = {
      voted: true,
      vote: type,
      vote_comment: comment,
    };

    try {
      const res = await updateVote(data, id);
      if (res.status === 200) {
        getChatGptHistory();
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [chatHistoryData]);

  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <>
      {/* {console.log(selectedFile)} */}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          style={{
            flex: 5,
            margin: '0 10px 0px 0',
            paddingRight: '10px',
            borderRight: '2px solid gray',
          }}
        >
          <DocumentDropdown
            setSelectedFile={setSelectedFile}
            language={language}
            setIsDisabled={setIsDisabled}
            formField={formField}
            setFormField={setFormField}
            INITIALSTATE={INITIALSTATE}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-label" style={{ backgroundColor: 'white' }}>
              Language
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label="Language"
              size="small"
              disabled={isDisabled}
              onChange={handleLanguageChange}
              sx={{ maxWidth: '100px' }}
            >
              <MenuItem value={'English'}>English</MenuItem>
              <MenuItem value={'Arabic'}>Arabic</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-label" style={{ backgroundColor: 'white' }}>
              Files
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedFile}
              label="Files"
              size="small"
              onChange={handleFileChange}
              sx={{ minWidth: '200px' }}
            >
              <MenuItem value="All">All</MenuItem>
              {fileList &&
                fileList.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
          <Tooltip title="Upload Files">
            <Box>
              {isDisabled ? (
                <IconButton sx={{ marginRight: 1, cursor: 'default' }}>
                  <Avatar>
                    <FaFileUpload size={25} />
                  </Avatar>
                </IconButton>
              ) : (
                <IconButton sx={{ marginRight: 1 }} onClick={handleUploadPDF}>
                  <Avatar style={{ backgroundColor: '#1C88D6' }}>
                    <FaFileUpload size={25} style={{ color: 'white' }} />
                  </Avatar>
                </IconButton>
              )}
              {/* <IconButton sx={{ marginRight: 1 }} onClick={handleUploadPDF}>
                <Avatar style={{ backgroundColor: '#1C88D6' }}>
                  <FaFileUpload size={25} style={{ color: 'white' }} />
                </Avatar>
              </IconButton> */}
            </Box>
          </Tooltip>
        </div>
      </div>
      <Paper className={classes.paper}>
        <Box className={classes.scrollBar} ref={messagesEndRef}>
          <Box>
            {chatHistoryData.map((item) => (
              <Box key={item.query_id}>
                <Stack
                  direction={language === 'Arabic' ? 'row-reverse' : 'row'}
                  alignItems="flex-end"
                  spacing={1}
                  sx={{ paddingRight: '10px' }}
                >
                  <img
                    src={question}
                    width="30"
                    alt="Nagarro"
                    style={{ filter: 'contrast(200%)', margin: '10px', marginLeft: '33px' }}
                  />
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ py: 1.5, textAlign: language === 'Arabic' ? 'right' : 'left' }}
                  >
                    {item.question}
                  </Typography>
                </Stack>
                <Stack
                  direction={language === 'Arabic' ? 'row-reverse' : 'row'}
                  alignItems="flex-end"
                  spacing={1}
                  className={classes.answer}
                >
                  <Box className={classes.answerImageBox}>
                    <img
                      src={chatGPTLogo}
                      width="30"
                      alt="Nagarro"
                      style={{ filter: 'contrast(200%)', marginLeft: '25px' }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ py: 1.5, textAlign: language === 'Arabic' ? 'right' : 'left', flexGrow: 1 }}
                  >
                    {item.answer}
                  </Typography>
                </Stack>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={classes.messageBox}>
          <FormControl
            sx={{
              width: '88%',
              my: 1,
            }}
            variant="filled"
          >
            <TextField
              size="small"
              placeholder="Send a message..."
              fullWidth
              id="fullWidth"
              variant="outlined"
              className={classes.message}
              value={Question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyPress={handleEnterPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!loading ? (
                      <IconButton aria-label="send-message" onClick={handleFetchResult}>
                        <SendIcon className={classes.sendIcon} />
                      </IconButton>
                    ) : (
                      <CircularProgress size={25} className={classes.loadingIcon} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Tooltip title="Clear Chat" sx={{ marginLeft: '10px' }}>
            <IconButton aria-label="send-message" onClick={handleClearChat}>
              <GiBroom />
            </IconButton>
          </Tooltip>
        </Box>
        <Feedback
          openModal={openModal}
          handleModal={handleModal}
          type={feedbackType}
          comment={comment}
          handleComment={handleComment}
          handleUpdateVote={handleUpdateVote}
          answerId={answerId}
        />
      </Paper>
      {loading && <LoadingIcon />}
      <UploadModal
        openModal={openUploadModal}
        handleCloseModal={handleCloseModal}
        language={language}
        clientNameTTD={formField.clientName}
        engagementNameTTD={formField.batchName}
        workflowNameTTD={formField.tableNameRead}
      />
    </>
  );
};

export default ChatGPTQuery;
