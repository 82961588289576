import React, { memo } from 'react';
import { Handle } from 'reactflow';
import { Tooltip } from '@mui/material';
import filterIcon from '../../../../assets/images/filter.png';
import sortIcon from '../../../../assets/images/sorting.png';
import joinIcon from '../../../../assets/images/link.png';
import executeSqlIcon from '../../../../assets/images/gear.png';
import coalesceIcon from '../../../../assets/images/merger.png';
import unionIcon from '../../../../assets/images/union.png';
import kpiIcon from '../../../../assets/images/kpi.png';
import selectIcon from '../../../../assets/images/select.png';

const DLDataTransformNode = ({ data, isConnectable }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip
        title={
          <>
            <div style={{ whiteSpace: 'pre-line' }}>
              Incoming edges: {data.Incoming !== undefined ? data.Incoming : 0}
            </div>
            <div style={{ whiteSpace: 'pre-line' }}>
              Outgoing edges: {data.Outgoing !== undefined ? data.Outgoing : 0}
            </div>
            <div style={{ whiteSpace: 'pre-line' }}>{data.label}</div>
          </>
        }
        placement="top"
      >
        <div
          style={{
            width: '150px',
            border: '2px solid black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fcefb4',
          }}
        >
          <Handle
            type="target"
            position="left"
            onConnect={(params) => console.log('', params)}
            isConnectable={isConnectable}
          />
          <Handle type="source" position="right" isConnectable={isConnectable} />
          {data.mode === 'Filter' && (
            <>
              <img src={filterIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Sort' && (
            <>
              <img src={sortIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Join' && (
            <>
              <img src={joinIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Execute SQL' && (
            <>
              <img src={executeSqlIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'KPI' && (
            <>
              <img src={kpiIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Union' && (
            <>
              <img src={unionIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Coalesce' && (
            <>
              <img src={coalesceIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Aggregation' && (
            <>
              <img src={selectIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default memo(DLDataTransformNode);
