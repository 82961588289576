import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const deleteWorkflowApi = async (workflow_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.delete(`${BASEURL}/workflow/delete-workflow`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { workflow_id },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowByUserGroupAndEngagementIdApi = async (group, engagement_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-workflow-by-userGroup-and-engagementId`, {
      params: {
        user_group: group,
        engagement_id,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowByUserGroupAndClientIdApi = async (group, client_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-workflow-by-userGroup-and-clientId`, {
      params: {
        user_group: group,
        client_id,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowByUserGroup = async (user_group) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-workflow-by-userGroup`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { user_group },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowByUserGroupAndDomainId = async (user_group, domain_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-workflow-by-userGroup-domainId`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { user_group, domain_id },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowByUserGroupAndProcessType = async (user_group, process_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-workflow-by-userGroup-and-processType`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { user_group, process_type },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowByUserGroupAndDomainIdAndProcessType = async (user_group, domain_id, process_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-workflow-by-userGroup-and-processType-and-domainId`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { user_group, domain_id, process_type },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllWorkflowByDomainIdAndProcessType = async (domain_id, process_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-all-workflow-by-domainId-and-processType`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { domain_id, process_type },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllWorkflowByProcessType = async (process_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-all-workflow-by-processType`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { process_type },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const createWorkflowApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/workflow/create-workflow/`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowById = async (workflow_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-workflow-by-id`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { workflow_id },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getWorkflowList = async (process_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-workflow-list`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { process_type },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getPendingWorkflowApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-pending-workflow`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllWorkflowApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-all-workflow`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllWorkflowByDomainIdApi = async (domain_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/get-all-workflow-by-domainId`, {
      params: { domain_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const approveWorkflowApi = async (workflow_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/approve-workflow`, {
      params: { workflow_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const rejectWorkflowApi = async (workflow_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/workflow/reject-workflow`, {
      params: { workflow_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const copyWorkflowApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/workflow/copy-job`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
