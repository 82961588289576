import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Button } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import {
  getAllEngagementsApi,
  getEngagementByUserGroupAndDomainIdApi,
  getEngagementByUserGroupApi,
  getAllEngagementByDomainIdApi,
} from "../api's/EngagementApi";
import { getAllClientsApi } from "../api's/ClientApi";
import AllEngagementsTable from '../components/Engagements/AllEngagementsTable';
import ClientNoData from '../components/Client/ClientNoData';
import SearchAndFilter from '../reusable-component/SearchAndFilter';
import { ENGAGEMENT_PAGE, CLIENT_PAGE } from '../utils/commonConstants';
import AddEngagementModal from '../reusable-component/AddEngagementModal';
import LoadingIcon from '../reusable-component/LoadingIcon';
import { AuthContext } from '../contexts/AuthProvider';
import AccessModal from '../reusable-component/AccessModal';
import useDebounce from '../hooks/useDebounce';

const EngagementPage = () => {
  const [search, setSearch] = useState('');
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [client, setClient] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userRole, domainId, domainName, userGroup } = useContext(AuthContext);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [page, setPage] = useState(1);

  const handleOpenModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSearch = () => {
    console.log('Hii');
  };

  const handleClient = (event) => {
    sessionStorage.setItem('filtered_client', event.target.value);
    setClient(event.target.value);
  };

  const handleOnChange = (event) => {
    sessionStorage.setItem('filtered_engagement_search', event.target.value);
    setSearch(event.target.value);
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem('filtered_client');
    sessionStorage.removeItem('filtered_engagement_search');
    fetchAllEngagementsTableData();
    setClient('');
    setSearch('');
    setPage(1);
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  const fetchAllClients = async () => {
    const response = await getAllClientsApi();
    try {
      if (response.status === 200) {
        setClientData(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Engagement not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const fetchFilterTableData = (responseTableData) => {
    const filtered_client = sessionStorage.getItem('filtered_client') || '';
    const filtered_search = sessionStorage.getItem('filtered_engagement_search') || '';
    const filtered_search_lowercase = filtered_search.toLowerCase() || '';

    const filteredData = responseTableData.filter((data) => {
      return (
        (!filtered_client || data.client_name === filtered_client) &&
        (!filtered_search || data.engagement_name.toLowerCase().startsWith(filtered_search_lowercase))
      );
    });

    setFilteredTableData(filteredData);
    setPage(1);
  };

  const fetchAllEngagementsTableData = async () => {
    setLoading(true);
    // console.log(domainId);
    try {
      const response = await getAllEngagementsApi();
      if (response.status === 200) {
        // const filtered_client = sessionStorage.getItem('filtered_client') || '';
        // if (filtered_client !== '') {
        //   const client_exist = filtered_client
        //     ? response.data.filter((data) => data.client_name === filtered_client)
        //     : null;
        //   if (client_exist.length > 0) {
        //     setClient(filtered_client);

        //     setTableData(response.data);
        //     setFilteredTableData(client_exist);
        //   } else {
        //     handleClearFilter();
        //   }
        // } else {
        //   setTableData(response.data);
        //   setFilteredTableData(response.data);
        // }

        setTableData(response.data);
        fetchFilterTableData(response.data);
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Engagement not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllEngagementsTableData();
    fetchAllClients();
  }, []);

  // useEffect(() => {
  //   const filtered_client = sessionStorage.getItem('filtered_client') || '';
  //   const filtered_search = sessionStorage.getItem('filtered_engagement_search') || '';
  //   setClient(filtered_client);
  //   setSearch(filtered_search);
  //   if (filtered_client !== '' || filtered_search !== '') {
  //     fetchFilterTableData(tableData);
  //   } else {
  //     setFilteredTableData(tableData);
  //   }
  // }, [search, client]);

  useEffect(() => {
    const filtered_client = sessionStorage.getItem('filtered_client') || '';
    const filtered_search = sessionStorage.getItem('filtered_engagement_search') || '';
    setClient(filtered_client);
    setSearch(filtered_search);
  }, []);

  const debouncedFilteredData = useDebounce(fetchFilterTableData, 500, [search, client, tableData]);

  useEffect(() => {
    debouncedFilteredData(tableData);
  }, [debouncedFilteredData]);

  // useEffect(() => {
  //   client
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.client_name === client))
  //     : setFilteredTableData(tableData);
  // }, [client]);

  // useEffect(() => {
  //   setFilteredTableData(
  //     tableData.filter((tableData) => tableData.engagement_name.toLowerCase().startsWith(search.toLowerCase()))
  //   );
  // }, [search]);

  const data = {
    search,
    handleSearch,
    handleOnChange,
    handleClearFilter,
    handleStatus: handleClient,
    status: client,
    firstFilterText: ENGAGEMENT_PAGE.engagementFilterByMode,
    secondFilterText: 'Filter By Client',
    clearFilterText: CLIENT_PAGE.clientClearFilterButton,
    firstFilterValues: {
      first: 'Real time',
      second: 'Batch',
    },
    secondFilterValues: {
      // const unique = [...new Set(data.map(item => item.group))];
      filterData: [...new Set(clientData.map((item) => item.client_name))],
    },
    isFirstFilterVisible: false,
    isSecondFilterVisible: true,
    isThirdFilterVisible: false,
  };

  return (
    <>
      <Grid container direction="row" display="flex">
        <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
          {ENGAGEMENT_PAGE.engagementPageTitle}
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
            onClick={handleOpenModal}
          >
            <MdAdd />
            {ENGAGEMENT_PAGE.addNewEngagementButton}
          </Button>
        </Grid>
      </Grid>
      {tableData.length > 0 && <SearchAndFilter data={data} />}
      {filteredTableData.length === 0 ? (
        !loading && (
          <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
            <ClientNoData
              title={ENGAGEMENT_PAGE.engagementPageNoDataToDisplay}
              text={ENGAGEMENT_PAGE.noEngagementToReview}
              addBtnText={ENGAGEMENT_PAGE.addNewEngagementButton}
              handleOnClick={handleOpenModal}
              isAddBtn
            />
          </Paper>
        )
      ) : (
        <AllEngagementsTable
          tableData={filteredTableData}
          fetchAllEngagementsTableData={fetchAllEngagementsTableData}
          currentPage={page}
          setPage={setPage}
        />
      )}
      <AddEngagementModal
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        fetchData={fetchAllEngagementsTableData}
      />
      <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />

      {loading && <LoadingIcon />}
    </>
  );
};

export default EngagementPage;
