import { Box, Typography, Button } from '@mui/material';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import no_data_graphic from '../../assets/images/no_data_graphic.svg';
import AddButton from '../../reusable-component/AddButton';

export default function ClientNoData(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        height: '96%',
      }}
    >
      <img src={no_data_graphic} alt="login" style={{ height: '25%', width: '35%' }} />
      <Typography variant="h5" component="h5" gutterBottom>
        {props.title}
      </Typography>
      <Typography variant="p" component="p" width={300} gutterBottom>
        {props.text}
      </Typography>
      {props.isRoute && <AddButton text={props.addBtnText} route={props.addBtnRoute} />}
      {props.isAddBtn && (
        <Button
          type="submit"
          variant="contained"
          sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
          onClick={props.handleOnClick}
        >
          <MdAdd />
          {props.addBtnText}
        </Button>
      )}
    </Box>
  );
}
