import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, InputLabel, Select, FormControl, MenuItem, Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { MdArrowBackIosNew } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom/dist';
import { getAzureLogsData } from "../../../api's/LogsApi";
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import PaginationFooter from '../../../reusable-component/PaginationFooter';

const LogsAnalyticsData = ({ headerTableData, processType }) => {
  const [logsJson, setLogsJson] = useState([]);
  const [search, setSearch] = React.useState('');
  const [sort, setSort] = React.useState('desc');
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const rowsPerPage = 20;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchAzureLogAnalyticsData = async (query) => {
    try {
      setLoading(true);
      const response = await getAzureLogsData(query);
      if (response.status === 200) {
        setLogsJson(response.data);
        setPage(1);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('No Logs Found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 No Logs found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const query = `${headerTableData.prefix} | sort by TimeGenerated ${sort}`;
    fetchAzureLogAnalyticsData(query);
  }, []);

  const handleClearFilter = () => {
    setSearch('');
    setSort('desc');
    setStatus('');

    const query = `${headerTableData.prefix} | sort by TimeGenerated ${sort}`;
    fetchAzureLogAnalyticsData(query);
  };

  const handleChangeSearch = (e) => {
    if (e.key === 'Enter') {
      let query;

      if (status === '') {
        query = `${headerTableData.prefix} | search '${e.target.value}' | sort by TimeGenerated ${sort}`;
      } else {
        query = `union (
        ${headerTableData.prefix}
        | search '${e.target.value}'
        | sort by TimeGenerated ${sort}
    ),
    (
      ${headerTableData.prefix}
        | search '${status}'
        | sort by TimeGenerated ${sort}
    )`;
        // query = `${headerTableData.prefix} | search ${e.target.value} | search ${status} | sort by TimeGenerated ${sort}`;
      }

      fetchAzureLogAnalyticsData(query);
    }
  };

  const handleChangeSort = (e) => {
    setSort(e.target.value);
    let query;

    if (status === '' && search === '') {
      query = `${headerTableData.prefix} | sort by TimeGenerated ${e.target.value}`;
    } else if (status !== '' && search !== '') {
      query = `union (
        ${headerTableData.prefix}
        | search '${search}'
        | sort by TimeGenerated ${e.target.value}
    ),
    (
      ${headerTableData.prefix}
        | search '${status}'
        | sort by TimeGenerated ${e.target.value}
    )`;
      // query = `${headerTableData.prefix} | search ${search} | search ${status} | sort by TimeGenerated ${e.target.value}`;
    } else if (status !== '' && search === '') {
      query = `${headerTableData.prefix} | search '${status}' | sort by TimeGenerated ${e.target.value}`;
    } else if (status === '' && search !== '') {
      query = `${headerTableData.prefix} | search '${search}' | sort by TimeGenerated ${e.target.value}`;
    }

    fetchAzureLogAnalyticsData(query);
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    let query;

    if (search === '') {
      query = `${headerTableData.prefix} | search '${e.target.value}' | sort by TimeGenerated ${sort}`;
    } else {
      query = `union (
        ${headerTableData.prefix}
        | search '${e.target.value}'
        | sort by TimeGenerated ${sort}
    ),
    (
      ${headerTableData.prefix}
        | search '${search}'
        | sort by TimeGenerated ${sort}
    )`;
      // query = `${headerTableData.prefix} | search ${e.target.value} | search ${search} | sort by TimeGenerated ${sort}`;
    }

    fetchAzureLogAnalyticsData(query);
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState(false);
    const [log_msg, setLog_Msg] = React.useState(false);

    const UTCDate = new Date(row.time_s);

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell sx={{ fontSize: '12px' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? (
                <KeyboardArrowUpIcon sx={{ fontSize: '12px' }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ fontSize: '12px' }} />
              )}
            </IconButton>
          </TableCell>
          <TableCell width={125} sx={{ fontWeight: open ? 'bold' : 'normal', fontSize: '12px' }}>
            {UTCDate.toUTCString()}
          </TableCell>
          <TableCell align="center" width={200} sx={{ fontWeight: open ? 'bold' : 'normal', fontSize: '12px' }}>
            {row.type.substring(0, 45)}...
          </TableCell>
          <TableCell align="center" width={125} sx={{ fontWeight: open ? 'bold' : 'normal', fontSize: '12px' }}>
            {row.time_s}
          </TableCell>
          <TableCell align="center" width={200} sx={{ fontWeight: open ? 'bold' : 'normal', fontSize: '12px' }}>
            {row.type.substring(0, 45)}...
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="inner table">
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontSize: '12px' }}>$table</TableCell>
                      <TableCell sx={{ fontSize: '12px' }}>{row.type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontSize: '12px' }}>TenantId</TableCell>
                      <TableCell sx={{ fontSize: '12px' }}>{row.tenant_id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontSize: '12px' }}>SourceSystem</TableCell>
                      <TableCell sx={{ fontSize: '12px' }}>{row.sourceSystem}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontSize: '12px' }}>TimeGenerated [UTC]</TableCell>
                      <TableCell sx={{ fontSize: '12px' }}>{UTCDate.toUTCString()}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          display: 'flex',
                          width: '100px',
                          justifyContent: 'space-between',
                          borderBottom: 'none',
                        }}
                      >
                        <IconButton aria-label="expand row" size="small" onClick={() => setMsg(!msg)}>
                          {msg ? (
                            <KeyboardArrowUpIcon sx={{ fontSize: '12px' }} />
                          ) : (
                            <KeyboardArrowDownIcon sx={{ fontSize: '12px' }} />
                          )}
                        </IconButton>
                        <p style={{ fontWeight: msg ? 'bold' : 'normal', fontSize: '12px', width: '30px' }}>Message</p>
                      </TableCell>
                      {/* <TableCell
                        align="left"
                        sx={{ fontWeight: msg ? 'bold' : 'normal', fontSize: '12px', width: '30px' }}
                      >
                        Message
                      </TableCell> */}
                      <TableCell
                        // align="left"
                        sx={{ borderBottom: 'none' }}
                      >
                        <div
                          style={{
                            fontWeight: msg ? 'bold' : 'normal',
                            fontSize: '12px',
                            // width: '550px',
                            // wordWrap: 'break-word',
                            wordBreak: 'break-all',
                          }}
                        >
                          {JSON.stringify(row.message).substring(0, 80)}...
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={msg} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="inner table">
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      display: 'flex',
                                      width: '150px',
                                      justifyContent: 'space-between',
                                      borderBottom: 'none',
                                    }}
                                  >
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() => setLog_Msg(!log_msg)}
                                    >
                                      {log_msg ? (
                                        <KeyboardArrowUpIcon sx={{ fontSize: '12px' }} />
                                      ) : (
                                        <KeyboardArrowDownIcon sx={{ fontSize: '12px' }} />
                                      )}
                                    </IconButton>
                                    <p
                                      style={{
                                        fontWeight: log_msg ? 'bold' : 'normal',
                                        fontSize: '12px',
                                        width: '30px',
                                      }}
                                    >
                                      log_msg
                                    </p>
                                  </TableCell>
                                  {/* <TableCell
                                    align="left"
                                    sx={{ fontWeight: log_msg ? 'bold' : 'normal', fontSize: '12px', width: '50px' }}
                                  >
                                    log_msg
                                  </TableCell> */}
                                  <TableCell align="left" sx={{ borderBottom: 'none' }}>
                                    <div
                                      style={{
                                        fontWeight: log_msg ? 'bold' : 'normal',
                                        fontSize: '12px',
                                        // width: '350px',
                                        // wordWrap: 'break-word',
                                        wordBreak: 'break-all',
                                        borderBottom: 'none',
                                      }}
                                    >
                                      {JSON.stringify(row.message.log_msg).substring(0, 80)}...
                                    </div>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={log_msg} timeout="auto" unmountOnExit>
                                      <Box sx={{ margin: 1 }}>
                                        <Table size="small" aria-label="purchases">
                                          <TableBody>
                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>batch_id</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.batch_id}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>batch_type</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.batch_type}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>client_name</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.client_name}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>job_name</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.job_name}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>level</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.level}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>log_timestamp</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.log_timestamp}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>msg</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>{row.message.log_msg.msg}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>status</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.status}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>step_name</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.step_name}
                                              </TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell sx={{ fontSize: '12px' }}>step_number</TableCell>
                                              <TableCell sx={{ fontSize: '12px' }}>
                                                {row.message.log_msg.step_number}
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell sx={{ fontSize: '12px' }}>log_type</TableCell>
                                  <TableCell sx={{ fontSize: '12px' }}>{row.message.log_type}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell sx={{ fontSize: '12px' }}>system_identifier</TableCell>
                                  <TableCell sx={{ fontSize: '12px' }}>{row.message.system_identifier}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontSize: '12px' }}>time_s</TableCell>
                      <TableCell sx={{ fontSize: '12px' }}>{row.time_s}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontSize: '12px' }}>Type</TableCell>
                      <TableCell sx={{ fontSize: '12px' }}>{row.type}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        startIcon={<MdArrowBackIosNew />}
        onClick={() => navigate(-1)}
        sx={{ color: '#43425D', border: '1px solid #43425D', background: '#fff', mb: 4 }}
      >
        Back
      </Button>

      {!loading && logsJson.length > 0 && (
        <Grid container spacing={1} direction="row" display="flex" mt={0.5} mb={3}>
          <Grid item xs={6} direction="row">
            <TextField
              id="input-with-icon-textfield"
              size="small"
              label="Search"
              name="search"
              InputProps={{
                endAdornment: <FiSearch sx={{ cursor: 'pointer' }} />,
              }}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleChangeSearch}
              value={search}
              variant="outlined"
              sx={{ background: '#ffffff', mt: 0.5 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 0.5,
              }}
            >
              <FormControl style={{ width: '100%', marginRight: '10px' }} size="small">
                <InputLabel>Filter By Status</InputLabel>
                <Select
                  labelId="accessRights"
                  id="demo-simple-select-helper"
                  value={status}
                  onChange={(e) => handleChangeStatus(e)}
                  size="small"
                  label="Filter by Region"
                  sx={{ background: '#ffffff' }}
                >
                  <MenuItem value={'Success'}>Success</MenuItem>
                  <MenuItem value={'Running'}>Running</MenuItem>
                  <MenuItem value={'Error'}>Error</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ width: '100%', marginRight: '10px' }} size="small">
                <InputLabel>Sort By Time Generated</InputLabel>
                <Select
                  labelId="accessRights"
                  id="demo-simple-select-helper"
                  value={sort}
                  onChange={(e) => handleChangeSort(e)}
                  fullWidth
                  size="small"
                  label="Filter by Status"
                  sx={{ background: '#ffffff' }}
                >
                  <MenuItem value={'asc'}>Ascending</MenuItem>
                  <MenuItem value={'desc'}>Descending</MenuItem>
                </Select>
              </FormControl>
              <Button
                size="small"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: '#00043C',
                  '&:hover': { backgroundColor: '#00043C' },
                  width: '260px',
                  fontSize: '12px',
                }}
                onClick={handleClearFilter}
              >
                Clear Filter
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}

      {loading && <LoadingIcon />}
      {!loading && logsJson.length === 0 && <h1>No Logs Found</h1>}
      {!loading && logsJson.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ maxWidth: 700 }} size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <div style={{ fontWeight: 'bold', fontSize: '18px', width: '200px' }}>TimeGenerated [UTC]</div>
                  </TableCell>
                  <TableCell width={200} align="center" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '18px', width: '180px' }}>$table</div>
                  </TableCell>
                  <TableCell align="center" width={125} sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '18px', width: '100px' }}>time_s</div>
                  </TableCell>
                  <TableCell align="center" width={200} sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '18px', width: '180px' }}>Type</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logsJson.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, i) => (
                  <Row key={i} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={Math.floor(logsJson.length / 20) + (logsJson.length % 20 === 0 ? 0 : 1)}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            size="small"
            sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
          />
        </>
      )}
    </Box>
  );
};

export default LogsAnalyticsData;
