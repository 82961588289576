import React, { useEffect } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { Switch, Space } from 'antd';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FileUploader = ({
  handleFileUploadChange,
  handleUpload,
  disableForm,
  isUploadDisabled,
  handleSwitchChange,
  isFileSelected,
  files,
}) => {
  useEffect(() => {
    if (isFileSelected) {
      document.getElementById('fileInput').removeAttribute('webkitdirectory');
    } else {
      document.getElementById('fileInput').setAttribute('webkitdirectory', '');
    }
  }, [isFileSelected]);
  const handleChooseFile = (event) => {
    document.getElementById('fileInput').click();
    event.stopPropagation();
  };
  return (
    <Stack direction="row" spacing={2}>
      <Switch
        disabled={disableForm}
        checkedChildren="File"
        unCheckedChildren="Folder"
        checked={isFileSelected}
        onClick={handleSwitchChange}
      />

      <Button
        disabled={disableForm}
        sx={{ width: '200px', height: '25px' }}
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        onClick={handleChooseFile}
      >
        CHOOSE {isFileSelected ? `FILE` : `FOLDER`}
      </Button>

      <span
        style={{
          fontFamily: 'Roboto,Helvetica,Arial,sansSerif',
          fontWeight: '400',
          fontSize: '15px',
          lineHeight: '1.43',
          letterSpacing: '0.01071em',
        }}
      >
        {files && files.length > 0 ? `${files.length} files` : 'No File Selected'}
      </span>
      <input
        type="file"
        required
        multiple
        onChange={handleFileUploadChange}
        disabled={disableForm}
        id="fileInput"
        style={{ display: 'none' }}
        data-directory=""
        webkitdirectory=""
      />

      <Button
        size="small"
        type="submit"
        disabled={disableForm || isUploadDisabled}
        variant="contained"
        onClick={handleUpload}
        sx={{ width: '10px', height: '25px' }}
      >
        Upload
      </Button>
    </Stack>
  );
};

export default FileUploader;
