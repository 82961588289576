export const GetEdges = async (allNodes) => {
  const edgeType = 'default';

  const allEdges = [];
  const NodesProperty = { Outgoing: {}, Incoming: {} };
  const getAllEdgesFromLocalStorage = JSON.parse(sessionStorage.getItem('edges'));
  let otherTargetEle;
  getAllEdgesFromLocalStorage.forEach((ele) => {
    const sourceEle = ele.source; // 301
    const targetEle = ele.target; // 794

    otherTargetEle = targetEle;
    allNodes.forEach((ele) => {
      const isNodeConnect = ele.id.startsWith(`${sourceEle}__`);

      if (isNodeConnect) {
        allEdges.push({
          id: `${sourceEle}__${ele.id}`,
          source: sourceEle,
          target: ele.id,
          type: edgeType,
          animated: true,
          sourceHandle: 'a',
        });
        NodesProperty['Outgoing'][sourceEle] = NodesProperty['Outgoing'][sourceEle]
          ? NodesProperty['Outgoing'][sourceEle] + 1
          : 1;

        if (ele.isChecked) {
          allEdges.push({
            id: `${ele.id}__${targetEle}`,
            source: ele.id,
            target: targetEle,
            type: edgeType,
            animated: true,
            sourceHandle: 'a',
          });
          NodesProperty['Incoming'][targetEle] = NodesProperty['Incoming'][targetEle]
            ? NodesProperty['Incoming'][targetEle] + 1
            : 1;
        }
      }
    });
  });

  allNodes.forEach((ele) => {
    const isNodeConnect = ele.id.startsWith(`${otherTargetEle}__`);

    if (isNodeConnect) {
      allEdges.push({
        id: `${otherTargetEle}__${ele.id}`,
        source: otherTargetEle,
        target: ele.id,
        type: edgeType,
        animated: true,
        sourceHandle: 'a',
      });
      NodesProperty['Outgoing'][otherTargetEle] = NodesProperty['Outgoing'][otherTargetEle]
        ? NodesProperty['Outgoing'][otherTargetEle] + 1
        : 1;
    }
  });

  return { allEdges, NodesProperty };
};
