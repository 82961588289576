import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from 'notistack';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material';
import Sidebar from './components/sidebar/Sidebar';
import AuthProvider from './contexts/AuthProvider';
import WorkflowProvider from './contexts/WorkflowProvider';
import Router from './utils/Routes';
import ChatGPTProvider from './contexts/ChatGPTProvider';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  // '&.notistack-MuiContent-success': {
  //   backgroundColor: '#EDF7ED',
  //   color: '#517152',
  // },
  // '&.notistack-MuiContent-error': {
  //   backgroundColor: '#fdeded',
  //   color: '#D94B4B',
  // },
  // '&.notistack-MuiContent-warning': {
  //   backgroundColor: '#FFF4E5',
  //   color: '#663C00',
  // },
}));

function App() {
  return (
    <AuthProvider>
      <WorkflowProvider>
        <ChatGPTProvider>
          <SnackbarProvider
            maxSnack={3}
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent,
            }}
            iconVariant={{
              error: <ErrorOutlineIcon sx={{ marginRight: 1, marginLeft: 0 }} />,
            }}
            action={(snackbarId) => (
              <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(snackbarId)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            )}
          >
            <Router />
          </SnackbarProvider>
        </ChatGPTProvider>
      </WorkflowProvider>
    </AuthProvider>
  );
}

export default App;
