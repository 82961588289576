import { Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { TbExchange } from 'react-icons/tb';
import { updateTileProperty, updateTileTitle } from "../../api's/ReportingApi";
import ReportTable from '../reports/ReportTable';
import './SingleValueCard.css';
import PercentageValue from './symbols/PercentageValue';

function SingleValueCard({ item, handleDeleteTile }) {
  console.log('Single Card: ', item);
  const { enqueueSnackbar } = useSnackbar();
  const [toolsVisible, setToolsVisible] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isViewTable, setIsViewTable] = useState(false);
  const [isPercentageValue, setIsPercentageValue] = useState(true);
  const [tileProperty, setTileProperty] = useState('general');
  const [tileColor, setTileColor] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const titleInputRef = useRef(null);
  const nameRegex = /^[a-zA-Z0-9 ]{1,50}$/;

  const handleEditTile = async () => {
    setIsEditingTitle(false);
    if (title.trim() === '') {
      setTitle(item.title);
      return;
    }
    if (!title.match(nameRegex)) {
      setTitle(item.title);
      if (title.length > 50) {
        enqueueSnackbar('ONLY 50 CHARACTERS ARE ALLOWED!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('NO SPECIAL CHARACTER ALLOWED!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      return;
    }

    try {
      const response = await updateTileTitle(item.rg_id, title);
    } catch (error) {
      setTitle(item.title);
      enqueueSnackbar('Error while saving Title!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleTileProperty = async () => {
    try {
      const payload = {
        rg_id: item.rg_id,
        tile_property: tileProperty === 'general' ? 'percentage' : 'general',
        tile_color: 'blue',
      };
      const response = await updateTileProperty(payload);
      if (response.status === 200) {
        setTileProperty(payload.tile_property);
        setTileColor(payload.color);
      }
    } catch (error) {
      enqueueSnackbar('Error while updating Tile property!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (isEditingTitle && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [isEditingTitle]);

  useEffect(() => {
    if (item) {
      const header = item.data.headers[0];
      const key = header.dataIndex;
      const value = item.data.data[0][key];
      setContent(value);
      setTitle(item.title);
      setTileProperty(item.tile_property);
      if (item.data.headers.length > 1) {
        setTileProperty('table');
      }
    }
  }, []);

  return (
    <div className="SingleValueCard">
      {!isViewTable && (
        <>
          <div
            className="box-card"
            style={{ position: 'relative' }}
            onMouseEnter={() => setToolsVisible(true)}
            onMouseLeave={() => setToolsVisible(false)}
          >
            {isEditingTitle ? (
              <input
                type="text"
                value={title}
                ref={titleInputRef}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={() => handleEditTile()}
                style={{
                  position: 'absolute',
                  top: '10px',
                  // left: '60%',
                  width: '175px',
                  // transform: 'translateX(-50%)',
                  padding: '5px',
                  fontSize: '16px',
                  // zIndex: 10,
                }}
              />
            ) : (
              <h2 className="box-card-title">{title || item?.title}</h2>
            )}
            {tileProperty === 'general' && (
              <p className="box-card-content">
                <IoMdCheckboxOutline />
                &nbsp;
                {content?.length > 9 ? <span style={{ fontSize: '0.6em' }}>{content}</span> : content}
              </p>
            )}
            {tileProperty === 'percentage' && (
              <PercentageValue
                content={content}
                colorCode={item.color}
                toolsVisible={toolsVisible}
                percentageShowType="progress"
              />
            )}
            {tileProperty === 'table' && (
              <>
                <Tooltip title="View Dataset" placement="right-start">
                  <button
                    style={{
                      cursor: 'pointer',
                      border: 'none',
                      background: 'none',
                      outline: 'none',
                      marginTop: 'auto',
                      marginBottom: '16px',
                      width: '20px',
                    }}
                    onClick={() => {
                      setIsViewTable(true);
                    }}
                  >
                    <FiFileText size={30} color="#64A0C9" />
                  </button>
                </Tooltip>
              </>
            )}

            {toolsVisible && tileProperty !== 'table' && (
              <Tooltip title="Toggle View">
                <button
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    padding: '0',
                    outline: 'none',
                    // color: 'grey',
                  }}
                  onClick={handleTileProperty}
                >
                  <TbExchange />
                </button>
              </Tooltip>
            )}
            {toolsVisible && (
              <>
                <button
                  style={{
                    position: 'absolute',
                    top: '20px',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    padding: '0',
                    outline: 'none',
                    // color: 'grey',
                  }}
                  onClick={() => {
                    setIsEditingTitle(true);
                  }}
                >
                  <FaEdit />
                </button>
                <button
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    padding: '0',
                    outline: 'none',
                  }}
                  onClick={() => {
                    handleDeleteTile(item?.rg_id);
                  }}
                >
                  <FaTrashAlt />
                </button>
              </>
            )}
          </div>
        </>
      )}
      {isViewTable && (
        <ReportTable
          data={item.data}
          rg_id={item.rg_id}
          path={item.path}
          handleDeleteTile={handleDeleteTile}
          initialTitle={item.title}
          report_workflow_id={item.report_workflow_id}
          tile_id={item.tile_id}
          fullViewMode="true"
          setIsViewTable={setIsViewTable}
        />
      )}
    </div>
  );
}

export default SingleValueCard;
