import React from 'react';
import { Row, Col } from 'antd';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FiSearch } from 'react-icons/fi';

export default function SearchAndFilter({ data }) {
  // console.log(data);
  const filtered_domain = sessionStorage.getItem('filtered_workflow_domain') || '';
  const filtered_workflow_client = sessionStorage.getItem('filtered_workflow_client') || '';
  const filtered_workflow_engagement = sessionStorage.getItem('filtered_workflow_engagement') || '';
  return (
    <div>
      <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
        <Col span={10}>
          <TextField
            id="input-with-icon-textfield"
            size="small"
            label="Search"
            name="search"
            InputProps={{
              endAdornment: <FiSearch onClick={data.handleSearch} sx={{ cursor: 'pointer' }} />,
            }}
            onChange={data.handleOnChange}
            value={data.search}
            variant="outlined"
            sx={{ background: '#ffffff', mt: 0.5 }}
            autoComplete="off"
          />
        </Col>
        <Col span={14}>
          <Row gutter={[8, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {data.isFirstFilterVisible && (
              <Col span={6}>
                <FormControl style={{ width: '100%', marginRight: '10px' }} size="small">
                  <InputLabel>{data.firstFilterText}</InputLabel>
                  <Select
                    labelId="accessRights"
                    id="demo-simple-select-helper"
                    value={data.region}
                    onChange={data.handleRegion}
                    size="small"
                    label="Filter by Region"
                    sx={{ background: '#ffffff' }}
                  >
                    {data?.firstFilterValues?.filterData?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            )}
            {data.isSecondFilterVisible && (
              <Col span={6}>
                <FormControl style={{ width: '100%', marginRight: '10px' }} size="small">
                  <InputLabel>{data.secondFilterText}</InputLabel>
                  <Select
                    labelId="accessRights"
                    id="demo-simple-select-helper"
                    value={data.status}
                    onChange={data.handleStatus}
                    fullWidth
                    size="small"
                    label="Filter by Status"
                    sx={{ background: '#ffffff' }}
                  >
                    {data?.secondFilterValues?.filterData?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            )}

            {data.isThirdFilterVisible && (
              <Col span={6}>
                <FormControl style={{ width: '100%', marginRight: '10px' }} size="small">
                  <InputLabel>{data.thirdFilterText}</InputLabel>
                  <Select
                    labelId="accessRights"
                    id="demo-simple-select-helper"
                    value={data.engagement}
                    onChange={data.handleEngagement}
                    fullWidth
                    size="small"
                    label="Filter by Status"
                    sx={{ background: '#ffffff' }}
                  >
                    {data?.thirdFilterValues?.filterData?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            )}
            <Col span={4}>
              <Button
                size="small"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: '#00043C',
                  '&:hover': { backgroundColor: '#00043C' },
                  // width: '260px',
                  height: '40px',
                  fontSize: '12px',
                }}
                onClick={data.handleClearFilter}
              >
                {data.clearFilterText}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
