import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Popconfirm, Table as AntdTable, Select, Empty } from 'antd';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { AiFillEdit, AiFillSave, AiOutlineCloseCircle } from 'react-icons/ai';
import { convertToPascalCase } from '../../utils/stringConversion';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import { removeKeyFromArrayObjects } from '../../utils/arrayManipulation';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  onInputChange,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber onChange={(e) => onInputChange(e, record.key, dataIndex)} />
    ) : (
      <Input onChange={(e) => onInputChange(e, record.key, dataIndex)} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const DataManager = ({
  goldenRecords,
  disableForm,
  dataManagerRows,
  dataManagerTableData,
  setDataManagerTableData,
  loading,
  dataManagerKeys,
  setDataManagerKeys,
}) => {
  const [form] = Form.useForm();

  const { setDataManager } = useContext(WorkflowContext);

  const [editingKey, setEditingKey] = useState('');
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const isEditing = (record) => record.key === editingKey;

  const actionColumn = {
    title: 'Action',
    dataIndex: 'action',
    fixed: 'right',
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Stack direction="row" alignItems="center">
          <Tooltip title="Save">
            <IconButton size="small" color="success" onClick={() => save(record.key)}>
              <AiFillSave />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton size="small" color="error" onClick={cancel}>
              <AiOutlineCloseCircle />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Tooltip title="Edit">
          <span>
            <IconButton
              size="small"
              color="primary"
              disabled={editingKey !== '' || disableForm}
              onClick={() => edit(record)}
            >
              <AiFillEdit />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
  };

  const handleEditableColumn = (item) => {
    if (
      item === 'parent_id' ||
      item === 'bus_unit_id' ||
      item === 'unique_id' ||
      item === 'source' ||
      item === 'index' ||
      item === 'score'
    )
      return false;
    return true;
  };

  const antdColumns = Object.keys(goldenRecords?.golden_records[0]).map((item, index, array) => ({
    width: index === array.length - 1 ? 0 : 170,
    title: convertToPascalCase(item),
    dataIndex: item,
    editable: handleEditableColumn(item),
  }));

  const updatedColumns = [...antdColumns, { ...actionColumn }];

  const sumOfWidths = updatedColumns.reduce((total, item) => total + (item.width || 0), 0) + 220;

  const identifierKey = 'unique_id';

  const newArray = dataManagerTableData && dataManagerTableData?.filter((item) => dataManagerKeys.includes(item.key));

  function compareArraysOfObjects(oldArray, newArray, identifierKey) {
    const updatedValues = [];
    newArray?.forEach((newObj) => {
      const matchingOldObj = oldArray?.find((oldObj) => oldObj[identifierKey] === newObj[identifierKey]);
      if (matchingOldObj) {
        const updatedKeys = Object.keys(newObj).filter((key) => newObj[key] !== matchingOldObj[key]);
        if (updatedKeys.length > 0) {
          const updatedObj = { [identifierKey]: newObj[identifierKey] };
          updatedKeys.forEach((key) => {
            updatedObj[key] = newObj[key];
          });
          updatedValues.push({ ...updatedObj, unique_id: newObj['unique_id'] });
        }
      }
    });
    return updatedValues;
  }

  useEffect(() => {
    const formattedValues = compareArraysOfObjects(dataManagerRows, newArray, identifierKey);
    const updatedValues = removeKeyFromArrayObjects(formattedValues, 'key');
    setDataManager(updatedValues);
  }, [dataManagerKeys, editingKey]);

  const edit = (record) => {
    form.setFieldsValue({
      bus_unit_id: '',
      cust_bill_to_address: '',
      bill_postalcd: '',
      unique_id: '',
      index: '',
      cust_address: '',
      source: '',
      ship_postalcd: '',
      src_sale_amt: '',
      cust_postalcd: '',
      score: '',
      duns_no: '',
      src_sale_currency: '',
      src_sale_date: '',
      parent_id: '',
      cust_ship_to_address: '',
      cust_name: '',
      customer_type_name: '',
      country: '',
      product: '',
      quantity: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    setDataManagerKeys((prev) => [...new Set([...prev, key])]);
    try {
      const row = await form.validateFields();
      const newData = [...dataManagerTableData];
      const newIndex = newData.findIndex((item) => key === item.key);
      if (newIndex > -1) {
        const item = newData[newIndex];
        newData.splice(newIndex, 1, {
          ...item,
          ...row,
        });
        setDataManagerTableData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setDataManagerTableData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const onInputChange = (e, index, dataIndex) => {
    const newData = [...dataManagerTableData];
    newData[index][dataIndex] = e.target.value;
    setDataManagerTableData(newData);
  };

  const mergedColumns = updatedColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        onInputChange,
        // onClick: () => {
        //   setColumnName(col.dataIndex);
        // },
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Box display="flex" flexDirection="column">
        <Typography variant="body2" sx={{ m: 1, ml: 'auto' }}>
          Total Records: {goldenRecords.total_objects ?? 0}
        </Typography>
        <AntdTable
          size="small"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataManagerTableData}
          columns={mergedColumns}
          loading={dataManagerTableData?.length <= 0 ? !loading && <Empty /> : false}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          scroll={{
            x: sumOfWidths,
            y: 300,
          }}
        />
      </Box>
    </Form>
  );
};

export default DataManager;
