import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  FormControl,
  Typography,
  OutlinedInput,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { CONNECTIONS_PAGE } from '../utils/commonConstants';

export default function EditConnectionModal({
  handleConnectionModal,
  openConnectionModal,
  row,
  fetchData,
  type,
  id,
  selectedRow,
}) {
  const [connectionName, setConnectName] = useState(row.connection_name);
  const [connectionType, setConnectionType] = useState(row.connection_type);
  const [databaseUsername, setDatabaseUsername] = useState('');
  const [databasePassword, setDatabasePassword] = useState('');
  const [databaseName, setDatabaseName] = useState('');
  const [warehouse, setWarehouse] = useState('');
  const [role, setRole] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleConnectionName = (event) => {
    setConnectName(event.target.value);
  };

  const handleConnectionType = (event) => {
    setConnectionType(event.target.value);
  };

  const handleDatabaseUsername = (event) => {
    setDatabaseUsername(event.target.value);
  };
  const handleDatabasePassword = (event) => {
    setDatabasePassword(event.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleDatabaseName = (event) => {
    setDatabaseName(event.target.value);
  };
  const handleWarehouse = (event) => {
    setWarehouse(event.target.value);
  };
  const handleRole = (event) => {
    setRole(event.target.value);
  };

  const connectionTypeArr = [
    { id: 1, connection_type: 'AWS' },
    { id: 2, connection_type: 'Snowflake' },
  ];

  return (
    <div>
      <Dialog
        open={openConnectionModal}
        onClose={handleConnectionModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{CONNECTIONS_PAGE.connectionEditModalTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={6}>
              <Typography
                variant="p"
                component="p"
                display="flex"
                alignContent="flex-start"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Connection Name*
              </Typography>
              <TextField
                size="small"
                required
                id="connection-name"
                name="connection-name"
                autoComplete="connection-name"
                value={connectionName}
                sx={{ backgroundColor: '#2E5BFF14' }}
                onChange={handleConnectionName}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 220 }}>
                <Typography
                  variant="p"
                  component="p"
                  display="flex"
                  alignContent="flex-start"
                  sx={{ color: '#8798AD', fontSize: '11px' }}
                >
                  Connection Type*
                </Typography>
                <Select
                  size="small"
                  labelId="connection-type"
                  id="connection-type"
                  value={connectionType}
                  onChange={handleConnectionType}
                  input={<OutlinedInput />}
                  sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
                >
                  {connectionTypeArr.map((item) => (
                    <MenuItem key={item.id} value={item.connection_type}>
                      {item.connection_type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {connectionType && (
              <>
                <Grid item xs={6}>
                  <Typography
                    variant="p"
                    component="p"
                    display="flex"
                    alignContent="flex-start"
                    sx={{ color: '#8798AD', fontSize: '11px' }}
                  >
                    Database Username*
                  </Typography>
                  <TextField
                    size="small"
                    required
                    id="database-username"
                    name="database-username"
                    autoComplete="database-username"
                    value={databaseUsername}
                    sx={{ backgroundColor: '#2E5BFF14' }}
                    onChange={handleDatabaseUsername}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="p"
                    component="p"
                    display="flex"
                    alignContent="flex-start"
                    sx={{ color: '#8798AD', fontSize: '11px' }}
                  >
                    Database Password*
                  </Typography>
                  <TextField
                    size="small"
                    required
                    id="database-password"
                    name="database-password"
                    autoComplete="database-password"
                    type={showPassword ? 'text' : 'password'}
                    value={databasePassword}
                    sx={{ backgroundColor: '#2E5BFF14' }}
                    onChange={handleDatabasePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                          >
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="p"
                    component="p"
                    display="flex"
                    alignContent="flex-start"
                    sx={{ color: '#8798AD', fontSize: '11px' }}
                  >
                    Database Name*
                  </Typography>
                  <TextField
                    size="small"
                    required
                    id="database-name"
                    name="database-name"
                    autoComplete="database-name"
                    value={databaseName}
                    sx={{ backgroundColor: '#2E5BFF14' }}
                    onChange={handleDatabaseName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="p"
                    component="p"
                    display="flex"
                    alignContent="flex-start"
                    sx={{ color: '#8798AD', fontSize: '11px' }}
                  >
                    Role*
                  </Typography>
                  <TextField
                    size="small"
                    required
                    id="role"
                    name="role"
                    autoComplete="role"
                    value={role}
                    sx={{ backgroundColor: '#2E5BFF14' }}
                    onChange={handleRole}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="p"
                    component="p"
                    display="flex"
                    alignContent="flex-start"
                    sx={{ color: '#8798AD', fontSize: '11px' }}
                  >
                    Warehouse*
                  </Typography>
                  <TextField
                    size="small"
                    required
                    id="warehouse"
                    name="warehouse"
                    autoComplete="warehouse"
                    value={warehouse}
                    sx={{ backgroundColor: '#2E5BFF14' }}
                    onChange={handleWarehouse}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingRight: 8 }}>
          <Button
            variant="outlined"
            onClick={handleConnectionModal}
            sx={{ color: '#43425D', border: '1px solid #43425D' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            // onClick={() => handleOnDelete(type)}
            autoFocus
            sx={{ backgroundColor: '#00043C', textTransform: 'capitalize', '&:hover': { backgroundColor: '#00043C' } }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
