import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { BUCKET_NAME } from '../../constants/Constant';

export default function convertToRequiredFormat(getJobFromLocalStorage, track_id, sample_view = '') {
  const steps = [];
  let previous = true;

  let stepCount = 1;
  let read_object_boolean = false;
  let read_boolean = true;
  let read_object_index = 0;
  let read_object;
  const read_object_arr = [];

  let write_object;
  const write_object_arr = [];
  let write_object_boolean = false;
  const write_boolean = true;
  const handle_write_object_index = 0;

  let executeSQL_object_boolean = false;
  let executeSQL_boolean = true;
  let executeSQL_object_index = 0;
  let executeSQL_object;
  let execute_sql_object_arr = [];

  let profiling_object_boolean = false;
  let profiling_boolean = true;
  let profiling_object_index = 0;
  let profiling_object;
  const profiling_object_arr = [];

  let ingestion_object_boolean = false;
  let ingestion_object;
  let ingestion_boolean = true;

  getJobFromLocalStorage &&
    getJobFromLocalStorage.map((ele) => {
      if (
        ele.nodeName === 'Read' ||
        ele.nodeName === 'ReadCSV' ||
        ele.nodeName === 'ReadPDF' ||
        // ele.nodeName === 'CopyADLS' ||
        ele.nodeName === 'ReadPublishedData' ||
        ele.nodeName === 'ReadStreaming' ||
        ele.nodeName === 'ReadDelimited' ||
        ele.nodeName === 'ReadOracle' ||
        ele.nodeName === 'ReadPostgres' ||
        ele.nodeName === 'ReadCosmos' ||
        ele.nodeName === 'ReadMySql' ||
        ele.nodeName === 'ReadSnowflake' ||
        ele.nodeName === 'Read Real-Time' ||
        ele.nodeName === 'FileUploader'
      ) {
        if (read_object_boolean === false) {
          read_object = new Object();
          read_object['method_name'] = ele.nodeName === 'ReadPDF' ? 'PdfTableExtract' : 'Read';
          read_object['params'] = new Object();
          read_object['params']['definitions'] = new Array();
          read_object_boolean = true;
        }

        read_object['params']['definitions'][read_object_index] = new Object();
        read_object['params']['definitions'][read_object_index]['step_name'] = `${ele.step_name}_${stepCount}`;
        read_object['params']['definitions'][read_object_index]['step_number'] = stepCount;
        read_object['params']['definitions'][read_object_index]['df'] = ele.formField.alias;
        if (ele.nodeName === 'ReadSnowflake') {
          read_object['params']['definitions'][read_object_index]['format'] = 'snowflake';
        } else {
          read_object['params']['definitions'][read_object_index]['format'] = ele.formField.format;
        }
        if (ele.nodeName !== 'Read Real-Time') {
          read_object['params']['definitions'][read_object_index]['cdc'] = ele.formField.CDC;
        }

        read_object['params']['definitions'][read_object_index]['node_id'] = ele.nodeId;

        if (track_id !== 'snowflake-garbageTID') {
          if (ele.nodeName !== 'Read Real-Time') {
            read_object['params']['definitions'][read_object_index]['DataProcessor_tracking_id'] = track_id;
          }
        }
        if (ele.formField.format === 'api') {
          read_object['params']['definitions'][read_object_index]['url'] = ele.formField.url;
        }
        if (ele.formField.format === 'delimeted') {
          const row_level_fields = [];

          if (ele.fetchedHeader.length > 0) {
            ele.fetchedHeader.forEach((el) => {
              row_level_fields.push({ fieldname: el.header });
            });
          }

          read_object['params']['definitions'][read_object_index]['row_operations'] = new Object();
          read_object['params']['definitions'][read_object_index]['row_operations']['ignoreblanklines'] =
            ele.formField.ignoreblanklines || false;
          read_object['params']['definitions'][read_object_index]['row_operations']['skipheaders'] =
            ele.formField.skipheaders || false;
          read_object['params']['definitions'][read_object_index]['row_operations']['columnshift'] =
            ele.formField.columnshift || false;
          read_object['params']['definitions'][read_object_index]['row_operations']['junkrecords'] =
            ele.formField.junkrecords || false;
          read_object['params']['definitions'][read_object_index]['row_operations']['linebreak'] =
            ele.formField.linebreak || false;
          read_object['params']['definitions'][read_object_index]['row_operations']['delimiter'] =
            ele.formField.delimiter;
          read_object['params']['definitions'][read_object_index]['row_operations']['fields'] = row_level_fields;
        }
        if (ele.nodeName !== 'Read Real-Time') {
          if (ele.nodeName === 'ReadSnowflake') {
            read_object['params']['definitions'][read_object_index]['delimiter'] = '';
          } else {
            read_object['params']['definitions'][read_object_index]['delimiter'] = ele.formField.delimiter;
          }
        }
        read_object['params']['definitions'][read_object_index]['distinct_rows'] = ele.formField.distinct_rows;
        read_object['params']['definitions'][read_object_index]['sample_view'] = ele.formField.sample_view;
        if (ele.nodeName !== 'Read Real-Time') {
          if (ele.nodeName === 'ReadPublishedData') {
            const url = ele.formField.path;
            const newPath = url.replace(/\/([^/]+)$/, '');
            read_object['params']['definitions'][read_object_index]['path'] = newPath;
          } else if (ele.formField.path) {
            read_object['params']['definitions'][read_object_index]['path'] = ele.formField.path;
          } else if (ele.nodeName === 'ReadCosmos') {
            read_object['params']['definitions'][read_object_index]['database'] = ele.formField.database;
            read_object['params']['definitions'][read_object_index]['container'] = ele.formField.schema;
          } else if (ele.nodeName === 'ReadSnowflake') {
            read_object['params']['definitions'][read_object_index]['database'] = ele.formField.database;
            read_object['params']['definitions'][read_object_index]['schema'] = ele.formField.schema;
            read_object['params']['definitions'][read_object_index]['tablename'] = ele.formField.tablename;
          } else {
            read_object['params']['definitions'][read_object_index]['database'] = ele.formField.database;
            read_object['params']['definitions'][read_object_index]['tablename'] = ele.formField.tablename;
            read_object['params']['definitions'][read_object_index]['schema'] = ele.formField.schema;
          }
        }
        if (ele.nodeName !== 'Read Real-Time') {
          if (ele.formField.persist === true) {
            read_object['params']['definitions'][read_object_index]['persist_type'] = ele.formField.persist_type;
          } else {
            read_object['params']['definitions'][read_object_index]['persist_type'] = '';
          }
          read_object['params']['definitions'][read_object_index]['pagination'] = ele.formField.pagination || false;
          if (ele.formField.pagination) {
            read_object['params']['definitions'][read_object_index]['partitionColumn'] = ele.formField.partitionColumn;
            read_object['params']['definitions'][read_object_index]['numPartitions'] = ele.formField.numPartitions;
            read_object['params']['definitions'][read_object_index]['lowerBound'] = ele.formField.lowerBound;
            read_object['params']['definitions'][read_object_index]['upperBound'] = ele.formField.upperBound;
          }
        }

        if (ele.nodeName === 'Read Real-Time') {
          read_object['params']['definitions'][read_object_index]['host'] = ele.formField.host;
          read_object['params']['definitions'][read_object_index]['topic'] = ele.formField.topic;
          read_object['params']['definitions'][read_object_index]['path_json'] = ele.formField.path;
          read_object['params']['definitions'][read_object_index]['step_name'] = `Read_${stepCount}`;
        }
        read_object['params']['definitions'][read_object_index]['action'] = ele.formField.action
          ? ele.formField.action
          : '';

        const newArray = [];

        if (ele.headerName.length > 0) {
          ele.headerName.forEach((el) => {
            // const updatedHeader = el.header.includes('.') ? `\`${el.header}\`` : `${el.header}`;
            const updatedHeader = `\`${el.header}\``;

            if (el.alias && el.cast_datatype !== 'string' && !el.default_value) {
              newArray.push(`cast(${updatedHeader} as ${el.cast_datatype}) as ${el.alias}`);
            } else if (!el.alias && el.cast_datatype !== 'string' && !el.default_value) {
              newArray.push(`cast(${updatedHeader} as ${el.cast_datatype})`);
            } else if (el.alias && el.cast_datatype === 'string' && !el.default_value) {
              newArray.push(`${updatedHeader} as ${el.alias}`);
            } else if (el.alias && el.cast_datatype !== 'string' && el.default_value) {
              newArray.push(
                `cast(${el.attribute_type === 'static' ? `'${el.default_value}'` : el.default_value} as ${
                  el.cast_datatype
                }) as ${el.alias}`
              );
            } else if (!el.alias && el.cast_datatype !== 'string' && el.default_value) {
              newArray.push(
                `cast(${el.attribute_type === 'static' ? `'${el.default_value}'` : el.default_value} as ${
                  el.cast_datatype
                })`
              );
            } else if (!el.alias && el.cast_datatype === 'string' && el.default_value) {
              newArray.push(
                `${el.attribute_type === 'static' ? `'${el.default_value}'` : el.default_value} as ${updatedHeader}`
              );
            } else {
              newArray.push(updatedHeader);
            }
          });

          read_object['params']['definitions'][read_object_index]['select_cols'] = newArray.toString();
        }

        read_object['params']['definitions'][read_object_index]['action'] = ele.formField.action
          ? ele.formField.action
          : '';

        read_object_index++;
        read_object_arr.push(read_object);
        if (read_boolean) {
          steps.push(read_object_arr[0]);
          read_boolean = false;
        }
        stepCount++;
        previous = true;
      } else if (ele.nodeName === 'Source') {
        const source_object = new Object();

        source_object['method_name'] = 'Read';
        source_object['params'] = new Object();

        const read_object = [];

        ele.fetchedHeader.forEach((item) => {
          const newReadObject = {
            step_name: `Read_${stepCount}`,
            step_number: stepCount,
            node_id: ele.nodeId,
            df: item.df,
            delimiter: ',',
            format: 'csv',
            distinct_rows: false,
            sample_view: ele.formField.sample_view,
            path: item.path,
            persist: false,
            persist_type: '',
            pagination: false,
            action: '',
            select_cols: '*',
          };
          stepCount++;
          read_object.push(newReadObject);
        });

        source_object['params']['definitions'] = read_object;

        steps.push(source_object);
        previous = true;
      } else if (ele.nodeName === 'Udf') {
        if (previous) {
          executeSQL_object_boolean = false;
          executeSQL_boolean = true;
          execute_sql_object_arr = [];
          executeSQL_object_index = 0;
        }
        // if (executeSQL_object_boolean === false) {
        executeSQL_object = new Object();
        executeSQL_object['method_name'] = 'ExecuteSQL';
        executeSQL_object['params'] = new Object();
        executeSQL_object['params']['definitions'] = new Array();
        executeSQL_object_boolean = true;
        // }
        executeSQL_object['params']['definitions'][executeSQL_object_index] = new Object();
        executeSQL_object['params']['definitions'][executeSQL_object_index][
          'step_name'
        ] = `${ele.step_name}_${stepCount}`;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['step_number'] = stepCount;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['node_id'] = ele.nodeId;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['df'] = ele.formField.alias;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['db_name'] = ele.formField.db_name;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['statement'] = ele.formField.statement;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['persist'] = ele.formField.persist;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['udf'] = ele.udfList;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['sample_view'] = ele.formField.sample_view;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['distinct_rows'] =
          ele.formField.distinct_rows;

        if (ele.formField.persist === true) {
          executeSQL_object['params']['definitions'][executeSQL_object_index]['persist_type'] =
            ele.formField.persist_type;
        } else {
          executeSQL_object['params']['definitions'][executeSQL_object_index]['persist_type'] = '';
        }
        executeSQL_object['params']['definitions'][executeSQL_object_index]['action'] = ele.formField.action
          ? ele.formField.action
          : '';
        // stepCount++;
        // executeSQL_object_index++;

        // execute_sql_object_arr.push(executeSQL_object);
        // // const new_execute_sql_object_arr = [...execute_sql_object_arr]

        // if (executeSQL_boolean) {
        //   steps.push(execute_sql_object_arr[0]);
        //   executeSQL_boolean = false;
        // }

        stepCount++;
        steps.push(executeSQL_object);
        previous = false;
      } else if (ele.nodeName === 'DataValidation') {
        const validation_object = new Object();
        let validation_object_index = 0;

        validation_object['method_name'] = 'DataValidation';
        validation_object['params'] = new Object();
        validation_object['params']['definitions'] = new Array();
        validation_object['params']['definitions'][validation_object_index] = new Object();
        validation_object['params']['definitions'][validation_object_index]['step_name'] = `Validate_${stepCount}`;
        validation_object['params']['definitions'][validation_object_index]['step_number'] = stepCount;
        validation_object['params']['definitions'][validation_object_index]['df'] = ele.formField.dataFrame;
        validation_object['params']['definitions'][validation_object_index]['alias'] = ele.formField.alias;
        validation_object['params']['definitions'][validation_object_index]['node_id'] = ele.nodeId;
        validation_object['params']['definitions'][validation_object_index]['sample_view'] = ele.formField.sample_view;
        validation_object['params']['definitions'][validation_object_index]['persist'] = ele.formField.persist;
        validation_object['params']['definitions'][validation_object_index]['persist_type'] =
          ele.formField.persist_type;
        validation_object['params']['definitions'][validation_object_index]['action'] = ele.formField.action;
        validation_object['params']['definitions'][validation_object_index]['path_validation'] = ele.formField.path;
        validation_object['params']['definitions'][validation_object_index]['path_profiling'] =
          ele.formField.profilingPath;

        const expectations_details = [];
        const newCol = [...ele.headerName];

        newCol.map((item) => {
          if (item.validation_name !== '') {
            const data = {
              expectation_name: item.validation_name,
              column: item.column,
            };

            delete item['validation_name'];
            delete item['column'];

            let param;

            if (data.expectation_name === 'expect_column_values_to_be_between') {
              param = {
                expectation_name: data.expectation_name,
                args: {
                  column: data.column,
                  max_value: parseInt(item.max_value, 10),
                  min_value: parseInt(item.min_value, 10),
                },
              };
            } else if (data.expectation_name === 'expect_column_values_to_be_in_set') {
              param = {
                expectation_name: data.expectation_name,
                args: {
                  column: data.column,
                  value_set: item.value_set.split(','),
                },
              };
            } else {
              param = {
                expectation_name: data.expectation_name,
                args: { column: data.column, ...item },
              };
            }

            expectations_details.push(param);
          }
        });

        validation_object['params']['definitions'][validation_object_index]['expectations_details'] =
          expectations_details;

        steps.push(validation_object);
        stepCount++;
        validation_object_index++;
        previous = true;
      } else if (ele.nodeName === 'Datatype Conversion') {
        let datatype_conversion_object_index = 0;
        const datatype_conversion_object = new Object();

        datatype_conversion_object['method_name'] = 'CHANGE_DATA_TYPE';
        datatype_conversion_object['params'] = new Object();
        datatype_conversion_object['params']['definitions'] = new Array();

        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index] = new Object();
        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index][
          'step_name'
        ] = `change_data_type_${stepCount}`;
        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index]['step_number'] =
          stepCount;
        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index]['node_id'] = ele.nodeId;
        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index]['df'] = ele.formField.df;
        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index]['db_name'] =
          ele.formField.db_name;
        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index]['alias'] =
          ele.formField.alias;
        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index]['sample_view'] =
          ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            if (el.cast_datatype === 'timestamp') {
              col_list.push({ col_name: el.header, type: el.cast_datatype, timestamp_format: el.time_stamp_format });
            } else {
              col_list.push({ col_name: el.header, type: el.cast_datatype });
            }
          }
        });

        const columns = { col_list };
        datatype_conversion_object['params']['definitions'][datatype_conversion_object_index]['columns'] = columns;

        stepCount++;
        datatype_conversion_object_index++;
        steps.push(datatype_conversion_object);
        previous = true;
      } else if (ele.nodeName === 'Concat') {
        let concat_object_index = 0;
        const concat_object = new Object();

        concat_object['method_name'] = 'CONCAT';
        concat_object['params'] = new Object();
        concat_object['params']['definitions'] = new Array();

        concat_object['params']['definitions'][concat_object_index] = new Object();
        concat_object['params']['definitions'][concat_object_index]['step_name'] = `concat_${stepCount}`;
        concat_object['params']['definitions'][concat_object_index]['step_number'] = stepCount;
        concat_object['params']['definitions'][concat_object_index]['node_id'] = ele.nodeId;
        concat_object['params']['definitions'][concat_object_index]['df'] = ele.formField.df;
        concat_object['params']['definitions'][concat_object_index]['alias'] = ele.formField.alias;
        concat_object['params']['definitions'][concat_object_index]['column1'] = ele.formField.column1;
        concat_object['params']['definitions'][concat_object_index]['column2'] = ele.formField.column2;
        concat_object['params']['definitions'][concat_object_index]['concat_seperator'] =
          ele.formField.concat_seperator;
        concat_object['params']['definitions'][concat_object_index]['outputcol'] = ele.formField.output_col_name;
        concat_object['params']['definitions'][concat_object_index]['sample_view'] = ele.formField.sample_view;

        stepCount++;
        concat_object_index++;
        steps.push(concat_object);
        previous = true;
      } else if (ele.nodeName === 'ViCell' || ele.nodeName === 'Instrument') {
        let vi_cell_defination_index = 0;

        const viCell_object = new Object();

        viCell_object['method_name'] = 'VICELL';
        viCell_object['params'] = new Object();
        viCell_object['params']['definitions'] = new Array();
        viCell_object['params']['definitions'][vi_cell_defination_index] = new Object();
        viCell_object['params']['definitions'][vi_cell_defination_index]['step_name'] = `Instrument`;
        viCell_object['params']['definitions'][vi_cell_defination_index]['step_number'] = stepCount;
        viCell_object['params']['definitions'][vi_cell_defination_index]['node_id'] = ele.nodeId;
        viCell_object['params']['definitions'][vi_cell_defination_index]['sample_view'] = ele.formField.sample_view;
        viCell_object['params']['definitions'][vi_cell_defination_index]['df'] = ele.formField.df;
        viCell_object['params']['definitions'][vi_cell_defination_index]['alias'] = ele.formField.alias;

        if (ele.nodeName === 'ViCell') {
          const data = ele.formField.vicell;

          const new_vi_cell_data = data.map((ele) => {
            return {
              fieldname: `\`${ele.fieldname}\``,
              type: ele.type,
              editable: ele.editable,
              fieldrules: [
                {
                  transformation_rules: ele.sanity_rule_names,
                  transformation_parameter: ele.required_parameter,
                  field_parameter: ele.sanity_parameter !== null ? `\`${ele.sanity_parameter}\`` : '',
                },
              ],
            };
          });
          viCell_object['params']['definitions'][vi_cell_defination_index]['fields'] = new_vi_cell_data;
        }
        if (ele.nodeName === 'Instrument') {
          const data = ele?.formField.instrument?.each_instrument;
          const new_instrument_data = data.map((item) => {
            return {
              fieldname: item.fieldname,
              type: item.type,
              editable: item.editable,
              fieldrules: [
                {
                  field_parameter: item.field_parameter || '',
                  transformation_rules: item.transformation_rules || '',
                },
              ],
            };
          });
          viCell_object['params']['definitions'][vi_cell_defination_index]['fields'] = new_instrument_data;
        }
        stepCount++;
        vi_cell_defination_index++;
        steps.push(viCell_object);
        previous = true;
      } else if (ele.nodeName === 'Date Time Feature') {
        let date_time_object_index = 0;
        const date_time_object = new Object();

        date_time_object['method_name'] = 'DATETIME_FEATURE';
        date_time_object['params'] = new Object();
        date_time_object['params']['definitions'] = new Array();

        date_time_object['params']['definitions'][date_time_object_index] = new Object();
        date_time_object['params']['definitions'][date_time_object_index][
          'step_name'
        ] = `datetime_feature_${stepCount}`;
        date_time_object['params']['definitions'][date_time_object_index]['step_number'] = stepCount;
        date_time_object['params']['definitions'][date_time_object_index]['node_id'] = ele.nodeId;
        date_time_object['params']['definitions'][date_time_object_index]['df'] = ele.formField.df;
        date_time_object['params']['definitions'][date_time_object_index]['db_name'] = ele.formField.db_name;
        date_time_object['params']['definitions'][date_time_object_index]['alias'] = ele.formField.alias;
        date_time_object['params']['definitions'][date_time_object_index]['sample_view'] = ele.formField.sample_view;

        const col_list = [];
        ele.sorting.forEach((el) => {
          col_list.push({ output_col_name: el.alias, date_feature: el.date_feature, timestamp_col: el.attribute });
          // if (el.cast_datatype === 'timestamp') {
          //   col_list.push({ output_col_name: el.alias, date_feature: el.date_feature, timestamp_col: el.attribute });
          // } else {
          //   col_list.push({ output_col_name: el.alias, date_feature: el.date_feature, timestamp_col: el.attribute });
          // }
        });

        const columns = { col_list };
        date_time_object['params']['definitions'][date_time_object_index]['columns'] = columns;

        stepCount++;
        date_time_object_index++;
        steps.push(date_time_object);
        previous = true;
      } else if (ele.nodeName === 'Handle Null') {
        let handle_null_object_index = 0;
        const handle_null_object = new Object();

        handle_null_object['method_name'] = 'HANDLING_NULL_VALUES';
        handle_null_object['params'] = new Object();
        handle_null_object['params']['definitions'] = new Array();

        handle_null_object['params']['definitions'][handle_null_object_index] = new Object();
        handle_null_object['params']['definitions'][handle_null_object_index]['step_name'] = `handle_null_${stepCount}`;
        handle_null_object['params']['definitions'][handle_null_object_index]['step_number'] = stepCount;
        handle_null_object['params']['definitions'][handle_null_object_index]['node_id'] = ele.nodeId;
        handle_null_object['params']['definitions'][handle_null_object_index]['df'] = ele.formField.df;
        handle_null_object['params']['definitions'][handle_null_object_index]['db_name'] = ele.formField.db_name;
        handle_null_object['params']['definitions'][handle_null_object_index]['alias'] = ele.formField.alias;
        handle_null_object['params']['definitions'][handle_null_object_index]['sample_view'] =
          ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            if (el.handling_type === 'withsomevalue') {
              col_list.push({
                input_col_name: el.header,
                null_handling_type: el.handling_type,
                value: el.value,
                data_type: el.cast_datatype,
              });
            } else if (el.handling_type === 'impute_with_hmean_median_mode') {
              col_list.push({
                input_col_name: el.header,
                null_handling_type: el.handling_type,
                impute_stratergy: el.impute,
                data_type: el.cast_datatype,
              });
            } else {
              col_list.push({
                input_col_name: el.header,
                null_handling_type: el.handling_type,
                data_type: el.cast_datatype,
              });
            }
          }
        });

        const columns = { col_list };
        handle_null_object['params']['definitions'][handle_null_object_index]['columns'] = columns;

        stepCount++;
        handle_null_object_index++;
        steps.push(handle_null_object);
        previous = true;
      } else if (ele.nodeName === 'OneHot Encoder') {
        let onehot_encoder_object_index = 0;
        const onehot_encoder_object = new Object();

        onehot_encoder_object['method_name'] = 'ONEHOT_ENCODER';
        onehot_encoder_object['params'] = new Object();
        onehot_encoder_object['params']['definitions'] = new Array();

        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index] = new Object();
        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index][
          'step_name'
        ] = `onehot_encoder_${stepCount}`;
        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index]['step_number'] = stepCount;
        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index]['node_id'] = ele.nodeId;
        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index]['df'] = ele.formField.df;
        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index]['db_name'] = ele.formField.db_name;
        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index]['alias'] = ele.formField.alias;
        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index]['sample_view'] =
          ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            col_list.push({
              input_col_name: el.header,
              data_type: el.cast_datatype,
            });
          }
        });

        const columns = { col_list };
        onehot_encoder_object['params']['definitions'][onehot_encoder_object_index]['columns'] = columns;

        stepCount++;
        onehot_encoder_object_index++;
        steps.push(onehot_encoder_object);
        previous = true;
      } else if (ele.nodeName === 'Label Encoder') {
        let label_encoder_object_index = 0;
        const label_encoder_object = new Object();

        label_encoder_object['method_name'] = 'LABEL_ENCODER';
        label_encoder_object['params'] = new Object();
        label_encoder_object['params']['definitions'] = new Array();

        label_encoder_object['params']['definitions'][label_encoder_object_index] = new Object();
        label_encoder_object['params']['definitions'][label_encoder_object_index][
          'step_name'
        ] = `label_encoder_${stepCount}`;
        label_encoder_object['params']['definitions'][label_encoder_object_index]['step_number'] = stepCount;
        label_encoder_object['params']['definitions'][label_encoder_object_index]['node_id'] = ele.nodeId;
        label_encoder_object['params']['definitions'][label_encoder_object_index]['df'] = ele.formField.df;
        label_encoder_object['params']['definitions'][label_encoder_object_index]['db_name'] = ele.formField.db_name;
        label_encoder_object['params']['definitions'][label_encoder_object_index]['alias'] = ele.formField.alias;
        label_encoder_object['params']['definitions'][label_encoder_object_index]['sample_view'] =
          ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            col_list.push({
              input_col_name: el.header,
              output_col_name: el.output_column,
              data_type: el.cast_datatype,
            });
          }
        });

        const columns = { col_list };
        label_encoder_object['params']['definitions'][label_encoder_object_index]['columns'] = columns;

        stepCount++;
        label_encoder_object_index++;
        steps.push(label_encoder_object);
        previous = true;
      } else if (ele.nodeName === 'Drop') {
        let drop_object_index = 0;
        const drop_object = new Object();

        drop_object['method_name'] = 'DROP';
        drop_object['params'] = new Object();
        drop_object['params']['definitions'] = new Array();

        drop_object['params']['definitions'][drop_object_index] = new Object();
        drop_object['params']['definitions'][drop_object_index]['step_name'] = `drop_${stepCount}`;
        drop_object['params']['definitions'][drop_object_index]['step_number'] = stepCount;
        drop_object['params']['definitions'][drop_object_index]['node_id'] = ele.nodeId;
        drop_object['params']['definitions'][drop_object_index]['df'] = ele.formField.df;
        drop_object['params']['definitions'][drop_object_index]['db_name'] = ele.formField.db_name;
        drop_object['params']['definitions'][drop_object_index]['alias'] = ele.formField.alias;
        drop_object['params']['definitions'][drop_object_index]['sample_view'] = ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            col_list.push({
              input_col_name: el.header,
            });
          }
        });

        const columns = { col_list };
        drop_object['params']['definitions'][drop_object_index]['columns'] = columns;

        stepCount++;
        drop_object_index++;
        steps.push(drop_object);
        previous = true;
      } else if (ele.nodeName === 'Outlier') {
        let outlier_object_index = 0;
        const outlier_object = new Object();

        outlier_object['method_name'] = 'OUTLIER';
        outlier_object['params'] = new Object();
        outlier_object['params']['definitions'] = new Array();

        outlier_object['params']['definitions'][outlier_object_index] = new Object();
        outlier_object['params']['definitions'][outlier_object_index]['step_name'] = `outlier_${stepCount}`;
        outlier_object['params']['definitions'][outlier_object_index]['step_number'] = stepCount;
        outlier_object['params']['definitions'][outlier_object_index]['node_id'] = ele.nodeId;
        outlier_object['params']['definitions'][outlier_object_index]['df'] = ele.formField.df;
        outlier_object['params']['definitions'][outlier_object_index]['db_name'] = ele.formField.db_name;
        outlier_object['params']['definitions'][outlier_object_index]['alias'] = ele.formField.alias;
        outlier_object['params']['definitions'][outlier_object_index]['sample_view'] = ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            if (el.outlier_stratergy === '') {
              col_list.push({
                input_col_name: el.header,
                outlier_type: el.outlier_type,
              });
            } else {
              col_list.push({
                input_col_name: el.header,
                outlier_type: el.outlier_type,
                outlier_stratergy: el.outlier_stratergy,
              });
            }
          }
        });

        const columns = { col_list };
        outlier_object['params']['definitions'][outlier_object_index]['columns'] = columns;

        stepCount++;
        outlier_object_index++;
        steps.push(outlier_object);
        previous = true;
      } else if (ele.nodeName === 'Imbalanced Data') {
        let imbalanced_data_object_index = 0;
        const imbalanced_data_object = new Object();

        imbalanced_data_object['method_name'] = 'HANDEL_IMBALANCED_DATA';
        imbalanced_data_object['params'] = new Object();
        imbalanced_data_object['params']['definitions'] = new Array();

        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index] = new Object();
        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index][
          'step_name'
        ] = `imbalanced_data_${stepCount}`;
        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index]['step_number'] = stepCount;
        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index]['node_id'] = ele.nodeId;
        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index]['df'] = ele.formField.df;
        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index]['db_name'] =
          ele.formField.db_name;
        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index]['alias'] = ele.formField.alias;
        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index]['sample_view'] =
          ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            col_list.push({
              input_col_name: el.header,
              majority_class: el.majority_class,
              minority_class: el.minority_class,
              ratio: el.ratio,
              type_of_sampling: el.type_of_sampling,
            });
          }
        });

        const columns = { col_list };
        imbalanced_data_object['params']['definitions'][imbalanced_data_object_index]['columns'] = columns;

        stepCount++;
        imbalanced_data_object_index++;
        steps.push(imbalanced_data_object);
        previous = true;
      } else if (ele.nodeName === 'Binning') {
        let binning_object_index = 0;
        const binning_object = new Object();

        binning_object['method_name'] = 'BINNING';
        binning_object['params'] = new Object();
        binning_object['params']['definitions'] = new Array();

        binning_object['params']['definitions'][binning_object_index] = new Object();
        binning_object['params']['definitions'][binning_object_index]['step_name'] = `binning_${stepCount}`;
        binning_object['params']['definitions'][binning_object_index]['step_number'] = stepCount;
        binning_object['params']['definitions'][binning_object_index]['node_id'] = ele.nodeId;
        binning_object['params']['definitions'][binning_object_index]['df'] = ele.formField.df;
        binning_object['params']['definitions'][binning_object_index]['db_name'] = ele.formField.db_name;
        binning_object['params']['definitions'][binning_object_index]['no_of_bins'] = ele.formField.no_of_bins;
        binning_object['params']['definitions'][binning_object_index]['alias'] = ele.formField.alias;
        binning_object['params']['definitions'][binning_object_index]['sample_view'] = ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            col_list.push({
              input_col_name: el.header,
              output_col_name: el.output_column,
            });
          }
        });

        const columns = { col_list };
        binning_object['params']['definitions'][binning_object_index]['columns'] = columns;

        stepCount++;
        binning_object_index++;
        steps.push(binning_object);
        previous = true;
      } else if (ele.nodeName === 'Scaler') {
        let scaler_object_index = 0;
        const scaler_object = new Object();

        scaler_object['method_name'] = 'SCALER';
        scaler_object['params'] = new Object();
        scaler_object['params']['definitions'] = new Array();

        scaler_object['params']['definitions'][scaler_object_index] = new Object();
        scaler_object['params']['definitions'][scaler_object_index]['step_name'] = `scaler_${stepCount}`;
        scaler_object['params']['definitions'][scaler_object_index]['step_number'] = stepCount;
        scaler_object['params']['definitions'][scaler_object_index]['node_id'] = ele.nodeId;
        scaler_object['params']['definitions'][scaler_object_index]['df'] = ele.formField.df;
        scaler_object['params']['definitions'][scaler_object_index]['db_name'] = ele.formField.db_name;
        scaler_object['params']['definitions'][scaler_object_index]['alias'] = ele.formField.alias;
        scaler_object['params']['definitions'][scaler_object_index]['sample_view'] = ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            col_list.push({
              input_col_name: el.header,
              output_col_name: el.output_column,
              Scaler_type: el.Scaler_type,
              input_col_drop: el.input_col_drop,
            });
          }
        });

        const columns = { col_list };
        scaler_object['params']['definitions'][scaler_object_index]['columns'] = columns;

        stepCount++;
        scaler_object_index++;
        steps.push(scaler_object);
        previous = true;
      } else if (ele.nodeName === 'Resample') {
        let resample_object_index = 0;
        const resample_object = new Object();

        resample_object['method_name'] = 'RESAMPLE';
        resample_object['params'] = new Object();
        resample_object['params']['definitions'] = new Array();

        resample_object['params']['definitions'][resample_object_index] = new Object();
        resample_object['params']['definitions'][resample_object_index]['step_name'] = `resample_${stepCount}`;
        resample_object['params']['definitions'][resample_object_index]['step_number'] = stepCount;
        resample_object['params']['definitions'][resample_object_index]['node_id'] = ele.nodeId;
        resample_object['params']['definitions'][resample_object_index]['df'] = ele.formField.df;
        resample_object['params']['definitions'][resample_object_index]['db_name'] = ele.formField.db_name;
        resample_object['params']['definitions'][resample_object_index]['alias'] = ele.formField.alias;
        resample_object['params']['definitions'][resample_object_index]['sample_view'] = ele.formField.sample_view;

        const col_list = [];
        ele.fetchedHeader.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            col_list.push({
              timestamp_column_name: el.header,
              time_frame_window: el.time_frame_window,
              aggregation_function: el.aggregation_function,
              column_name: el.output_column,
            });
          }
        });

        const columns = { col_list };
        resample_object['params']['definitions'][resample_object_index]['columns'] = columns;

        stepCount++;
        resample_object_index++;
        steps.push(resample_object);
        previous = true;
      } else if (ele.nodeName === 'Training') {
        let handle_training_object_index = 0;
        const handle_training_object = new Object();

        handle_training_object['method_name'] = 'MULTI_MODEL_TRAIN';
        handle_training_object['params'] = new Object();
        handle_training_object['params']['definitions'] = new Array();

        handle_training_object['params']['definitions'][handle_training_object_index] = new Object();
        handle_training_object['params']['definitions'][handle_training_object_index][
          'step_name'
        ] = `train_${stepCount}`;
        handle_training_object['params']['definitions'][handle_training_object_index]['step_number'] = stepCount;
        handle_training_object['params']['definitions'][handle_training_object_index]['node_id'] = ele.nodeId;
        handle_training_object['params']['definitions'][handle_training_object_index]['df'] = ele.formField.df;
        handle_training_object['params']['definitions'][handle_training_object_index]['alias'] = ele.formField.alias;
        handle_training_object['params']['definitions'][handle_training_object_index]['savePath'] = ele.formField.path;
        handle_training_object['params']['definitions'][handle_training_object_index]['sample_view'] =
          ele.formField.sample_view;

        const targetCol = {};
        const algorithmArr = [];
        let token = '';

        const split_ratio = {
          train: ele.formField.train,
          test: ele.formField.test,
          seed: ele.formField.seed,
        };

        if (ele.formField.algorithm === 'LinearRegression' || ele.formField.algorithm === 'LogisticRegression') {
          algorithmArr.push({
            maxIter: ele.formField.maxIter,
            regParam: ele.formField.regParam,
            elasticNetParam: ele.formField.elasticNetParam,
          });
        } else if (ele.formField.algorithm === 'RandomForestRegressor') {
          algorithmArr.push({
            numTrees: ele.formField.numTrees,
            maxDepth: ele.formField.maxDepth,
          });
        } else if (ele.formField.algorithm === 'SVM') {
          algorithmArr.push({
            maxIter: ele.formField.maxIter,
            regParam: ele.formField.regParam,
          });
        } else if (ele.formField.algorithm === 'KMeans' || ele.formField.algorithm === 'LDA') {
          algorithmArr.push({
            k: ele.formField.k,
            maxIter: ele.formField.maxIter,
          });
        } else if (ele.formField.algorithm === 'ALS') {
          algorithmArr.push({
            maxIter: ele.formField.maxIter,
            regParam: ele.formField.regParam,
            rank: ele.formField.rank,
          });
        }

        const algo_type = {
          [ele.formField.algo_type]: {
            [ele.formField.algorithm]: algorithmArr,
          },
        };
        const feature_list = [];
        ele.headerName.forEach((el) => {
          if (el.col_type !== 'target Col' && (el.checked === 'true' || el.checked === true)) {
            feature_list.push({
              col_name: el.header,
              type: el.cast_datatype,
            });
          } else {
            targetCol['col_name'] = el.header;
            targetCol['type'] = el.cast_datatype;
          }
        });

        const ratingCol = {};
        const userCol = {};
        const itemCol = {};
        console.log('Element printing', ele);

        ele.headerName.forEach((el) => {
          if (
            el.col_type !== 'user col' &&
            (el.checked === 'true' || el.checked === true) &&
            el.col_type !== 'item col' &&
            (el.checked === 'true' || el.checked === true)
          ) {
            ratingCol['col_name'] = el.header;
            ratingCol['type'] = el.cast_datatype;
          } else if (
            el.col_type !== 'rating col' &&
            (el.checked === 'true' || el.checked === true) &&
            el.col_type !== 'item col' &&
            (el.checked === 'true' || el.checked === true)
          ) {
            userCol['col_name'] = el.header;
            console.log(userCol);
            userCol['type'] = el.cast_datatype;
          } else if (
            el.col_type !== 'rating col' &&
            (el.checked === 'true' || el.checked === true) &&
            el.col_type !== 'user col' &&
            (el.checked === 'true' || el.checked === true)
          ) {
            itemCol['col_name'] = el.header;
            itemCol['type'] = el.cast_datatype;
          }
        });

        token = `${ele.formField.algo_type}-${ele.formField.algorithm}`;
        const features = { feature_list };
        const users = { userCol };
        const items = { itemCol };
        if (ele.formField.algo_type === 'recommendation' && ele.formField.algorithm === 'ALS') {
          handle_training_object['params']['definitions'][handle_training_object_index]['userCol'] = userCol;
          handle_training_object['params']['definitions'][handle_training_object_index]['ratingCol'] = ratingCol;
          handle_training_object['params']['definitions'][handle_training_object_index]['itemCol'] = itemCol;
        } else {
          handle_training_object['params']['definitions'][handle_training_object_index]['features'] = features;
          handle_training_object['params']['definitions'][handle_training_object_index]['targetCol'] = targetCol;
        }
        handle_training_object['params']['definitions'][handle_training_object_index]['algo_type'] = algo_type;
        handle_training_object['params']['definitions'][handle_training_object_index]['split_ratio'] = split_ratio;
        handle_training_object['params']['definitions'][handle_training_object_index]['token'] = token;

        stepCount++;
        handle_training_object_index++;
        steps.push(handle_training_object);
        previous = true;
      } else if (ele.nodeName === 'Prediction') {
        let handle_prediction_object_index = 0;
        const handle_prediction_object = new Object();
        let token = '';

        const userCol = {};
        const itemCol = {};
        userCol['col_name'] = ele.formField.user_col;
        itemCol['col_name'] = ele.formField.item_col;

        const timestamp = ele.formField.path;
        const algo_type = {
          [ele.formField.algo_type]: {
            [ele.formField.algorithm]: [{ timestamp }],
          },
        };

        handle_prediction_object['method_name'] = 'PRED';
        handle_prediction_object['params'] = new Object();
        handle_prediction_object['params']['definitions'] = new Array();
        token = `${ele.formField.algo_type}-${ele.formField.algorithm}`;

        handle_prediction_object['params']['definitions'][handle_prediction_object_index] = new Object();
        if (ele.formField.algo_type === 'recommendation' && ele.formField.algorithm === 'ALS') {
          handle_prediction_object['params']['definitions'][handle_prediction_object_index]['userCol'] = userCol;
          handle_prediction_object['params']['definitions'][handle_prediction_object_index]['itemCol'] = itemCol;
          handle_prediction_object['params']['definitions'][handle_prediction_object_index]['algo_type'] = algo_type;
          handle_prediction_object['params']['definitions'][handle_prediction_object_index]['token'] = token;
          handle_prediction_object['params']['definitions'][handle_prediction_object_index]['alias'] =
            ele.formField.alias;
        } else {
          handle_prediction_object['params']['definitions'][handle_prediction_object_index]['algo_type'] = algo_type;
          handle_prediction_object['params']['definitions'][handle_prediction_object_index]['token'] = token;
          handle_prediction_object['params']['definitions'][handle_prediction_object_index]['alias'] =
            ele.formField.alias;
        }

        handle_prediction_object['params']['definitions'][handle_prediction_object_index]['sample_view'] =
          ele.formField.sample_view;

        handle_prediction_object['params']['definitions'][handle_prediction_object_index]['df'] = ele.formField.df;
        handle_prediction_object['params']['definitions'][handle_prediction_object_index]['step_number'] = stepCount;
        handle_prediction_object['params']['definitions'][handle_prediction_object_index][
          'step_name'
        ] = `pred_${stepCount}`;

        handle_prediction_object['params']['definitions'][handle_prediction_object_index]['node_id'] = ele.nodeId;

        stepCount++;
        handle_prediction_object_index++;
        steps.push(handle_prediction_object);
      } else if (ele.nodeName === 'Profiling') {
        if (profiling_object_boolean === false) {
          profiling_object = new Object();
          profiling_object['method_name'] = 'DataProfiling';
          profiling_object['params'] = new Object();
          profiling_object['params']['definitions'] = new Array();
          profiling_object_boolean = true;
        }

        profiling_object['params']['definitions'][profiling_object_index] = new Object();
        profiling_object['params']['definitions'][profiling_object_index]['step_name'] = `Profile_${stepCount}`;
        profiling_object['params']['definitions'][profiling_object_index]['step_number'] = stepCount;
        profiling_object['params']['definitions'][profiling_object_index]['node_id'] = ele.nodeId;
        profiling_object['params']['definitions'][profiling_object_index]['df'] = ele.formField.dataFrame;
        profiling_object['params']['definitions'][profiling_object_index]['alias'] = ele.formField.alias;
        profiling_object['params']['definitions'][profiling_object_index]['path_profiling'] =
          ele.formField.profilingPath;
        profiling_object['params']['definitions'][profiling_object_index]['path_expectations'] =
          ele.formField.expectationsPath;
        profiling_object['params']['definitions'][profiling_object_index]['action'] = ele.formField.action
          ? ele.formField.action
          : '';
        profiling_object['params']['definitions'][profiling_object_index]['sample_view'] = ele.formField.sample_view;
        profiling_object['params']['definitions'][profiling_object_index]['persist'] = ele.formField.persist;

        if (ele.formField.persist === true) {
          profiling_object['params']['definitions'][profiling_object_index]['persist_type'] =
            ele.formField.persist_type;
        } else {
          profiling_object['params']['definitions'][profiling_object_index]['persist_type'] = '';
        }
        profiling_object_index++;
        profiling_object_arr.push(profiling_object);
        if (profiling_boolean) {
          steps.push(profiling_object_arr[0]);
          profiling_boolean = false;
        }
        stepCount++;
        previous = true;
      } else if (ele.nodeName === 'Math Transformation') {
        let handle_math_transform_object_index = 0;
        const handle_math_transform_object = new Object();

        handle_math_transform_object['method_name'] = 'MATH_TRANSFORMATION';
        handle_math_transform_object['params'] = new Object();
        handle_math_transform_object['params']['definitions'] = new Array();

        handle_math_transform_object['params']['definitions'][handle_math_transform_object_index] = new Object();
        handle_math_transform_object['params']['definitions'][handle_math_transform_object_index][
          'step_name'
        ] = `math_transformation_${stepCount}`;
        handle_math_transform_object['params']['definitions'][handle_math_transform_object_index]['db_name'] =
          ele.formField.db_name;

        handle_math_transform_object['params']['definitions'][handle_math_transform_object_index]['alias'] =
          ele.formField.alias;
        handle_math_transform_object['params']['definitions'][handle_math_transform_object_index]['sample_view'] =
          ele.formField.sample_view;

        const col_list = [];

        ele.headerName.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.transform === 'true') {
            if (el.power === '') {
              col_list.push({
                input_col_name: el.header,
                output_col_name: el.output_column,
                transform_type: el.transform_type,
                data_type: el.cast_datatype,
              });
            }
          } else {
            col_list.push({
              input_col_name: el.header,
              output_col_name: el.output_column,
              transform_type: el.transform_type,
              data_type: el.cast_datatype,
              power: el.power ?? '',
            });
          }
        });
        const columns = { col_list };
        handle_math_transform_object['params']['definitions'][handle_math_transform_object_index]['columns'] = columns;

        stepCount++;
        handle_math_transform_object_index++;
        steps.push(handle_math_transform_object);
        previous = true;
      } else if (
        ele.nodeName === 'Write' ||
        ele.nodeName === 'SnowFlake' ||
        ele.nodeName === 'CopyDoc' ||
        ele.nodeName === 'Postgres' ||
        ele.nodeName === 'OpenSearch'
      ) {
        // if (write_object_boolean === false) {
        write_object = new Object();
        write_object['method_name'] = 'Write';
        write_object['params'] = new Object();
        write_object['params']['definitions'] = new Array();
        write_object_boolean = true;
        // }

        write_object['params']['definitions'][handle_write_object_index] = new Object();
        write_object['params']['definitions'][handle_write_object_index]['step_name'] = `Write_${stepCount}`;
        write_object['params']['definitions'][handle_write_object_index]['step_number'] = stepCount;
        write_object['params']['definitions'][handle_write_object_index]['node_id'] = ele.nodeId;
        if (ele.nodeName !== 'CopyDoc') {
          write_object['params']['definitions'][handle_write_object_index]['sample_view'] = ele.formField.sample_view;
        }
        if (ele.nodeName === 'CopyDoc') {
          const encoder = new TextEncoder('utf-8');
          const pathname = ele.formField.innerPath;
          const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
          const utf8Encoded = encoder.encode(filename);

          const lastIndex = pathname.lastIndexOf('/');
          const pathWithoutFilename = pathname.substring(0, lastIndex);

          write_object['method_name'] = 'Write_SFTP';
          write_object['params']['definitions'][handle_write_object_index]['file_path'] = pathWithoutFilename;
          write_object['params']['definitions'][handle_write_object_index]['utf8Encoded'] = utf8Encoded;
          write_object['params']['definitions'][handle_write_object_index]['client_name_cd'] = ele.formField.clientName;
          write_object['params']['definitions'][handle_write_object_index]['batch_name_cd'] = ele.formField.batchName;
          write_object['params']['definitions'][handle_write_object_index]['job_name_cd'] = ele.formField.tableNameRead;
          write_object['params']['definitions'][handle_write_object_index]['language'] = ele.formField.dataSetName;
          write_object['params']['definitions'][handle_write_object_index]['step_name'] = 'Write_SFTP';
        }
        if (ele.nodeName === 'Write') {
          if (ele.formField.format === 'streaming') {
            write_object['params']['definitions'][handle_write_object_index]['stream_interval'] = parseInt(
              ele.formField.stream_interval,
              10
            );
          }
        }
        if (ele.toggleType === 'S3') {
          write_object['params']['definitions'][handle_write_object_index]['format'] = ele.formField.format;
          write_object['params']['definitions'][handle_write_object_index]['overwrite'] = ele.formField.overwrite;
          write_object['params']['definitions'][handle_write_object_index]['df'] = ele.formField.df;
          write_object['params']['definitions'][handle_write_object_index]['alias'] = ele.formField.alias;
          if (track_id !== 'snowflake-garbageTID') {
            write_object['params']['definitions'][handle_write_object_index][
              'path'
            ] = `${ele.formField.path}${track_id}/`;
          } else {
            write_object['params']['definitions'][handle_write_object_index]['path'] = `${ele.formField.path}`;
          }
          write_object['params']['definitions'][handle_write_object_index]['partition'] = ele.formField.partition;
          write_object['params']['definitions'][handle_write_object_index]['persist'] = ele.formField.persist;
          write_object['params']['definitions'][handle_write_object_index]['distinct_rows'] =
            ele.formField.distinct_rows;

          if (ele.formField.persist === true) {
            write_object['params']['definitions'][handle_write_object_index]['persist_type'] =
              ele.formField.persist_type;
          } else {
            write_object['params']['definitions'][handle_write_object_index]['persist_type'] = '';
          }
          if (ele.formField.format !== 'streaming') {
            write_object['params']['definitions'][handle_write_object_index]['target'] = AWS_CONFIG_TYPE
              ? 's3'
              : 'adls';
          } else {
            write_object['params']['definitions'][handle_write_object_index]['target'] = 'streaming';
          }

          // const newArray = [];

          // if (ele.headerName.length > 0) {
          //   ele.headerName.forEach((el) => {
          //     if (el.alias) {
          //       newArray.push(`${el.header} as ${el.alias}`);
          //     } else {
          //       newArray.push(el.header);
          //     }
          //   });

          //   write_object['params']['definitions'] = newArray.toString();
          // }
        } else if (ele.toggleType === 'Write SFTP') {
          write_object['params']['definitions'][handle_write_object_index]['df'] = ele.formField.df;
          write_object['params']['definitions'][handle_write_object_index]['index'] = ele.formField.index;
          write_object['params']['definitions'][handle_write_object_index]['spark_config'] = ele.formField.spark_config;
          write_object['params']['definitions'][handle_write_object_index]['udf'] = ele.formField.udf;
          write_object['params']['definitions'][handle_write_object_index]['file_path'] = ele.formField.path;
        } else if (ele.toggleType === 'Open Search') {
          write_object['params']['definitions'][handle_write_object_index]['df'] = ele.formField.df;
          write_object['params']['definitions'][handle_write_object_index]['index'] = ele.formField.index;
          write_object['params']['definitions'][handle_write_object_index]['primary_key'] = ele.formField.p_key;
          write_object['params']['definitions'][handle_write_object_index]['target'] = 'opensearch';
          write_object['params']['definitions'][handle_write_object_index]['distinct_rows'] =
            ele.formField.distinct_rows;
        } else if (ele.toggleType === 'Database') {
          write_object['params']['definitions'][handle_write_object_index]['df'] = ele.formField.df;
          write_object['params']['definitions'][handle_write_object_index]['db_type'] = ele.formField.db_type;
          write_object['params']['definitions'][handle_write_object_index]['service'] = ele.formField.database;
          write_object['params']['definitions'][handle_write_object_index]['database'] = ele.formField.schema;
          write_object['params']['definitions'][handle_write_object_index]['tablename'] = ele.formField.tablename;
          write_object['params']['definitions'][handle_write_object_index]['mode'] = ele.formField.mode;
          write_object['params']['definitions'][handle_write_object_index]['target'] = 'database';
          write_object['params']['definitions'][handle_write_object_index]['distinct_rows'] =
            ele.formField.distinct_rows;
          write_object['params']['definitions'][handle_write_object_index]['sample_view'] =
            ele.formField.sample_view === null ? false : ele.formField.sample_view;
        } else if (ele.toggleType === 'SnowFlake') {
          write_object['params']['definitions'][handle_write_object_index]['df'] = ele.formField.df;
          write_object['params']['definitions'][handle_write_object_index]['mode'] = ele.formField.snowflake_mode;
          write_object['params']['definitions'][handle_write_object_index]['dbtable'] =
            ele.formField.snowflake_db_table;
          write_object['params']['definitions'][handle_write_object_index]['node_id'] = ele.nodeId;
          write_object['params']['definitions'][handle_write_object_index]['database'] = ele.formField.database;
          write_object['params']['definitions'][handle_write_object_index]['schema'] = ele.formField.schema;
          write_object['params']['definitions'][handle_write_object_index]['target'] = 'snowflake';
          write_object['params']['definitions'][handle_write_object_index]['sample_view'] =
            ele.formField.sample_view === null ? false : ele.formField.sample_view;
          write_object['params']['definitions'][handle_write_object_index]['distinct_rows'] =
            ele.formField.distinct_rows;
          write_object['params']['definitions'][handle_write_object_index]['persist'] = ele.formField.persist;
          if (ele.formField.persist === true) {
            write_object['params']['definitions'][handle_write_object_index]['persist_type'] =
              ele.formField.persist_type;
          } else {
            write_object['params']['definitions'][handle_write_object_index]['persist_type'] = '';
          }
        }

        // handle_write_object_index++;
        // write_object_arr.push(write_object);
        // if (write_boolean) {
        //   steps.push(write_object_arr[0]);
        //   write_boolean = false;
        // }

        stepCount++;
        steps.push(write_object);
        previous = true;
      } else if (ele.nodeName === 'WriteToBenchling') {
        let writeBenchling_defination_index = 0;

        const writeBenchling_object = new Object();

        writeBenchling_object['method_name'] = 'WriteToBenchling';
        writeBenchling_object['params'] = new Object();
        writeBenchling_object['params']['definitions'] = new Array();
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index] = new Object();
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index] = new Object();
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index][
          'step_name'
        ] = `WriteToBenchling_${stepCount}`;
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index]['step_number'] = stepCount;
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index]['node_id'] = ele.nodeId;
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index]['sample_view'] =
          ele.formField.sample_view;
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index]['df'] = ele.formField.df;
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index]['alias'] = ele.formField.alias;
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index]['project_id'] =
          ele.formField.project_id;
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index]['table_id'] =
          ele.formField.table_id;
        writeBenchling_object['params']['definitions'][writeBenchling_defination_index]['schema_id'] =
          ele.formField.schema_id;

        stepCount++;
        writeBenchling_defination_index++;
        steps.push(writeBenchling_object);
        previous = true;
      } else if (ele.nodeName === 'AutoRefresh') {
        let updateBenchling_defination_index = 0;
        const updateBenchling_object = new Object();

        updateBenchling_object['method_name'] = 'AutoRefresh';
        updateBenchling_object['params'] = new Object();
        updateBenchling_object['params']['definitions'] = new Array();
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index] = new Object();
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index] = new Object();
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index][
          'step_name'
        ] = `UpdateToBenchling_${stepCount}`;
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index]['step_number'] = stepCount;
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index]['node_id'] = ele.nodeId;
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index]['alias'] =
          ele.formField.alias;
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index]['name'] =
          ele.formField.username;
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index]['project_id'] =
          ele.formField.project_id;
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index]['project_name'] =
          ele.formField.project_name;
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index]['entry_id'] =
          ele.formField.entry_id;
        updateBenchling_object['params']['definitions'][updateBenchling_defination_index]['entry_name'] =
          ele.formField.entry_name;

        stepCount++;
        updateBenchling_defination_index++;
        steps.push(updateBenchling_object);
        previous = true;
      } else if (ele.nodeName === 'Email') {
        let email_object_index = 0;
        const email_object = new Object();

        email_object['method_name'] = 'SENDEMAIL';
        email_object['params'] = new Object();
        email_object['params']['definitions'] = new Array();

        email_object['params']['definitions'][email_object_index] = new Object();
        email_object['params']['definitions'][email_object_index]['step_name'] = `sendemail_${stepCount}`;
        email_object['params']['definitions'][email_object_index]['step_number'] = stepCount;
        email_object['params']['definitions'][email_object_index]['node_id'] = ele.nodeId;
        email_object['params']['definitions'][email_object_index]['df'] = ele.formField.df;
        email_object['params']['definitions'][email_object_index]['column'] = ele.formField.column;
        email_object['params']['definitions'][email_object_index]['filter_condition'] = ele.formField.filter_condition;
        email_object['params']['definitions'][email_object_index]['operator'] = ele.formField.operator;
        email_object['params']['definitions'][email_object_index]['recipients_list'] =
          ele.formField.recipients_list.split(',');
        email_object['params']['definitions'][email_object_index]['subject'] = ele.formField.subject;
        email_object['params']['definitions'][email_object_index]['column'] = ele.formField.column;

        email_object['params']['definitions'][email_object_index]['alias'] = ele.formField.alias;
        email_object['params']['definitions'][email_object_index]['sample_view'] = ele.formField.sample_view;

        stepCount++;
        email_object_index++;
        steps.push(email_object);
      } else if (
        ele.nodeName === 'Execute SQL' ||
        ele.nodeName === 'Sort' ||
        ele.nodeName === 'Filter' ||
        ele.nodeName === 'Aggregation' ||
        ele.nodeName === 'Coalesce' ||
        ele.nodeName === 'Union' ||
        ele.nodeName === 'KPI'
      ) {
        if (previous) {
          executeSQL_object_boolean = false;
          executeSQL_boolean = true;
          execute_sql_object_arr = [];
          executeSQL_object_index = 0;
        }
        // if (executeSQL_object_boolean === false) {
        executeSQL_object = new Object();
        executeSQL_object['method_name'] = 'ExecuteSQL';
        executeSQL_object['params'] = new Object();
        executeSQL_object['params']['definitions'] = new Array();
        executeSQL_object_boolean = true;
        // }
        executeSQL_object['params']['definitions'][executeSQL_object_index] = new Object();
        executeSQL_object['params']['definitions'][executeSQL_object_index][
          'step_name'
        ] = `${ele.step_name}_${stepCount}`;
        executeSQL_object['params']['definitions'][executeSQL_object_index]['node_id'] = ele.nodeId;
        if (ele.toggleType === 'KPI') {
          executeSQL_object['params']['definitions'][executeSQL_object_index]['sql_type'] = ele.toggleType;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['KPI'] = ele.formField.kpi;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['kpi_id'] = ele.formField.kpi_id;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['step_number'] = stepCount;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['df'] = ele.formField.alias;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['db_name'] = ele.formField.db_name;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['statement'] = ele.formField.statement;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['persist'] = ele.formField.persist;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['sample_view'] =
            ele.formField.sample_view;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['distinct_rows'] =
            ele.formField.distinct_rows;
          if (ele.formField.persist === true) {
            executeSQL_object['params']['definitions'][executeSQL_object_index]['persist_type'] =
              ele.formField.persist_type;
          } else {
            executeSQL_object['params']['definitions'][executeSQL_object_index]['persist_type'] = '';
          }
          executeSQL_object['params']['definitions'][executeSQL_object_index]['action'] = ele.formField.action
            ? ele.formField.action
            : '';
          const newArray = [];
          if (ele.headerName.length > 0) {
            ele.headerName.forEach((el) => {
              if (el.alias) {
                newArray.push(`${el.header} as ${el.alias}`);
              } else {
                newArray.push(el.header);
              }
            });
          }
          executeSQL_object['params']['definitions'][executeSQL_object_index]['select_cols'] = newArray.toString();
        } else {
          executeSQL_object['params']['definitions'][executeSQL_object_index]['sql_type'] = 'Execute SQL';
          executeSQL_object['params']['definitions'][executeSQL_object_index]['step_number'] = stepCount;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['df'] = ele.formField.alias;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['db_name'] = ele.formField.db_name;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['statement'] = ele.formField.statement;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['sample_view'] =
            ele.formField.sample_view;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['persist'] = ele.formField.persist;
          executeSQL_object['params']['definitions'][executeSQL_object_index]['distinct_rows'] =
            ele.formField.distinct_rows;
          if (ele.formField.persist === true) {
            executeSQL_object['params']['definitions'][executeSQL_object_index]['persist_type'] =
              ele.formField.persist_type;
          } else {
            executeSQL_object['params']['definitions'][executeSQL_object_index]['persist_type'] = '';
          }
          executeSQL_object['params']['definitions'][executeSQL_object_index]['action'] = ele.formField.action
            ? ele.formField.action
            : '';
        }

        // executeSQL_object_index++;
        // execute_sql_object_arr.push(executeSQL_object);
        // // const new_execute_sql_object_arr = [...execute_sql_object_arr]
        // if (executeSQL_boolean) {
        //   steps.push(execute_sql_object_arr[0]);
        //   executeSQL_boolean = false;
        // }

        stepCount++;
        steps.push(executeSQL_object);
        previous = false;
      } else if (ele.nodeName === 'Data Cleansing') {
        const Data_Cleansing_object = new Object();
        Data_Cleansing_object['method_name'] = 'RuleEngine';
        Data_Cleansing_object['params'] = new Object();
        Data_Cleansing_object['params']['flow_name'] = 'Data Cleansing';
        Data_Cleansing_object['params']['step_name'] = ele.step_name;
        Data_Cleansing_object['params']['step_number'] = stepCount;
        Data_Cleansing_object['params']['node_id'] = ele.nodeId;
        Data_Cleansing_object['params']['log_group'] = 'cdep_rule_engine_logs';
        Data_Cleansing_object['params']['execution_id'] = `${ele.client_name}_${ele.batch_name}_${
          ele.table_name
        }_${new Date().getTime()}`;
        Data_Cleansing_object['params']['engagement_name'] = ele.batch_name;
        Data_Cleansing_object['params']['client_name'] = ele.client_name;
        Data_Cleansing_object['params']['workflow_name'] = ele.table_name;
        Data_Cleansing_object['params']['bucket_name'] = BUCKET_NAME;
        Data_Cleansing_object['params']['jar_folder_path'] = `s3://${BUCKET_NAME}/rule_engine/jars/*`;
        Data_Cleansing_object['params']['job_status_table'] = 'dep_rule_engine_job_status';
        Data_Cleansing_object['params']['db_audit_table'] = 'dep_rule_engine_audit_table';
        Data_Cleansing_object['params']['region_name'] = 'us-east-1';
        Data_Cleansing_object['params']['extensions'] = '[.txt,.csv,.json]';
        Data_Cleansing_object['params']['profile_env'] = 'dev';
        Data_Cleansing_object['params']['profile_env_bucket'] = BUCKET_NAME;
        Data_Cleansing_object['params']['db_status_table'] = 'dep_rule_engine_workflow_status';
        Data_Cleansing_object['params']['df'] = ele.table_name;
        Data_Cleansing_object['params']['db_name'] = 'default';
        Data_Cleansing_object['params']['alias'] = ele.formField.alias;
        Data_Cleansing_object['params']['distinct_rows'] = ele.formField.distinct_rows;
        Data_Cleansing_object['params']['sample_view'] = ele.formField.sample_view;
        Data_Cleansing_object['params']['ruleFile'] = new Object();

        const allFields = [];
        ele.initial_rules.forEach((el) => {
          const field_rules = [];
          el.rulename.forEach((e_rule) => {
            ele.customRules.forEach((e_cust) => {
              if (e_rule === e_cust.rulename) {
                const argkey = e_cust.argkey.split(',');
                const argvalue = e_cust.argvalue.split(',');
                const obj1 = {};
                argkey.map((element, argkey_idx) => {
                  obj1[element] = argvalue[argkey_idx];
                });
                const obj = new Object();
                obj['rulename'] = e_rule;
                obj['args'] = [obj1];
                field_rules.push(obj);
              }
            });
          });

          const data = {
            fieldname: el.header,
            size: 10,
            scale: 50,
            type: el.type,
            rulename: [],
            fieldrules: field_rules,
          };
          allFields.push(data);
        });
        Data_Cleansing_object['params']['ruleFile']['fields'] = allFields;

        Data_Cleansing_object['params']['ruleFile']['swap'] = ele.swap_Cols;
        Data_Cleansing_object['params']['ruleFile']['delete'] = ele.delete_cols;

        stepCount++;
        steps.push(Data_Cleansing_object);
        previous = true;
      } else if (ele.nodeName === 'Data Cleaning & Validation') {
        const rule_engine_object = new Object();
        rule_engine_object['method_name'] = 'DataCleaning&Validation';
        rule_engine_object['params'] = new Object();
        rule_engine_object['params']['step_name'] = ele.step_name;
        rule_engine_object['params']['step_number'] = stepCount;
        rule_engine_object['params']['node_id'] = ele.nodeId;
        rule_engine_object['params']['alias'] = ele.formField.alias;
        rule_engine_object['params']['sample_view'] = ele.formField.sample_view;
        rule_engine_object['params']['db_name'] = 'default';
        rule_engine_object['params']['df'] = ele.formField.df;

        rule_engine_object['params']['ruleFile'] = new Object();

        const validate_email = [];
        const validate_phone = [];

        ele.cleansingRules.forEach((el) => {
          if (
            (el.checked === 'true' || el.checked === true || el.selected === true) &&
            el.validation === 'validate_email'
          ) {
            validate_email.push(el.header);
          } else if (
            (el.checked === 'true' || el.checked === true || el.selected === true) &&
            el.validation === 'validate_phone'
          ) {
            validate_phone.push(el.header);
          }
        });

        rule_engine_object['params']['validate_email'] = validate_email;
        rule_engine_object['params']['validate_phone'] = validate_phone;

        const allFields = [];
        ele.cleansingRules.forEach((el) => {
          const field_rules = [];
          el.rulename.forEach((e_rule) => {
            ele.customRules.forEach((e_cust) => {
              if (e_rule === e_cust.rulename) {
                const argkey = e_cust.argkey.split(',');
                const argvalue = e_cust.argvalue.split(',');
                const obj1 = {};
                argkey.map((element, argkey_idx) => {
                  obj1[element] = argvalue[argkey_idx];
                });
                const obj = new Object();
                obj['rulename'] = e_rule;
                // obj['args'] = [obj1];
                field_rules.push(obj);
              }
            });
          });

          const data = {
            fieldname: el.header,
            type: el.type,
            // rulename: [],
            fieldrules: field_rules,
          };
          allFields.push(data);
        });
        rule_engine_object['params']['ruleFile']['fields'] = allFields;

        stepCount++;
        steps.push(rule_engine_object);
        previous = true;
      } else if (ele.nodeName === 'Fuzzy Match') {
        let fuzzy_match_object_index = 0;
        const fuzzy_match_object = new Object();

        fuzzy_match_object['method_name'] = 'FUZZY_MATCH';
        fuzzy_match_object['params'] = new Object();
        fuzzy_match_object['params']['definitions'] = new Array();

        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index] = new Object();
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['step_name'] = `fuzzy_match_${stepCount}`;
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['step_number'] = stepCount;
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['node_id'] = ele.nodeId;
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['df'] = ele.formField.df;
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['alias'] = ele.formField.alias;
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['sample_view'] =
          ele.formField.sample_view;

        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['algorithm'] = ele.formField.algorithm;

        const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

        const priorities = getAllNodes
          ?.filter(
            (item) =>
              item.nodeName === 'ReadCSV' ||
              item.nodeName === 'ReadPDF' ||
              // item.nodeName === 'CopyADLS' ||
              item.nodeName === 'ReadPublishedData' ||
              item.nodeName === 'Read' ||
              item.nodeName === 'ReadStreaming' ||
              item.nodeName === 'ReadDelimited' ||
              item.nodeName === 'ReadOracle' ||
              item.nodeName === 'ReadPostgres' ||
              item.nodeName === 'ReadCosmos' ||
              item.nodeName === 'ReadMySql' ||
              item.nodeName === 'ReadSnowflake' ||
              item.nodeName === 'Read Real-Time' ||
              item.nodeName === 'FileUploader'
          )
          .map((ele) => ({ source: ele.formField.alias, priority: parseInt(ele.formField.priority, 10) }));

        const col_list = [];
        const fuzzy_match = [];
        const exact_match = [];

        ele.headerName.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.merge_rule) {
            col_list.push({
              input_col_name: el.header,
              merge_rule: el.merge_rule,
            });
          }
        });

        ele.headerName.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.match_type === 'fuzzy') {
            fuzzy_match.push(el.header);
          }
        });

        ele.headerName.forEach((el) => {
          if ((el.checked === 'true' || el.checked === true) && el.match_type === 'exact') {
            exact_match.push(el.header);
          }
        });

        const columns = { col_list };
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['columns'] = columns;
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['fuzzy_match'] = fuzzy_match;
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['exact_match'] = exact_match;
        fuzzy_match_object['params']['definitions'][fuzzy_match_object_index]['source_priority'] = priorities;

        stepCount++;
        fuzzy_match_object_index++;
        steps.push(fuzzy_match_object);
        previous = true;
      } else if (ele.nodeName === 'Match & Merge') {
        let match_merge_object_index = 0;
        const match_merge_object = new Object();

        match_merge_object['method_name'] = 'MatchAndMerge';
        match_merge_object['params'] = new Object();
        match_merge_object['params']['definitions'] = new Array();

        match_merge_object['params']['definitions'][match_merge_object_index] = new Object();
        match_merge_object['params']['definitions'][match_merge_object_index][
          'step_name'
        ] = `MatchAndMerge${stepCount}`;
        match_merge_object['params']['definitions'][match_merge_object_index]['step_number'] = stepCount;
        match_merge_object['params']['definitions'][match_merge_object_index]['node_id'] = ele.nodeId;
        // match_merge_object['params']['definitions'][match_merge_object_index]['df'] = ele.formField.df;
        match_merge_object['params']['definitions'][match_merge_object_index]['alias'] = ele.formField.alias;
        match_merge_object['params']['definitions'][match_merge_object_index]['sample_view'] =
          ele.formField.sample_view;
        match_merge_object['params']['definitions'][match_merge_object_index]['primary_key'] = 'unique_id';

        const ungroup =
          'mergeManager' in ele
            ? ele?.mergeManager
                .filter((item) => item.select_grouping === 'Ungroup')
                .map((ele) => ({ identifier_key: ele.unique_id, reviewed_by: ele.reviewed_by }))
            : [];

        const regroup =
          'mergeManager' in ele
            ? ele?.mergeManager
                .filter((item) => item.select_grouping === 'Regroup')
                .map((ele) => ({
                  identifier_key: ele.unique_id,
                  reviewed_by: ele.reviewed_by,
                  group: ele.group,
                }))
            : [];

        match_merge_object['params']['definitions'][match_merge_object_index]['edited_values'] = ele.dataManager ?? [];
        match_merge_object['params']['definitions'][match_merge_object_index]['ungroup'] = ungroup;
        match_merge_object['params']['definitions'][match_merge_object_index]['regroup'] = regroup;

        stepCount++;
        match_merge_object_index++;
        steps.push(match_merge_object);
        previous = true;
      } else if (ele.nodeName === 'Customer Segmentation' || ele.nodeName === 'Plot') {
        let customer_seg_object_index = 0;
        const customer_seg_object = new Object();

        const fieldsList = ele.fields.map((item) =>
          item.operation === 'cohort_analysis'
            ? {
                operation: item.operation,
                date: item.xAxis,
                identifier: item.yAxis,
                value: item.zAxis,
                graph_id: item.graph_id,
              }
            : item.operation === 'market_basket_analysis'
            ? { operation: item.operation, identifier: item.xAxis, item: item.yAxis }
            : item
        );

        customer_seg_object['method_name'] = 'CustomerSegmentation';
        customer_seg_object['params'] = new Object();
        customer_seg_object['params']['definitions'] = new Array();

        customer_seg_object['params']['definitions'][customer_seg_object_index] = new Object();
        customer_seg_object['params']['definitions'][customer_seg_object_index][
          'step_name'
        ] = `CustomerSegmentation${stepCount}`;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['step_number'] = stepCount;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['node_id'] = ele.nodeId;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['df'] = ele.formField.df;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['alias'] = ele.formField.alias;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['sample_view'] =
          ele.formField.sample_view;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['persist'] = ele.formField.persist;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['persist_type'] =
          ele.formField.persist_type;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['distinct_rows'] =
          ele.formField.distinct_rows;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['fields'] = fieldsList;
        customer_seg_object['params']['definitions'][customer_seg_object_index]['action'] = ele.formField.action
          ? ele.formField.action
          : '';
        stepCount++;
        customer_seg_object_index++;
        steps.push(customer_seg_object);
        previous = true;
      } else if (ele.nodeName === 'C360') {
        // console.log()
      } else if (ele.nodeName === 'Ingestion Write') {
        const write_object = new Object();
        write_object['method_name'] = 'Write';

        write_object['params'] = new Object();

        const df_write = [];
        ele.sorting.forEach((data) => {
          const columns = getColumnList(data.dataFrame, getJobFromLocalStorage);
          const item = {
            step_name: `Write_${stepCount}`,
            step_number: stepCount,
            format: data.format,
            overwrite: data.overwrite,
            df: data.dataFrame,
            alias: ele.formField.alias,
            distinct_rows: data.distinct_rows,
            path: data.file_path,
            node_id: ele.nodeId,
            sample_view: ele.formField.sample_view,
            target: AWS_CONFIG_TYPE ? 's3' : 'adls',
            definitions: columns.toString(),
          };

          df_write.push(item);
          stepCount++;
        });

        write_object['params']['definitions'] = df_write;

        stepCount++;
        steps.push(write_object);
        previous = true;
      } else if (ele.nodeName === 'Ingest Column Metadata') {
        if (ingestion_object_boolean === false) {
          ingestion_object = new Object();
          ingestion_object['method_name'] = 'Catalog';
          ingestion_object['params'] = new Object();
          ingestion_object['params']['definitions'] = new Array();
          ingestion_object_boolean = true;
        }

        // ele.columnsConfig?.map((data) => {
        //   if (data.column !== "") {
        const dataHubConfigData = ele.formField.dataHubConfigData;
        const column_arr = [];
        const tags_arr = [];
        const glossary_terms_arr = [];
        const description_arr = [];
        dataHubConfigData.forEach((el) => {
          column_arr.push(el.column);
          tags_arr.push(el.tag);
          glossary_terms_arr.push(el.glossaryterm);
          description_arr.push(el.description);
        });
        const item = {
          step_name: `${ele.step_name}_${stepCount}`,
          step_number: stepCount,
          df: ele.formField.alias,
          node_id: ele.nodeId,
          sample_view: ele.formField.sample_view,
          file_paths: ele.formField.file_paths,

          table_level: false,
          subresources: column_arr,
          // subresources: data.column,
          glossary_terms: glossary_terms_arr,
          // glossary_terms: data.glossaryterm.split(","),
          tags: tags_arr,

          // tags: data.tag.split(","),
          owners: [],
          ownership_type: '',
          description: description_arr,
          // description: data.description,
          domain: '',
        };

        if (ele.filePaths?.length !== 0) {
          ingestion_object['params']['definitions'].push(item);
        }
        stepCount++;
        if (ingestion_boolean) {
          steps.push(ingestion_object);
          ingestion_boolean = false;
        }
        previous = true;

        // }
      }
      // )
      // }
      else if (ele.nodeName === 'Ingest Table Metadata') {
        // let ingestion_object_boolean=false;
        // let ingestion_object;
        if (ingestion_object_boolean === false) {
          ingestion_object = new Object();
          ingestion_object['method_name'] = 'Catalog';
          ingestion_object['params'] = new Object();
          ingestion_object['params']['definitions'] = new Array();
          ingestion_object_boolean = true;
        }

        // write_object = new Object();
        // write_object['method_name'] = 'Catalog';
        // write_object['params'] = new Object();
        // console.log('hi',ele)
        const item = {
          step_name: `${ele.step_name}_${stepCount}`,
          step_number: stepCount,
          df: ele.formField.df,
          df_list: ele.formField.df_list,
          node_id: ele.nodeId,
          sample_view: ele.formField.sample_view,
          file_paths: ele.formField.file_paths,
          table_level: true,
          subresources: '',
          glossary_terms: ele.formField?.glossary?.split(','),
          tags: ele.formField?.tag?.split(','),
          owners: ele.formField?.owner?.split(','),
          ownership_type: ele.formField.ownership_type,
          description: ele.formField.description,
          domain: ele.formField.domain,
        };

        if (ele.filePaths?.length !== 0) {
          ingestion_object['params']['definitions'].push(item);
        }
        //  } else
        //   ingestion_object['params']['definitions'] = []
        // }
        stepCount++;
        if (ingestion_boolean) {
          steps.push(ingestion_object);
          // steps.push(read_object_arr[0]);
          ingestion_boolean = false;
        }
        previous = true;
      } else if (ele.nodeName === 'Embedding') {
        const embedding_object = new Object();
        embedding_object['method_name'] = 'Generate_Embeddings';
        embedding_object['params'] = new Object();
        embedding_object['params']['definitions'] = new Array();

        const item = {
          step_name: `${ele.step_name}_${stepCount}`,
          step_number: stepCount,
          node_id: ele.nodeId,
          DataProcessor_tracking_id: '',
          sample_view: ele.formField.sample_view,
          path: ele.formField.path,
          df: ele.formField.df,
          model: ele.formField.db_type,
          df_list: ele.formField.df_list,
          domain: ele.formField.domainName,
          client: ele.formField.clientName,
          engagement: ele.formField.batchName,
          workflow: ele.formField.workflow_name,
          persist: ele.formField.persist,
          persist_type: ele.formField.persist_type,
          pagination: false,
          action: '',
          subdomain: ele.formField.subdomain,
          vectorstore: 'FAISS',
        };

        embedding_object['params']['definitions'].push(item);
        stepCount++;
        steps.push(embedding_object);
        previous = true;
      } else if (ele.nodeName === 'Read From Folder') {
        const readFromFolderObject = new Object();
        readFromFolderObject['method_name'] = 'Read';
        readFromFolderObject['params'] = new Object();
        readFromFolderObject['params']['definitions'] = new Array();

        const item = {
          step_name: `${ele.step_name}_${stepCount}`,
          step_number: stepCount,
          format: ele.formField.format,
          node_id: ele.nodeId,
          DataProcessor_tracking_id: '',
          sample_view: ele.formField.sample_view,
          df: '',
          domain: ele.formField.domainName,
          client: ele.formField.clientName,
          engagement: ele.formField.batchName,
          workflow: ele.formField.tableNameRead,
          path: ele.formField.path,
          persist: ele.formField.persist,
          persist_type: ele.formField.persist_type,
          pagination: false,
          action: '',
        };
        readFromFolderObject['params']['definitions'].push(item);
        stepCount++;
        steps.push(readFromFolderObject);
        previous = true;
      } else if (ele.nodeName === 'Analysis') {
        return null;
      } else {
        const multiJoin_object = new Object();
        multiJoin_object['method_name'] = 'MultiTableJoin';
        multiJoin_object['params'] = new Object();
        multiJoin_object['params']['step_name'] = `${ele.step_name}_${stepCount}`;
        multiJoin_object['params']['step_number'] = stepCount;
        multiJoin_object['params']['df'] = ele.formField.alias;
        multiJoin_object['params']['node_id'] = ele.nodeId;
        multiJoin_object['params']['join_condition'] = ele.formField.join_conditions;
        multiJoin_object['params']['select_cols'] = ele.formField.select_cols;
        multiJoin_object['params']['join_filter'] = ele.formField.join_filter ? ele.formField.join_filter : '';
        multiJoin_object['params']['persist'] = ele.formField.persist;
        multiJoin_object['params']['distinct_rows'] = ele.formField.distinct_rows;
        multiJoin_object['params']['sample_view'] = ele.formField.sample_view;
        if (ele.formField.persist === true) {
          multiJoin_object['params']['persist_type'] = ele.formField.persist_type;
        } else {
          multiJoin_object['params']['persist_type'] = '';
        }
        multiJoin_object['params']['tables'] = new Object();

        // json was breaking as tables was null
        const tableLength =
          ele.formField.tables === null || ele.formField.tables === undefined
            ? 0
            : Object.keys(ele?.formField?.tables).length;
        for (let i = 1; i <= tableLength; i++) {
          multiJoin_object['params']['tables'][`table${i}`] = ele.formField.tables[`table${i}`];
        }

        multiJoin_object['params']['joins'] = new Object();

        // json was breaking as joins was null
        const joinLength =
          ele.formField.joins === null || ele.formField.joins === undefined
            ? 0
            : Object.keys(ele.formField.joins).length;

        for (let j = 1; j <= joinLength; j++) {
          multiJoin_object['params']['joins'][`join${j}`] = ele.formField.joins[`join${j}`];
        }

        multiJoin_object['params']['action'] = ele.formField.action ? ele.formField.action : '';

        stepCount++;
        steps.push(multiJoin_object);
        previous = true;
      }
    });

  // steps.push(read_object_arr[0]);
  // steps.push(execute_sql_object_arr[0]);

  const main = {
    name: '',
    transformer_execution_details: {},
    spark_config: {},
    udf: {},
    steps,
  };

  return main;
}

const getColumnList = (dataFrame, localStorageData) => {
  const columns = [];
  localStorageData.forEach((el) => {
    if (el.formField.alias === dataFrame) {
      el.fetchedHeader.forEach((ele) => {
        columns.push(ele.header);
      });
    }
  });

  return columns;
};
