import React, { useContext, useEffect, useState, useCallback, forwardRef } from 'react';
import {
  Box,
  Stack,
  Paper,
  Button as MuiButton,
  Select as MuiSelect,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import { FaFileUpload } from 'react-icons/fa';

import {
  Space,
  Table,
  Typography,
  Button,
  Row,
  Col,
  Input,
  Form,
  Upload,
  Tag,
  InputNumber,
  Select,
  Modal,
  Checkbox,
} from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useSnackbar } from 'notistack';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DeleteFilled, EditFilled, MenuOutlined, UploadOutlined } from '@ant-design/icons';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import {
  createMetadata,
  UpdateRuleEngine,
  executeRuleEngine,
  fetchTableRuleNew,
  storeTableRuleJson,
  AllFilesOfS3,
  AllFoldersOfS3,
  fetchSingleTableRuleNew,
  getRuleEngineList,
} from "../../api's/RuleEngineApi";
import { updateExecution } from "../../api's/ExecutionApi";
import { getCustomRuleApi } from "../../api's/CustomRuleApi";
import FetchModal from './FetchModal';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { BUCKET_NAME } from '../../constants/Constant';
import UploadModal from '../chat_gpt/UploadModal';
import AddConnectionModal from '../chat_gpt/AddConnectionModal';
import { AuthContext } from '../../contexts/AuthProvider';
import FileUpload from './FileUpload';

const { Option } = Select;

const header = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px',
  borderRadius: '5px',
  margin: '10px 0px',
};

const INITIALROWLEVELSTATE = {
  fieldName: '',
  position: '',
  fieldType: '',
  fieldValue: '',
};

const fieldTypeValues = ['integer', 'string', 'float', 'double', 'datetime', 'date', 'time'];
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  handleChangeFieldType,
  customRules,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber />
    ) : inputType === 'select' ? (
      <Select mode="multiple">
        {customRules &&
          customRules.map((rule) => {
            return (
              <Option key={rule} value={rule.rulename}>
                {rule.rulename}
              </Option>
            );
          })}
      </Select>
    ) : inputType === 'typeSelect' ? (
      <Select
        onChange={(e) => {
          handleChangeFieldType(e, record);
        }}
      >
        {fieldTypeValues.map((ftv) => {
          return (
            <Option key={ftv} value={ftv}>
              {ftv}
            </Option>
          );
        })}
      </Select>
    ) : (
      <Input />
    );
  return <td {...restProps}>{editing ? <Form.Item name={dataIndex}>{inputNode}</Form.Item> : children}</td>;
};

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const RuleTableSectionNew = forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fileNames, setFileNames] = useState([]);
  const { workflow, setIsDisabled } = useContext(WorkflowContext);
  const { userRole, userId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formAdd] = Form.useForm();
  const [formRowLevelOperation] = Form.useForm();
  const [formRun] = Form.useForm();
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [openConnectionModal, setOpenConnectionModal] = useState(false);
  const [runStatus, setRunStatus] = useState('Loading...');
  const [disableRun, setDisableRun] = useState(false);
  const [rowInputModal, setRowInputModal] = useState(false);
  const [S3Path, setS3Path] = useState();
  const [openS3PathModal, setopenS3PathModal] = useState(false);
  const [rowLevelState, setRowLevelState] = useState(INITIALROWLEVELSTATE);
  const [editingKey, setEditingKey] = useState('');
  const [loadingStatus, setloadingStatus] = useState(true);
  const [InitialTableRule, setInitialTableRule] = useState();
  const [UpdateTableRule, setUpdateTableRule] = useState();
  const [UpdateTableRuleFields, setUpdateTableRuleFields] = useState([]);
  const [customRules, setCustomRules] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [AddFieldArray, setAddFieldArray] = useState([]);
  const [DeleteFieldArray, setDeleteFieldArray] = useState([]);
  const [SwapFieldArray, setSwapFieldArray] = useState([]);

  const [FoldersData, setFoldersData] = useState([]);
  const [FilesData, setFilesData] = useState([]);
  const [isRunModalVisible, setIsRunModalVisible] = useState(false);
  const [tableNameModal, setTableNameModal] = useState('');

  const [fileUploadMetaData, setfileUploadMetaData] = useState({});

  const [saveLoadingStatus, setSaveLoadingStatus] = useState(false);
  const [timestampId, setTimestampId] = useState('');
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
  const [ComponentSaveButton, setComponentSaveButton] = useState(false);
  const [pathName, setPathName] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);

  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [customRulesResponse, setCustomRulesResponse] = useState([]);
  const [openFetchModal, setOpenFetchModal] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenFetchModal = (status) => {
    setOpenFetchModal(true);
    setIsUpdate(status);
  };
  const handleCloseFetchModal = () => {
    setOpenFetchModal(false);
  };

  const handlePathName = (event) => {
    setPathName(event.target.value);
    getSingleTableRule(event.target.value);
  };

  const handleUploadPDF = () => {
    setOpenModal(true);
  };

  console.log(openModal);

  const uploadFile = () => {
    console.log(openModal);
    const data = {
      file: '',
      filename: '',
      path: '',
      cloud_path: `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Rule_engine/Input`,
      client_id: workflow.engagement.client.client_id,
      engagement_name: workflow.engagement.engagement_name,
      workflow_id: workflow.workflow_id,
      workflow_name: workflow.workflow_name,
      execution_mode: workflow.execution_mode,
      delimiter: ',',
      file_type: 'csv',
      user_id: userId,
      aws_config_type: AWS_CONFIG_TYPE,
    };
    setfileUploadMetaData(data);
    handleUploadPDF();
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenAddNewConnectionModal = () => {
    setOpenConnectionModal(true);
  };

  const handleCloseAddNewConnectionModal = () => {
    setOpenConnectionModal(false);
  };

  const handleLoadingModalOpen = useCallback(() => setOpenLoadingModal(true), []);

  const handleLoadingModalClose = useCallback(() => setOpenLoadingModal(false), []);

  const isEditing = (record) => record.fieldname === editingKey;

  const handleChangeFieldType = async (value, record) => {
    record.rulename = [];
    const custom_rules = customRulesResponse.filter((rule) => rule.type === value);
    setCustomRules(custom_rules);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const saveRecord = async (key) => {
    setLoading(true);
    try {
      const row = await form.validateFields();
      const newData = [...UpdateTableRuleFields];
      const index = newData.findIndex((item) => key === item.fieldname);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setUpdateTableRuleFields(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setUpdateTableRuleFields(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      // console.log('Validate Failed:', errInfo);
    }
    setLoading(false);
  };
  const deleteRecord = (record) => {
    const newData = UpdateTableRuleFields.filter((item) => item.fieldname !== record.fieldname);
    setUpdateTableRuleFields(newData);
    setDeleteFieldArray([...DeleteFieldArray, record]);
  };

  const showModal = () => {
    formRowLevelOperation.setFieldsValue({
      ignoreblanklines: false,
      skipheaders: false,
      skiptrailers: false,
      columnshift: true,
      junkrecords: true,
      linebreak: true,
      delimiter: ',',
    });
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = (checkedValues) => {
    setIsModalVisible(false);
  };

  const EditRecord = async (record) => {
    setIsEditEnabled(true);

    const custom_rules = customRulesResponse.filter((rule) => rule.type === record.type);
    setCustomRules(custom_rules);

    form.setFieldsValue({
      size: '',
      scale: '',
      type: '',
      rulename: [],
      ...record,
    });
    setEditingKey(record.fieldname);
  };

  const AddModal = () => {
    setIsAddModalVisible(true);
  };
  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const onAddFinish = (values) => {
    setAddFieldArray([...AddFieldArray, values]);
    const data = {
      fieldname: values.fieldName,
      type: values.fieldType,
      scale: 0,
      size: 0,
      rulename: [],
    };
    setRowLevelState(values);
    const newArr = handleAddRow(UpdateTableRuleFields, values.position, data);
    setUpdateTableRuleFields(newArr);
    setIsAddModalVisible(false);
  };

  const handleAddRow = (arr, index, item) => {
    return [...arr.slice(0, index), item, ...arr.slice(index)];
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(UpdateTableRuleFields.slice(), oldIndex, newIndex).filter((el) => !!el);
      const newData1 = arrayMoveImmutable(UpdateTableRuleFields.slice(), newIndex, oldIndex).filter((el) => !!el);

      const A = UpdateTableRuleFields.slice();
      const B = UpdateTableRuleFields.slice();
      A[oldIndex] = A.splice(newIndex, 1, A[oldIndex])[0];

      setUpdateTableRuleFields(A);
      setSwapFieldArray([...SwapFieldArray, { swap_col_one: B[oldIndex], swap_col_two: B[newIndex] }]);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = UpdateTableRuleFields.findIndex((x) => x.fieldname === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const getTableRules = useCallback(async () => {
    setloadingStatus(true);

    try {
      const response = await fetchTableRuleNew(workflow.workflow_id);

      if (response.status === 200) {
        const data = response.data;
        const rules = [...data.fields];
        const clone = { ...data };
        setInitialTableRule(data);
        setUpdateTableRule(clone);
        setUpdateTableRuleFields(rules);
        setFileNames(response.data.filenames);
        setPathName(response.data.filename);
      }
    } catch (error) {
      handleOpenFetchModal(false);
      setIsDisabled(true);
    }
    setloadingStatus(false);
  }, []);

  const getSingleTableRule = useCallback(async (filename) => {
    setloadingStatus(true);

    try {
      const response = await fetchSingleTableRuleNew(workflow.workflow_id, filename);

      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        const rules = [...data.fields];
        const clone = { ...data };
        setInitialTableRule(data);
        setUpdateTableRule(clone);
        setUpdateTableRuleFields(rules);
      }
    } catch (error) {
      handleOpenFetchModal(false);
    }
    setloadingStatus(false);
  }, []);

  useEffect(() => {
    getTableRules();
  }, []);

  useEffect(() => {
    setLoading(true);
    setIsDisabled(false);
    const fetchCustomRule = async () => {
      try {
        const response = await getCustomRuleApi();
        if (response.status === 200) {
          setCustomRulesResponse(response.data);
        }
      } catch (error) {
        console.log(error);
        setIsDisabled(true);
      }
    };
    if (isEditEnabled) {
      fetchCustomRule();
    }
    setLoading(false);
  }, [isEditEnabled]);

  const createRuleJson = async (TimeStampID = '') => {
    const response = InitialTableRule;
    let main = {};

    const json = response;
    const clone1 = [...response.fields];

    let fetchCustomRule;

    json.fields.map((el) => {
      UpdateTableRuleFields.map((elem) => {
        if (el.fieldname === elem.fieldname) {
          el.type = elem.type;
          el.scale = parseInt(elem.scale, 10);
          el.size = parseInt(elem.size, 10);
          el.rulename = elem.rulename || [];
        }
      });
    });
    setLoading(true);
    try {
      fetchCustomRule = await getCustomRuleApi();
    } catch (error) {
      // console.log(error)
    }
    setLoading(false);
    if (fetchCustomRule && fetchCustomRule.status === 200) {
      if (fetchCustomRule) {
        json.fields.map((el) => {
          fetchCustomRule.data.forEach((item) => {
            el.rulename &&
              el.rulename.forEach((ele) => {
                if (ele === item.rulename) {
                  const argkey = item.argkey.split(',');
                  const argvalue = item.argvalue.split(',');
                  const obj = {};
                  argkey.map((element, argkey_idx) => {
                    obj[element] = argvalue[argkey_idx];
                  });

                  if (el.fieldrules) {
                    const custfieldrules = {
                      rulename: item.rulename,
                      args: [obj],
                    };

                    const index = el.fieldrules.findIndex((x) => x.rulename === item.rulename);

                    if (index === -1) {
                      if (el.fieldrules) {
                        el.fieldrules = [...el.fieldrules, custfieldrules];
                      } else {
                        // el.fieldrules = [custfieldrules];
                      }
                    }
                  } else {
                    const custfieldrules = {
                      rulename: item.rulename,
                      args: [obj],
                    };
                    el['fieldrules'] = [custfieldrules];
                  }
                }
              });
          });
        });
      }
    }

    if (AddFieldArray.length > 0) {
      AddFieldArray.map((ele) => {
        UpdateTableRuleFields.map((el) => {
          if (ele.fieldName === el.fieldname) {
            ele.fieldType = el.type;
          }
        });
      });
      json['column_add'] = AddFieldArray;
    }

    AddFieldArray.map((elem) => {
      const data = {
        fieldname: elem.fieldName,
        type: elem.fieldType,
        scale: 0,
        size: 0,
        rulename: [],
      };
      clone1.splice(elem.position, 0, data);
    });

    const swap_field = [];

    let clone2;
    SwapFieldArray.map((ele, idx) => {
      if (idx === 0) {
        clone2 = clone1.slice();
      } else {
        clone2 = [...clone2];
      }

      const first_one = ele.swap_col_one.fieldname;
      const second_one = ele.swap_col_two.fieldname;
      const obj = {};

      let index1, index2;
      clone2.find((el, i) => {
        if (el.fieldname === first_one) {
          obj['swap_col_one'] = i;
          index1 = i;
        }
      });
      clone2.find((el, i) => {
        if (el.fieldname === second_one) {
          obj['swap_col_two'] = i;
          index2 = i;
        }
      });

      swap_field.push(obj);

      function swap(x, y) {
        const z = clone2[y];
        clone2[y] = clone2[x];
        clone2[x] = z;
      }
      swap(index1, index2);
    });

    if (SwapFieldArray.length > 0) {
      json.swap_field = swap_field;
    }

    const del_col_seq = [];

    if (SwapFieldArray.length > 0) {
      DeleteFieldArray.map((ele) => {
        clone2.map((el, i) => {
          if (ele.fieldname === el.fieldname) {
            del_col_seq.push(i);
          }
        });
      });
    } else {
      DeleteFieldArray.map((ele) => {
        clone1.map((el, i) => {
          if (ele.fieldname === el.fieldname) {
            del_col_seq.push(i);
          }
        });
      });
    }

    if (DeleteFieldArray.length > 0) {
      json.del_col_seq = del_col_seq;
    }

    let clone3;

    if (SwapFieldArray.length > 0) {
      clone3 = clone2.slice(); /// update fields by Delete
      DeleteFieldArray.map((ele) => {
        clone3.map((el, i) => {
          if (ele.fieldname === el.fieldname) {
            clone3.splice(i, 1);
          }
        });
      });
    } else {
      clone3 = clone1.slice();
      DeleteFieldArray.map((ele) => {
        clone3.map((el, i) => {
          if (ele.fieldname === el.fieldname) {
            clone3.splice(i, 1);
          }
        });
      });
    }

    json.columnshift =
      formRowLevelOperation.getFieldValue().columnshift !== undefined
        ? formRowLevelOperation.getFieldValue().columnshift
        : true;
    json.junkrecords =
      formRowLevelOperation.getFieldValue().junkrecords !== undefined
        ? formRowLevelOperation.getFieldValue().junkrecords
        : true;
    json.linebreak =
      formRowLevelOperation.getFieldValue().linebreak !== undefined
        ? formRowLevelOperation.getFieldValue().linebreak
        : true;
    json.ignoreblanklines =
      formRowLevelOperation.getFieldValue().ignoreblanklines !== undefined
        ? formRowLevelOperation.getFieldValue().ignoreblanklines
        : false;
    json.skipheaders =
      formRowLevelOperation.getFieldValue().skipheaders !== undefined
        ? formRowLevelOperation.getFieldValue().skipheaders
        : false;
    json.skiptrailers =
      formRowLevelOperation.getFieldValue().skiptrailers !== undefined
        ? formRowLevelOperation.getFieldValue().skiptrailers
        : false;
    json.delimiter =
      formRowLevelOperation.getFieldValue().delimiter !== undefined
        ? formRowLevelOperation.getFieldValue().delimiter
        : ',';

    json.rule_engine_tracking_id = TimeStampID;

    if (S3Path) {
      json.s3_path = S3Path;
    }

    main = {
      NewcreatedJson: { ...json },
      NewUpdatedFields: clone3,
    };

    setAddFieldArray([]);
    setDeleteFieldArray([]);
    setSwapFieldArray([]);
    return main;
  };

  const saveTableRule = async () => {
    setLoading(true);

    try {
      setComponentSaveButton(true);

      const { NewcreatedJson, NewUpdatedFields } = await createRuleJson();

      delete NewcreatedJson['id'];
      delete NewcreatedJson['workflow.workflow_name'];
      delete NewcreatedJson['batchname'];
      delete NewcreatedJson['path'];
      delete NewcreatedJson['client_id'];
      delete NewcreatedJson['generated'];

      const jsonData = {
        ...NewcreatedJson,
      };

      // await storeTableRuleJson(workflow.workflow_id, jsonData);

      const RuleDbMeta = { ...InitialTableRule };
      delete RuleDbMeta['columnshift'];
      delete RuleDbMeta['delimiter'];
      delete RuleDbMeta['ignoreblanklines'];
      delete RuleDbMeta['junkrecords'];
      delete RuleDbMeta['linebreak'];
      delete RuleDbMeta['skipheaders'];
      delete RuleDbMeta['skiptrailers'];
      delete RuleDbMeta['rule_engine_tracking_id'];

      const updateDone = await UpdateRuleEngine({ ...RuleDbMeta, fields: NewUpdatedFields, jsonFields: jsonData }); // db store

      let response;
      if (updateDone.status === 200) response = await getRuleEngineList(workflow.workflow_id);

      if (response.status === 200) {
        const pathList = [];

        response.data.forEach((el) => {
          const map = {
            json_path: el.path,
            csv_path: el.cloud_path,
          };
          pathList.push(map);
        });

        const metadata = {
          id: `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`,
          client_name: workflow.engagement.client.client_name,
          workflow_name: workflow.workflow_name,
          params: {
            client_name: workflow.engagement.client.client_name,
            engagement_name: workflow.engagement.engagement_name,
            workflow_name: workflow.workflow_name,
            bucket_name: workflow.cloudEnvironment === 'AWS' ? 'dep-qa' : 'dep-develop',
            delimiter: ',',
            db_status_table: 'dep_rule_engine_workflow_status',
            log_group: 'cdep_rule_engine_logs',
            file_detail: pathList,
          },
        };

        await createMetadata(workflow.engagement.client.client_id, metadata);
      }

      enqueueSnackbar('Table Rule Saved Successfully!', {
        variant: 'Success',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setAddFieldArray([]);
      setDeleteFieldArray([]);
      setSwapFieldArray([]);
      setComponentSaveButton(false);
    } catch (error) {
      if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }

      if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      setComponentSaveButton(false);
    }
    setLoading(false);
  };

  ref.current = saveTableRule;

  const pad2 = (n) => {
    return n < 10 ? `0${n}` : n;
  };

  const runRuleEngine = async (e) => {
    setLoading(true);
    handleLoadingModalOpen();
    try {
      let trackingId = '';
      const date = new Date();
      trackingId = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(
        date.getDate()
      )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(date.getMilliseconds())}`;

      const batch_table_id = `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}_ruleEngine_${trackingId}`;

      const meta = {
        execution_id: batch_table_id.replaceAll(' ', ''),
        executed_by_id: userId,
        execution_engine: workflow.connectionName,
        workflow_type: 'Rule Engine',
      };

      await updateExecution(meta, workflow.workflow_id);

      const input = {
        workflow_id: workflow.workflow_id,
        execution_id: batch_table_id.replaceAll(' ', ''),
        cloudEnvironment: workflow.cloudEnvironment,
      };

      const response = await executeRuleEngine(input);

      if (response.status === 200) {
        setDisableRun(true);
        setRunStatus('In Progress');
        handleLoadingModalClose();
        enqueueSnackbar('Table Rule is running!', {
          variant: 'Success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      // }
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }

      if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      handleLoadingModalClose();
    }
    setLoading(false);
  };

  const getAllFiles = async (prefix) => {
    setLoading(true);
    setFilesData([]);
    const res = await AllFilesOfS3(workflow.engagement.client.client_id, prefix, false);
    if (res.status === 200) {
      setFilesData(res.data);
    }
    setLoading(false);
  };

  const showTimestampModal = async (client_name, batch_name, table_name) => {
    setLoading(true);
    setIsRunModalVisible(true);
    setTableNameModal(table_name);
    const res1 = await AllFoldersOfS3(
      workflow.engagement.client.client_id,
      workflow.engagement.engagement_name,
      workflow.workflow_name,
      false
    );
    if (res1.status === 200) {
      setFoldersData(res1.data);
    }
    setLoading(false);
  };

  const handleTimestampCancel = () => {
    setIsRunModalVisible(false);
    setSubmitButtonDisable(true);
    setTimestampId('');
    form.setFieldsValue({
      Folders: '',
      Files: '',
    });
  };

  const saveButtonClick = async () => {
    if (form.getFieldValue().Files !== '') {
      setSaveLoadingStatus(true);
      setLoading(true);
      const splitFile = form.getFieldValue().Files.split('/');
      const timestamp_Id = `/${splitFile[5]}/${splitFile[6]}`;
      const idt = splitFile[5];

      await saveTableRule(timestamp_Id, idt);

      setTimestampId(timestamp_Id);
      setSaveLoadingStatus(false);
      setSubmitButtonDisable(false);
    } else {
      enqueueSnackbar(`Select File`, {
        variant: 'warning',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  const onTimestampFinish = async (values) => {
    setIsRunModalVisible(false);
    setLoading(true);
    console.log('1');
    form.setFieldsValue({
      Folders: '',
      Files: '',
    });
    console.log('2');

    await runRuleEngine(workflow.workflow_name);
    console.log('3');
    setSubmitButtonDisable(true);
    setLoading(false);
  };

  const columns = [
    {
      title: 'Swap Field',
      dataIndex: 'sort',
      align: 'center',
      // width: '5%',
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Field Name',
      dataIndex: 'fieldname',
      align: 'center',
      // width: '25%',
      // sorter: (a, b) => a.fieldname.localeCompare(b.fieldname),
      render(text, record) {
        return {
          props: {
            style: {
              fontWeight: 'bold',
              fontSize: 14,
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Field Type',
      dataIndex: 'type',
      align: 'center',
      // width: '15%',
      editable: true,
    },
    {
      title: 'Rule Name',
      dataIndex: 'rulename',
      align: 'center',
      // width: '30%',
      editable: true,
      render: (_, { rulename }) => (
        <>
          {rulename &&
            rulename.map((rule) => {
              let color = 'geekblue';

              if (rule === 'loser') {
                color = 'volcano';
              }

              return (
                <Tag color={color} key={rule}>
                  {rule}
                </Tag>
              );
            })}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      // width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return !editable ? (
          <Space size="middle">
            <Typography.Link
              onClick={() => {
                EditRecord(record);
              }}
              style={{
                marginRight: 8,
              }}
            >
              <IconButton sx={{ color: 'blue' }}>
                <AiFillEdit />
              </IconButton>
            </Typography.Link>
            <Typography.Link
              onClick={() => {
                deleteRecord(record);
              }}
              style={{
                marginRight: 8,
                color: 'red',
              }}
            >
              <IconButton sx={{ color: 'red' }}>
                <AiFillDelete />
              </IconButton>
            </Typography.Link>
          </Space>
        ) : (
          <Space size="middle">
            <Typography.Link onClick={() => saveRecord(record.fieldname)}>
              <Button type="primary" shape="round" size="small" style={{ color: 'white', backgroundColor: 'green' }}>
                save
              </Button>
            </Typography.Link>
            <Button type="primary" shape="round" size="small" onClick={cancel}>
              cancel
            </Button>
            <Typography.Link
              onClick={() => {
                deleteRecord(record);
              }}
              style={{
                marginRight: 8,
                color: 'red',
              }}
            >
              <Button type="primary" shape="round" danger icon={<DeleteFilled />} size="small">
                Delete
              </Button>
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === 'size' || col.dataIndex === 'scale'
            ? 'number'
            : col.dataIndex === 'rulename'
            ? 'select'
            : col.dataIndex === 'type'
            ? 'typeSelect'
            : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        handleChangeFieldType,
        customRules,
      }),
    };
  });

  return (
    <>
      <Box style={header}>
        <Stack direction="row" spacing={2}>
          {/* <Tooltip title="Row Level Operation">
            <Button
              variant="outlined"
              color="secondary"
              onClick={showModal}
              //  disabled={userRole === 'ROLE_reader'}
            >
              <AssignmentIcon color="secondary" fontSize="small" />
            </Button>
          </Tooltip> */}

          {/* <Link to={`/workflow/rule-engine/${workflow.workflow_name}`} style={{ textDecoration: 'none' }}>
            <Tooltip title="View Logs">
              <Button variant="outlined" color="warning">
                <FormatListBulletedIcon color="warning" fontSize="small" />
              </Button>
            </Tooltip>
          </Link> */}

          <MuiButton
            variant="outlined"
            color="primary"
            onClick={AddModal}
            size="small"
            // disabled={userRole === 'ROLE_reader'}
          >
            Add Column&nbsp;
            <AddCircleIcon color="primary" fontSize="small" />
          </MuiButton>

          <MuiButton
            variant="outlined"
            color="primary"
            // onClick={handleOpenFetchModal}
            onClick={() => handleOpenFetchModal(true)}
            size="small"
            // disabled={userRole === 'ROLE_reader'}
          >
            Update Schema <EmojiObjectsIcon color="primary" fontSize="small" />
          </MuiButton>
          <FormControl size="small" sx={{ minWidth: 220 }}>
            <InputLabel id="demo-simple-select-label">Select CSV</InputLabel>
            <MuiSelect
              labelId="select-csv"
              id="demo-simple-select-helper"
              value={pathName}
              onChange={handlePathName}
              size="small"
              label="Select CSV"
              sx={{ background: '#ffffff' }}
            >
              {fileNames.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>

          <MuiButton variant="outlined" color="primary" onClick={uploadFile} size="small">
            File upload
          </MuiButton>
        </Stack>
        {/* <div>
          <Tooltip title="Run" style={{ cursor: disableRun ? 'no-drop' : 'pointer' }}>
            <span>
              <Button
                variant="outlined"
                // disabled={userRole === 'ROLE_reader'}
                color="success"
                onClick={() => {
                  // setCurrentRecord(record);
                  showTimestampModal(
                    workflow.engagement.client.client_name,
                    workflow.engagement.engagement_name,
                    workflow.workflow_name
                  );
                }}
                //  onClick={runRuleEngine}
                //  disabled={disableRun}
              >
                <PlayArrowIcon {...(disableRun ? { color: 'disable' } : { color: 'success' })} fontSize="small" />
              </Button>
            </span>
          </Tooltip>
        </div> */}
      </Box>
      {!loading && (
        <Paper>
          <Form form={form} component={false}>
            <Table
              rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-light')}
              bordered="true"
              rowKey="fieldname"
              loading={loadingStatus}
              components={{
                body: {
                  cell: EditableCell,
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              pagination={{
                total: UpdateTableRuleFields.length,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Rules  `,
                position: ['bottomCenter'],
                defaultPageSize: 5,
                showSizeChanger: true,
                defaultCurrent: 1,
              }}
              columns={mergedColumns}
              dataSource={UpdateTableRuleFields}
            />
          </Form>
        </Paper>
      )}
      <Modal title="Add Column" visible={isAddModalVisible} onCancel={handleAddCancel} closable centered footer={null}>
        <Form
          form={formAdd}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          size="large"
          onFinish={onAddFinish}
        >
          <Form.Item
            label="Field Name"
            name="fieldName"
            rules={[{ required: true, message: 'Please input Table name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Index" name="position" rules={[{ required: true, message: 'Please input Table name!' }]}>
            <InputNumber min={0} max={UpdateTableRuleFields.length} />
          </Form.Item>
          <Form.Item
            label="Field Type"
            name="fieldType"
            rules={[{ required: true, message: 'Please input Table name!' }]}
          >
            <Select placeholder="select Field Type">
              <Option value="integer">integer</Option>
              <Option value="string">string</Option>
              <Option value="double">double</Option>
              <Option value="datetime">datetime</Option>
              <Option value="date">date</Option>
              <Option value="time">time</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Field Value"
            name="fieldValue"
            rules={[{ required: true, message: 'Please input Table name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ marginLeft: '40%' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Row Level Operation"
        visible={isModalVisible}
        onCancel={handleCancel}
        closable
        centered
        footer={null}
      >
        <Form
          form={formRowLevelOperation}
          labelCol={{ span: 30 }}
          wrapperCol={{ span: 14 }}
          style={{ fontSize: 34 }}
          layout="horizontal"
          size="large"
          onFinish={onFinish}
        >
          <Row>
            <Col span={8}>
              <Form.Item label="ignoreBlankLines" name="ignoreblanklines" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="skipHeaders" name="skipheaders" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="skipTrailers" name="skiptrailers" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="columnShift" name="columnshift" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="junkRecords" name="junkrecords" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="lineBreak" name="linebreak" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item label="Delimiter" name="delimiter">
              <Input defaultValue="," />
            </Form.Item>
          </Row>
          <Form.Item style={{ marginLeft: '40%' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`${tableNameModal} Rule Engine Execution`}
        visible={isRunModalVisible}
        onCancel={handleTimestampCancel}
        footer={null}
        centered
        maskClosable
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 22 }}
          layout="horizontal"
          onFinish={onTimestampFinish}
        >
          <Form.Item
            name="Folders"
            label="TimeStamp"
            rules={[
              {
                required: true,
                message: 'Please select folder!',
              },
            ]}
          >
            <Select onChange={getAllFiles}>
              {FoldersData &&
                FoldersData.map((ele, idx) => {
                  return (
                    <Option key={idx} value={ele.value}>
                      {ele.label}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name="Files"
            label="File"
            rules={[
              {
                required: true,
                message: 'Please select file!',
              },
            ]}
          >
            <Select>
              {FilesData &&
                FilesData.map((ele, idx) => {
                  return (
                    <Option key={idx} value={ele.value}>
                      {ele.label}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Row>
            <Col span={8}>
              <Form.Item style={{ marginLeft: '80%' }}>
                <Button
                  type="primary"
                  loading={saveLoadingStatus}
                  onClick={() => {
                    saveButtonClick();
                  }}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item style={{ marginLeft: '60%' }}>
                <Button disabled={submitButtonDisable} type="primary" htmlType="submit">
                  Run
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {openModal && (
        <UploadModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          type="ruleEngine"
          metadata={fileUploadMetaData}
        />
      )}
      {/* {openModal && (
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <FileUpload handleCloseModal={handleCloseModal} type="ruleEngine" metadata={fileUploadMetaData} />
      )} */}

      {openConnectionModal && (
        <AddConnectionModal open={setOpenConnectionModal} close={handleCloseAddNewConnectionModal} />
      )}
      <FetchModal
        openFetchModal={openFetchModal}
        handleCloseFetchModal={handleCloseFetchModal}
        getTableRules={getTableRules}
        isUpdate={isUpdate}
        fileNames={fileNames}
        setFileNames={setFileNames}
        pathName={pathName}
        setInitialTableRule={setInitialTableRule}
        setUpdateTableRule={setUpdateTableRule}
        setUpdateTableRuleFields={setUpdateTableRuleFields}
      />
      {loading && <LoadingIcon />}
    </>
  );
});

export default RuleTableSectionNew;
