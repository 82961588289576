import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Typography,
  Toolbar,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  ButtonGroup,
  TextField,
  MenuItem,
  Grid,
  TableContainer,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Autocomplete,
  Modal,
  Tooltip,
  Box,
  FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LoadingIcon from '../../../../reusable-component/LoadingIcon';
import InputField from '../../../../reusable-component/InputField';
import { fetchPostgresDatabase, fetchPostgresTables, fetchPostgresSchema } from "../../../../api's/DataProcessorApi";
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';
import generateAlias from '../../../../utils/generateAlias';
import { AWS_CONFIG_TYPE } from '../../../../BaseUrl';
import { saveReportsData } from "../../../../api's/ReportingApi";

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NodeModal_Write = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { workflow } = useContext(WorkflowContext);

  const domain_name = workflow.engagement.client.depDomainEntity.domain_name;
  const client_name = workflow.engagement.client.client_name;
  const engagement_name = workflow.engagement.engagement_name;
  const workflow_name = workflow.workflow_name;

  const output_path = `${
    AWS_CONFIG_TYPE ? 'dep-qa' : 'dep-develop'
  }/${domain_name}/${client_name}/${engagement_name}/${workflow_name}/Output/${generateAlias(nodeName, nodeId)}`;
  const INITIALSTATE = {
    path: output_path,
    format: '',
    persist: false,
    alias: generateAlias(nodeName, nodeId),
    snowflake_mode: '',
    snowflake_db_table: '',
    persist_type: '',
    partition: false,
    overwrite: false,
    df: '',
    p_key: '',
    index: '',
    database: '',
    tablename: '',
    db_type: '',
    mode: '',
    schema: '',
    distinct_rows: false,
    stream_interval: '',
    sample_view: true,
  };

  const { enqueueSnackbar } = useSnackbar();
  const [formField, setFormField] = useState(INITIALSTATE);

  const [disableForm, setDisableForm] = useState(false);
  const [tableName, setTableName] = useState([]);
  const [toggleType, setToggleType] = useState('S3');
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);
  const [errors, setErrors] = useState({ stream_interval: '' });
  const [prevNodeName, setPrevNodeName] = useState('');

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  console.log({ workflow });

  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              header: obj.header,
              alias: obj.alias,
              checked: true,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: obj.checked,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: obj.checked,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: false,
              alias: '',
              cast_datatype: 'string',
              attribute_type: 'static',
              default_value: '',
            };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          alias: n.alias,
          checked: true,
          cast_datatype: n.cast_datatype ? n.cast_datatype : 'string',
          attribute_type: 'static',
          default_value: '',
          // type: n.type ? n.type : 'string',
          // deleted: n.deleted ? n.deleted : false,
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });
    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            setPrevNodeName(node.step_name);
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const isHeader = header.some((item) => item.header);
      if (isHeader) {
        const newArr = [];

        if (prevNodeName === 'Join') {
          header.forEach((el) => {
            const exist = el.header.split('.').length;

            if (exist === 2) {
              const head = el.header.split('.')[1];
              newArr.push({ ...el, header: el.alias ? el.alias : head });
            }
          });
        }
        if (newArr.length > 0) {
          header = newArr;
        }

        const uniqueHeader = [];

        const uniqueArray = header.filter((element) => {
          const isDuplicate = uniqueHeader.includes(element.header);

          if (!isDuplicate) {
            uniqueHeader.push(element.header);
            return true;
          }

          return false;
        });

        const head = [];

        const newHeader = uniqueArray.map((el) => {
          return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
        });

        newHeader.forEach((el) => {
          head.push({ label: el.header });
        });

        setHeaders(head);
        setFetchedHeader(newHeader);
      } else {
        setHeaders([]);
        setFetchedHeader([]);
      }
      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setToggleType(node.toggleType);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  const validate = () => {
    const numbers = /^[0-9]+$/;
    if (!String(formField?.stream_interval)?.match(numbers)) {
      setErrors({ ...errors, stream_interval: 'Only integres allowed' });
    } else {
      setErrors({ stream_interval: '' });
    }
  };

  useEffect(() => {
    validate();
  }, [formField.stream_interval, errors.stream_interval]);

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    setToggleType('S3');
    getLocalData();
  }, [nodeId]);

  const sendFormData = {
    nodeId,
    nodeName,
    formField,
    disabled: false,
    step_name: nodeName,
  };

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            setPrevNodeName(node.step_name);
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });
      const isHeader = header.some((item) => item.header);

      if (isHeader) {
        const newArr = [];

        if (prevNodeName === 'Join') {
          header.forEach((el) => {
            const exist = el.header.split('.').length;

            if (exist === 2) {
              const head = el.header.split('.')[1];
              newArr.push({ ...el, header: el.alias ? el.alias : head });
            }
          });
        }
        if (newArr.length > 0) {
          header = newArr;
        }

        const uniqueHeader = [];

        const uniqueArray = header.filter((element) => {
          const isDuplicate = uniqueHeader.includes(element.header);

          if (!isDuplicate) {
            uniqueHeader.push(element.header);
            return true;
          }

          return false;
        });

        const head = [];

        const newHeader = uniqueArray.map((el) => {
          return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
        });

        newHeader.forEach((el) => {
          head.push({ label: el.header });
        });

        setHeaders(head);

        if (nodesExist) {
          store.forEach((node) => {
            if (node.nodeId === nodeId) {
              setFormField(node.formField);
              setHeaderName(node.headerName);
              setToggleType(node.toggleType);

              const nonExistingHeader = [];
              node.fetchedHeader.forEach((item) => {
                const index = newHeader.findIndex((x) => x.header === item.header);

                if (index !== -1) {
                  nonExistingHeader.push(item);
                }
              });

              newHeader.forEach((el) => {
                const index = nonExistingHeader.findIndex((x) => x.header === el.header);

                if (index === -1) {
                  nonExistingHeader.push({
                    ...el,
                    header: el.alias ? el.alias : el.header,
                    alias: el.alias ? '' : el.alias,
                  });
                }
              });

              setFetchedHeader(nonExistingHeader);
            }
          });
        } else {
          setFetchedHeader(newHeader);
        }
      } else {
        setFetchedHeader([]);
      }
    }

    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  useEffect(() => {
    if (store) {
      setTableName(
        store.map((node, i) => {
          if (node.nodeName !== 'Write') {
            return node.formField.alias;
          }
        })
      );
    }
  }, []);

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    // const regex = /^https:\/\//i;

    // if (toggleType === 'S3' && !regex.test(formField.path)) {
    //   enqueueSnackbar('S3 path is invalid!', {
    //     variant: 'error',
    //     autoHideDuration: 3000,
    //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //   });
    //   return;
    // }

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    // let y_axis = 0;
    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    // headerName.forEach((item) => {
    //   newHeaderName.push({ ...item, tableAlias: formField.alias });
    // });

    fetchedHeader.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField: {
        ...formField,
        index:
          formField.index.toLowerCase() ||
          `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`.toLowerCase(),
      },
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      toggleType,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    // if (!errors.stream_interval) {
    //   setDisableForm(true);
    // }
    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '70%', marginLeft: '30%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  onClick={refreshAllState}
                  aria-label="close"
                  sx={{ color: '#00043C' }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid item xs={4}>
                <InputField
                  name="data_set_id"
                  label="Data Set Id"
                  value={formField.p_key || `${workflow.workflow_name}_ds_${nodeId.split('_')[1]}`}
                  size="small"
                  disabled
                  required
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  name="alias"
                  label="Alias"
                  value={formField.alias}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                  required
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formField.sample_view}
                      onChange={handleChecked}
                      disabled={disableForm}
                    />
                  }
                  label="Sample View"
                  sx={{ pt: 1 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Distinct Rows"
                  size="small"
                  value={formField.distinct_rows}
                  onChange={(event) => {
                    setFormField({
                      ...formField,
                      distinct_rows: event.target.value,
                    });
                  }}
                  disabled={disableForm}
                  style={{ width: '100%', marginTop: '15px' }}
                >
                  <MenuItem value="true">true</MenuItem>
                  <MenuItem value="false">false</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  name="df"
                  label="DF"
                  value={formField.df}
                  onChange={handleInputChange}
                  size="small"
                  sx={{ mt: 2 }}
                  disabled={disableForm}
                  required
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "'EB Garamond', serif ",
                      fontWeight: 600,
                    },
                  }}
                  InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                >
                  {tableName.map((node, i) => {
                    if (node !== undefined) {
                      return (
                        <MenuItem value={node} key={i}>
                          {node}
                        </MenuItem>
                      );
                    }
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  select
                  label="Format"
                  variant="outlined"
                  value={formField.format}
                  onChange={handleInputChange}
                  name="format"
                  size="small"
                  fullWidth
                  sx={{ mt: 2 }}
                  disabled={disableForm}
                  required
                  InputProps={{
                    style: {
                      fontWeight: 600,
                    },
                  }}
                >
                  <MenuItem value="txt">txt</MenuItem>
                  <MenuItem value="doc">doc</MenuItem>
                  <MenuItem value="png">png</MenuItem>
                  <MenuItem value="csv">csv</MenuItem>
                  <MenuItem value="gif">gif</MenuItem>
                  <MenuItem value="streaming">streaming</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  select
                  label="Overwrite"
                  variant="outlined"
                  value={formField.overwrite}
                  name="overwrite"
                  onChange={handleInputChange}
                  sx={{ mt: 2 }}
                  fullWidth
                  size="small"
                  disabled={disableForm}
                  required
                >
                  <MenuItem value>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={4}>
                <InputField
                  name="path"
                  label="File Path"
                  value={formField.path}
                  onChange={handleInputChange}
                  size="small"
                  disabled
                  required
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={4}>
                {formField.format === 'streaming' && (
                  <InputField
                    required
                    name="stream_interval"
                    label="Stream Interval"
                    value={formField.stream_interval}
                    onChange={handleInputChange}
                    size="small"
                    type="number"
                    disabled={disableForm}
                    // error={errors.stream_interval}
                    // helperText={errors.stream_interval}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  select
                  label="Partition"
                  variant="outlined"
                  value={formField.partition}
                  name="partition"
                  onChange={handleInputChange}
                  fullWidth
                  size="small"
                  disabled={disableForm}
                  required
                >
                  <MenuItem value>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  select
                  label="Persist"
                  variant="outlined"
                  value={formField.persist}
                  name="persist"
                  onChange={handleInputChange}
                  sx={{ mt: 2 }}
                  size="small"
                  disabled={disableForm}
                  required
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "'EB Garamond', serif ",
                      fontWeight: 600,
                    },
                  }}
                  InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                >
                  <MenuItem value>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                {formField.persist === true && (
                  <TextField
                    id="outlined-basic"
                    select
                    label="Persist Type"
                    variant="outlined"
                    value={formField.persist_type}
                    name="persist_type"
                    onChange={handleInputChange}
                    sx={{ mt: 2 }}
                    size="small"
                    disabled={disableForm}
                    required
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "'EB Garamond', serif ",
                        fontWeight: 600,
                      },
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "'EB Garamond', serif " },
                    }}
                  >
                    <MenuItem value="DISK_ONLY">DISK_ONLY</MenuItem>
                    <MenuItem value="DISK_ONLY_2">DISK_ONLY_2</MenuItem>
                    <MenuItem value="MEMORY_ONLY">MEMORY_ONLY</MenuItem>
                    <MenuItem value="MEMORY_ONLY_2">MEMORY_ONLY_2</MenuItem>
                    <MenuItem value="MEMORY_AND_DISK">MEMORY_AND_DISK</MenuItem>
                    <MenuItem value="MEMORY_AND_DISK_2">MEMORY_AND_DISK_2</MenuItem>
                  </TextField>
                )}
              </Grid>
            </Grid>

            <>
              {fetchedHeader.length > 0 && prevNodeName !== 'Execute SQL' && (
                <TableContainer sx={{ maxHeight: 300 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            color="primary"
                            disabled={disableForm}
                            checked={fetchedHeader.length === headerName.length}
                            onChange={handleSelectAllClick}
                          />
                          Select Columns
                        </TableCell>
                        <TableCell>Columns</TableCell>
                        <TableCell>Datatype</TableCell>
                        <TableCell>Alias</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fetchedHeader.map((row, i) => {
                        const isItemSelected = isSelected(row.header);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={i}
                            selected={isItemSelected}
                          >
                            <TableCell>
                              <Checkbox
                                checked={isItemSelected}
                                disabled={disableForm}
                                onChange={(event) =>
                                  handleHeaderChange({
                                    header: row.header,
                                    alias: '',
                                    checked: event.target.checked,
                                    cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                    default_value: row.default_value,
                                    attribute_type: row.attribute_type,
                                    type: row.type ? row.type : 'string',
                                    deleted: row.deleted ? row.deleted : false,
                                  })
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            </TableCell>
                            <TableCell>{row.header}</TableCell>
                            <TableCell>
                              <TextField
                                id="standard-select-currency"
                                select
                                label="Cast Datatype"
                                name="cast_datatype"
                                disabled={disableForm}
                                value={row.cast_datatype ? row.cast_datatype : 'string'}
                                onChange={(event) =>
                                  handleHeaderChange({
                                    header: row.header,
                                    alias: row.alias,
                                    checked: true,
                                    cast_datatype: event.target.value,
                                    default_value: row.default_value,
                                    attribute_type: row.attribute_type,
                                  })
                                }
                                style={{ width: '100%' }}
                                size="small"
                              >
                                <MenuItem value="int">Integer</MenuItem>
                                <MenuItem value="string">String</MenuItem>
                                <MenuItem value="float">Float</MenuItem>
                                <MenuItem value="timestamp">Timestamp</MenuItem>
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="alias"
                                label="Alias"
                                value={row.alias}
                                disabled={disableForm}
                                onChange={(e) =>
                                  handleHeaderChange({
                                    header: row.header,
                                    alias: e.target.value,
                                    checked: true,
                                    cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                    default_value: row.default_value,
                                    attribute_type: row.attribute_type,
                                    type: row.type ? row.type : 'string',
                                    deleted: row.deleted ? row.deleted : false,
                                  })
                                }
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeModal_Write;
