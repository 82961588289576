import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import DomainTable from '../components/approvals/DomainTable';
import ClientTable from '../components/approvals/ClientTable';
import EngagementTable from '../components/approvals/EngagementTable';
import WorkflowTable from '../components/approvals/WorkflowTable';
import UserTable from '../components/approvals/UserTable';
import GrantClient from '../components/approvals/GrantClient';
import { getPendingClientApi } from "../api's/ClientApi";
import { getPendingEngagementApi } from "../api's/EngagementApi";
import { getPendingWorkflowApi } from "../api's/WorkflowApi";
import { getPendingUserApi } from "../api's/UserApi";
import { getPendingDomainApi } from "../api's/DomainApi";
import LoadingIcon from '../reusable-component/LoadingIcon';
import {
  CLIENT_PAGE,
  ENGAGEMENT_PAGE,
  WORKFLOW_PAGE,
  USER_PAGE,
  APPROVAL_PAGE,
  DOMAIN_PAGE,
} from '../utils/commonConstants';
import ClientNoData from '../components/Client/ClientNoData';

const ApprovalPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);
  const [clientTableData, setClientTableData] = useState([]);
  const [engagementTableData, setEngagementTableData] = useState([]);
  const [workflowTableData, setWorkflowTableData] = useState([]);
  const [userTableData, setUserTableData] = useState([]);
  const [domainTableData, setDomainTableData] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const fetchPendingDomainData = async () => {
    setLoading(true);
    try {
      const response = await getPendingDomainApi();
      if (response.status === 200) {
        setDomainTableData(response.data);
      }
    } catch (error) {
      setDomainTableData([]);
      if (error.response.status === 403) {
        enqueueSnackbar('You have dont Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const fetchPendingClientData = async () => {
    setLoading(true);
    try {
      const response = await getPendingClientApi();
      if (response.status === 200) {
        setClientTableData(response.data);
      }
    } catch (error) {
      setClientTableData([]);
      if (error.response.status === 403) {
        enqueueSnackbar('You have dont Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const fetchPendingEngagementData = async () => {
    setLoading(true);
    try {
      const response = await getPendingEngagementApi();
      if (response.status === 200) {
        setEngagementTableData(response.data);
      }
    } catch (error) {
      setEngagementTableData([]);
      if (error.response.status === 403) {
        enqueueSnackbar('You have dont Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const fetchPendingWorkflowData = async () => {
    setLoading(true);
    try {
      const response = await getPendingWorkflowApi();
      if (response.status === 200) {
        setWorkflowTableData(response.data);
      }
    } catch (error) {
      setWorkflowTableData([]);
      if (error.response.status === 403) {
        enqueueSnackbar('You have dont Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const fetchPendingUserData = async () => {
    setLoading(true);
    try {
      const response = await getPendingUserApi();
      if (response.status === 200) {
        setUserTableData(response.data);
      }
    } catch (error) {
      setUserTableData([]);
      if (error.response.status === 403) {
        enqueueSnackbar('You have dont Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPendingClientData();
    fetchPendingEngagementData();
    fetchPendingWorkflowData();
    fetchPendingUserData();
    fetchPendingDomainData();
  }, []);

  return (
    <Box>
      <Typography sx={{ color: '#43425D' }}> {APPROVAL_PAGE.approvalsPageTitle}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#47D7AC',
            },
          }}
          sx={{ '& .MuiTab-root.Mui-selected': { color: '#00043C' } }}
        >
          <Tab label={`Domain(${domainTableData.length})`} {...a11yProps(0)} sx={{ fontWeight: 'bold' }} />
          <Tab label={`Client(${clientTableData.length})`} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
          <Tab label={`Engagement(${engagementTableData.length})`} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
          <Tab label={`Workflow(${workflowTableData.length})`} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
          <Tab label={`User(${userTableData.length})`} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
          {/* <Tab label={`Grant Client`} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} /> */}
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          {domainTableData.length > 0 ? (
            <DomainTable tableData={domainTableData} fetchTableData={fetchPendingDomainData} />
          ) : (
            !loading && (
              <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                <ClientNoData title={DOMAIN_PAGE.domainPageNoDataToDisplay} text={DOMAIN_PAGE.noDomainToReview} />
              </Paper>
            )
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {clientTableData.length > 0 ? (
            <ClientTable tableData={clientTableData} fetchTableData={fetchPendingClientData} />
          ) : (
            !loading && (
              <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                <ClientNoData title={CLIENT_PAGE.clientPageNoDataToDisplay} text={CLIENT_PAGE.noClientToReview} />
              </Paper>
            )
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {engagementTableData.length > 0 ? (
            <EngagementTable tableData={engagementTableData} fetchTableData={fetchPendingEngagementData} />
          ) : (
            !loading && (
              <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                <ClientNoData
                  title={ENGAGEMENT_PAGE.engagementPageNoDataToDisplay}
                  text={ENGAGEMENT_PAGE.noEngagementToReview}
                />
              </Paper>
            )
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {workflowTableData.length > 0 ? (
            <WorkflowTable tableData={workflowTableData} fetchTableData={fetchPendingWorkflowData} />
          ) : (
            !loading && (
              <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                <ClientNoData
                  title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
                  text={WORKFLOW_PAGE.noWorkflowToReview}
                />
              </Paper>
            )
          )}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {userTableData.length > 0 ? (
            <UserTable tableData={userTableData} fetchTableData={fetchPendingUserData} />
          ) : (
            !loading && (
              <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                <ClientNoData title={USER_PAGE.userPageNoDataToDisplay} text={USER_PAGE.noUserToReview} />
              </Paper>
            )
          )}
        </TabPanel>
        {/* <TabPanel value={value} index={5}>
          <GrantClient />
        </TabPanel> */}
      </Box>
      {loading && <LoadingIcon />}
    </Box>
  );
};

export default ApprovalPage;
