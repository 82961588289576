import React from 'react';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { deleteWorkflowApi } from "../api's/WorkflowApi";
import { deleteEngagementApi } from "../api's/EngagementApi";
import { deleteClientApi } from "../api's/ClientApi";
import { deleteDomainApi } from "../api's/DomainApi";

export default function DeleteModal({ handleCloseDeleteModal, openDeleteModal, fetchData, type, id }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteWorkflow = async () => {
    try {
      const response = await deleteWorkflowApi(id);

      if (response.status === 200) {
        enqueueSnackbar('Workflow Deleted Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleCloseDeleteModal();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleDeleteEngagement = async () => {
    try {
      const response = await deleteEngagementApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Engagement Deleted Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleCloseDeleteModal();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleDeleteClient = async () => {
    try {
      const response = await deleteClientApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Client Deleted Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleCloseDeleteModal();
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleDeleteDomain = async () => {
    try {
      const response = await deleteDomainApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Domain Deleted Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleCloseDeleteModal();
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleOnDelete = (type) => {
    const access_right = JSON.parse(sessionStorage.getItem('access_right'));

    if (access_right !== 'SUPER_USER') {
      enqueueSnackbar(`You don't have permission to perform this action!!`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseDeleteModal();
      return;
    }

    switch (type) {
      case 'Workflow':
        return handleDeleteWorkflow();
      case 'Engagement':
        return handleDeleteEngagement();
      case 'Client':
        return handleDeleteClient();
      case 'Domain':
        return handleDeleteDomain();
      default:
    }
  };

  return (
    <div>
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete?`}</DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDeleteModal}
            sx={{ textTransform: 'capitalize', color: '#43425D', border: '1px solid #43425D' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleOnDelete(type)}
            autoFocus
            sx={{ backgroundColor: '#00043C', textTransform: 'capitalize', '&:hover': { backgroundColor: '#00043C' } }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteModal.propTypes = {
  handleCloseDeleteModal: PropTypes.func,
  openDeleteModal: PropTypes.bool,
  fetchData: PropTypes.func,
  type: PropTypes.string,
  id: PropTypes.string,
};
