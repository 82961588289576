import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, CircularProgress, Input } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BASEURL from '../../BaseUrl';

const FileUpload = ({ handleCloseModal, type, metadata }) => {
  const [selectedFile, setSelectedFile] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  console.log("eewfew")

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files);
  };

  const handleCsvUpload = async (event) => {
    event.preventDefault();
    if (selectedFile.length === 0) {
      enqueueSnackbar(`Please upload a file`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append('file', selectedFile[i]);
    }
    console.log(formData);
    const data = { ...metadata, file: formData, filename: '' };
    console.log(data);
    axios
      .post(`${BASEURL}/rule-engine/create-rule-engine-by-file`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(`Files uploaded successfully`, {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          // navigate('/chat-gpt');
        }
        // Do something with the response, such as show a success message
      })
      .catch((error) => {
        enqueueSnackbar(`Invalid file`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        // Handle the error, such as showing an error message
      });
    setLoading(false);
    handleCloseModal();
  };
  return (
    <form onSubmit={handleCsvUpload}>
      <Box>
        <Button
          variant="contained"
          component="label"
          sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
        >
          <input type="file" multiple onChange={handleFileInputChange} />
        </Button>
      </Box>
      {!loading ? (
        <Button
          type="submit"
          sx={{
            marginTop: '20px',
            backgroundColor: '#00043C',
            color: '#ffffff',
            '&:hover': { backgroundColor: '#00043C' },
          }}
        >
          Upload
        </Button>
      ) : (
        <LoadingButton loading variant="outlined" sx={{ marginTop: '20px' }}>
          Submit
        </LoadingButton>
      )}
    </form>
  );
};

export default FileUpload;
