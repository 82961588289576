import React, { useState, memo, useEffect, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Box,
  IconButton,
  MenuItem,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  FormControl,
  Grid,
  Modal,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Radio } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import InputField from '../../../reusable-component/InputField';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import { getJsonData } from "../../../api's/RuleEngineApi";
import {
  fetchOracleDatabase,
  fetchOracleTables,
  fetchOracleColumn,
  getCsvHeaderList,
  createHeaderJson,
} from "../../../api's/DataProcessorApi";
import { BUCKET_NAME } from '../../../constants/Constant';
import { WorkflowContext } from '../../../contexts/WorkflowProvider';
import { AuthContext } from '../../../contexts/AuthProvider';
import { AWS_CONFIG_TYPE } from '../../../BaseUrl';
import FileUploader from './read/FileUploader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
  path: '',
  format: 'kafka',
  host: '192.168.1.100:9092',
  topic: '',
  alias: '',
  distinct_rows: false,
  sample_view: true,
  priority: '',
};
const RowLevelOperation = {
  ignoreblanklines: false,
  skipheaders: false,
  columnshift: false,
  junkrecords: false,
  linebreak: false,
  delimiter: ',',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const NodeModal_ReadRealTime = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userRole } = useContext(AuthContext);
  const { workflow } = useContext(WorkflowContext);

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [flowbuilderRowLevelOperation, setflowbuilderRowLevelOperation] = useState(RowLevelOperation);
  const [DataType, setDataType] = useState('source');
  const [isDataLoad, setisDataLoad] = useState(false);
  const [addColumn, setAddColumn] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [tempHeader, setTempHeader] = useState([]);
  const [attributeType, setAttributeType] = useState('static');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);

  const attributeOptions = [
    {
      label: 'Static',
      value: 'static',
    },
    {
      label: 'Function',
      value: 'function',
    },
  ];

  const bucketValue = AWS_CONFIG_TYPE ? `s3://${BUCKET_NAME}` : `https://depazure.blob.core.windows.net/dep-develop/`;

  const [addColumnModalOpen, setAddColumnModalOpen] = useState(false);
  const handleAddColumnModalOpen = () => setAddColumnModalOpen(true);
  const handleAddColumnModalClose = () => setAddColumnModalOpen(false);

  const [addColumnArray, setAddColumnArray] = useState([]);

  const checklabel = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const onChangeDataType = ({ target: { value } }) => {
    setDataType(value);
    setisDataLoad(true);
    setFormField({ ...formField, dataTypeRead: value });
  };

  const handleFileUploadChange = async (event) => {
    setIsUploadDisabled(false);

    setSelectedFile(event.target.files);
    event.preventDefault();
    let text;
    const reader = new FileReader();
    reader.onload = async (event) => {
      text = event.target.result;
    };
    reader.readAsText(event.target.files[0]);

    if (text?.trim().length <= 0) {
      enqueueSnackbar(`File is empty!`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  // showFile = async (e) => {
  //   e.preventDefault()
  //   const reader = new FileReader()
  //   reader.onload = async (e) => {
  //     const text = (e.target.result)
  //     console.log(text)
  //     alert(text)
  //   };
  //   reader.readAsText(e.target.files[0])
  // }

  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              header: obj.header,
              alias: obj.alias,
              checked: true,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: false,
              alias: '',
              cast_datatype: 'string',
              attribute_type: 'static',
              default_value: '',
            };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          alias: n.alias,
          checked: true,
          cast_datatype: 'string',
          attribute_type: 'static',
          default_value: '',
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });

    return selectedIndex !== -1;
  };

  const handleAddColumn = () => {
    setFetchedHeader([
      ...fetchedHeader,
      {
        header: addColumn,
        alias: '',
        checked: false,
        cast_datatype: 'string',
        default_value: defaultValue,
        attribute_type: attributeType,
      },
    ]);

    setAddColumnArray([...addColumnArray, addColumn]);
    handleAddColumnModalClose();
    setAddColumn('');
    setDefaultValue('');
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    if (store) {
      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setisDataLoad(true);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setAddColumnArray(node.attributes);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  const handleUpload = (e) => {
    // Perform file upload logic here
    e.preventDefault();
    setIsUploadDisabled(false);
    if (selectedFile) {
      let isCsv = true;
      for (let i = 0; i < selectedFile.length; i++) {
        if (!selectedFile[i].name.toLowerCase().endsWith('.csv')) {
          isCsv = false;
        }
      }

      if (isCsv === false) {
        setIsUploadDisabled(true);
        enqueueSnackbar(`Only .csv files allowed`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        fetchCsvHeaderList();
      }
    } else {
      enqueueSnackbar(`No file chosen!`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const fetchCsvHeaderList = async () => {
    const data = {
      file: selectedFile,
      aws_config_type: AWS_CONFIG_TYPE,
      filename: selectedFile[0].name,
      workflow_id: workflow.workflow_id,
      delimiter: ',',
      file_type: 'csv',
      read_type: 'realtime',
    };

    try {
      const response = await getCsvHeaderList(data);
      if (response.status === 200) {
        const header = [];

        response.data.headers.forEach((el) => {
          header.push({
            header: el,
            alias: '',
            checked: false,
            tableAlias: '',
            cast_datatype: 'string',
            default_value: '',
            attribute_type: 'static',
          });
        });
        setFormField({ ...formField, path: response.data.path_json });

        setFetchedHeader([...header]);
        setHeaderName([]);
        setAddColumnArray([]);
        enqueueSnackbar('File uploaded successfully!', {
          variant: 'Success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setflowbuilderRowLevelOperation(RowLevelOperation);
    setDisableForm(false);
    setFetchedHeader([]);
    setAddColumnArray([]);
    setHeaderName([]);
    getLocalData();
  }, [nodeId]);

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    // try {
    if (fetchedHeader.length > 0) {
      const columnArray = [];
      headerName.forEach((el) => {
        if (el.checked === 'true' || el.checked) columnArray.push({ fieldname: el.header, type: el.cast_datatype });
      });

      const filteredArray = [];

      if (addColumnArray?.length > 0) {
        columnArray.forEach((el) => {
          const selectedIndex = addColumnArray.findIndex((object) => {
            return object === el.fieldname;
          });

          if (selectedIndex === -1) {
            filteredArray.push(el);
          }
        });
      }

      const data = {
        aws_config_type: AWS_CONFIG_TYPE,
        read_type: 'realtime',
        workflow_id: workflow.workflow_id,
        columnArray: addColumnArray?.length > 0 ? filteredArray : columnArray,
      };

      await createHeaderJson(data);
    }
    // } catch (error) {
    //   enqueueSnackbar('Something went wrong!', {
    //     variant: 'error',
    //     autoHideDuration: 3000,
    //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //   });
    // }

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        el.data.label = formField.alias;
      }
    });

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      attributes: addColumnArray,
    };

    changeNodeName(nodes);
    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };
  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition} style={{ width: '70%', marginLeft: '30%' }}>
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{ color: '#00043C' }}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>
          <div style={{ margin: '20px' }}>
            <input type="hidden" value={nodeId} />
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl sx={{ minWidth: 270 }}>
                  <InputField
                    name="alias"
                    label="Alias"
                    value={formField.alias}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formField.sample_view}
                      onChange={handleChecked}
                      disabled={disableForm}
                    />
                  }
                  label="Sample View"
                  sx={{ pt: 2 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl sx={{ minWidth: 270 }}>
                  <TextField
                    id="outlined-basic"
                    label="Endpoint"
                    variant="outlined"
                    value={formField.host}
                    onChange={handleInputChange}
                    name="host"
                    sx={{ mt: 2 }}
                    size="small"
                    disabled={disableForm}
                    required
                    InputProps={{
                      style: {
                        fontFamily: "'EB Garamond', serif ",
                        fontWeight: 600,
                      },
                    }}
                    InputLabelProps={{ shrink: true, style: { fontFamily: "'EB Garamond', serif " } }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl sx={{ minWidth: 270 }}>
                  <InputField
                    required
                    name="topic"
                    label="Topic"
                    value={formField.topic}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl sx={{ minWidth: 270 }}>
                  <TextField
                    id="outlined-basic"
                    select
                    label="Format"
                    variant="outlined"
                    value={formField.format}
                    onChange={handleInputChange}
                    name="format"
                    sx={{ mt: 2 }}
                    size="small"
                    disabled
                    required
                    InputProps={{
                      style: {
                        fontFamily: "'EB Garamond', serif ",
                        fontWeight: 600,
                      },
                    }}
                    InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                  >
                    <MenuItem value="kafka">kafka</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {workflow.process_type === 'XPerience360' && (
                  <FormControl sx={{ minWidth: 270 }}>
                    <InputField
                      name="priority"
                      label="Priority"
                      value={formField.priority}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                      required
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 2, width: '32ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <FileUploader
                handleFileUploadChange={handleFileUploadChange}
                handleUpload={handleUpload}
                disableForm={disableForm}
                isUploadDisabled={isUploadDisabled}
              />
              <Button
                disabled={disableForm}
                variant="contained"
                endIcon={<AddIcon />}
                onClick={handleAddColumnModalOpen}
              >
                Add Column
              </Button>
              <Modal
                open={addColumnModalOpen}
                onClose={handleAddColumnModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Radio.Group
                    options={attributeOptions}
                    size="large"
                    name="DataTypeRadio"
                    onChange={(e) => setAttributeType(e.target.value)}
                    value={attributeType}
                    disabled={disableForm}
                  />
                  <InputField
                    name="header"
                    label="Attribute"
                    onChange={(e) => setAddColumn(e.target.value)}
                    size="small"
                    required
                  />
                  <InputField
                    name="default_value"
                    label="Default Value"
                    onChange={(e) => setDefaultValue(e.target.value)}
                    size="small"
                    required
                  />

                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    color="secondary"
                    type="submit"
                    className="button-color"
                    onClick={handleAddColumn}
                  >
                    Add
                  </Button>
                </Box>
              </Modal>
            </Box>
            {!loadingBtn && fetchedHeader.length > 0 && (
              <TableContainer sx={{ maxHeight: 300 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={fetchedHeader.length === headerName.length}
                          onChange={handleSelectAllClick}
                        />
                        Select Columns
                      </TableCell>
                      <TableCell>Columns</TableCell>
                      <TableCell>Datatype</TableCell>
                      <TableCell>Alias</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchedHeader.map((row, i) => {
                      const isItemSelected = isSelected(row.header);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={i}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <Checkbox
                              checked={isItemSelected}
                              disabled={disableForm}
                              onChange={(event) =>
                                handleHeaderChange({
                                  header: row.header,
                                  alias: '',
                                  checked: event.target.checked,
                                  cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                  default_value: row.default_value,
                                  attribute_type: row.attribute_type,
                                })
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </TableCell>
                          <TableCell>{row.header}</TableCell>

                          <TableCell>
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Cast Datatype"
                              name="cast_datatype"
                              disabled={disableForm}
                              value={row.cast_datatype ? row.cast_datatype : 'string'}
                              onChange={(event) =>
                                handleHeaderChange({
                                  header: row.header,
                                  alias: row.alias,
                                  checked: true,
                                  cast_datatype: event.target.value,
                                  default_value: row.default_value,
                                  attribute_type: row.attribute_type,
                                })
                              }
                              style={{ width: '100%' }}
                              size="small"
                            >
                              <MenuItem value="int">Integer</MenuItem>
                              <MenuItem value="string">String</MenuItem>
                              <MenuItem value="float">Float</MenuItem>
                              <MenuItem value="timestamp">Timestamp</MenuItem>
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <TextField
                              name="alias"
                              label="Alias"
                              value={row.alias}
                              disabled={disableForm}
                              onChange={(e) =>
                                handleHeaderChange({
                                  header: row.header,
                                  alias: e.target.value,
                                  checked: true,
                                  cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                  default_value: row.default_value,
                                  attribute_type: row.attribute_type,
                                })
                              }
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {loadingBtn && (
              <Box>
                <LoadingIcon />
              </Box>
            )}
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default memo(NodeModal_ReadRealTime);
