import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  ButtonGroup,
  IconButton,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  TableContainer,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  FormControl,
  MenuItem,
  Modal,
  Grid,
  Tooltip,
  FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import InputField from '../../../reusable-component/InputField';
import { WorkflowContext } from '../../../contexts/WorkflowProvider';
import { AWS_CONFIG_TYPE } from '../../../BaseUrl';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

let timestamp = '';
const pad2 = (n) => {
  return n < 10 ? `0${n}` : n;
};

const date = new Date();

timestamp = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(date.getDate())}${pad2(
  date.getHours()
)}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(date.getMilliseconds())}`;

const NodeProperties_Training = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);
  const [selected_cols, setSelected_cols] = useState('');
  const recommendation_cols = [
    { label: 'Usercols', value: 'user col' },
    {
      label: 'Ratingcols',
      value: 'rating col',
    },
    {
      label: 'Itemcols',
      value: 'item col',
    },
  ];
  const algo_cols = [
    { label: 'Targetcols', value: 'target Col' },
    {
      label: 'Featurecols',
      value: 'feature',
    },
  ];

  const INITIALSTATE = {
    path: `${AWS_CONFIG_TYPE ? 'dep-develop' : 'dep-qa'}/${workflow.engagement.client.client_name}/${
      workflow.engagement.engagement_name
    }/${workflow.workflow_name}/Model/Output/${timestamp}`,
    action: '',
    alias: '',
    df: '',
    db_name: '',
    statement: '',
    algo_type: '',
    algorithm: '',
    maxIter: '10',
    regParam: '0.3',
    elasticNetParam: '0.8',
    numTrees: 2,
    maxDepth: 2,
    k: '5',
    train: 0.7,
    test: 0.3,
    seed: 40,
    sample_view: true,
  };
  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);
  const [errors, setErrors] = useState({ train: '', test: '' });

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const handleHeaderChange = (obj, type) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    const selectedCol = headerName.findIndex((object) => {
      return object.col_type === 'target Col' && obj.col_type === 'target Col';
    });

    if (selectedCol !== -1 && type === 'col' && formField.algorithm !== 'ALS') {
      enqueueSnackbar('Target col can be selected for single row only!!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              header: obj.header,
              alias: obj.alias,
              checked: true,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
              col_type: obj.col_type,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: obj.checked,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
              col_type: obj.col_type,
            };
            // return { ...object, header: obj.header, alias: obj.alias };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: obj.checked,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
              col_type: obj.col_type,
            };
            // return { ...object, header: obj.header, alias: obj.alias };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: false,
              alias: '',
              cast_datatype: 'string',
              attribute_type: 'static',
              default_value: '',
              col_type: selected_cols,
            };
            // return { ...object, checked: false, alias: '' };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          alias: n.alias,
          checked: true,
          cast_datatype: n.cast_datatype ? n.cast_datatype : 'string',
          attribute_type: 'static',
          default_value: '',
          col_type: n.col_type ? n.col_type : selected_cols,
          // type: n.type ? n.type : 'string',
          // deleted: n.deleted ? n.deleted : false,
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });
    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getSourceData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);
      setFetchedHeader(newHeader);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setTempHeader(node.headerName);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  const validate = () => {
    if (formField.train > 1 || formField.train < 0 || formField.train.length <= 0) {
      setErrors({ ...errors, train: 'Enter value between 0 and 1' });
    } else if (formField.test > 1 || formField.test < 0 || formField.test.length <= 0) {
      setErrors({ ...errors, test: 'Enter value between 0 and 1' });
    } else if (parseFloat(formField.train) + parseFloat(formField.test) !== 1) {
      setErrors({
        ...errors,
        train: 'Sum of train and test should be equal to 1',
        test: 'Sum of train and test should be equal to 1',
      });
    } else {
      setErrors({ train: '', test: '' });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    getSourceData();
  }, [nodeId]);

  useEffect(() => {
    validate();
    console.log('formField.algo_type', formField.algo_type);

    if (formField.algorithm === 'ALS') {
      setSelected_cols(recommendation_cols[0].value);
    } else {
      setSelected_cols(algo_cols[1].value);
    }
  }, [formField.test, formField.train, errors.train, errors.test, formField.algorithm]);

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];

    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setHeaderName(node.headerName);

            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = newHeader.findIndex((x) => x.header === item.header);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            newHeader.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.header === el.header);

              if (index === -1) {
                nonExistingHeader.push({
                  ...el,
                  header: el.alias ? el.alias : el.header,
                  alias: el.alias ? '' : el.alias,
                });
              }
            });

            setFetchedHeader(nonExistingHeader);
            // if (node.disabled) {
            //   setDisableForm(node.disabled);
            // }
          }
        });
      } else {
        setFetchedHeader(newHeader);
      }
    }
    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const getDatabaseName = async () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    const sourceData = [];
    if (store && findSrcNodeId) {
      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            sourceData.push(node.formField.alias);
          }
        });
      });
    }
    const database_name = sourceData[0];

    return database_name;
  };

  // const createStatement = async () => {
  //   let statement = 'SELECT ';

  //   headerName.forEach((ele, idx) => {
  //     if (idx !== headerName.length - 1) {
  //       if (ele.alias) {
  //         statement += `${ele.header} as ${ele.alias} ,`;
  //       } else {
  //         statement += `${ele.header} ,`;
  //       }
  //     } else if (idx === headerName.length - 1) {
  //       if (ele.alias) {
  //         statement += `${ele.header} as ${ele.alias}`;
  //       } else {
  //         statement += `${ele.header}`;
  //       }
  //     }
  //   });

  //   const database_name = await getDatabaseName();
  //   statement += ` FROM ${database_name} `;

  //   statement += ' ORDER BY ';

  //   formField.statement = statement;
  //   formField.db_name = 'default';
  // };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    // await createStatement();
    if (fetchedHeader.filter((item) => item.col_type === 'target Col').length < 1 && formField.algorithm !== 'ALS') {
      enqueueSnackbar('At least 1 target Col type is required!!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '70%', marginLeft: '30%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{ color: '#00043C' }}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>

              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  onClick={refreshAllState}
                  aria-label="close"
                  sx={{ color: '#00043C' }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  disabled={disableForm}
                  className="button-color"
                  // onClick={handleFormsubmit}
                >
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <div style={{ marginBottom: '20px' }}>
              <InputField
                name="alias"
                label="Alias"
                value={formField.alias}
                onChange={handleInputChange}
                size="small"
                disabled={disableForm}
                required
              />
              &emsp;
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={formField.sample_view}
                    onChange={handleChecked}
                    disabled={disableForm}
                  />
                }
                label="Sample View"
                sx={{ p: 2 }}
              />
              <FormControl sx={{ minWidth: 500 }}>
                <InputField
                  name="path"
                  label="Model Path"
                  value={formField.path}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                  required
                />
              </FormControl>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-select-currency"
                  select
                  required
                  name="algo_type"
                  label="Algo Type"
                  size="small"
                  value={formField.algo_type}
                  onChange={handleInputChange}
                  disabled={disableForm}
                  style={{ width: '100%', marginTop: '15px' }}
                >
                  <MenuItem value="regression">Regression</MenuItem>
                  <MenuItem value="classification">Classification</MenuItem>
                  <MenuItem value="clustering">Clustering</MenuItem>
                  <MenuItem value="recommendation">Recommendation</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                {formField.algo_type === 'regression' && (
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="algorithm"
                    label="Algorithm"
                    size="small"
                    value={formField.algorithm}
                    onChange={handleInputChange}
                    disabled={disableForm}
                    style={{ width: '100%', marginTop: '15px' }}
                  >
                    <MenuItem value="LinearRegression">LinearRegression</MenuItem>
                    <MenuItem value="RandomForestRegressor">RandomForestRegressor</MenuItem>
                    <MenuItem value="XgBoost">XgBoost</MenuItem>
                  </TextField>
                )}
                {formField.algo_type === 'classification' && (
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="algorithm"
                    label="Algorithms"
                    size="small"
                    value={formField.algorithm}
                    onChange={handleInputChange}
                    disabled={disableForm}
                    style={{ width: '100%', marginTop: '15px' }}
                  >
                    <MenuItem value="LogisticRegression">LogisticRegression</MenuItem>
                    <MenuItem value="SVM">SVM</MenuItem>
                  </TextField>
                )}
                {formField.algo_type === 'clustering' && (
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="algorithm"
                    label="Algorithms"
                    size="small"
                    value={formField.algorithm}
                    onChange={handleInputChange}
                    disabled={disableForm}
                    style={{ width: '100%', marginTop: '15px' }}
                  >
                    <MenuItem value="KMeans">KMeans</MenuItem>
                    <MenuItem value="LDA">LDA</MenuItem>
                  </TextField>
                )}
                {formField.algo_type === 'recommendation' && (
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="algorithm"
                    label="Algorithms"
                    size="small"
                    value={formField.algorithm}
                    onChange={handleInputChange}
                    disabled={disableForm}
                    style={{ width: '100%', marginTop: '15px' }}
                  >
                    <MenuItem value="ALS">ALS</MenuItem>
                  </TextField>
                )}
              </Grid>
              {/* <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formField.sample_view}
                      onChange={handleChecked}
                      disabled={disableForm}
                    />
                  }
                  label="Sample View"
                  sx={{ p: 2 }}
                />
              </Grid> */}
            </Grid>
            <Grid container spacing={1}>
              {formField.algo_type === 'regression' && formField.algorithm === 'LinearRegression' && (
                <>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="maxIter"
                      label="maxIter"
                      value={formField.maxIter}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="regParam"
                      label="regParam"
                      value={formField.regParam}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="elasticNetParam"
                      label="elasticNetParam"
                      value={formField.elasticNetParam}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                </>
              )}
              {formField.algo_type === 'regression' && formField.algorithm === 'RandomForestRegressor' && (
                <>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="numTrees"
                      label="numTrees"
                      value={formField.numTrees}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="maxDepth"
                      label="maxDepth"
                      value={formField.maxDepth}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                </>
              )}
              {formField.algo_type === 'classification' && formField.algorithm === 'LogisticRegression' && (
                <>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="maxIter"
                      label="maxIter"
                      value={formField.maxIter}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="regParam"
                      label="regParam"
                      value={formField.regParam}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="elasticNetParam"
                      label="elasticNetParam"
                      value={formField.elasticNetParam}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                </>
              )}
              {formField.algo_type === 'classification' && formField.algorithm === 'SVM' && (
                <>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="maxIter"
                      label="maxIter"
                      value={formField.maxIter}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="regParam"
                      label="regParam"
                      value={formField.regParam}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                </>
              )}
              {formField.algo_type === 'clustering' && formField.algorithm === 'KMeans' && (
                <>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="k"
                      label="k"
                      value={formField.k}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="maxIter"
                      label="maxIter"
                      value={formField.maxIter}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                </>
              )}
              {formField.algo_type === 'clustering' && formField.algorithm === 'LDA' && (
                <>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="k"
                      label="k"
                      value={formField.k}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="maxIter"
                      label="maxIter"
                      value={formField.maxIter}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                </>
              )}
              {formField.algo_type === 'recommendation' && formField.algorithm === 'ALS' && (
                <>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="maxIter"
                      label="maxIter"
                      value={formField.maxIter}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="regParam"
                      label="regParam"
                      value={formField.regParam}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      required
                      name="rank"
                      label="rank"
                      value={formField.rank}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <div style={{ marginTop: '20px' }}>Split Ratio</div>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <InputField
                  required
                  name="train"
                  label="train"
                  value={formField.train}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                  error={errors.train}
                  helperText={errors.train}
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  required
                  name="test"
                  label="test"
                  value={formField.test}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                  error={errors.test}
                  helperText={errors.test}
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  required
                  name="seed"
                  label="seed"
                  value={formField.seed}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                />
              </Grid>
            </Grid>

            <TableContainer sx={{ maxHeight: 300 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        disabled={disableForm}
                        checked={fetchedHeader.length === headerName.length}
                        onChange={handleSelectAllClick}
                      />
                      Select Columns
                    </TableCell>
                    <TableCell>Columns</TableCell>
                    <TableCell>Datatype</TableCell>
                    <TableCell>Alias</TableCell>
                    <TableCell>Column Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchedHeader.map((row, i) => {
                    const isItemSelected = isSelected(row.header);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={i}
                        selected={isItemSelected}
                      >
                        <TableCell>
                          <Checkbox
                            checked={isItemSelected}
                            disabled={disableForm}
                            onChange={(event) =>
                              handleHeaderChange({
                                header: row.header,
                                alias: '',
                                checked: event.target.checked,
                                cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                default_value: row.default_value,
                                attribute_type: row.attribute_type,
                                col_type: row.col_type ? row.col_type : selected_cols,
                                // type: row.type ? row.type : 'string',
                                // deleted: row.deleted ? row.deleted : false,
                              })
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>

                        <TableCell>{row.header}</TableCell>
                        <TableCell>
                          <TextField
                            id="standard-select-currency"
                            select
                            label="Cast Datatype"
                            name="cast_datatype"
                            disabled={disableForm}
                            value={row.cast_datatype ? row.cast_datatype : 'string'}
                            onChange={(event) =>
                              handleHeaderChange({
                                header: row.header,
                                alias: row.alias,
                                checked: true,
                                cast_datatype: event.target.value,
                                default_value: row.default_value,
                                attribute_type: row.attribute_type,
                                col_type: row.col_type,
                              })
                            }
                            style={{ width: '100%' }}
                            size="small"
                          >
                            <MenuItem value="int">Integer</MenuItem>
                            <MenuItem value="string">String</MenuItem>
                            <MenuItem value="float">Float</MenuItem>
                            <MenuItem value="timestamp">Timestamp</MenuItem>
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="alias"
                            label="Alias"
                            value={row.alias}
                            disabled={disableForm}
                            onChange={(e) =>
                              handleHeaderChange({
                                header: row.header,
                                alias: e.target.value,
                                checked: true,
                                cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                default_value: row.default_value,
                                attribute_type: row.attribute_type,
                                col_type: row.col_type,
                              })
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            id="standard-select-currency"
                            select
                            label="Column Type"
                            name="col_type"
                            disabled={disableForm}
                            value={row.col_type ? row.col_type : selected_cols}
                            onChange={(event) =>
                              handleHeaderChange(
                                {
                                  header: row.header,
                                  alias: row.alias,
                                  checked: true,
                                  cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                  default_value: row.default_value,
                                  attribute_type: row.attribute_type,
                                  col_type: event.target.value,
                                },
                                'col'
                              )
                            }
                            style={{ width: '100%' }}
                            size="small"
                          >
                            {formField.algo_type === 'recommendation' && formField.algorithm === 'ALS'
                              ? recommendation_cols.map((item) => (
                                  <MenuItem key={item.label} value={item.value}>
                                    {item.value}
                                  </MenuItem>
                                ))
                              : algo_cols.map((item) => (
                                  <MenuItem key={item.label} value={item.value}>
                                    {item.value}
                                  </MenuItem>
                                ))}
                          </TextField>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeProperties_Training;
