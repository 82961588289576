import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { getAllClientsApi, getClientByUserGroup, getClientByUserGroupAndDomainIdApi } from "../api's/ClientApi";
import { getEngagementByUserGroupAndClientIdApi } from "../api's/EngagementApi";
import { createWorkflowApi, getWorkflowByUserGroupAndEngagementIdApi, copyWorkflowApi } from "../api's/WorkflowApi";
import { AuthContext } from '../contexts/AuthProvider';
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { cloudEnvironments } from '../constants/Clouds';
import LoadingIcon from './LoadingIcon';
import DraggableDropdowns from './DraggableDropdowns';
import { WorkflowContext } from '../contexts/WorkflowProvider';
import { getAllDomainsApi, getDomainByUserGroup } from "../api's/DomainApi";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 30,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  KeyboardEvent: false,
};

export default function AddWorkflowModal({
  handleModal,
  open,
  fetchTableData,
  processType,
  engagementId: propsEngagementId,
  engagementName,
  clientName,
  data,
}) {
  const { userRole, userGroup, userId } = useContext(AuthContext);
  // const { workflow: workflowData } = useContext(WorkflowContext);
  const headerDomain = data?.domain_name;
  const headerClient = data?.client_name;
  const headerEngagement = data?.engagement_name;

  // const nameRegex = /^\S{1,35}$/;
  const nameRegex = /^[a-zA-Z0-9 ]{1,35}$/;

  const [domain, setDomain] = useState('');
  const [client, setClient] = useState('');
  const [engagement, setEngagement] = useState('');
  const [workflow, setWorkflow] = useState('');
  const [executionMode, setExecutionMode] = useState('Batch');
  const [workflowNameError, setWorkflowNameError] = useState('');
  const [selectedDomainName, setSelectedDomainName] = useState('');
  const [selectedClientName, setSelectedClientName] = useState('');
  const [selectedEngagementName, setSelectedEngagementName] = useState('');
  const [selectedWorkflowName, setSelectedWorkflowName] = useState('');
  const [sourceWorkflowError, setSourceWorkflowError] = useState('');
  const [selectedprocessType, setSelectedprocessType] = useState('');
  const [selectClientError, setSelectClientError] = useState('');
  const [selectDomainError, setSelectDomainError] = useState('');
  const [selectEngagementError, setSelectEngagementError] = useState('');
  const [selectProcessTypeError, setSelectProcessTypeError] = useState('');
  const [domainTableData, setDomainTableData] = useState([]);
  const [clientTableData, setClientTableData] = useState([]);
  const [engagementTableData, setEngagementTableData] = useState([]);
  const [workflowTableData, setWorkflowTableData] = useState([]);
  const [isChecked, setIsChecked] = useState('');
  const [engagementId, setEngagementId] = useState('');
  const [cloudEnvironment, setCloudEnvironment] = useState(AWS_CONFIG_TYPE ? 'AWS' : 'AZURE');
  const { enqueueSnackbar } = useSnackbar();

  // COPY JOB STATES
  const [showCopyFields, setShowCopyFields] = useState(false);
  const [targetWorkflowNameError, setTargetWorkflowNameError] = useState('');
  const [targetWorkflowName, setTargetWorkflowName] = useState('');
  const [workflowId, setWorkflowId] = useState('');
  const [targetEngagementName, setTargetEngagementName] = useState('');
  const [targetClientName, setTargetClientName] = useState('');
  const [targetEngagementId, setTargetEngagementId] = useState('');
  const [targetClient, setTargetClient] = useState('');
  const [targetClientError, setTargetClientError] = useState('');
  const [targetEngagement, setTargetEngagement] = useState('');
  const [targetEngagementError, setTargetEngagementError] = useState('');
  const [targetEngagementTableData, setTargetEngagementTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDomainTableData = async (userGroup) => {
    setLoading(true);
    console.log({ userGroup });
    try {
      const response = userRole === 'SUPER_USER' ? await getAllDomainsApi() : await getDomainByUserGroup(userGroup);
      // const response = await getAllDomainsApi();

      if (response.status === 200) {
        response.data.sort((a, b) => {
          return a.domain_name.localeCompare(b.domain_name);
        });
        setDomainTableData(response.data);
        setDomain(headerDomain);
        setClient('');
        setEngagement('');
      }
    } catch (error) {
      console.log(error);
      setDomainTableData([]);

      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Domain not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const fetchClientTableData = async (group, domainId) => {
    try {
      // const response = userRole === 'SUPER_USER' ? await getAllClientsApi() : await getClientByUserGroup(userGroup);
      const response = await getClientByUserGroupAndDomainIdApi(group, domainId);
      if (response.status === 200) {
        setClientTableData(response.data);
        if (client === '') {
          setClient(headerClient);
          setEngagement('');
        } else {
          setClient('');
          setEngagement('');
        }
      }
    } catch (error) {
      if (error.response.status === 404) {
        enqueueSnackbar('No data found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const fetchEngagementTableData = async (group, clientId) => {
    const { data, status } = await getEngagementByUserGroupAndClientIdApi(group, clientId);
    try {
      if (status === 200) {
        setEngagementTableData(data);
        if (engagement === '') {
          setEngagement(headerEngagement);
        } else {
          setEngagement('');
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Engagement not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const fetchTargetEngagementTableData = async (group, clientId) => {
    const { data, status } = await getEngagementByUserGroupAndClientIdApi(group, clientId);
    try {
      if (status === 200) {
        setTargetEngagementTableData(data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Engagement not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const fetchWorkflowTableData = async (group, engagementId) => {
    try {
      const { data, status } = await getWorkflowByUserGroupAndEngagementIdApi(group, engagementId);

      if (status === 200) {
        setWorkflowTableData(data);
        if (version === '1.0') {
          const filterByVersion = data.filter(
            (item) => item.process_type === 'Data Ingestion' || item.process_type === 'Data Transformation'
          );
          setWorkflowTableData(filterByVersion);
        } else {
          setWorkflowTableData(data);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const createWorkflow = async (workflowData) => {
    try {
      const { data, status } = await createWorkflowApi(workflowData);

      if (status === 201) {
        const group = userGroup;
        enqueueSnackbar('Workflow added successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setWorkflow('');
        setClient('');
        setEngagement('');
        setSelectedprocessType('');
        setClient('');
        setEngagement('');
        setSelectedprocessType('');
        fetchTableData();
        handleModal();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 409) {
        enqueueSnackbar('Workflow name already exists!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  // COPY JOB API
  const copyWorkflow = async (workflowData) => {
    try {
      setLoading(true);

      const { data, status } = await copyWorkflowApi(workflowData);

      if (status === 201) {
        sessionStorage.removeItem('allNodes');

        sessionStorage.removeItem('node');

        sessionStorage.removeItem('elementCount');

        sessionStorage.removeItem('saved_node');

        sessionStorage.removeItem('edges');

        sessionStorage.removeItem('nodeStatus');
        const group = userGroup;

        enqueueSnackbar('Workflow Copied successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        enqueueSnackbar('URGENT: Update all Write Paths !', {
          variant: 'warning',
          autoHideDuration: 6000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        setWorkflow('');
        setClient('');
        setEngagement('');
        setSelectedprocessType('');
        setClient('');
        setEngagement('');
        setSelectedprocessType('');
        fetchTableData();
        handleModal();
      }
    } catch (error) {
      sessionStorage.removeItem('allNodes');

      sessionStorage.removeItem('node');

      sessionStorage.removeItem('elementCount');

      sessionStorage.removeItem('saved_node');

      sessionStorage.removeItem('edges');

      sessionStorage.removeItem('nodeStatus');
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 409) {
        enqueueSnackbar('Workflow alreay exist!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        enqueueSnackbar('URGENT: Update all Write Paths !', {
          variant: 'warning',
          autoHideDuration: 10000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    open && fetchDomainTableData(userGroup);
  }, [open]);

  useEffect(() => {
    const domainId = domainTableData
      .filter((domainTableData) =>
        domainTableData.client_name === selectedDomainName || domainTableData.domain_name === domain
          ? domainTableData.domain_id
          : null
      )
      .map((item) => {
        return item.domain_id;
      });

    if (domainId.length > 0) {
      fetchClientTableData(userGroup, domainId.join());
    }
  }, [selectedDomainName, domain]);

  useEffect(() => {
    const clientId = clientTableData
      .filter((clientTableData) =>
        clientTableData.client_name === selectedClientName || clientTableData.client_name === client
          ? clientTableData.client_id
          : null
      )
      .map((item) => {
        return item.client_id;
      });

    if (clientId.length > 0) {
      fetchEngagementTableData(userGroup, clientId.join());
    }
  }, [selectedClientName, client]);

  useEffect(() => {
    const clientId = clientTableData
      .filter((clientTableData) =>
        clientTableData.client_name === targetClientName || clientTableData.client_name === targetClient
          ? clientTableData.client_id
          : null
      )
      .map((item) => {
        return item.client_id;
      });

    if (clientId.length > 0) {
      fetchTargetEngagementTableData(userGroup, clientId.join());
    }
  }, [targetClientName, targetClient]);

  useEffect(() => {
    if (client) {
      const engagement_id = engagementTableData
        .filter(
          (engagementTableData) =>
            engagementTableData.engagement_name === selectedEngagementName ||
            engagementTableData.engagement_name === engagement
        )
        .map((item) => {
          return item.engagement_id;
        });
      if (engagement_id.length > 0) {
        fetchWorkflowTableData(userGroup, engagement_id.join());
      }
    }
  }, [selectedEngagementName, engagement]);

  // useEffect(() => {
  //   if(targetEngagement)
  //   {
  //   const engagement_id = targetEngagementTableData
  //     .filter((targetengagementTableData) =>
  //       targetengagementTableData.engagement_name === selectedEngagementName ||
  //       targetengagementTableData.engagement_name === targetEngagement
  //         ? engagementTableData.engagement_id
  //         : null
  //     )
  //     .map((item) => {
  //       return item.engagement_id;
  //     });
  //   if (engagement_id.length > 0) {
  //     fetchWorkflowTableData(userGroup, engagement_id.join());
  //   }
  // }
  // }, [selectedEngagementName, engagement]);

  // useEffect(() => {
  //   const engagement_id = engagementTableData
  //     .filter((engagementTableData) =>
  //       engagementTableData.engagement_name === targetEngagementName|| engagementTableData.engagement_name === targetEngagement ? engagementTableData.engagement_id : null
  //     )
  //     .map((item) => {
  //       return item.engagement_id;
  //     });
  //   if (engagement_id.length > 0) {
  //     fetchWorkflowTableData(userGroup, engagement_id.join());
  //   }
  // }, [selectedEngagementName,targetEngagement]);

  // useEffect(() => {
  //   setFilteredTableData(tableData.filter((tableData) => tableData.client_name.startsWith(search)));
  // }, []);

  const handleChecked = (event) => {
    setIsChecked(event.target.checked);
    setShowCopyFields(event.target.checked);
    if (!event.target.checked) {
      setSelectedDomainName('');
      setSelectedClientName('');
      setSelectedEngagementName('');
      setSelectedWorkflowName('');
      setSelectClientError('');
      setSelectEngagementError('');
      setSelectProcessTypeError('');
      setTargetClientName('');
      setTargetEngagementName('');
      setTargetWorkflowName('');
      setTargetClient('');
      setTargetEngagement('');
      setTargetClientError('');
      setTargetEngagementError('');
      setTargetWorkflowNameError('');
      setSourceWorkflowError('');
    }
  };

  const handleAddWorkflow = () => {
    console.log(domain);
    if (
      domain === '' ||
      domain === 'undefined' ||
      domain === null ||
      domain === undefined ||
      client === '' ||
      selectedprocessType === '' ||
      engagement === '' ||
      workflow === ''
    ) {
      if (domain === '' || domain === 'undefined' || domain === null || domain === undefined) {
        setSelectDomainError('Domain is required.');
      }
      if (client === '') {
        setSelectClientError('Client is required.');
      }
      if (engagement === '') {
        setSelectEngagementError('Engagement is required.');
      }
      if (selectedprocessType === '') {
        setSelectProcessTypeError('Process Type is required.');
      }

      if (workflow.trim() === '') {
        setWorkflowNameError('Workflow Name is required.');
      } else if (!workflow.match(nameRegex)) {
        setWorkflowNameError(`NO SPECIAL CHARACTER ALLOWED.`);
      }

      enqueueSnackbar('Please complete all required fields !', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });

      return;
    }

    const data = {
      workflow_name: workflow,
      connectionType: AWS_CONFIG_TYPE ? 'emr' : 'data bricks',
      connectionName: AWS_CONFIG_TYPE ? 'emr' : 'data bricks',
      execution_mode: executionMode,
      user_id: userId,
      process_type: selectedprocessType,
      cloudEnvironment,
      engagement_id: engagementId === '' ? propsEngagementId : engagementId,
    };
    createWorkflow(data);
  };

  // COPY JOB HANDLE
  const handleCopyWorkflow = () => {
    if (
      domain === '' ||
      client === '' ||
      engagement === '' ||
      targetClient === '' ||
      targetEngagement === '' ||
      targetWorkflowName === '' ||
      selectedWorkflowName === ''
    ) {
      if (domain === '') {
        setSelectDomainError('Domain is required.');
      }
      if (client === '') {
        setSelectClientError('Client is required.');
      }
      if (engagement === '') {
        setSelectEngagementError('Engagement is required.');
      }

      if (targetClient === '') {
        setTargetClientError('Target Client is required.');
      }
      if (targetEngagement === '') {
        setTargetEngagementError('Target Engagement is required.');
      }

      if (targetWorkflowName.trim() === '') {
        setTargetWorkflowNameError('Target Workflow Name is required.');
      } else if (!targetWorkflowName.match(nameRegex)) {
        setTargetWorkflowNameError(`NO SPECIAL CHARACTER ALLOWED.`);
      }

      if (selectedWorkflowName === '') {
        setSourceWorkflowError('Source Workflow Name is required.');
      }

      enqueueSnackbar('Please complete all required fields !', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });

      return;
    }

    const data = {
      workflow_name: selectedWorkflowName,
      execution_mode: executionMode,
      user_id: userId,
      cloudEnvironment,
      process_type: processType,
      preprocessor_Id: `${client}_${engagement}_${selectedWorkflowName}`,
      copy_from_engagement: engagementId === '' ? propsEngagementId : engagementId,
      copy_to_engagement: targetEngagementId,
      copy_from_workflow: workflowId,
      copy_to_workflow: targetWorkflowName,
      file_Name: `${selectedWorkflowName}.json`,
    };
    copyWorkflow(data);
  };

  const handleExecutionMode = (event) => {
    setExecutionMode(event.target.value);
  };

  const handleCancel = () => {
    setDomain('');
    setClient('');
    setEngagement('');
    setWorkflow('');
    setWorkflowNameError('');
    setSelectedDomainName('');
    setSelectedClientName('');
    setSelectedEngagementName('');
    setSelectedWorkflowName('');
    setSelectClientError('');
    setSelectDomainError('');
    setSelectEngagementError('');
    setSelectProcessTypeError('');

    setTargetClient('');
    setTargetEngagement('');
    setTargetWorkflowName('');
    setTargetClientError('');
    setTargetEngagementError('');
    setTargetWorkflowNameError('');
    setSourceWorkflowError('');

    if (isChecked === true) {
      setIsChecked(false);
    }
    handleModal();
  };

  const handleChange = (event, source) => {
    switch (source) {
      case 'cloud': {
        setCloudEnvironment(event.target.value);
        break;
      }
      case 'domain': {
        setDomain(event.target.value);
        // setClient('');
        // setEngagement('');
        if (event.target.value.trim() === '') {
          setSelectDomainError('Domain is required.');
        } else {
          setSelectDomainError('');
        }
        break;
      }
      case 'client': {
        setClient(event.target.value);
        // setEngagement('');
        if (event.target.value.trim() === '') {
          setSelectClientError('Client is required.');
        } else {
          setSelectClientError('');
        }
        break;
      }
      case 'engagement': {
        setEngagement(event.target.value);
        const engagement_id = engagementTableData
          .filter((engagementTableData) => engagementTableData.engagement_name === event.target.value)
          .map((item) => {
            return item.engagement_id;
          });
        setEngagementId(engagement_id.join());
        if (event.target.value.trim() === '') {
          setSelectEngagementError('Engagement is required.');
        } else {
          setSelectEngagementError('');
        }
        break;
      }
      case 'workflow': {
        const newValue = event.target.value;
        setWorkflow(newValue);

        if (newValue.trim() === '') {
          setWorkflowNameError('Workflow name is required.');
        } else if (!newValue.match(nameRegex)) {
          setWorkflowNameError(`NO SPECIAL CHARACTER ALLOWED.`);
        } else {
          setWorkflowNameError('');
        }
        break;
      }

      case 'sourceworkflow': {
        setSelectedWorkflowName(event.target.value);
        const workflow_id = workflowTableData
          .filter((workflowTableData) => workflowTableData.workflow_name === event.target.value)
          .map((item) => {
            return item.workflow_id;
          });
        setWorkflowId(workflow_id.join());
        if (event.target.value === '') {
          setSourceWorkflowError('Source Workflow is required.');
        } else {
          setSourceWorkflowError('');
        }
        break;
      }
      case 'targetclient': {
        setTargetClient(event.target.value);
        setTargetEngagementName('');
        // setSourceClientSelected(false);
        // setTargetClientSelected(true);
        setTargetEngagement('');
        if (event.target.value.trim() === '') {
          setTargetClientError('Target Client is required.');
        } else {
          setTargetClientError('');
        }
        break;
      }
      case 'targetengagement': {
        setTargetEngagement(event.target.value);
        const engagement_id = targetEngagementTableData
          .filter((targetengagementTableData) => targetengagementTableData.engagement_name === event.target.value)
          .map((item) => {
            return item.engagement_id;
          });
        setTargetEngagementId(engagement_id.join());
        if (event.target.value.trim() === '') {
          setTargetEngagementError('Target Engagement is required.');
        } else {
          setTargetEngagementError('');
        }
        break;
      }
      case 'targetworkflow': {
        const newValue = event.target.value;
        setTargetWorkflowName(event.target.value);
        if (newValue.trim() === '') {
          setTargetWorkflowNameError('Target Workflow Name is required.');
        } else if (!newValue.match(nameRegex)) {
          setTargetWorkflowNameError(`NO SPECIAL CHARACTER ALLOWED.`);
        } else {
          setTargetWorkflowNameError('');
        }
        break;
      }
      case 'processType': {
        setSelectedprocessType(event.target.value);
        if (event.target.value.trim() === '') {
          setSelectProcessTypeError('Process Type is required.');
        } else {
          setSelectProcessTypeError('');
        }
        break;
      }
      default:
        break;
    }
  };

  const version = JSON.parse(sessionStorage.getItem('version'));

  // copy job check function on copy existing workflow
  const handleCopyChecked = (event) => {
    setIsChecked(event.target.checked);
    setShowCopyFields(event.target.checked);
  };

  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box>
            <Typography variant="p" component="p" sx={{ fontWeight: '550', textTransform: 'uppercase' }}>
              WORKFLOW DETAILS
            </Typography>
          </Box>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={4}>
              <TextField
                id="combo-box-demo"
                label="Cloud"
                name="cloud"
                value={AWS_CONFIG_TYPE ? 'AWS' : 'AZURE'}
                InputProps={{
                  readOnly: true,
                }}
                size="small"
                disabled
                sx={{ backgroundColor: '#2E5BFF14', height: '40px', mt: 2, mb: 2 }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                display="flex"
                alignContent="flex-start"
                variant="p"
                component="p"
                sx={{ color: '#8798AD', fontSize: '11px' }}
              >
                Domain*
              </Typography>
              <FormControl sx={{ width: '100%' }} error>
                <Select
                  labelId="domain"
                  id="demo-simple-select-helper"
                  value={domain}
                  onChange={(e) => handleChange(e, 'domain')}
                  fullWidth
                  error={!!selectDomainError}
                  helperText={selectDomainError}
                  sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
                  required
                >
                  {domainTableData?.map((item) => {
                    if (item.status === 'approved') {
                      return (
                        <MenuItem key={item.domain_name} value={item.domain_name}>
                          {item.domain_name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                <FormHelperText>{selectDomainError}</FormHelperText>
              </FormControl>
            </Grid>

            {domain && (
              <Grid item xs={4}>
                <Typography
                  display="flex"
                  alignContent="flex-start"
                  variant="p"
                  component="p"
                  sx={{ color: '#8798AD', fontSize: '11px' }}
                >
                  Client*
                </Typography>
                <FormControl sx={{ width: '100%' }} error>
                  <Select
                    labelId="client"
                    id="demo-simple-select-helper"
                    value={client}
                    onChange={(e) => handleChange(e, 'client')}
                    fullWidth
                    error={!!selectClientError}
                    helperText={selectClientError}
                    sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
                    required
                  >
                    {clientTableData?.map((item) => {
                      if (item.status === 'approved') {
                        return (
                          <MenuItem key={item.client_name} value={item.client_name}>
                            {item.client_name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText>{selectClientError}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            {client && (
              <Grid item xs={4}>
                <Typography
                  display="flex"
                  alignContent="flex-start"
                  variant="p"
                  component="p"
                  sx={{ color: '#8798AD', fontSize: '11px' }}
                >
                  Engagement*
                </Typography>
                <FormControl sx={{ width: '100%' }} error>
                  <Select
                    labelId="engagement"
                    id="demo-simple-select-helper"
                    value={engagement}
                    onChange={(e) => handleChange(e, 'engagement')}
                    fullWidth
                    error={!!selectEngagementError}
                    helperText={selectEngagementError}
                    sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
                    required
                  >
                    {engagementTableData?.map((item) => {
                      if (item.status === 'approved') {
                        return (
                          <MenuItem key={item.engagement_id} value={item.engagement_name}>
                            {item.engagement_name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText>{selectEngagementError}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={4}>
              {!showCopyFields ? (
                <>
                  <Typography
                    variant="p"
                    component="p"
                    display="flex"
                    alignContent="flex-start"
                    sx={{ color: '#8798AD', fontSize: '11px' }}
                  >
                    Workflow*
                  </Typography>
                  <TextField
                    size="small"
                    required
                    id="workflow"
                    name="workflow"
                    autoComplete="workflow"
                    value={workflow}
                    error={!!workflowNameError}
                    helperText={workflowNameError}
                    sx={{ backgroundColor: '#2E5BFF14' }}
                    onChange={(e) => handleChange(e, 'workflow')}
                  />
                </>
              ) : (
                client &&
                engagement && (
                  <>
                    <Typography
                      display="flex"
                      alignContent="flex-start"
                      variant="p"
                      component="p"
                      sx={{ color: '#8798AD', fontSize: '11px', mt: '3' }}
                    >
                      Source WorkFlow*
                    </Typography>
                    <FormControl sx={{ width: '100%' }} error>
                      <Select
                        labelId="sourceworkflow"
                        id="demo-simple-select-helper"
                        value={selectedWorkflowName}
                        onChange={(e) => handleChange(e, 'sourceworkflow')}
                        fullWidth
                        error={!!sourceWorkflowError}
                        helperText={sourceWorkflowError}
                        sx={{ backgroundColor: '#2E5BFF14', height: '40px', mt: '3' }}
                        required
                      >
                        {workflowTableData?.map((item) => {
                          if (item.status === 'approved') {
                            return (
                              <MenuItem key={item.workflow_id} value={item.workflow_name}>
                                {item.workflow_name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                      <FormHelperText>{sourceWorkflowError}</FormHelperText>
                    </FormControl>
                  </>
                )
              )}
            </Grid>
            {!showCopyFields && (
              <Grid item xs={12}>
                <Typography
                  display="flex"
                  alignContent="flex-start"
                  variant="p"
                  component="p"
                  sx={{ color: '#8798AD', fontSize: '11px' }}
                >
                  Process Type*
                </Typography>
                <FormControl sx={{ width: '35%', marginBottom: '20px' }} error>
                  <Select
                    labelId="processType"
                    id="demo-simple-select-helper"
                    value={selectedprocessType}
                    onChange={(e) => handleChange(e, 'processType')}
                    fullWidth
                    error={!!selectProcessTypeError}
                    helperText={selectProcessTypeError}
                    sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
                    required
                  >
                    <MenuItem value="Data Copy">Data Copy</MenuItem>
                    <MenuItem value="Data Ingestion">Data Ingestion</MenuItem>
                    <MenuItem value="Data Transformation">Data Transformation</MenuItem>
                    <MenuItem value="Reporting">Reporting</MenuItem>
                    {version === '2.0' && cloudEnvironment !== 'SNOWFLAKE' && (
                      <MenuItem value="Data Quality">Data Quality</MenuItem>
                    )}{' '}
                    {version === '2.0' && cloudEnvironment !== 'SNOWFLAKE' && (
                      <MenuItem value="Modelling & Analytics">Modelling & Analytics</MenuItem>
                    )}{' '}
                    ;
                    {version === '2.0' && cloudEnvironment !== 'SNOWFLAKE' && (
                      <MenuItem value="XPerience360">XPerience360</MenuItem>
                    )}{' '}
                    ; ;
                    {version === '2.0' && cloudEnvironment !== 'SNOWFLAKE' && (
                      <MenuItem value="Customer Segmentation">Customer Segmentation</MenuItem>
                    )}
                    ;
                    {version === '2.0' && cloudEnvironment !== 'SNOWFLAKE' && (
                      <MenuItem value="openAI">openAI</MenuItem>
                    )}{' '}
                    ;
                  </Select>
                  <FormHelperText>{selectProcessTypeError}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6}>
              <FormGroup
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: 14,
                  },
                }}
              >
                <FormControlLabel
                  control={<Checkbox checked={isChecked} onChange={handleChecked} size="small" />}
                  label="Copy existing workflow"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={-2}>
            {isChecked ? (
              <Grid item xs={4}>
                <Typography
                  display="flex"
                  alignContent="flex-start"
                  variant="p"
                  component="p"
                  sx={{ color: '#8798AD', fontSize: '11px' }}
                >
                  Target Client*
                </Typography>
                <FormControl sx={{ width: '100%' }} error>
                  <Select
                    labelId="targetclient"
                    id="demo-simple-select-helper"
                    value={targetClient}
                    error={!!targetClientError}
                    helperText={targetClientError}
                    onChange={(e) => handleChange(e, 'targetclient')}
                    fullWidth
                    sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
                    required
                  >
                    {clientTableData?.map((item) => {
                      if (item.status === 'approved') {
                        return (
                          <MenuItem key={item.client_name} value={item.client_name}>
                            {item.client_name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText>{targetClientError}</FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
            {isChecked && targetClient ? (
              <Grid item xs={4}>
                <Typography
                  display="flex"
                  alignContent="flex-start"
                  variant="p"
                  component="p"
                  sx={{ color: '#8798AD', fontSize: '11px' }}
                >
                  Target Engagement*
                </Typography>
                <FormControl sx={{ width: '100%' }} error>
                  <Select
                    labelId="targetengagement"
                    id="demo-simple-select-helper"
                    value={targetEngagement}
                    onChange={(e) => handleChange(e, 'targetengagement')}
                    fullWidth
                    error={!!targetEngagementError}
                    helperText={targetEngagementError}
                    sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
                    required
                  >
                    {targetEngagementTableData?.map((item) => {
                      if (item.status === 'approved') {
                        return (
                          <MenuItem key={item.engagement_id} value={item.engagement_name}>
                            {item.engagement_name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText>{targetEngagementError}</FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
            {isChecked && targetClient && targetEngagement ? (
              <Grid item xs={4}>
                <>
                  <Typography
                    variant="p"
                    component="p"
                    display="flex"
                    alignContent="flex-start"
                    sx={{ color: '#8798AD', fontSize: '11px' }}
                  >
                    Target Workflow*
                  </Typography>
                  <TextField
                    size="small"
                    required
                    id="targetworkflowName"
                    name="targetworkflowName"
                    autoComplete="targetworkflowName"
                    value={targetWorkflowName}
                    error={!!targetWorkflowNameError}
                    helperText={targetWorkflowNameError}
                    sx={{ backgroundColor: '#2E5BFF14' }}
                    onChange={(e) => handleChange(e, 'targetworkflow')}
                  />
                </>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12} mt={2}>
            <Button
              size="small"
              type="submit"
              variant="contained"
              sx={{
                marginRight: '8px',
                backgroundColor: '#ffffff',
                '&:hover': { backgroundColor: '#ffffff' },
                color: '#00043C',
                border: '1px solid black',
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {!showCopyFields ? (
              <Button
                size="small"
                type="submit"
                variant="contained"
                sx={{
                  marginRight: '8px',
                  backgroundColor: '#00043C',
                  '&:hover': { backgroundColor: '#00043C' },
                  color: '#ffffff',
                }}
                onClick={handleAddWorkflow}
              >
                Add Workflow
              </Button>
            ) : (
              <Button
                size="small"
                type="submit"
                variant="contained"
                sx={{
                  marginRight: '8px',
                  backgroundColor: '#00043C',
                  '&:hover': { backgroundColor: '#00043C' },
                  color: '#ffffff',
                }}
                onClick={handleCopyWorkflow}
              >
                Copy Workflow
                {loading && <LoadingIcon />}
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
