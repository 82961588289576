import React, { useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import { AppBar, Box, Button, Stack, Tab, Typography, styled } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from '@mui/material/DialogTitle';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { AWS_CONFIG_TYPE } from '../BaseUrl';

import FileUpload from './FileUpload';
import { getListOfFileMetadataApi, uploadFileApi } from "../api's/BIApi";
import BasicTable from './BasicTable';
import { fetchHtmlJsonApi } from "../api's/DataProcessorApi";
import LoadingIcon from './LoadingIcon';
import InsightsFetchGraphs from './InsightsFetchGraphs';
import RetailDashboard from '../assets/images/retail_dashboard.jpg';

const FileDialog = ({ workflow, isFileDialogOpen, handleCloseFileDialog }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fileMetadataList, setFileMetadataList] = useState([]);
  const [filePath, setFilePath] = useState('');
  const [relativeFilePaths, setRelativeFilePaths] = React.useState([]);
  const [value, setValue] = React.useState('1');
  const [loading, setLoading] = useState(false);

  const INITIALSTATE = {
    alias: '',
    df: '',
    sample_view: false,
    persist: false,
    distinct_rows: false,
    persist_type: '',
    action: '',
    path: `https://depazuredev.blob.core.windows.net/dep-develop/${workflow.client_name}/${workflow.engagement_name}/${workflow.workflow_name}/MDM/CustomerSegmentation/CustomerSegmentation.json`,
  };

  const [formField, setFormField] = useState(INITIALSTATE);
  const [fetchedGraphs, setFetchedGraphs] = useState([]);

  useEffect(() => {
    fetchGraphs();
  }, []);

  const fetchGraphs = async () => {
    setLoading(true);
    const pathType = AWS_CONFIG_TYPE
      ? formField.path.split('s3://dep-qa/')[1]
      : formField.path.split('https://depazuredev.blob.core.windows.net/dep-develop/')[1];
    try {
      const response = await fetchHtmlJsonApi(pathType, AWS_CONFIG_TYPE, workflow.client_id);
      if (response.status === 200) {
        setFetchedGraphs(response.data);
      }
    } catch (error) {
      enqueueSnackbar("View doesn't exist! Internal Server Error", {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const filepath = `${workflow.client_name}/${workflow.engagement_name}/${workflow.workflow_name}/Data_Processor/BI`;
    const relativePaths = [];
    try {
      setLoading(true);
      const response = await getListOfFileMetadataApi(filepath, workflow.cloudEnvironment);
      setFileMetadataList(response.data);
      for (let i = 0; i < response.data.length; i++) {
        if (!response.data[i].folder) {
          const relativePath = `${filepath}/${response.data[i].filename}`;
          relativePaths.push(relativePath);
        }
      }
    } catch (error) {
      //   if (error.response.status === 404) {
      //     enqueueSnackbar('No Data !!', {
      //       variant: 'danger',
      //       autoHideDuration: 1000,
      //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
      //     });
      //   }
      console.error(error);
    }

    setFilePath(filepath);
    setRelativeFilePaths(relativePaths);
    setLoading(false);
  };

  let file = '';
  let fileName = '';
  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      file = e.target.files[0];
      fileName = e.target.files[0].name;
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', fileName);
    formData.append('file_path', filePath);
    formData.append('cloud_environment', workflow.cloudEnvironment);
    enqueueSnackbar('File upload in progress', {
      variant: 'warning',
      autoHideDuration: 1000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleCloseFileDialog();
    await uploadFileApi(formData)
      .then(() => {
        enqueueSnackbar('File uploaded', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      })
      .catch((error) => {
        enqueueSnackbar('File Uploading Fail !', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      });
  };

  const descriptionElementRef = React.useRef(null);

  return (
    <div>
      <Dialog
        open={isFileDialogOpen}
        onClose={handleCloseFileDialog}
        BackdropProps={{ invisible: true }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
        position="absolute"
      >
        <DialogTitle id="scroll-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography display="block" sx={{ fontWeight: 'bold', fontSize: 17 }}>
              INSIGHTS
            </Typography>
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFileDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          autoFocus
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="Insight Tabs">
                <Tab className="MuiTab-root MuiTab-textColorPrimary" label="Storage" value="1" />
                <Tab className="MuiTab-root MuiTab-textColorPrimary" label="View" value="2" />
                <Tab className="MuiTab-root MuiTab-textColorPrimary" label="Power BI" value="3" />
              </TabList>
            </Box>
            {/* <DialogContent dividers="paper">
              <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}> */}

            <TabPanel value="1">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1">Storage</Typography>

                <FileUpload handleFileChange={handleFileChange} handleFileUpload={handleFileUpload} />
              </div>
              {
                <BasicTable
                  relativeFilePaths={relativeFilePaths}
                  cloudEnvironment={workflow.cloudEnvironment}
                  fileMetadataList={fileMetadataList}
                  getData={getData}
                />
              }
              {loading && <LoadingIcon />}
            </TabPanel>

            {/* </DialogContentText>
            </DialogContent> */}
            <TabPanel value="2">
              <Typography variant="body1">View</Typography>
              <InsightsFetchGraphs fetchedGraphs={fetchedGraphs} />

              {loading && <LoadingIcon />}
            </TabPanel>
            <TabPanel value="3">
              <Typography variant="body1">Power BI</Typography>
              <img src={RetailDashboard} alt="Power BI" style={{ minWidth: '100%', height: 'auto' }} />
              {loading && <LoadingIcon />}
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
    </div>
  );
};

export default FileDialog;
