import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import { MdSchedule, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { VscDebugStart } from 'react-icons/vsc';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'; // Import the draggable handle icon
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { TableCell, Stack, Link, Button, Tooltip, Grid, TextField, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { GrUnorderedList } from 'react-icons/gr';
import { DateConverter } from '../../utils/DateConverter';
import GreenChip from '../../reusable-component/GreenChip';
import YellowChip from '../../reusable-component/YellowChip';
import RedChip from '../../reusable-component/RedChip';
import BlueChip from '../../reusable-component/BlueChip';
import BasicMenu from '../../reusable-component/BasicMenu';
import { getWorkflowById } from "../../api's/WorkflowApi";
import { getDataProcessor, getNodeStatus } from "../../api's/DataProcessorApi";
import { fetchExecutionListApi } from "../../api's/ExecutionApi";
import { AuthContext } from '../../contexts/AuthProvider';
import LoadingIcon from '../../reusable-component/LoadingIcon';

export default function WorkflowAccordionTable(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [workflowId, setWorkflowId] = useState('');
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { dropdowns, setDropdowns, fetchWorkflowTableData } = props;

  const [dragging, setDragging] = useState(false);
  const [allWorkflows, setAllWorkflows] = useState([]);
  const { userRole, userGroup } = useContext(AuthContext);

  useEffect(() => {
    if (props.data) {
      const newDropdowns = [...props.data];
      setDropdowns(newDropdowns);
    }
  }, [props.data]);

  const open = Boolean(anchorEl);

  const handleClick = (row, event) => {
    setAnchorEl(event.currentTarget);
    setWorkflowId(row.workflow_id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      background: 'none !important',
      color: '#8798AD',
      padding: '10px',
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
      textAlign: 'center',
      // width: '10%',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '15px',
      textAlign: 'center',
      // width: '10%',
      // '&:first-of-type': {
      //   maxWidth: '20vw',
      // },
    },
  }));

  const StyledTableRow = withStyles((theme) =>
    createStyles({
      root: {
        border: '1px solid white',
        background: '#FFFFFF',
        boxShadow: '0px 2px 6px #0000000A',
        borderRadius: '5px !important',
      },
    })
  )(TableRow);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    handleClose();
  };

  Row.propTypes = {
    row: PropTypes.shape({
      workflow_name: PropTypes.string.isRequired,
      created_by: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      execution_mode: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      executionInfoDto: PropTypes.arrayOf(
        PropTypes.shape({
          execution_engine: PropTypes.string.isRequired,
          last_executed_on: PropTypes.string.isRequired,
          execution_status: PropTypes.string.isRequired,
          executed_by: PropTypes.string.isRequired,
          created_by: PropTypes.string.isRequired,
          scheduled_for: PropTypes.string.isRequired,
          action: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  };

  const navigateToWorkflow = async (row) => {
    sessionStorage.removeItem('allNodes');
    sessionStorage.removeItem('node');
    sessionStorage.removeItem('elementCount');
    sessionStorage.removeItem('saved_node');
    sessionStorage.removeItem('edges');
    setLoading(true);
    const response = await getWorkflowById(row.workflow_id);

    let result, nodeStatus;
    try {
      setLoading(true);
      result = await getDataProcessor(row.workflow_id, row.execution_mode);
      nodeStatus = await getNodeStatus(
        response.data.engagement.client.client_name,
        response.data.engagement.engagement_name,
        response.data.workflow_name,
        response.data.cloudEnvironment
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }

    if (response.status === 200) {
      if (result) {
        let elemCount = 0;

        const newSavedElement = [];

        result.data.nodes.forEach((el) => {
          el['nodeId'] = `${el.nodeId}`;
          elemCount++;
          newSavedElement.push(el.id);
        });

        sessionStorage.setItem('allNodes', JSON.stringify(result.data.form_data));
        sessionStorage.setItem('elementCount', elemCount);
        sessionStorage.setItem('node', JSON.stringify(result.data.nodes));
        sessionStorage.setItem('edges', JSON.stringify(result.data.edges));
        sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
        nodeStatus && sessionStorage.setItem('nodeStatus', JSON.stringify(nodeStatus.data));
      }

      const type =
        row?.process_type === 'Data Quality'
          ? 'data-quality'
          : row?.process_type === 'Data Copy'
          ? 'data-copy'
          : row?.process_type === 'Data Transformation'
          ? 'data-transformation'
          : row?.process_type === 'Reporting'
          ? 'reporting'
          : row?.process_type === 'Data Ingestion'
          ? 'ingestion'
          : row?.process_type === 'Modelling & Analytics'
          ? 'modelling-analytics'
          : row?.process_type === 'XPerience360'
          ? 'xperience360'
          : row?.process_type === 'Customer Segmentation'
          ? 'customer-segmentation'
          : 'data';

      navigate(`/workflow-${type}/onboarding`, {
        state: {
          workflow_name: row.workflow_name,
          client_name: row.client_name,
          engagement_name: row.engagement_name,
          execution_mode: row.execution_mode,
          created_by: row.created_by,
          created_at: row.created_at,
          status: row.status,
          workflow_data: response.data,
        },
      });
    }
    setLoading(false);
  };

  function Row(props) {
    const { row, dropdown } = props;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [openData, setOpenData] = React.useState(false);
    const [executionRow, setExecutionRow] = useState([]);

    const type =
      row?.process_type === 'Data Quality'
        ? 'data-quality'
        : row?.process_type === 'Data Copy'
        ? 'data-copy'
        : row?.process_type === 'Data Transformation'
        ? 'data-transformation'
        : row?.process_type === 'Reporting'
        ? 'reporting'
        : row?.process_type === 'Data Ingestion'
        ? 'ingestion'
        : row?.process_type === 'Modelling & Analytics'
        ? 'modelling-analytics'
        : row?.process_type === 'XPerience360'
        ? 'xperience360'
        : row?.process_type === 'Customer Segmentation'
        ? 'customer-segmentation'
        : 'data';

    const handleRefreshWorkflow = async () => {
      try {
        const response = await fetchExecutionListApi(row.workflow_id);

        if (response.data === 200) {
          enqueueSnackbar('Status Refreshed!', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleViewLogs = (workflow_type) => {
      // navigate(`/workflow/logs`, { state: { executionRow } });
      navigate(`/workflow-${type}/logs/data-processor`, {
        state: {
          workflow_id: row.workflow_id,
          workflow_name: row.workflow_name,
          client_name: row.client_name,
          engagement_name: row.engagement_name,
          // execution_mode: row.execution_mode,
          created_by: row.created_by,
          created_at: row.created_at,
          status: row.status,
          workflow_type,
          cloudEnvironment: row.cloudEnvironment,
        },
      });
    };

    const toggleInChain = (dropdownId) => {
      setDropdowns((dropdowns) =>
        dropdowns.map((dropdown) =>
          dropdown.id === dropdownId ? { ...dropdown, inChain: !dropdown.inChain } : dropdown
        )
      );
    };

    return (
      <>
        {/* <DragIndicatorIcon style={{ color: 'grey', cursor: 'grab' }} /> */}

        <StyledTableRow
          sx={{ '& > *': { borderBottom: 'unset' }, flex: 1, display: 'flex', justifyContent: 'space-evenly' }}
        >
          <StyledTableCell sx={{ width: '5%' }} style={{ backgroundColor: '#f8f8ff', border: '1px solid #f8f8ff' }}>
            <div
              disabled={dragging}
              style={{
                position: 'relative',
                left: `${dropdown.x}px`,
                top: `${dropdown.y}px`,
              }}
            >
              <IconButton aria-label="expand row" size="small" onClick={() => toggleInChain(row.id)}>
                {row.inChain ? (
                  <DragIndicatorIcon style={{ color: 'limegreen', cursor: 'grab' }} />
                ) : (
                  <DragIndicatorIcon style={{ color: 'grey', cursor: 'grab', transform: 'rotate(90deg)' }} />
                )}
              </IconButton>
            </div>
          </StyledTableCell>
          <StyledTableCell sx={{ width: '5%' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpenData(!openData)}>
              {openData ? <MdKeyboardArrowUp size={15} /> : <MdKeyboardArrowDown size={15} />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="left" sx={{ width: '20%' }}>
            {row.status === 'approved' ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigateToWorkflow(row);
                }}
                sx={{ textDecoration: 'none', color: '#1976D2' }}
              >
                {row.workflow_name}
              </Link>
            ) : (
              row.workflow_name
            )}
          </StyledTableCell>
          <StyledTableCell sx={{ width: '10%' }} align="left">
            {row.execution_mode}
          </StyledTableCell>
          <StyledTableCell align="left" sx={{ width: '20%' }}>
            {row.created_by}
          </StyledTableCell>
          <StyledTableCell align="left" sx={{ width: '20%' }}>
            {DateConverter(row.created_at)}
          </StyledTableCell>
          <StyledTableCell sx={{ width: '10%' }}>
            {row.status === 'approved' ? (
              <GreenChip title="Approved" />
            ) : row.status === 'pending' ? (
              <YellowChip title="Pending" />
            ) : (
              <RedChip title="Rejected" />
            )}
          </StyledTableCell>
          <StyledTableCell sx={{ width: '10%' }}>
            <BasicMenu
              row={row}
              fetchData={fetchWorkflowTableData}
              type="Workflow"
              handleRefreshWorkflow={handleRefreshWorkflow}
            />
          </StyledTableCell>
        </StyledTableRow>

        {/* </div> */}
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' }, flex: 1, display: 'flex', flexDirection: 'column' }}>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={openData} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>WORKFLOW TYPE</StyledTableCell>
                    <StyledTableCell>EXECUTION ENGINE</StyledTableCell>
                    <StyledTableCell align="left">LAST EXECUTED ON</StyledTableCell>
                    <StyledTableCell align="left">EXECUTION STATUS</StyledTableCell>
                    <StyledTableCell align="left">EXECUTED BY</StyledTableCell>
                    <StyledTableCell align="left">CREATED BY</StyledTableCell>
                    <StyledTableCell align="left">SCHEDULED FOR</StyledTableCell>
                    <StyledTableCell align="left">ACTION</StyledTableCell>
                    <StyledTableCell align="left">Logs</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.executionInfoDto.length > 0
                    ? row.executionInfoDto.map((item) => (
                        <StyledTableRow key={item.execution_status}>
                          <StyledTableCell>{item.workflow_type}</StyledTableCell>
                          <StyledTableCell>{item.execution_engine || '--'}</StyledTableCell>
                          <StyledTableCell>{item.last_executed_on || '--'}</StyledTableCell>
                          <StyledTableCell>
                            {item.execution_status.toLowerCase() === 'completed' ? (
                              <GreenChip title="Completed" />
                            ) : item.execution_status.toLowerCase() === 'saved' ? (
                              <BlueChip title="Saved" />
                            ) : item.execution_status.toLowerCase() === 'running' ? (
                              <YellowChip title="Running" />
                            ) : (
                              <RedChip title="Rejected" />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">{item.executed_by || '--'}</StyledTableCell>
                          <StyledTableCell align="left">{item.created_by || '--'}</StyledTableCell>
                          <StyledTableCell align="left">{item.scheduled_for || '--'}</StyledTableCell>
                          {item.workflow_type !== 'Pre Processor' && (
                            <>
                              <StyledTableCell align="left">
                                <Stack direction="row" spacing={1}>
                                  <Tooltip title="Run">
                                    <Button sx={{ backgroundColor: 'white', border: 1, borderColor: 'black' }}>
                                      <VscDebugStart size={20} color="black" />
                                    </Button>
                                  </Tooltip>
                                  {/* <AddButton text="SCHEDULE" route="/client/onboarding" /> */}
                                  <Tooltip title="Schedule">
                                    <Button sx={{ backgroundColor: 'white', border: 1, borderColor: 'black' }}>
                                      <MdSchedule size={20} color="black" />
                                    </Button>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Tooltip title="Logs">
                                  <Button
                                    sx={{ backgroundColor: 'white', border: 1, borderColor: 'black' }}
                                    onClick={() => handleViewLogs(item.workflow_type)}
                                  >
                                    <GrUnorderedList size={20} color="black" />
                                  </Button>
                                </Tooltip>
                              </StyledTableCell>
                            </>
                          )}
                        </StyledTableRow>
                      ))
                    : 'No Data'}
                </TableBody>
              </Table>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  }

  // useEffect(() => {
  //   if (clientName && engagementName) {
  //     const str = `${clientName}/${engagementName}`;
  //     setFirstDropdown(str);
  //   }
  // }, []);

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', id.toString());
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetId) => {
    const sourceId = e.dataTransfer.getData('text/plain');
    const updatedDropdowns = [...dropdowns];
    const sourceIndex = dropdowns.findIndex((dropdown) => dropdown.id === +sourceId);
    const targetIndex = dropdowns.findIndex((dropdown) => dropdown.id === +targetId);

    updatedDropdowns.splice(targetIndex, 0, updatedDropdowns.splice(sourceIndex, 1)[0]);

    setDropdowns(updatedDropdowns);
    setDragging(false);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table" sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <TableHead>
            <TableRow sx={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <StyledTableCell sx={{ width: '5%' }}> </StyledTableCell>
              <StyledTableCell sx={{ width: '5%' }}> </StyledTableCell>
              <StyledTableCell sx={{ width: '20%' }}>Workflow Name</StyledTableCell>
              <StyledTableCell sx={{ width: '10%' }}>EXECUTION MODE</StyledTableCell>
              <StyledTableCell sx={{ width: '20%' }}>CREATED BY</StyledTableCell>
              <StyledTableCell sx={{ width: '20%' }}>CREATED ON</StyledTableCell>
              <StyledTableCell sx={{ width: '10%' }}>STATUS</StyledTableCell>
              <StyledTableCell sx={{ width: '10%' }}>ACTION</StyledTableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody>
            {props?.data
              // ?.slice((props.page - 1) * props.rowsPerPage, (props.page - 1) * props.rowsPerPage + props.rowsPerPage)
              .map((row) => (
                <Row key={row.name} row={row} />
              ))}
          </TableBody> */}
          <TableBody>
            {dropdowns &&
              dropdowns.map((dropdown, index) => (
                <div
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, dropdown.id)}
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, dropdown.id)}
                  style={{
                    // display: 'flex',
                    // alignItems: 'center',
                    textAlign: 'center',
                    borderWidth: '1px 0 1px 0',
                    borderColor: 'lightgrey',
                    borderStyle: 'solid',
                  }}
                >
                  <Row key={dropdown.id} row={dropdowns[index]} id={index} dropdown={dropdown} />
                </div>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingIcon />}
      {/* </TableBody> */}
    </>
  );
}
