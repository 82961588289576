import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const createDomainApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/domain/create-domain`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
      //   params: { domain_id },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const deleteDomainApi = async (domain_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.delete(`${BASEURL}/domain/delete-domain-by-id`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { domain_id },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllDomainsApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/domain/get-all-domains`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getDomainByUserGroup = async (user_group) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/domain/get-domain-by-userGroup`, {
      params: { user_group },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getPendingDomainApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/domain/get-pending-domain`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const approveDomainApi = async (domain_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/domain/approve-domain`, {
      params: { domain_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const rejectDomainApi = async (domain_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/domain/reject-domain`, {
      params: { domain_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
