import React from 'react';
import { Box, Grid, Paper, Typography, Stack } from '@mui/material';
import useStyles from './aboutPageStyles';

const AboutPage = () => {
  const classes = useStyles();

  return (
    <Box>
      <section style={{ background: '#13294b', color: '#fff' }}>
        <Grid container spacing={1} p={6}>
          <Grid item xs={10} mx={2}>
            <Typography variant="h2">BIG DATA ENGINEERING: Data Engineering Platform (DEP)</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center" my={6}>
            <Typography variant="subtitle1" className={classes.summary}>
              For business organizations to thrive in today's market and stay ahead of the competition, data alone isn't
              enough. To turn data into business insights, it is extremely critical to trust the data, consolidate it
              and analyze it for actionable insights.
              <br />
              However, data's sheer volume and complexity pose challenges in deriving tangible business value. This has
              led to a growing demand for Data Engineering Platforms that offer centralized and integrated solutions to
              collect, store, manage, and analyze large-scale data sets. By leveraging these platforms, organizations
              can analyze data swiftly and efficiently, enabling data-driven decision-making.
            </Typography>
          </Grid>
        </Grid>
      </section>
      <section style={{ background: '#3aab94', color: '#fff' }}>
        <Grid container spacing={1} p={8} justifyContent="flex-start" alignItems="center">
          <Grid item xs={8} paddingRight={2}>
            <Typography variant="h3">DEP: Data Engineering Platform by Nagarro</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Nagarro's Data Engineering Platform simplifies data management and enhances data quality through
              standardized intake, sharing, and storage.
            </Typography>
          </Grid>
        </Grid>
      </section>
      <Paper sx={{ px: 4 }} className={classes.root}>
        <section>
          <Grid container spacing={2} px={2} py={4}>
            <Grid item xs={4} my={1.5} px={2}>
              <Typography variant="h4" gutterBottom paddingBottom={3} className={classes.title}>
                ETL
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                It supports ETL processes like extracting data from disparate sources, cleansing it for consistency,
                duplicity, and quality, and consolidating the information into a data warehouse. It maximizes data value
                and brings in a higher level of integration, control, and data sharing internally and externally.
              </Typography>
            </Grid>
            <Grid item xs={4} my={1.5} px={2} className={classes.title}>
              <Typography variant="h4" gutterBottom paddingBottom={3}>
                Data Exchange
              </Typography>

              <Typography variant="subtitle2" className={classes.description}>
                It synchronizes multiple applications for more efficient data exchange. DEP runs code seamlessly across
                multiple platforms, allowing efficient execution and enables compatibility and scalability (utilizing
                features from other platforms).
              </Typography>
            </Grid>
            <Grid item xs={4} my={1.5}>
              <Typography variant="h4" gutterBottom paddingBottom={3} className={classes.title}>
                PAAS Integration
              </Typography>

              <Typography variant="subtitle2" className={classes.description}>
                It seamlessly integrates with various PAAS offerings, empowering users to use multiple services and
                resources and allows greater customization and optimization of data integration workflows.
              </Typography>
            </Grid>
            <Grid item xs={4} my={1.5}>
              <Typography variant="h4" gutterBottom paddingBottom={3} className={classes.title}>
                Low Code
              </Typography>

              <Typography variant="subtitle2" className={classes.description}>
                Being a low-code platform, it delivers rapid application development, increased productivity,
                accessibility for non-technical users, enhanced collaboration, scalability, and flexibility.
              </Typography>
            </Grid>
          </Grid>
        </section>
        <br />
        <br />
        <article>
          <em>
            <Typography variant="h6" component="h6" gutterBottom sx={{ color: '#43425d' }}>
              With Nagarro's Data Engineering Platform, enterprises can gain increased data security, integration,
              control, and more efficient data sharing possibilities, internally and externally. Let's help you get
              started!
            </Typography>
          </em>
        </article>
        <br />
        <br />
        <br />
        <section>
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            ml={1.5}
            display="flex"
            alignContent="center"
            justifyContent="center"
            className={classes.title}
          >
            Platform Benefits
          </Typography>
          <br />
          <Grid container spacing={2} p={4}>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Single click deployment
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Reduce development costs with continuous automated integration / continuous deployment pipelines.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Modernize data integrations
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Extend capabilities with your pre-extended workflows.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Low code environment
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Quickly build data integration pipelines with an intuitive visual that cuts development expenses.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Templating of workflows
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Save your workflows and edit them in different engagements. productivity, accessibility for
                non-technical users, enhanced collaboration, scalability, and flexibility.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Cost-effectiveness
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Scale on demand with fully managed and serverless cloud services.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Accelerated development and testing
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Improve your turnaround time with faster development and testing.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Cloud agnostic
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Migrate seamlessly between multiple cloud platforms without service disruption with architectural
                design.
              </Typography>
            </Grid>
          </Grid>
        </section>
        <br />
        <br />
        <br />
        <section>
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            ml={1.5}
            display="flex"
            alignContent="center"
            justifyContent="center"
            className={classes.title}
          >
            Platform Features
          </Typography>
          <br />
          <Grid container spacing={2} p={4}>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Data quality
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                In-built data quality framework to assess data quality issues and execute automatic improvements.{' '}
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Data ingestion and transformation
              </Typography>{' '}
              <Typography variant="subtitle2" className={classes.description}>
                Configuration-driven data ingestion and transformation pipeline-builder, which the business users can
                create.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Integration of data sources
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Seamless Integration of various data sources, from structured to unstructured data and batch to
                real-time data, into a single, standardized format.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Pre-processing and real-time processing
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Microservices for all pre-processing operations like PDF parsing, video transcript extraction, and
                unzipping.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Prebuilt connectors
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Use prebuilt connectivity to move data between multiple cloud sources and data warehouses, such as
                PostgreSQL and Snowflake.
              </Typography>
            </Grid>
            <Grid item xs={4} my={2}>
              <Typography variant="h6" gutterBottom className={classes.feature}>
                Cloud and resource provisioning
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Functions and mathematical expressions plan resources for cost savings and the most optimized cluster
                spawning.
              </Typography>
            </Grid>
          </Grid>
        </section>
      </Paper>
    </Box>
  );
};

export default AboutPage;
