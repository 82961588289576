import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  FormControl,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { DOMAIN_PAGE } from '../utils/commonConstants';
import { createDomainApi, getAllDomainsApi } from "../api's/DomainApi";
import { AuthContext } from '../contexts/AuthProvider';

export default function AddDomainModal({ handleCloseModal, openModal, fetchData }) {
  const { userId } = useContext(AuthContext);
  const [domainData, setDomainData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [domainName, setDomainName] = useState('');
  const [clientName, setClientName] = useState('');
  const [domainNameError, setDomainNameError] = useState('');

  // const nameRegex = /^\S{1,35}$/;
  const nameRegex = /^[a-zA-Z0-9 ]{1,35}$/;

  const handleChange = (event, source) => {
    switch (source) {
      case 'clientName': {
        const newValue = event.target.value;

        setClientName(newValue);

        break;
      }
      case 'domainName': {
        const newValue = event.target.value;

        setDomainName(newValue);
        if (newValue.trim() === '') {
          setDomainNameError('Domain Name is required.');
        } else if (!newValue.match(nameRegex)) {
          setDomainNameError(`Invalid Domain Name Format "${newValue}". NO SPECIAL CHARACTER ALLOWED.`);
        } else {
          setDomainNameError('');
        }

        break;
      }

      default:
        break;
    }
  };

  const handleOnClickAdd = () => {
    if (domainName.match(nameRegex)) {
      if (domainName) {
        createDomain();
      } else {
        enqueueSnackbar('Domain Name not selected.', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } else {
      enqueueSnackbar(`INVALID DOMAIN NAME FORMAT: ${domainName}.`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleOnCancel = () => {
    handleCloseModal();
    setClientName('');
    setDomainName('');
    setDomainNameError('');
  };

  const createDomain = async () => {
    // const selectedDomainId = domainData.find((item) => item.domain_name === domainName).domain_id;
    const data = {
      domain_name: domainName,
      user_id: userId,
    };
    console.log(data);
    try {
      const response = await createDomainApi(data);
      if (response.status === 201) {
        enqueueSnackbar('Domain added successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleOnCancel();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        const str = `domain name ${data.domain_name} is already exist`;

        enqueueSnackbar(
          str === error.response.data
            ? `Domain name ${data.domain_name} is already exist !!`
            : '500 Internal Server Error!',
          {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      } else if (error.response?.status === 409) {
        enqueueSnackbar('Domain with the same name already exists!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 403) {
        enqueueSnackbar('You Dont have access to perform this action!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const fetchAllDomains = async () => {
    const response = await getAllDomainsApi();
    try {
      if (response.status === 200) {
        setDomainData(response.data);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (openModal) {
      fetchAllDomains();
    }
  }, [openModal]);

  useEffect(() => {
    setDomainNameError('');
  }, []);

  return (
    <Dialog
      open={openModal}
      onClose={handleOnCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ color: '#43425D' }}> {DOMAIN_PAGE.addDomainModal}</DialogTitle>
      <DialogContent>
        <Box component="form" display="flex" justifyContent="space-between" gap="10px" alignItems="end" width="450px">
          <FormControl variant="standard" sx={{ minWidth: 220 }}>
            <Typography
              variant="p"
              component="p"
              display="flex"
              alignContent="flex-start"
              sx={{ color: '#8798AD', fontSize: '11px' }}
            >
              Domain Name*
            </Typography>
            <TextField
              size="small"
              required
              id="domainName"
              name="domainName"
              autoComplete="domainName"
              variant="outlined"
              value={domainName}
              error={!!domainNameError}
              helperText={domainNameError}
              onChange={(e) => handleChange(e, 'domainName')}
              sx={{ background: '#2E5BFF14', mt: 0.5 }}
            />
          </FormControl>
          {/* <FormControl sx={{ minWidth: 220 }}>
            <Typography
              variant="p"
              component="p"
              display="flex"
              alignContent="flex-start"
              sx={{ color: '#8798AD', fontSize: '11px' }}
            >
              Client*
            </Typography>
            <Select
              size="small"
              labelId="clientName"
              id="clientName"
              value={clientName}
              onChange={(e) => handleChange(e, 'clientName')}
              input={<OutlinedInput />}
              sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
            >
              {clientData.map((item) => (
                <MenuItem key={item.client_id} value={item.client_name}>
                  {item.client_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 2 }}>
        <Button
          onClick={handleOnCancel}
          variant="outlined"
          sx={{ textTransform: 'capitalize', color: '#43425D', border: '1px solid #43425D' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOnClickAdd}
          variant="contained"
          sx={{ backgroundColor: '#00043C', textTransform: 'capitalize', '&:hover': { backgroundColor: '#00043C' } }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
