import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Col, Row } from 'antd';
import {
  getDashboardCount,
  getTop10ClientsApi,
  getTop10DomainsApi,
  getTop10EngagementsApi,
  getTop10WorkflowsApi,
} from "../api's/DashboardApi";
import DashboardCard from '../components/dashboard-component/DashboardCard';
import ClientTable from '../components/dashboard-component/ClientTable';
import DomainTable from '../components/dashboard-component/DomainTable';
import EngagementTable from '../components/dashboard-component/EngagementTable';
import WorkflowTable from '../components/dashboard-component/WorkflowTable';
import '../components/dashboard-component/dashboard.css';
import LoadingIcon from '../reusable-component/LoadingIcon';
import DashboardCards from '../components/dashboard-component/DashboardCards';

const DashboardPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  // const [ cardLoading, setCardLoading ] = useState(false);
  const [clientCount, setClientCount] = useState();
  const [domainCount, setDomainCount] = useState(1);
  const [engagementCount, setEngagementCount] = useState();
  const [workflowCount, setWorkflowCount] = useState();
  const [tableType, setTableType] = useState('Domains');
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const fetchDashboardCount = async () => {
    try {
      const response = await getDashboardCount();
      if (response.status === 200) {
        response.data.forEach((el) => {
          if (el.type === 'Clients') {
            setClientCount(el);
          } else if (el.type === 'Domains') {
            setDomainCount(el);
          } else if (el.type === 'Engagements') {
            setEngagementCount(el);
          } else {
            setWorkflowCount(el);
          }
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to get count!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  useEffect(() => {
    fetchDashboardCount();
  }, []);

  const version = JSON.parse(sessionStorage.getItem('version'));

  const fetchTableData = async () => {
    setLoading(true);
    try {
      let response;

      if (tableType === 'Clients') {
        response = await getTop10ClientsApi();
        response.data.sort((a, b) => {
          return a.client_name.localeCompare(b.client_name);
        });
      } else if (tableType === 'Domains') {
        response = await getTop10DomainsApi();
        response.data.sort((a, b) => {
          return a.domain_name.localeCompare(b.domain_name);
        });
      } else if (tableType === 'Engagements') {
        response = await getTop10EngagementsApi();
        response.data.sort((a, b) => {
          return a.engagement_name.localeCompare(b.engagement_name);
        });
      } else {
        response = await getTop10WorkflowsApi();
        response.data.sort((a, b) => {
          return a.workflow_name.localeCompare(b.workflow_name);
        });
      }

      if (response.status === 200) {
        if (version === '1.0' && tableType === 'Workflows') {
          const filterByVersion = response.data.filter(
            (item) => item.process_type === 'Data Ingestion' || item.process_type === 'Data Transformation'
          );
          setTableData(filterByVersion);
        } else {
          setTableData(response.data);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404 && tableType === 'Clients') {
        enqueueSnackbar('404 Client not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404 && tableType === 'Domains') {
        enqueueSnackbar('404 Domain not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404 && tableType === 'Engagements') {
        enqueueSnackbar('404 Engagement not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404 && tableType === 'Workflows') {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setTableData([]);
    fetchTableData();
  }, [tableType]);

  return (
    <>
      <div className="heading">Dashboard</div>
      {/* <div className="dashboard-card">
        {domainCount && <DashboardCard setTableType={setTableType} tableType={tableType} data={domainCount} />}
        {clientCount && <DashboardCard setTableType={setTableType} tableType={tableType} data={clientCount} />}
        {engagementCount && <DashboardCard setTableType={setTableType} tableType={tableType} data={engagementCount} />}
        {workflowCount && <DashboardCard setTableType={setTableType} tableType={tableType} data={workflowCount} />}
      </div> */}
      {/* {!loading && ( */}
      <div className="dashboard-cards">
        <Row gutter={16}>
          <Col className="gutter-row" span={6} xs={24} sm={24} md={12} lg={12} xl={6}>
            {domainCount && <DashboardCard setTableType={setTableType} tableType={tableType} data={domainCount} />}
          </Col>
          <Col className="gutter-row" span={6} xs={24} sm={24} md={12} lg={12} xl={6}>
            {clientCount && <DashboardCard setTableType={setTableType} tableType={tableType} data={clientCount} />}
          </Col>
          <Col className="gutter-row" span={6} xs={24} sm={24} md={12} lg={12} xl={6}>
            {engagementCount && (
              <DashboardCard setTableType={setTableType} tableType={tableType} data={engagementCount} />
            )}
          </Col>
          <Col className="gutter-row" span={6} xs={24} sm={24} md={12} lg={12} xl={6}>
            {workflowCount && <DashboardCard setTableType={setTableType} tableType={tableType} data={workflowCount} />}
          </Col>
        </Row>
      </div>
      {/* )} */}
      <div className="heading">
        {tableType}
        {tableType === 'Clients' && (
          <Link to={'/client'} style={{ fontSize: '12px', textDecoration: 'underline' }}>
            View All
          </Link>
        )}
        {tableType === 'Domains' && (
          <Link to={'/domain'} style={{ fontSize: '12px', textDecoration: 'underline' }}>
            View All
          </Link>
        )}
        {tableType === 'Engagements' && (
          <Link to={'/engagement'} style={{ fontSize: '12px', textDecoration: 'underline' }}>
            View All
          </Link>
        )}
        {tableType === 'Workflows' && (
          <Link to={'/workflow-ingestion'} style={{ fontSize: '12px', textDecoration: 'underline' }}>
            View All
          </Link>
        )}
      </div>

      {tableType === 'Clients' && (
        <ClientTable tableData={tableData} fetchTableData={fetchTableData} currentPage={page} setPage={setPage} />
      )}
      {tableType === 'Domains' && (
        <DomainTable tableData={tableData} fetchTableData={fetchTableData} currentPage={page} setPage={setPage} />
      )}

      {tableType === 'Engagements' && <EngagementTable tableData={tableData} fetchTableData={fetchTableData} />}
      {tableType === 'Workflows' && <WorkflowTable tableData={tableData} fetchTableData={fetchTableData} />}
      {loading && <LoadingIcon />}
    </>
  );
};

export default DashboardPage;
