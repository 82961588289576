import React, { useState, useEffect, memo } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Typography,
  Toolbar,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  ButtonGroup,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  Select,
  FormControl,
  Modal,
  Tooltip,
  Box,
  Autocomplete,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { MdDelete } from 'react-icons/md';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import InputField from '../../../reusable-component/InputField';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: '2px solid rgba(0, 0, 0, .125)',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
  action: '',
  persist: false,
  alias: '',
  persist_type: '',
  db_name: '',
  statement: '',
  distinct_rows: false,
  sample_view: true,
};

const NodeProperties_Filter = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);
  const [headers, setHeaders] = useState([]);

  const [attributes, setAttributes] = useState([]);
  const [filterCol, setFilterCol] = useState([]);
  const [addClause, setAddClause] = useState([]);
  const [openColapse, setOpenColapse] = useState(false);

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getSourceData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];
      const filterArray = uniqueArray.map((el) => ({
        ...el,
        header: el.alias ? el.alias : el.header,
        alias: el.alias ? '' : el.alias,
        filter: '',
      }));

      filterArray.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);

      setFetchedHeader(filterArray);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setFilterCol(node.sorting);
          setAttributes(node.attributes);
          setAddClause(node.addClause);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    setFilterCol([]);
    setAttributes([]);
    setAddClause([]);
    getSourceData();
  }, [nodeId]);

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];

    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);
      setFilterCol([]);
      setAttributes([]);
      setAddClause([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }

      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const filterArray = uniqueArray.map((el) => ({
        ...el,
        header: el.alias ? el.alias : el.header,
        alias: el.alias ? '' : el.alias,
        filter: '',
      }));

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setHeaderName(node.headerName);

            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = filterArray.findIndex((x) => x.header === item.header);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            filterArray.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.header === el.header);

              if (index === -1) {
                nonExistingHeader.push({
                  ...el,
                  header: el.alias ? el.alias : el.header,
                  alias: el.alias ? '' : el.alias,
                  filter: '',
                });
              }
            });

            const head = [];

            filterArray.forEach((el) => {
              head.push({ label: el.header });
            });

            setHeaders(head);

            setFetchedHeader(nonExistingHeader);
            setFilterCol(node.sorting);
            setAttributes(node.attributes);
            setAddClause(node.addClause);
          }
        });
      } else {
        setFetchedHeader(filterArray);
      }
    }
    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const handleFilterChange = (obj, idx) => {
    const data = [...filterCol];

    data.map((el, index) => {
      if (index === idx) {
        el.header = obj.header;
        el.oper = obj.oper;
        el.filter = obj.filter;
        el.clause = obj.clause;
        el.condition = obj.condition;
      }
    });

    setFilterCol(data);
  };

  const addFields = (column_name) => {
    const newfield = { header: '', oper: 'is', filter: '', clause: 'AND', condition: column_name };

    setFilterCol([...filterCol, newfield]);
  };

  const deleteFields = (i) => {
    const deleteValue = [...filterCol];
    deleteValue.splice(i, 1);
    setFilterCol(deleteValue);
  };

  const addAttributes = () => {
    let last_index = 0;
    if (attributes.length !== 0) {
      last_index = parseInt(attributes[attributes.length - 1].split('Condition_Group_')[1], 10);
    }
    setAddClause([...addClause, { condition: `Condition_Group_${last_index + 1}`, clause: 'AND' }]);

    setAttributes([...attributes, `Condition_Group_${last_index + 1}`]);
  };

  const deleteAttributes = (i) => {
    const deleteClause = [...addClause];
    deleteClause.splice(i, 1);
    setAddClause(deleteClause);

    const deleteAttributes = [...attributes];
    deleteAttributes.splice(i, 1);
    setAttributes(deleteAttributes);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const getDatabaseName = async () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    const sourceData = [];
    if (store && findSrcNodeId) {
      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            sourceData.push(node.formField.alias);
          }
        });
      });
    }
    let database_name = '';

    sourceData.forEach((ele, id) => {
      if (id !== sourceData.length - 1) {
        database_name += `${ele} ,`;
      } else {
        database_name += `${ele} `;
      }
    });

    return database_name;
  };

  const createStatement = async () => {
    const tempFilterCol = [...filterCol];
    const tempAddClause = [...addClause];

    tempFilterCol.sort((a, b) => a.condition - b.condition);

    tempAddClause.map((item, i) => {
      let count = 0;
      tempFilterCol.map((ele) => {
        if (ele.condition === item.condition) {
          count++;
        }
      });
      item['count'] = count;
      return item;
    });

    setAddClause(tempAddClause);

    let statement = 'SELECT ';

    fetchedHeader.forEach((ele, idx) => {
      if (idx !== fetchedHeader.length - 1) {
        if (ele.alias) {
          statement += `${ele.header} as ${ele.alias} ,`;
        } else {
          statement += `${ele.header} ,`;
        }
      } else if (idx === fetchedHeader.length - 1) {
        if (ele.alias) {
          statement += `${ele.header} as ${ele.alias}`;
        } else {
          statement += `${ele.header}`;
        }
      }
    });

    const database_name = await getDatabaseName();
    statement += ` FROM ${database_name} WHERE `;

    addClause.forEach((item, i) => {
      statement += `(`;
      let innerCount = 0;
      tempFilterCol.forEach((ele) => {
        if (ele.condition === item.condition) {
          if (innerCount < item.count - 1) {
            statement += `${ele.header} ${ele.oper} ${ele.filter} ${ele.clause} `;
          } else {
            statement += `${ele.header} ${ele.oper} ${ele.filter}) `;
          }
          innerCount++;
        }
      });

      if (i < addClause.length - 1) {
        statement += `${item.clause} `;
      }
    });

    formField.statement = statement;
    formField.db_name = 'default';
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) =>
    first_array_of_objects.length === second_array_of_objects.length &&
    first_array_of_objects.every((element_1) =>
      second_array_of_objects.some(
        (element_2) =>
          element_1.header === element_2.header &&
          element_1.tableAlias === element_2.tableAlias &&
          element_1.alias === element_2.alias &&
          element_1.checked === element_2.checked
      )
    );

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    await createStatement();
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    fetchedHeader.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      sorting: filterCol,
      attributes,
      addClause,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const changeColapse = (panel) => (event, isExpanded) => {
    setOpenColapse(isExpanded ? panel : false);
  };

  const addNextClause = (obj) => {
    const data = [...addClause];

    data.map((el) => {
      if (obj.condition === el.condition) {
        el.condition = obj.condition;
        el.clause = obj.clause;
      }
    });

    setAddClause(data);
  };

  const getClause = (condition) => {
    const data = addClause;
    let clause = 'AND';
    data.map((el) => {
      if (condition === el.condition) {
        clause = el.clause;
      }
    });

    return clause;
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '70%', marginLeft: '30%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  onClick={refreshAllState}
                  aria-label="close"
                  sx={{ color: '#00043C' }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>
          <div style={{ padding: '20px 30px' }}>
            <div style={{ marginBottom: '20px' }}>
              <InputField
                name="alias"
                label="Alias"
                value={formField.alias}
                onChange={handleInputChange}
                size="small"
                disabled={disableForm}
                required
              />
              &emsp;
              <TextField
                id="outlined-select-currency"
                select
                label="Distinct Rows"
                size="small"
                value={formField.distinct_rows}
                onChange={(event) => {
                  setFormField({
                    ...formField,
                    distinct_rows: event.target.value,
                  });
                }}
                disabled={disableForm}
                style={{ width: '30%', marginTop: '15px' }}
              >
                <MenuItem value="true">true</MenuItem>
                <MenuItem value="false">false</MenuItem>
              </TextField>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={formField.sample_view}
                    onChange={handleChecked}
                    disabled={disableForm}
                  />
                }
                label="Sample View"
                sx={{ pl: 2, pt: 2 }}
              />
            </div>

            {attributes.map((column_name, index) => (
              <Accordion expanded={openColapse === column_name} onChange={changeColapse(column_name)} key={index}>
                <AccordionSummary
                  expandIcon={<ChevronRightIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '96%', flexShrink: 0 }}>{column_name}</Typography>
                  <IconButton aria-label="expand row" size="small" onClick={() => deleteAttributes(index)}>
                    <MdDelete />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ margin: 1 }}>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Headers</TableCell>
                          <TableCell>Operator</TableCell>
                          <TableCell>Filter Condition</TableCell>
                          <TableCell>Clause</TableCell>
                          <TableCell>Delete Condition</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filterCol.map((row, i) => {
                          if (row.condition === column_name) {
                            return (
                              <TableRow hover key={i}>
                                <TableCell>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={row.header}
                                    disabled={disableForm}
                                    onChange={(event, newValue) =>
                                      handleFilterChange(
                                        {
                                          header: newValue.label,
                                          filter: row.filter,
                                          oper: row.oper,
                                          clause: row.clause,
                                          condition: row.condition,
                                        },
                                        i
                                      )
                                    }
                                    options={headers}
                                    sx={{ width: 200 }}
                                    size="small"
                                    renderInput={(params) => <TextField {...params} label="Attributes" />}
                                  />
                                </TableCell>
                                <TableCell style={{ display: 'flex' }}>
                                  <FormControl>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={row.oper}
                                      label=""
                                      size="small"
                                      disabled={disableForm}
                                      style={{ width: '60px' }}
                                      onChange={(e) =>
                                        handleFilterChange(
                                          {
                                            header: row.header,
                                            filter: row.filter,
                                            oper: e.target.value,
                                            clause: row.clause,
                                            condition: row.condition,
                                          },
                                          i
                                        )
                                      }
                                    >
                                      <MenuItem value="is">is</MenuItem>
                                      <MenuItem value="not in">not in</MenuItem>
                                      <MenuItem value="in">in</MenuItem>
                                      <MenuItem value="between">between</MenuItem>
                                      <MenuItem value=">">&#62;</MenuItem>
                                      <MenuItem value=">=">&ge;</MenuItem>
                                      <MenuItem value="<">&#60;</MenuItem>
                                      <MenuItem value="<=">&le;</MenuItem>
                                      <MenuItem value="=">&#9868;</MenuItem>
                                      <MenuItem value="!=">&ne;</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    name="filter"
                                    label="Filter Condition"
                                    multiline
                                    value={row.filter}
                                    style={{ width: '200px' }}
                                    onChange={(e) => {
                                      handleFilterChange(
                                        {
                                          header: row.header,
                                          filter: e.target.value,
                                          oper: row.oper,
                                          clause: row.clause,
                                          condition: row.condition,
                                        },
                                        i
                                      );
                                    }}
                                    size="small"
                                    disabled={disableForm}
                                  />
                                </TableCell>

                                <TableCell style={{ display: 'flex' }}>
                                  <FormControl>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={row.clause}
                                      label=""
                                      size="small"
                                      disabled={disableForm}
                                      style={{ width: '80px' }}
                                      onChange={(e) =>
                                        handleFilterChange(
                                          {
                                            header: row.header,
                                            filter: row.filter,
                                            oper: row.oper,
                                            clause: e.target.value,
                                            condition: row.condition,
                                          },
                                          i
                                        )
                                      }
                                    >
                                      <MenuItem value="AND">AND</MenuItem>
                                      <MenuItem value="OR">OR</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <IconButton aria-label="expand row" size="small" onClick={() => deleteFields(i)}>
                                    <MdDelete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                    </Table>
                    <Box
                      style={{
                        margin: '30px 0',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        disabled={disableForm}
                        variant="outlined"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          addFields(column_name);
                        }}
                      >
                        Add Condition
                      </Button>
                      <FormControl style={{ marginTop: '20px' }}>
                        <InputLabel id="demo-simple-select-label">Next Clause</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={getClause(column_name)}
                          label="Next Clause"
                          size="small"
                          disabled={disableForm}
                          style={{ width: '200px' }}
                          onChange={(e) =>
                            addNextClause({
                              condition: column_name,
                              clause: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="AND">AND</MenuItem>
                          <MenuItem value="OR">OR</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}

            <Box
              style={{
                margin: '20px 0',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <Box style={{ margin: '30px 0', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Button
                  disabled={disableForm}
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={addAttributes}
                >
                  Add Condition Group
                </Button>
              </Box>
            </Box>
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default memo(NodeProperties_Filter);
