import React, { memo, useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Box,
  IconButton,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Checkbox,
  Grid,
  FormControlLabel,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DownloadIcon from '@mui/icons-material/Download';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import AWS from 'aws-sdk';
import FileUploader from './FileUploader';
import InputField from '../../../../reusable-component/InputField';
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';
import {
  downloadLargeFileApi,
  getListOfLargeFileMetadataApi,
  largefileUploaderApi,
} from "../../../../api's/LargeFileUploaderApi";
import HtmlContent from '../../../../reusable-component/HtmlContent';
import { dataConvertor } from '../../../../utils/dataConvertor';
import { AWS_CONFIG_TYPE } from '../../../../BaseUrl';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
  alias: '',
  path: '',
  sample_view: true,
};

const NodeModalFileUploader = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [disableForm, setDisableForm] = useState(false);
  const [file, setFile] = useState(null);
  const [headerName, setHeaderName] = useState([]);
  const [fileName, setFileName] = useState('');
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [formField, setFormField] = useState(INITIALSTATE);
  const { workflow, isDisabled, setIsDisabled } = useContext(WorkflowContext);
  const [ReadFilePath, setReadFilePath] = useState('');
  const [relativeFilePaths, setRelativeFilePaths] = useState([]);
  const [fileMetadataList, setFileMetadataList] = useState([]);
  const [uploadSuccessIndicator, setUploadSuccessIndicator] = useState(true);

  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [isDataLoad, setisDataLoad] = useState(false);
  const [tempHeader, setTempHeader] = useState([]);

  const [isFileSelected, setIsFileSelected] = React.useState(true);

  const handleSwitchChange = () => {
    setIsFileSelected(!isFileSelected);
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    if (store) {
      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setisDataLoad(true);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };
  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };
  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              header: obj.header,
              alias: obj.alias,
              checked: true,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: false,
              alias: '',
              cast_datatype: 'string',
              attribute_type: 'static',
              default_value: '',
            };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          alias: n.alias,
          checked: true,
          cast_datatype: 'string',
          attribute_type: 'static',
          default_value: '',
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    getLocalData();
  }, [nodeId]);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState(null);

  useEffect(() => {
    getData();
  }, [uploadSuccessIndicator]);

  const getData = async () => {
    const filepath = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/input`;
    const relativePaths = [];
    try {
      const response = await getListOfLargeFileMetadataApi(filepath, workflow.cloudEnvironment);
      setFileMetadataList(response.data);
      for (let i = 0; i < response.data.length; i++) {
        if (!response.data[i].folder) {
          const relativePath = `${filepath}/${response.data[i].filename}`;
          relativePaths.push(relativePath);
        }
      }
    } catch (error) {
      if (error.response.status === 404) {
        enqueueSnackbar('No Data !!', {
          variant: 'danger',
          autoHideDuration: 1000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }

    setReadFilePath(filepath);
    setRelativeFilePaths(relativePaths);
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleFormsubmit = (e) => {
    e.preventDefault();
    if (AWS_CONFIG_TYPE !== true) {
      enqueueSnackbar('Upload Functionality is not implemented in Azure !!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        el.data.label = formField.alias;
      }
    });

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleResetForm = () => {
    setDisableForm(false);
    setFormField(INITIALSTATE);

    setFetchedHeader([]);
    setHeaderName([]);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
  };

  const handleFileUploadChange = (event) => {
    event.preventDefault();
    setFiles(event.target.files);
    setIsUploadDisabled(false);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsUploadDisabled(true);
    // S3 Bucket Name
    const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;

    // S3 Region
    const REGION = process.env.REACT_APP_REGION;

    // const s3 = new AWS.S3();

    try {
      // S3 Credentials

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
        sessionToken: process.env.REACT_APP_SESSION_TOKEN,
      });

      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      enqueueSnackbar('Uploading Started...', {
        variant: 'warning',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      Array.from(files).forEach(async (file) => {
        const params = {
          Bucket: `${S3_BUCKET}`,
          Key: isFileSelected ? `${ReadFilePath}/${file.name}` : `${ReadFilePath}/${file.webkitRelativePath}`,
        };
        if (!isFileSelected) {
          const directory = file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf('/') + 1);
          const createParams = {
            Bucket: S3_BUCKET,
            Key: `${ReadFilePath}/${directory}`,
          };
          try {
            const data = await s3
              .listObjectsV2({ Bucket: S3_BUCKET, Prefix: `${ReadFilePath}/${directory}` })
              .promise();

            if (data.Contents.length > 0) {
              console.log('Directory exists.');
            } else {
              console.log('Directory does not exist.');
              // create directories
              await s3.putObject(createParams).promise();
            }
          } catch (error) {
            console.error('Error checking directory existence:', error);
          }
        }

        // Initiate the multipart upload
        const multipartUpload = await s3
          .createMultipartUpload(params)
          .promise()
          .catch(() => {
            enqueueSnackbar('File Uploading Failed', {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            setIsUploadDisabled(false);
            setProgress(0);
          });

        // Calculate the part size (e.g., 50MB)
        const partSize = 50 * 1024 * 1024;

        // Calculate the number of parts
        const numParts = Math.ceil(file.size / partSize);
        const uploadedParts = [];
        let totalUploaded = 0;

        /* eslint-disable no-await-in-loop */
        for (let partNumber = 1; partNumber <= numParts; partNumber++) {
          const start = (partNumber - 1) * partSize;
          const end = Math.min(start + partSize, file.size);

          const partParams = {
            Bucket: `${S3_BUCKET}`,
            Key: isFileSelected ? `${ReadFilePath}/${file.name}` : `${ReadFilePath}/${file.webkitRelativePath}`,
            PartNumber: partNumber,
            UploadId: multipartUpload.UploadId,
            Body: file.slice(start, end),
          };

          const uploadPart = await s3
            .uploadPart(partParams)
            .promise()
            .catch(() => {
              enqueueSnackbar('File Uploading Failed', {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              });
              setIsUploadDisabled(false);
              setProgress(0);
            });
          uploadedParts.push({ PartNumber: partNumber, ETag: uploadPart.ETag });
          // setProgress((partNumber / numParts) * 100);

          // Update the totalUploaded bytes
          totalUploaded += partSize;

          // Calculate progress based on totalUploaded and file size
          const progress = (totalUploaded / file.size) * 100;
          setProgress(progress);
        }

        // Complete the multipart upload
        await s3
          .completeMultipartUpload({
            Bucket: `${S3_BUCKET}`,
            Key: isFileSelected ? `${ReadFilePath}/${file.name}` : `${ReadFilePath}/${file.webkitRelativePath}`,
            UploadId: multipartUpload.UploadId,
            MultipartUpload: {
              Parts: uploadedParts,
            },
          })
          .promise()
          .then(() => {
            setIsUploadDisabled(false);
            setUploadSuccessIndicator(!uploadSuccessIndicator);
            setFiles(null);
          })
          .catch(() => {
            enqueueSnackbar('File Uploading Failed', {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            setIsUploadDisabled(false);
            setProgress(0);
          });

        enqueueSnackbar('File Uploaded Successfully', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setProgress(0);
      });
    } catch (error) {
      enqueueSnackbar('File Uploading Failed', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setIsUploadDisabled(false);
      setProgress(0);
    }
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  const downloadFile = async (relativeFilePath, cloudEnvironment) => {
    enqueueSnackbar('File Downloading...', {
      variant: 'warning',
      autoHideDuration: 1000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });

    await downloadLargeFileApi(relativeFilePath, cloudEnvironment);

    enqueueSnackbar('File Downloaded', {
      variant: 'success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition} style={{ width: '70%', marginLeft: '30%' }}>
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <input type="hidden" value={nodeId} />
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl sx={{ minWidth: 270 }}>
                  <InputField
                    name="alias"
                    label="Alias"
                    value={formField.alias}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formField.sample_view}
                      onChange={handleChecked}
                      disabled={disableForm}
                    />
                  }
                  label="Sample View"
                  sx={{ pt: 2 }}
                />
              </Grid>
            </Grid>

            <Box
              component="form"
              sx={{
                '& > :not(style)': { my: 2 },
              }}
              noValidate
              autoComplete="off"
            >
              <FileUploader
                handleFileUploadChange={handleFileUploadChange}
                handleUpload={handleUpload}
                disableForm={disableForm}
                isUploadDisabled={isUploadDisabled}
                handleSwitchChange={handleSwitchChange}
                isFileSelected={isFileSelected}
                files={files}
              />
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl
                  sx={{
                    width: '100%',
                  }}
                >
                  <TextField
                    id="filepath"
                    name="path"
                    label="Full Path"
                    value={ReadFilePath}
                    onChange={handleInputChange}
                    size="small"
                    disabled
                    required
                    // error={errors.filepath}
                    // helperText={errors.filepath}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                {progress > 0 && progress < 100 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">
                        Uploading...
                      </Typography>
                    </Box>
                  </Box>
                )}
                {/* <BasicTable
                  relativeFilePaths={relativeFilePaths}
                  cloudEnvironment={workflow.cloudEnvironment}
                  fileMetadataList={fileMetadataList}
                /> */}

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bolder' }}>Files</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                          Size
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                          Download
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {relativeFilePaths &&
                        relativeFilePaths.map(
                          (relativeFilePath, i) =>
                            !fileMetadataList[i].folder && (
                              <TableRow
                                key={relativeFilePath}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {fileMetadataList && fileMetadataList[i].filename}
                                </TableCell>
                                <TableCell align="center">
                                  {fileMetadataList && dataConvertor(fileMetadataList[i].filesize)}
                                </TableCell>
                                <TableCell align="center">
                                  <DownloadIcon
                                    style={{ color: '#00043c', cursor: 'pointer' }}
                                    onClick={() => downloadFile(relativeFilePath, workflow.cloudEnvironment)}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default memo(NodeModalFileUploader);
