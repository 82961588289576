import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, CircularProgress, FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { uploadFileForChatGPTApi } from "../../api's/ChatGPTApi";

export default function FileUpload({
  handleCloseModal,
  type,
  metadata,
  language,
  clientNameTTD,
  engagementNameTTD,
  workflowNameTTD,
}) {
  const [selectedFile, setSelectedFile] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const [language, setLanguage] = useState('English');

  // const handleLanguageChange = (event) => {
  //   setLanguage(event.target.value);
  // };

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedFile.length === 0) {
      enqueueSnackbar(`Please upload a file`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('language', language);
    formData.append('client', clientNameTTD);
    formData.append('engagement', engagementNameTTD);
    formData.append('workflow', workflowNameTTD);
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append('file', selectedFile[i]);
    }
    try {
      const response = await uploadFileForChatGPTApi(formData);

      if (response.status === 200) {
        enqueueSnackbar(`Files uploaded successfully`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        navigate('/chat-gpt');
      }
      handleCloseModal();
    } catch (error) {
      setLoading(false);
      if (error.response.status === 409) {
        enqueueSnackbar(`This file already exist!`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar(`Invalid file`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      // Handle the error, such as showing an error message
    }
    setLoading(false);
    setSelectedFile([]);
  };

  const handleCsvUpload = async (event) => {
    event.preventDefault();
    if (selectedFile.length === 0) {
      enqueueSnackbar(`Please upload a file`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }
    setLoading(true);
    const data = new FormData();
    data.append('file', selectedFile);
    for (let i = 0; i < selectedFile.length; i++) {
      data.append('file', selectedFile[i]);
    }
    data.append('aws_config_type', metadata.aws_config_type);
    data.append('client_id', metadata.client_id);
    data.append('cloud_path', metadata.cloud_path);
    data.append('delimiter', ',');
    data.append('engagement_name', metadata.engagement_name);
    data.append('execution_mode', metadata.execution_mode);
    data.append('file_type', 'csv');
    data.append('filename', selectedFile[0].name);
    data.append('path', '');
    data.append('workflow_id', metadata.workflow_id);
    data.append('workflow_name', metadata.workflow_name);
    data.append('user_id', metadata.user_id);
    console.log(data);

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'http://localhost:5000/api/rule-engine/create-rule-engine-by-file-upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(`Files uploaded successfully`, {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Invalid file`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        // Handle the error, such as showing an error message
      });
    setLoading(false);
    handleCloseModal();
  };
  return (
    <form onSubmit={type === 'ruleEngine' ? handleCsvUpload : handleSubmit}>
      {/* <Box>
        <label>Select a file to upload:</label>
        <br />
        <input type="file" multiple onChange={handleFileInputChange} />
      </Box> */}
      <Box>
        <Button
          variant="contained"
          component="label"
          sx={{
            backgroundColor: '#00043C',
            '&:hover': { backgroundColor: '#00043C' },
            // mr: 4, mt: 0.25
          }}
        >
          <input type="file" multiple onChange={handleFileInputChange} />
        </Button>
        {/* <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label" style={{ backgroundColor: 'white' }}>
            Language
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label="Language"
            size="small"
            onChange={handleLanguageChange}
            sx={{ maxWidth: '100px', mr: 2 }}
          >
            <MenuItem value={'English'}>English</MenuItem>
            <MenuItem value={'Arabic'}>Arabic</MenuItem>
          </Select>
        </FormControl> */}
      </Box>
      {/* <button type="submit">Upload</button> */}
      {!loading ? (
        <>
          <Button
            type="submit"
            sx={{
              marginTop: '20px',
              backgroundColor: '#00043C',
              color: '#ffffff',
              '&:hover': { backgroundColor: '#00043C' },
            }}
          >
            Upload
          </Button>
        </>
      ) : (
        <LoadingButton loading variant="outlined" sx={{ marginTop: '20px' }}>
          Submit
        </LoadingButton>
      )}
    </form>
  );
}
