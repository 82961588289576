export const EACH_INSTRUMENT = {
  data: [
    {
      fieldName: 'Raw Sample ID',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Editable Sample ID',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'ConditionID',

      dataType: 'Text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Editable ConditionID',

      dataType: 'Text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Editable Condition',

      dataType: 'Text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Prefix',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Experiment',

      dataType: 'Integer',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Infection day',

      dataType: 'date',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Infection day (editable)',

      dataType: 'date',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Passage number',

      dataType: 'Integer',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Passage number (editable)',

      dataType: 'Integer',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Revision number',

      dataType: 'Integer',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Revision number (editable)',

      dataType: 'Integer',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Infection flag',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Infection flag (editable)',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Vessel id',

      dataType: 'Integer',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Vessel type',

      dataType: 'Integer',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Dilution factor',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'skip entry',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'rundate',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'infection hour',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'program',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'seed train',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'comments',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'viability%',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'total cells e6 cells ml',

      dataType: 'decimal',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'average diameter nm',

      dataType: 'decimal',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'vaible  cells e6 cells ml',

      dataType: 'decimal',

      editable: 'N',

      default_field_value: '',
    },

    {
      fieldName: 'Experiment Day',

      dataType: 'text',

      editable: 'N',

      default_field_value: '',
    },
  ],
};
