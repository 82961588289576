import React, { useState, useRef, useCallback, useMemo, useEffect, useContext, forwardRef } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  updateEdge,
  applyNodeChanges,
  applyEdgeChanges,
  ControlButton,
} from 'reactflow';
import { MdLabel, MdLabelOff } from 'react-icons/md';
import { Switch } from 'antd';
import { Paper, Fab, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import 'reactflow/dist/style.css';
import './flowbuilderpannel.css';
import NodesSection from './NodesSection';
// import FlowBuilderTop from '../FlowBuilderTop';
import CustomNode from './CustomNode';
import ButtonEdge from './ButtonEdge';
import NodeModalRead from './flow-builder-form/NodeModal_Read';
import NodeModalReadCSV from './flow-builder-form/read/NodeModalReadCSV';
import NodeModalReadPDF from './flow-builder-form/read/NodeModalReadPDF';
import NodeModalCopyDoc from './flow-builder-form/read/NodeModalCopyDoc';
import NodeModalReadDelimited from './flow-builder-form/read/NodeModalReadDelimited';
import NodeModalReadMySql from './flow-builder-form/read/NodeModalReadMySql';
import NodeModalReadOracle from './flow-builder-form/read/NodeModalReadOracle';
import NodeModalReadPostgres from './flow-builder-form/read/NodeModalReadPostgres';
import NodeModalReadAPI from './flow-builder-form/read/NodeModal_API';
import NodeModalReadSnowflake from './flow-builder-form/read/NodeModalReadSnowflake';
import NodeModalKPI from './flow-builder-form/NodeModal_KPI';
import NodeModalSnowFlake from './flow-builder-form/write/NodeModal_SnowFlake';
import NodeModalReadCosmos from './flow-builder-form/read/NodeModalReadCosmos';
import NodeModalReadRealTime from './flow-builder-form/NodeModal_ReadRealTime';
import NodeModalJoin from './flow-builder-form/NodeModal_Join';
import NodeModalWrite from './flow-builder-form/write/NodeModal_Write';
import NodeModalWriteToBenchling from './flow-builder-form/write/NodeModal_WriteToBenchling';
import NodeModalUpdateToBenchling from './flow-builder-form/write/NodeModal_UpdateToBenchling';
import NodeModalIngestionWrite from './flow-builder-form/write/NodeModal_IngestionWrite';
import NodeModalDatabase from './flow-builder-form/NodeModal_Database';
import NodeModalOpenSearch from './flow-builder-form/write/NodeModal_OpenSearch';
import NodeModalWriteSFTP from './flow-builder-form/NodeModal_WriteSFTP';
import NodeModalC360 from './flow-builder-form/NodeModal_C360';
import NodeModalCoalease from './flow-builder-form/NodeModal_Coalease';
import NodeModalVICELL from './flow-builder-form/Vicell/NodeModal_ViCell';
import NodeModalExecuteSql from './flow-builder-form/NodeModal_ExecuteSQL';
import NodeModalUdf from './flow-builder-form/NodeModal_Udf';
import NodeModalEmail from './flow-builder-form/NodeModal_Email';
import NodeModalDataCleansing from './flow-builder-form/NodeModal_DataCleansing';
import NodePropertiesSort from './flow-builder-form/NodeProperties_Sort';
import NodePropertiesFilter from './flow-builder-form/NodeProperties_Filter';
import NodeModalUnion from './flow-builder-form/NodeModal_Union';
import NodeModalAggregate from './flow-builder-form/NodeModal_Aggregate';
import NodeModalDatatypeConv from './flow-builder-form/NodeModal_DatatypeConv';
import NodeModalConcat from './flow-builder-form/NodeModal_Concat';
import NodeModalDateTime from './flow-builder-form/NodeModal_DateTime';
import NodeModalHandleNull from './flow-builder-form/NodeModal_HandleNull';
import NodePropertiesTraining from './flow-builder-form/NodeProperties_Training';
import NodeModalMathTransformation from './flow-builder-form/NodeModal_MathTransformation';
import NodeModalReadMDM from './flow-builder-form/read/NodeModalReadMDM';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import { createDataProcessorApi } from "../../api's/DataProcessorApi";
import NodePropertiesPrediction from './flow-builder-form/NodeProperties_Prediction';
import NodePropertiesBinning from './flow-builder-form/NodeProperties_Binning';
import NodeModalSource from './flow-builder-form/NodeModal_Source';
import NodeModalProfiling from './flow-builder-form/NodeModal_Profiling';
import NodeModalDataValidation from './flow-builder-form/NodeModal_DataValidation';
import NodeModalOneHotEncoder from './flow-builder-form/NodeModal_OneHotEncoder';
import NodeModalLabelEncoder from './flow-builder-form/NodeModal_LabelEncoder';
import DataLineageGraph from './data-lineage/DataLineageGraph';
import NodeModalOutlier from './flow-builder-form/NodeModal_Outlier';
import NodeModalDrop from './flow-builder-form/NodeModal_Drop';
import NodeModalImbalancedData from './flow-builder-form/NodeModal_ImbalancedData';
import NodeModalScaler from './flow-builder-form/NodeModal_Scaler';
import NodeModalResample from './flow-builder-form/NodeModal_Resample';
import NodeModalReadStreaming from './flow-builder-form/read/NodeModalReadStreaming';
import NodeModalFuzzyMatch from './flow-builder-form/NodeModalFuzzyMatch';
import NodeModalRuleEngine from './flow-builder-form/NodeModalRuleEngine';
import NodeModalManualMerge from './flow-builder-form/NodeModalManualMerge';
import NodeModalCustomerSegmentation from './flow-builder-form/NodeModalCustomerSegmentation';
import NodeModalAnalysis from './flow-builder-form/NodeModalAnalysis';
import NodeModalPlot from './flow-builder-form/NodeModalPlot';
import { AuthContext } from '../../contexts/AuthProvider';
import NodeModalReadPublishedData from './flow-builder-form/read/NodeModalReadPublishedData';
import NodeModalFileUploader from './flow-builder-form/read/NodeModalFileUploader';
import { saveGraphPlotData, saveReportsData } from "../../api's/ReportingApi";
import generateAlias from '../../utils/generateAlias';
import NodeModalColumnLevelMetadataIngestion from './flow-builder-form/write/NodeModal_ColumnLevelMetadataIngestion';
import NodeModalTableLevelMetadataIngestion from './flow-builder-form/write/NodeModal_TableLevelMetadataIngestion';
import NodeModalGenerateTranscript from './flow-builder-form/write/NodeModal_GenerateTranscript';
import NodeModalEmbedding from './flow-builder-form/write/NodeModal_Embedding';
import NodeModalReadFromFolder from './flow-builder-form/read/NodeModalReadFromFolder';
import NodeModalInstrument from './flow-builder-form/instrument/NodeModalInstrument';
import convertToRequiredFormat from './JsonConverter';

const edgeTypes = {
  buttonedge: ButtonEdge,
};

const getId = () => `dndnode_${+new Date()}`;

const ReactFlowPannel = forwardRef((props, ref) => {
  const [isLabelVisible, setIsLabelVisible] = useState(sessionStorage.getItem('isLabelVisible') === 'true');
  const nodeTypes = useMemo(() => ({ editableNode: CustomNode }), [isLabelVisible]);
  const { userId } = useContext(AuthContext);
  const { workflow, isDisabled, setIsDisabled } = useContext(WorkflowContext);
  const { enqueueSnackbar } = useSnackbar();
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [nodeName, setNodeName] = useState('');
  const [nodeId, setNodeId] = useState('');

  const [sortOpen, setSortOpen] = useState(false);
  const handleSortOpen = () => setSortOpen(true);
  const handleSortClose = () => setSortOpen(false);

  const [filterOpen, setFilterOpen] = useState(false);
  const handleFilterOpen = () => setFilterOpen(true);
  const handleFilterClose = () => setFilterOpen(false);

  const [unionOpen, setUnionOpen] = useState(false);
  const handleUnionOpen = () => setUnionOpen(true);
  const handleUnionClose = () => setUnionOpen(false);

  const [joinOpen, setJoinOpen] = useState(false);
  const handleJoinOpen = () => setJoinOpen(true);
  const handleJoinClose = () => setJoinOpen(false);

  const [readOpen, setReadOpen] = useState(false);
  const handleReadOpen = () => setReadOpen(true);
  const handleReadClose = () => setReadOpen(false);

  const [readCsvOpen, setReadCsvOpen] = useState(false);
  const handleReadCsvOpen = () => setReadCsvOpen(true);
  const handleReadCsvClose = () => setReadCsvOpen(false);

  const [instrumentOpen, setInstrumentOpen] = useState(false);
  const handleInstrumentOpen = () => setInstrumentOpen(true);
  const handleInstrumentClose = () => setInstrumentOpen(false);

  const [readPdfOpen, setReadPdfOpen] = useState(false);
  const handleReadPdfOpen = () => setReadPdfOpen(true);
  const handleReadPdfClose = () => setReadPdfOpen(false);

  const [copyDocOpen, setCopyDocOpen] = useState(false);
  const handleCopyDocOpen = () => setCopyDocOpen(true);
  const handleCopyDocClose = () => setCopyDocOpen(false);

  const [readPublishedDataOpen, setReadPublishedDataOpen] = useState(false);
  const handleReadPublishedDataOpen = () => setReadPublishedDataOpen(true);
  const handleReadPublishedDataClose = () => setReadPublishedDataOpen(false);

  const [FileUploaderOpen, setFileUploaderOpen] = useState(false);
  const handleFileUploaderOpen = () => setFileUploaderOpen(true);
  const handleFileUploaderClose = () => setFileUploaderOpen(false);

  const [readStreamingOpen, setReadStreamingOpen] = useState(false);
  const handleReadStreamingOpen = () => setReadStreamingOpen(true);
  const handleReadStreamingClose = () => setReadStreamingOpen(false);

  const [readDelimitedOpen, setReadDelimitedOpen] = useState(false);
  const handleReadDelimitedOpen = () => setReadDelimitedOpen(true);
  const handleReadDelimitedClose = () => setReadDelimitedOpen(false);

  const [readOraclepen, setReadOracleOpen] = useState(false);
  const handleReadOracleOpen = () => setReadOracleOpen(true);
  const handleReadOracleClose = () => setReadOracleOpen(false);

  const [readPostgresOpen, setReadPostgresOpen] = useState(false);
  const handleReadPostgresOpen = () => setReadPostgresOpen(true);
  const handleReadPostgresClose = () => setReadPostgresOpen(false);

  const [readCosmosOpen, setReadCosmosOpen] = useState(false);
  const handleReadCosmosOpen = () => setReadCosmosOpen(true);
  const handleReadCosmosClose = () => setReadCosmosOpen(false);

  const [readApiOpen, setReadApiOpen] = useState(false);
  const handleReadApiOpen = () => setReadApiOpen(true);
  const handleReadApiClose = () => setReadApiOpen(false);

  const [readMySqlOpen, setReadMySqlOpen] = useState(false);
  const handleReadMySqlOpen = () => setReadMySqlOpen(true);
  const handleReadMySqlClose = () => setReadMySqlOpen(false);

  const [KPIOpen, setKPIOpen] = useState(false);
  const handleKPIOpen = () => setKPIOpen(true);
  const handleKPIClose = () => setKPIOpen(false);

  const [readSnowflakeOpen, setReadSnowflakeOpen] = useState(false);
  const handleReadSnowflakeOpen = () => setReadSnowflakeOpen(true);
  const handleReadSnowflakeClose = () => setReadSnowflakeOpen(false);

  const [readRealTimeOpen, setReadRealTimeOpen] = useState(false);
  const handleReadRealTimeOpen = () => setReadRealTimeOpen(true);
  const handleReadRealTimeClose = () => setReadRealTimeOpen(false);

  const [emailOpen, setEmailOpen] = useState(false);
  const handleEmailOpen = () => setEmailOpen(true);
  const handleEmailClose = () => setEmailOpen(false);

  const [cleansingOpen, setCleansingOpen] = useState(false);
  const handleCleansingOpen = () => setCleansingOpen(true);
  const handleCleansingClose = () => setCleansingOpen(false);

  const [sqlOpen, setSqlOpen] = useState(false);
  const handleSqlOpen = () => setSqlOpen(true);
  const handleSqlClose = () => setSqlOpen(false);

  const [writeOpen, setWriteOpen] = useState(false);
  const handleWriteOpen = () => setWriteOpen(true);
  const handleWriteClose = () => setWriteOpen(false);

  const [writeToBenchlingOpen, setWriteToBenchlingOpen] = useState(false);
  const handleWriteToBenchlingOpen = () => setWriteToBenchlingOpen(true);
  const handleWriteToBenchlingClose = () => setWriteToBenchlingOpen(false);

  const [updateToBenchlingOpen, setUpdateToBenchlingOpen] = useState(false);
  const handleUpdateToBenchlingOpen = () => setUpdateToBenchlingOpen(true);
  const handleUpdateToBenchlingClose = () => setUpdateToBenchlingOpen(false);

  const [SnowFlakeOpen, setSnowFlakeOpen] = useState(false);
  const handleSnowFlakeOpen = () => setSnowFlakeOpen(true);
  const handleSnowFlakeClose = () => setSnowFlakeOpen(false);

  const [sourceOpen, setSourceOpen] = useState(false);
  const handleSourceOpen = () => setSourceOpen(true);
  const handleSourceClose = () => setSourceOpen(false);

  const [OpenSearchOpen, setOpenSearchOpen] = useState(false);
  const handleopenSearchOpen = () => setOpenSearchOpen(true);
  const handleOpenSearchClose = () => setOpenSearchOpen(false);

  const [WriteSFTPOpen, setWriteSFTPOpen] = useState(false);
  const handleWriteSFTPOpen = () => setWriteSFTPOpen(true);
  const handleWriteSFTPClose = () => setWriteSFTPOpen(false);

  const [DatabaseOpen, setDatabaseOpen] = useState(false);
  const handleDatabaseOpen = () => setDatabaseOpen(true);
  const handleDatabaseClose = () => setDatabaseOpen(false);

  const [aggregateOpen, setAggregateOpen] = useState(false);
  const handleAggregateOpen = () => setAggregateOpen(true);
  const handleAggregateClose = () => setAggregateOpen(false);

  const [C360Open, setC360Open] = useState(false);
  const handleC360Open = () => setC360Open(true);
  const handleC360Close = () => setC360Open(false);

  const [udfOpen, setUdfOpen] = useState(false);
  const handleUdfOpen = () => setUdfOpen(true);
  const handleUdfClose = () => setUdfOpen(false);

  const [coaleaseOpen, setCoaleaseOpen] = useState(false);
  const handleCoaleaseOpen = () => setCoaleaseOpen(true);
  const handleCoaleaseClose = () => setCoaleaseOpen(false);

  const [vicellOpen, setVicellOpen] = useState(false);
  const handleViCellOpen = () => setVicellOpen(true);
  const handleVicellClose = () => setVicellOpen(false);

  const [datatypeConvOpen, setDatatypeConvOpen] = useState(false);
  const handleDatatypeConvOpen = () => setDatatypeConvOpen(true);
  const handleDatatypeConvClose = () => setDatatypeConvOpen(false);

  const [concatOpen, setConcatOpen] = useState(false);
  const handleConcatOpen = () => setConcatOpen(true);
  const handleConcatClose = () => setConcatOpen(false);

  const [dateTimeOpen, setDateTimeOpen] = useState(false);
  const handleDateTimeOpen = () => setDateTimeOpen(true);
  const handleDateTimeClose = () => setDateTimeOpen(false);

  const [nullOpen, setNullOpen] = useState(false);
  const handleNullOpen = () => setNullOpen(true);
  const handleNullClose = () => setNullOpen(false);

  const [ingestionWriteOpen, setIngestionWriteOpen] = useState(false);
  const handleIngestionWriteOpen = () => setIngestionWriteOpen(true);
  const handleIngestionWriteClose = () => setIngestionWriteOpen(false);

  const [tableLevelMetadataIngestionOpen, setTableLevelMetadataIngestionOpen] = useState(false);
  const handleTableLevelMetadataIngestionOpen = () => setTableLevelMetadataIngestionOpen(true);
  const handleTableLevelMetadataIngestionClose = () => setTableLevelMetadataIngestionOpen(false);

  const [columnLevelMetadataIngestionOpen, setColumnLevelMetadataIngestionOpen] = useState(false);
  const handleColumnLevelMetadataIngestionOpen = () => setColumnLevelMetadataIngestionOpen(true);
  const handleColumnLevelMetadataIngestionClose = () => setColumnLevelMetadataIngestionOpen(false);

  const [trainingOpen, setTrainingOpen] = useState(false);
  const handleTrainingOpen = () => setTrainingOpen(true);
  const handleTrainingClose = () => setTrainingOpen(false);

  const [predictionOpen, setPredictionOpen] = useState(false);
  const handlePredictionOpen = () => setPredictionOpen(true);
  const handlePredictionClose = () => setPredictionOpen(false);

  const [binningOpen, setBinningOpen] = useState(false);
  const handleBinningOpen = () => setBinningOpen(true);
  const handleBinningClose = () => setBinningOpen(false);

  const [profilingOpen, setProfilingOpen] = useState(false);
  const handleProfilingOpen = () => setProfilingOpen(true);
  const handleProfilingClose = () => setProfilingOpen(false);

  const [mathTransformationOpen, setMathTransformationOpen] = useState(false);
  const handleMathTransformationOpen = () => setMathTransformationOpen(true);
  const handleMathTransformationClose = () => setMathTransformationOpen(false);

  const [dataValidationOpen, setDataValidationOpen] = useState(false);
  const handleDataValidationOpen = () => setDataValidationOpen(true);
  const handleDataValidationClose = () => setDataValidationOpen(false);

  const [oneHotEncoderOpen, setOneHotEncoderOpen] = useState(false);
  const handleOneHotEncoderOpen = () => setOneHotEncoderOpen(true);
  const handleOneHotEncoderClose = () => setOneHotEncoderOpen(false);

  const [labelEncoderOpen, setLabelEncoderOpen] = useState(false);
  const handleLabelEncoderOpen = () => setLabelEncoderOpen(true);
  const handleLabelEncoderClose = () => setLabelEncoderOpen(false);

  const [isDataLineage, setIsDataLineage] = useState(false);
  const handleDataLineage = () => setIsDataLineage(!isDataLineage);
  const [dropOpen, setDropOpen] = useState(false);
  const handleDropOpen = () => setDropOpen(true);
  const handleDropClose = () => setDropOpen(false);

  const [imbalancedDataOpen, setImbalancedDataOpen] = useState(false);
  const handleImbalancedDataOpen = () => setImbalancedDataOpen(true);
  const handleImbalancedDataClose = () => setImbalancedDataOpen(false);

  const [outlierOpen, setOutlierOpen] = useState(false);
  const handleOutlierOpen = () => setOutlierOpen(true);
  const handleOutlierClose = () => setOutlierOpen(false);

  const [scalerOpen, setScalerOpen] = useState(false);
  const handleScalerOpen = () => setScalerOpen(true);
  const handleScalerClose = () => setScalerOpen(false);

  const [resampleOpen, setResampleOpen] = useState(false);
  const handleResampleOpen = () => setResampleOpen(true);
  const handleResampleClose = () => setResampleOpen(false);

  const [readMdmOpen, setReadMdmOpen] = useState(false);
  const handleReadMdmOpen = () => setReadMdmOpen(true);
  const handleReadMdmClose = () => setReadMdmOpen(false);

  const [fuzzyMatchOpen, setFuzzyMatchOpen] = useState(false);
  const handleFuzzyMatchOpen = () => setFuzzyMatchOpen(true);
  const handleFuzzyMatchClose = () => setFuzzyMatchOpen(false);

  const [ruleEngineOpen, setRuleEngineOpen] = useState(false);
  const handleRuleEngineOpen = () => setRuleEngineOpen(true);
  const handleRuleEngineClose = () => setRuleEngineOpen(false);

  const [manualMergeOpen, setManualMergeOpen] = useState(false);
  const handleManualMergeOpen = () => setManualMergeOpen(true);
  const handleManualMergeClose = () => setManualMergeOpen(false);

  const [custSegmentationOpen, setCustSegmentationOpen] = useState(false);
  const handleCustSegmentationOpen = () => setCustSegmentationOpen(true);
  const handleCustSegmentationClose = () => setCustSegmentationOpen(false);

  const [plotOpen, setPlotOpen] = useState(false);
  const handlePlotOpen = () => setPlotOpen(true);
  const handlePlotClose = () => setPlotOpen(false);

  const [analysisOpen, setAnalysisOpen] = useState(false);
  const handleAnalysisOpen = () => setAnalysisOpen(true);
  const handleAnalysisClose = () => setAnalysisOpen(false);

  const [generateTranscriptOpen, setGenerateTranscriptOpen] = useState(false);
  const handleGenerateTranscriptOpen = () => setGenerateTranscriptOpen(true);
  const handleGenerateTranscriptClose = () => setGenerateTranscriptOpen(false);

  const [embeddingOpen, setEmbeddingOpen] = useState(false);
  const handleEmbeddingOpen = () => setEmbeddingOpen(true);
  const handleEmbeddingClose = () => setEmbeddingOpen(false);

  const [readFromFolderOpen, setReadFromFolderOpen] = useState(false);
  const handleReadFromFolderOpen = () => setReadFromFolderOpen(true);
  const handleReadFromFolderClose = () => setReadFromFolderOpen(false);

  const onConnect = useCallback((params) => {
    setEdges((eds) =>
      addEdge(
        {
          ...params,
          type: 'buttonedge',
          data: { onEdgesChange },
        },
        eds
      )
    );
  }, []);

  const onEdgeUpdate = (oldEdge, newConnection) => setEdges((els) => updateEdge(oldEdge, newConnection, els));

  const onNodesChange = useCallback((changes) => {
    if (changes[0].type === 'remove') {
      const getElementsId = JSON.parse(sessionStorage.getItem('saved_node')) || [];
      let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

      const updatedSavedNodes = getElementsId.filter((el) => el !== changes[0].id);
      updateNodes = updateNodes.filter((el) => el !== changes[0].id);

      sessionStorage.setItem('saved_node', JSON.stringify(updatedSavedNodes));
      sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

      setNodes((ns) => applyNodeChanges(changes, ns));

      const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

      if (getAllNodes) {
        getAllNodes.forEach((el, i) => {
          if (el.nodeId === changes[0].id) {
            getAllNodes.splice(i, 1);
            sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));
          }
        });
      }

      const elemCount = parseInt(sessionStorage.getItem('elementCount'), 10);

      elemCount && sessionStorage.setItem('elementCount', elemCount - 1);

      setNodeName('');
      setNodeId('');
    } else {
      setNodes((ns) => applyNodeChanges(changes, ns));
    }
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((es) => applyEdgeChanges(changes, es));
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      const name = event.dataTransfer.getData('nodeName');
      // const className = event.dataTransfer.getData('className');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${name}`, initNode: `${name}`, onNodesChange },
      };

      const elemCount = parseInt(sessionStorage.getItem('elementCount'), 10);

      elemCount ? sessionStorage.setItem('elementCount', elemCount + 1) : sessionStorage.setItem('elementCount', 1);

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const onNodeClick = (event, node) => {
    if (nodeName || nodeId) {
      setNodeName('');
      setNodeId('');
    }

    setNodeId(node.id);
    setNodeName(node.data.initNode);

    // if (node.height === 174) {
    if (node.data.initNode === 'Sort') {
      handleSortOpen();
    }

    if (node.data.initNode === 'Filter') {
      handleFilterOpen();
    }

    if (node.data.initNode === 'Union') {
      handleUnionOpen();
    }

    if (node.data.initNode === 'Join') {
      handleJoinOpen();
    }
    if (node.data.initNode === 'ViCell') {
      handleViCellOpen();
    }

    if (node.data.initNode === 'Read') {
      handleReadOpen();
    }
    if (node.data.initNode === 'ReadCSV') {
      handleReadCsvOpen();
    }
    if (node.data.initNode === 'Instrument') {
      handleInstrumentOpen();
    }
    if (node.data.initNode === 'ReadPDF') {
      handleReadPdfOpen();
    }
    if (node.data.initNode === 'CopyDoc') {
      handleCopyDocOpen();
    }
    if (node.data.initNode === 'ReadPublishedData') {
      handleReadPublishedDataOpen();
    }
    if (node.data.initNode === 'FileUploader') {
      handleFileUploaderOpen();
    }
    if (node.data.initNode === 'ReadStreaming') {
      handleReadStreamingOpen();
    }
    if (node.data.initNode === 'ReadDelimited') {
      handleReadDelimitedOpen();
    }
    if (node.data.initNode === 'ReadOracle') {
      handleReadOracleOpen();
    }
    if (node.data.initNode === 'ReadPostgres') {
      handleReadPostgresOpen();
    }
    if (node.data.initNode === 'ReadCosmos') {
      handleReadCosmosOpen();
    }
    if (node.data.initNode === 'ReadApi') {
      handleReadApiOpen();
    }
    if (node.data.initNode === 'ReadMySql') {
      handleReadMySqlOpen();
    }

    if (node.data.initNode === 'ReadSnowflake') {
      handleReadSnowflakeOpen();
    }

    if (node.data.initNode === 'Read Real-Time') {
      handleReadRealTimeOpen();
    }

    if (node.data.initNode === 'Read MDM') {
      handleReadMdmOpen();
    }

    if (node.data.initNode === 'Email') {
      handleEmailOpen();
    }

    if (node.data.initNode === 'Data Cleansing') {
      handleCleansingOpen();
    }

    if (node.data.initNode === 'KPI') {
      handleKPIOpen();
    }
    if (node.data.initNode === 'Execute SQL') {
      handleSqlOpen();
    }

    if (node.data.initNode === 'Write') {
      handleWriteOpen();
    }

    if (node.data.initNode === 'WriteToBenchling') {
      handleWriteToBenchlingOpen();
    }

    if (node.data.initNode === 'AutoRefresh') {
      handleUpdateToBenchlingOpen();
    }

    if (node.data.initNode === 'SnowFlake') {
      handleSnowFlakeOpen();
    }

    if (node.data.initNode === 'OpenSearch') {
      handleopenSearchOpen();
    }

    if (node.data.initNode === 'WriteSFTP') {
      handleWriteSFTPOpen();
    }

    if (node.data.initNode === 'Postgres') {
      handleDatabaseOpen();
    }

    if (node.data.initNode === 'Aggregation') {
      handleAggregateOpen();
    }

    if (node.data.initNode === 'C360') {
      handleC360Open();
    }

    if (node.data.initNode === 'Udf') {
      handleUdfOpen();
    }

    if (node.data.initNode === 'Coalesce') {
      handleCoaleaseOpen();
    }

    if (node.data.initNode === 'Datatype Conversion') {
      handleDatatypeConvOpen();
    }

    if (node.data.initNode === 'Concat') {
      handleConcatOpen();
    }

    if (node.data.initNode === 'Date Time Feature') {
      handleDateTimeOpen();
    }

    if (node.data.initNode === 'Handle Null') {
      handleNullOpen();
    }

    if (node.data.initNode === 'Ingestion Write') {
      handleIngestionWriteOpen();
    }

    if (node.data.initNode === 'Ingest Table Metadata') {
      handleTableLevelMetadataIngestionOpen();
    }

    if (node.data.initNode === 'Ingest Column Metadata') {
      handleColumnLevelMetadataIngestionOpen();
    }

    if (node.data.initNode === 'Training') {
      handleTrainingOpen();
    }

    if (node.data.initNode === 'Prediction') {
      handlePredictionOpen();
    }

    if (node.data.initNode === 'Binning') {
      handleBinningOpen();
    }

    if (node.data.initNode === 'Source') {
      handleSourceOpen();
    }

    if (node.data.initNode === 'Profiling') {
      handleProfilingOpen();
    }

    if (node.data.initNode === 'Math Transformation') {
      handleMathTransformationOpen();
    }

    if (node.data.initNode === 'DataValidation') {
      handleDataValidationOpen();
    }

    if (node.data.initNode === 'OneHot Encoder') {
      handleOneHotEncoderOpen();
    }

    if (node.data.initNode === 'Label Encoder') {
      handleLabelEncoderOpen();
    }

    if (node.data.initNode === 'Drop') {
      handleDropOpen();
    }

    if (node.data.initNode === 'Imbalanced Data') {
      handleImbalancedDataOpen();
    }

    if (node.data.initNode === 'Outlier') {
      handleOutlierOpen();
    }

    if (node.data.initNode === 'Scaler') {
      handleScalerOpen();
    }

    if (node.data.initNode === 'Resample') {
      handleResampleOpen();
    }

    if (node.data.initNode === 'Fuzzy Match') {
      handleFuzzyMatchOpen();
    }

    if (node.data.initNode === 'Data Cleaning & Validation') {
      handleRuleEngineOpen();
    }

    if (node.data.initNode === 'Match & Merge') {
      handleManualMergeOpen();
    }

    if (node.data.initNode === 'Customer Segmentation') {
      handleCustSegmentationOpen();
    }
    if (node.data.initNode === 'Plot') {
      handlePlotOpen();
    }

    if (node.data.initNode === 'Analysis') {
      handleAnalysisOpen();
    }

    if (node.data.initNode === 'Generate Transcript') {
      handleGenerateTranscriptOpen();
    }

    if (node.data.initNode === 'Embedding') {
      handleEmbeddingOpen();
    }

    if (node.data.initNode === 'Read From Folder') {
      handleReadFromFolderOpen();
    }
  };

  const changeNodeName = (newNode) => {
    newNode.forEach((el) => {
      el.data.onNodesChange = onNodesChange;
    });
    setNodes(newNode);
  };

  const resetElements = () => {
    setNodes([]);
  };

  useEffect(() => {
    const fetchNodes = JSON.parse(sessionStorage.getItem('node'));
    const fetchEdges = JSON.parse(sessionStorage.getItem('edges'));

    if (fetchEdges && fetchNodes) {
      fetchNodes.forEach((el) => {
        el.data.onNodesChange = onNodesChange;
      });
      fetchEdges.forEach((el) => {
        el.data.onEdgesChange = onEdgesChange;
      });

      setNodes(fetchNodes);
      setEdges(fetchEdges);
    }
    // sessionStorage.setItem('isLabelVisible', false);
  }, []);

  const getNodeData = JSON.parse(sessionStorage.getItem('saved_node'));

  useEffect(() => {
    setIsDisabled(false);

    if (nodes.length === 0 || getNodeData?.length === 0 || getNodeData === null) {
      setIsDisabled(true);
    }
  }, [isDisabled, nodes, getNodeData]);

  const handleMapReportingId = async (tempFormData) => {
    try {
      const client_name = workflow?.engagement?.client?.client_name;
      const engagement_name = workflow?.engagement?.engagement_name;
      const workflow_name = workflow?.workflow_name;
      const reporting_id = [];
      const node_id = [];
      const sample_view_path = [];

      const graph_plots_json = [];

      tempFormData.map(async (el) => {
        if (el.nodeName === 'Write') {
          reporting_id.push(`${workflow_name}_ds_${el.nodeId.split('_')[1]}`);
          node_id.push(el.nodeId);
          sample_view_path.push(
            `${client_name}/${engagement_name}/${workflow_name}/Data_Processor/SampleRecords/${el.nodeId}/Output/`
          );
        }
        if (el.nodeName === 'Plot') {
          const graph_id = [];
          el.fields.map((item) => {
            graph_id.push(item.graph_id);
          });
          graph_plots_json.push({
            graph_id,
            node_id: el.nodeId,
          });
        }
      });

      const payloadReport = {
        report_nodes_json: {
          reporting_id,
          node_id,
          sample_view_path,
        },
        domain: {
          domain_id: workflow?.engagement?.client?.depDomainEntity?.domain_id,
        },
        client: {
          client_id: workflow?.engagement?.client?.client_id,
        },
        engagement: {
          engagement_id: workflow?.engagement?.engagement_id,
        },
        workflow: {
          workflow_id: workflow?.workflow_id,
        },
      };

      const payloadPlot = {
        graph_plots_json,
        domain: {
          domain_id: workflow?.engagement?.client?.depDomainEntity?.domain_id,
        },
        client: {
          client_id: workflow?.engagement?.client?.client_id,
        },
        engagement: {
          engagement_id: workflow?.engagement?.engagement_id,
        },
        workflow: {
          workflow_id: workflow?.workflow_id,
        },
      };

      await saveReportsData(payloadReport);
      await saveGraphPlotData(payloadPlot);
    } catch (error) {
      enqueueSnackbar(`Error while saving data for report dashboard !`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const submitDataProcessor = async () => {
    props.setLoading(true);
    try {
      if (nodes.length === 0) {
        props.setLoading(false);
      }
      const newEdges = [];

      edges.forEach((el) => {
        const source = nodes.filter((item) => item.id === el.source);
        const target = nodes.filter((item) => item.id === el.target);

        if (source.length > 0 && target.length > 0) {
          newEdges.push(el);
        }
      });

      const getAllDataFromLocalStorage = JSON.parse(sessionStorage.getItem('allNodes'));

      getAllDataFromLocalStorage.map((item) => {
        nodes.forEach((el) => {
          if (item.nodeId === el.id) {
            item.y_axis = parseInt(el.position.x, 10);
          }
        });
      });

      getAllDataFromLocalStorage.sort((a, b) => a.y_axis - b.y_axis);

      const getNodeData = JSON.parse(sessionStorage.getItem('saved_node'));

      const getElementCount = parseInt(sessionStorage.getItem('elementCount'), 10) || '';

      if (getNodeData.length === getElementCount) {
        let count = 1;
        getAllDataFromLocalStorage.forEach((el) => {
          el.formField.step_no = count;
          count++;
        });

        getAllDataFromLocalStorage.sort((a, b) => a.formField.step_no - b.formField.step_no);

        const tempFormData = getAllDataFromLocalStorage;

        tempFormData.forEach((el) => {
          if (el.nodeName === 'Data Cleansing') {
            el.cleansingRules = el.cleansingRules.filter((el) => {
              return !el.deleted;
            });
          }
        });

        tempFormData.forEach((el) => {
          if (el.nodeName === 'Data Cleansing') {
            el.initial_rules = el.cleansingRules;
          }
        });

        const createFormData = {
          workflow_id: workflow.workflow_id,
          execution_mode: workflow.execution_mode,
          form_data: tempFormData,
          nodes,
          edges: newEdges,
        };

        const user_id = userId;
        const createForm = await createDataProcessorApi(createFormData, user_id);

        if (createForm.status === 201) {
          handleMapReportingId(tempFormData);

          sessionStorage.removeItem('allNodes');
          sessionStorage.removeItem('node');
          sessionStorage.removeItem('edges');
          sessionStorage.removeItem('nodeStatus');
          sessionStorage.removeItem('elementCount');
          sessionStorage.removeItem('saved_node');

          enqueueSnackbar('Steps and Data are saved!', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });

          resetElements();
        }
        console.log(convertToRequiredFormat(tempFormData, '', ''));
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response.status === 500) {
        enqueueSnackbar('Something went wrong !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    props.setLoading(false);
  };

  const handleLabelVisible = async () => {
    setIsLabelVisible(!isLabelVisible);
    sessionStorage.setItem('isLabelVisible', !isLabelVisible);
  };

  ref.current = submitDataProcessor;

  return (
    <>
      <Paper
        elevation={5}
        style={{ padding: ' 10px', overflow: 'visible', display: 'flex', flexDirection: 'row' }}
        className="react-flow-wrappper"
      >
        {/* <FlowBuilderTop
            resetElements={resetElements}
            nodes={nodes}
            edges={edges}
            userRole={userRole}
            componentType={componentType}
            setNodes={setNodes}
          /> */}
        {/* <div className="node-sec">{userRole !== 'ROLE_reader' ? <NodesSection /> : null}</div> */}

        <div className="node-sec">
          <NodesSection />
        </div>
        <div className="dndflow">
          <ReactFlowProvider>
            {nodeName === 'Write' && nodes && (
              <NodeModalWrite
                key={nodeId}
                nodeId={nodeId}
                changeNodeName={changeNodeName}
                nodeName={nodeName}
                nodes={nodes}
                edges={edges}
                open={writeOpen}
                setNodes={setNodes}
                handleClose={handleWriteClose}
              />
            )}
            {nodeName === 'WriteToBenchling' && nodes && (
              <NodeModalWriteToBenchling
                key={nodeId}
                nodeId={nodeId}
                changeNodeName={changeNodeName}
                nodeName={nodeName}
                nodes={nodes}
                edges={edges}
                open={writeToBenchlingOpen}
                setNodes={setNodes}
                handleClose={handleWriteToBenchlingClose}
              />
            )}
            {nodeName === 'AutoRefresh' && nodes && (
              <NodeModalUpdateToBenchling
                key={nodeId}
                nodeId={nodeId}
                changeNodeName={changeNodeName}
                nodeName={nodeName}
                nodes={nodes}
                edges={edges}
                open={updateToBenchlingOpen}
                setNodes={setNodes}
                handleClose={handleUpdateToBenchlingClose}
              />
            )}
            {nodeName === 'Concat' && nodes && (
              <NodeModalConcat
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={concatOpen}
                setNodes={setNodes}
                handleClose={handleConcatClose}
              />
            )}

            {nodeName === 'Source' && nodes && (
              <NodeModalSource
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={sourceOpen}
                setNodes={setNodes}
                handleClose={handleSourceClose}
              />
            )}

            {nodeName === 'Datatype Conversion' && nodes && (
              <NodeModalDatatypeConv
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={datatypeConvOpen}
                setNodes={setNodes}
                handleClose={handleDatatypeConvClose}
              />
            )}
            {nodeName === 'Date Time Feature' && nodes && (
              <NodeModalDateTime
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={dateTimeOpen}
                setNodes={setNodes}
                handleClose={handleDateTimeClose}
              />
            )}
            {nodeName === 'Handle Null' && nodes && (
              <NodeModalHandleNull
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={nullOpen}
                setNodes={setNodes}
                handleClose={handleNullClose}
              />
            )}

            {nodeName === 'OneHot Encoder' && nodes && (
              <NodeModalOneHotEncoder
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={oneHotEncoderOpen}
                setNodes={setNodes}
                handleClose={handleOneHotEncoderClose}
              />
            )}

            {nodeName === 'Outlier' && nodes && (
              <NodeModalOutlier
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={outlierOpen}
                setNodes={setNodes}
                handleClose={handleOutlierClose}
              />
            )}

            {nodeName === 'Imbalanced Data' && nodes && (
              <NodeModalImbalancedData
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={imbalancedDataOpen}
                setNodes={setNodes}
                handleClose={handleImbalancedDataClose}
              />
            )}

            {nodeName === 'Drop' && nodes && (
              <NodeModalDrop
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={dropOpen}
                setNodes={setNodes}
                handleClose={handleDropClose}
              />
            )}

            {nodeName === 'Scaler' && nodes && (
              <NodeModalScaler
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={scalerOpen}
                setNodes={setNodes}
                handleClose={handleScalerClose}
              />
            )}

            {nodeName === 'Resample' && nodes && (
              <NodeModalResample
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={resampleOpen}
                setNodes={setNodes}
                handleClose={handleResampleClose}
              />
            )}

            {nodeName === 'Label Encoder' && nodes && (
              <NodeModalLabelEncoder
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={labelEncoderOpen}
                setNodes={setNodes}
                handleClose={handleLabelEncoderClose}
              />
            )}

            {nodeName === 'OpenSearch' && nodes && (
              <NodeModalOpenSearch
                key={nodeId}
                nodeId={nodeId}
                changeNodeName={changeNodeName}
                nodeName={nodeName}
                nodes={nodes}
                edges={edges}
                open={OpenSearchOpen}
                setNodes={setNodes}
                handleClose={handleOpenSearchClose}
              />
            )}

            {nodeName === 'WriteSFTP' && nodes && (
              <NodeModalWriteSFTP
                key={nodeId}
                nodeId={nodeId}
                changeNodeName={changeNodeName}
                nodeName={nodeName}
                nodes={nodes}
                edges={edges}
                open={WriteSFTPOpen}
                setNodes={setNodes}
                handleClose={handleWriteSFTPClose}
              />
            )}

            {nodeName === 'Postgres' && nodes && (
              <NodeModalDatabase
                key={nodeId}
                nodeId={nodeId}
                changeNodeName={changeNodeName}
                nodeName={nodeName}
                nodes={nodes}
                edges={edges}
                open={DatabaseOpen}
                setNodes={setNodes}
                handleClose={handleDatabaseClose}
              />
            )}
            {nodeName === 'C360' && nodes && (
              <NodeModalC360
                key={nodeId}
                nodeId={nodeId}
                changeNodeName={changeNodeName}
                nodeName={nodeName}
                nodes={nodes}
                edges={edges}
                open={C360Open}
                setNodes={setNodes}
                handleClose={handleC360Close}
              />
            )}
            {nodeName === 'Read' && nodes && (
              <NodeModalRead
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readOpen}
                setNodes={setNodes}
                handleClose={handleReadClose}
              />
            )}
            {nodeName === 'ReadCSV' && nodes && (
              <NodeModalReadCSV
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readCsvOpen}
                setNodes={setNodes}
                handleClose={handleReadCsvClose}
              />
            )}
            {nodeName === 'Instrument' && nodes && (
              <NodeModalInstrument
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={instrumentOpen}
                setNodes={setNodes}
                handleClose={handleInstrumentClose}
                workflow={workflow}
              />
            )}
            {nodeName === 'ReadPDF' && nodes && (
              <NodeModalReadPDF
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readPdfOpen}
                setNodes={setNodes}
                handleClose={handleReadPdfClose}
              />
            )}
            {nodeName === 'CopyDoc' && nodes && (
              <NodeModalCopyDoc
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={copyDocOpen}
                setNodes={setNodes}
                handleClose={handleCopyDocClose}
              />
            )}
            {nodeName === 'ReadPublishedData' && nodes && (
              <NodeModalReadPublishedData
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readPublishedDataOpen}
                setNodes={setNodes}
                handleClose={handleReadPublishedDataClose}
              />
            )}
            {nodeName === 'FileUploader' && nodes && (
              <NodeModalFileUploader
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={FileUploaderOpen}
                setNodes={setNodes}
                handleClose={handleFileUploaderClose}
              />
            )}
            {nodeName === 'ReadStreaming' && nodes && (
              <NodeModalReadStreaming
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readStreamingOpen}
                setNodes={setNodes}
                handleClose={handleReadStreamingClose}
              />
            )}
            {nodeName === 'ReadDelimited' && nodes && (
              <NodeModalReadDelimited
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readDelimitedOpen}
                setNodes={setNodes}
                handleClose={handleReadDelimitedClose}
              />
            )}
            {nodeName === 'ReadOracle' && nodes && (
              <NodeModalReadOracle
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readOraclepen}
                setNodes={setNodes}
                handleClose={handleReadOracleClose}
              />
            )}
            {nodeName === 'ReadPostgres' && nodes && (
              <NodeModalReadPostgres
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readPostgresOpen}
                setNodes={setNodes}
                handleClose={handleReadPostgresClose}
              />
            )}
            {nodeName === 'ReadCosmos' && nodes && (
              <NodeModalReadCosmos
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readCosmosOpen}
                setNodes={setNodes}
                handleClose={handleReadCosmosClose}
              />
            )}
            {nodeName === 'ReadApi' && nodes && (
              <NodeModalReadAPI
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readApiOpen}
                setNodes={setNodes}
                handleClose={handleReadApiClose}
              />
            )}
            {nodeName === 'ReadMySql' && nodes && (
              <NodeModalReadMySql
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readMySqlOpen}
                setNodes={setNodes}
                handleClose={handleReadMySqlClose}
              />
            )}

            {nodeName === 'ReadSnowflake' && nodes && (
              <NodeModalReadSnowflake
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readSnowflakeOpen}
                setNodes={setNodes}
                handleClose={handleReadSnowflakeClose}
              />
            )}
            {nodeName === 'Read Real-Time' && nodes && (
              <NodeModalReadRealTime
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readRealTimeOpen}
                setNodes={setNodes}
                handleClose={handleReadRealTimeClose}
              />
            )}

            {nodeName === 'Execute SQL' && nodes && (
              <NodeModalExecuteSql
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={sqlOpen}
                setNodes={setNodes}
                handleClose={handleSqlClose}
              />
            )}

            {nodeName === 'KPI' && nodes && (
              <NodeModalKPI
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={KPIOpen}
                setNodes={setNodes}
                handleClose={handleKPIClose}
              />
            )}

            {nodeName === 'SnowFlake' && nodes && (
              <NodeModalSnowFlake
                key={nodeId}
                nodeId={nodeId}
                changeNodeName={changeNodeName}
                nodeName={nodeName}
                nodes={nodes}
                edges={edges}
                open={SnowFlakeOpen}
                setNodes={setNodes}
                handleClose={handleSnowFlakeClose}
              />
            )}

            {nodeName === 'Udf' && nodes && (
              <NodeModalUdf
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={udfOpen}
                setNodes={setNodes}
                handleClose={handleUdfClose}
              />
            )}

            {nodeName === 'Join' && nodes && (
              <NodeModalJoin
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={joinOpen}
                setNodes={setNodes}
                handleClose={handleJoinClose}
              />
            )}

            {nodeName === 'Sort' && nodes && (
              <NodePropertiesSort
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={sortOpen}
                setNodes={setNodes}
                handleClose={handleSortClose}
              />
            )}

            {nodeName === 'Coalesce' && nodes && (
              <NodeModalCoalease
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={coaleaseOpen}
                setNodes={setNodes}
                handleClose={handleCoaleaseClose}
              />
            )}
            {nodeName === 'ViCell' && nodes && (
              <NodeModalVICELL
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={vicellOpen}
                setNodes={setNodes}
                handleClose={handleVicellClose}
              />
            )}

            {nodeName === 'Filter' && nodes && (
              <NodePropertiesFilter
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={filterOpen}
                setNodes={setNodes}
                handleClose={handleFilterClose}
              />
            )}
            {nodeName === 'Union' && nodes && (
              <NodeModalUnion
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={unionOpen}
                setNodes={setNodes}
                handleClose={handleUnionClose}
              />
            )}

            {nodeName === 'Email' && nodes && (
              <NodeModalEmail
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={emailOpen}
                setNodes={setNodes}
                handleClose={handleEmailClose}
              />
            )}

            {nodeName === 'Data Cleansing' && nodes && (
              <NodeModalDataCleansing
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={cleansingOpen}
                setNodes={setNodes}
                handleClose={handleCleansingClose}
              />
            )}

            {nodeName === 'Aggregation' && nodes && (
              <NodeModalAggregate
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={aggregateOpen}
                setNodes={setNodes}
                handleClose={handleAggregateClose}
              />
            )}

            {nodeName === 'Ingestion Write' && nodes && (
              <NodeModalIngestionWrite
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={ingestionWriteOpen}
                setNodes={setNodes}
                handleClose={handleIngestionWriteClose}
              />
            )}

            {nodeName === 'Ingest Table Metadata' && nodes && (
              <NodeModalTableLevelMetadataIngestion
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={tableLevelMetadataIngestionOpen}
                setNodes={setNodes}
                handleClose={handleTableLevelMetadataIngestionClose}
              />
            )}

            {nodeName === 'Ingest Column Metadata' && nodes && (
              <NodeModalColumnLevelMetadataIngestion
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={columnLevelMetadataIngestionOpen}
                setNodes={setNodes}
                handleClose={handleColumnLevelMetadataIngestionClose}
              />
            )}

            {nodeName === 'Training' && nodes && (
              <NodePropertiesTraining
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={trainingOpen}
                setNodes={setNodes}
                handleClose={handleTrainingClose}
              />
            )}
            {nodeName === 'Prediction' && nodes && (
              <NodePropertiesPrediction
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={predictionOpen}
                setNodes={setNodes}
                handleClose={handlePredictionClose}
              />
            )}
            {nodeName === 'Binning' && nodes && (
              <NodePropertiesBinning
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={binningOpen}
                setNodes={setNodes}
                handleClose={handleBinningClose}
              />
            )}
            {nodeName === 'Profiling' && nodes && (
              <NodeModalProfiling
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={profilingOpen}
                setNodes={setNodes}
                handleClose={handleProfilingClose}
              />
            )}
            {nodeName === 'Math Transformation' && nodes && (
              <NodeModalMathTransformation
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={mathTransformationOpen}
                setNodes={setNodes}
                handleClose={handleMathTransformationClose}
              />
            )}
            {nodeName === 'DataValidation' && nodes && (
              <NodeModalDataValidation
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={dataValidationOpen}
                setNodes={setNodes}
                handleClose={handleDataValidationClose}
              />
            )}
            {nodeName === 'Read MDM' && nodes && (
              <NodeModalReadMDM
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readMdmOpen}
                setNodes={setNodes}
                handleClose={handleReadMdmClose}
              />
            )}
            {nodeName === 'Data Cleaning & Validation' && nodes && (
              <NodeModalRuleEngine
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={ruleEngineOpen}
                setNodes={setNodes}
                handleClose={handleRuleEngineClose}
              />
            )}
            {nodeName === 'Fuzzy Match' && nodes && (
              <NodeModalFuzzyMatch
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={fuzzyMatchOpen}
                setNodes={setNodes}
                handleClose={handleFuzzyMatchClose}
              />
            )}
            {nodeName === 'Match & Merge' && nodes && (
              <NodeModalManualMerge
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={manualMergeOpen}
                setNodes={setNodes}
                handleClose={handleManualMergeClose}
              />
            )}
            {nodeName === 'Customer Segmentation' && nodes && (
              <NodeModalCustomerSegmentation
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={custSegmentationOpen}
                setNodes={setNodes}
                handleClose={handleCustSegmentationClose}
              />
            )}
            {nodeName === 'Plot' && nodes && (
              <NodeModalPlot
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={plotOpen}
                setNodes={setNodes}
                handleClose={handlePlotClose}
              />
            )}
            {nodeName === 'Analysis' && nodes && (
              <NodeModalAnalysis
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={analysisOpen}
                setNodes={setNodes}
                handleClose={handleAnalysisClose}
              />
            )}

            {nodeName === 'Generate Transcript' && nodes && (
              <NodeModalGenerateTranscript
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={generateTranscriptOpen}
                setNodes={setNodes}
                handleClose={handleGenerateTranscriptClose}
              />
            )}
            {nodeName === 'Embedding' && nodes && (
              <NodeModalEmbedding
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={embeddingOpen}
                setNodes={setNodes}
                handleClose={handleEmbeddingClose}
              />
            )}
            {nodeName === 'Read From Folder' && nodes && (
              <NodeModalReadFromFolder
                key={nodeId}
                nodeId={nodeId}
                nodeName={nodeName}
                changeNodeName={changeNodeName}
                nodes={nodes}
                edges={edges}
                open={readFromFolderOpen}
                setNodes={setNodes}
                handleClose={handleReadFromFolderClose}
              />
            )}
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              {workflow.process_type !== 'XPerience360' && workflow.process_type !== 'Customer Segmentation' && (
                <>
                  {/* <Tooltip title="Node Label">
                    <div
                      aria-label="data-lineaege"
                      style={{ position: 'absolute', top: 22, right: 75, background: '#fff', zIndex: '999' }}
                    >
                      <Switch
                        checked={!!isLabelVisible}
                        onChange={handleLabelVisible}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        size="small"
                        defaultChecked={!!isLabelVisible}
                      />
                    </div>
                  </Tooltip> */}
                  <Tooltip title="Data Lineage">
                    <Fab
                      aria-label="data-lineaege"
                      onClick={handleDataLineage}
                      size="small"
                      sx={{ position: 'absolute', top: 14, right: 22, background: '#fff' }}
                    >
                      <LinearScaleIcon fontSize="medium" color="primary" />
                    </Fab>
                  </Tooltip>
                </>
              )}
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodeClick={onNodeClick}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onEdgeUpdate={onEdgeUpdate}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                fitView
                attributionPosition="bottom-left"
              >
                {/* <Controls position="top-left" /> */}
                <Controls position="top-left">
                  <ControlButton onClick={handleLabelVisible} title="label visibility">
                    {isLabelVisible ? <MdLabel /> : <MdLabelOff />}
                  </ControlButton>
                </Controls>
                <Background variant="cross" />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
          <DataLineageGraph open={isDataLineage} handleClose={handleDataLineage} />
        </div>
      </Paper>
    </>
  );
});

export default ReactFlowPannel;
