import React, { useState, memo, useEffect, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Box,
  IconButton,
  MenuItem,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  TableContainer,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  Autocomplete,
  FormControlLabel,
  Modal,
} from '@mui/material';
import { Select, Radio } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import InputField from '../../../../reusable-component/InputField';
import LoadingIcon from '../../../../reusable-component/LoadingIcon';
import { getJsonData, getCsvData } from "../../../../api's/RuleEngineApi";
import {
  AllFilesOfS3,
  AllFoldersOfS3,
  fetchPostgresColumn,
  fetchPostgresDatabase,
  fetchPostgresSchema,
  fetchPostgresTables,
} from "../../../../api's/DataProcessorApi";
import { getEngagementByUserGroupAndClientIdApi } from "../../../../api's/EngagementApi";
import { getAllClientsApi, getClientByUserGroup } from "../../../../api's/ClientApi";
import { getWorkflowByUserGroupAndEngagementIdApi } from "../../../../api's/WorkflowApi";
import { BUCKET_NAME } from '../../../../constants/Constant';
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';
import { AuthContext } from '../../../../contexts/AuthProvider';
import { AWS_CONFIG_TYPE } from '../../../../BaseUrl';
import { getCosmosDatabase, getCosmosContainers, getCosmosColumns } from "../../../../api's/cosmosApi";

const { Option } = Select;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
  path: '',
  format: 'cosmos',
  action: '',
  persist: false,
  alias: '',
  persist_type: '',
  database: '',
  delimiter: ',',
  tablename: '',
  schema: '',
  ignoreblanklines: false,
  skipheaders: false,
  columnshift: false,
  junkrecords: false,
  linebreak: false,
  distinct_rows: false,
  clientId: '',
  clientName: '',
  batchName: '',
  tableNameRead: '',
  trackingId: '',
  innerPath: '',
  file: '',
  dataTypeRead: 'source',
  pagination: false,
  lowerBound: '',
  upperBound: '',
  CDC: false,
  partitionColumn: '',
  numPartitions: '',
  sample_view: true,
  priority: '',
};
const RowLevelOperation = {
  ignoreblanklines: false,
  skipheaders: false,
  columnshift: false,
  junkrecords: false,
  linebreak: false,
  delimiter: ',',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const NodeModalReadCosmos = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userRole, userGroup } = useContext(AuthContext);
  const { workflow } = useContext(WorkflowContext);

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [flowbuilderRowLevelOperation, setflowbuilderRowLevelOperation] = useState(RowLevelOperation);
  const [DataType, setDataType] = useState('source');
  const [isDataLoad, setisDataLoad] = useState(false);
  const [addColumn, setAddColumn] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [tempHeader, setTempHeader] = useState([]);
  const [attributeType, setAttributeType] = useState('static');

  const [AllClient, setAllClient] = useState([]);
  const [AllBatch, setAllBatch] = useState([]);
  const [AllTable, setAllTable] = useState([]);
  const [AllTracking, setAllTracking] = useState([]);
  const [AllFiles, setAllFiles] = useState([]);

  const [postgresDatabases, setPostgresDatabases] = useState([]);
  const [postgresSchemas, setPostgresSchemas] = useState([]);
  const [postgresTables, setPostgresTables] = useState([]);

  const [cosmosDatabases, setCosmosDatabases] = useState([]);
  const [cosmosContainers, setCosmosContainers] = useState([]);

  const plainOptions = [
    {
      label: 'Source Data',
      value: 'source',
    },
    {
      label: 'Refernced Data',
      value: 'refernce',
    },
  ];

  const attributeOptions = [
    {
      label: 'Static',
      value: 'static',
    },
    {
      label: 'Function',
      value: 'function',
    },
  ];

  const bucketValue = AWS_CONFIG_TYPE ? `s3://${BUCKET_NAME}` : `https://depazure.blob.core.windows.net/dep-develop/`;

  const [addColumnModalOpen, setAddColumnModalOpen] = useState(false);
  const handleAddColumnModalOpen = () => setAddColumnModalOpen(true);
  const handleAddColumnModalClose = () => setAddColumnModalOpen(false);

  const checklabel = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const onChangeDataType = ({ target: { value } }) => {
    setDataType(value);
    setisDataLoad(true);
    setFormField({ ...formField, dataTypeRead: value });
  };

  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              header: obj.header,
              alias: obj.alias,
              checked: true,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: false,
              alias: '',
              cast_datatype: 'string',
              attribute_type: 'static',
              default_value: '',
            };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          alias: n.alias,
          checked: true,
          cast_datatype: 'string',
          attribute_type: 'static',
          default_value: '',
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });

    return selectedIndex !== -1;
  };

  const handleAddColumn = () => {
    setFetchedHeader([
      ...fetchedHeader,
      {
        header: addColumn,
        alias: '',
        checked: false,
        cast_datatype: 'string',
        default_value: defaultValue,
        attribute_type: attributeType,
      },
    ]);
    handleAddColumnModalClose();
    setAddColumn('');
    setDefaultValue('');
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    if (store) {
      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setisDataLoad(true);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setflowbuilderRowLevelOperation(RowLevelOperation);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    getLocalData();
  }, [nodeId]);

  const sendFormData = {
    nodeId,
    nodeName,
    formField,
    disabled: false,
    step_name: nodeName,
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    if (
      name === 'ignoreblanklines' ||
      name === 'linebreak' ||
      name === 'skipheaders' ||
      name === 'columnshift' ||
      name === 'junkrecords' ||
      name === 'pagination'
    ) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  let rowName, rowValue;
  const RowLevelInputChange = (e) => {
    rowName = e.target.name;
    if (rowName === 'delimiter') {
      rowValue = e.target.value;
    } else {
      rowValue = e.target.checked;
    }
    setflowbuilderRowLevelOperation({
      ...flowbuilderRowLevelOperation,
      [rowName]: rowValue,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      // flowbuilderRowLevelOperation
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    // setTempHeader(headerName);

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const fetchFileHeader = async (dataType) => {
    if (dataType === 'refernce') {
      const regex =
        workflow.cloudEnvironment === 'AWS'
          ? /^s3:\/\/.*csv$/
          : workflow.cloudEnvironment === 'AZURE'
          ? /^https:\/\/.*csv$/
          : workflow.cloudEnvironment === 'SNOWFLAKE'
          ? /.*csv$/
          : '';

      if (!regex.test(formField.path)) {
        if (workflow.cloudEnvironment === 'AWS') {
          enqueueSnackbar('S3 path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (workflow.cloudEnvironment === 'AZURE') {
          enqueueSnackbar('ADLS gen2 path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (workflow.cloudEnvironment === 'SNOWFLAKE') {
          enqueueSnackbar('snowflake path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        return;
      }
    }

    setLoadingBtn(true);

    let data = {};
    if (dataType === 'refernce') {
      const pathArr = formField.path.split('/');
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      workflow.cloudEnvironment === 'AZURE' && pathArr.shift(0);
      const newPath = pathArr.join('/');
      data = {
        path: newPath,
        workflow_id: workflow.workflow_id,
      };
    } else {
      const newPath = `${formField.trackingId}${formField.file}`;
      data = {
        path: newPath,
        workflow_id: workflow.workflow_id,
      };
    }

    try {
      const response = await getCsvData(data);
      if (response.status === 200) {
        const header = [];

        response.data.forEach((el) => {
          header.push({
            header: el,
            alias: '',
            checked: false,
            tableAlias: '',
            cast_datatype: 'string',
            default_value: '',
            attribute_type: 'static',
          });
        });

        setFetchedHeader(header);
      }
    } catch (error) {
      enqueueSnackbar('S3 path is invalid!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoadingBtn(false);
  };

  const fetchJsonFileHeader = async () => {
    const regex = AWS_CONFIG_TYPE === true ? /^s3:\/\/.*json$/ : /^https:\/\/.*json$/;

    if (!regex.test(formField.path)) {
      if (AWS_CONFIG_TYPE) {
        enqueueSnackbar('S3 path is invalid!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('ADLS gen2 path is invalid!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      return;
    }

    setLoadingBtn(true);

    const pathArr = formField.path.split('/');
    pathArr.shift(0);
    pathArr.shift(0);
    pathArr.shift(0);
    AWS_CONFIG_TYPE === false && pathArr.shift(0);
    const newPath = pathArr.join('/');

    try {
      const data = {
        path: newPath,
        client_id: workflow.engagement.client.client_id,
        aws_config_type: AWS_CONFIG_TYPE,
      };
      const response = await getJsonData(data);
      if (response.status === 200) {
        const header = [];

        response.data.forEach((el) => {
          header.push({
            header: el,
            alias: '',
            checked: false,
            tableAlias: '',
            cast_datatype: 'string',
            default_value: '',
            attribute_type: 'static',
          });
        });

        setFetchedHeader(header);
      }
    } catch (error) {
      enqueueSnackbar('S3 path is invalid!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoadingBtn(false);
  };

  const getClient = async () => {
    // let response = null;

    // if (userRole === 'ROLE_executor') {
    //   response = await getApprovedClientApiByUserId(userId);
    // } else response = await getApprovedClientApi();

    const response = userRole === 'SUPER_USER' ? await getAllClientsApi() : await getClientByUserGroup(userGroup);
    if (response.status === 200) {
      const clients = response.data.filter((el) => el.status === 'approved');
      setAllClient(clients);
    }
  };

  const getBatch = async (clientId) => {
    const sp = clientId.split('_____');
    const response = await getEngagementByUserGroupAndClientIdApi(userGroup, sp[0]);
    if (response.status === 200) {
      const engagements = response.data.filter((el) => el.status === 'approved');
      setAllBatch(engagements);
    }
  };

  const getTable = async (batchName) => {
    try {
      const sp = formField.clientId.split('_____');
      const sp1 = formField.batchName.split('_____');
      // const response = await fetchTableRules(sp[0], workflow.engagement.engagement_id);
      const response = await getWorkflowByUserGroupAndEngagementIdApi(userGroup, sp1[0]);
      if (response.status === 200) {
        const workflows = response.data.filter((el) => el.status === 'approved');
        setAllTable(workflows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTrackingId = async (values, innerPath) => {
    try {
      const sp = formField.clientId.split('_____');
      const sp1 = formField.batchName.split('_____');
      const response = await AllFoldersOfS3(sp[0], sp1[1], formField.tableNameRead, innerPath, false);
      if (response.status === 200) {
        setAllTracking(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFile = async (values) => {
    const sp = formField.clientId.split('_____');
    const response = await AllFilesOfS3(formField.trackingId, sp[0]);
    if (response.status === 200) {
      setAllFiles(response.data);
    }
  };

  const handleClientChange = async (e) => {
    setFormField({ ...formField, clientId: e.target.value });
  };

  const handleBatchChange = (e) => {
    setFormField({ ...formField, batchName: e.target.value });
  };

  const handleTableChange = (e) => {
    setFormField({ ...formField, tableNameRead: e.target.value });
  };

  const handleinnerPathChange = (e) => {
    setFormField({ ...formField, innerPath: e.target.value });
  };

  const handleTrackingChange = (e) => {
    setFormField({ ...formField, trackingId: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormField({ ...formField, file: e.target.value });
  };

  useEffect(() => {
    if (isDataLoad) {
      getClient();
    }
  }, [isDataLoad]);

  useEffect(() => {
    if (isDataLoad) {
      getBatch(formField.clientId);
      setFormField({ ...formField, batchName: '', tableNameRead: '', trackingId: '', file: '' });
    }
  }, [formField.clientId]);

  useEffect(() => {
    if (isDataLoad) {
      getTable(formField.batchName);
      setFormField({ ...formField, tableNameRead: '', trackingId: '', file: '' });
    }
  }, [formField.batchName]);

  useEffect(() => {
    if (isDataLoad) {
      getTrackingId(formField.tableNameRead, formField.innerPath);
      setFormField({ ...formField, trackingId: '', file: '' });
    }
  }, [formField.innerPath]);

  useEffect(() => {
    if (isDataLoad) {
      getFile();
      setFormField({ ...formField, file: '' });
    }
  }, [formField.trackingId]);

  useEffect(() => {
    if (isDataLoad) {
      setFormField({ ...formField });
    }
  }, [formField.file]);

  const getPostgresDatabase = async () => {
    try {
      const response = await fetchPostgresDatabase(AWS_CONFIG_TYPE);
      if (response.status === 200) {
        const db = [];
        response.data.forEach((el) => {
          db.push(el.label);
        });
        setPostgresDatabases(db);
      }
    } catch (error) {
      enqueueSnackbar('Databases not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getPostgresSchema = async (database) => {
    try {
      const response = await fetchPostgresSchema(database, AWS_CONFIG_TYPE);
      if (response.status === 200) {
        const tables = [];
        response.data.forEach((el) => {
          tables.push(el.label);
        });
        setPostgresSchemas(tables);
      }
    } catch (error) {
      enqueueSnackbar('Schema not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getPostgresTables = async (database, schema) => {
    try {
      const response = await fetchPostgresTables(database, schema, AWS_CONFIG_TYPE);
      if (response.status === 200) {
        const tables = [];
        response.data.forEach((el) => {
          tables.push(el.label);
        });
        setPostgresTables(tables);
      }
    } catch (error) {
      enqueueSnackbar('Tables not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getPostgresColumn = async (table) => {
    setLoadingBtn(true);

    try {
      const response = await fetchPostgresColumn(formField.database, formField.schema, table, AWS_CONFIG_TYPE);
      if (response.status === 200) {
        const header = [];

        response.data.forEach((el) => {
          header.push({
            header: el,
            alias: '',
            checked: false,
            tableAlias: '',
            cast_datatype: 'string',
            default_value: '',
            attribute_type: 'static',
          });
        });

        setFetchedHeader(header);
      }
    } catch (error) {
      enqueueSnackbar('Columns not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }

    setLoadingBtn(false);
  };

  useEffect(() => {
    if (formField.format === 'postgres') {
      getPostgresDatabase();
    }

    if (formField.format === 'postgres' && formField.database) {
      getPostgresSchema(formField.database);
    }

    if (formField.format === 'postgres' && formField.database && formField.schema) {
      getPostgresTables(formField.database, formField.schema);
    }
  }, [formField]);

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  const fetchCosmosDatabase = async () => {
    try {
      const res = await getCosmosDatabase();
      if (res.status === 200) {
        console.log(res.data);
        setCosmosDatabases(res.data);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const fetchCosmosContainer = async (database) => {
    try {
      const res = await getCosmosContainers(database);
      if (res.status === 200) {
        console.log(res.data);
        setCosmosContainers(res.data);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };
  const fetchCosmosColumns = async (database, container) => {
    // try {
    //   const res = await getCosmosColumns(database, container);
    //   if (res.status === 200) {
    //     console.log(res.data);
    //     setCosmosContainers(res.data);
    //   }
    // } catch (error) {
    //   enqueueSnackbar(error, {
    //     variant: 'error',
    //     autoHideDuration: 3000,
    //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //   });
    // }

    setLoadingBtn(true);

    try {
      const response = await getCosmosColumns(database, container);
      if (response.status === 200) {
        const header = [];

        response.data.forEach((el) => {
          header.push({
            header: el.label,
            alias: '',
            checked: false,
            tableAlias: '',
            cast_datatype: 'string',
            default_value: '',
            attribute_type: 'static',
          });
        });

        setFetchedHeader(header);
      }
    } catch (error) {
      enqueueSnackbar('Columns not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }

    setLoadingBtn(false);
  };

  useEffect(() => {
    fetchCosmosDatabase();
  }, []);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '70%', marginLeft: '30%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>
          <div style={{ margin: '20px' }}>
            <input type="hidden" value={nodeId} />

            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 2, width: '32ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <InputField
                name="alias"
                label="Alias"
                value={formField.alias}
                onChange={handleInputChange}
                size="small"
                disabled={disableForm}
                required
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={formField.sample_view}
                    onChange={handleChecked}
                    disabled={disableForm}
                  />
                }
                label="Sample View"
                sx={{ pl: 1 }}
              />
            </Box>

            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 2, width: '32ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                // select
                label="Format"
                variant="outlined"
                value="cosmos"
                // onChange={handleInputChange}
                name="format"
                size="small"
                disabled
                required
                InputProps={{
                  style: {
                    fontFamily: "'EB Garamond', serif ",
                    fontWeight: 600,
                  },
                }}
                InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
              >
                {/* <MenuItem value="csv">csv</MenuItem>
                <MenuItem value="streaming">streaming</MenuItem>
                <MenuItem value="delimited">delimited</MenuItem>
                <MenuItem value="postgres">postgres</MenuItem>
                <MenuItem value="mysql">mysql</MenuItem>
                <MenuItem value="oracle">oracle</MenuItem> */}
              </TextField>
              {workflow.process_type === 'XPerience360' && (
                <InputField
                  name="priority"
                  label="Priority"
                  value={formField.priority}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                  required
                />
              )}

              {formField.format === 'delimited' && (
                <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <Checkbox
                      disabled={disableForm}
                      name="ignoreblanklines"
                      {...checklabel}
                      checked={formField.ignoreblanklines}
                      onChange={handleInputChange}
                    />
                    ignoreblanklines
                    <Checkbox
                      disabled={disableForm}
                      name="skipheaders"
                      {...checklabel}
                      checked={formField.skipheaders}
                      onChange={handleInputChange}
                    />
                    skipheaders
                    <Checkbox
                      disabled={disableForm}
                      name="columnshift"
                      {...checklabel}
                      checked={formField.columnshift}
                      onChange={handleInputChange}
                    />
                    columnshift
                    <Checkbox
                      disabled={disableForm}
                      name="junkrecords"
                      {...checklabel}
                      checked={formField.junkrecords}
                      onChange={handleInputChange}
                    />
                    junkrecords
                    <Checkbox
                      disabled={disableForm}
                      name="linebreak"
                      {...checklabel}
                      checked={formField.linebreak}
                      onChange={handleInputChange}
                    />
                    linebreak
                  </div>
                  <InputField
                    name="delimiter"
                    label="Delimiter"
                    value={formField.delimiter}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                    required
                  />
                  <InputField
                    name="path"
                    label="File Path"
                    value={formField.path}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                    required
                  />

                  <Button
                    style={{ width: '100px' }}
                    variant="outlined"
                    onClick={() => fetchFileHeader('refernce')}
                    disabled={disableForm}
                    className="outlined-button-color"
                  >
                    Fetch
                  </Button>
                </>
              )}
              {formField.format === 'csv' && (
                <>
                  <Radio.Group
                    style={{ width: '30vw' }}
                    options={plainOptions}
                    size="large"
                    name="DataTypeRadio"
                    onChange={onChangeDataType}
                    value={formField.dataTypeRead}
                    disabled={disableForm}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {formField.dataTypeRead === 'source' && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '70vw',
                        }}
                      >
                        {/* <ReadRefernce disableForm={disableForm} /> */}
                        <Box
                          component="form"
                          sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
                          noValidate
                          autoComplete="off"
                        >
                          <div>
                            <TextField id="Bucket" name="bucket" disabled value={bucketValue} />
                            <TextField
                              id="outlined-select-client"
                              select
                              disabled={disableForm}
                              required
                              label="Client"
                              name="client"
                              value={formField.clientId}
                              onChange={handleClientChange}
                            >
                              {AllClient &&
                                AllClient.map((ele) => {
                                  return (
                                    <MenuItem key={ele.client_id} value={`${ele.client_id}_____${ele.client_name}`}>
                                      {ele.client_name}
                                    </MenuItem>
                                  );
                                })}
                            </TextField>
                            <TextField
                              id="outlined-select-batch"
                              select
                              disabled={disableForm}
                              required
                              label="Batch"
                              name="batch"
                              value={formField.batchName}
                              onChange={handleBatchChange}
                            >
                              {AllBatch &&
                                AllBatch.map((ele) => {
                                  return (
                                    <MenuItem
                                      key={ele.engagement_id}
                                      value={`${ele.engagement_id}_____${ele.engagement_name}`}
                                    >
                                      {ele.engagement_name}
                                    </MenuItem>
                                  );
                                })}
                            </TextField>
                            <TextField
                              id="tableNameRead"
                              select
                              required
                              label="Jobs"
                              name="tableNameRead"
                              disabled={disableForm}
                              value={formField.tableNameRead}
                              onChange={handleTableChange}
                            >
                              {AllTable &&
                                AllTable.map((ele) => {
                                  return (
                                    <MenuItem key={ele.workflow_name} value={ele.workflow_name}>
                                      {ele.workflow_name}
                                    </MenuItem>
                                  );
                                })}
                            </TextField>
                            <TextField
                              id="track"
                              select
                              label="innerPath"
                              name="track"
                              value={formField.innerPath}
                              disabled={disableForm}
                              onChange={handleinnerPathChange}
                            >
                              <MenuItem value="RuleEngine">RuleEngine</MenuItem>
                              <MenuItem value="Data_Processor">DataProcessor</MenuItem>
                            </TextField>

                            <TextField
                              id="outlined-select-tracking"
                              select
                              disabled={disableForm}
                              required
                              label="Tracking Id"
                              name="tracking"
                              value={formField.trackingId}
                              onChange={handleTrackingChange}
                            >
                              {AllTracking &&
                                AllTracking.map((ele) => {
                                  return (
                                    <MenuItem key={ele.value} value={ele.value}>
                                      {ele.label}
                                    </MenuItem>
                                  );
                                })}
                            </TextField>
                            <TextField
                              id="file"
                              select
                              // required
                              label="Files"
                              name="file"
                              value={formField.file}
                              disabled={disableForm}
                              onChange={handleFileChange}
                            >
                              {AllFiles &&
                                AllFiles.map((ele) => {
                                  return (
                                    <MenuItem key={ele.value} value={ele.label}>
                                      {ele.label}
                                    </MenuItem>
                                  );
                                })}
                            </TextField>
                            <Button
                              style={{ width: '100px', marginTop: 30 }}
                              variant="outlined"
                              onClick={() => {
                                formField.path = `s3://${BUCKET_NAME}/${formField.trackingId}${formField.file}`;
                                fetchFileHeader('source');
                              }}
                              disabled={disableForm}
                              className="outlined-button-color"
                            >
                              Fetch
                            </Button>
                          </div>
                        </Box>
                      </div>
                    )}
                    {formField.dataTypeRead === 'refernce' && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          // border: '2px solid black',
                          width: '50vw',
                        }}
                      >
                        <TextField
                          name="path"
                          label="File Path"
                          value={formField.path}
                          onChange={handleInputChange}
                          size="small"
                          disabled={disableForm}
                          required
                          style={{ marginRight: 15, width: '80%' }}
                        />

                        <Button
                          style={{ width: '100px' }}
                          variant="outlined"
                          onClick={() => {
                            fetchFileHeader('refernce');
                          }}
                          disabled={disableForm}
                          className="outlined-button-color"
                        >
                          Fetch
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )}
              {formField.format === 'streaming' && (
                <>
                  <InputField
                    name="path"
                    label="File Path"
                    value={formField.path}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                    required
                  />

                  <Button
                    style={{ width: '100px' }}
                    variant="outlined"
                    onClick={fetchJsonFileHeader}
                    disabled={disableForm}
                    className="outlined-button-color"
                  >
                    Fetch
                  </Button>
                </>
              )}
              {formField.format === 'mysql' && (
                <>
                  <InputField
                    name="database"
                    label="Database Name"
                    value={formField.database}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                    required
                  />
                  <InputField
                    name="tablename"
                    label="Table Name"
                    value={formField.tablename}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                    required
                  />
                </>
              )}
            </Box>

            {formField.format === 'postgres' && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px 20px' }}>
                  <Autocomplete
                    disablePortal
                    id="flowbuilder-oracle"
                    value={formField.database}
                    disabled={disableForm}
                    onChange={(event, newValue) => {
                      setFormField({
                        ...formField,
                        database: newValue,
                      });
                      getPostgresSchema(newValue);
                    }}
                    options={postgresDatabases}
                    // getOptionLabel={(option) => (option.label ? option.label : '')}
                    size="small"
                    sx={{ width: '40%' }}
                    renderInput={(params) => <TextField {...params} label="Databases" required />}
                  />
                  <Autocomplete
                    disablePortal
                    id="flowbuilder-oracle"
                    value={formField.schema}
                    disabled={disableForm}
                    onChange={(event, newValue) => {
                      setFormField({
                        ...formField,
                        schema: newValue,
                      });
                      getPostgresTables(formField.database, newValue);
                    }}
                    options={postgresSchemas}
                    // getOptionLabel={(option) => (option.label ? option.label : '')}
                    size="small"
                    sx={{ width: '40%' }}
                    renderInput={(params) => <TextField {...params} label="Schemas" required />}
                  />
                  <Autocomplete
                    disablePortal
                    id="flowbuilder-oracle"
                    value={formField.tablename || null}
                    disabled={disableForm}
                    onChange={(event, newValue) => {
                      setFormField({
                        ...formField,
                        tablename: newValue,
                      });
                      getPostgresColumn(newValue);
                    }}
                    options={postgresTables}
                    // getOptionLabel={(option) => (option.label ? option.label : '')}
                    size="small"
                    sx={{ width: '40%' }}
                    renderInput={(params) => <TextField {...params} label="Tables " required />}
                  />
                </div>
              </>
            )}

            {formField.format === 'cosmos' && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px 20px' }}>
                  <Autocomplete
                    disablePortal
                    id="flowbuilder-oracle"
                    value={formField.database}
                    disabled={disableForm}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setFormField({
                        ...formField,
                        database: newValue.label,
                      });
                      fetchCosmosContainer(newValue.label);
                    }}
                    options={cosmosDatabases}
                    // getOptionLabel={(option) => (option.label ? option.label : '')}
                    size="small"
                    sx={{ width: '40%' }}
                    renderInput={(params) => <TextField {...params} label="Databases" required />}
                  />
                  <Autocomplete
                    disablePortal
                    id="flowbuilder-oracle"
                    value={formField.schema}
                    disabled={disableForm}
                    onChange={(event, newValue) => {
                      setFormField({
                        ...formField,
                        schema: newValue.label,
                      });
                      fetchCosmosColumns(formField.database, newValue.label);
                    }}
                    options={cosmosContainers}
                    // getOptionLabel={(option) => (option.label ? option.label : '')}
                    size="small"
                    sx={{ width: '40%' }}
                    renderInput={(params) => <TextField {...params} label="Containers" required />}
                  />
                </div>
              </>
            )}

            {loadingBtn && (
              <Box>
                <LoadingIcon />
              </Box>
            )}

            {!loadingBtn && fetchedHeader.length > 0 && (
              <TableContainer sx={{ maxHeight: 300 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={fetchedHeader.length === headerName.length}
                          onChange={handleSelectAllClick}
                        />
                        Select Columns
                      </TableCell>
                      <TableCell>Columns</TableCell>
                      <TableCell>Datatype</TableCell>
                      <TableCell>Alias</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchedHeader.map((row, i) => {
                      const isItemSelected = isSelected(row.header);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={i}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <Checkbox
                              checked={isItemSelected}
                              disabled={disableForm}
                              onChange={(event) =>
                                handleHeaderChange({
                                  header: row.header,
                                  alias: '',
                                  checked: event.target.checked,
                                  cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                  default_value: row.default_value,
                                  attribute_type: row.attribute_type,
                                })
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </TableCell>
                          <TableCell>{row.header}</TableCell>

                          <TableCell>
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Cast Datatype"
                              name="cast_datatype"
                              disabled={disableForm}
                              value={row.cast_datatype ? row.cast_datatype : 'string'}
                              onChange={(event) =>
                                handleHeaderChange({
                                  header: row.header,
                                  alias: row.alias,
                                  checked: true,
                                  cast_datatype: event.target.value,
                                  default_value: row.default_value,
                                  attribute_type: row.attribute_type,
                                })
                              }
                              style={{ width: '100%' }}
                              size="small"
                            >
                              <MenuItem value="int">Integer</MenuItem>
                              <MenuItem value="string">String</MenuItem>
                              <MenuItem value="float">Float</MenuItem>
                              <MenuItem value="timestamp">Timestamp</MenuItem>
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <TextField
                              name="alias"
                              label="Alias"
                              value={row.alias}
                              disabled={disableForm}
                              onChange={(e) =>
                                handleHeaderChange({
                                  header: row.header,
                                  alias: e.target.value,
                                  checked: true,
                                  cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                  default_value: row.default_value,
                                  attribute_type: row.attribute_type,
                                })
                              }
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 2, width: '32ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <Button
                disabled={disableForm}
                variant="contained"
                endIcon={<AddIcon />}
                onClick={handleAddColumnModalOpen}
              >
                Add Column
              </Button>
              <TextField
                id="outlined-basic"
                select
                label="Persist"
                variant="outlined"
                value={formField.persist}
                name="persist"
                onChange={handleInputChange}
                sx={{ mt: 2 }}
                size="small"
                disabled={disableForm}
                required
                InputProps={{
                  style: {
                    fontFamily: "'EB Garamond', serif ",
                    fontWeight: 600,
                  },
                }}
                InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
              >
                <MenuItem value>true</MenuItem>
                <MenuItem value={false}>false</MenuItem>
              </TextField>

              {formField.persist === true && (
                <TextField
                  id="outlined-basic"
                  select
                  label="Persist Type"
                  variant="outlined"
                  value={formField.persist_type}
                  name="persist_type"
                  onChange={handleInputChange}
                  sx={{ mt: 2 }}
                  size="small"
                  disabled={disableForm}
                  required
                >
                  <MenuItem value="DISK_ONLY">DISK_ONLY</MenuItem>
                  <MenuItem value="DISK_ONLY_2">DISK_ONLY_2</MenuItem>
                  <MenuItem value="MEMORY_ONLY">MEMORY_ONLY</MenuItem>
                  <MenuItem value="MEMORY_ONLY_2">MEMORY_ONLY_2</MenuItem>
                  <MenuItem value="MEMORY_AND_DISK">MEMORY_AND_DISK</MenuItem>
                  <MenuItem value="MEMORY_AND_DISK_2">MEMORY_AND_DISK_2</MenuItem>
                </TextField>
              )}
            </Box>
            {(formField.format === 'oracle' || formField.format === 'mysql' || formField.format === 'postgres') && (
              <>
                <Grid container spacing={2} sx={{ mx: 1 }}>
                  <Grid item xs={4}>
                    <Checkbox
                      disabled={disableForm}
                      name="pagination"
                      {...checklabel}
                      checked={formField.pagination}
                      onChange={handleInputChange}
                    />
                    Pagination
                  </Grid>
                  {formField.pagination === true && (
                    <>
                      <Grid item xs={4}>
                        <TextField
                          name="numPartitions"
                          label="Number Partition"
                          value={formField.numPartitions}
                          onChange={handleInputChange}
                          size="small"
                          type="number"
                          disabled={disableForm}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="partitionColumn"
                          label="Partition Column"
                          value={formField.partitionColumn}
                          onChange={handleInputChange}
                          size="small"
                          type="number"
                          disabled={disableForm}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="lowerBound"
                          label="LowerBound"
                          value={formField.lowerBound}
                          onChange={handleInputChange}
                          size="small"
                          type="number"
                          disabled={disableForm}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="upperBound"
                          label="UpperBound"
                          value={formField.upperBound}
                          onChange={handleInputChange}
                          size="small"
                          type="number"
                          disabled={disableForm}
                          required
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
            <Grid container spacing={2} sx={{ mx: 1 }}>
              <Grid item xs={4}>
                <InputField
                  name="action"
                  label="Action"
                  value={formField.action}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Distinct Rows"
                  size="small"
                  value={formField.distinct_rows}
                  onChange={(event) => {
                    setFormField({
                      ...formField,
                      distinct_rows: event.target.value,
                    });
                  }}
                  disabled={disableForm}
                  style={{ width: '100%', marginTop: '15px' }}
                >
                  <MenuItem value="true">true</MenuItem>
                  <MenuItem value="false">false</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </div>
          <Modal
            open={addColumnModalOpen}
            onClose={handleAddColumnModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Radio.Group
                options={attributeOptions}
                size="large"
                name="DataTypeRadio"
                onChange={(e) => setAttributeType(e.target.value)}
                value={attributeType}
                disabled={disableForm}
              />
              <InputField
                name="header"
                label="Attribute"
                onChange={(e) => setAddColumn(e.target.value)}
                size="small"
                required
              />
              <InputField
                name="default_value"
                label="Default Value"
                onChange={(e) => setDefaultValue(e.target.value)}
                size="small"
                required
              />

              <Button
                variant="contained"
                sx={{ mt: 2 }}
                color="secondary"
                type="submit"
                className="button-color"
                onClick={handleAddColumn}
              >
                Add
              </Button>
            </Box>
          </Modal>
        </form>
      </Dialog>
    </div>
  );
};

export default memo(NodeModalReadCosmos);
