import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom/dist';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import RedChip from '../../reusable-component/RedChip';
import GreenChip from '../../reusable-component/GreenChip';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import { getAWSLogstreams } from "../../api's/LogsApi";
import { convertToCamelCase } from '../../utils/stringConversion';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import YellowChip from '../../reusable-component/YellowChip';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
    '&:first-of-type': {
      maxWidth: '30vw',
      wordBreak: 'break-all',
    },
    textAlign: 'center',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function AWSLogsTable({ headerTableData, processType }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { workflow_type, client_name, engagement_name, workflow_name } = headerTableData;
  const workFlowType = convertToCamelCase(workflow_type);
  useEffect(() => {
    const fetchAWSLogsreams = async () => {
      const data = {
        workflow_type,
        prefix: `${client_name}_${engagement_name}_${workflow_name}_${workFlowType}`,
      };
      try {
        setLoading(true);
        const response = await getAWSLogstreams(data);
        if (response.status === 200) {
          setTableData(response.data);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.status === 500) {
          enqueueSnackbar('500 Internal Server Error!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (error.response?.status === 404) {
          enqueueSnackbar('404 No Logs found!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else {
          enqueueSnackbar('Something went wrong!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
      setLoading(false);
    };

    fetchAWSLogsreams();
  }, []);

  const handleDetailedLogs = (logstream) => {
    navigate(`/workflow-${processType}/logs/detailed-logs`, {
      state: {
        workflow_type: headerTableData.workflow_type,
        prefix: logstream,
      },
    });
  };
  return (
    <Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          style={{ minWidth: 650, borderSpacing: '0px 4px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Stream Name</StyledTableCell>
              <StyledTableCell>Timestamp</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length <= 0
              ? !loading && 'No logs found'
              : tableData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.log_stream_name}</StyledTableCell>
                    <StyledTableCell>{row.timestamp}</StyledTableCell>
                    <StyledTableCell>
                      {row.status === 'Completed' ? (
                        <GreenChip title="Completed" />
                      ) : row.status === 'Running' ? (
                        <YellowChip title="Running" />
                      ) : (
                        <RedChip title="Failed" />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => handleDetailedLogs(row.log_stream_name)}
                        variant="contained"
                        size="small"
                        sx={{ background: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
                      >
                        Detailed Logs
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationFooter tableData={tableData} page={page} handleChangePage={handleChangePage} />
      {loading && <LoadingIcon />}
    </Box>
  );
}
