import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import { CLIENT_PAGE, CONNECTIONS_PAGE } from '../utils/commonConstants';
import ConnectionsTable from '../components/conections/ConnectionsTable';
import SearchAndFilter from '../reusable-component/SearchAndFilter';
import ConnectionModal from '../reusable-component/ConnectionModal';

const ConnectionsPage = () => {
  const [search, setSearch] = useState('');
  const [user, setUser] = useState('');
  const [type, setType] = useState('');
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [openConnectionModal, setOpenConnectionModal] = useState(false);

  const handleOnChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearch = () => {
    console.log('Hii');
  };

  const handleType = (event) => {
    setType(event.target.value);
  };

  const handleUser = (event) => {
    setUser(event.target.value);
  };

  const handleClearFilter = () => {
    setUser('');
    setType('');
    // fetchAllEngagementsTableData();
  };

  const handleConnectionModal = () => {
    setOpenConnectionModal(!openConnectionModal);
  };

  const tableData = [
    {
      connection_id: '802fcc1c-7984-427f-b715-79f9ea8ab679',
      connection_name: 'John Doe',
      connection_type: 'AWS',
      created_by: 'John Doe',
      created_at: '2023-02-24T04:21:48.506Z',
      last_updated_by: 'John Doe',
      updated_on: '2023-02-24T04:21:48.506Z',
    },
    {
      connection_id: '7d09f7e2-603e-4d09-8490-1b7c14d9feeb',
      connection_name: 'karen@gmail.com',
      connection_type: 'Snowflake',
      created_by: 'John Doe',
      created_at: '2023-02-24T04:21:48.506Z',
      last_updated_by: 'John Doe',
      updated_on: '2023-02-24T04:21:48.506Z',
    },
  ];

  useEffect(() => {
    // fetchTableData();
    setFilteredTableData(tableData);
  }, []);

  useEffect(() => {
    type
      ? setFilteredTableData(
          tableData.filter((tableData) => tableData.created_by === user && tableData.connection_type === type)
        )
      : user
      ? setFilteredTableData(tableData.filter((tableData) => tableData.created_by === user))
      : setFilteredTableData(tableData);
  }, [user]);

  useEffect(() => {
    user
      ? setFilteredTableData(
          tableData.filter((tableData) => tableData.connection_type === type && tableData.created_by === user)
        )
      : type
      ? setFilteredTableData(tableData.filter((tableData) => tableData.connection_type === type))
      : setFilteredTableData(tableData);
  }, [type]);

  useEffect(() => {
    setFilteredTableData(tableData.filter((tableData) => tableData.connection_name.toLowerCase().startsWith(search.toLowerCase())));
  }, [search]);

  const data = {
    search,
    handleSearch,
    handleOnChange,
    region: type,
    handleRegion: handleType,
    handleClearFilter,
    handleStatus: handleUser,
    status: user,
    firstFilterText: CONNECTIONS_PAGE.connectionsFilterByType,
    secondFilterText: CONNECTIONS_PAGE.connectionsFilterByUser,
    clearFilterText: CLIENT_PAGE.clientClearFilterButton,
    firstFilterValues: {
      first: 'AWS',
      second: 'Snowflake',
    },
    secondFilterValues: {
      filterData: tableData.map((item) => {
        return item.created_by;
      }),
    },
    isFirstFilterVisible: true,
  };

  return (
    <>
      <Grid container direction="row" display="flex">
        <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
          {CONNECTIONS_PAGE.connectionsPageTitle}
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
            onClick={handleConnectionModal}
          >
            <MdAdd />
            {CONNECTIONS_PAGE.addNewconnectiontButton}
          </Button>
        </Grid>
      </Grid>
      {tableData.length > 0 && <SearchAndFilter data={data} />}
      <ConnectionsTable tableData={filteredTableData} />
      <ConnectionModal openConnectionModal={openConnectionModal} handleConnectionModal={handleConnectionModal} />
    </>
  );
};

export default ConnectionsPage;
