import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { MdDelete } from 'react-icons/md';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RenderHtml from '../RenderHtml';
import UploadFiles from './UploadFiles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  height: 200,
  paddingLeft: '40px',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function UploadModal({
  openModal,
  handleCloseModal,
  type,
  metadata,
  language,
  clientNameTTD,
  engagementNameTTD,
  workflowNameTTD,
}) {
  return (
    <div>
      <Modal
        keepMounted
        open={openModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="expand row"
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              handleCloseModal();
            }}
            sx={{ marginLeft: '32vw' }}
          >
            <CloseIcon />
          </IconButton>
          {/* <RenderHtml htmlData={htmlData} nodeId={nodeId}/> */}
          <UploadFiles
            handleCloseModal={handleCloseModal}
            type={type}
            metadata={metadata}
            language={language}
            clientNameTTD={clientNameTTD}
            engagementNameTTD={engagementNameTTD}
            workflowNameTTD={workflowNameTTD}
          />
        </Box>
      </Modal>
    </div>
  );
}
