import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  pageTitle: {
    color: '#43425D',
  },
  pageText: {
    color: '#444444',
  },
  divider: {
    width: '130px',
    marginBottom: '13px',
  },
  paper: {
    minHeight: '330px',
    marginTop: '15px',
    padding: '20px',
  },
  paperTitle: {
    color: '#8798AD',
  },
  formInputField: {
    marginTop: '15px',
    color: '#8798AD',
    fontSize: '11px',
  },
  formTextField: {
    backgroundColor: '#2E5BFF14',
  },
  formControl: {
    width: '23.1%',
  },
  formSelect: {
    backgroundColor: '#2E5BFF14',
    // height: '40px',
    marginTop: '8px',
  },
  previousAndCancelBtn: {
    marginRight: '7px',
    backgroundColor: '#ffffff',
    '&:hover': { backgroundColor: '#ffffff' },
    color: '#444444',
    border: '1px solid black',
  },
  saveAndNextBtn: {
    backgroundColor: '#00043C',
    '&:hover': { backgroundColor: '#00043C' },
  },
});

export default useStyles;
