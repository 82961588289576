import React, { useContext, useState } from 'react';
import { Button } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthProvider';
import AccessModal from './AccessModal';

export default function AddButton({ text, route }) {
  const navigate = useNavigate();

  const [openAccessModal, setOpenAccessModal] = useState(false);
  const { userRole } = useContext(AuthContext);

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  const handleRoute = () => {
    if (userRole === 'READER') {
      handleAccessModal();
    } else {
      navigate(route);
    }
  };

  return (
    <>
      <Button
        // size="small"
        type="submit"
        variant="contained"
        sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
        onClick={handleRoute}
      >
        <MdAdd />
        {text}
      </Button>
      <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
    </>
  );
}
