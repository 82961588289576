import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import AddDynamicField from './AddDynamicField';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function FetchModal({
  openFetchModal,
  handleCloseFetchModal,
  getTableRules,
  isUpdate,
  fileNames,
  setFileNames,
  pathName,
  setInitialTableRule,
  setUpdateTableRule,
  setUpdateTableRuleFields,
}) {
  return (
    <BootstrapDialog aria-labelledby="customized-dialog-title" open={openFetchModal}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseFetchModal}>
        Fetch Schema
      </BootstrapDialogTitle>
      <DialogContent>
        <AddDynamicField
          getTableRules={getTableRules}
          fileNames={fileNames}
          setFileNames={setFileNames}
          pathName={pathName}
          setInitialTableRule={setInitialTableRule}
          setUpdateTableRule={setUpdateTableRule}
          setUpdateTableRuleFields={setUpdateTableRuleFields}
        />
      </DialogContent>
    </BootstrapDialog>
  );
}
