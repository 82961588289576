import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import './domainTable.css';
import { DateConverter } from '../../utils/DateConverter';
import RedChip from '../../reusable-component/RedChip';
import GreenChip from '../../reusable-component/GreenChip';
import YellowChip from '../../reusable-component/YellowChip';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import BasicMenu from '../../reusable-component/BasicMenu';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { AuthContext } from '../../contexts/AuthProvider';
import {
  getAllClientByDomainIdApi,
  getAllClientsApi,
  getClientByUserGroup,
  getClientByUserGroupAndDomainIdApi,
} from "../../api's/ClientApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
    textAlign: 'center',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function DomainTable({ tableData, fetchTableData, currentPage, setPage }) {
  const { userRole, userGroup, domainId, domainName, setDomainId, setDomainName } = useContext(AuthContext);
  const [currentSelectedDomainId, setCurrentSelectedDomainId] = useState(domainId);
  // const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const rowsPerPage = 5;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const SetDomain = (row) => {
    setDomainId(row.domain_id);
    setCurrentSelectedDomainId(row.domain_id);
    setDomainName(row.domain_name);
    sessionStorage.setItem('domainId', JSON.stringify(row.domain_id));
    sessionStorage.setItem('domainName', JSON.stringify(row.domain_name));
  };

  const RemoveDomain = () => {
    setDomainId(null);
    setDomainName(null);
    setCurrentSelectedDomainId(null);
    sessionStorage.removeItem('domainId');
    sessionStorage.removeItem('domainName');
  };

  const handleToggleDomain = (row) => {
    if (currentSelectedDomainId === row.domain_id) {
      RemoveDomain();
      return;
    }
    SetDomain(row);
  };

  const navigateToDomain = async (row) => {
    SetDomain(row);
    sessionStorage.removeItem('filtered_domaindetails_search');
    sessionStorage.removeItem('filtered_domaindetails_status');
    setLoading(true);
    try {
      let response;

      if (userRole === 'SUPER_USER' && row.domain_id !== null) {
        response = await getAllClientByDomainIdApi(row.domain_id);
      } else if (userRole === 'SUPER_USER' && row.domain_id === null) {
        response = await getAllClientsApi();
      } else if (userRole !== 'SUPER_USER' && row.domain_id !== null) {
        response = await getClientByUserGroupAndDomainIdApi(userGroup, row.domain_id);
      }

      if (response.status === 200) {
        navigate('/domain/view_details', {
          state: {
            domain_id: row.domain_id,
            domain_name: row.domain_name,
            created_by: row.created_by,
            creation_date: row.creation_date,
            status: row.status,
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Client not found for this domain!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }

    setLoading(false);
  };

  return (
    <Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          sx={{
            minWidth: 650,
            borderSpacing: '0px 4px',
            borderCollapse: 'separate',
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Domain Name</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow
                  key={row.domain_id}
                  class="rowHover"
                  style={{
                    backgroundColor: `${row.domain_name === domainName ? '#e6f4fc' : ''}`,
                    border: `${row.domain_name === domainName ? '1px solid red' : ''}`,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleToggleDomain(row)}
                >
                  <StyledTableCell>
                    {row.status === 'approved' ? (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigateToDomain(row);
                        }}
                        sx={{ textDecoration: 'none', color: '#1976D2' }}
                      >
                        {row.domain_name}
                      </Link>
                    ) : (
                      row.domain_name
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{DateConverter(row.creation_date)}</StyledTableCell>
                  <StyledTableCell>{row.created_by}</StyledTableCell>
                  <StyledTableCell>
                    {row.status === 'approved' ? (
                      <GreenChip title="Approved" />
                    ) : row.status === 'pending' ? (
                      <YellowChip title="Pending" />
                    ) : (
                      <RedChip title="Rejected" />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <BasicMenu row={row} fetchData={fetchTableData} type="Domain" />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationFooter tableData={tableData} page={currentPage} handleChangePage={handleChangePage} />
      {loading && <LoadingIcon />}
    </Box>
  );
}
