import React, { useState, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createStyles, withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { DateConverter } from '../../utils/DateConverter';
import GreenChip from '../../reusable-component/GreenChip';
import RedChip from '../../reusable-component/RedChip';
import YellowChip from '../../reusable-component/YellowChip';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import DeleteModal from '../../reusable-component/DeleteModal';
import BasicMenu from '../../reusable-component/BasicMenu';
import { getWorkflowByUserGroupAndEngagementIdApi } from "../../api's/WorkflowApi";
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { AuthContext } from '../../contexts/AuthProvider';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
    textAlign: 'center',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function AllEngagementsTable({ tableData, fetchAllEngagementsTableData, currentPage, setPage }) {
  const { userGroup } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const [loading, setLoading] = useState(false);
  const [engagementRow, setEngagementRow] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    handleClose();
  };

  const navigateToEngagement = async (row) => {
    setLoading(true);
    sessionStorage.removeItem('filtered_engagementdetails_search');
    sessionStorage.removeItem('filtered_engagementdetails_status');
    const group = userGroup;
    try {
      const response = await getWorkflowByUserGroupAndEngagementIdApi(group, row.engagement_id);
      if (response.status === 200) {
        navigate('/engagement/view_details', {
          state: {
            engagement_id: row.engagement_id,
            engagement_name: row.engagement_name,
            client_name: row.client_name,
            domain_name: row.domain_name,
            workflow_count: row.workflow_count,
            workflow_name: row.workflow_name,
            execution_mode: row.execution_mode,
            created_by: row.created_by,
            created_at: row.created_at,
            status: row.status,
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  return (
    <Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          style={{ minWidth: 650, borderSpacing: '0px 4px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Engagement Name</StyledTableCell>
              <StyledTableCell>Client Name</StyledTableCell>
              <StyledTableCell>Total Workflow</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow key={row.engagement_id}>
                  <StyledTableCell>
                    {row.status === 'approved' ? (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          navigateToEngagement(row);
                        }}
                        sx={{ textDecoration: 'none', color: '#1976D2' }}
                      >
                        {row.engagement_name}
                      </Link>
                    ) : (
                      row.engagement_name
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row.client_name}</StyledTableCell>
                  <StyledTableCell>{row.workflow_count}</StyledTableCell>
                  <StyledTableCell>{row.created_by}</StyledTableCell>
                  <StyledTableCell>{DateConverter(row.created_at)}</StyledTableCell>

                  <StyledTableCell>
                    {row.status === 'approved' ? (
                      <GreenChip title="Approved" />
                    ) : row.status === 'pending' ? (
                      <YellowChip title="Pending" />
                    ) : (
                      <RedChip title="Rejected" />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <BasicMenu row={row} fetchData={fetchAllEngagementsTableData} type="Engagement" />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationFooter tableData={tableData} page={currentPage} handleChangePage={handleChangePage} />
      {loading && <LoadingIcon />}
    </Box>
  );
}
