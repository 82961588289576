import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

export default function AccessModal({ handleAccessModal, openAccessModal }) {
  return (
    <div>
      <Dialog
        open={openAccessModal}
        onClose={handleAccessModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: '#00043C' }}>
          You don't have permission to perform this action!!
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleAccessModal} sx={{ color: '#00043C', border: '1px solid #43425D' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AccessModal.propTypes = {
  openAccessModal: PropTypes.bool,
  handleAccessModal: PropTypes.func,
};
