import axios from 'axios';
import BASEURL from '../BaseUrl';

export const largefileUploaderApi = async (formData) => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  try {
    const response = await axios.post(`${BASEURL}/data-processor/file-upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${parseToken}`,
      },
    });
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const getListOfLargeFileMetadataApi = async (filepath, cloudEnvironment) => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  const response = await axios.post(
    `${BASEURL}/directory/directory-content`,
    { filepath, cloudEnvironment },
    {
      headers: { Authorization: `Bearer ${parseToken}` },
    }
  );
  return response;
};

export const downloadLargeFileApi = async (file_path, cloudEnvironment) => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  await axios
    .get(`${BASEURL}/business-intelligence/download-file`, {
      params: {
        file_path,
        cloudEnvironment,
      },
      responseType: 'blob',
      headers: { Authorization: `Bearer ${parseToken}` },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file_path.split('/').at(-1)}`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    });
};
