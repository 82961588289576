import { Chip } from '@mui/material';
import React from 'react';

const GreenChip = ({ title }) => {
  return (
    <Chip
      label={title}
      size="small"
      style={{
        color: '#198400',
        fontSize: '10px',
        backgroundColor: '#DFF2DF',
        fontWeight: 'bold',
      }}
    />
  );
};

export default GreenChip;
