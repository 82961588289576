import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  FormControl,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { getAllClientsApi } from "../api's/ClientApi";
import { createEngagementApi } from "../api's/EngagementApi";
import { ENGAGEMENT_PAGE } from '../utils/commonConstants';
import { AuthContext } from '../contexts/AuthProvider';

export default function AddEngagementModal({ handleCloseModal, openModal, fetchData }) {
  const { userId } = useContext(AuthContext);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedEngagement, setSelectedEngagement] = useState('');
  const [clientData, setClientData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [engagementName, setEngagementName] = useState('');
  const [clientName, setClientName] = useState('');
  const [engagementNameError, setEngagementNameError] = useState('');

  // const nameRegex = /^\S{1,35}$/;
  const nameRegex = /^[a-zA-Z0-9 ]{1,35}$/;

  const handleChange = (event, source) => {
    switch (source) {
      case 'clientName': {
        const newValue = event.target.value;

        setClientName(newValue);

        break;
      }
      case 'engagementName': {
        const newValue = event.target.value;

        setEngagementName(newValue);
        if (newValue.trim() === '') {
          setEngagementNameError('Engagement Name is required.');
        } else if (!newValue.match(nameRegex)) {
          setEngagementNameError(`Invalid Engagement Name Format "${newValue}". NO SPECIAL CHARACTER ALLOWED.`);
        } else {
          setEngagementNameError('');
        }

        break;
      }

      default:
        break;
    }
  };

  const handleOnClickAdd = () => {
    if (engagementName.match(nameRegex)) {
      if (clientName) {
        createEngagement();
      } else {
        enqueueSnackbar('Client Name not selected.', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } else {
      enqueueSnackbar(`INVALID ENGAGEMENT NAME FORMAT:${engagementName}.`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleOnCancel = () => {
    handleCloseModal();
    setClientName('');
    setEngagementName('');
    setEngagementNameError('');
  };

  const createEngagement = async () => {
    const selectedClientId = clientData.find((item) => item.client_name === clientName).client_id;
    const data = {
      engagement_name: engagementName,
      engagement_type: 'daily',
      user_id: userId,
      client_id: selectedClientId,
    };
    try {
      const response = await createEngagementApi(data);
      if (response.status === 201) {
        enqueueSnackbar('Engagement added successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleOnCancel();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 409) {
        enqueueSnackbar('Engagement with the same name already exists!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const fetchAllClients = async () => {
    const response = await getAllClientsApi();
    try {
      if (response.status === 200) {
        const approvedClients = response.data.filter((el) => el.status === 'approved');
        setClientData(approvedClients);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        enqueueSnackbar('No data found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response.status === 500) {
        enqueueSnackbar(error.response.data || 'Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  useEffect(() => {
    if (openModal) {
      fetchAllClients();
    }
  }, [openModal]);

  useEffect(() => {
    setEngagementNameError('');
  }, []);

  return (
    <Dialog
      open={openModal}
      onClose={handleOnCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ color: '#43425D' }}> {ENGAGEMENT_PAGE.addEngagementModal}</DialogTitle>
      <DialogContent>
        <Box component="form" display="flex" justifyContent="space-between" gap="10px" alignItems="end" width="450px">
          <FormControl variant="standard" sx={{ minWidth: 220 }}>
            <Typography
              variant="p"
              component="p"
              display="flex"
              alignContent="flex-start"
              sx={{ color: '#8798AD', fontSize: '11px' }}
            >
              Engagement Name*
            </Typography>
            <TextField
              size="small"
              required
              id="engagementName"
              name="engagementName"
              autoComplete="engagementName"
              variant="outlined"
              value={engagementName}
              error={!!engagementNameError}
              helperText={engagementNameError}
              onChange={(e) => handleChange(e, 'engagementName')}
              sx={{ background: '#2E5BFF14', mt: 0.5 }}
            />
          </FormControl>
          <FormControl sx={{ minWidth: 220 }}>
            <Typography
              variant="p"
              component="p"
              display="flex"
              alignContent="flex-start"
              sx={{ color: '#8798AD', fontSize: '11px' }}
            >
              Client*
            </Typography>
            <Select
              size="small"
              labelId="clientName"
              id="clientName"
              value={clientName}
              onChange={(e) => handleChange(e, 'clientName')}
              input={<OutlinedInput />}
              sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
            >
              {clientData.map((item) => (
                <MenuItem key={item.client_id} value={item.client_name}>
                  {item.client_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 2 }}>
        <Button
          onClick={handleOnCancel}
          variant="outlined"
          sx={{ textTransform: 'capitalize', color: '#43425D', border: '1px solid #43425D' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOnClickAdd}
          variant="contained"
          sx={{ backgroundColor: '#00043C', textTransform: 'capitalize', '&:hover': { backgroundColor: '#00043C' } }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
