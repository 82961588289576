import { Button, Grid } from '@mui/material';
import { Col, Row } from 'antd/es';
import html2pdf from 'html2pdf.js';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { fetchHtmlJsonApi, fetchSampleRecordsApi } from "../api's/DataProcessorApi";
import { deleteChartsData, getTileSequence, getTilesData, upsertTileSequence } from "../api's/ReportingApi";
import { AuthContext } from '../contexts/AuthProvider';
import AccessModal from '../reusable-component/AccessModal';
import AddChartsModal from '../reusable-component/AddChartsModal';
import LoadingIcon from '../reusable-component/LoadingIcon';
import RearrangeModal from '../reusable-component/RearrangeModal';
import BarChart from '../reusable-component/charts/BarChart';
import HistogramChart from '../reusable-component/charts/HistogramChart';
import LineChart from '../reusable-component/charts/LineChart';
import PieChart from '../reusable-component/charts/PieChart';
import ReportTable from '../reusable-component/reports/ReportTable';
import './ReportPage.css';
import StatCards from '../reusable-component/stats/StatCards';
import DoughnutChart from '../reusable-component/charts/DoughnutChart';

const ReportingPage = ({ headerTableData }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userRole, userGroup } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [openRearrangeModal, setOpenRearrangeModal] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const { workflow_data: workflow } = headerTableData;
  const clientName = workflow?.engagement?.client?.client_name;
  const engagementName = workflow?.engagement?.engagement_name;
  const [allTilesDataSet, setAllTilesDataSet] = useState([]);

  const [singleValuedata, setSingleValuedata] = useState([
    { title: 'Total Sales of Product A, Product B', content: '312', color: '#FF4D4F' },
    { title: 'Total Count of all products', content: '89', color: '#52C41A' },
    { title: 'Year 2023 revenue', content: '74', color: '#1677FF' },
    { title: 'Total Sales of Product D', content: '32', color: '#FF4D4F' },
    { title: 'Total Count of all products', content: '93', color: '#1677FF' },
    { title: 'Total Count of all products', content: '44', color: '#52C41A' },
  ]);

  const getAllCharts = async () => {
    setLoading(true);
    try {
      const response = await getTilesData(workflow.workflow_id);

      if (response.status === 200) {
        const allTilesData = [];

        await Promise.all(
          response.data.map(async (item) => {
            let tileResponse;
            // console.log({ item });
            if (item.tile_type === 'graph') {
              const pathType = `${item.path}/CustomerSegmentation.json`;
              tileResponse = await fetchHtmlJsonApi(pathType, AWS_CONFIG_TYPE, workflow.engagement.client.client_id);
            } else if (item.tile_type === 'report') {
              const payload = {
                client_id: workflow.engagement.client.client_id,
                client_name: workflow.engagement.client.client_name,
                engagement_id: workflow.engagement.engagement_id,
                engagement_name: workflow.engagement.engagement_name,
                workflow_id: item.report_workflow_id,
                workflow_name: item.path.split('/')[2],
                csvPath: item.path,
                bucket_name: workflow.engagement.client.bucket_name,
                data_region: workflow.engagement.client.data_region,
                aws_config_type: AWS_CONFIG_TYPE,
              };
              tileResponse = await fetchSampleRecordsApi(payload);
            }

            if (tileResponse.status === 200) {
              allTilesData.push({
                rg_id: item.rg_id,
                data: tileResponse.data,
                chart_type: item.chart_type,
                path: item.path,
                title: item.tile_title,
                report_workflow_id: item.report_workflow_id,
                tile_id: item.tile_id,
                tile_property: item.tile_property,
                tile_color: item.tile_color,
              });
            }
          })
        );

        try {
          const sequence_response = await getTileSequence(workflow.workflow_id);
          if (sequence_response.status === 200) {
            const sequence = sequence_response.data.tile_sequence;
            const sequenceMap = new Map(sequence.map((rg_id, index) => [rg_id, index]));
            allTilesData.sort((a, b) => sequenceMap.get(a.rg_id) - sequenceMap.get(b.rg_id));
            setAllTilesDataSet(allTilesData);
          } else {
            allTilesData.sort((a, b) => a.rg_id - b.rg_id);
            setAllTilesDataSet(allTilesData);
          }
        } catch (error) {
          if (error.sequence_response?.status === 204) {
            console.log(error);
            enqueueSnackbar('Sequence Not Found!', {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }
        }

        // allTilesData.sort((a, b) => a.rg_id - b.rg_id);
        // setAllTilesDataSet(allTilesData);
      }
      if (response?.status === 204) {
        setAllTilesDataSet([]);
        enqueueSnackbar('Tiles Not Found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 404) {
        setAllTilesDataSet([]);
        enqueueSnackbar('404 Tiles Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong report!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllCharts();
  }, []);

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenRearrangeModal(false);
  };

  const handleOpenModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenModal(true);
    }
  };

  const handleOpenRearrangeModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenRearrangeModal(true);
    }
  };

  const handleDeleteTile = async (rg_id) => {
    setLoading(true);
    try {
      const response = await deleteChartsData(rg_id);
      if (response.status === 409 || response.status === 200) {
        // getAllCharts();
        setAllTilesDataSet((prevTiles) => prevTiles.filter((tile) => tile.rg_id !== rg_id));
      }
    } catch (error) {
      enqueueSnackbar('Internal Server Error!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  const handleOnSaveSequence = async (new_rg_id, data) => {
    setLoading(true);
    try {
      const sequence = [];
      console.log('new_rg_id:', new_rg_id);
      if (new_rg_id) {
        console.log('if block new_rg_id :', new_rg_id);
        allTilesDataSet?.map((item) => sequence.push(item.rg_id));
        sequence.push(new_rg_id);
      } else {
        data?.map((item) => sequence.push(item.rg_id));
      }

      const payload = {
        workflow: {
          workflow_id: workflow?.workflow_id,
        },
        tile_sequence: sequence,
      };

      const response = await upsertTileSequence(payload);
      if (response.status === 201 || response.status === 200) {
        getAllCharts();
        setLoading(false);
        enqueueSnackbar('Sequence saved!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } catch (error) {
      // handleOnCancel();
      setLoading(false);
      console.log('error:', error);
      enqueueSnackbar('Internal Server Error Save Sequence!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };
  const componentRef = useRef();

  const exportToPDF = () => {
    const input = componentRef.current;

    html2pdf(input, {
      margin: 10,
      filename: 'report.pdf',
      html2canvas: {
        scale: 2,
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'landscape',
      },
    });
  };

  return (
    <div>
      <Grid container direction="row" display="flex" style={{ marginBottom: '8px' }}>
        <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
          Insights Dashboard
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
            onClick={handleOpenModal}
          >
            <MdAdd />
            Add New Tile
          </Button>
          {allTilesDataSet.length > 0 && (
            <>
              <Button
                type="submit"
                variant="contained"
                sx={{ mx: 2, backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
                onClick={handleOpenRearrangeModal}
              >
                Rearrange
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
                onClick={() => exportToPDF()}
              >
                Export to PDF
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      {allTilesDataSet.length > 0 && (
        <div ref={componentRef} className="report-page">
          <div className="report-title">
            <strong>{workflow.workflow_name}</strong>
          </div>
          <div style={{ padding: '8px' }}>
            <StatCards
              allTilesDataSet={allTilesDataSet}
              setData={setSingleValuedata}
              handleDeleteTile={handleDeleteTile}
            />
          </div>
          <div>
            <Row gutter={[16, 16]} justify="center" align="middle">
              {allTilesDataSet
                ?.filter((item) => item.chart_type !== 'Stats')
                ?.map((item) => (
                  <Col key={item.rg_id} xl={12} lg={12} md={24} sm={24} xs={24} justify="center" align="middle">
                    {item.chart_type === 'Bar Chart' && <BarChart props={item} handleDeleteTile={handleDeleteTile} />}
                    {item.chart_type === 'Pie Chart' && <PieChart props={item} handleDeleteTile={handleDeleteTile} />}
                    {item.chart_type === 'Doughnut Chart' && (
                      <DoughnutChart props={item} handleDeleteTile={handleDeleteTile} />
                    )}
                    {item.chart_type === 'Line Chart' && <LineChart props={item} handleDeleteTile={handleDeleteTile} />}
                    {item.chart_type === 'Histograms' && (
                      <HistogramChart props={item} handleDeleteTile={handleDeleteTile} />
                    )}
                    {item.chart_type === 'Table' && (
                      <ReportTable
                        data={item.data}
                        rg_id={item.rg_id}
                        path={item.path}
                        handleDeleteTile={handleDeleteTile}
                        initialTitle={item.title}
                        report_workflow_id={item.report_workflow_id}
                        tile_id={item.tile_id}
                      />
                    )}
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      )}
      {openModal && (
        <AddChartsModal
          handleCloseModal={handleCloseModal}
          openModal={openModal}
          getAllCharts={getAllCharts}
          workflow={workflow}
          handleOnSaveSequence={handleOnSaveSequence}
        />
      )}
      {openRearrangeModal && (
        <RearrangeModal
          dataset={allTilesDataSet}
          handleCloseModal={handleCloseModal}
          openRearrangeModal={openRearrangeModal}
          getAllCharts={getAllCharts}
          handleOnSaveSequence={handleOnSaveSequence}
        />
      )}

      <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
      {loading && <LoadingIcon />}
    </div>
  );
};

export default ReportingPage;
