import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { getDirectoryData, getSelectedFileData } from "../../api's/ChatGPTApi";
import { getDataProcessor } from "../../api's/DataProcessorApi";
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import LoadingIcon from '../LoadingIcon';

function ReportLLM({ report_workflow_id, tile_path }) {
  const { enqueueSnackbar } = useSnackbar();
  const [sqlText, setSqlText] = useState('');
  const [final_rows, setfinal_rows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [sqlQuery, setSqlQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const [path, setPath] = useState('');
  const [expanded, setExpanded] = React.useState();
  const [newData, setNewData] = useState('');
  const [newDataRows, setNewDataRows] = useState([]);
  const [csvPath, setCsvPath] = useState('');
  const [workflowData, setWorkflowData] = useState();

  const { workflow } = useContext(WorkflowContext);

  const getCsvPath = async () => {
    try {
      const response = await getDataProcessor(report_workflow_id, 'Batch');
      if (response.status === 200) {
        const pathNew = `dndnode_${tile_path.split('/')[5].split('_')[1]}`;
        const data_set = response.data.form_data.filter((el) => el.step_name === 'Write' && el.nodeId === pathNew);

        const write_path = data_set[0]?.formField?.path;

        let str = write_path?.replace(/^[^/]*\//, '');
        if (str.endsWith('/')) {
          str = str.slice(0, -1);
        }

        const data = {
          filepath: str,
          cloudEnvironment: workflow.cloudEnvironment,
        };
        const directory_response = await getDirectoryData(data);
        if (directory_response.status === 200) {
          const csvObject = directory_response.data.find((obj) => obj.filename.endsWith('.csv'));
          const csvPath = AWS_CONFIG_TYPE
            ? `s3://dep-qa/${csvObject.filepath}`
            : `https://depazuredev.blob.core.windows.net/dep-develop/${csvObject.filepath}`;
          setPath(csvPath);
        }
        // const data_set1 = response.data.form_data.filter((el) => el.step_name === 'Write');
        // const data_set2 = response.data.form_data.filter((el) => el.step_name === 'Ingestion Write');
        // if (data_set2.length > 0) {
        //   setIngestionWriteTable(data_set2[0].sorting);
        //   setWriteTable([]);
        // } else {
        //   setIngestionWriteTable([]);
        //   setWriteTable(data_set1);
        // }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error while fetching CSV path!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    getCsvPath();
  }, []);

  const sqlQueryData = async () => {
    if (sqlText === '') {
      enqueueSnackbar('Please enter some text for result.', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }
    try {
      setLoading(true);

      const dataForFile = AWS_CONFIG_TYPE
        ? {
            user_prompt: sqlText,
            path,
          }
        : {
            user_prompt: sqlText,
            path,
            env: `${process.env.REACT_APP_CURRENT_ENV}`,
          };

      const responseForFile = await getSelectedFileData(dataForFile);
      setNewData(responseForFile.data.result);

      const arr = responseForFile.data.result
        .split('\n')
        .map((row) => row.replace('<|im_end|>', '').trim())
        .filter((row) => row !== '');

      setNewDataRows(arr);
    } catch (error) {
      setfinal_rows([]);
      setHeaders([]);
      enqueueSnackbar('This query can not be executed. Please explain your views more thoroughly.', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sqlQueryData();
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      // display: 'flex',
      // justifyContent: 'space-evenly',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <div
      style={{
        // backgroundColor: '#fff',
        margin: '16px 0',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="standard-basic"
          name="sql_text"
          placeholder="Talk to Report"
          required
          fullWidth
          onChange={(e) => setSqlText(e.target.value)}
          onKeyPress={handleKeyPress}
          autoComplete="off"
        />
        <Button
          size="small"
          type="submit"
          variant="contained"
          sx={{
            marginLeft: '8px',
            backgroundColor: '#00043C',
            '&:hover': { backgroundColor: '#00043C' },
            width: 150,
            height: 50,
            color: '#ffffff',
          }}
          disabled={path === '' || sqlText === ''}
          onClick={sqlQueryData}
        >
          Proceed
        </Button>
      </Box>

      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            {newDataRows &&
              newDataRows.map((row) => (
                <StyledTableRow key={row}>
                  <StyledTableCell component="th" scope="row">
                    {row}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingIcon />}
    </div>
  );
}

export default ReportLLM;
