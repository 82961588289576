import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  Stack,
  RadioGroup,
  OutlinedInput,
  Select,
  Button,
  IconButton,
  MenuItem,
} from '@mui/material';
import useStyles from './styles';
import cloud_upload_icon from '../../assets/images/cloud_upload_icon.svg';

const options = [
  {
    id: 1,
    name: 'option 1',
  },
  {
    id: 2,
    name: 'option 2',
  },
];
const Realtime = () => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container spacing={1} mt={0}>
        <Grid item xs={9}>
          <FormControl>
            <Typography
              id="stream-row-radio-buttons-group-label"
              variant="p"
              component="p"
              display="flex"
              alignContent="flex-start"
              sx={{ color: '#8798AD', fontSize: '16px' }}
            >
              Stream
            </Typography>
            <RadioGroup row aria-labelledby="stream-row-radio-buttons-group-label" name="stream-radio-buttons-group">
              <FormControlLabel value="existing-stream" control={<Radio size="small" />} label="Existing Stream" />
              <FormControlLabel value="new-stream" control={<Radio size="small" />} label="New Stream" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={9} paddingLeft={2}>
          <FormControl sx={{ width: '80%' }}>
            <Select
              size="small"
              labelId="user-name"
              id="user-name"
              value={value}
              onChange={handleOnChange}
              input={<OutlinedInput />}
              sx={{ backgroundColor: '#2E5BFF14', height: '40px' }}
            >
              {options.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <Typography
            variant="p"
            component="p"
            display="flex"
            alignContent="flex-start"
            sx={{ color: '#8798AD', fontSize: '11px' }}
          >
            Upload JSON File*
          </Typography>
        </Grid>
        <Box
          sx={{
            backgroundColor: '#2E5BFF14',
            border: '1px solid #D6DBDF',
            width: '100%',
            height: '170px',
            marginLeft: '10px',
          }}
        >
          <Stack direction="column" alignItems="center">
            <IconButton color="primary" aria-label="upload picture" component="label">
              <input hidden accept="image/*" type="file" />
              <img src={cloud_upload_icon} alt="upload json file" style={{ height: '15vh', width: '15vw' }} />
            </IconButton>
            <Typography
              variant="p"
              component="p"
              display="flex"
              alignContent="flex-start"
              sx={{ color: '#8798AD', fontSize: '11px' }}
            >
              Drag and drop a file here or
            </Typography>

            <Button
              variant="contained"
              component="label"
              size="small"
              sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
            >
              Browse File
              <input hidden accept="image/*" multiple type="file" />
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Paper>
  );
};

export default Realtime;
