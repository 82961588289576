import React, { memo } from 'react';
import { Handle } from 'reactflow';
import { Tooltip } from '@mui/material';
import readIcon from '../../../../assets/images/books.png';
import readSqlIcon from '../../../../assets/images/sql.png';
import readOracleIcon from '../../../../assets/images/oracle.png';
import readPostgresIcon from '../../../../assets/images/pg.png';
import readCsvIcon from '../../../../assets/images/csv.png';
import readPdfIcon from '../../../../assets/images/readPdf.png';
import copyADLSIcon from '../../../../assets/images/copyADLS.png';
import readPublishedDataIcon from '../../../../assets/images/readPublishedData.png';
import readDelimitedIcon from '../../../../assets/images/delimited.png';
import ApiIcon from '../../../../assets/images/api.png';
import snowflakeReadIcon from '../../../../assets/images/snowflake_read.png';
import readRealTimeIcon from '../../../../assets/images/realtime-icon.png';
import readMdmIcon from '../../../../assets/images/mdm.png';
import fileUploaderIcon from '../../../../assets/images/UploadFile.png';

const DLReadNode = ({ data, isConnectable }) => {
  console.log(data);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip
        title={
          <>
            <div style={{ whiteSpace: 'pre-line' }}>
              Incoming edges: {data.Incoming !== undefined ? data.Incoming : 0}
            </div>
            <div style={{ whiteSpace: 'pre-line' }}>
              Outgoing edges: {data.Outgoing !== undefined ? data.Outgoing : 0}
            </div>
            <div style={{ whiteSpace: 'pre-line' }}>{data.label}</div>
          </>
        }
        placement="top"
      >
        <div
          style={{
            width: '150px',
            border: '2px solid black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fcefb4',
          }}
        >
          <Handle
            type="source"
            position="right"
            style={{ background: '#555', fontWeight: 'bold' }}
            isConnectable={isConnectable}
          />
          {data.mode === 'Read' && (
            <>
              <img src={readIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word', padding: 3 }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadCSV' && (
            <>
              <img src={readCsvIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadPDF' && (
            <>
              <img src={readPdfIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'CopyADLS' && (
            <>
              <img src={copyADLSIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadPublishedData' && (
            <>
              <img
                src={readPublishedDataIcon}
                width="30"
                alt="Nagarro"
                style={{ filter: 'contrast(200%)', margin: '10px' }}
              />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadDelimited' && (
            <>
              <img
                src={readDelimitedIcon}
                width="60"
                alt="Nagarro"
                style={{ filter: 'contrast(200%)', margin: '10px' }}
              />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadApi' && (
            <>
              <img src={ApiIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadOracle' && (
            <>
              <img src={readOracleIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadPostgres' && (
            <>
              <img
                src={readPostgresIcon}
                width="60"
                alt="Nagarro"
                style={{ filter: 'contrast(200%)', margin: '10px' }}
              />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadMySql' && (
            <>
              <img src={readSqlIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'ReadSnowflake' && (
            <>
              <img
                src={snowflakeReadIcon}
                width="60"
                alt="Nagarro"
                style={{ filter: 'contrast(200%)', margin: '10px' }}
              />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Read Real-Time' && (
            <>
              <img
                src={readRealTimeIcon}
                width="60"
                alt="Nagarro"
                style={{ filter: 'contrast(200%)', margin: '10px' }}
              />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'Read MDM' && (
            <>
              <img src={readMdmIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
          {data.mode === 'FileUploader' && (
            <>
              <img
                src={fileUploaderIcon}
                width="30"
                alt="Nagarro"
                style={{ filter: 'contrast(200%)', margin: '10px' }}
              />
              <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            </>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default memo(DLReadNode);
