import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  pageTitle: {
    color: '#43425D',
  },
  pageText: {
    color: '#444444',
  },
  divider: {
    width: '130px',
    marginBottom: '13px !important',
  },
  paper: {
    height: '350px',
    marginTop: '15px',
    padding: '20px',
    '@media (max-width: 600px)': {
      height: '500px',
    },

    '@media (min-width: 601px) and (max-width: 1090px)': {
      height: '450px',
    },
  },
  paperTitle: {
    color: '#8798AD',
  },
  formInputField: {
    marginTop: '15px !important',
    color: '#8798AD',
    fontSize: '11px',
  },
  formTextField: {
    backgroundColor: '#2E5BFF14',
  },
  formControl: {
    width: '23.1%',
  },
  formSelect: {
    backgroundColor: '#2E5BFF14',
    height: '40px',
  },
  previousAndCancelBtn: {
    marginRight: '7px !important',
    backgroundColor: '#ffffff !important',
    '&:hover': { backgroundColor: '#ffffff !important' },
    color: '#444444 !important',
    border: '1px solid black !important',
  },
  saveAndNextBtn: {
    backgroundColor: '#00043C !important',
    '&:hover': { backgroundColor: '#00043C !important' },
  },
});

export default useStyles;
