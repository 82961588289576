import { Tooltip } from '@mui/material';
import React, { memo } from 'react';
import { Handle } from 'reactflow';

const DLInnerNode = ({ data, isConnectable }) => {
  return (
    <>
      <div
        style={{
          marginLeft: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '4px solid red',
        }}
      >
        <div>{data.label}</div>
        <div>
          <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{data.label}</div>} placement="top">
            <div
              style={{
                width: '50px',
                height: '50px',
                border: '2px solid black',
                borderRadius: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: `${
                  (data.mode === 'Read' && '#F1A1A7') ||
                  (data.mode === 'ReadCSV' && '#6d99a5') ||
                  (data.mode === 'ReadPDF' && '#47d7ac') ||
                  (data.mode === 'CopyADLS' && '#36a390') ||
                  (data.mode === 'ReadPublishedData' && '#6d78a5') ||
                  (data.mode === 'ReadDelimited' && '#36a390') ||
                  (data.mode === 'ReadApi' && '#47d7ac') ||
                  (data.mode === 'ReadOracle' && '#36a390') ||
                  (data.mode === 'ReadPostgres' && '#47d7ac') ||
                  (data.mode === 'ReadMySql' && '#36a390') ||
                  (data.mode === 'ReadSnowflake' && '#abf0ff') ||
                  (data.mode === 'Read Real-Time' && '#47d7ac') ||
                  (data.mode === 'Read MDM' && '#7aa388') ||
                  (data.mode === 'Filter' && '#ddd') ||
                  (data.mode === 'Sort' && '#AAE0F3') ||
                  (data.mode === 'Join' && '#f3b3ee') ||
                  (data.mode === 'Execute SQL' && '#EECFA6') ||
                  (data.mode === 'KPI' && '#C3C9A7') ||
                  (data.mode === 'Aggregation' && '#afa6c9') ||
                  (data.mode === 'Coalesce' && '#687C96') ||
                  (data.mode === 'Union' && '#E2F59D') ||
                  (data.mode === 'Email' && '#e5f3ff') ||
                  (data.mode === 'Data Cleansing' && '#e5f3ff') ||
                  (data.mode === 'Datatype Conversion' && '#6DA6A4') ||
                  (data.mode === 'Concat' && '#B9BB90') ||
                  (data.mode === 'Date Time Feature' && '#FFDF7E') ||
                  (data.mode === 'Handle Null' && '#ddd') ||
                  (data.mode === 'Math Transformation' && '#A49C9C') ||
                  (data.mode === 'OneHot Encoder' && '#BF7878') ||
                  (data.mode === 'Label Encoder' && '#AFA6C9') ||
                  (data.mode === 'Udf' && '#CAFFE6') ||
                  (data.mode === 'C360' && '#77799F') ||
                  (data.mode === 'Source' && '#77799F') ||
                  (data.mode === 'DataValidation' && '#CAFFE6') ||
                  (data.mode === 'FileUploader' && '#99ffff')
                }`,
              }}
            >
              <Handle
                type="source"
                position="right"
                style={{ background: '#555', fontWeight: 'bold' }}
                isConnectable={isConnectable}
              />
              <Handle
                type="target"
                position="left"
                onConnect={(params) => console.log('', params)}
                isConnectable={isConnectable}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default memo(DLInnerNode);
