import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom/dist';
import { Box, Button } from '@mui/material';
import { MdArrowBackIosNew } from 'react-icons/md';
import { getAWSLogsByLogstreams } from "../../api's/LogsApi";
import LoadingIcon from '../../reusable-component/LoadingIcon';

export default function DetailedLogs({ headerTableData }) {
  const [longText, setLongtext] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAWSLogsByLogstreams = async () => {
      const data = {
        workflow_type: headerTableData.workflow_type,
        log_stream: headerTableData.prefix,
      };
      try {
        setLoading(true);
        const response = await getAWSLogsByLogstreams(data);
        if (response.status === 200) {
          setLongtext(response.data);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.status === 500) {
          enqueueSnackbar('500 Internal Server Error!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (error.response?.status === 404) {
          enqueueSnackbar('404 No Logs found!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else {
          enqueueSnackbar('Something went wrong!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
      setLoading(false);
    };
    fetchAWSLogsByLogstreams();
  }, []);

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        startIcon={<MdArrowBackIosNew />}
        onClick={() => navigate(-1)}
        sx={{ color: '#43425D', border: '1px solid #43425D', background: '#fff' }}
      >
        Back
      </Button>
      <div style={{ width: '78vw', overflow: 'auto', paddingTop: '10px', overflowWrap: 'break-word' }}>
        {longText.length <= 0
          ? !loading && 'No logs found.'
          : longText?.map((item) => Object.entries(item).map(([key, value]) => <p>{`${[key]}: ${value},`}</p>))}
        {loading && <LoadingIcon />}
      </div>
    </Box>
  );
}
