import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const getCosmosDatabase = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/cosmos-info/get-db-list`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
export const getCosmosContainers = async (database) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/cosmos-info/get-containers-list/${database}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getCosmosColumns = async (database, container) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/cosmos-info/get-columns/${database}/${container}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
