import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TfiMoreAlt } from 'react-icons/tfi';
import { useSnackbar } from 'notistack';
import { getWorkflowById, getWorkflowByUserGroupAndEngagementIdApi } from "../api's/WorkflowApi";
import { getDataProcessor, getNodeStatus } from "../api's/DataProcessorApi";
import DeleteModal from './DeleteModal';
import { getEngagementByUserGroupAndClientIdApi } from "../api's/EngagementApi";
import LoadingIcon from './LoadingIcon';
import EditConnectionModal from './EditConnectionModal';
import AccessModal from './AccessModal';
import { AuthContext } from '../contexts/AuthProvider';
import {
  getAllClientByDomainIdApi,
  getAllClientsApi,
  getClientByUserGroup,
  getClientByUserGroupAndDomainIdApi,
} from "../api's/ClientApi";

export default function BasicMenu({ row, fetchData, type, handleRefreshWorkflow }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openConnectionModal, setOpenConnectionModal] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [id, setId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { userRole, userGroup, domainId } = useContext(AuthContext);

  const handleClick = (row, event) => {
    setAnchorEl(event.currentTarget);
    type === 'Workflow'
      ? setId(row.workflow_id)
      : type === 'Client'
      ? setId(row.client_id)
      : type === 'Engagement'
      ? setId(row.engagement_id)
      : null;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteModal = () => {
    if (type === 'Domain') {
      if (userRole === 'SUPER_USER') {
        setId(row.domain_id);
        setOpenDeleteModal(true);
        handleClose();
      } else {
        handleAccessModal();
      }
    } else if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenDeleteModal(true);
      handleClose();
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    handleClose();
  };

  const handleConnectionModal = () => {
    setOpenConnectionModal(!openConnectionModal);
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  const handleNavigate = (row, type) => {
    type === 'Workflow'
      ? navigateToWorkflow(row)
      : type === 'Client'
      ? navigateToClient(row)
      : type === 'Engagement'
      ? navigateToEngagement(row)
      : type === 'Domain'
      ? navigateToDomain(row)
      : null;
  };

  const navigateToDomain = async (row) => {
    setLoading(true);
    const group = userGroup;
    try {
      let response;
      if (userRole === 'SUPER_USER' && domainId !== undefined) {
        response = await getAllClientByDomainIdApi(row.domain_id);
      } else if (userRole === 'SUPER_USER' && domainId === undefined) {
        response = await getAllClientsApi();
      } else if (userRole !== 'SUPER_USER' && domainId !== undefined) {
        response = await getClientByUserGroupAndDomainIdApi(userGroup, row.domain_id);
      } else {
        response = await getClientByUserGroup(userGroup);
      }
      if (response.status === 200) {
        navigate('/domain/view_details', {
          state: {
            domain_id: row.domain_id,
            domain_name: row.domain_name,
            created_by: row.created_by,
            creation_date: row.creation_date,
            status: row.status,
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Client not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }

    setLoading(false);
  };

  const navigateToClient = async (row) => {
    setLoading(true);

    try {
      const response = await getEngagementByUserGroupAndClientIdApi(userGroup, row.client_id);
      if (response.status === 200) {
        navigate('/client/view_details', {
          state: {
            client_id: row.client_id,
            client_name: row.client_name,
            region: row.region,
            engagement_count: row.engagement_count,
            workflow_count: row.workflow_count,
            created_by: row.created_by,
            created_at: row.created_at,
            status: row.status,
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Engagement not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const navigateToEngagement = async (row) => {
    setLoading(true);

    try {
      const response = await getWorkflowByUserGroupAndEngagementIdApi(userGroup, row.engagement_id);
      if (response.status === 200) {
        navigate('/engagement/view_details', {
          state: {
            engagement_id: row.engagement_id,
            engagement_name: row.engagement_name,
            client_name: row.client_name,
            domain_name: row.domain_name,
            workflow_count: row.workflow_count,
            workflow_name: row.workflow_name,
            execution_mode: row.execution_mode,
            created_by: row.created_by,
            created_at: row.created_at,
            status: row.status,
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const navigateToWorkflow = async (row) => {
    sessionStorage.removeItem('allNodes');
    sessionStorage.removeItem('node');
    sessionStorage.removeItem('elementCount');
    sessionStorage.removeItem('saved_node');
    sessionStorage.removeItem('edges');
    setLoading(true);
    const response = await getWorkflowById(row.workflow_id);

    let result, nodeStatus;
    try {
      result = await getDataProcessor(row.workflow_id, row.execution_mode);
      nodeStatus = await getNodeStatus(
        response.data.engagement.client.client_name,
        response.data.engagement.engagement_name,
        response.data.workflow_name,
        response.data.cloudEnvironment
      );
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      if (result) {
        let elemCount = 0;

        const newSavedElement = [];

        result.data.nodes.forEach((el) => {
          el['nodeId'] = `${el.nodeId}`;
          elemCount++;
          newSavedElement.push(el.id);
        });

        sessionStorage.setItem('allNodes', JSON.stringify(result.data.form_data));
        sessionStorage.setItem('elementCount', elemCount);
        sessionStorage.setItem('node', JSON.stringify(result.data.nodes));
        sessionStorage.setItem('edges', JSON.stringify(result.data.edges));
        sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
        nodeStatus && sessionStorage.setItem('nodeStatus', JSON.stringify(nodeStatus.data));
      }

      const type =
        row?.process_type === 'Data Quality'
          ? 'data-quality'
          : row?.process_type === 'Data Copy'
          ? 'data-copy'
          : row?.process_type === 'Data Transformation'
          ? 'data-transformation'
          : row?.process_type === 'Reporting'
          ? 'reporting'
          : row?.process_type === 'Data Ingestion'
          ? 'ingestion'
          : row?.process_type === 'Modelling & Analytics'
          ? 'modelling-analytics'
          : row?.process_type === 'XPerience360'
          ? 'xperience360'
          : row?.process_type === 'Customer Segmentation'
          ? 'customer-segmentation'
          : 'data';

      navigate(`/workflow-${type}/onboarding`, {
        state: {
          workflow_name: row.workflow_name,
          client_name: row.client_name,
          engagement_name: row.engagement_name,
          execution_mode: row.execution_mode,
          created_by: row.created_by,
          created_at: row.created_at,
          status: row.status,
          workflow_data: response.data,
        },
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(row, e);
        }}
      >
        <TfiMoreAlt />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {row.status === 'approved' ? (
          <div>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleNavigate(row, type);
              }}
            >
              View Details
            </MenuItem>
            {type === 'Connection' && <MenuItem onClick={handleConnectionModal}>Edit {type}</MenuItem>}
            <MenuItem onClick={handleOpenDeleteModal}>Delete {type}</MenuItem>
            {type === 'Workflow' && <MenuItem onClick={handleRefreshWorkflow}>Refresh {type}</MenuItem>}
          </div>
        ) : (
          <MenuItem onClick={handleOpenDeleteModal}>Delete {type}</MenuItem>
        )}
      </Menu>

      <EditConnectionModal
        openConnectionModal={openConnectionModal}
        handleConnectionModal={handleConnectionModal}
        row={row}
      />
      <DeleteModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        type={type}
        id={id}
        fetchData={fetchData}
      />
      <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
      {loading && <LoadingIcon />}
    </div>
  );
}
