import React, { memo } from 'react';
import { Handle } from 'reactflow';
import { Tooltip } from '@mui/material';
import writeIcon from '../../../../assets/images/pencil.png';
import snowFlakeIcon from '../../../../assets/images/snowFlake.png';
import DatabaseIcon from '../../../../assets/images/Database.png';
import openSearchIcon from '../../../../assets/images/openSearch.png';
import writeSFTPIcon from '../../../../assets/images/writeSFTP.png';
import ingestionWrite from '../../../../assets/images/Data_Ingestion.png';
import tableLevelMetadataIngestion from '../../../../assets/images/tableLevelMetadataIngestion.png';
import columnLevelMetadataIngestion from '../../../../assets/images/columnLevelMetadataIngestion.png';
import generateTranscript from '../../../../assets/images/generateTranscript.png';



const DLWriteNode = ({ data, isConnectable }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip
          title={
            <>
              <div style={{ whiteSpace: 'pre-line' }}>
                Incoming edges: {data.Incoming !== undefined ? data.Incoming : 0}
              </div>
              <div style={{ whiteSpace: 'pre-line' }}>
                Outgoing edges: {data.Outgoing !== undefined ? data.Outgoing : 0}
              </div>
              <div style={{ whiteSpace: 'pre-line' }}>{data.label}</div>
            </>
          }
          placement="top"
        >
          <div
            style={{
              width: '150px',
              border: '2px solid black',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fcefb4',
            }}
          >
            <Handle
              type="target"
              position="left"
              onConnect={(params) => console.log('', params)}
              isConnectable={isConnectable}
            />
            <Handle type="source" position="right" isConnectable={isConnectable} />

            {data.mode === 'Write' && (
              <>
                <img src={writeIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'SnowFlake' && (
              <>
                <img
                  src={snowFlakeIcon}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'OpenSearch' && (
              <>
                <img
                  src={openSearchIcon}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'WriteSFTP' && (
              <>
                <img
                  src={writeSFTPIcon}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Postgres' && (
              <>
                <img src={DatabaseIcon} width="60" alt="Nagarro" style={{ filter: 'contrast(200%)', margin: '10px' }} />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Ingestion Write' && (
              <>
                <img
                  src={ingestionWrite}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Ingest Table Metadata' && (
              <>
                <img
                  src={tableLevelMetadataIngestion}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Ingest Column Metadata' && (
              <>
                <img
                  src={columnLevelMetadataIngestion}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
            {data.mode === 'Generate Transcript' && (
              <>
                <img
                  src={generateTranscript}
                  width="60"
                  alt="Nagarro"
                  style={{ filter: 'contrast(200%)', margin: '10px' }}
                />
                <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
              </>
            )}
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default memo(DLWriteNode);
