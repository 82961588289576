import { makeStyles } from '@mui/styles';

const themeStyles = makeStyles({
  // Add {client_name}ThemeColor, {client_name}LogoImg, 
  // {client_name}gtSidebar for adding new theme according to Client

  gtThemeColor: {
    backgroundColor: '#623C74 !important',
  },
  nagarroThemeColor: {
    backgroundColor: '#00043C !important',
  },
  gtLogoImg: {
    width: '170px',
    height: '60px',
  },
  nagarroLogoImg: {
    width: '170px',
    height: '40px',
  },
  gtSidebar: {
    position: 'fixed',
    backgroundColor: '#623c74',
    color: '#ffffff',
    height: '100vh',
    borderBottomRightRadius: '80px',
    transition: 'all .5s',
  },
  nagarroSidebar: {
    position: 'fixed',
    backgroundColor: '#00043C',
    color: '#ffffff',
    height: '100vh',
    borderBottomRightRadius: '80px',
    transition: 'all .5s',
  },
});

export default themeStyles;
