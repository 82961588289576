import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';

const useStyles = makeStyles({
  mainText: {
    // color: grey[600],
    // '&.MuiTab-root': {
    //   fontWeight: 'bold',
    // },
  },

  description: {
    color: '#4e5e78',
  },

  circle: {
    height: '20px',
    width: '20px',
    backgroundColor: 'green',
    borderRadius: '50%',
    display: 'inline-block',
  },

  feature: {
    position: 'relative',
    fontWeight: '600',
    paddingBottom: '20px',
    width: '250px',
    height: '80px',
    color: '#13294b',
    whiteSpace: 'break-spaces',
    '&:after': {
      background:
        'url(https://www.nagarro.com/hubfs/NagarroWebsiteRedesign-Aug2020/Assets/Images/title-underline.png) no-repeat',
      backgroundPosition: 'left bottom',
      paddingTop: '40px',
      content: '""',
      position: 'absolute',
      left: '10px',
      //   bottom: '25px',
      width: '100%',
      height: '3px',
    },
  },

  header: {
    height: '50px',
    width: '100%',
    background: '#13294b',
  },

  actions: {
    margin: '0 auto',
  },

  content: {
    marginTop: '20px',
    minWidth: '200px',
  },
});

export default useStyles;
