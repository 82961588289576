import axios from 'axios';
import { logoutApi } from './AuthApi';
import { BACKEND_VDE_BASEURL } from '../BaseUrl';

export const getAllDomainsApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BACKEND_VDE_BASEURL}/list_domains`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllClientApi = async (domain_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BACKEND_VDE_BASEURL}/list_clients`, {
      params: {
        domain_name,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllEngagementApi = async (domain_name, client_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BACKEND_VDE_BASEURL}/list_engagements`, {
      params: {
        domain_name,
        client_name,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllWorkflowApi = async (domain_name, client_name, batch_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BACKEND_VDE_BASEURL}/list_workflows`, {
      params: {
        domain_name,
        client_name,
        batch_name,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllSubDomainApi = async (domain_name, client_name, batch_name, job_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BACKEND_VDE_BASEURL}/list_subdomains`, {
      params: {
        domain_name,
        client_name,
        batch_name,
        job_name,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const fetchResult = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BACKEND_VDE_BASEURL}/talk_to_docubot`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
