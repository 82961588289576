import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  DialogContentText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import large_icon from '../assets/images/large_icon.png';
import useStyles from '../pages/aboutPageStyles';
import AboutPage from '../pages/AboutPage';

export default function AboutModal() {
  const [open, setOpen] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const handleMoreInfo = () => {
    setMoreInfo(true);
    setOpen(false);
  };

  useEffect(() => {
    const id = location.pathname === '/about';
    if (id) {
      setOpen(true);
    }
  }, [location.pathname]);

  const handleClose = () => {
    navigate(-1);
    setOpen(false);
  };

  const version = JSON.parse(sessionStorage.getItem('version'));

  return (
    <>
      <Dialog open={open}>
        <DialogTitle id="alert-dialog-title" className={classes.header}>
          <div style={{ textAlign: 'center' }}>
            <img src={large_icon} alt="logo" width="100" />
          </div>
          {handleClose ? (
            <IconButton
              aria-label="close"
              size="small"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            <Typography variant="subtitle2" className={classes.description}>
              DEP: {version}
            </Typography>
          </DialogContentText>
        </DialogContent>
        {version === '2.0' && (
          <DialogActions className={classes.actions}>
            <Button autoFocus onClick={handleMoreInfo} sx={{ color: '#13294b' }}>
              More Info
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {moreInfo ? <AboutPage /> : null}
    </>
  );
}
