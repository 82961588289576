import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { MdDelete } from 'react-icons/md';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RenderHtml from '../RenderHtml';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: '90%',
  overflowY: 'auto',
  bgcolor: '#f8f8ff',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function HtmlModal({ openModal, handleCloseModal, htmlData, nodeId }) {
  return (
    <div>
      <Modal
        keepMounted
        open={openModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box sx={style}>
          {htmlData === 'training' && (
            <IconButton
              aria-label="expand row"
              size="large"
              onClick={(e) => {
                e.stopPropagation();
                handleCloseModal();
              }}
              sx={{ marginLeft: '93%' }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <RenderHtml htmlData={htmlData} nodeId={nodeId} handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>
    </div>
  );
}
