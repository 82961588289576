import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Checkbox,
  Box,
  Modal,
  Tooltip,
  FormControlLabel,
  Stack,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Plot from 'react-plotly.js';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import InputField from '../../../reusable-component/InputField';
import { WorkflowContext } from '../../../contexts/WorkflowProvider';
import { AWS_CONFIG_TYPE } from '../../../BaseUrl';
import { fetchHtmlJsonApi } from "../../../api's/DataProcessorApi";
import PaginationFooter from '../../../reusable-component/PaginationFooter';
import { convertToPascalCase } from '../../../utils/stringConversion';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const rowsPerPage = 5;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NodeModalAnalysis = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { workflow } = useContext(WorkflowContext);

  const INITIALSTATE = {
    alias: '',
    df: '',
    sample_view: true,
    persist: false,
    distinct_rows: false,
    persist_type: '',
    action: '',
    path: AWS_CONFIG_TYPE
      ? `s3://dep-qa/${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/MDM/CustomerSegmentation/CustomerSegmentation.json`
      : `https://depazuredev.blob.core.windows.net/dep-develop/${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/MDM/CustomerSegmentation/CustomerSegmentation.json`,
  };

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedGraphs, setFetchedGraphs] = useState([]);
  const [fetchedMarketBasket, setFetchedMarketBasket] = useState([]);
  const [isMarketBasket, setIsMarkedBaset] = useState(false);
  const [page, setPage] = useState(1);

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setIsMarkedBaset(event.target.checked);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });
    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getSourceData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return {
          ...el,
          header: el.alias ? el.alias : el.header,
          alias: el.alias ? '' : el.alias,
        };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);
      setFetchedHeader(newHeader);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setFetchedGraphs(node.fetchedGraphs);
          // setFetchedMarketBasket(node.fetchedMarketBasket);
          setTempHeader(node.headerName);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setFetchedGraphs([]);
    // setFetchedMarketBasket([]);
    setHeaderName([]);
    getSourceData();
    fetchMarketBasket();
  }, [nodeId]);

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];

    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setFetchedGraphs([]);
      // setFetchedMarketBasket([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setHeaderName(node.headerName);
            setFetchedGraphs(node.fetchedGraphs);
            // setFetchedMarketBasket(node.fetchedMarketBasket);
            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = newHeader.findIndex((x) => x.header === item.header);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            newHeader.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.header === el.header);

              if (index === -1) {
                nonExistingHeader.push({
                  ...el,
                  header: el.alias ? el.alias : el.header,
                  alias: el.alias ? '' : el.alias,
                });
              }
            });

            setFetchedHeader(nonExistingHeader);
          }
        });
      } else {
        setFetchedHeader(newHeader);
      }
    }
    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  const fetchGraphs = async () => {
    setLoading(true);
    const pathType = AWS_CONFIG_TYPE
      ? formField.path.split('s3://dep-qa/')[1]
      : formField.path.split('https://depazuredev.blob.core.windows.net/dep-develop/')[1];

    try {
      const response = await fetchHtmlJsonApi(pathType, AWS_CONFIG_TYPE, workflow.engagement.client.client_id);
      if (response.status === 200) {
        setFetchedGraphs(response.data);
      }
    } catch (error) {
      enqueueSnackbar('Path does not exist! Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  const fetchMarketBasket = async () => {
    setLoading(true);
    const pathType = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/MDM/CustomerSegmentation/market_basket_analysis.json`;

    try {
      const response = await fetchHtmlJsonApi(pathType, AWS_CONFIG_TYPE, workflow.engagement.client.client_id);
      if (response.status === 200) {
        setFetchedMarketBasket(response.data);
      }
    } catch (error) {
      // enqueueSnackbar('Failed to fetch Market Basket Analysis data!', {
      //   variant: 'error',
      //   autoHideDuration: 3000,
      //   anchorOrigin: { vertical: 'top', horizontal: 'right' },
      // });
    }
    setLoading(false);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      fetchedGraphs,
      // fetchedMarketBasket
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '80%', marginLeft: '20%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>

              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  onClick={refreshAllState}
                  aria-label="close"
                  sx={{ color: '#00043C' }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  disabled={disableForm}
                  className="button-color"
                  // onClick={handleFormsubmit}
                >
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <Box
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <InputField
                name="alias"
                label="Alias"
                value={formField.alias}
                onChange={handleInputChange}
                size="small"
                disabled={disableForm}
                required
                style={{ width: '30%' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={formField.sample_view}
                    onChange={handleChecked}
                    disabled={disableForm}
                  />
                }
                label="Sample View"
                sx={{ p: 1 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={isMarketBasket}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                disabled={disableForm}
                label="Market Analysis"
                sx={{ pl: 1 }}
              />
              <InputField
                name="path"
                label="File Path"
                value={formField.path}
                onChange={handleInputChange}
                size="small"
                disabled={disableForm}
                required
                style={{ width: '50%' }}
              />
              <Button
                style={{ width: '100px', marginTop: '10px' }}
                variant="outlined"
                onClick={fetchGraphs}
                disabled={disableForm || formField.path.length <= 0}
                className="outlined-button-color"
              >
                Fetch
              </Button>
            </Box>
            {isMarketBasket &&
              (fetchedMarketBasket?.market_basket_table?.length >= 0 ? (
                <>
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                          {Object.keys(fetchedMarketBasket?.market_basket_table[0]).map((item) => (
                            <TableCell sx={{ fontWeight: 'bold' }}>{convertToPascalCase(item)}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fetchedMarketBasket?.market_basket_table
                          ?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                          .map((row, i) => {
                            return (
                              <TableRow key={i}>
                                <TableCell width="10%">{`${i + 1 + (page - 1) * rowsPerPage}.`}</TableCell>
                                {/* <TableCell width="30%">{row.antecedent}</TableCell> */}
                                <TableCell width="30%">{Object.values(row)[0]}</TableCell>
                                {/* <TableCell>{row.Recommendations.map((ele) => ele).join(', ')}</TableCell> */}
                                <TableCell>{Object.values(row)[1].join(',')}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                  <PaginationFooter
                    tableData={fetchedMarketBasket?.market_basket_table}
                    page={page}
                    handleChangePage={handleChangePage}
                  />
                </>
              ) : (
                <Typography sx={{ p: 1 }}>No Data</Typography>
              ))}
            <Stack direction="row" flexWrap="wrap">
              {isMarketBasket ? (
                <>
                  {fetchedMarketBasket?.market_basket_plot
                    ?.filter((item) => item.graph_type === 'bar' && item.barmode !== 'stack')
                    .map((item) => (
                      <Plot
                        data={[
                          {
                            x: item.data?.xaxis_values,
                            y: item.data?.yaxis_values,
                            type: item.graph_type,
                            orientation: item.orientation,
                            width: 0.5,
                          },
                        ]}
                        layout={{
                          width: 750,
                          height: 450,
                          title: { text: item.title, font: { size: 14 } },
                          xaxis: {
                            title: {
                              text: item.xaxis_name,
                              font: { size: 12 },
                            },
                          },
                          yaxis: {
                            title: {
                              text: item.yaxis_name,
                              font: { size: 12 },
                            },
                            automargin: true,
                          },
                          bargap: 0.3,
                        }}
                        config={{ responsive: true, displayModeBar: false }}
                      />
                    ))}
                </>
              ) : (
                <>
                  {fetchedGraphs
                    ?.filter((item) => item.graph_type === 'bar' && item.barmode !== 'stack')
                    .map((item) => (
                      <Plot
                        data={[
                          {
                            x: item.data?.xaxis_values,
                            y: item.data?.yaxis_values,
                            type: item.graph_type,
                          },
                        ]}
                        layout={{
                          width: 450,
                          height: 350,
                          title: { text: item.title, font: { size: 14 } },
                          xaxis: {
                            title: {
                              text: item.xaxis_name,
                              font: { size: 12 },
                            },
                            automargin: true,
                          },
                          yaxis: {
                            title: {
                              text: item.yaxis_name,
                              font: { size: 12 },
                            },
                          },
                          bargap: 0.3,
                        }}
                        config={{ responsive: true, displayModeBar: false }}
                      />
                    ))}
                  {fetchedGraphs
                    ?.filter((item) => item.graph_type === 'heatmap')
                    .map((item) => (
                      <Plot
                        data={[
                          {
                            x: item.data?.xaxis_values,
                            y: item.data?.yaxis_values,
                            z: item.data?.zaxis_values,
                            type: item.graph_type,
                            colorscale: 'Viridis',
                            hoverongaps: false,
                            hoverinfo: 'none',
                            hovertemplate: `${item.xaxis_name}: %{x}<br>${item.yaxis_name}: %{y}<br>${item.zaxis_name}: %{z}<extra></extra>`,
                          },
                        ]}
                        layout={{
                          width: 580,
                          height: 480,
                          title: { text: item.title, font: { size: 14 } },
                          xaxis: {
                            title: {
                              text: item.xaxis_name,
                              font: { size: 12 },
                            },
                          },
                          yaxis: {
                            title: {
                              text: item.yaxis_name,
                              font: { size: 12 },
                            },
                          },
                        }}
                        config={{ responsive: true, displayModeBar: false }}
                      />
                    ))}
                  {fetchedGraphs
                    ?.filter((item) => item.graph_type === 'scatter')
                    .map((item) => (
                      <Plot
                        data={[
                          {
                            x: item.data?.xaxis_values,
                            y: item.data?.yaxis_values,
                            type: item.graph_type,
                          },
                        ]}
                        layout={{
                          width: 580,
                          height: 480,
                          title: { text: item.title, font: { size: 14 } },
                          xaxis: {
                            title: {
                              text: item.xaxis_name,
                              font: { size: 12 },
                            },
                          },
                          yaxis: {
                            title: {
                              text: item.yaxis_name,
                              font: { size: 12 },
                            },
                          },
                          bargap: 0.3,
                        }}
                        config={{ displayModeBar: false }}
                      />
                    ))}
                  {fetchedGraphs
                    ?.filter((element) => element.barmode === 'stack')
                    .map((graphData) => (
                      <div key={graphData.id}>
                        <Plot
                          data={graphData.traces.map((trace) => ({
                            x: trace.xaxis_values,
                            y: trace.yaxis_values,
                            name: trace.name,
                            type: trace.graph_type,
                          }))}
                          layout={{
                            title: graphData.title,
                            xaxis: { title: graphData.xaxis_name },
                            yaxis: { title: graphData.yaxis_name },
                            barmode: graphData.barmode,
                          }}
                          config={{ displayModeBar: false }}
                        />
                      </div>
                    ))}
                </>
              )}
            </Stack>
            {loading && <LoadingIcon />}
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeModalAnalysis;
