import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: '#e2e3e5',
    borderColor: '#d6d8db',
    minHeight: '50px',
    padding: '10px',
    // maxWidth: '70%',
    width: '100%',
    borderRadius: '5px',
    border: '1px solid grey',
    margin: '10px 0',
  },

  menuContainer: {
    padding: '40px 20px 0 20px',
  },

  menu: {
    '&.MuiButton-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      color: '#fff',
      padding: '7px 0',
      '&:hover': {
        borderLeft: '4px solid var(--second-color)',
        background:
          'transparent linear-gradient(90deg, var(--second-color) 0%, #4A90E200 100%) 0% 0% no-repeat padding-box',
        color: 'var(--white-color) !important',
        opacity: '1 !important',
        transition: 'all .1s',
      },
    },
  },
});

export default useStyles;
