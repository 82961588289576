import { IconButton, Stack, Tooltip } from '@mui/material';
import { Button, Empty, Form, Input, Select, Table, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AiFillEdit, AiFillSave, AiOutlineCloseCircle } from 'react-icons/ai';
import './TemplateRules.css';

const DATA_TYPE = ['text', 'Integer', 'decimal', 'date'];
// const DATA_TYPE = ["text", "Integer", "image", "decimal", "date"];
const EDITABLE_TYPE = ['Y', 'N'];

const handleTitle = (ele) => {
  switch (ele) {
    case 'fieldname':
      return 'Field Name';
    case 'editable':
      return 'Editable';
    case 'image_field':
      return 'Image';
    case 'transformation_rule_names':
      return 'Transformation Rules';
    case 'type':
      return 'Type';
    case 'required_parameter':
      return 'Transformation Parameter';
    case 'field_parameter':
      return 'Field Parameter';
    case 'size':
      return 'Size';
    default:
      return ele;
  }
};

const { Option } = Select;

const getTypeTooltip = (value) => {
  switch (value) {
    case 'VARCHAR':
    case 'CHAR':
      return 'length';
    default:
      return value;
  }
};

const getRules = (dataIndex, title, record) => {
  if (
    record.transformation_rule_names !== null &&
    record.transformation_rule_names.length > 0 &&
    dataIndex === 'required_parameter'
  ) {
    return [
      {
        required: false,
        message: `Please Input ${title}!`,
      },
    ];
  }

  if (dataIndex === 'field_parameter' && record.transformation_rule_names.includes('col_not_null')) {
    return [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ];
  }
  return [];
};

const filterOption = (input, option) => {
  return (option?.value ?? '').toLowerCase().includes(input.toLowerCase());
};

const onSearch = (value) => {
  console.log('search:', value);
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  handleChangeRule,
  handleChangeType,
  handleChangeFile,
  onInputChange,
  transformationRules,
  templateData,
  fetchedHeader,
  ...restProps
}) => {
  let inputNode;

  if (inputType === 'select' && dataIndex === 'type') {
    inputNode = (
      <Select
        placeholder="Please select"
        onChange={(e) => handleChangeType(e, record, dataIndex)}
        getPopupContainer={(node) => node.parentNode}
      >
        {DATA_TYPE.map((option) => (
          <Option key={option} value={option} title={getTypeTooltip(option)}>
            {option}
          </Option>
        ))}
      </Select>
    );
  } else if (inputType === 'select' && dataIndex === 'transformation_rule_names') {
    inputNode = (
      <Select
        // allowClear
        // showSearch
        placeholder="Please select"
        onChange={(e) => handleChangeRule(e, record, dataIndex)}
        getPopupContainer={(node) => node.parentNode}
      >
        {transformationRules.map((item) => (
          <Select.Option key={item.ruleId} value={item.backendRuleName}>
            {item.ruleName}
          </Select.Option>
        ))}
      </Select>
    );
  } else if (inputType === 'select' && dataIndex === 'field_parameter') {
    inputNode = (
      <Select
        allowClear
        showSearch={dataIndex === 'field_parameter'}
        placeholder="Please select"
        filterOption={filterOption}
        onSearch={onSearch}
        onChange={(e) => handleChangeType(e, record, dataIndex)}
        getPopupContainer={(node) => node.parentNode}
      >
        {fetchedHeader.map((option) => (
          <Option key={option.header} value={option.header}>
            {option.header}
          </Option>
        ))}
      </Select>
    );
  } else if (inputType === 'select' && dataIndex === 'editable') {
    inputNode = (
      <Select
        placeholder="Please select"
        onChange={(e) => handleChangeType(e, record, dataIndex)}
        getPopupContainer={(node) => node.parentNode}
      >
        {EDITABLE_TYPE.map((option) => (
          <Option key={option} value={option} title={getTypeTooltip(option)}>
            {option}
          </Option>
        ))}
      </Select>
    );
  } else if (dataIndex === 'image_field') {
    if (record.type === 'image') {
      inputNode = (
        <Upload
          name="file"
          beforeUpload={(file) => {
            const isPNG = file.type === 'image/png';
            if (!isPNG) {
              console.error(`${file.name} is not a png file`);
            }
            return isPNG || Upload.LIST_IGNORE;
          }}
          onChange={(e) => handleChangeFile(e, record, dataIndex)}
          // action={uploadFileApi}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />} />
        </Upload>
      );
    }
  } else {
    inputNode = <Input placeholder={getTypeTooltip(record?.type)} />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={getRules(dataIndex, title, record)}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  children: PropTypes.any,
  dataIndex: PropTypes.string,
  editing: PropTypes.any,
  handleChangeRule: PropTypes.func,
  handleChangeType: PropTypes.func,
  handleChangeFile: PropTypes.func,
  index: PropTypes.any,
  inputType: PropTypes.string,
  onInputChange: PropTypes.any,
  record: PropTypes.shape({
    type: PropTypes.any,
  }),
  sanityRules: PropTypes.shape({
    map: PropTypes.func,
  }),
  templateData: PropTypes.shape({
    map: PropTypes.func,
  }),
  title: PropTypes.any,
  fetchedHeader: PropTypes.any,
};

export default function TemplateRules({
  fetchedHeader,
  transformationRules,
  templateData,
  setTemplateData,
  loading,
  editingKey,
  setEditingKey,
  disableForm,
}) {
  const [form] = Form.useForm();
  const [, setIsEditable] = useState(true);
  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    if (editingKey === '') {
      // console.log("useEffect")
    }
  }, [editingKey]);

  const [tableHeight, setTableHeight] = useState(null);

  function arrayToObjectWithDefaultValues(keys) {
    return Object.fromEntries(keys.map((key) => [key, '']));
  }

  const templateColumns = templateData?.length > 0 ? templateData[0] : [];
  const resultObject = arrayToObjectWithDefaultValues(Object.keys(templateColumns));

  const edit = (record) => {
    form.setFieldsValue({
      ...resultObject,
      ...record,
    });

    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const handleChangeType = (value, record, dataIndex) => {
    const newData = [...templateData];
    newData[record.key][dataIndex] = value;
    setTemplateData(newData);

    if (record.type === 'VARCHAR' || record.type === 'CHAR') {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  };

  const handleChangeFile = (info, record, dataIndex) => {
    const newData = [...templateData];
    const uploadedFile = info.file;
    newData[record.key][dataIndex]['image_name'] = uploadedFile.name;
    newData[record.key][dataIndex]['image_path'] = `dep-develop/${uploadedFile.name}`;
    console.log(newData);
    setTemplateData(newData);
    if (record.type === 'VARCHAR' || record.type === 'CHAR') {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  };

  const handleChangeRule = (value, record, dataIndex) => {
    const newData = [...templateData];
    newData[record.key][dataIndex] = value;

    setTemplateData(newData);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...templateData];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        const record = { ...item };
        newData.splice(index, 1, {
          ...record,
        });
        setTemplateData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setTemplateData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleEditableColumn = (item) => {
    switch (item) {
      case 'transformation_rule_names':
      case 'type':
      case 'size':
      case 'editable':
        return true;
      case 'field_parameter':
        return true;
      case 'required_parameter':
        return true;
      case 'image_field':
        return true;
      default:
        return false;
    }
  };

  const columns =
    templateData?.length > 0
      ? Object.keys(templateData[0])
          .filter((item) => item !== 'key' && item !== 'size')
          .map((ele) => {
            if (ele === 'image_field') {
              return {
                title: handleTitle(ele),
                dataIndex: ele,
                editable: handleEditableColumn(ele),
                render: (ele) => `${ele.image_name}`,
              };
            }
            return {
              title: handleTitle(ele),
              dataIndex: ele,
              editable: handleEditableColumn(ele),
            };
          })
      : [];

  const actionColumn = {
    title: 'Action',
    dataIndex: 'action',
    fixed: 'right',
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Stack direction="row" alignItems="center">
          <Tooltip title="Save">
            <IconButton size="medium" disabled={disableForm} color="success" onClick={() => save(record.key)}>
              <AiFillSave />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton size="medium" disabled={disableForm} color="error" onClick={cancel}>
              <AiOutlineCloseCircle />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Tooltip title="Edit">
          <span>
            <IconButton
              size="medium"
              color="primary"
              disabled={editingKey !== '' || disableForm}
              onClick={() => edit(record)}
            >
              <AiFillEdit />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
  };

  const updatedColumns = [...columns, { ...actionColumn }];

  const mergedColumns = updatedColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === 'transformation_rule_names' ||
          col.dataIndex === 'type' ||
          col.dataIndex === 'field_parameter' ||
          col.dataIndex === 'editable'
            ? 'select'
            : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        handleChangeRule,
        handleChangeType,
        handleChangeFile,
        transformationRules,
        onInputChange,
        templateData,
        fetchedHeader,
      }),
    };
  });

  const onInputChange = (e, record, dataIndex) => {
    const newData = [...templateData];
    newData[record.key][dataIndex] = e.target.value;
    setTemplateData(newData);
  };

  useEffect(() => {
    const maxHeightPercentage = 80;
    const maxHeight = (window.innerHeight * maxHeightPercentage) / 100;
    setTableHeight(maxHeight);
  }, []);

  return (
    <Form form={form} component={false}>
      <div
        className="table-container"
        style={{
          maxHeight: tableHeight,
          overflowY: tableHeight ? 'auto' : 'visible',
          border: '2px solid black',
        }}
      >
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={templateData}
          columns={mergedColumns}
          rowClassName="hoverable-row"
          loading={templateData.length <= 0 && loading ? !loading && <Empty /> : false}
          pagination={{
            onChange: cancel,
            pageSize: 5,
          }}
          sticky
        />
      </div>
    </Form>
  );
}

TemplateRules.propTypes = {
  fetchedHeader: PropTypes.any,
  loading: PropTypes.any,
  transformationRules: PropTypes.any,
  setTemplateData: PropTypes.func,
  templateData: PropTypes.shape({
    length: PropTypes.number,
  }),
};
