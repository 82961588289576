import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  paper: {
    height: '73vh',
    background: '#ffffff',
    color: 'black',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    px: 2,
  },
  scrollBar: {
    flex: 1,
    overflowY: 'scroll',
    transition: 'scroll-behaviour 1s ease-out',
    scrollBehavior: 'smooth',
  },
  answer: {
    background: '#FAF9F6',
    paddingRight: '10px',
  },
  messageBox: {
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    background: '#ffffff',
    input: { color: 'black' },
  },
  answerImageBox: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px',
  },
  sendIcon: {
    color: 'silver',
    rotate: '340deg',
    marginBottom: '5px',
  },
  loadingIcon: {
    color: 'black'
  },
});

export default useStyles;
