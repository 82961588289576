import { Grid } from '@mui/material';
import React from 'react';
import AddButton from './AddButton';

export default function PageHeader({ title, btnText, route }) {
  return (
    <Grid container direction="row" display="flex">
      <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
        {title}
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        <AddButton text={btnText} route={route} />
      </Grid>
    </Grid>
  );
}
