import React, { useState, memo, useEffect, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  IconButton,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import LoadingIcon from '../../../../reusable-component/LoadingIcon';
import { getCsvData } from "../../../../api's/RuleEngineApi";
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';
import TreeViewDirectory from '../../../../reusable-component/TreeViewDirectory';
import generateAlias from '../../../../utils/generateAlias';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const RowLevelOperation = {
  ignoreblanklines: false,
  skipheaders: false,
  columnshift: false,
  junkrecords: false,
  linebreak: false,
  delimiter: ',',
};

const NodeModalReadFromFolder = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { workflow: workflowInfo } = useContext(WorkflowContext);
  const INITIALSTATE = {
    path: '',
    format: 'pdf',
    action: '',
    persist: false,
    alias: generateAlias(nodeName, nodeId),
    persist_type: '',
    database: '',
    delimiter: '',
    tablename: '',
    ignoreblanklines: false,
    skipheaders: false,
    columnshift: false,
    junkrecords: false,
    linebreak: false,
    distinct_rows: false,
    clientId: '',
    domainName: workflowInfo?.engagement?.client?.depDomainEntity?.domain_name,
    clientName: workflowInfo.engagement.client.client_name,
    batchName: workflowInfo.engagement.engagement_name,
    tableNameRead: workflowInfo.workflow_name,
    dataSetName: 'English',
    trackingId: '',
    innerPath: '',
    file: '',
    dataTypeRead: 'source',
    pathTypeFile: 'filepath',
    pagination: false,
    lowerBound: '',
    upperBound: '',
    CDC: false,
    partitionColumn: '',
    numPartitions: '',
    sample_view: false,
    priority: '',
  };
  const domainAndClient = `${workflowInfo.engagement.client.depDomainEntity.domain_name}/${INITIALSTATE.clientName}`;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFilePath, setSelectedFilePath] = useState('');
  const [workflow, setWorkflow] = useState();
  const [AllBatch, setAllBatch] = useState([]);
  const [AllTable, setAllTable] = useState([]);
  const [WriteTable, setWriteTable] = useState([]);
  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [flowbuilderRowLevelOperation, setflowbuilderRowLevelOperation] = useState(RowLevelOperation);
  const [isDataLoad, setisDataLoad] = useState(false);
  const [tempHeader, setTempHeader] = useState([]);
  const [errors, setErrors] = useState({ train: '', test: '' });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (formField.path) {
      fetchFileHeader('refernce');
    }
  }, [formField.path]);

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    if (store) {
      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setisDataLoad(true);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  const validate = () => {
    if (formField.pathTypeFile === 'filepath' && !formField.path.includes('.csv')) {
      setErrors({ ...errors, filepath: 'Enter valid file path' });
    } else if (formField.pathTypeFile === 'outputpath' && formField.path.includes('.csv')) {
      setErrors({ ...errors, outputpath: 'Enter valid folder path' });
    } else {
      setErrors({ filepath: '', outputpath: '' });
    }
  };

  useEffect(() => {
    validate();
  }, [formField.path, errors.filepath, errors.outputpath]);

  useEffect(() => {
    setFormField(INITIALSTATE);
    setflowbuilderRowLevelOperation(RowLevelOperation);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    getLocalData();
  }, [nodeId]);

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    setAllBatch([]);
    setAllTable([]);
    setWriteTable([]);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    // const regex = AWS_CONFIG_TYPE === true ? /^s3:\/\//i : /^https:\/\//i;

    // if (!regex.test(formField.path)) {
    //   if (AWS_CONFIG_TYPE) {
    //     enqueueSnackbar('S3 path is invalid!', {
    //       variant: 'error',
    //       autoHideDuration: 3000,
    //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //     });
    //   } else {
    //     enqueueSnackbar('Doc gen2 path is invalid!', {
    //       variant: 'error',
    //       autoHideDuration: 3000,
    //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //     });
    //   }
    //   return;
    // }

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        el.data.label = formField.alias;
      }
    });

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const fetchFileHeader = async (dataType) => {
    if (dataType === 'refernce') {
      // const regex =
      //   workflow.cloudEnvironment === 'AWS'
      //     ? /^s3:\/\/.*csv$/
      //     : workflow.cloudEnvironment === 'AZURE'
      //     ? /^https:\/\/.*csv$/
      //     : workflow.cloudEnvironment === 'SNOWFLAKE'
      //     ? /.*csv$/
      //     : '';
      // if (!regex.test(formField.path)) {
      //   if (workflow.cloudEnvironment === 'AWS') {
      //     enqueueSnackbar('S3 path is invalid!', {
      //       variant: 'error',
      //       autoHideDuration: 3000,
      //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
      //     });
      //   } else if (workflow.cloudEnvironment === 'AZURE') {
      //     enqueueSnackbar('Doc gen2 path is invalid!', {
      //       variant: 'error',
      //       autoHideDuration: 3000,
      //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
      //     });
      //   } else if (workflow.cloudEnvironment === 'SNOWFLAKE') {
      //     enqueueSnackbar('snowflake path is invalid!', {
      //       variant: 'error',
      //       autoHideDuration: 3000,
      //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
      //     });
      //   }
      //   return;
      // }
    }
    setLoadingBtn(true);

    let data = {};
    if (dataType === 'refernce') {
      const pathArr = formField.path.split('/');
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      workflow.cloudEnvironment === 'AZURE' && pathArr.shift(0);
      const newPath = pathArr.join('/');
      data = {
        path: newPath,
        workflow_id: workflow.workflow_id,
      };
    } else {
      const newPath = `${formField.trackingId}${formField.file}`;
      data = {
        path: newPath,
        workflow_id: workflow.workflow_id,
      };
    }

    try {
      const response = await getCsvData(data);
      if (response.status === 200) {
        const header = [];

        response.data.forEach((el) => {
          header.push({
            header: el,
            alias: '',
            checked: false,
            tableAlias: '',
            cast_datatype: 'string',
            default_value: '',
            attribute_type: 'static',
          });
        });

        setFetchedHeader(header);
        setHeaderName([]);
        setTempHeader([]);
      }
    } catch (error) {
      enqueueSnackbar('Path does not exist! Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoadingBtn(false);
  };
  // let name, value;
  // const handleInputChange = (e) => {
  //   name = e.target.name;
  //   if (
  //     name === 'ignoreblanklines' ||
  //     name === 'linebreak' ||
  //     name === 'skipheaders' ||
  //     name === 'columnshift' ||
  //     name === 'junkrecords' ||
  //     name === 'pagination'
  //   ) {
  //     value = e.target.checked;
  //   } else {
  //     value = e.target.value;
  //   }

  //   setFormField({
  //     ...formField,
  //     [name]: value,
  //   });
  // };

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition} style={{ width: '70%', marginLeft: '30%' }}>
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!disableForm}
                  className="outlined-button-color"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>
          <div style={{ margin: '20px 40px' }}>
            <TreeViewDirectory
              setSelectedFilePath={setSelectedFilePath}
              formField={formField}
              setFormField={setFormField}
              disableForm={disableForm}
              setDisableForm={setDisableForm}
              domainAndClient={domainAndClient}
              setLoading={setLoading}
            />
            <Tooltip title={<pre style={{ whiteSpace: 'pre-wrap' }}>{formField.path}</pre>} arrow placement="bottom">
              <TextField
                id="filepath"
                name="path"
                label="Full Path"
                value={formField.path}
                sx={{ margin: '20px 0' }}
                size="small"
                disabled
                fullWidth
                required
              />
            </Tooltip>
          {loading && <LoadingIcon />}
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default memo(NodeModalReadFromFolder);
