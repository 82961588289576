import React, { useCallback, useEffect, useState, useContext } from 'react';
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  MiniMap,
  ReactFlowProvider,
  Background,
  Controls,
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';
import './index.css';
import DLWriteNode from './nodes/DLWriteNode';
import DLReadNode from './nodes/DLReadNode';
import DLDataTransformNode from './nodes/DLDataTransformNode';
import DLOthersNode from './nodes/DLOthersNode';
import DLInnerNode from './nodes/DLInnerNode';
import DLModelNode from './nodes/DLModelNode';
import DLTransformNode from './nodes/DLTransformNode';
import DLEmailNode from './nodes/DLEmailNode';
import DLDataQualityNode from './nodes/DLDataQualityNode';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const nodeTypes = {
  WriteNode: DLWriteNode,
  ReadNode: DLReadNode,
  DataTransformNode: DLDataTransformNode,
  OthersNode: DLOthersNode,
  InnerNode: DLInnerNode,
  ModelNode: DLModelNode,
  TransformNode: DLTransformNode,
  EmailNode: DLEmailNode,
  DataQualityNode: DLDataQualityNode,
};

const getLayoutedElements = (nodes, edges, direction = 'LR') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight }, { incoming: 0, outgoing: 0 });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};
const DataLineageParent = ({ graphNodes, graphEdges }) => {
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(graphNodes, graphEdges);
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)),
    []
  );

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.isMainBlock) {
          return { ...node };
        }
        return node;
      })
    );
  }, [setNodes]);

  return (
    <div className="layoutflow">
      <div style={{ height: 720 }}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            connectionLineType={ConnectionLineType.SmoothStep}
            fitView
          />
          <MiniMap />
          <Controls />
        </ReactFlowProvider>
      </div>
    </div>
  );
};

export default DataLineageParent;
