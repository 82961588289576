import React from 'react';
import { Button } from '@mui/material';

const FileUpload = ({ handleFileChange, handleFileUpload, disableForm, isUploadDisabled }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'right' }}>
      <div>
        {/* <input type="file" required multiple onChange={handleFileChange} disabled={disableForm} /> */}
        <input type="file" required multiple onChange={handleFileChange} />
      </div>

      <div>
        <Button
          size="small"
          type="submit"
          // disabled={isUploadDisabled || disableForm}
          variant="contained"
          onClick={handleFileUpload}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

export default FileUpload;
