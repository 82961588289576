import React, { useState} from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Paper,
  Typography,
  TextField,
  FormControl,
  styled,
} from '@mui/material';
import { GiBroom } from 'react-icons/gi';
import SendIcon from '@mui/icons-material/Send';
import { useSnackbar } from 'notistack';
import DocumentBotHeader from '../../reusable-component/DocumentBotHeader';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import useStyles from './ChatGPTQueryCss';
import question from '../../assets/images/question_1.png';
import chatGPTLogo from '../../assets/images/ChatGPT_logo.png';
import { fetchResult } from "../../api's/TalkToDocumentBotApi";

const ChatGPTDocumentBot = () => {
  const INITIALSTATE = {
    domainList: [],
    clientList: [],
    engagementList: [],
    workflowList: [],
    subDomainList: [],
    modelList: [],
    domain: '',
    client: '',
    engagement: '',
    workflow: '',
    subDomain: '',
    modelName: '',
    language: 'English',
    question: '',
  };
  const [formField, setFormField] = useState(INITIALSTATE);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('English');
  const [questionAnswerList, setQuestionAnswerList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} PopperProps={{ disablePortal: true }} classes={{ popper: className }} arrow placement="top" />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      maxWidth: '800px', // Set the maximum width
      // overflowY: 'auto', // Add vertical scrollbar
      maxHeight: '300px', // Set max height for the tooltip
    },
  }));

  const classes = useStyles();
  const handleFetchResult = async () => {
    const data = {
      user_prompt: formField.question,
      language: formField.language,
      domain: formField.domain,
      client: formField.client,
      engagement: formField.engagement,
      workflow: formField.workflow,
      subdomain: formField.subDomain,
      model: formField.modelName,
    };
    setLoading(true);
    try {
      const response = await fetchResult(data);
      // const response = {
      //   data: {
      //     english_response:
      //       ' A PO is taxed in India at 40% (Plus applicable surcharge as cess). If a PO fails to file its tax return, it will face consequences such as penalties, fines, and additional interest on the unpaid tax.',
      //     file_name: ['po.pdf', 'po.pdf'],
      //     page_no: ['4', '4'],
      //     question: 'How is a PO taxed in India?',
      //     response:
      //       'Un PO est taxé en Inde à 40% (plus le supplément applicable comme cess).Si un PO ne parvient pas à produire sa déclaration de revenus, elle sera confrontée à des conséquences telles que des pénalités, des amendes et des intérêts supplémentaires sur la taxe impayée.',
      //   },
      // };

      setFormField({ ...formField, question: '' });
      const answer = {
        question: formField.question,
        answer: response?.data?.response,
        answerInEnglish: response?.data?.english_response,
        pageNos: response?.data?.page_no,
        fileNames: response?.data?.file_name,
        language: formField.language,
        translatedQuestion: response?.data?.question,
      };
      setQuestionAnswerList([...questionAnswerList, answer]);
    } catch (error) {
      enqueueSnackbar('Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };
  const handleEnterPress = (event) => {
    if (event.charCode === 13) {
      handleFetchResult();
    }
  };

  const handlePrompt = (event) => {
    setFormField({ ...formField, question: event.target.value });
  };

  const handleClearChat = async () => {
    setLoading(true);
    setQuestionAnswerList([]);
    setLoading(false);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          style={{
            flex: 5,
            margin: '0 10px 0px 0',
          }}
        >
          <DocumentBotHeader
            language={language}
            setLanguage={setLanguage}
            formField={formField}
            setFormField={setFormField}
            setLoading={setLoading}
          />
          <Paper className={classes.paper}>
            <Box className={classes.scrollBar}>
              <Box>
                {questionAnswerList.map((item) => (
                  <Box key={item.query_id}>
                    <Stack
                      direction={language === 'Arabic' ? 'row-reverse' : 'row'}
                      alignItems="flex-start"
                      spacing={1}
                      sx={{ paddingRight: '10px' }}
                    >
                      <img
                        src={question}
                        width="30"
                        alt="Nagarro"
                        style={{ filter: 'contrast(200%)', margin: '10px', marginLeft: '33px' }}
                      />
                      {item.language !== 'English' ? (
                        <CustomTooltip
                          title={<div style={{ whiteSpace: 'pre-wrap' }}>{item.translatedQuestion}</div>}
                          arrow
                          placement="top"
                        >
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ py: 1.5, textAlign: language === 'Arabic' ? 'right' : 'left' }}
                          >
                            {item.question}
                          </Typography>
                        </CustomTooltip>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ py: 1.5, textAlign: language === 'Arabic' ? 'right' : 'left' }}
                        >
                          {item.question}
                        </Typography>
                      )}
                    </Stack>
                    <Stack
                      direction={language === 'Arabic' ? 'row-reverse' : 'row'}
                      alignItems="flex-start"
                      spacing={1}
                      className={classes.answer}
                    >
                      <Box className={classes.answerImageBox}>
                        <img
                          src={chatGPTLogo}
                          width="30"
                          alt="Nagarro"
                          style={{ filter: 'contrast(200%)', marginLeft: '25px' }}
                        />
                      </Box>
                      {item.language !== 'English' ? (
                        <CustomTooltip
                          title={<div style={{ whiteSpace: 'pre-wrap' }}>{item.answerInEnglish}</div>}
                          arrow
                          placement="top"
                        >
                          <Typography
                            variant="body2"
                            gutterBottom
                            sx={{ py: 1.5, textAlign: language === 'Arabic' ? 'right' : 'left', flexGrow: 1 }}
                          >
                            {item.answer}
                            <br />
                            {item.fileNames.map((source, i) => (
                              <>
                                <strong>Source:</strong> {source}, <strong>Page No:</strong> {item.pageNos[i]}
                                <br />
                              </>
                            ))}
                          </Typography>
                        </CustomTooltip>
                      ) : (
                        <Typography
                          variant="body2"
                          gutterBottom
                          sx={{ py: 1.5, textAlign: language === 'Arabic' ? 'right' : 'left', flexGrow: 1 }}
                        >
                          {item.answer}
                          <br />
                          {item?.fileNames?.map((source, i) => (
                            <>
                              <strong>Source:</strong> {source}, <strong>Page No:</strong> {item.pageNos[i]}
                              <br />
                            </>
                          ))}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={classes.messageBox}>
              <FormControl
                sx={{
                  width: '88%',
                  my: 1,
                }}
                variant="filled"
              >
                <TextField
                  size="small"
                  placeholder="Send a message..."
                  fullWidth
                  id="fullWidth"
                  variant="outlined"
                  className={classes.message}
                  value={formField.question}
                  onChange={handlePrompt}
                  onKeyPress={handleEnterPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!loading ? (
                          <IconButton
                            aria-label="send-message"
                            onClick={handleFetchResult}
                            disabled={!formField.modelName || !formField.subDomain || !formField.question}
                          >
                            <SendIcon className={classes.sendIcon} />
                          </IconButton>
                        ) : (
                          <CircularProgress size={25} className={classes.loadingIcon} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <Tooltip title="Clear Chat" sx={{ marginLeft: '10px' }}>
                <IconButton aria-label="send-message" onClick={handleClearChat}>
                  <GiBroom />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        </div>
      </div>

      {loading && <LoadingIcon />}
    </>
  );
};

export default ChatGPTDocumentBot;
