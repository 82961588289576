import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const createSampleJsonApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/data-processor/store-sample-json`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchSampleRecordsApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/data-processor/get-sample-view`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const runEmrForSampleRecordApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/data-processor/run-emr-for-sample-record`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getCustomer360SegmentApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/customer-360/getAllSegments`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchPostgresDatabase = async (aws_config_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/postgres-info/get-db-list/${aws_config_type}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchPostgresSchema = async (database, aws_config_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/postgres-info/get-schema-list/${database}/${aws_config_type}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchPostgresTables = async (dbname, schema, aws_config_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/postgres-info/get-table-list/${dbname}/${schema}/${aws_config_type}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchPostgresColumn = async (database, schema, table, aws_config_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(
      `${BASEURL}/postgres-info/get-column-list/${database}/${schema}/${table}/${aws_config_type}`,
      {
        headers: { Authorization: `Bearer ${parseToken}` },
      }
    );
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getUdfNames = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-udf-names`, {
      params: { client_id: data.client_id, path: data.path },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      // await logoutApi();
      console.log('logout');
    }

    throw error;
  }
};

export const fetchOracleDatabase = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/oracle-info/get-db-list`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
export const fetchOracleTables = async (database) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/oracle-info/get-table-list/${database}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchOracleColumn = async (database, table) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/oracle-info/get-column-list/${database}/${table}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const AllFoldersOfS3 = async (client_id, engagement_name, workflow_name, timestamp_type, aws_config_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-s3-folder-list`, {
      params: { client_id, engagement_name, workflow_name, timestamp_type, aws_config_type },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const AllFilesOfS3 = async (prefix, client_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-s3-files-list`, {
      params: { prefix, client_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getCSVFileList = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-csv-file-list`, {
      params: { prefix: data.prefix, client_id: data.client_id, aws_config_type: data.aws_config_type },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getFilesOfS3 = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-s3-files-list`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const downloadFilesOfS3 = async (data) => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  await axios
    .get(`${BASEURL}/data-processor/download-file`, {
      params: data,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${parseToken}` },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.file_path.split('/').at(-1)}`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(async (error) => {
      if (error.response.status === 401) {
        await logoutApi();
      }

      throw error;
    });
};

export const getFolderHeaderList = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-folder-header-list`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getAllEntity = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/dep_entity/get-all-entity`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getAttributeByEntityName = async (entity) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/dep_entity/get-attribute-by-entity-name`, {
      params: { entity_name: entity },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const createHeaderJson = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/data-processor/create-header-json`, data, {
      // params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getDataProcessor = async (workflow_id, execution_mode) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-data-processor`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { workflow_id, execution_mode },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getNodeStatus = async (client_name, engagement_name, workflow_name, cloudEnvironment) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/node-status/get-step-status`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { client_name, engagement_name, workflow_name, cloudEnvironment },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const createDataProcessorApi = async (data, user_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/data-processor/create-data-processor`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { user_id },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getCsvHeaderList = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/data-processor/get-csv-header-list`, data, {
      // params: { user_id },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${parseToken}`,
      },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const runDataProcessorApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/data-processor/execute-data-processor`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const createDataProcessorJobInputApi = async (workflow_id, AWS_CONFIG_TYPE, data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/data-processor/add-job-input`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { workflow_id, AWS_CONFIG_TYPE },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getDataProcessorJobInputApi = async (engagement_id, workflow_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-job-input`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { engagement_id, workflow_name },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const deleteDataProcessorJobInputApi = async (engagement_id, workflow_name) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.delete(`${BASEURL}/data-processor/delete-job-input`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { engagement_id, workflow_name },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchAPIData = async (url) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchHtmlApi = async (path, workflow_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-html`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { path, workflow_id },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchHtmlJsonApi = async (path, config_type, id) => {
  try {
    // const path1 = 'NexaTrend_Enterprise/CustomerCanvasQ3/Reporting_test/MDM/CustomerSegmentation/Reporting_test_Graph_1/CustomerSegmentation.json';
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/read-dq-json`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { prefix: path, client_id: id, aws_config_type: config_type },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getHeadersFromPartFileApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-headers-from-part-file`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getValidationListApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/get-validation-list`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getGoldenRecordsApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/mdm/goldenRecords`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getGroupRecordsApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/mdm/groupRecords`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getBadRecordsApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/mdm/bad-records`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const reviewRecordsApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/mdm/reviewRecords`, {
      params: data,
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getPdf = async (blobName) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/data-processor/read-pdf`, {
      params: { blobName },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      // await logoutApi();
      console.log('logout');
    }

    throw error;
  }
};

export const scheduleWorkflow = async (data, cron_expression, executed_by_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/scheduler/schedule-data-processor`, data, {
      params: { cron_expression, executed_by_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      // await logoutApi();
      console.log('logout');
    }

    throw error;
  }
};
