import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  ButtonGroup,
  IconButton,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  TableContainer,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  MenuItem,
  Modal,
  Tooltip,
  FormControlLabel,
  Switch,
  Grid,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MdDelete } from 'react-icons/md';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Plot from 'react-plotly.js';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import InputField from '../../../reusable-component/InputField';
import customer_segmentation_data from '../../../mock/customer_segmentation_data.json';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
  alias: '',
  df: '',
  sample_view: true,
  persist: false,
  distinct_rows: false,
  persist_type: '',
  action: '',
  // db_name: 'default',
  // statement: '',
};

const NodeModalCustomerSegmentation = ({
  open,
  handleClose,
  nodeId,
  nodeName,
  nodes,
  edges,
  changeNodeName,
  setNodes,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const [fields, setFields] = useState([
    {
      operation: '',
      xAxis: '',
      yAxis: '',
      zAxis: '',
    },
  ]);

  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              header: obj.header,
              fuzzy_match: obj.fuzzy_match,
              checked: true,
              match_type: obj.match_type,
              merge_rule: obj.merge_rule,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              fuzzy_match: obj.fuzzy_match,
              match_type: obj.match_type,
              merge_rule: obj.merge_rule,
            };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              fuzzy_match: obj.fuzzy_match,
              match_type: obj.match_type,
              merge_rule: obj.merge_rule,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: false,
              fuzzy_match: 'false',
              match_type: 'NA',
              merge_rule: 'NA',
            };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          fuzzy_match: n.fuzzy_match ?? 'false',
          checked: true,
          match_type: n.match_type ? n.match_type : 'NA',
          merge_rule: n.merge_rule ? n.merge_rule : 'NA',
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });
    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getSourceData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return {
          ...el,
          header: el.alias ? el.alias : el.header,
          alias: el.alias ? '' : el.alias,
        };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);
      setFetchedHeader(newHeader);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setTempHeader(node.headerName);
          setFields(node.fields);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    getSourceData();
  }, [nodeId]);

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];

    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);
      setFields([
        {
          operation: '',
          xAxis: '',
          yAxis: '',
        },
      ]);
      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setHeaderName(node.headerName);

            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = newHeader.findIndex((x) => x.header === item.header);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            newHeader.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.header === el.header);

              if (index === -1) {
                nonExistingHeader.push({
                  ...el,
                  header: el.alias ? el.alias : el.header,
                  alias: el.alias ? '' : el.alias,
                });
              }
            });

            setFetchedHeader(nonExistingHeader);
            setFields(node.fields);
          }
        });
      } else {
        setFetchedHeader(newHeader);
      }
    }
    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  function hasDuplicateValues(obj) {
    const values = Object.values(obj);
    const nonEmptyValues = values.filter((value) => value !== '');

    return new Set(nonEmptyValues).size !== nonEmptyValues.length;
  }

  const handleFieldChange = (event, index) => {
    const data = [...fields];
    data[index][event.target.name] = event.target.value;

    const hasDuplicates = hasDuplicateValues(data[index]);

    if (hasDuplicates) {
      if (data[index].operation === 'cohort_analysis') {
        enqueueSnackbar('Date, Identifier or Value cannot not be same!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (data[index].operation === 'market_basket_analysis') {
        enqueueSnackbar('Identifier and Item cannot not be same!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('X and Y axis cannot not be same!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      return;
    }
    setFields(data);
  };

  const addFields = () => {
    const newfield = { operation: '', xAxis: '', yAxis: '' };
    setFields([...fields, newfield]);
  };

  const handleDelete = (index) => {
    const deleteValue = [...fields];
    deleteValue.splice(index, 1);
    setFields(deleteValue);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      fields,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '80%', marginLeft: '20%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                Segmentation
              </Typography>

              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  onClick={refreshAllState}
                  aria-label="close"
                  sx={{ color: '#00043C' }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  disabled={disableForm}
                  className="button-color"
                  // onClick={handleFormsubmit}
                >
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <Box
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <InputField
                name="alias"
                label="Alias"
                value={formField.alias}
                onChange={handleInputChange}
                size="small"
                disabled={disableForm}
                required
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={formField.sample_view}
                    onChange={handleChecked}
                    disabled={disableForm}
                  />
                }
                label="Sample View"
                sx={{ p: 1 }}
              />
              {fields.map((item, index) => (
                <Box
                  style={{
                    margin: '20px 0',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    marginRight: '20px',
                  }}
                  key={index}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={2.2}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        required
                        name="operation"
                        label="Operation"
                        size="small"
                        value={item.operation}
                        onChange={(event) => handleFieldChange(event, index)}
                        disabled={disableForm}
                        style={{ width: '100%' }}
                      >
                        <MenuItem value="aggregate">Aggregate</MenuItem>
                        <MenuItem value="cohort_analysis">Cohort Analysis</MenuItem>
                        <MenuItem value="market_basket_analysis">Market Analysis</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        required
                        name="xAxis"
                        label={
                          item.operation === 'cohort_analysis'
                            ? 'Select Date'
                            : item.operation === 'market_basket_analysis'
                            ? 'Select Identifier'
                            : 'Select X Axis'
                        }
                        size="small"
                        value={item.xAxis}
                        onChange={(event) => handleFieldChange(event, index)}
                        disabled={disableForm}
                        style={{ width: '100%' }}
                      >
                        {fetchedHeader.map((ele) => (
                          <MenuItem value={ele.header}>{ele.header}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        required
                        name="yAxis"
                        label={
                          item.operation === 'cohort_analysis'
                            ? 'Select Identifier'
                            : item.operation === 'market_basket_analysis'
                            ? 'Select Item'
                            : 'Select Y Axis'
                        }
                        size="small"
                        value={item.yAxis}
                        onChange={(event) => handleFieldChange(event, index)}
                        disabled={disableForm}
                        style={{ width: '100%' }}
                      >
                        {fetchedHeader.map((ele) => (
                          <MenuItem value={ele.header}>{ele.header}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {item.operation === 'cohort_analysis' && (
                      <Grid item xs={3}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          required
                          name="zAxis"
                          label={item.operation === 'cohort_analysis' ? 'Select Value' : 'Select Z Axis'}
                          size="small"
                          value={item.zAxis}
                          onChange={(event) => handleFieldChange(event, index)}
                          disabled={disableForm}
                          style={{ width: '100%' }}
                        >
                          {fetchedHeader.map((ele) => (
                            <MenuItem value={ele.header}>{ele.header}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    <Grid item xs={0.8}>
                      {index !== 0 && (
                        <IconButton aria-label="expand row" size="large" onClick={() => handleDelete(index)}>
                          <MdDelete />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Button
                // style={{ width: '100px' }}
                variant="outlined"
                onClick={addFields}
                disabled={disableForm}
                className="outlined-button-color"
              >
                Add
              </Button>
            </Box>
            {/* <Stack direction="row" flexWrap="wrap">
              {customer_segmentation_data
                .filter((item) => item.graph_type === 'bar')
                .map((item) => (
                  <Plot
                    data={[
                      {
                        x: item.data.xaxis_values,
                        y: item.data.yaxis_values,
                        type: item.graph_type,
                      },
                    ]}
                    layout={{
                      width: 450,
                      height: 350,
                      title: { text: item.title, font: { size: 14 } },
                      barmode: 'stack',
                      xaxis: {
                        // autotypenumbers: 'strict',
                        // tickmode: 'array',
                        // tickvals: item.data.xaxis_values,
                        // ticktext: item.data.xaxis_values,
                        title: {
                          text: item.xaxis_name,
                          font: { size: 12 },
                        },
                      },
                      yaxis: {
                        title: {
                          text: item.yaxis_name,
                          font: { size: 12 },
                        },
                      },
                      bargap: 0.3,
                      // xaxis: { fixedrange: true },
                      // yaxis: { fixedrange: true },
                    }}
                    config={{ displayModeBar: false }}
                  />
                ))}
              {customer_segmentation_data
                .filter((item) => item.graph_type === 'heatmap')
                .map((item) => (
                  <Plot
                    data={[
                      {
                        x: item.data.xaxis_values,
                        y: item.data.yaxis_values,
                        z: item.data.zaxis_values,
                        type: item.graph_type,
                        colorscale: 'Viridis',
                        hoverongaps: false,
                        hoverinfo: 'none',
                        hovertemplate:
                          'Cohort Index: %{x}<br>%{item.yaxis_name}: %{y}<br>%{item.zaxis_name}: %{z}<extra></extra>',
                      },
                    ]}
                    layout={{
                      width: 580,
                      height: 480,
                      title: { text: item.title, font: { size: 14 } },
                      xaxis: {
                        title: {
                          text: item.xaxis_name,
                          font: { size: 12 },
                        },
                      },
                      yaxis: {
                        title: {
                          text: item.yaxis_name,
                          font: { size: 12 },
                        },
                      },
                      // xaxis: { fixedrange: true },
                      // yaxis: { fixedrange: true },
                    }}
                    config={{ responsive: true, displayModeBar: false }}
                  />
                ))}
            </Stack> */}

            {/* <TableContainer sx={{ maxHeight: 400 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        disabled={disableForm}
                        checked={fetchedHeader.length === headerName.length}
                        onChange={handleSelectAllClick}
                      />
                      Select Columns
                    </TableCell>
                    <TableCell>Columns</TableCell>
                    <TableCell>Select Match</TableCell>
                    <TableCell>Select Merge Rule</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchedHeader.map((row, i) => {
                    const isItemSelected = isSelected(row.header);
                    return (
                      <TableRow
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={i}
                        selected={isItemSelected}
                      >
                        <TableCell width="25%">
                          <Checkbox
                            checked={isItemSelected}
                            disabled={disableForm}
                            onChange={(event) =>
                              handleHeaderChange({
                                header: row.header,
                                checked: event.target.checked,
                                fuzzy_match: row.fuzzy_match ? row.fuzzy_match : 'false',
                                match_type: row.match_type ? row.match_type : 'NA',
                                merge_rule: row.merge_rule ? row.merge_rule : 'NA',
                              })
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                        <TableCell width="25%">{row.header}</TableCell>

                        <TableCell width="25%">
                          <TextField
                            id="standard-select-currency"
                            select
                            label="Select Match"
                            name="match_type"
                            disabled={disableForm}
                            value={row.match_type ? row.match_type : 'NA'}
                            onChange={(event) =>
                              handleHeaderChange({
                                header: row.header,
                                checked: true,
                                fuzzy_match: row.fuzzy_match ? row.fuzzy_match : 'false',
                                match_type: event.target.value,
                                merge_rule: row.merge_rule ? row.merge_rule : 'NA',
                              })
                            }
                            style={{ width: '100%' }}
                            size="small"
                          >
                            <MenuItem value="NA">NA</MenuItem>
                            <MenuItem value="fuzzy">Fuzzy</MenuItem>
                            <MenuItem value="exact">Exact</MenuItem>
                          </TextField>
                        </TableCell>
                        <TableCell width="25%">
                          <TextField
                            id="standard-select-currency"
                            select
                            label="Select Merge Rule"
                            name="merge_rule"
                            disabled={disableForm}
                            value={row.merge_rule ? row.merge_rule : 'NA'}
                            onChange={(event) =>
                              handleHeaderChange({
                                header: row.header,
                                checked: true,
                                fuzzy_match: row.fuzzy_match ? row.fuzzy_match : 'false',
                                match_type: row.match_type ? row.match_type : 'NA',
                                merge_rule: event.target.value,
                              })
                            }
                            style={{ width: '100%' }}
                            size="small"
                          >
                            <MenuItem value="NA">NA</MenuItem>
                            <MenuItem value="Frequency">Frequency</MenuItem>
                            <MenuItem value="Source_priority">Source_priority</MenuItem>
                            <MenuItem value="Higest_weight/quality">Higest_weight/quality</MenuItem>
                            <MenuItem value="Latest_date">Latest_date</MenuItem>
                          </TextField>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer> */}
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeModalCustomerSegmentation;
