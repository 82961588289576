import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getContainerLogDetails } from "../../../api's/LogsApi";

const LogDetails = ({ headerTableData, processType }) => {
  const navigate = useNavigate();

  console.log(headerTableData.prefix);
  const [longText, setLongtext] = useState('');

  const getLogDetails = async () => {
    const prefix = headerTableData.prefix;
    const response = await getContainerLogDetails(prefix);

    if (response.status === 200) {
      setLongtext(response.data);
    }
  };

  useEffect(() => {
    getLogDetails();
  }, []);

  const backToLog = () => {
    if (headerTableData.from === 'rule-engine') {
      navigate(`/workflow-${processType}/logs/rule-engine`, {
        state: {
          workflow_id: headerTableData.workflow_id,
          workflow_name: headerTableData.workflow_name,
          client_name: headerTableData.client_name,
          engagement_name: headerTableData.engagement_name,
          execution_mode: headerTableData.execution_mode,
          created_by: headerTableData.created_by,
          created_at: headerTableData.created_at,
          status: headerTableData.status,
        },
      });
    } else {
      navigate(`/workflow-${processType}/logs/data-processor`, {
        state: {
          workflow_id: headerTableData.workflow_id,
          workflow_name: headerTableData.workflow_name,
          client_name: headerTableData.client_name,
          engagement_name: headerTableData.engagement_name,
          execution_mode: headerTableData.execution_mode,
          created_by: headerTableData.created_by,
          created_at: headerTableData.created_at,
          status: headerTableData.status,
        },
      });
    }
  };

  return (
    <>
      <Button
        onClick={backToLog}
        icon={<ChevronLeftIcon fontsize="small" />}
        size={30}
        style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
      >
        Back
      </Button>

      <div style={{ whiteSpace: 'pre-wrap' }}>{longText}</div>
    </>
  );
};

export default LogDetails;
