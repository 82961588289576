import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Typography,
  Toolbar,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  Menu,
  MenuItem,
  Fade,
  Link,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { tableCellClasses } from '@mui/material/TableCell';
import { TfiMoreAlt } from 'react-icons/tfi';
import { useSnackbar } from 'notistack';
import { createStyles, withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { DateConverter } from '../../utils/DateConverter';
import RedChip from '../../reusable-component/RedChip';
import GreenChip from '../../reusable-component/GreenChip';
import YellowChip from '../../reusable-component/YellowChip';
import { getWorkflowById } from "../../api's/WorkflowApi";
import { getDataProcessor, getNodeStatus } from "../../api's/DataProcessorApi";
import BasicMenu from '../../reusable-component/BasicMenu';
import { WORKFLOW_PAGE } from '../../utils/commonConstants';
import ClientNoData from '../Client/ClientNoData';
import AddWorkflowModal from '../../reusable-component/AddWorkflowModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
    textAlign: 'center',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function ViewWorkflow({
  openWorkflow,
  handleCloseWorkflow,
  tableData,
  engagementName,
  fetchWorkflowTableData,
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const navigateToWorkflow = async (row) => {
    sessionStorage.removeItem('allNodes');
    sessionStorage.removeItem('node');
    sessionStorage.removeItem('elementCount');
    sessionStorage.removeItem('saved_node');
    sessionStorage.removeItem('edges');
    setLoading(true);
    const response = await getWorkflowById(row.workflow_id);

    let result, nodeStatus;
    try {
      result = await getDataProcessor(row.workflow_id, row.execution_mode);
      nodeStatus = await getNodeStatus(
        response.data.engagement.client.client_name,
        response.data.engagement.engagement_name,
        response.data.workflow_name,
        response.data.cloudEnvironment
      );
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      if (result) {
        let elemCount = 0;

        const newSavedElement = [];

        result.data.nodes.forEach((el) => {
          el['nodeId'] = `${el.nodeId}`;
          elemCount++;
          newSavedElement.push(el.id);
        });

        sessionStorage.setItem('allNodes', JSON.stringify(result.data.form_data));
        sessionStorage.setItem('elementCount', elemCount);
        sessionStorage.setItem('node', JSON.stringify(result.data.nodes));
        sessionStorage.setItem('edges', JSON.stringify(result.data.edges));
        sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
        nodeStatus && sessionStorage.setItem('nodeStatus', JSON.stringify(nodeStatus.data));
      }

      navigate('/workflow-ingestion/onboarding', {
        state: {
          workflow_name: row.workflow_name,
          client_name: row.client_name,
          engagement_name: row.engagement_name,
          execution_mode: row.execution_mode,
          created_by: row.created_by,
          created_at: row.created_at,
          status: row.status,
          workflow_data: response.data,
        },
      });
    }
    setLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      open={openWorkflow}
      onClose={handleCloseWorkflow}
      PaperProps={{
        sx: { minWidth: '60%' },
      }}
    >
      <AppBar position="sticky" sx={{ background: '#fff', color: '#000' }}>
        <Toolbar>
          <Box>
            <Typography variant="overline" component="div" sx={{ flexGrow: 1, color: '#8798AD' }}>
              Engagement
            </Typography>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              {engagementName}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: '15px' }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, pl: '10px' }}>
          Workflows({tableData?.length})
        </Typography>
        {tableData.length > 0 ? (
          <TableContainer>
            <Table
              aria-label="simple table"
              style={{ minWidth: 650, borderSpacing: '0px 10px', borderCollapse: 'separate' }}
            >
              <TableHead>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell>Workflow Name</StyledTableCell>
                  <StyledTableCell>Execution Mode</StyledTableCell>
                  <StyledTableCell>CLOUD</StyledTableCell>
                  <StyledTableCell>Created By</StyledTableCell>
                  <StyledTableCell>Created On</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <StyledTableRow key={row.workflow_id}>
                    <StyledTableCell>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          navigateToWorkflow(row, setLoading);
                        }}
                      >
                        {row.workflow_name}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>{row.execution_mode}</StyledTableCell>
                    <StyledTableCell>{row.cloudEnvironment}</StyledTableCell>
                    <StyledTableCell>{row.created_by}</StyledTableCell>
                    <StyledTableCell>{DateConverter(row.created_at)}</StyledTableCell>
                    <StyledTableCell>
                      {row.status === 'approved' ? (
                        <GreenChip title="Approved" />
                      ) : row.status === 'pending' ? (
                        <YellowChip title="Pending" />
                      ) : (
                        <RedChip title="Rejected" />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <BasicMenu row={row} type="Workflow" fetchData={fetchWorkflowTableData} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper elevation={3} sx={{ height: '330px', marginTop: '10px' }}>
            <ClientNoData
              title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
              text={WORKFLOW_PAGE.noWorkflowToReview}
              addBtnText={WORKFLOW_PAGE.addNewWorkflowButton}
              handleOnClick={handleModal}
              isAddBtn
            />
          </Paper>
        )}
        <AddWorkflowModal
          handleModal={handleModal}
          open={openModal}
          fetchTableData={fetchWorkflowTableData}
          processType="Ingestion"
        />
      </Box>
      <AppBar position="sticky" sx={{ top: 'auto', bottom: 0, background: '#fff', width: '100%' }}>
        <Toolbar>
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
            onClick={handleCloseWorkflow}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
    </Drawer>
  );
}
