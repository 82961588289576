import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiBuildings, BiCategory } from 'react-icons/bi';
import { BsBag, BsDatabaseGear, BsFileText } from 'react-icons/bs';
import { FaCheckSquare, FaHome, FaRegCopy, FaRegFileAlt, FaUserCheck } from 'react-icons/fa';
import { HiChartPie } from 'react-icons/hi';
import { ImTree } from 'react-icons/im';
import { MdOutlineAnalytics, MdQueryStats } from 'react-icons/md';
import { RiAdminFill, RiArrowDownSFill, RiArrowUpSFill, RiCheckboxMultipleFill } from 'react-icons/ri';
import { TbChartDots3, TbPlugConnected } from 'react-icons/tb';
import { TiFlowMerge } from 'react-icons/ti';
import { VscGitCompare } from 'react-icons/vsc';

export const menuItem = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <FaHome />,
    level: 0,
  },
  {
    path: '/domain',
    name: 'Domain',
    icon: <BiCategory />,
    level: 0,
  },
  {
    path: '/client',
    name: 'Client',
    icon: <BiBuildings />,
    level: 0,
  },
  {
    path: '/engagement',
    name: 'Engagement',
    icon: <ImTree />,
    level: 0,
  },
  // {
  //   path: '/upload-files',
  //   name: 'Upload Files',
  //   icon: <MdQueryStats />,
  // },
  // {
  //   path: '/workflow',
  //   name: 'WorkFlow',
  //   icon: <TbChartDots3 />,
  // },
  {
    path: '/workflow-data',
    name: 'WorkFlow',
    icon: <TbChartDots3 />,
    dropDownClosed: <RiArrowDownSFill />,
    dropDownOpened: <RiArrowUpSFill />,
    level: 2,
    subNav: [
      {
        path: '/workflow-data',
        name: 'All Workflows',
        icon: <BsBag />,
        level: 0,
      },
      {
        path: '/workflow-data-copy',
        name: 'Data Copy',
        icon: <FaRegCopy />,
        level: 0,
      },
      {
        path: '/workflow-ingestion',
        name: 'Data Ingestion',
        icon: <TiFlowMerge />,
        level: 0,
      },
      {
        path: '/workflow-data-quality',
        name: 'Data Quality',
        icon: <FaCheckSquare />,
        level: 0,
      },
      {
        path: '/workflow-data-transformation',
        name: 'Data Transformation',
        icon: <VscGitCompare />,
        level: 0,
      },
      {
        path: '/workflow-reporting',
        name: 'Reporting',
        icon: <FaRegFileAlt />,
        level: 0,
      },
      {
        path: '/workflow-modelling-analytics',
        name: 'Modelling & Analytics',
        icon: <MdOutlineAnalytics />,
        dropDownClosed: <RiArrowDownSFill />,
        dropDownOpened: <RiArrowUpSFill />,
        level: 1,
        subNav: [
          {
            path: '/workflow-modelling-analytics',
            name: 'Data Science',
            icon: <ImTree />,
            level: 0,
          },
          {
            path: '/workflow-llm',
            name: 'LLM',
            icon: <MdQueryStats />,
            level: 0,
          },
        ],
      },
      {
        path: '/workflow-xperience360',
        name: 'XPerience360',
        icon: <BsDatabaseGear />,
        dropDownClosed: <RiArrowDownSFill />,
        dropDownOpened: <RiArrowUpSFill />,
        level: 1,
        subNav: [
          {
            path: '/workflow-xperience360',
            name: 'Golden Record Creation',
            icon: <ImTree />,
            level: 0,
          },
          {
            path: '/workflow-customer-segmentation',
            name: 'Customer Segmentation',
            icon: <HiChartPie />,
            level: 0,
          },
        ],
      },
      {
        path: '/chat-gpt',
        name: 'Insights',
        icon: <RiAdminFill />,
        dropDownClosed: <RiArrowDownSFill />,
        dropDownOpened: <RiArrowUpSFill />,
        level: 1,
        subNav: [
          {
            path: '/chat-gpt',
            name: 'Talk to Document',
            icon: <ImTree />,
            level: 0,
          },
          {
            path: '/chat-gpt-document-bot',
            name: 'Talk to Document Bot',
            icon: <ImTree />,
            level: 0,
          },
          {
            path: '/chat-gpt-query',
            name: 'Talk to DB',
            icon: <MdQueryStats />,
            level: 0,
          },

          {
            path: '/chat-gpt-file-system',
            name: 'Talk to FS',
            icon: <BsFileText />,
            level: 0,
          },
        ],
      },
    ],
  },
  // {
  //   path: '/chat-gpt',
  //   name: 'Openai Chat GPT',
  //   icon: <RiAdminFill />,
  //   dropDownClosed: <RiArrowDownSFill />,
  //   dropDownOpened: <RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       path: '/chat-gpt',
  //       name: 'Talk to Document',
  //       icon: <ImTree />,
  //     },
  //     {
  //       path: '/chat-gpt-query',
  //       name: 'Talk to DB',
  //       icon: <MdQueryStats />,
  //     },
  //   ],
  // },
  {
    path: '/approval',
    name: 'Admin Console',
    icon: <RiAdminFill />,
    dropDownClosed: <RiArrowDownSFill />,
    dropDownOpened: <RiArrowUpSFill />,
    level: 1,
    subNav: [
      {
        path: '/approval',
        name: 'Approvals',
        icon: <RiCheckboxMultipleFill />,
        level: 0,
      },
      {
        path: '/user-management',
        name: 'User Management',
        icon: <FaUserCheck />,
        level: 0,
      },
      {
        path: '/connections',
        name: 'Connections',
        icon: <TbPlugConnected />,
        level: 0,
      },
      {
        path: '/llm',
        name: 'LLM',
        icon: <MdQueryStats />,
        level: 0,
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    icon: <AiOutlineInfoCircle />,
    level: 0,
  },
];

export const menuItemV1 = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <FaHome />,
    level: 0,
  },
  {
    path: '/client',
    name: 'Client',
    icon: <BiBuildings />,
    level: 0,
  },
  {
    path: '/engagement',
    name: 'Engagement',
    icon: <ImTree />,
    level: 0,
  },
  {
    path: '/workflow-data',
    name: 'WorkFlow',
    icon: <TbChartDots3 />,
    dropDownClosed: <RiArrowDownSFill />,
    dropDownOpened: <RiArrowUpSFill />,
    level: 1,
    subNav: [
      {
        path: '/workflow-data',
        name: 'All Workflows',
        icon: <BsBag />,
        level: 0,
      },
      {
        path: '/workflow-ingestion',
        name: 'Data Ingestion',
        icon: <TiFlowMerge />,
        level: 0,
      },
      {
        path: '/workflow-data-transformation',
        name: 'Data Transformation',
        icon: <VscGitCompare />,
        level: 0,
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    icon: <AiOutlineInfoCircle />,
    level: 0,
  },
];
