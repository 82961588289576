import React, { useState, useEffect, useContext, memo } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  ButtonGroup,
  IconButton,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Grid,
  MenuItem,
  Modal,
  Tooltip,
  FormControl,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import InputField from '../../../reusable-component/InputField';
import { WorkflowContext } from '../../../contexts/WorkflowProvider';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NodeModal_Profiling = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);

  const INITIALSTATE = {
    dataProfiling: `dep-develop/${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Data_Processor/Output/Data_Profiling/profiling`,
    validationResult: `dep-develop/${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Data_Processor/Output/Validation_Result/dataProfiling`,
    action: '',
    persist: false,
    persist_type: '',
    alias: '',
    df: '',
    dataFrame: '',
    sample_view: true,
  };

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getSourceData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      const header = [];
      let prev_alias;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });
      setFetchedHeader(header);
      setHeaderName(header);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.fetchedHeader);
          setFetchedHeader(node.fetchedHeader);
          setTempHeader(node.headerName);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    getSourceData();
  }, [nodeId]);

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];

    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      const header = [];
      let prev_alias;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);

            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = header.findIndex((x) => x.df === item.df);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            header.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.df === el.df);

              if (index === -1) {
                nonExistingHeader.push(el);
              }
            });

            setFetchedHeader(nonExistingHeader);
            setHeaderName(nonExistingHeader);
          }
        });
      } else {
        setFetchedHeader(header);
        setHeaderName(header);
      }
    }
    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition} style={{ width: '70%', marginLeft: '30%' }}>
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{ color: '#00043C' }}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>

              <Tooltip title="Refresh Schema" placement="left">
                <IconButton edge="start" onClick={refreshAllState} aria-label="close" sx={{ color: '#00043C' }}>
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <div style={{ marginBottom: '20px' }}>
              <InputField
                name="alias"
                label="Alias"
                value={formField.alias}
                onChange={handleInputChange}
                size="small"
                disabled={disableForm}
                required
              />
              &emsp;
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={formField.sample_view}
                    onChange={handleChecked}
                    disabled={disableForm}
                  />
                }
                label="Sample View"
                sx={{ pl: 1 }}
              />
            </div>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <FormControl sx={{ minWidth: 500 }}>
                  <InputField
                    name="dataProfiling"
                    label="Data Profiling"
                    value={formField.dataProfiling}
                    onChange={handleInputChange}
                    size="small"
                    disabled
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <FormControl sx={{ minWidth: 500 }}>
                  <InputField
                    name="validationResult"
                    label="Validation Result"
                    value={formField.validationResult}
                    onChange={handleInputChange}
                    size="small"
                    disabled
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-select-currency"
                  select
                  required
                  label="Data Frame"
                  size="small"
                  value={formField.dataFrame}
                  onChange={(event) => {
                    setFormField({
                      ...formField,
                      dataFrame: event.target.value,
                      profilingPath: `dep-develop/${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Data_Processor/Output/Data_Profiling/${event.target.value}/profiling`,
                    });
                  }}
                  disabled={disableForm}
                  style={{ width: '100%', marginTop: '15px' }}
                >
                  {fetchedHeader.map((el, i) => (
                    <MenuItem key={i} value={el.df}>
                      {el.df}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl sx={{ minWidth: 200 }}>
                  <TextField
                    id="outlined-basic"
                    select
                    label="Persist"
                    variant="outlined"
                    value={formField.persist}
                    name="persist"
                    onChange={handleInputChange}
                    sx={{ mt: 2 }}
                    size="small"
                    disabled={disableForm}
                    required
                    InputProps={{
                      style: {
                        fontFamily: "'EB Garamond', serif ",
                        fontWeight: 600,
                      },
                    }}
                    InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                  >
                    <MenuItem value>true</MenuItem>
                    <MenuItem value={false}>false</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              {formField.persist === true && (
                <Grid item xs={4}>
                  <FormControl sx={{ minWidth: 250 }}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Persist Type"
                      variant="outlined"
                      value={formField.persist_type}
                      name="persist_type"
                      onChange={handleInputChange}
                      sx={{ mt: 2 }}
                      size="small"
                      disabled={disableForm}
                      required
                    >
                      <MenuItem value="DISK_ONLY">DISK_ONLY</MenuItem>
                      <MenuItem value="DISK_ONLY_2">DISK_ONLY_2</MenuItem>
                      <MenuItem value="MEMORY_ONLY">MEMORY_ONLY</MenuItem>
                      <MenuItem value="MEMORY_ONLY_2">MEMORY_ONLY_2</MenuItem>
                      <MenuItem value="MEMORY_AND_DISK">MEMORY_AND_DISK</MenuItem>
                      <MenuItem value="MEMORY_AND_DISK_2">MEMORY_AND_DISK_2</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              )}
              <Grid item={3}>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputField
                    name="action"
                    label="Action"
                    value={formField.action}
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default memo(NodeModal_Profiling);
