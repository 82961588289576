export function removeKeyFromArrayObjects(array, keyToRemove) {
  return array.map((obj) => {
    const { [keyToRemove]: _, ...rest } = obj;
    return rest;
  });
}

export function arrangeKeysBasedOnSequence(data, sequence) {
  return data.map((item) => {
    const orderedItem = {};
    sequence.forEach((key) => {
      orderedItem[key] = item[key];
    });
    return orderedItem;
  });
}
