import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { styled } from '@mui/material/styles';
import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Table,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useSnackbar } from 'notistack';
import { getSqlTableData, getSqlQueryData, getSelectedFileData } from "../../api's/ChatGPTApi";
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import ChatGPTFsInput from './ChatGptFsInput';
import FileDropdown from '../../reusable-component/FileDropdown';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: '110ch',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ChatGPTFs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [sqlText, setSqlText] = useState('');
  const [final_rows, setfinal_rows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [sqlQuery, setSqlQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const [path, setPath] = useState('');
  const [expanded, setExpanded] = React.useState();
  const [newData, setNewData] = useState('');
  const [newDataRows, setNewDataRows] = useState([]);
  const [csvPath, setCsvPath] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    // console.log('newDataRows: ', newDataRows);
  });

  const sqlQueryData = async () => {
    if (sqlText === '') {
      enqueueSnackbar('Please enter some text for result.', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }
    try {
      setLoading(true);

      const dataForFile = AWS_CONFIG_TYPE
        ? {
            user_prompt: sqlText,
            path,
          }
        : {
            user_prompt: sqlText,
            path,
            env: `${process.env.REACT_APP_CURRENT_ENV}`,
          };

      const responseForFile = await getSelectedFileData(dataForFile);

      // console.log('response from main file: ', responseForFile);
      setNewData(responseForFile.data.result);
      const rowsData = responseForFile.data.result.split('\n');
      // console.log('rows: ', rowsData);
      // Remove the first row (header) and empty lines
      const dataRows = rowsData.filter(
        (row) => row.trim() !== ''
        // &&
        // !row.includes('dataframe') &&
        // !row.includes('records') &&
        // !row.includes('rows') &&
        // !row.includes('columns')
      );
      // console.log('dataRows: ', dataRows);
      setNewDataRows(dataRows);

      // DON'T REMOVE BELOW COMMENTED CODE

      // const data = {
      //   sql_text: sqlText,
      // };
      // const response = await getSqlQueryData(data);
      // if (response.status === 200) {
      //   const dataTable = {
      //     sql_query: response.data.sql_query,
      //     sql_text: sqlText,
      //   };
      //   setSqlQuery(response.data.sql_query);
      //   const result = await getSqlTableData(dataTable);
      //   if (result.status === 200) {
      //     const heads = [];
      //     result.data.headers.forEach((element) => {
      //       heads.push({
      //         title: element,
      //         dataIndex: element,
      //       });
      //     });
      //     setHeaders(heads);
      //     setfinal_rows(result.data.final_rows);
      //     setIsGraphVisible(result.data.chart);
      //   }
      //   // setIsGraphVisible(response.data.chart);
      //   // setHeaders([]);
      //   // setfinal_rows([]);

      //   enqueueSnackbar('Result Fetched.', {
      //     variant: 'success',
      //     autoHideDuration: 3000,
      //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
      //   });
      // }
    } catch (error) {
      setfinal_rows([]);
      setHeaders([]);
      enqueueSnackbar('This query can not be executed. Please explain your views more thoroughly.', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const sqlTableData = async () => {
    if (sqlQuery === '') {
      enqueueSnackbar('Please enter query.', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }
    try {
      setLoading(true);
      const data = {
        sql_query: sqlQuery,
      };
      const response = await getSqlTableData(data);
      if (response.status === 200) {
        const heads = [];
        response.data.headers.forEach((element) => {
          heads.push({
            title: element,
            dataIndex: element,
          });
        });
        setHeaders(heads);
        setfinal_rows(response.data.final_rows);
        setIsGraphVisible(response.data.chart);
      }
      enqueueSnackbar('Query Table', {
        variant: 'success',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } catch (error) {
      enqueueSnackbar('This query can not be executed. Please explain your views more thoroughly.', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const tableColumns = headers.map((item) => ({ ...item }));

  const barGraphData = [
    {
      x: final_rows.map((item) => item[headers[0]?.title]),
      y: final_rows.map((item) => item[headers[1]?.title]),
      type: 'bar',
      mode: 'lines+markers',
      marker: {
        color: 'rgb(142,124,195)',
      },
    },
  ];

  const pieChartData = [
    {
      values: final_rows.map((item) => item[headers[1].title]),
      labels: final_rows.map((item) => item[headers[0].title]),
      type: 'pie',
    },
  ];

  const pieChartLayout = {
    font: {
      family: 'Raleway, sans-serif',
    },
    title: 'Pie Chart',
    width: 480,
    height: 400,
    showlegend: true,
  };

  const barGraphLayout = {
    font: {
      family: 'Raleway, sans-serif',
    },
    title: 'Bar Graph',
    width: 480,
    height: 400,
    showlegend: true,
    xaxis: {
      tickangle: -25,
      title: headers[0]?.title,
      standoff: 15,
      automargin: true,
    },
    yaxis: {
      zeroline: true,
      gridwidth: 2,
      title: headers[1]?.title,
    },
    bargap: 0.6,
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sqlQueryData();
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      // display: 'flex',
      // justifyContent: 'space-evenly',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <>
      {/* <ChatGPTFsInput setPath={setPath} /> */}
      <FileDropdown setCsvPath={setCsvPath} setPath={setPath} />
      <Box>
        <TextField
          id="standard-basic"
          name="sql_text"
          placeholder="Talk to File System"
          required
          fullWidth
          onChange={(e) => setSqlText(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ marginTop: '10px' }}
          autoComplete="off"
        />
        {/* <Button style={{ width: 200, marginTop: '10px' }} variant="outlined" onClick={sqlQueryData}>
          Proceed
        </Button> */}
        <Button
          size="small"
          type="submit"
          variant="contained"
          sx={{
            marginRight: '8px',
            backgroundColor: '#00043C',
            '&:hover': { backgroundColor: '#00043C' },
            width: 150,
            marginTop: '10px',
            color: '#ffffff',
          }}
          disabled={path === '' || sqlText === ''}
          onClick={sqlQueryData}
        >
          Proceed
        </Button>
      </Box>
      {/* {newDataRows} */}
      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            {newDataRows &&
              newDataRows.map((row) => (
                <StyledTableRow key={row}>
                  <StyledTableCell component="th" scope="row">
                    {row}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* DON'T REMOVE BELOW CODE WHICH IS COMMENTED */}
      {/* LAST COMMENTED */}
      {/* {sqlQuery !== '' && (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ marginTop: '10px' }}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>View Query</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { width: '105ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-multiline-flexible"
                value={sqlQuery}
                label="Query"
                // fullWidth
                multiline
                maxRows={5}
                onChange={(e) => setSqlQuery(e.target.value)}
              />
            </Box>
            <Button style={{ width: 200, marginTop: '10px' }} variant="outlined" onClick={sqlTableData}>
              Fetch
            </Button>
          </AccordionDetails>
        </Accordion>
      )} */}
      {/* {sqlQuery !== '' && (
        <TextField
          id="outlined-multiline-flexible"
          value={sqlQuery}
          label="Query"
          fullWidth
          multiline
          maxRows={5}
          onChange={(e) => setSqlQuery(e.target.value)}
        />
      )}
      <br />
      <br />

      {sqlQuery !== '' && (
        <Button style={{ width: 200 }} variant="outlined" onClick={sqlTableData}>
          Fetch
        </Button>
      )} */}
      {/* LAST COMMENTED */}
      {/* {final_rows.length > 0 && (
        <div style={{ overflow: 'auto', width: '110ch', border: '1px solid #a4a4a4', marginTop: '10px' }}>
          <Table
            rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-light')}
            bordered="true"
            columns={tableColumns}
            dataSource={final_rows}
          />
        </div>
      )}
      {isGraphVisible && (
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={6}>
            {final_rows.length > 0 && <Plot data={barGraphData} layout={barGraphLayout} />}
          </Grid>
          <Grid item xs={6}>
            {final_rows.length > 0 && <Plot data={pieChartData} layout={pieChartLayout} />}
          </Grid>
        </Grid>
      )} */}
      {loading && <LoadingIcon />}
    </>
  );
};

export default ChatGPTFs;
