import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const getViCellFields = async () => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  try {
    return await axios.get(`${BASEURL}/vt/vi-cell/all-fields`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getTransformationRules = async () => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  try {
    return await axios.get(`${BASEURL}/vt/transformation-rules`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getSchemaEntries = async (user_id) => {
  const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  try {
    return await axios.get(`${BASEURL}/vt/get-schema-entry`, {
      params: { user_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};


export const createEntryApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/vt/add-entry`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
