import React, { useState, useContext } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { MdDelete } from 'react-icons/md';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import { createRuleEngineNew, deleteRuleEngine } from "../../api's/RuleEngineApi";
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { AuthContext } from '../../contexts/AuthProvider';

const AddDynamicField = ({
  getTableRules,
  fileNames,
  setFileNames,
  pathName,
  setInitialTableRule,
  setUpdateTableRule,
  setUpdateTableRuleFields,
}) => {
  const { userId } = useContext(AuthContext);
  const { workflow } = useContext(WorkflowContext);
  const { enqueueSnackbar } = useSnackbar();
  const allData = [];
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([
    {
      filePath: '',
      delimiter: '',
    },
  ]);

  const handleAdd = () => {
    const newfield = { filePath: '', delimiter: '' };
    setValue([...value, newfield]);
  };

  const handleChange = (event, index) => {
    const data = [...value];
    data[index][event.target.name] = event.target.value;
    setValue(data);
  };

  const handleAlreadyExistingDelete = async (i, filename) => {
    try {
      setLoading(true);
      const response = await deleteRuleEngine(workflow.workflow_id, filename, AWS_CONFIG_TYPE);
      if (response.status === 200) {
        let deleteFile = [...fileNames];
        console.log(filename);
        deleteFile = deleteFile.filter((item) => item !== filename);
        setFileNames(deleteFile);
        if (pathName === filename) {
          setInitialTableRule([]);
          setUpdateTableRule([]);
          setUpdateTableRuleFields([]);
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const handleDelete = (i) => {
    const deleteValue = [...value];
    deleteValue.splice(i, 1);
    setValue(deleteValue);
  };

  const fetchRuleEngine = async () => {
    setLoading(true);
    const regex =
      workflow.cloudEnvironment === 'AWS'
        ? /^s3:\/\//i
        : workflow.cloudEnvironment === 'AZURE'
        ? /^https:\/\//i
        : workflow.cloudEnvironment === 'SNOWFLAKE'
        ? /.*csv$/
        : '';

    let fileValid = true;
    value.map(async (data, i) => {
      if (!regex.test(data.filePath)) {
        if (workflow.cloudEnvironment === 'AWS') {
          enqueueSnackbar('S3 path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (workflow.cloudEnvironment === 'AZURE') {
          enqueueSnackbar('ADLS gen2 path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (workflow.cloudEnvironment === 'SNOWFLAKE') {
          enqueueSnackbar('snowflake path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        fileValid = false;
      }
    });

    if (!fileValid) return;

    value.map(async (data, i) => {
      const pathArr = data.filePath.split('/');
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      workflow.cloudEnvironment === 'AZURE' && pathArr.shift(0);
      const fileName = pathArr[pathArr.length - 1];
      const newPath = pathArr.join('/');
      const row = {
        path: newPath,
        cloud_path: newPath,
        filename: fileName,
        workflow_id: workflow.workflow_id,
        execution_mode: workflow.execution_mode,
        workflow_type: 'Rule Engine',
        file_type: data.filePath.includes('csv') ? 'csv' : 'json',
        user_id: userId,
        delimiter: data.delimiter,
        aws_config_type: AWS_CONFIG_TYPE,
      };

      allData.push(row);
    });
    try {
      const response = await createRuleEngineNew(allData);
      if (response.status === 201) {
        const newfield = { filePath: '', delimiter: '' };
        setValue([newfield]);
        getTableRules();
      }
    } catch (error) {
      if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Internal Server Error!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  return (
    <>
      {fileNames &&
        fileNames.map((item, i) => {
          return (
            <>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                {i === 0 && (
                  <Typography variant="p" component="p">
                    &nbsp; Already Added files
                  </Typography>
                )}
                <TextField
                  variant="outlined"
                  name="filePath"
                  label=""
                  value={item}
                  // onChange={(e) => handleChange(e, i)}
                  size="small"
                  placeholder={item}
                  disabled
                  InputProps={{
                    style: {
                      fontWeight: 600,
                      fontFamily: "'Roboto Slab', serif",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "'Roboto Slab', serif" },
                  }}
                />
                <IconButton aria-label="expand row" size="large" onClick={() => handleAlreadyExistingDelete(i, item)}>
                  <MdDelete />
                </IconButton>
              </Box>
            </>
          );
        })}
      {fileNames.length !== 0 && <Divider />}
      {value.map((data, i) => {
        return (
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="p" component="p">
              &nbsp; Add New files
            </Typography>
            <TextField
              variant="outlined"
              name="filePath"
              label="Schema File Path"
              value={data.filePath}
              onChange={(e) => handleChange(e, i)}
              required
              size="small"
              InputProps={{
                style: {
                  fontWeight: 600,
                  fontFamily: "'Roboto Slab', serif",
                },
              }}
              InputLabelProps={{
                style: { fontFamily: "'Roboto Slab', serif" },
              }}
            />
            <TextField
              variant="outlined"
              name="delimiter"
              label="Delimiter"
              value={data.delimiter}
              onChange={(e) => handleChange(e, i)}
              required
              size="small"
              InputProps={{
                style: {
                  fontWeight: 600,
                  fontFamily: "'Roboto Slab', serif",
                },
              }}
              InputLabelProps={{
                style: { fontFamily: "'Roboto Slab', serif" },
              }}
            />
            {i !== 0 && (
              <IconButton aria-label="expand row" size="large" onClick={() => handleDelete(i)}>
                <MdDelete />
              </IconButton>
            )}
          </Box>
        );
      })}
      <Grid container direction="row" display="flex" sx={{ mt: 1, mb: -1 }}>
        <Grid item xs={6} direction="row" justifyContent="flex-start">
          <Button
            autoFocus
            variant="contained"
            sx={{
              display: 'flex',
              alignContent: 'flex-start',
              backgroundColor: '#00043C',
              '&:hover': { backgroundColor: '#00043C' },
            }}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          {!loading ? (
            <Button
              autoFocus
              variant="contained"
              sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
              onClick={fetchRuleEngine}
            >
              Fetch
            </Button>
          ) : (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddDynamicField;
