import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
    Button,
    Typography,
    Toolbar,
    Dialog,
    Slide,
    IconButton,
    AppBar,
    ButtonGroup,
    Grid,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Chip,
    Divider,
    Box,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    TextField,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import InputField from '../../../../reusable-component/InputField';
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
    path: '',
    format: '',
    persist: false,
    alias: '',
    snowflake_mode: '',
    snowflake_db_table: '',
    persist_type: '',
    partition: false,
    overwrite: false,
    df: '',
    p_key: '',
    index: '',
    database: '',
    tablename: '',
    db_type: '',
    mode: '',
    schema: '',
    distinct_rows: false,
    sample_view: true,
    glossary: '',
    tag: '',
    owner: '',
    domain: '',
    ownership_type: 'custom',
    description: '',
    file_paths: [],
    dataHubConfigData: [
        {
            id: 0,
            column: '',
            glossaryterm: '',
            tag: '',
            description: ''
        },
    ]
};

const NodeModal_ColumnLevelMetadataIngestion = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {

    const { enqueueSnackbar } = useSnackbar();

    const { workflow } = useContext(WorkflowContext);

    const [disableForm, setDisableForm] = useState(false);
    const [formField, setFormField] = useState(INITIALSTATE);
    const [dataFrameCat, setDataFrameCat] = useState([]);
    const [tableName, setTableName] = useState([]);
    const [fetchedHeader, setFetchedHeader] = useState([]);
    const [headerName, setHeaderName] = useState([]);
    const [refreshModalOpen, setRefreshModalOpen] = useState(false);
    const [dataFrameName, setDataFrameName] = useState([]);
    const [tempHeader, setTempHeader] = useState([]);
    const handleRefreshModalOpen = () => setRefreshModalOpen(true);
    const handleRefreshModalClose = () => setRefreshModalOpen(false);
    const [filePaths, setFilePaths] = useState([]);
    // const [configData, setConfigData] = useState([
    //     {
    //         id: 0,
    //         column: '',
    //         glossaryterm: '',
    //         tag: '',
    //         description: ''
    //     },
    // ]);

    const handleAdd = () => {
        setFormField({
            ...formField,
            dataHubConfigData: [
                ...formField.dataHubConfigData,
                {
                    id: formField.dataHubConfigData[formField.dataHubConfigData.length - 1].id + 1,
                    column: '',
                    glossaryterm: '',
                    tag: '',
                    description: ''
                },

            ]
        })
        // setConfigData([
        //     ...configData,
        //     {
        //         id: configData[configData.length - 1].id + 1,
        //         column: '',
        //         glossaryterm: '',
        //         tag: '',
        //         description: ''
        //     },
        // ]);
    };

    // const onConfigChange = (event, index) => {
    //     const data = [...formField.configData]
    //     data[index][event.target.name] = event.target.value;
    //     setConfigData((prevValue) => data)
    // }

    const handleFormsubmit = async (e) => {
        e.preventDefault();

        // const pathList= [];
        // joinInputs.forEach((el,i)=>{
        //     pathList.push(el.path);
        // })
      

        const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

        if (getAllNodes.length > 0) {
            const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
            sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
        }

        // let y_axis = 0;
        let y_axis;

        nodes.forEach((el) => {
            if (nodeId === el.id) {
                y_axis = parseInt(el.position.x, 10);
                // y_axis = `${parseInt(el.position.y, 10)}`;
                el.data.label = formField.alias;
            }
        });

        const newHeaderName = [];

        fetchedHeader.forEach((item) => {
            newHeaderName.push({ ...item, tableAlias: formField.alias });
        });

        setHeaderName(newHeaderName);

        const newDataFrameSorted = dataFrameCat;

        newDataFrameSorted.sort((a, b) => a.priority - b.priority);

        setFormField({
            ...formField,
            columnsConfig: formField.dataHubConfigData,
        


        });

        const sendFormData = {
            y_axis,
            nodeId,
            nodeName,
            formField: {
                ...formField,
                index:
                    formField.index.toLowerCase() ||
                    `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`.toLowerCase(),
            
            },
            disabled: true,
            step_name: nodeName,
            sorting: newDataFrameSorted,
            filePath: filePaths,
            columnsConfig: formField.dataHubConfigData,
        }

        changeNodeName(nodes);

        setDisableForm(true);

        sessionStorage.setItem('node', JSON.stringify(nodes));
        sessionStorage.setItem('edges', JSON.stringify(edges));

        const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
        fetchNodesData.push(sendFormData);

        sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

        const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
        getElements.push(nodeId);
        sessionStorage.setItem('saved_node', JSON.stringify(getElements));
    };


    useEffect(() => {
        setFormField(INITIALSTATE);
        setDisableForm(false);
        getLocalData();
    }, [nodeId]);

    const getLocalData = () => {

        const findSrcNodeId = [];

        edges.forEach((el) => {
            if (el.target === nodeId) {
                findSrcNodeId.push(el.source);
            }
        });

        const tablename = [];

        if (store) {
            store.forEach((node) => {
                if (
                    node.nodeName !== 'Write' &&
                    (node.nodeName === 'Read' ||
                        node.nodeName === 'ReadPostgres' ||
                        node.nodeName === 'ReadCSV' ||
                        node.nodeName === 'ReadSnowflake')
                ) {
                    tablename.push(node.nodeId);
                }
            });
            setTableName(tablename);
        }

        if (store && findSrcNodeId) {
            const file_paths = [];
            let file_headers = [];
            let isheaderfetched = false;
            store.forEach((node) => {
                const id = findSrcNodeId.find((item) => item === node.nodeId);
                if (
                    id &&
                    node.nodeName !== 'Write' &&
                    (node.nodeName === 'Read' ||
                        node.nodeName === 'ReadPostgres' ||
                        node.nodeName === 'ReadCSV' ||
                        node.nodeName === 'ReadSnowflake')
                ) {
                    console.log(node.formField);
                    const path = node.formField.path;
                    const featchedHeaderArray = node.fetchedHeader
                    if (!file_paths.includes(path)) {
                        file_paths.push(path);
                    }
                    if (!isheaderfetched) {
                        featchedHeaderArray.forEach((headers) => {
                            file_headers.push(headers.header)
                        })
                        isheaderfetched = true
                    } else {
                        featchedHeaderArray.forEach((headers) => {
                            if (!file_headers.includes(headers.header)) {
                                file_headers = []
                                setFormField({
                                    ...formField,
                                    dataHubConfigData: [{
                                        id: 0,
                                        column: '',
                                        glossaryterm: '',
                                        tag: '',
                                        description: ''
                                    },

                                    ]

                                })
                                // setConfigData([
                                //     {
                                //         id: 0,
                                //         column: '',
                                //         glossaryterm: '',
                                //         tag: '',
                                //         description: ''
                                //     },
                                // ])
                            }
                        })
                    }
                }
            });

            setFetchedHeader(file_headers);
            // setFilePaths(file_paths);

            formField.file_paths=file_paths


            store.forEach((node) => {
                if (node.nodeId === nodeId) {
                    setFormField(node.formField);
                    if (node.disabled) {
                        setDisableForm(node.disabled);
                    }
                }
            });
        }
    };
    console.log(formField);


    const store = JSON.parse(sessionStorage.getItem('allNodes'));

    // let name, value;
    const handleInputChange = (e) => {
        // name = e.target.name;
        // value = e.target.value;
        const { name, value } = e.target;


        setFormField({
            ...formField,
            [name]: value,
        });
    };

    // Function to handle changes in dataHubConfigData
    const handleDataHubConfigChange = (index, event) => {
        const { name, value } = event.target;

        // Update the specific field in the dataHubConfigData array
        const updatedConfigData = formField.dataHubConfigData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    [name]: value,  // Update the field with the new value
                };
            }
            return item;  // Return unchanged items
        });

        // Update the state with the new dataHubConfigData
        setFormField((prevState) => ({
            ...prevState,
            dataHubConfigData: updatedConfigData,
        }));
    };


    const handleChecked = (event) => {
        setFormField({ ...formField, sample_view: event.target.checked });
    };

    const refreshAllState = () => {
        handleRefreshModalOpen();
        let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

        let count = 0;

        setNodes((nds) =>
            nds.map((node) => {
                if (count <= updateNodes.length && node.id === updateNodes[count]) {
                    node.position.x -= 0.1;
                    count++;
                }

                return node;
            })
        );

        updateNodes = updateNodes.filter((el) => el !== nodeId);

        sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

        const findSrcNodeId = [];
        edges.forEach((el) => {
            if (el.target === nodeId) {
                findSrcNodeId.push(el.source);
            }
        });

        if (findSrcNodeId.length === 0) {
            setFormField(INITIALSTATE);
            setDisableForm(false);
            setFetchedHeader([]);
            setHeaderName([]);

            enqueueSnackbar('No source node is connected !!!', {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            handleRefreshModalClose();
            return;
        }

        let nodesExist = false;

        store.forEach((node) => {
            if (node.nodeId === nodeId) {
                nodesExist = true;
            }
        });

        if (store && findSrcNodeId) {
            const file_paths = []
            let file_headers = [];
            let isheaderfetched = false;
            let differentHeaders = false;
            store.forEach((node) => {
                const id = findSrcNodeId.find((item) => item === node.nodeId);
                if (
                    id &&
                    node.nodeName !== 'Write' &&
                    (node.nodeName === 'Read' || node.nodeName === 'ReadPostgres' || node.nodeName === 'ReadCSV')
                ) {
                    const path = node.formField.path;
                    const featchedHeaderArray = node.fetchedHeader
                    if (!file_paths.includes(path)) {
                        file_paths.push(path);
                    }

                    if (!isheaderfetched) {
                        featchedHeaderArray.forEach((headers) => {
                            file_headers.push(headers.header)
                        })
                        isheaderfetched = true
                    } else {
                        featchedHeaderArray.forEach((headers) => {
                            if (!file_headers.includes(headers.header)) {
                                file_headers = []
                                differentHeaders = true
                                setFormField({
                                    ...formField,
                                    dataHubConfigData: [{
                                        id: 0,
                                        column: '',
                                        glossaryterm: '',
                                        tag: '',
                                        description: ''
                                    },

                                    ]

                                })
                                // setConfigData([
                                //     {
                                //         id: 0,
                                //         column: '',
                                //         glossaryterm: '',
                                //         tag: '',
                                //         description: ''
                                //     },
                                // ])
                            }
                        })
                    }

                }
            });
            setFetchedHeader(file_headers);
            setFilePaths(file_paths);
            if (differentHeaders) {
                enqueueSnackbar('source with different headers are connected !!!', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                });
                handleRefreshModalClose();
                return
            }
        }

        enqueueSnackbar('Data Refreshed!!!', {
            variant: 'Success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        handleRefreshModalClose();
    }

    const handleResetForm = () => {
        setFormField(INITIALSTATE);
        // setConfigData([{
        //     id: 0,
        //     column: '',
        //     glossaryterm: '',
        //     tag: '',
        //     description: ''
        // }])
    };

    const handleEdit = (e) => {
        e.preventDefault();
        setDisableForm(false);

        store.forEach((node) => {
            if (node.nodeId === nodeId) {
                setTempHeader(headerName);
            }
        });

        const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

        getAllNodes.forEach((el) => {
            if (el.nodeId === nodeId) {
                el['disabled'] = false;
            }
        });

        sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

        const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

        const newSavedElement = getElements.filter((el) => el !== nodeId);

        sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
    };
    console.log(formField.dataHubConfigData)
    return (<div>
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            style={{ width: '70%', marginLeft: '30%' }}
        >
            <form autoComplete="off" onSubmit={handleFormsubmit}>
                <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
                    <Toolbar>
                        <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                            {nodeName}
                        </Typography>
                        <Tooltip title="Refresh Schema" placement="left">
                            <IconButton
                                disabled={disableForm}
                                edge="start"
                                onClick={refreshAllState}
                                aria-label="close"
                                sx={{ color: '#00043C' }}
                            >
                                <RotateLeftIcon />
                            </IconButton>
                        </Tooltip>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={handleResetForm}
                                disabled={disableForm}
                                className="outlined-button-color"
                            >
                                Clear
                            </Button>
                            <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                                Edit
                            </Button>
                            <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                                Submit
                            </Button>
                        </ButtonGroup>
                    </Toolbar>
                </AppBar>
                <div style={{ margin: '20px' }}>
                    <Grid container spacing={2} sx={{ m: 1 }}>
                        <Grid item xs={4}>
                            <InputField
                                name="alias"
                                label="Alias"
                                value={formField.alias}
                                onChange={handleInputChange}
                                size="small"
                                disabled={disableForm}
                                required
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={formField.sample_view}
                                        onChange={handleChecked}
                                        disabled={disableForm}
                                    />
                                }
                                label="Sample View"
                                sx={{ pt: 2 }}
                            />
                        </Grid>

                    </Grid>
                    <Divider><Chip label="MetaData Ingestion" size="small" /></Divider>
                    {fetchedHeader.length !== 0 &&
                        <Grid container spacing={2} sx={{ m: 1 }}>
                            {formField?.dataHubConfigData?.map((data, i) => (
                                <Grid container key={data} spacing={2} sx={{ m: 0.1 }}>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="outlined-basic"
                                            select
                                            label="Column Name"
                                            variant="outlined"
                                            name="column"
                                            value={data.column}
                                            onChange={(e) => handleDataHubConfigChange(i, e)}  // Pass the index and event

                                            // onChange={handleInputChange}


                                            // value={data.column}
                                            // onChange={(e) => onConfigChange(e, i, name)}
                                            size="small"
                                            fullWidth
                                            sx={{ mt: 1 }}
                                            disabled={disableForm}
                                            required
                                            InputProps={{
                                                style: {
                                                    fontWeight: 600,
                                                },
                                            }}
                                        >
                                            {fetchedHeader
                                                .map((item, id) => (
                                                    <MenuItem key={id} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            name="glossaryterm"
                                            label="Glossary Term"
                                            size="small"
                                            value={data.glossaryterm}
                                            onChange={(e) => handleDataHubConfigChange(i, e)}  // Pass the index and event

                                            // onChange={handleInputChange}
                                            // value={data.glossaryterm}
                                            // onChange={(e) => onConfigChange(e, i, name)}
                                            sx={{ mt: 1 }}
                                            disabled={disableForm}
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            name="tag"
                                            label="Tag"
                                            size="small"
                                            value={data.tag}
                                            onChange={(e) => handleDataHubConfigChange(i, e)}  // Pass the index and event

                                            // onChange={handleInputChange}
                                            // value={data.tag}
                                            // onChange={(e) => onConfigChange(e, i, name)}
                                            sx={{ mt: 1 }}
                                            disabled={disableForm}
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            name="description"
                                            label="Description"
                                            size="small"
                                            value={data.description}
                                            onChange={(e) => handleDataHubConfigChange(i, e)}  // Pass the index and event

                                            // onChange={handleInputChange}
                                            // value={data.description}
                                            // onChange={(e) => onConfigChange(e, i, name)}
                                            sx={{ mt: 1 }}
                                            disabled={disableForm}
                                            style={{ width: '100%' }}
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item>
                                <Button
                                    variant="contained"
                                    sx={{
                                        display: 'flex',
                                        alignContent: 'flex-start',
                                        backgroundColor: '#00043C',
                                        '&:hover': { backgroundColor: '#00043C' },
                                    }}
                                    onClick={handleAdd}
                                    disabled={disableForm}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </div>
            </form>

        </Dialog>
    </div>)
}

export default NodeModal_ColumnLevelMetadataIngestion;