import React, { useState } from 'react';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteFileApi, downloadFileApi, getPdf } from "../api's/BIApi";
import { DateConverter } from '../utils/DateConverter';
import LoadingIcon from './LoadingIcon';

const BasicTable = ({ relativeFilePaths, cloudEnvironment, fileMetadataList }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = async (relativeFilePath, cloudEnvironment) => {
    enqueueSnackbar('File Downloading...', {
      variant: 'warning',
      autoHideDuration: 1000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });

    await downloadFileApi(relativeFilePath, cloudEnvironment);

    enqueueSnackbar('File Downloaded', {
      variant: 'success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  const fetchPdf = async (relativePath) => {
    try {
      const { data, status } = await getPdf(cloudEnvironment, relativePath);

      if (status === 200) {
        const pdfBlob = new Blob([data], { type: 'application/pdf' });
        const pdfURL = URL.createObjectURL(pdfBlob);
        const newWindow = window.open(pdfURL, '_blank');
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleViewPdf = async (relativePath) => {
    // Set loading to true when the user clicks on the "View" icon
    setIsLoading(true);

    // Call the fetchPdf function
    await fetchPdf(relativePath);

    // Set loading to false when the operation is complete
    setIsLoading(false);
  };

 
  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bolder' }}>Files</TableCell>
            <TableCell sx={{ minWidth: 200 }} align="center" style={{ fontWeight: 'bolder' }}>
              Created On
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
              View
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
              Download
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {relativeFilePaths &&
            relativeFilePaths.map((relativeFilePath, i) => (
              <TableRow key={relativeFilePath} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {fileMetadataList && fileMetadataList[i].filename}
                </TableCell>
                <TableCell align="center">
                  <Typography>{fileMetadataList && DateConverter(fileMetadataList[i].createdOn)}</Typography>
                </TableCell>
                <TableCell align="center">
                  <FileOpenIcon
                    style={{ color: '#00043c', cursor: 'pointer' }}
                    onClick={() => handleViewPdf(relativeFilePath)}
                  />
                  {isLoading && <LoadingIcon />}
                </TableCell>
                <TableCell align="center">
                  <DownloadIcon
                    style={{ color: '#00043c', cursor: 'pointer' }}
                    onClick={() => downloadFile(relativeFilePath, cloudEnvironment)}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
