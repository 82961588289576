import { Divider, IconButton, Paper, Typography } from '@mui/material';

import { Box } from '@mui/system';
import React from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { DateConverter } from '../utils/DateConverter';
import GreenChip from './GreenChip';
import RedChip from './RedChip';
import YellowChip from './YellowChip';

const PageHeaderTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box style={{ width: '35px' }}>
        <IconButton size="small" aria-label="delete" onClick={() => navigate(-1)}>
          <MdArrowBackIosNew />
        </IconButton>
      </Box>
      <Paper sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="overline" sx={{ color: '#8798AD', fontWeight: 'bold' }}>
              {data.firstHeader}
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ color: '#00043C' }}>
              {data.firstHeaderText}
            </Typography>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        {/* {data.domainName && (
          <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="overline" sx={{ color: '#8798AD', fontWeight: 'bold' }}>
                DOMAIN
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: '#00043C' }}>
                {data.domainName}
              </Typography>
            </Box>
          </Box>
        )} */}

        <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="overline" sx={{ color: '#8798AD', fontWeight: 'bold' }}>
              {data.secondHeader}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#00043C' }}>
              {data.secondHeaderText}
            </Typography>
          </Box>
        </Box>
        {data.thirdHeader && (
          <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="overline" sx={{ color: '#8798AD', fontWeight: 'bold' }}>
                {data.thirdHeader}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: '#00043C' }}>
                {data.thirdHeaderText}
              </Typography>
            </Box>
          </Box>
        )}
        <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="overline" sx={{ color: '#8798AD', fontWeight: 'bold' }}>
              {data.fourthHeader}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#00043C' }}>
              {data.fourthHeaderText}
            </Typography>
          </Box>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="overline" sx={{ color: '#8798AD', fontWeight: 'bold' }}>
              {data.fifthHeader}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#00043C' }}>
              {data.fifthHeaderText}
            </Typography>
          </Box>
        </Box>
        {data.sixthHeader && (
          <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="overline" sx={{ color: '#8798AD', fontWeight: 'bold' }}>
                {data.sixthHeader}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: '#00043C' }}>
                {DateConverter(data.sixthHeaderText)}
              </Typography>
            </Box>
          </Box>
        )}
        {data.seventhHeader && (
          <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="overline" sx={{ color: '#8798AD', fontWeight: 'bold' }}>
                {data.seventhHeader}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: '#00043C' }}>
                {data.seventhHeaderText === 'approved' ? (
                  <GreenChip title="Approved" />
                ) : data.seventhHeaderText === 'pending' ? (
                  <YellowChip title="Pending" />
                ) : (
                  <RedChip title="Rejected" />
                )}
              </Typography>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default PageHeaderTable;
