import React, { useContext } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  Typography,
} from '@mui/material';
import { RiFileDownloadLine } from 'react-icons/ri';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { downloadFilesOfS3 } from "../../api's/DataProcessorApi";
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import LoadingIcon from '../../reusable-component/LoadingIcon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  height: 500,
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function ResultModal({ openResultModal, handleCloseResultModal, fileList, loading, setLoading }) {
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);

  const downloadFile = async (label) => {
    const value = fileList.find((item) => label === item.label).value;
    const data = {
      file_path: value,
      client_id: workflow.engagement.client.client_id,
      aws_config_type: AWS_CONFIG_TYPE,
    };

    try {
      setLoading(true);
      const response = await downloadFilesOfS3(data);

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', label);
        link.click();
        window.URL.revokeObjectURL(url);
        enqueueSnackbar('File downloaded successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } catch (error) {
      enqueueSnackbar('Download failed!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  return (
    <Box>
      <Dialog
        open={openResultModal}
        onClose={handleCloseResultModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll="paper"
        onClick={(e) => {
          e.stopPropagation();
        }}
        PaperProps={{
          style: {
            backgroundColor: '#f8f8ff',
            minWidth: '50vw',
          },
        }}
      >
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              Prediction Result
              {handleCloseResultModal ? (
                <IconButton
                  aria-label="close"
                  onClick={handleCloseResultModal}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </DialogTitle>
            <Divider />
            <DialogContent>
              {fileList?.length <= 0 ? (
                <Typography
                  variant="h6"
                  component="h6"
                  gutterBottom
                  display="flex"
                  alignContent="center"
                  justifyContent="center"
                  sx={{ background: '#fff', padding: 1 }}
                >
                  No records found
                </Typography>
              ) : (
                fileList
                  .filter((item) => item.label.includes('.csv'))
                  .map((item) => (
                    <List dense sx={{ background: '#fff', border: '5px solid #f8f8ff' }}>
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => downloadFile(item.label)}>
                          <ListItemIcon>
                            <RiFileDownloadLine size="1.5em" />
                          </ListItemIcon>
                          <Link sx={{ fontSize: '14px' }}>{item.label}</Link>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  ))
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
}
