import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
    Button,
    Typography,
    Toolbar,
    Dialog,
    Slide,
    IconButton,
    AppBar,
    ButtonGroup,
    TextField,
    Grid,
    Tooltip,
    Divider,
    Chip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import InputField from '../../../../reusable-component/InputField';
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
    path: '',
    format: '',
    persist: false,
    alias: '',
    snowflake_mode: '',
    snowflake_db_table: '',
    persist_type: '',
    partition: false,
    overwrite: false,
    df: '',
    df_list:[],
    p_key: '',
    index: '',
    database: '',
    tablename: '',
    db_type: '',
    mode: '',
    schema: '',
    distinct_rows: false,
    sample_view: true,
    glossary: '',
    tag: '',
    owner: '',
    domain: '',
    ownership_type: 'custom',
    description: '',
    file_paths: []



};

const NodeModal_TableLevelMetadataIngestion = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { workflow } = useContext(WorkflowContext);

    const [disableForm, setDisableForm] = useState(false);
    const [formField, setFormField] = useState(INITIALSTATE);
    const [tableName, setTableName] = useState([]);
    const [headerName, setHeaderName] = useState([]);
    const [fetchedHeader, setFetchedHeader] = useState([]);
    const [refreshModalOpen, setRefreshModalOpen] = useState(false);
    const handleRefreshModalOpen = () => setRefreshModalOpen(true);
    const handleRefreshModalClose = () => setRefreshModalOpen(false);
    const [joinInputs, setJoinInputs] = useState([]);
    const [headerRow, setHeaderRow] = useState([]);
    const [tempHeader, setTempHeader] = useState([]);
    const [count, setCount] = useState({});
    const [combineHeader, setCombineHeader] = useState({});
    console.log(workflow)

    

    // const [filePaths, setFilePaths] = useState([]);

    // const [glossary, setGlossary] = useState('');
    // const [tag, setTag] = useState('');
    // const [owner, setOwner] = useState('');
    // const [domain, setDomain] = useState('');
    // const [ownerShipType, setOwnerShipType] = useState('Custom');
    // const [description, setDescription] = useState('');

    
    
    const handleFormsubmit = async (e) => {
        e.preventDefault();

        const dfList=[];
        const pathList= [];
        joinInputs.forEach((el,i)=>{
            // console.log(i,' ',el)
            dfList.push(el.alias);
            pathList.push(el.path);
        })
        // console.log({dfList})
        const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    
        if (getAllNodes.length > 0) {
          const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
          sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
        }
    
        let y_axis;
    
        nodes.forEach((el) => {
          if (nodeId === el.id) {
            y_axis = parseInt(el.position.x, 10);
            // y_axis = `${parseInt(el.position.y, 10)}`;
            el.data.label = formField.alias;
          }
        });
    
        const tables = {};
    
        joinInputs.forEach((el, i) => {
          tables[`table${i + 1}`] = el.alias;
        });
    
        const joinCond = [];
    
        headerRow.forEach((el) => {
          joinCond.push(`${el.table1}=${el.table2}`);
        });
    
        const selectCols = [];
    
        headerName.forEach((el) => {
          selectCols.push(el.header);
        });
    
        const newHeaderName = [];
    
        headerName.forEach((item) => {
          newHeaderName.push({ ...item, tableAlias: formField.alias });
        });
    
        let equalArray;
        if (tempHeader?.length > 0) {
          equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
        }
    
        const connectedNodes = [];
    
        if (!equalArray && tempHeader?.length > 0) {
          edges.forEach((el) => {
            if (el.source === nodeId) connectedNodes.push(el.target);
          });
    
          let count = 0;
    
          setNodes((nds) =>
            nds.map((node) => {
              if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
                node.position.x += 0.1;
                count++;
              }
    
              return node;
            })
          );
        }
    
        sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));
    
        const columns = [...newHeaderName];
        setHeaderName(newHeaderName);
    
        const statementHeader = [...newHeaderName];
        const TableList = [...joinInputs];
    
        let modifiedStatement = '';
        TableList.map((table, idx) => {
          let boolean = false;
          let cols = '';
          statementHeader.map((row) => {
            if (table.alias === row.header.split('.')[0]) {
              boolean = true;
              cols += `${row.header.split('.')[1]} ${row.alias !== '' ? `AS ${row.alias} ,` : ' ,'} `;
            }
          });
          const index1 = cols.lastIndexOf(',');
          cols = cols.substring(0, index1);
          modifiedStatement += boolean === true ? ` SELECT ${cols}FROM ${table.alias} UNION` : '';
        });
    
        const index2 = modifiedStatement.lastIndexOf(' ');
        modifiedStatement = modifiedStatement.substring(0, index2);
    
        setFormField({
          ...formField,
          tables,
          df_list:dfList,
          file_paths:pathList,
          ownership_type:'custom',
          domain:workflow?.engagement?.client?.depDomainEntity?.domain_name
        });
    
        const newArr = [];
    
        columns.forEach((el) => {
          newArr.push({ ...el, header: el.header.split('.')[1] });
        });
    
        const uniqueObjects = getDistinctObjects(newArr, 'header');
    
        const sendFormData = {
          y_axis,
          nodeId,
          nodeName,
          formField: {
            ...formField,
            index:
                    formField.index.toLowerCase() ||
                    `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`.toLowerCase(),
            tables,
            df_list:dfList,
            file_paths:pathList,
            ownership_type:'custom',
            domain:workflow?.engagement?.client?.depDomainEntity?.domain_name
          },
          disabled: true,
          step_name: nodeName,
                  };
    
        changeNodeName(nodes);
    
        setDisableForm(true);
    
        sessionStorage.setItem('node', JSON.stringify(nodes));
        sessionStorage.setItem('edges', JSON.stringify(edges));
    
        const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
        fetchNodesData.push(sendFormData);
    
        sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));
    
        const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
        getElements.push(nodeId);
        sessionStorage.setItem('saved_node', JSON.stringify(getElements));
      };
    


      const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
        return (
          first_array_of_objects.length === second_array_of_objects.length &&
          first_array_of_objects.every((element_1) =>
            second_array_of_objects.some(
              (element_2) =>
                element_1.header === element_2.header &&
                element_1.tableAlias === element_2.tableAlias &&
                element_1.alias === element_2.alias &&
                element_1.checked === element_2.checked
            )
          )
        );
      };

      function getDistinctObjects(array, property) {
        const seen = new Set();
        return array.filter((obj) => {
          const value = obj[property];
          if (!seen.has(value)) {
            seen.add(value);
            return true;
          }
          return false;
        });
      }
    useEffect(() => {
        setFormField(INITIALSTATE);
        setDisableForm(false);
        getSourceData();
        setFetchedHeader([]);
        setHeaderName([]);
        setHeaderRow([]);
        setCombineHeader({});
        setCount({});
    }, [nodeId]);

    const getSourceData = () => {
        const findSrcNodeId = [];
        edges.forEach((el) => {
          if (el.target === nodeId) {
            findSrcNodeId.push(el.source);
          }
        });
    
 
    
        if (store && findSrcNodeId) {
          let fetchedCount;
    
          const sourceData = [];
          const countObj = {};
          let header = [];
          let prev_alias;
          let prev_nodeName;
    
          findSrcNodeId.forEach((item, i) => {
            store.forEach((node) => {
              if (node.nodeId === item) {
                sourceData.push(node.formField);
                header.push(...node.headerName);
                prev_alias = node.formField.alias;
                prev_nodeName = node.step_name;
              }
            });
            countObj[`count_${i + 1}`] = 1;
          });
    
          setFormField({ ...formField, df: prev_alias });
    
          
    
          setCount(countObj);
    
          const newArr = [];
    
          if (prev_nodeName === 'Join') {
            header.forEach((el) => {
              const exist = el.header.split('.').length;
    
              if (exist === 2) {
                const head = el.header.split('.')[1];
                newArr.push({ ...el, header: el.alias ? el.alias : head });
              } else {
                newArr.push({ ...el });
              }
            });
          }
          if (newArr.length > 0) {
            header = newArr;
          }
    
          const uniqueArray = header;
    
          const aliasHeader = [];
    
          uniqueArray.forEach((el) => {
            aliasHeader.push({
              ...el,
              header: el.alias ? `${el.tableAlias}.${el.alias}` : `${el.tableAlias}.${el.header}`,
              alias: el.alias ? '' : el.alias,
            });
          });
    
          setJoinInputs(sourceData);
          setFetchedHeader(aliasHeader);
    
          const headObj = {};
          headObj[`combine_header_3`] = aliasHeader.filter((el) => {
            if (el.tableAlias === sourceData[0].alias || el.tableAlias === sourceData[1].alias) {
              return el;
            }
          });
    
          for (let i = 2; i < sourceData.length; i++) {
            const indexHead = aliasHeader.filter((el) => {
              if (el.tableAlias === sourceData[i].alias) {
                return el;
              }
            });
            headObj[`combine_header_${i + 2}`] = headObj[`combine_header_${i + 1}`].concat(indexHead);
          }
    
          setCombineHeader(headObj);
          
    
          store.forEach((node) => {
            if (node.nodeId === nodeId) {
         
              setFormField(node.formField);
              setHeaderName(node.headerName);
              setTempHeader(node.headerName);
              setFetchedHeader(node.fetchedHeader);
              setHeaderRow(node.onRow);
              if (node.count) fetchedCount = node.count;
    
              if (fetchedCount) {
                setCount(fetchedCount);
              }
    
              if (node.disabled) {
                setDisableForm(node.disabled);
              }
            }
          });
        }

        if (store && findSrcNodeId) {
            const file_paths = []
            store.forEach((node) => {
                const id = findSrcNodeId.find((item) => item === node.nodeId);
                if (
                    id &&
                    node.nodeName !== 'Write' &&
                    (node.nodeName === 'Read' ||
                        node.nodeName === 'ReadPostgres' ||
                        node.nodeName === 'ReadCSV' ||
                        node.nodeName === 'ReadSnowflake')
                ) {
                    const path = node.formField.path;
                    if (!file_paths.includes(path)) {
                        file_paths.push(path);
                    }
                }
            });

            // setFilePaths(file_paths);
            formField.file_paths=file_paths

            store.forEach((node) => {
                if (node.nodeId === nodeId) {
                    setFormField(node.formField);
      
                    if (node.disabled) {
                        setDisableForm(node.disabled);
                    }
                }
            });
        }
      };



    const store = JSON.parse(sessionStorage.getItem('allNodes'));

    let name, value;
    const handleInputChange = (e) => {
        name = e.target.name;
        value = e.target.value;

        setFormField({
            ...formField,
            [name]: value,
        });
    };

    const handleChecked = (event) => {
        setFormField({ ...formField, sample_view: event.target.checked });
    };

    const refreshAllState = () => {
        handleRefreshModalOpen();
    
        let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];
    
        let count = 0;
    
        setNodes((nds) =>
          nds.map((node) => {
            if (count <= updateNodes.length && node.id === updateNodes[count]) {
              node.position.x -= 0.1;
              count++;
            }
    
            return node;
          })
        );
    
        updateNodes = updateNodes.filter((el) => el !== nodeId);
    
        sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));
    
        const findSrcNodeId = [];
        edges.forEach((el) => {
          if (el.target === nodeId) {
            findSrcNodeId.push(el.source);
          }
        });
    
        if (findSrcNodeId.length < 2) {
          handleClose();
          enqueueSnackbar('Connect at least 2 blocks', {
            variant: 'warning',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          return;
        }
    
        if (findSrcNodeId.length === 0) {
          setFormField(INITIALSTATE);
          setDisableForm(false);
          setFetchedHeader([]);
          setHeaderName([]);
    
          enqueueSnackbar('No source node is connected !!!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          handleRefreshModalClose();
          return;
        }
    
        let nodesExist = false;
    
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            nodesExist = true;
          }
        });
    
        if (store && findSrcNodeId) {
          let fetchedCount;
    
          const sourceData = [];
          const countObj = {};
          let header = [];
          let prev_alias;
          let prev_nodeName;
    
          findSrcNodeId.forEach((item, i) => {
            store.forEach((node) => {
              if (node.nodeId === item) {
                sourceData.push(node.formField);
                header.push(...node.headerName);
                prev_alias = node.formField.alias;
                prev_nodeName = node.step_name;
              }
            });
            countObj[`count_${i + 1}`] = 1;
          });
    
          setFormField({ ...formField, df: prev_alias });
    
          if (sourceData.length < 2) {
            handleClose();
            enqueueSnackbar('Connected block does not have data!', {
              variant: 'warning',
              autoHideDuration: 3000,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            return;
          }
    
          setCount(countObj);
    
          const newArr = [];
    
          if (prev_nodeName === 'Join') {
            header.forEach((el) => {
              const exist = el.header.split('.').length;
    
              if (exist === 2) {
                const head = el.header.split('.')[1];
                newArr.push({ ...el, header: el.alias ? el.alias : head });
              } else {
                newArr.push({ ...el });
              }
            });
          }
          if (newArr.length > 0) {
            header = newArr;
          }
    
          const uniqueArray = header;
    
          const aliasHeader = [];
    
          uniqueArray.forEach((el) => {
            aliasHeader.push({
              ...el,
              header: el.alias ? `${el.tableAlias}.${el.alias}` : `${el.tableAlias}.${el.header}`,
              alias: el.alias ? '' : el.alias,
            });
          });
    
          setJoinInputs(sourceData);
          setFetchedHeader(aliasHeader);
    
          const headObj = {};
          headObj[`combine_header_3`] = aliasHeader.filter((el) => {
            if (el.tableAlias === sourceData[0].alias || el.tableAlias === sourceData[1].alias) {
              return el;
            }
          });
    
          for (let i = 2; i < sourceData.length; i++) {
            const indexHead = aliasHeader.filter((el) => {
              if (el.tableAlias === sourceData[i].alias) {
                return el;
              }
            });
            headObj[`combine_header_${i + 2}`] = headObj[`combine_header_${i + 1}`].concat(indexHead);
          }
    
          if (nodesExist) {
            store.forEach((node) => {
              if (node.nodeId === nodeId) {
                setFormField(node.formField);
                setHeaderName(node.headerName);
                setHeaderRow(node.onRow);
                if (node.count) fetchedCount = node.count;
    
                if (fetchedCount) {
                  setCount(fetchedCount);
                }
    
                const nonExistingHeader = [];
                node.fetchedHeader.forEach((item) => {
                  const index = uniqueArray.findIndex((x) => x.header === item.header);
    
                  if (index !== -1) {
                    nonExistingHeader.push(item);
                  }
                });
    
                uniqueArray.forEach((el) => {
                  const index = nonExistingHeader.findIndex((x) => x.header === `${el.alias}.${el.header}`);
    
                  if (index === -1) {
                    nonExistingHeader.push({
                      ...el,
                      header: el.alias ? `${el.tableAlias}.${el.alias}` : `${el.tableAlias}.${el.header}`,
                      alias: el.alias ? '' : el.alias,
                    });
                  }
                });
    
                setFetchedHeader(nonExistingHeader);
              }
            });
          } else {
            setCombineHeader(headObj);
          }
        }
    
        enqueueSnackbar('Data Refreshed!!!', {
          variant: 'Success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        handleRefreshModalClose();
      };

    const handleResetForm = () => {
        setFormField(INITIALSTATE);
        // setGlossary('');
        // setTag('');
        // setOwner('')
        // setDescription('');
    };

    const handleEdit = (e) => {
        e.preventDefault();
        setDisableForm(false);

        const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

        getAllNodes.forEach((el) => {
            if (el.nodeId === nodeId) {
                el['disabled'] = false;
            }
        });

        sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

        const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

        const newSavedElement = getElements.filter((el) => el !== nodeId);

        sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
    };

    return (<div>
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            style={{ width: '70%', marginLeft: '30%' }}
        >
            <form autoComplete="off" onSubmit={handleFormsubmit}>
                <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
                    <Toolbar>
                        <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                            {nodeName}
                        </Typography>
                        <Tooltip title="Refresh Schema" placement="left">
                            <IconButton
                                disabled={disableForm}
                                edge="start"
                                onClick={refreshAllState}
                                aria-label="close"
                                sx={{ color: '#00043C' }}
                            >
                                <RotateLeftIcon />
                            </IconButton>
                        </Tooltip>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={handleResetForm}
                                disabled={disableForm}
                                className="outlined-button-color"
                            >
                                Clear
                            </Button>
                            <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                                Edit
                            </Button>
                            <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                                Submit
                            </Button>
                        </ButtonGroup>
                    </Toolbar>
                </AppBar>
                <div style={{ margin: '20px' }}>
                    <Grid container spacing={2} sx={{ m: 1 }}>
                        <Grid item xs={4}>
                            <InputField
                                name="alias"
                                label="Alias"
                                value={formField.alias}
                                onChange={handleInputChange}
                                size="small"
                                disabled={disableForm}
                                required
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={formField.sample_view}
                                        onChange={handleChecked}
                                        disabled={disableForm}
                                    />
                                }
                                label="Sample View"
                                sx={{ pt: 2 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider><Chip label="MetaData Ingestion" size="small" /></Divider>
                    {formField?.file_paths?.length !== 0 &&
                        < Grid container spacing={2}>
                            <Grid container spacing={2} sx={{ m: 1 }}>
                                <Grid item xs={4}>
                                    <TextField
                                        name="glossary"
                                        label="Glossary Term"
                                        size="small"
                                        value={formField.glossary}
                                        onChange={handleInputChange}
                                        disabled={disableForm}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="tag"
                                        label="Tags"
                                        size="small"
                                        value={formField.tag}
                                        onChange={handleInputChange}
                                        disabled={disableForm}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="owner"
                                        label="Owners"
                                        size="small"
                                        value={formField.owner}
                                        onChange={handleInputChange}
                                        disabled={disableForm}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="domain"
                                        label="Domain"
                                        size="small"
                                        value={formField.domain}
                                        onChange={handleInputChange}
                                        disabled
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="ownership_type"
                                        label="OwnerShip Type"
                                        size="small"
                                        value={formField.ownership_type}
                                        onChange={handleInputChange}
                                        disabled
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        name="description"
                                        label="Description"
                                        size="small"
                                        onChange={handleInputChange}
                                        value={formField.description}
                                        disabled={disableForm}
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </div>
            </form>
        </Dialog>
    </div >)
}

export default NodeModal_TableLevelMetadataIngestion;




















