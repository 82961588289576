import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import { DateConverter } from '../../utils/DateConverter';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import ApproveModal from '../../reusable-component/ApproveModal';
import RejectModal from '../../reusable-component/RejectModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function WorkflowTable({ tableData, fetchTableData }) {
  const [page, setPage] = useState(1);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [message, setMessage] = useState('');
  const [workflowId, setWorkflowId] = useState('');
  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleApproveModal = (row) => {
    setOpenApproveModal(!openApproveModal);
    if (!openApproveModal) {
      setMessage(`Are you sure you want to approve the workflow "${row.workflow_name}"`);
      setWorkflowId(row.workflow_id);
    }
  };

  const handleRejectModal = (row) => {
    setOpenRejectModal(!openRejectModal);
    if (!openRejectModal) {
      setMessage(`Are you sure you want to reject the workflow "${row?.workflow_name}"`);
      setWorkflowId(row.workflow_id);
    }
  };

  return (
    <Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          style={{ minWidth: 650, borderSpacing: '0px 4px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Workflow Name</StyledTableCell>
              <StyledTableCell>Engagement Name</StyledTableCell>
              <StyledTableCell>Client Name</StyledTableCell>
              <StyledTableCell>Execution Mode</StyledTableCell>
              <StyledTableCell>Process Type</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.workflow_id}>
                {console.log(row)}
                <StyledTableCell>{row.workflow_name}</StyledTableCell>
                <StyledTableCell>{row.engagement_name}</StyledTableCell>
                <StyledTableCell>{row.client_name}</StyledTableCell>
                <StyledTableCell>{row.execution_mode}</StyledTableCell>
                <StyledTableCell>{row.process_type}</StyledTableCell>
                <StyledTableCell>{row.created_by}</StyledTableCell>
                <StyledTableCell>{DateConverter(row.created_at)}</StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" spacing={2}>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ background: '#27CC00', '&:hover': { backgroundColor: '#27CC00' } }}
                      onClick={() => handleApproveModal(row)}
                    >
                      Approve
                    </Button>
                    <Button size="small" variant="contained" color="error" onClick={() => handleRejectModal(row)}>
                      Reject
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ApproveModal
        openApproveModal={openApproveModal}
        handleApproveModal={handleApproveModal}
        fetchData={fetchTableData}
        type="workflow"
        id={workflowId}
        message={message}
      />
      <RejectModal
        openRejectModal={openRejectModal}
        handleRejectModal={handleRejectModal}
        fetchData={fetchTableData}
        type="workflow"
        id={workflowId}
        message={message}
      />
      <PaginationFooter tableData={tableData} page={page} handleChangePage={handleChangePage} />
    </Box>
  );
}
