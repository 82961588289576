import React, { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import LoginForm from '../reusable-component/LoginForm';
import graphicImage from '../images/graphic.svg';
import lamp from '../images/lamp.svg';
import themeStyles from '../utils/theme';
import { AuthContext } from '../contexts/AuthProvider';
import { MAIN } from '../utils/commonConstants';

export default function Login() {
  const classes = themeStyles();
  // const theme = process.env.REACT_APP_THEME;
  const theme = 'nagarro';
  const { isDep, setIsDep } = useContext(AuthContext);

  useEffect(() => {
    setIsDep(true);
  }, []);

  return (
    <Grid container style={{ height: '100vh' }} className={classes[`${theme}ThemeColor`]}>
      <Grid item xs={7}>
        <Grid item xs={12} style={{ height: '50%', marginLeft: '35px' }}>
          <img src={lamp} alt="login" style={{ marginLeft: '25px', width: '160px', height: '180px' }} />
          <Typography
            variant="h4"
            gutterBottom
            component="h4"
            sx={{ marginTop: '10px', color: '#FFFFFF', textAlign: 'left' }}
          >
            {isDep ? MAIN.dep : MAIN.xperience360}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={5} component={Paper} elevation={6} square sx={{ borderTopLeftRadius: '80px' }}>
        <Box
          className="LoginForm"
          sx={{
            my: 5,
            mx: 18,
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
          }}
        >
          <LoginForm />
        </Box>
      </Grid>
      <img
        src={graphicImage}
        alt="login"
        style={{
          position: 'absolute',
          width: '380px',
          height: '294px',
          marginLeft: '30.5%',
          marginTop: '24%',
        }}
      />
    </Grid>
  );
}
