import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const deleteEngagementApi = async (engagement_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.delete(`${BASEURL}/engagement/delete-engagement`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { engagement_id },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const createEngagementApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/engagement/create-engagement`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getEngagementByUserGroupAndClientIdApi = async (group, client_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/engagement/get-engagement-by-userGroup-and-clientId`, {
      params: {
        user_group: group,
        client_id,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getEngagementByUserGroupAndDomainIdApi = async (group, domain_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/engagement/get-engagement-by-userGroup-and-domainId`, {
      params: {
        user_group: group,
        domain_id,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllEngagementsApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/engagement/get-all-engagement`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllEngagementByDomainIdApi = async (domain_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/engagement/get-all-engagement-by-domainId`, {
      params: {
        domain_id,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getEngagementByUserGroupApi = async (group) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/engagement/get-engagement-by-userGroup`, {
      params: {
        user_group: group,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getPendingEngagementApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/engagement/get-pending-engagement`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const approveEngagementApi = async (engagement_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/engagement/approve-engagement`, {
      params: { engagement_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const rejectEngagementApi = async (engagement_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/engagement/reject-engagement`, {
      params: { engagement_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
