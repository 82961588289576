import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { FaEdit, FaInfoCircle, FaTrashAlt } from 'react-icons/fa';
import Plot from 'react-plotly.js';
import { Tooltip } from '@mui/material';
import { updateTileTitle } from "../../api's/ReportingApi";
import ViewTable from '../reports/ViewTable';

function HistogramChart({ props, handleDeleteTile }) {
  const [isTrashVisible, setIsTrashVisible] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [isViewTable, setIsViewTable] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const nameRegex = /^[a-zA-Z0-9 ]{1,50}$/;
  const titleInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const histogramChartData = [
    {
      y: props?.data[0]?.data?.xaxis_values,
      x: props?.data[0]?.data?.yaxis_values,
      type: 'histogram',
      marker: {
        color: 'skyblue',
      },
    },
  ];

  const histogramChartLayout = {
    font: {
      family: 'Raleway, sans-serif',
    },
    title: '',
    width: 480,
    height: 400,
    // showlegend: true,
    yaxis: {
      title: {
        text: props?.data[0]?.xaxis_name,
        font: { size: 12 },
      },
    },
    xaxis: {
      title: {
        text: props?.data[0]?.yaxis_name,
        font: { size: 12 },
      },
    },
  };

  const handleEditTile = async () => {
    setIsEditingTitle(false);
    if (title.trim() === '') {
      setTitle(props.title);
      return;
    }
    if (!title.match(nameRegex)) {
      setTitle(props.title);
      if (title.length > 50) {
        enqueueSnackbar('ONLY 50 CHARACTERS ARE ALLOWED!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('NO SPECIAL CHARACTER ALLOWED!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      return;
    }

    try {
      const response = await updateTileTitle(props.rg_id, title);
    } catch (error) {
      setTitle(props.title);
      enqueueSnackbar('Error while saving Title!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (isEditingTitle && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [isEditingTitle]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setIsEditingTitle(true);
    }
  };

  return (
    <div
      style={{ position: 'relative', width: '480px', height: '400px' }}
      onMouseEnter={() => setIsTrashVisible(true)}
      onMouseLeave={() => setIsTrashVisible(false)}
    >
      {isEditingTitle ? (
        <input
          type="text"
          value={title}
          ref={titleInputRef}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={() => handleEditTile()}
          style={{
            position: 'absolute',
            top: '32px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '5px',
            fontSize: '16px',
            zIndex: 10,
          }}
        />
      ) : (
        <div
          role="button"
          tabIndex={0}
          style={{
            position: 'absolute',
            top: '30px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '5px',
            fontSize: '18px',
            cursor: 'default',
            zIndex: 10,
            // fontWeight: '500',
          }}
          // onClick={() => setIsEditingTitle(true)}
          // onKeyDown={handleKeyDown}
        >
          {title !== '' ? title : `Analysis of ${props?.data[0]?.yaxis_name} by ${props?.data[0]?.xaxis_name}`}
        </div>
      )}

      <Plot data={histogramChartData} layout={histogramChartLayout} />

      {isTrashVisible && (
        <>
          <button
            style={{
              position: 'absolute',
              top: '12%',
              right: '10px',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              border: 'none',
              background: 'none',
              padding: '0',
              outline: 'none',
              // color: 'grey',
            }}
            onClick={() => {
              setIsEditingTitle(true);
            }}
          >
            <FaEdit />
          </button>

          <Tooltip title="View Data">
            <button
              style={{
                position: 'absolute',
                top: '19%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
                padding: '0',
                outline: 'none',
                // color: 'grey',
              }}
              onClick={() => {
                setIsViewTable(true);
              }}
            >
              <FaInfoCircle />
            </button>
          </Tooltip>

          <button
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              border: 'none',
              background: 'none',
              padding: '0',
              outline: 'none',
            }}
            onClick={() => {
              handleDeleteTile(props.rg_id);
            }}
          >
            <FaTrashAlt />
          </button>
        </>
      )}
      {isViewTable && (
        <ViewTable
          props={props?.data[0]}
          fileName={props?.fileName}
          setIsViewTable={setIsViewTable}
          title={props.title}
        />
      )}
    </div>
  );
}

export default HistogramChart;
