import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Typography,
  Toolbar,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  ButtonGroup,
  Grid,
  Box,
  Modal,
  Tooltip,
  FormControlLabel,
  Checkbox,
  MenuItem,
  TextField,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
// import LoadingIcon from "../../../../reusable-component/LoadingIcon";
// import InputField from '../../../../reusable-component/InputField';
// import { VoyagerCellApi } from "../../../../services/VoyagerCellApi";
import TemplateRules from './TemplateRules';
// import { InstrumentApi } from "../../../../services/InstrumentApi";
// import { useAxios } from "../../../../contexts/AxiosProvider";
import { TRANSFORMATION_RULES } from '../../../../mock/transformationRules';
import eachInstrumentJsonData, { EACH_INSTRUMENT } from '../../../../mock/eachInstrument';
import LoadingIcon from '../../../../reusable-component/LoadingIcon';
import InputField from '../../../../reusable-component/InputField';
import { INSTRUMENT_DATA } from '../../../../mock/instrument';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const nameRegex = /^[a-zA-Z0-9_]{1,35}$/;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
  alias: '',
  sample_view: true,
  df: '',
  each_instrument: [],
  instrument_name: '',
  instrument_id: '',
};
const NodeModalInstrument = ({
  nodeId,
  nodeName,
  handleClose,
  open,
  nodes,
  edges,
  changeNodeName,
  setNodes,
  workflow,
}) => {
  // console.log(transformationRules);
  const { enqueueSnackbar } = useSnackbar();
  const [formField, setFormField] = useState(INITIALSTATE);
  console.log(formField);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [instrumentData, setInstrumentData] = useState(INSTRUMENT_DATA);
  const [eachInstrumentData, setEachInstrumentData] = useState(EACH_INSTRUMENT);
  const location = useLocation();
  const [templateData, setTemplateData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [transformationRules, setTransformationRules] = useState(TRANSFORMATION_RULES);
  const [loading, setLoading] = useState(false);
  const [aliasError, setAliasError] = useState('');
  // const axios = useAxios();

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = async () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const newHeader = uniqueArray.map((el) => {
        return {
          ...el,
          header: el.alias ? el.alias : el.header,
          alias: el.alias ? '' : el.alias,
        };
      });

      //
      const head = [];
      const coalesce = [];
      newHeader.forEach((el) => {
        head.push({ label: el.header });
        coalesce.push({
          header: el.header,
          coalesce: [],
          alias: '',
          selected: false,
        });
      });

      setHeaders(head);
      setFetchedHeader(newHeader);
      //   await createInitialViCellData(newHeader);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          const hhh = node.formField.instrument.each_instrument.map((item, index) => {
            return { ...item, key: index };
          });
          setTemplateData(hhh);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  const fetchAllInstruments = async () => {
    try {
      setLoading(true);
      // const data = {
      //   user_id: location.state.userId, // Provide the user_id
      // };
      // const response = await InstrumentApi.getAllInstruments(axios, data);
      // if (response.status === 200) {
      //   setInstrumentData(response.data);
      // }
      // setInstrumentData(instrumentJsonData.data);
      // setInstrumentData(instrumentJsonData);
    } catch (error) {
      enqueueSnackbar('Internal Server Error!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    // fetchAllInstruments();
    getLocalData();
  }, [nodeId]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    // Validate alias field against nameRegex only if it has a value
    if (name === 'alias') {
      if (fieldValue && !nameRegex.test(fieldValue)) {
        setAliasError('Special characters are not allowed');
      } else {
        setAliasError('');
      }
    }
    setFormField((prev) => ({
      ...prev,
      [name]: fieldValue,
    }));
  };

  const handleResetForm = async () => {
    console.log('Ewe');
    createInitialViCellData(fetchedHeader, formField.instrument_id);
    setFormField(INITIALSTATE);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const getInstrumentById = async (instrumentId) => {
    try {
      setLoading(true);
      // const response = await InstrumentApi.getEachInstrumentById(
      //   axios,
      //   instrumentId
      // );
      // return response.data;
      // return eachInstrumentJsonData.data;
    } catch (error) {
      console.error('Error fetching field data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInstrumentName = (event) => {
    const instrumentId = event.target.value;

    const instrumentCellData = instrumentData.data.find((item) => item.instrument_id === instrumentId);
    if (instrumentCellData) {
      setFormField({
        ...formField,
        instrument_name: instrumentCellData.instrument_name,
        instrument_id: instrumentCellData.instrument_id,
      });
      createInitialViCellData(fetchedHeader, instrumentId);
    }
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();
    if (editingKey !== '') {
      enqueueSnackbar('One of the Editing is open', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    templateData.map((item) => {
      newHeaderName.push({
        header: item.fieldname,
        alias: '',
        checked: '',
        tableAlias: formField.alias,
      });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);
    const sendFormData = {
      nodeId,
      nodeName,
      formField: {
        instrument_id: formField.instrument_id,
        instrument: { ...formField, each_instrument: templateData },
        alias: formField.alias,
        instrument_name: formField.instrument_name,
        df: formField.df,
      },
      disabled: true,
      step_name: nodeName,
      y_axis,
      headerName: newHeaderName,
      fetchedHeader,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);
    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  const fetchTransformationRules = async () => {
    try {
      // const response = await VoyagerCellApi.getTransformationRules(axios);
      // setTransformationRules(response.data);
      // setTransformationRules(transformationRulesJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchTransformationRules();
    // setEachInstrumentData(eachInstrumentJsonData);
    // setTransformationRules(transformationRulesJson);
    // setInstrumentData(instrumentJsonData);
  }, []);

  const createInitialViCellData = async (headers, instrumentId) => {
    // const ViCellFields = await getInstrumentById(instrumentId);
    const ViCellFields = eachInstrumentData.data;
    const ViCellData = [];
    ViCellFields.map((ele, index) => {
      let flag = false;
      ele.default_field_value !== null &&
        headers.forEach((prev_header) => {
          if (ele.default_field_value.includes(prev_header.header)) {
            flag = true;
          }
        });

      const viCellRow = {
        fieldname: ele.fieldName,
        type: ele.dataType,
        size: 50,
        editable: ele.editable,
        transformation_rule_names: '',
        field_parameter: flag ? ele.default_field_value : null,
        // image_field: {
        //   image_name: '',
        //   image_path: ''
        // }
      };
      ViCellData.push(viCellRow);
    });

    setTemplateData(
      ViCellData.map((item, index) => {
        return { ...item, key: index };
      })
    );
  };

  console.log(workflow);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '70%', marginLeft: '30%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <Grid container spacing={2}>
              <Box sx={{ '& > :not(style)': { m: 2, width: '32ch' } }}>
                <InputField
                  name="alias"
                  label="Alias"
                  value={formField?.alias || ''}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                  required
                  error={!!aliasError}
                  helperText={aliasError}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formField?.sample_view}
                      onChange={handleChecked}
                      disabled={disableForm}
                    />
                  }
                  label="Sample View"
                  sx={{ pl: 1 }}
                />
              </Box>
            </Grid>
            <Box>
              <TextField
                id="outlined-select-currency"
                select
                label="Instrument Name"
                size="small"
                value={formField?.instrument_id}
                onChange={handleInstrumentName}
                disabled={disableForm}
                sx={{ width: '32ch' }}
              >
                {instrumentData.data.map((item) => {
                  if (item.domain_name === workflow.engagement.client.depDomainEntity.domain_name) {
                    return (
                      <MenuItem key={item.instrument_id} value={item.instrument_id}>
                        {item.instrument_name}
                      </MenuItem>
                    );
                  }
                })}
              </TextField>
            </Box>

            <br />
            {templateData.length !== 0 && (
              <TemplateRules
                fetchedHeader={fetchedHeader}
                transformationRules={transformationRules.data}
                templateData={templateData}
                setTemplateData={setTemplateData}
                loading={loading}
                editingKey={editingKey}
                setEditingKey={setEditingKey}
                disableForm={disableForm}
              />
            )}
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeModalInstrument;
