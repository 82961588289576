import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Typography,
  Toolbar,
  Slide,
  IconButton,
  AppBar,
  ButtonGroup,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  Checkbox,
  Autocomplete,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Modal,
  Tooltip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import InputField from '../../../reusable-component/InputField';
import LoadingIcon from '../../../reusable-component/LoadingIcon';

import { getKpiDetails, getKpiList } from "../../../api's/KpiApi";
import { WorkflowContext } from '../../../contexts/WorkflowProvider';
import generateAlias from '../../../utils/generateAlias';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NodeModal_KPI = ({ nodeId, nodeName, handleClose, open, nodes, edges, changeNodeName, setNodes }) => {
  const INITIALSTATE = {
    action: '',
    persist: false,
    alias: generateAlias(nodeName, nodeId),
    persist_type: '',
    db_name: '',
    statement: '',
    distinct_rows: false,
    kpi: '',
    kpi_id: '',
    sample_view: true,
  };

  const { enqueueSnackbar } = useSnackbar();
  // const { batch } = useContext(BatchContext);
  const { workflow } = useContext(WorkflowContext);
  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [toggleType, setToggleType] = useState('KPI');
  const [kpiList, setKpiList] = useState([]);
  const [kpiLHSColumn, setKpiLHSColumn] = useState([]);
  const [kpiRHSColumn, setKpiRHSColumn] = useState([]);
  const [kpiLHSMapping, setkpiLHSMapping] = useState([]);
  const [firstTimeFetch, setfirstTimeFetch] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);
  const [otherFieldvalue, setOtherFieldValue] = useState({ value: '', index: '', closeType: 'cancel' });

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const [otherTextFieldDialogopen, setOtherTextFieldDialogopen] = useState(false);
  const handleotherTextFieldDialogClose = (event, reason) => {
    console.log({ value: otherFieldvalue.value, index: otherFieldvalue.index, closeType: reason });
    setOtherFieldValue({ value: otherFieldvalue.value, index: otherFieldvalue.index, closeType: reason });
    if (reason !== 'backdropClick') {
      setOtherTextFieldDialogopen(false);
      const data = [...kpiLHSMapping];
      data[otherFieldvalue.index]['value'] = otherFieldvalue.value;
    }
  };
  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setKpiRHSColumn((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { header: obj.header, alias: obj.alias, checked: true };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, header: obj.header, alias: obj.alias };
          }

          return object;
        })
      );

      setKpiRHSColumn((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, header: obj.header, alias: obj.alias };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setKpiRHSColumn((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, checked: false, alias: '' };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = kpiRHSColumn.map((n) => {
        const all = {
          header: n.header,
          alias: n.alias,
          checked: true,
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });

    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    fetchKpiList();

    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      let read_aliases = [];
      if (store) {
        read_aliases = store.filter((ele, i) => {
          if (
            ele.nodeName === 'Read' ||
            // ele.nodeName === 'CopyADLS' ||
            ele.nodeName === 'ReadCSV' ||
            ele.nodeName === 'ReadPDF' ||
            ele.nodeName === 'ReadPublishedData' ||
            ele.nodeName === 'ReadDelimited' ||
            ele.nodeName === 'ReadOracle' ||
            ele.nodeName === 'ReadPostgres' ||
            ele.nodeName === 'ReadMySql' ||
            ele.nodeName === 'ReadSnowflake' ||
            ele.nodeName === 'Read Real-Time' ||
            ele.nodeName === 'FileUploader'
          ) {
            return ele.formField;
          }
        });
      }
      const head = [];
      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      read_aliases.forEach((read_alias) => {
        head.push({ label: read_alias.formField.alias });
      });
      head.push({ label: 'Others' });

      setHeaders(head);
      setFetchedHeader(newHeader);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setfirstTimeFetch(true);
          setkpiLHSMapping(node.kpiLhsCol);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setToggleType(node.toggleType);
          setKpiRHSColumn(node.kpiRHSCol);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    setfirstTimeFetch(false);
    setkpiLHSMapping([]);
    getLocalData();
  }, [nodeId]);

  const refreshAllState = () => {
    handleRefreshModalOpen();
    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];
    let count = 0;
    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }
        return node;
      })
    );
    updateNodes = updateNodes.filter((el) => el !== nodeId);
    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      //
      const head = [];
      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setfirstTimeFetch(true);
            setkpiLHSMapping(node.kpiLhsCol);
            setHeaderName(node.headerName);
            setToggleType(node.toggleType);
            setKpiRHSColumn(node.kpiRHSCol);

            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = newHeader.findIndex((x) => x.header === item.header);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            newHeader.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.header === el.header);

              if (index === -1) {
                nonExistingHeader.push({
                  ...el,
                  header: el.alias ? el.alias : el.header,
                  alias: el.alias ? '' : el.alias,
                });
              }
            });

            setFetchedHeader(nonExistingHeader);
          }
        });
      } else {
        setFetchedHeader(newHeader);
      }
    }

    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const fetchKpiList = async () => {
    try {
      const response = await getKpiList();

      if (response.status === 200) {
        setfirstTimeFetch(false);
        setKpiList(response.data);
      }
    } catch (error) {
      enqueueSnackbar('KPI Lists not found! 1', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    // fetchKpiList();

    return () => {
      setKpiList([]);
    };
  }, []);

  const fetchKpiDetails = async (kpi_id) => {
    setLoadingBtn(true);
    try {
      const response = await getKpiDetails(kpi_id);

      if (response.status === 200) {
        // setKpiLHSColumn(response.data.lhs_cols);

        setFormField({
          ...formField,
          statement: response.data.sql_query,
        });

        // const lhs_mapping = [];
        // const lhs_list = response.data.lhs_cols;
        // lhs_list.forEach((cols) => {
        //   lhs_mapping.push({ key: cols, value: '', custom: false });
        // });
        // if (!firstTimeFetch) {
        //   setkpiLHSMapping(lhs_mapping);
        // }

        // const rhs_mapping = [];
        // const rhs_list = response.data.rhs_cols;
        // rhs_list.forEach((cols) => {
        //   rhs_mapping.push({
        //     header: cols,
        //     alias: '',
        //     checked: true,
        //     type: 'string',
        //     deleted: false,
        //   });
        // });
        // if (!firstTimeFetch) {
        //   setKpiRHSColumn(rhs_mapping);
        // }
      }
      // setHeaderName([]);
    } catch (error) {
      enqueueSnackbar('KPI Lists not found! 2', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoadingBtn(false);
  };

  useEffect(() => {
    console.log(disableForm);
    console.log(formField.kpi_id);
    if (formField.kpi_id !== '' && !disableForm) {
      fetchKpiDetails(formField.kpi_id);
    }
  }, [formField.kpi]);

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        el.data.label = formField.alias;
      }
    });

    if (toggleType === 'KPI') {
      let query = formField.statement;

      kpiLHSMapping.forEach((ele, idx) => {
        if (ele.value !== '') {
          query = query.replaceAll(ele.key, ele.value);
        }
      });
      console.log(query);

      formField.statement = query;
      const data = {
        query,
      };
    }

    const newHeaderName = [];

    if (kpiRHSColumn.length > 0 && kpiLHSMapping.length > 0) {
      headerName.forEach((item) => {
        newHeaderName.push({ ...item, tableAlias: formField.alias });
      });
    } else {
      fetchedHeader.forEach((item) => {
        newHeaderName.push({ ...item, tableAlias: formField.alias });
      });
    }

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const sendFormData = {
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      y_axis,
      headerName: newHeaderName,
      fetchedHeader,
      kpiLhsCol: kpiLHSMapping,
      toggleType,
      kpiRHSCol: kpiRHSColumn,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);
    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleChangeType = (event) => {
    setToggleType(event.target.value);
  };

  const handleStatementChange = (name, value) => {
    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleOtherFieldValue = (e) => {
    setOtherFieldValue({ ...otherFieldvalue, value: e.target.value });
  };

  const changeLhsMapping = async (row, newValue, index, dropdown) => {
    console.log(newValue);

    if (newValue.label === 'Others') {
      setOtherFieldValue({ value: '', index, closeType: 'cancel' });
      setOtherTextFieldDialogopen(true);
    }
    console.log({ value: '', index, closeType: 'cancel' });
    console.log(otherFieldvalue);
    const data = [...kpiLHSMapping];
    if (dropdown) {
      data[index]['value'] = newValue.label;
    } else {
      data[index]['value'] = newValue;
    }
    setkpiLHSMapping(data);
  };

  const changeToCustom = (obj) => {
    console.log(obj);
    const selectedIndex = kpiLHSMapping.findIndex((object) => {
      return object.key === obj.key;
    });

    console.log(selectedIndex);
    setkpiLHSMapping((current) =>
      current.map((object) => {
        if (object.key === obj.key) {
          console.log(obj);
          return { ...obj, custom: !obj.custom };
        }
        return object;
      })
    );
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition} style={{ width: '70%', marginLeft: '30%' }}>
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar color="inherit" sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  color="inherit"
                  onClick={refreshAllState}
                  aria-label="close"
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid item xs={4}>
                <InputField
                  name="alias"
                  label="Alias"
                  value={formField.alias}
                  onChange={handleInputChange}
                  size="small"
                  // disabled={disableForm}
                  disabled
                  required
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formField.sample_view}
                      onChange={handleChecked}
                      disabled={disableForm}
                    />
                  }
                  label="Sample View"
                  sx={{ pt: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mx: 1 }}>
              <Grid item xs={6}>
                <FormControl sx={{ width: '410px' }}>
                  <TextField
                    id="outlined-select-currency"
                    label="Type"
                    size="small"
                    value="KPI"
                    disabled
                    style={{ width: '100%', marginTop: '15px' }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="kpi"
                  value={formField.kpi}
                  disabled={disableForm}
                  onChange={(event, newValue) => {
                    const kpi_info = kpiList.find((ele) => ele.kpi_name === newValue);
                    setFormField({
                      ...formField,
                      kpi: newValue,
                      kpi_id: kpi_info.kpi_id,
                    });
                  }}
                  options={kpiList.map((ele) => ele.kpi_name)}
                  style={{ width: '100%', marginTop: '15px' }}
                  size="small"
                  renderInput={(params) => <TextField {...params} label="KPI List" />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mx: 1 }}>
              <Grid item xs={6}>
                <InputField
                  rows={4}
                  placeholder="Select * from table"
                  name="statement"
                  label="Statement"
                  value={formField.statement}
                  onChange={handleInputChange}
                  size="small"
                  // disabled={disableForm}
                  disabled
                  multiline
                  sx={{ mt: 2 }}
                  style={{ width: '100%' }}
                  required
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1 }}>
              <Grid item xs={4}>
                <InputField
                  name="action"
                  label="Action"
                  value={formField.action}
                  onChange={handleInputChange}
                  size="small"
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Distinct Rows"
                  size="small"
                  value={formField.distinct_rows}
                  onChange={(event) => {
                    setFormField({
                      ...formField,
                      distinct_rows: event.target.value,
                    });
                  }}
                  disabled={disableForm}
                  style={{ width: '100%', marginTop: '15px' }}
                >
                  <MenuItem value="true">true</MenuItem>
                  <MenuItem value="false">false</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  select
                  label="Persist"
                  variant="outlined"
                  value={formField.persist}
                  name="persist"
                  onChange={handleInputChange}
                  sx={{ mt: 2 }}
                  size="small"
                  disabled={disableForm}
                  required
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "'EB Garamond', serif ",
                      fontWeight: 600,
                    },
                  }}
                  InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                >
                  <MenuItem value>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                {formField.persist === true && (
                  <TextField
                    id="outlined-basic"
                    select
                    label="Persist Type"
                    variant="outlined"
                    value={formField.persist_type}
                    name="persist_type"
                    onChange={handleInputChange}
                    sx={{ mt: 2 }}
                    size="small"
                    disabled={disableForm}
                    required
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "'EB Garamond', serif ",
                        fontWeight: 600,
                      },
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "'EB Garamond', serif " },
                    }}
                  >
                    <MenuItem value="DISK_ONLY">DISK_ONLY</MenuItem>
                    <MenuItem value="DISK_ONLY_2">DISK_ONLY_2</MenuItem>
                    <MenuItem value="MEMORY_ONLY">MEMORY_ONLY</MenuItem>
                    <MenuItem value="MEMORY_ONLY_2">MEMORY_ONLY_2</MenuItem>
                    <MenuItem value="MEMORY_AND_DISK">MEMORY_AND_DISK</MenuItem>
                    <MenuItem value="MEMORY_AND_DISK_2">MEMORY_AND_DISK_2</MenuItem>
                  </TextField>
                )}
              </Grid>
            </Grid>
            {/* <TableContainer sx={{ maxHeight: 300, mt: 2 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>KPI Input Columns</TableCell>
                    <TableCell>Source Column</TableCell>
                  </TableRow>
                </TableHead>
                {loadingBtn && <LoadingIcon />}
                <TableBody>
                  {(kpiLHSMapping ?? []).map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{row.key}</TableCell>
                        <TableCell>
                          <div style={{ display: 'flex' }}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              value={row.value}
                              disabled={disableForm}
                              onChange={(event, newValue) => changeLhsMapping(row.key, newValue, index, true)}
                              options={headers}
                              renderInput={(params) => <TextField {...params} label="Attribute" />}
                              sx={{ width: 200, marginRight: 10 }}
                              size="small"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer> */}

            {/* {kpiRHSColumn && (
              <TableContainer sx={{ maxHeight: 300 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={kpiRHSColumn.length === headerName.length}
                          onChange={handleSelectAllClick}
                        />
                        Select Columns
                      </TableCell>
                      <TableCell>Columns</TableCell>
                      <TableCell>Alias</TableCell>
                    </TableRow>
                  </TableHead>

                  {loadingBtn && <LoadingIcon />}
                  <TableBody>
                    {kpiRHSColumn.map((row, i) => {
                      const isItemSelected = isSelected(row.header);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={i}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <Checkbox
                              checked={isItemSelected}
                              disabled={disableForm}
                              onChange={(event) =>
                                handleHeaderChange({
                                  header: row.header,
                                  alias: '',
                                  checked: event.target.checked,
                                  type: 'string',
                                  deleted: false,
                                })
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </TableCell>
                          <TableCell>{row.header}</TableCell>

                          <TableCell>
                            <TextField
                              name="alias"
                              label="Alias"
                              value={row.alias}
                              disabled={disableForm}
                              onChange={(e) =>
                                handleHeaderChange({
                                  header: row.header,
                                  alias: e.target.value,
                                  checked: true,
                                  type: 'string',
                                  deleted: false,
                                })
                              }
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )} */}
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>

      <Dialog disableEscapeKeyDown open={otherTextFieldDialogopen}>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                id="standard-basic"
                value={otherFieldvalue.value}
                onChange={handleOtherFieldValue}
                label="custom value"
                variant="standard"
                sx={{ width: 200, marginRight: 10 }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleotherTextFieldDialogClose(e, 'cancel')}>Cancel</Button>
          <Button onClick={(e) => handleotherTextFieldDialogClose(e, 'ok')}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NodeModal_KPI;
