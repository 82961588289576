import React, { useState, memo, useEffect, useContext } from 'react';
import { MenuItem, TextField, Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { getDataProcessor } from "../api's/DataProcessorApi";
import { getDirectoryData } from "../api's/ChatGPTApi";
import { getAllClientsApi, getClientByUserGroup } from "../api's/ClientApi";
import { getEngagementByUserGroupAndClientIdApi } from "../api's/EngagementApi";
import { getWorkflowByUserGroupAndEngagementIdApi } from "../api's/WorkflowApi";
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { AuthContext } from '../contexts/AuthProvider';

const INITIALSTATE = {
  path: '',
  format: 'csv',
  alias: '',
  clientName: '',
  batchName: '',
  tableNameRead: '',
  dataSetName: '',
  trackingId: '',
  file: '',
};

function FileDropdown({ setCsvPath, setPath }) {
  const [client, setClient] = useState();
  const [engagement, setEngagement] = useState();
  const [workflowData, setWorkflowData] = useState();
  const [workflow, setWorkflow] = useState();
  const [fullPath, setFullPath] = useState(null);
  const [locationPath, setlocationPath] = useState('TalkToDB');
  const [AllClient, setAllClient] = useState([]);
  const [AllBatch, setAllBatch] = useState([]);
  const [AllTable, setAllTable] = useState([]);
  const [WriteTable, setWriteTable] = useState([]);
  const [IngestionWriteTable, setIngestionWriteTable] = useState([]);
  //   const [path, setPath] = useState(null);
  const [formField, setFormField] = useState(INITIALSTATE);
  const { userRole, userGroup } = useContext(AuthContext);

  const handleAutoFetch = async (value) => {
    try {
      let str = value.replace(/^[^/]*\//, '');
      if (str.endsWith('/')) {
        str = str.slice(0, -1);
      }
      //   const csvPath2 = AWS_CONFIG_TYPE
      //     ? `s3://dep-qa/${str}`
      //     : `https://depazuredev.blob.core.windows.net/dep-develop/${str}`;
      //   setCsvPath(csvPath2);
      const data = {
        filepath: str,
        cloudEnvironment: workflow.cloudEnvironment,
      };
      const response = await getDirectoryData(data);
      if (response.status === 200) {
        const csvObject = response.data.find((obj) => obj.filename.endsWith('.csv'));
        const csvPath = AWS_CONFIG_TYPE
          ? `s3://dep-qa/${csvObject.filepath}`
          : `https://depazuredev.blob.core.windows.net/dep-develop/${csvObject.filepath}`;
        // const new_path = csvObject.filepath.replace(/\/[^/]+$/, '');
        setPath(csvPath);
        setFormField({
          ...formField,
          path: csvPath,
        });
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      // handleResetForm();
    }
  };

  const handleOnChange = (event, source) => {
    switch (source) {
      case 'client': {
        setClient(event.target.value);
        setFormField({
          ...formField,
          clientName: event.target.value,
        });
        break;
      }
      case 'engagement': {
        setEngagement(event.target.value);
        setFormField({
          ...formField,
          batchName: event.target.value,
        });
        break;
      }
      case 'workflow': {
        const foundWorkflow = AllTable.find((item) => item.workflow_id === event.target.value.split('_____')[0]);
        const str = `${foundWorkflow.workflow_id}_____${foundWorkflow.execution_mode}`;
        setWorkflowData(str);
        setWorkflow(foundWorkflow);
        setFormField({
          ...formField,
          tableNameRead: event.target.value,
        });
        break;
      }
      case 'writealias': {
        setFormField({
          ...formField,
          dataSetName: event.target.value,
        });
        break;
      }
      case 'locationPath': {
        setlocationPath(event.target.value);
        break;
      }
      default:
        break;
    }
  };

  const getClient = async () => {
    const response = userRole === 'SUPER_USER' ? await getAllClientsApi() : await getClientByUserGroup(userGroup);
    if (response.status === 200) {
      const clients = response.data.filter((el) => el.status === 'approved');
      setAllClient(clients);
      // setAllTable([]);
    }
  };

  const getBatch = async (clientName) => {
    const sp = clientName.split('_____');
    const response = await getEngagementByUserGroupAndClientIdApi(userGroup, sp[0]);
    if (response.status === 200) {
      const engagements = response.data.filter((el) => el.status === 'approved');
      setAllBatch(engagements);
    }
  };

  const getTable = async (batchName) => {
    try {
      const sp1 = batchName.split('_____');
      const response = await getWorkflowByUserGroupAndEngagementIdApi(userGroup, sp1[0]);
      if (response.status === 200) {
        const workflows = response.data.filter((el) => el.status === 'approved');
        setAllTable(workflows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWriteAlias = async (workflow) => {
    try {
      const sp1 = workflow.split('_____');
      const response = await getDataProcessor(sp1[0], sp1[1]);
      if (response.status === 200) {
        const data_set1 = response.data.form_data.filter((el) => el.step_name === 'Write');
        const data_set2 = response.data.form_data.filter((el) => el.step_name === 'Ingestion Write');
        if (data_set2.length > 0) {
          setIngestionWriteTable(data_set2[0].sorting);
          setWriteTable([]);
        } else {
          setIngestionWriteTable([]);
          setWriteTable(data_set1);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClient();
  }, []);

  useEffect(() => {
    if (formField.clientName) {
      getBatch(formField.clientName);
      setFormField({ ...formField, batchName: '', tableNameRead: '', dataSetName: '', trackingId: '', file: '' });
    }
  }, [formField.clientName]);

  useEffect(() => {
    if (formField.batchName) {
      getTable(formField.batchName);
      setFormField({ ...formField, trackingId: '', file: '' });
    }
  }, [formField.batchName]);

  useEffect(() => {
    if (formField.tableNameRead) {
      getWriteAlias(formField.tableNameRead);
      setFormField({ ...formField, trackingId: '', file: '' });
    }
  }, [formField.tableNameRead]);

  useEffect(() => {
    if (formField.dataSetName) {
      handleAutoFetch(formField.dataSetName);
    }
  }, [formField.dataSetName]);

  useEffect(() => {
    setFormField(INITIALSTATE);
  }, []);

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
    setAllBatch([]);
    setAllTable([]);
    setWriteTable([]);
  };

  return (
    <div>
      <Grid container mt={1} spacing={2}>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-client"
            select
            required
            label="Client"
            name="clientName"
            value={formField.clientName}
            onChange={(e) => handleOnChange(e, 'client')}
            fullWidth
            size="small"
          >
            {AllClient &&
              AllClient.map((ele) => {
                return (
                  <MenuItem key={ele.client_id} value={`${ele.client_id}_____${ele.client_name}`}>
                    {ele.client_name}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-batch"
            select
            required
            label="Engagement"
            name="batchName"
            value={formField.batchName}
            onChange={(e) => handleOnChange(e, 'engagement')}
            fullWidth
            size="small"
          >
            {AllBatch &&
              AllBatch.map((ele) => {
                return (
                  <MenuItem key={ele.engagement_id} value={`${ele.engagement_id}_____${ele.engagement_name}`}>
                    {ele.engagement_name}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="tableNameRead"
            select
            required
            label="Workflow"
            name="tableNameRead"
            value={formField.tableNameRead}
            onChange={(e) => handleOnChange(e, 'workflow')}
            fullWidth
            size="small"
          >
            {AllTable &&
              AllTable.map((ele) => {
                return (
                  <MenuItem key={ele.workflow_id} value={`${ele.workflow_id}_____${ele.execution_mode}`}>
                    {ele.workflow_name}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="dataSetName"
            select
            required
            label="Data Set"
            name="dataSetName"
            value={formField.dataSetName}
            onChange={(e) => handleOnChange(e, 'writealias')}
            fullWidth
            size="small"
          >
            {WriteTable &&
              WriteTable.map((ele) => {
                return (
                  <MenuItem key={ele.formField.alias} value={ele.formField.path}>
                    {ele.formField.alias}
                  </MenuItem>
                );
              })}
            {IngestionWriteTable &&
              IngestionWriteTable.map((ele) => {
                return (
                  <MenuItem key={ele.dataFrame} value={ele.file_path}>
                    {ele.dataFrame}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(FileDropdown);
