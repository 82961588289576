import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CLIENT_MODAL } from '../../utils/commonConstants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 30,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  KeyboardEvent: false,
};

export default function ClientSubmittedModal() {
  const navigate = useNavigate();

  return (
    <div>
      <Modal open="true" aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <BsFillCheckCircleFill fontSize="large" sx={{ color: 'green', height: '50px', width: '50px' }} />
          <Typography id="modal-modal-title" variant="h6" component="h1">
            {CLIENT_MODAL.clientModalTitle}
          </Typography>
          <Typography fontSize={13} variant="p" component="p" gutterBottom id="modal-modal-description">
            {CLIENT_MODAL.clientModalText}
          </Typography>
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{ mt: 2, mb: 3, backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
            onClick={() => navigate('/client')}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
