import { Pagination } from '@mui/material';
import React from 'react';

export default function PaginationFooter({ tableData, page, handleChangePage }) {
  return (
    <Pagination
      count={Math.floor(tableData.length / 5) + (tableData.length % 5 === 0 ? 0 : 1)}
      page={page}
      onChange={handleChangePage}
      variant="outlined"
      shape="rounded"
      size="small"
      sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
    />
  );
}
