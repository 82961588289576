import React, { useContext, useEffect, useState } from 'react';
import { Box, Toolbar, List, ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SourceIcon from '@mui/icons-material/Source';
import AppsIcon from '@mui/icons-material/Apps';
import InsightsIcon from '@mui/icons-material/Insights';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { MdTransform, MdEmail } from 'react-icons/md';
import './flowbuilderpannel.css';
import { WorkflowContext } from '../../contexts/WorkflowProvider';

const drawerWidth = 262;

const openedMixin = (theme) => ({
  position: 'relative',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: '#111111',
  borderRadius: '5px',
});

const closedMixin = (theme) => ({
  position: 'relative',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: '#111111',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const options = [
  {
    id: 1,
    name: 'Data Ingestion',
  },
  {
    id: 2,
    name: 'Data Quality',
  },
  {
    id: 3,
    name: 'Data Transformation',
  },
];

export default () => {
  const onDragStart = (event, nodeType, nodeValue, className) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('nodeName', nodeValue);
    event.dataTransfer.setData('className', className);
    event.dataTransfer.effectAllowed = 'move';
  };
  const { workflow } = useContext(WorkflowContext);

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState(workflow.process_type);

  const version = JSON.parse(sessionStorage.getItem('version'));

  const menuList = [
    { id: 1, text: 'All', icon: <LibraryAddCheckIcon fontSize="small" /> },
    { id: 2, text: 'Read', icon: <TextSnippetIcon fontSize="small" /> },
    { id: 3, text: 'Data Transform', icon: <AutorenewIcon fontSize="small" /> },
    { id: 10, text: 'Insight', icon: <InsightsIcon /> },
    { id: 4, text: 'Write', icon: <DriveFileRenameOutlineIcon fontSize="small" /> },
    { id: 5, text: 'Transform', icon: <MdTransform /> },
    { id: 6, text: 'Model', icon: <PsychologyIcon fontSize="small" /> },
    { id: 7, text: 'Data Quality', icon: <SourceIcon fontSize="small" /> },
    { id: 8, text: 'Notification', icon: <MdEmail /> },
    { id: 9, text: 'Others', icon: <AppsIcon fontSize="small" /> },
    { id: 11, text: 'Copy', icon: <ContentCopyIcon fontSize="small" /> },
  ];

  const menuListV1 = [
    { id: 1, text: 'All', icon: <LibraryAddCheckIcon fontSize="small" /> },
    { id: 2, text: 'Read', icon: <TextSnippetIcon fontSize="small" /> },
    { id: 3, text: 'Data Transform', icon: <AutorenewIcon fontSize="small" /> },
    { id: 4, text: 'Write', icon: <DriveFileRenameOutlineIcon fontSize="small" /> },
  ];

  const filterByVersion = version === '2.0' ? menuList : menuListV1;

  useEffect(() => {
    setValue(workflow.process_type);
    workflow.process_type === 'Data Quality' && setType('Write');
    workflow.process_type === 'Data Ingestion' && setType('Read');
  }, [workflow]);

  const filteredMenuList =
    value === 'Data Ingestion'
      ? filterByVersion.filter((item) => item.text === 'Read' || item.text === 'Write')
      : value === 'Data Copy'
      ? filterByVersion.filter((item) => item.text === 'Copy')
      : value === 'Data Quality'
      ? filterByVersion.filter((item) => item.text === 'Data Quality' || item.text === 'Write')
      : value === 'Data Transformation'
      ? filterByVersion.filter(
          (item) =>
            item.text !== 'Data Quality' && item.text !== 'Transform' && item.text !== 'Model' && item.text !== 'Copy'
        )
      : value === 'XPerience360' || value === 'Customer Segmentation'
      ? filterByVersion.filter((item) => item.text !== 'Copy')
      : filterByVersion;

  const [type, setType] = useState(filteredMenuList[0]?.text);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleList = (event, type) => {
    setType(type);
  };

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <Box
        className="custom-scrollbar"
        sx={{ display: 'flex', justifyContent: 'space-between', minHeight: 0, height: '60vh' }}
      >
        <AppBar
          position="absolute"
          open={open}
          sx={{
            background: '#111111',
            width: '14px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '12vh',
            borderTopRightRadius: '12px',
            borderBottomRightRadius: '12px',
            left: open ? '-3.8px' : '57px',
            top: `calc(100% - 36vh)`,
          }}
        >
          <Toolbar>
            <IconButton color="inherit" size="small" aria-label="open drawer" onClick={handleDrawerOpen}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          {/* <Select
            size="small"
            labelId="menu"
            id="menu"
            value={value}
            onChange={handleOnChange}
            input={<OutlinedInput />}
            sx={{
              background: '#323232',
              color: '#fff',
              fontSize: 14,
              position: 'sticky',
              top: 0,
              zIndex: 1,
              '.MuiSvgIcon-root ': {
                fill: '#fff',
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
            }}
            MenuProps={{
              sx: {
                '&& .Mui-selected': {
                  backgroundColor: '#474444',
                },
                '.MuiList-root': { backgroundColor: '#323232', color: '#fff' },
              },
            }}
          >
            {options.map((item) => (
              <MenuItem
                key={item.id}
                value={item.name}
                sx={{
                  fontSize: 14,
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select> */}
          <Box
            className="custom-scrollbar"
            sx={{
              display: 'flex',
              height: '100%',
              background: '#111111',
              borderRadius: '5px',
            }}
          >
            <List>
              {filteredMenuList.map((item) => (
                <ListItem key={item.id} disablePadding sx={{ display: 'flex', my: '3px' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 30,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      '&.Mui-selected': {
                        background: '#4a4e69',
                        height: '65px',
                        width: '80px',
                        maxWidth: '95px',
                        borderRadius: '3px',
                        '&:hover': { backgroundColor: '#4a4e69' },
                        '.MuiListItemIcon-root, .MuiListItemText-root': {
                          color: '#fff',
                        },
                      },
                    }}
                    selected={type === item.text}
                    onClick={(event) => handleList(event, item.text)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 0.5 : 'auto',
                        color: '#696969',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, color: '#696969' }}
                      primaryTypographyProps={{
                        fontSize: 12,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                height: '78vh',
                display: 'flex',
                flexWrap: 'wrap',
                overflowY: 'auto',
                alignContent: 'flex-start',
                py: 1,
                minWidth: '200px',
              }}
            >
              {type === 'Read' && value === 'Data Copy' && (
                <div
                  className="dndnode input"
                  onDragStart={(event) => onDragStart(event, 'editableNode', 'CopyADLS', 'dndnode input')}
                  draggable
                >
                  <div className="node-container">Copy ADLS</div>
                </div>
              )}
              {type === 'Copy' && value === 'Data Copy' && (
                <div
                  className="dndnode input"
                  onDragStart={(event) => onDragStart(event, 'editableNode', 'CopyDoc', 'dndnode input')}
                  draggable
                >
                  <div className="node-container">Copy Doc</div>
                </div>
              )}
              {(type === 'All' || (type === 'Read' && value !== 'Data Copy')) && (
                <>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Read', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Read</div>
                  </div>

                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadCSV', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Read CSV</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadPDF', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">PDF Data Extractor</div>
                  </div>

                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadPublishedData', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Read Published Data</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Read From Folder', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Read From Folder</div>
                  </div>

                  {/* <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadStreaming', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Read Streaming</div>
                  </div> */}
                  {version === '2.0' && (
                    <>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadDelimited', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Read Delimited</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadApi', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Read Api</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadOracle', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Read Oracle</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadMySql', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Read MySql</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Read Real-Time', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Read Real-Time</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Read MDM', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Read MDM</div>
                      </div>
                    </>
                  )}

                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadPostgres', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Read Postgres</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadCosmos', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Read Cosmos</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'ReadSnowflake', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Read SnowFlake</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'FileUploader', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">File Uploader</div>
                  </div>
                </>
              )}
              {(type === 'All' || type === 'Data Transform') && (
                <>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Instrument', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Instrument</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Execute SQL', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Execute SQL</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'KPI', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">KPI</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Join', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Join</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Sort', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Sort</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Filter', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Filter</div>
                  </div>
                  {version === '2.0' && (
                    <>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Union', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Union</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Coalesce', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Coalesce</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Aggregation', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Aggregation</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'ViCell', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">VICELL</div>
                      </div>

                      {(workflow.process_type === 'XPerience360' ||
                        workflow.process_type === 'Customer Segmentation') && (
                        <>
                          <div
                            className="dndnode input"
                            onDragStart={(event) =>
                              onDragStart(event, 'editableNode', 'Data Cleaning & Validation', 'dndnode input')
                            }
                            draggable
                          >
                            <div className="node-container">Data Cleaning & Validation</div>
                          </div>
                          <div
                            className="dndnode input"
                            onDragStart={(event) => onDragStart(event, 'editableNode', 'Fuzzy Match', 'dndnode input')}
                            draggable
                          >
                            <div className="node-container">Fuzzy Match</div>
                          </div>
                          <div
                            className="dndnode input"
                            onDragStart={(event) =>
                              onDragStart(event, 'editableNode', 'Match & Merge', 'dndnode input')
                            }
                            draggable
                          >
                            <div className="node-container">Match & Merge</div>
                          </div>
                          <div
                            className="dndnode input"
                            onDragStart={(event) =>
                              onDragStart(event, 'editableNode', 'Customer Segmentation', 'dndnode input')
                            }
                            draggable
                          >
                            <div className="node-container">Customer Segmentation</div>
                          </div>

                          <div
                            className="dndnode input"
                            onDragStart={(event) => onDragStart(event, 'editableNode', 'Analysis', 'dndnode input')}
                            draggable
                          >
                            <div className="node-container">Analysis</div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {(type === 'All' || (type === 'Write' && value !== 'Data Copy')) && (
                <>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'SnowFlake', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">SnowFlake</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Write', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Write</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'WriteToBenchling', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Benchling Write</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'AutoRefresh', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Benchling AutoRefresh</div>
                  </div>
                  {version === '2.0' && (
                    <div
                      className="dndnode input"
                      onDragStart={(event) => onDragStart(event, 'editableNode', 'OpenSearch', 'dndnode input')}
                      draggable
                    >
                      <div className="node-container">Open Search</div>
                    </div>
                  )}
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Postgres', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Postgres</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Ingestion Write', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Ingestion Write</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) =>
                      onDragStart(event, 'editableNode', 'Ingest Table Metadata', 'dndnode input')
                    }
                    draggable
                  >
                    <div className="node-container">Ingest Table Metadata</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) =>
                      onDragStart(event, 'editableNode', 'Ingest Column Metadata', 'dndnode input')
                    }
                    draggable
                  >
                    <div className="node-container">Ingest Column Metadata</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Generate Transcript', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Generate Transcript</div>
                  </div>
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Embedding', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Embedding</div>
                  </div>
                </>
              )}

              {version === '2.0' && (
                <>
                  {(type === 'All' || type === 'Transform') && (
                    <>
                      <div
                        className="dndnode input"
                        onDragStart={(event) =>
                          onDragStart(event, 'editableNode', 'Datatype Conversion', 'dndnode input')
                        }
                        draggable
                      >
                        <div className="node-container">Datatype Conversion</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Concat', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Concat</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) =>
                          onDragStart(event, 'editableNode', 'Date Time Feature', 'dndnode input')
                        }
                        draggable
                      >
                        <div className="node-container">Date Time Feature</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Handle Null', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Handle Null</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) =>
                          onDragStart(event, 'editableNode', 'Math Transformation', 'dndnode input')
                        }
                        draggable
                      >
                        <div className="node-container">Math Transformation</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'OneHot Encoder', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">OneHot Encoder</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Label Encoder', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Label Encoder</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Drop', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Drop</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Outlier', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Outlier</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Imbalanced Data', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Imbalanced Data</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Binning', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Binning</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Scaler', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Scaler</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Resample', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Resample</div>
                      </div>
                    </>
                  )}

                  {(type === 'All' || type === 'Model') && (
                    <>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Training', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Training</div>
                      </div>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Prediction', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Prediction</div>
                      </div>

                      {/* <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Binning', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Binning</div>
                  </div> */}
                    </>
                  )}

                  {(type === 'All' || type === 'Data Quality') && (
                    <>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Source', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Source</div>
                      </div>

                      {/* <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, 'editableNode', 'Profiling', 'dndnode input')}
                    draggable
                  >
                    <div className="node-container">Profiling</div>
                  </div> */}

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'DataValidation', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Validation</div>
                      </div>
                    </>
                  )}

                  {(type === 'All' || type === 'Notification') && (
                    <>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Email', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Email</div>
                      </div>
                    </>
                  )}
                  {(type === 'All' || type === 'Insight') && (
                    <>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Plot', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Plot</div>
                      </div>
                    </>
                  )}

                  {(type === 'All' || type === 'Others') && (
                    <>
                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Data Cleansing', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Data Cleansing</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'C360', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Predictive Analytics</div>
                      </div>

                      <div
                        className="dndnode input"
                        onDragStart={(event) => onDragStart(event, 'editableNode', 'Udf', 'dndnode input')}
                        draggable
                      >
                        <div className="node-container">Udf</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};
