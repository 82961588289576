import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Modal,
  Tooltip,
  Tabs,
  Tab,
  Stack,
  Paper,
  TablePagination,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { red } from '@mui/material/colors';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import InputField from '../../../reusable-component/InputField';
import GroupRecords from '../../mdm/GroupRecords';
import { WorkflowContext } from '../../../contexts/WorkflowProvider';
import { getBadRecordsApi, getGoldenRecordsApi, reviewRecordsApi } from "../../../api's/DataProcessorApi";
import { convertToPascalCase } from '../../../utils/stringConversion';
import { arrangeKeysBasedOnSequence } from '../../../utils/arrayManipulation';
import MergeManager from '../../mdm/MergeManager';
import DataManager from '../../mdm/DataManager';
import Insights from '../../mdm/Insights';
import { mdmColors } from '../../../utils/mdmColors';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const NodeModalManualMerge = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { workflow, mergeManager, dataManager } = useContext(WorkflowContext);

  const INITIALSTATE = {
    alias: `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}_MatchAndMerge`,
    algorithm: '',
    df: '',
    sample_view: true,
  };
  const { enqueueSnackbar } = useSnackbar();

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);
  const [showSource, setShowSource] = useState(false);
  const [showIndividualRecord, setShowIndividualRecords] = useState(false);
  const [row, setRow] = useState({});
  const [goldenRecords, setGoldenRecords] = useState([]);
  const [badRecords, setBadRecords] = useState([]);
  const [badRecordsColumns, setBadRecordsColumns] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleRows, setVisibleRows] = useState([]);
  const [visibleBadRecords, setVisibleBadRecords] = useState([]);
  const [selectedSource, setSelectedSource] = useState('');
  const [mergeManagerData, setMergeManagerData] = useState([]);
  const [dataManagerRows, setDataManagerRows] = useState([]);
  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const [dataManagerKeys, setDataManagerKeys] = useState([]);
  const [mergeManagerKeys, setMergeManagerKeys] = useState([]);

  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const [dataManagerTableData, setDataManagerTableData] = useState([]);

  const [tabValue, setTabValue] = useState(0);

  const handleSource = (row) => {
    setRow(row);
    setShowSource(!showSource);
  };

  const handleIndividualRecords = (row) => {
    setRow(row);
    setShowIndividualRecords(!showIndividualRecord);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectedRow = (item) => {
    setSelectedRow(item);
    setShowSource(!showSource);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              header: obj.header,
              fuzzyMatch: obj.fuzzyMatch,
              checked: true,
              cast_datatype: obj.cast_datatype,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              fuzzyMatch: obj.fuzzyMatch,
              cast_datatype: obj.cast_datatype,
            };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              fuzzyMatch: obj.fuzzyMatch,
              cast_datatype: obj.cast_datatype,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: false,
              fuzzyMatch: 'false',
              cast_datatype: 'string',
            };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          fuzzyMatch: n.fuzzyMatch ?? 'false',
          checked: true,
          cast_datatype: n.cast_datatype ? n.cast_datatype : 'string',
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });
    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getSourceData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return {
          ...el,
          header: el.alias ? el.alias : el.header,
          alias: el.alias ? '' : el.alias,
        };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);
      setFetchedHeader(newHeader);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setTempHeader(node.headerName);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    getSourceData();
  }, [nodeId]);

  useEffect(() => {
    fetchGoldenRecords();
    fetchBadRecords();
    fetchReviewRecords();
  }, []);

  useEffect(() => {
    setVisibleRows(goldenRecords.golden_records?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    setVisibleBadRecords(badRecords.bad_records?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [page, rowsPerPage]);

  const fetchGoldenRecords = async () => {
    setLoading(true);
    const data = {
      client_name: workflow.engagement.client.client_name,
      engagement_name: workflow.engagement.engagement_name,
      workflow_name: workflow.workflow_name,
    };
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    const obj = getAllNodes.find((item) => item.step_name === 'Match & Merge');

    try {
      const response = await getGoldenRecordsApi(data);
      if (response.status === 200) {
        const arrangedData = arrangeKeysBasedOnSequence(response.data.golden_records, response.data.headers);

        response.data.golden_records = arrangedData;
        setGoldenRecords(response.data);
        setNewColumns(
          Object.keys(response.data?.golden_records[0]).map((item) => ({ width: 170, label: item, dataKey: item }))
        );
        setVisibleRows(response.data?.golden_records?.slice(0, rowsPerPage));
        setDataManagerRows(
          response.data?.golden_records.map((item, index) => {
            return { ...item, key: index };
          })
        );
        if (obj !== undefined && 'dataManagerTableData' in obj && obj !== null && Object.keys(obj).length > 0) {
          setDataManagerTableData(obj.dataManagerTableData);
          // setDataManagerRows(obj.dataManagerTableData);
        } else {
          setDataManagerTableData(
            response.data?.golden_records.map((item, index) => {
              return { ...item, key: index };
            })
          );
        }
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Golden Records not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }

    setLoading(false);
  };

  const fetchBadRecords = async () => {
    setLoading(true);
    const data = {
      client_name: workflow.engagement.client.client_name,
      engagement_name: workflow.engagement.engagement_name,
      workflow_name: workflow.workflow_name,
    };
    try {
      const response = await getBadRecordsApi(data);
      if (response.status === 200) {
        const arrangedData = arrangeKeysBasedOnSequence(response.data.bad_records, response.data.headers);
        response.data.bad_records = arrangedData;

        setBadRecords(response.data);
        setBadRecordsColumns(
          Object.keys(response.data?.bad_records[0]).map((item) => ({ width: 170, label: item, dataKey: item }))
        );
        setVisibleBadRecords(response.data?.bad_records?.slice(0, rowsPerPage));
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Records not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const fetchReviewRecords = async () => {
    setLoading(true);
    const data = {
      client_name: workflow.engagement.client.client_name,
      engagement_name: workflow.engagement.engagement_name,
      workflow_name: workflow.workflow_name,
    };

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    const obj = getAllNodes.find((item) => item.step_name === 'Match & Merge');

    if (obj !== undefined && 'mergeManagerData' in obj && obj !== null && Object.keys(obj).length > 0) {
      setMergeManagerData(obj.mergeManagerData);
    } else {
      try {
        const response = await reviewRecordsApi(data);
        if (response.status === 200) {
          setMergeManagerData(
            response.data.map(({ is_review_required, is_email_valid, is_phone_no_valid, ...item }, index) => {
              return { ...item, key: index, select_grouping: 'NA', group: '', reviewed_by: '' };
            })
          );
        }
      } catch (error) {
        if (error.response?.status === 500) {
          enqueueSnackbar('500 Internal Server Error!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (error.response?.status === 404) {
          enqueueSnackbar('404 Records not found!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else {
          enqueueSnackbar('Something went wrong!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
      setLoading(false);
    }
  };

  // customized sources
  const sources = [...new Set(goldenRecords?.golden_records?.map((item) => item.source))].map((ele, index) => ({
    source: ele,
    color: mdmColors[index],
  }));

  // const sources = [
  //   { source: 'AMAT', color: '#C39BD3' },
  //   { source: 'APG', color: '#7FB3D5' },
  //   { source: 'IPG', color: '#73C6B6' },
  //   // { source: '209', color: '#7DCEA0' },
  // ];

  // const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

  // const sources = getAllNodes
  //   ?.filter(
  //     (item) =>
  //       item.nodeName === 'ReadCSV' ||
  //       item.nodeName === 'ReadPublishedData' ||
  //       item.nodeName === 'Read' ||
  //       item.nodeName === 'ReadStreaming' ||
  //       item.nodeName === 'ReadDelimited' ||
  //       item.nodeName === 'ReadOracle' ||
  //       item.nodeName === 'ReadPostgres' ||
  //       item.nodeName === 'ReadCosmos' ||
  //       item.nodeName === 'ReadMySql' ||
  //       item.nodeName === 'ReadSnowflake' ||
  //       item.nodeName === 'Read Real-Time'
  //   )
  //   ?.map((ele, index) => ({ source: ele.formField.alias, color: mdmColors[index] }));
  // const sourceCount =
  //   goldenRecords &&
  //   goldenRecords?.golden_records &&
  //   goldenRecords?.golden_records
  //     .map((item, index) => item.source)
  //     .reduce(function (prev, cur) {
  //       prev[cur] = (prev[cur] || 0) + 1;
  //       return prev;
  //     }, {});

  function findCount(arr, key) {
    const arr2 = [];

    arr?.forEach((x) => {
      // Checking if there is any object in arr2
      // which contains the key value
      if (
        arr2.some((val) => {
          return val[key] === x[key];
        })
      ) {
        // If yes! then increase the occurrence by 1
        arr2?.forEach((k) => {
          if (k[key] === x[key]) {
            k['count']++;
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the occurrence to 1
        const a = {};
        a[key] = x[key];
        a['count'] = 1;
        arr2.push(a);
      }
    });

    return arr2;
  }

  const myArray = goldenRecords && goldenRecords?.golden_records && goldenRecords?.golden_records;

  const sourceCount = findCount(myArray, 'source');
  const sourceData = sourceCount?.map((item, index, arr) => ({ ...item, color: sources[index].color }));

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];

    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setHeaderName(node.headerName);

            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = newHeader.findIndex((x) => x.header === item.header);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            newHeader.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.header === el.header);

              if (index === -1) {
                nonExistingHeader.push({
                  ...el,
                  header: el.alias ? el.alias : el.header,
                  alias: el.alias ? '' : el.alias,
                });
              }
            });
            setFetchedHeader(nonExistingHeader);
            // if (node.disabled) {
            //   setDisableForm(node.disabled);
            // }
          }
        });
      } else {
        setFetchedHeader(newHeader);
      }
    }
    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      mergeManager,
      dataManager,
      mergeManagerData,
      dataManagerTableData,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setShowSource(false);
    setPage(0);
  };

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      disabled: disableForm,
    };
  }

  const tableCol = tabValue === 0 ? newColumns : badRecordsColumns;

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
    Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) =>
      tabValue === 0 ? (
        <TableRow
          onClick={() => !disableForm && handleSelectedRow(_item)}
          hover
          {...props}
          sx={{ cursor: !disableForm && 'pointer', whiteSpace: 'normal', wordWrap: 'break-word' }}
        />
      ) : (
        <TableRow hover {...props} />
      ),
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        {tableCol
          .filter((item) => item.dataKey !== 'index' && item.dataKey !== 'parent_id')
          .map((column) => (
            <TableCell
              size="small"
              key={column.dataKey}
              variant="head"
              align={column.numeric || false ? 'right' : 'left'}
              style={{ width: column.width }}
              sx={{
                backgroundColor: 'background.paper',
                fontWeight: 600,
              }}
            >
              {convertToPascalCase(column.label)}
            </TableCell>
          ))}
      </TableRow>
    );
  }

  function rowContent(_index, row) {
    return (
      <>
        {tableCol
          .filter((item) => item.dataKey !== 'index' && item.dataKey !== 'parent_id')
          .map((column) => (
            <TableCell
              size="small"
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              sx={{ color: column.dataKey === 'exception_message' ? red[500] : 'inherit' }}
            >
              {row[column.dataKey].includes('|')
                ? row[column.dataKey].split('|').map((ele) => ele)
                : row[column.dataKey]}
            </TableCell>
          ))}
      </>
    );
  }

  const handleClickAway = () => {
    setSelectedSource('');
  };

  const badRecordsCount = (badRecords && badRecords?.bad_records && badRecords.bad_records.length) ?? 0;

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '80%', marginLeft: '20%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>

              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  onClick={refreshAllState}
                  aria-label="close"
                  sx={{ color: '#00043C' }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  disabled={disableForm}
                  className="button-color"
                  // onClick={handleFormsubmit}
                >
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            <Box
              sx={{
                '& .MuiTextField-root': { width: '32ch' },
                display: 'flex',
                justifyContent: 'space-between',
              }}
              noValidate
              autoComplete="off"
            >
              <InputField
                name="alias"
                label="Alias"
                value={formField.alias}
                onChange={handleInputChange}
                size="small"
                disabled={disableForm}
                required
              />
              <Paper sx={{ width: 300, display: 'flex', flexDirection: 'column', p: 1, mt: 1, maxHeight: 70 }}>
                <Typography variant="caption" display="block" sx={{ fontWeight: 600, mr: 1 }}>
                  Sources
                </Typography>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap', overflow: 'auto' }}>
                    {sources?.map((item) => (
                      <Stack direction="row" sx={{ cursor: 'pointer' }} onClick={() => setSelectedSource(item.source)}>
                        <span
                          style={{
                            height: 15,
                            width: 15,
                            marginRight: '5px',
                            backgroundColor: item.color,
                            borderRadius: '50%',
                            display: 'inline-block',
                          }}
                        />
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {item.source}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </ClickAwayListener>
              </Paper>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="mdm tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Golden Records" {...a11yProps(0)} />
                <Tab label="Records that failed validation" {...a11yProps(1)} />
                <Tab label="Merge Manager" {...a11yProps(2)} />
                <Tab label="Data Manager" {...a11yProps(3)} />
                <Tab label="Insights" {...a11yProps(4)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={tabValue} index={0}>
              {showSource ? (
                <GroupRecords
                  handleSource={handleSource}
                  row={row}
                  selectedRow={selectedRow}
                  sources={sources}
                  selectedSource={selectedSource}
                />
              ) : (
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Typography variant="body2" sx={{ m: 1 }}>
                    Total Records: {goldenRecords.total_objects ?? 0}
                  </Typography>
                  <Paper style={{ height: 400, width: '100%' }}>
                    <TableVirtuoso
                      data={visibleRows}
                      components={VirtuosoTableComponents}
                      fixedHeaderContent={fixedHeaderContent}
                      itemContent={rowContent}
                    />
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={goldenRecords?.golden_records?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              )}
              {loading && <LoadingIcon />}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              {loading || badRecordsCount <= 0 || badRecords?.bad_records?.length === undefined ? (
                <LoadingIcon />
              ) : !loading && badRecordsCount === 0 ? (
                'No Data'
              ) : (
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Typography variant="body2" sx={{ m: 1 }}>
                    Total Records: {badRecordsCount}
                  </Typography>
                  <Paper style={{ height: 400, width: '100%' }}>
                    <TableVirtuoso
                      data={visibleBadRecords}
                      components={VirtuosoTableComponents}
                      fixedHeaderContent={fixedHeaderContent}
                      itemContent={rowContent}
                    />
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={badRecordsCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <MergeManager
                disableForm={disableForm}
                mergeManagerData={mergeManagerData}
                setMergeManagerData={setMergeManagerData}
                mergeManagerKeys={mergeManagerKeys}
                setMergeManagerKeys={setMergeManagerKeys}
              />
              {loading && <LoadingIcon />}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
              <DataManager
                goldenRecords={goldenRecords}
                disableForm={disableForm}
                dataManagerRows={dataManagerRows}
                dataManagerTableData={dataManagerTableData}
                setDataManagerTableData={setDataManagerTableData}
                dataManagerKeys={dataManagerKeys}
                setDataManagerKeys={setDataManagerKeys}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={4}>
              <Insights sourceData={sourceData} goldenRecords={goldenRecords} badRecordsCount={badRecordsCount} />
            </CustomTabPanel>
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeModalManualMerge;
