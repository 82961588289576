import React from 'react';
import './LoaderIcon.css';

const LoaderIcon = () => {
  return (
    <div className="loader">
      <div className="cell d-0" />
      <div className="cell d-1" />
      <div className="cell d-2" />

      <div className="cell d-1" />
      <div className="cell d-2" />
      <div className="cell d-2" />

      <div className="cell d-3" />
      <div className="cell d-3" />
      <div className="cell d-4" />
    </div>
  );
};

export default LoaderIcon;
