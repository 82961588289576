import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { DateConverter } from '../../utils/DateConverter';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import ApproveModal from '../../reusable-component/ApproveModal';
import BasicMenu from '../../reusable-component/BasicMenu';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function ConnectionsTable({ tableData }) {
  const [page, setPage] = useState(1);
  //   const [openApproveModal, setOpenApproveModal] = useState(false);
  //   const [approveMsg, setApproveMsg] = useState('');

  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //   const handleOpenApproveModal = () => {
  //     setOpenDeleteModal(true);
  //   };

  //   const handleCloseApproveModal = () => {
  //     setOpenApproveModal(false);
  //   };

  return (
    <Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          style={{ minWidth: 650, borderSpacing: '0px 4px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Connection Name</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
              <StyledTableCell>Last Updated By</StyledTableCell>
              <StyledTableCell>Updated On</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.connection_id}>
                <StyledTableCell>{row.connection_name}</StyledTableCell>
                <StyledTableCell>{row.connection_type}</StyledTableCell>
                <StyledTableCell>{row.created_by}</StyledTableCell>
                <StyledTableCell>{DateConverter(row.created_at)}</StyledTableCell>
                <StyledTableCell>{row.last_updated_by}</StyledTableCell>
                <StyledTableCell>{row.updated_on}</StyledTableCell>
                <StyledTableCell>
                  <BasicMenu row={row} fetchData={tableData} type="Connection" />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <ApproveModal
        openApproveModal={openApproveModal}
        handleApproveModal={handleApproveModal}
        // fetchData={fetchTableData}
        type="client"
        // id={engagementId}
        // approveMsg={approveMsg}
      /> */}
      <PaginationFooter tableData={tableData} page={page} handleChangePage={handleChangePage} />
    </Box>
  );
}
