import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { rejectClientApi } from "../api's/ClientApi";
import { rejectDomainApi } from "../api's/DomainApi";
import { rejectEngagementApi } from "../api's/EngagementApi";
import { rejectUserApi } from "../api's/UserApi";
import { rejectWorkflowApi } from "../api's/WorkflowApi";
import { AuthContext } from '../contexts/AuthProvider';

export default function RejectModal({ handleRejectModal, openRejectModal, fetchData, type, id, message }) {
  const { enqueueSnackbar } = useSnackbar();
  const { userRole } = useContext(AuthContext);

  const handleRejectUser = async () => {
    try {
      const response = await rejectUserApi(id, userRole);
      if (response.status === 200) {
        enqueueSnackbar('User Rejected Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleRejectModal();
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to reject engagement!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 403) {
        enqueueSnackbar('You are not allowed to perform this action !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleRejectWorkflow = async () => {
    try {
      const response = await rejectWorkflowApi(id);

      if (response.status === 200) {
        enqueueSnackbar('Workflow Rejected Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleRejectModal();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to reject workflow!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response.status === 403) {
        enqueueSnackbar('You have only Read Permission !!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleRejectEngagement = async () => {
    try {
      const response = await rejectEngagementApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Engagement Rejected Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleRejectModal();
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to reject engagement!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleRejectClient = async () => {
    try {
      const response = await rejectClientApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Client Rejected Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleRejectModal();
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to reject client!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleRejectDomain = async () => {
    try {
      const response = await rejectDomainApi(id);
      if (response.status === 200) {
        enqueueSnackbar('Domain Rejected Successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        fetchData();
        handleRejectModal();
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Failed to reject domain!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleOnReject = (type) => {
    switch (type) {
      case 'user':
        return handleRejectUser();
      case 'workflow':
        return handleRejectWorkflow();
      case 'engagement':
        return handleRejectEngagement();
      case 'client':
        return handleRejectClient();
      case 'domain':
        return handleRejectDomain();
      default:
        return null;
    }
  };

  return (
    <div>
      <Dialog
        open={openRejectModal}
        onClose={handleRejectModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ coloe: '#43425D' }}>
          Rejection Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: '#444444' }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleRejectModal} sx={{ color: '#43425D', border: '1px solid #43425D' }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => handleOnReject(type)} autoFocus color="error">
            Yes, Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

RejectModal.propTypes = {
  handleRejectModal: PropTypes.func,
  openRejectModal: PropTypes.bool,
  fetchData: PropTypes.func,
  type: PropTypes.string,
  id: PropTypes.string,
  message: PropTypes.string,
};
