export const INSTRUMENT_DATA = {
  data: [
    {
      instrument_id: '09d25424-154d-4b40-9278-0f9bf0221ce3',
      instrument_name: 'NOVAFLEX',
      creation_date: '2024-07-30T11:04:45.707Z',
      user_id: 'dc8f97ba-0b1e-427b-9ea4-f0b38e5f4f7e',
      file_type: 'csv',
      domain_name: 'LifeScience',
    },
    {
      instrument_id: '9164b69a-a570-43c9-be7b-dce28f56e835',
      instrument_name: 'VICELL',
      creation_date: '2024-07-30T11:04:29.390Z',
      user_id: 'dc8f97ba-0b1e-427b-9ea4-f0b38e5f4f7e',
      file_type: 'txt',
      domain_name: 'LifeScience',
    },
    {
      instrument_id: '09d25424-154d-4b40-9278-0f9bf0221444',
      instrument_name: 'NOVAFLEX',
      creation_date: '2024-07-30T11:04:45.707Z',
      user_id: 'dc8f97ba-0b1e-427b-9ea4-f0b38e5f4f7e',
      file_type: 'csv',
      domain_name: 'LifeSciences',
    },
    {
      instrument_id: '9164b69a-a570-43c9-be7b-dce28f56e555',
      instrument_name: 'VICELL',
      creation_date: '2024-07-30T11:04:29.390Z',
      user_id: 'dc8f97ba-0b1e-427b-9ea4-f0b38e5f4f7e',
      file_type: 'txt',
      domain_name: 'LifeSciences',
    },
    {
      instrument_id: 'd72f327e-21a5-424f-8207-cb010013f60f',
      instrument_name: 'DAB',
      creation_date: '2024-09-04T06:17:37.664Z',
      user_id: 'dc8f97ba-0b1e-427b-9ea4-f0b38e5f4f7e',
      file_type: 'csv',
      domain_name: 'ReportingDomain',
    },
    {
      instrument_id: '14a0df69-3278-48d0-8e00-39abd9eb2ec7',
      instrument_name: 'NANODROP',
      creation_date: '2024-09-04T06:18:09.698Z',
      user_id: 'dc8f97ba-0b1e-427b-9ea4-f0b38e5f4f7e',
      file_type: 'csv',
      domain_name: 'ReportingDomain',
    },
    {
      instrument_id: '160e5119-5373-4863-ab07-7c9c83f77690',
      instrument_name: 'CELLULAR_TROPISM',
      creation_date: '2024-09-05T08:59:35.427Z',
      user_id: '4a9c9014-d74a-4446-9ea3-a242b923aad2',
      file_type: 'csv',
      domain_name: 'ReportingDomain',
    },
    {
      instrument_id: '5557ad19-63a9-4d49-9b85-84cd45f6e8f4',
      instrument_name: 'SINGLEPLEX_ISH',
      creation_date: '2024-09-09T09:44:25.931Z',
      user_id: 'ff6c8684-0d63-4f70-991e-55f856145b59',
      file_type: 'csv',
      domain_name: 'ReportingDomain',
    },
    {
      instrument_id: 'd2eb8e6e-acda-40aa-80bc-4d9d72532968',
      instrument_name: 'DUPLEX_ISH',
      creation_date: '2024-09-09T09:44:48.874Z',
      user_id: 'ff6c8684-0d63-4f70-991e-55f856145b59',
      file_type: 'csv',
      domain_name: 'ReportingDomain',
    },
    {
      instrument_id: '694f3d98-455f-493a-ae53-5cdd42e1111f',
      instrument_name: 'DDPCR',
      creation_date: '2024-09-11T07:10:50.204Z',
      user_id: '4a9c9014-d74a-4446-9ea3-a242b923aad2',
      file_type: 'csv',
      domain_name: 'ReportingDomain',
    },
    {
      instrument_id: 'cd38b80b-4dad-4382-8724-2fcb62a6d992',
      instrument_name: 'REPLICON',
      creation_date: '2024-09-16T11:05:04.687Z',
      user_id: 'a59be732-8d7b-4f56-899f-f3d4d7d8ceb8',
      file_type: 'csv',
      domain_name: 'Travel',
    },
    {
      instrument_id: 'c3356173-b08a-4f7a-b751-b09b804f4553',
      instrument_name: 'FTE',
      creation_date: '2024-09-23T10:48:58.436Z',
      user_id: 'a59be732-8d7b-4f56-899f-f3d4d7d8ceb8',
      file_type: 'csv',
      domain_name: 'Travel',
    },
    {
      instrument_id: 'aa542dfb-1bbb-4096-84fb-b345b22e1fae',
      instrument_name: 'REFRENCE_DATA',
      creation_date: '2024-09-24T06:10:37.121Z',
      user_id: '4a9c9014-d74a-4446-9ea3-a242b923aad2',
      file_type: 'csv',
      domain_name: 'Travel',
    },
    {
      instrument_id: '9d256b0e-85c5-47a6-a051-c972a7660b0a',
      instrument_name: 'MISC_DAB_CELLULAR',
      creation_date: '2024-09-24T11:49:53.619Z',
      user_id: '4a9c9014-d74a-4446-9ea3-a242b923aad2',
      file_type: 'csv',
      domain_name: 'ReportingDomain',
    },
  ],
};
