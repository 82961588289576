import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Paper } from '@mui/material';
import ClientNoData from '../components/Client/ClientNoData';
import ManagementTable from '../components/Admin/ManagementTable';
import SearchAndFilter from '../reusable-component/SearchAndFilter';
import { ENGAGEMENT_PAGE, CLIENT_PAGE, USER_PAGE } from '../utils/commonConstants';
import { getManagementUserDetails, getManagementUserDetailsByUserGroup } from "../api's/ManagementApi";
import LoadingIcon from '../reusable-component/LoadingIcon';
import { AuthContext } from '../contexts/AuthProvider';

const ManagementPage = () => {
  const { userRole, userGroup } = useContext(AuthContext);
  const [search, setSearch] = useState('');
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  // const [client, setClient] = useState('');
  const [mode, setMode] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSearch = () => {
    console.log('Hii');
  };

  const handleMode = (event) => {
    // setClient(event.target.value);
    setMode(event.target.value);
  };

  const handleOnChange = (event) => {
    setSearch(event.target.value);
  };

  const handleClearFilter = () => {
    setMode('');
  };

  const fetchManagementTableData = async () => {
    setLoading(true);
    try {
      const response =
        userRole === 'SUPER_USER'
          ? await getManagementUserDetails()
          : await getManagementUserDetailsByUserGroup(userGroup);
      if (response.status === 200) {
        setTableData(response.data);
        setFilteredTableData(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 403) {
        enqueueSnackbar('You dont have permission!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchManagementTableData();
  }, []);

  useEffect(() => {
    mode
      ? setFilteredTableData(tableData.filter((tableData) => tableData.role === mode))
      : setFilteredTableData(tableData);
  }, [mode]);

  useEffect(() => {
    setFilteredTableData(
      tableData.filter((tableData) => tableData.username.toLowerCase().startsWith(search.toLowerCase()))
    );
  }, [search]);

  const data = {
    search,
    handleSearch,
    handleOnChange,
    handleClearFilter,
    handleStatus: handleMode,
    status: mode,
    firstFilterText: '',
    secondFilterText: 'Filter By Role',
    clearFilterText: CLIENT_PAGE.clientClearFilterButton,
    firstFilterValues: {
      first: '',
      second: '',
    },
    secondFilterValues: {
      filterData: ['ADMIN', 'EXECUTOR', 'READER'],
    },
    isFirstFilterVisible: false,
  };

  return (
    <>
      <Grid container direction="row" display="flex">
        <Grid item xs={12} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
          User Management
        </Grid>
      </Grid>
      {tableData.length > 0 && <SearchAndFilter data={data} />}
      {filteredTableData.length === 0 ? (
        !loading && (
          <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
            <ClientNoData
              title={USER_PAGE.userPageNoDataToDisplay}
              text={USER_PAGE.noUserToReview}
              // addBtnText={ENGAGEMENT_PAGE.addNewEngagementButton}
              // handleOnClick={handleOpenModal}
              // isAddBtn
            />
          </Paper>
          // <></>
        )
      ) : (
        <ManagementTable tableData={filteredTableData} />
      )}
      {loading && <LoadingIcon />}
    </>
  );
};

export default ManagementPage;
