import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    '& article': {
      margin: 'auto',
      width: '70%',
      padding: '10px',
      textAlign: 'center',
      color: '#43425d',
    },
  },

  summary: {
    letterSpacing: '1px',
    width: '80%',
    textAlign: 'justify',
  },
  title: {
    color: '#13294b',
  },

  description: {
    color: '#4e5e78',
  },

  feature: {
    position: 'relative',
    fontWeight: '600',
    paddingBottom: '20px',
    width: '250px',
    height: '80px',
    color: '#13294b',
    whiteSpace: 'break-spaces',
    '&:after': {
      background:
        'url(https://www.nagarro.com/hubfs/NagarroWebsiteRedesign-Aug2020/Assets/Images/title-underline.png) no-repeat',
      backgroundPosition: 'left bottom',
      paddingTop: '40px',
      content: '""',
      position: 'absolute',
      left: '10px',
      //   bottom: '25px',
      width: '100%',
      height: '3px',
    },
  },

  header: {
    height: '50px',
    width: '100%',
    background: '#13294b',
  },

  actions: {
    margin: '0 auto',
  },

  content: {
    marginTop: '20px',
    minWidth: '200px',
  },
});

export default useStyles;
