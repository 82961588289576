import React, { useContext, useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Box, Typography, Tab, Tabs, Paper, Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { WORKFLOW_PAGE } from '../utils/commonConstants';
import SearchAndFilter from '../reusable-component/SearchAndFilter';
import AccordionTable from '../reusable-component/AccordionTable';
import ClientNoData from '../components/Client/ClientNoData';
import AddWorkflowModal from '../reusable-component/AddWorkflowModal';
import LoadingIcon from '../reusable-component/LoadingIcon';
import usePrevious from '../hooks/usePrevious';
import {
  getAllWorkflowApi,
  getWorkflowByUserGroup,
  getWorkflowByUserGroupAndProcessType,
  getAllWorkflowByDomainIdApi,
  getWorkflowByUserGroupAndDomainId,
  getWorkflowByUserGroupAndDomainIdAndProcessType,
  getAllWorkflowByDomainIdAndProcessType,
  getAllWorkflowByProcessType,
} from "../api's/WorkflowApi";
import PaginationFooter from '../reusable-component/PaginationFooter';
import { AuthContext } from '../contexts/AuthProvider';
import AccessModal from '../reusable-component/AccessModal';
import useDebounce from '../hooks/useDebounce';

export default function WorkflowPage({ processType }) {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(() => {
    const storedValue = sessionStorage.getItem('filtered_workflow_domain');
    return storedValue !== null ? storedValue : '';
  });
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const [client, setClient] = useState(() => {
    const storedValue = sessionStorage.getItem('filtered_workflow_client');
    return storedValue !== null ? storedValue : '';
  });
  const [engagement, setEngagement] = useState(() => {
    const storedValue = sessionStorage.getItem('filtered_workflow_engagement');
    return storedValue !== null ? storedValue : '';
  });
  const [search, setSearch] = useState(() => {
    const storedValue = sessionStorage.getItem('filtered_workflow_search');
    return storedValue !== null ? storedValue : '';
  });
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const { userRole, domainId, userGroup } = useContext(AuthContext);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [previousProcessType, setPreviousProcessType] = useState('');
  const prevProcessType = usePrevious(processType);

  const type =
    processType === 'data-quality'
      ? 'Data Quality'
      : processType === 'data-copy'
      ? 'Data Copy'
      : processType === 'data-transformation'
      ? 'Data Transformation'
      : processType === 'reporting'
      ? 'Reporting'
      : processType === 'Ingestion'
      ? 'Data Ingestion'
      : processType === 'modelling-analytics'
      ? 'Modelling & Analytics'
      : processType === 'xperience360'
      ? 'XPerience360'
      : processType === 'customer-segmentation'
      ? 'Customer Segmentation'
      : 'data';

  const handleModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenModal(!openModal);
    }
  };

  const handleSearch = () => {
    console.log(search);
  };

  const handleMode = (event) => {
    sessionStorage.setItem('filtered_workflow_domain', event.target.value);
    sessionStorage.setItem('filtered_workflow_client', '');
    sessionStorage.setItem('filtered_workflow_engagement', '');
    setMode(event.target.value);
    setClient('');
    setEngagement('');
  };

  const handleClient = (event) => {
    sessionStorage.setItem('filtered_workflow_client', event.target.value);
    sessionStorage.setItem('filtered_workflow_engagement', '');
    setClient(event.target.value);
    setEngagement('');
  };

  const handleEngagement = (event) => {
    sessionStorage.setItem('filtered_workflow_engagement', event.target.value);
    setEngagement(event.target.value);
  };

  const handleOnChange = (event) => {
    sessionStorage.setItem('filtered_workflow_search', event.target.value);
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem('filtered_workflow_client');
    sessionStorage.removeItem('filtered_workflow_engagement');
    sessionStorage.removeItem('filtered_workflow_domain');
    sessionStorage.removeItem('filtered_workflow_search');
    fetchTableData();
    setMode('');
    setClient('');
    setEngagement('');
    setSearch('');
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  const version = JSON.parse(sessionStorage.getItem('version'));

  const fetchFilterTableData = (responseTableData) => {
    const filtered_domain = sessionStorage.getItem('filtered_workflow_domain') || '';
    const filtered_workflow_engagement = sessionStorage.getItem('filtered_workflow_engagement') || '';
    const filtered_workflow_client = sessionStorage.getItem('filtered_workflow_client') || '';
    const filtered_search = sessionStorage.getItem('filtered_workflow_search') || '';
    const filtered_search_lowercase = filtered_search.toLowerCase() || '';

    const filteredData = responseTableData.filter((data) => {
      return (
        (!filtered_workflow_client || data.client_name === filtered_workflow_client) &&
        (!filtered_workflow_engagement || data.engagement_name === filtered_workflow_engagement) &&
        (!filtered_domain || data.domain_name === filtered_domain) &&
        (!filtered_search || data.workflow_name.toLowerCase().startsWith(filtered_search_lowercase))
      );
    });

    setFilteredTableData(filteredData);
    setPage(1);
  };

  const fetchTableData = async () => {
    setLoading(true);
    setPage(1);

    try {
      const response = type === 'data' ? await getAllWorkflowApi() : await getAllWorkflowByProcessType(type);
      if (response.status === 200) {
        if (version === '1.0') {
          const filterByVersion = response.data.filter(
            (item) => item.process_type === 'Data Ingestion' || item.process_type === 'Data Transformation'
          );

          const filtered_client = sessionStorage.getItem('filtered_workflow_client') || '';
          const filtered_mode = sessionStorage.getItem('filtered_workflow_domain') || '';
          if (filtered_client !== '' || filtered_mode !== '') {
            const client_exist =
              filtered_client && filtered_mode
                ? response.data.filter(
                    (data) => data.client_name === filtered_client && data.domain_name === filtered_mode
                  )
                : filtered_client
                ? response.data.filter((data) => data.client_name === filtered_client)
                : response.data.filter((data) => data.domain_name === filtered_mode);
            if (client_exist.length > 0) {
              setClient(filtered_client);
              setMode(filtered_mode);

              setTableData(response.data);
              setFilteredTableData(client_exist);
            } else {
              // if (sessionStorage.getItem('filtered_workflow_client') && sessionStorage.getItem('filtered_workflow_domain')) {
              //   enqueueSnackbar(
              //     `Client ${sessionStorage.getItem('filtered_workflow_client')} with mode ${sessionStorage.getItem(
              //       'filtered_workflow_domain'
              //     )} not present in ${type}`,
              //     {
              //       variant: 'error',
              //       autoHideDuration: 3000,
              //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
              //     }
              //   );
              // } else if (sessionStorage.getItem('filtered_workflow_client')) {
              //   enqueueSnackbar(`Client ${sessionStorage.getItem('filtered_workflow_client')} not present in ${type}`, {
              //     variant: 'error',
              //     autoHideDuration: 3000,
              //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
              //   });
              // } else {
              //   enqueueSnackbar(`Mode ${sessionStorage.getItem('filtered_workflow_domain')} not present in ${type}`, {
              //     variant: 'error',
              //     autoHideDuration: 3000,
              //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
              //   });
              // }
              handleClearFilter();
            }
          } else {
            setTableData(filterByVersion);
            setFilteredTableData(filterByVersion);
          }
        } else {
          // const filtered_client = sessionStorage.getItem('filtered_workflow_client') || '';
          // const filtered_mode = sessionStorage.getItem('filtered_workflow_domain') || '';
          // const filtered_search = sessionStorage.getItem('filtered_workflow_search') || '';
          // const filtered_search_lowercase = filtered_search.toLowerCase() || '';
          // if (filtered_client !== '' || filtered_search !== '' || filtered_domain !== '') {
          //   const client_exist =
          //     filtered_client && filtered_search && filtered_domain
          //       ? response.data.filter(
          //           (data) =>
          //             data.client_name === filtered_client &&
          //             data.workflow_name.toLowerCase().startsWith(filtered_search_lowercase) &&
          //             data.domain_name === filtered_domain
          //         )
          //       : filtered_search && filtered_domain
          //       ? response.data.filter(
          //           (data) =>
          //             data.workflow_name.toLowerCase().startsWith(filtered_search_lowercase) &&
          //             data.domain_name === filtered_domain
          //         )
          //       : filtered_client && filtered_domain
          //       ? response.data.filter(
          //           (data) => data.client_name === filtered_client && data.domain_name === filtered_domain
          //         )
          //       : filtered_client && filtered_search
          //       ? response.data.filter(
          //           (data) =>
          //             data.client_name === filtered_client &&
          //             data.workflow_name.toLowerCase().startsWith(filtered_search_lowercase)
          //         )
          //       : filtered_client
          //       ? response.data.filter((data) => data.client_name === filtered_client)
          //       : filtered_domain
          //       ? response.data.filter((data) => data.domain_name === filtered_domain)
          //       : filtered_search
          //       ? response.data.filter((data) => data.workflow_name.toLowerCase().startsWith(filtered_search_lowercase))
          //       : null;

          //   if (client_exist.length > 0) {
          //     setClient(filtered_client);
          //     setMode(filtered_mode);

          //     setTableData(response.data);
          //     setFilteredTableData(client_exist);
          //   } else {
          //     // if (sessionStorage.getItem('filtered_workflow_client') && sessionStorage.getItem('filtered_workflow_domain')) {
          //     //   enqueueSnackbar(
          //     //     `Client ${sessionStorage.getItem('filtered_workflow_client')} with mode ${sessionStorage.getItem(
          //     //       'filtered_workflow_domain'
          //     //     )} not present in ${type}`,
          //     //     {
          //     //       variant: 'error',
          //     //       autoHideDuration: 3000,
          //     //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
          //     //     }
          //     //   );
          //     // } else if (sessionStorage.getItem('filtered_workflow_client')) {
          //     //   enqueueSnackbar(`Client ${sessionStorage.getItem('filtered_workflow_client')} not present in ${type}`, {
          //     //     variant: 'error',
          //     //     autoHideDuration: 3000,
          //     //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
          //     //   });
          //     // } else {
          //     //   enqueueSnackbar(`Mode ${sessionStorage.getItem('filtered_workflow_domain')} not present in ${type}`, {
          //     //     variant: 'error',
          //     //     autoHideDuration: 3000,
          //     //     anchorOrigin: { vertical: 'top', horizontal: 'right' },
          //     //   });
          //     // }
          //     handleClearFilter();
          //   }
          // } else {
          //   setTableData(response.data);
          //   setFilteredTableData(response.data);
          // }

          setTableData(response.data);
          fetchFilterTableData(response.data);
        }
      }
    } catch (error) {
      console.log(error);
      setTableData([]);
      setFilteredTableData([]);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setPreviousProcessType(processType);
    fetchTableData();
    // handleClearFilter();
  }, [processType]);

  const filtered_domain = sessionStorage.getItem('filtered_workflow_domain') || '';
  const filtered_workflow_client = sessionStorage.getItem('filtered_workflow_client') || '';
  const filtered_workflow_engagement = sessionStorage.getItem('filtered_workflow_engagement') || '';
  const filtered_search = sessionStorage.getItem('filtered_workflow_search') || '';

  useEffect(() => {
    setSearch(filtered_search);
    setMode(filtered_domain);
    setClient(filtered_workflow_client);
    setEngagement(filtered_workflow_engagement);
  }, []);

  // useEffect(() => {
  //   if (filtered_workflow_client !== '' || filtered_workflow_engagement !== '' || filtered_search !== '' || filtered_domain !== '') {
  //     fetchFilterTableData(tableData);
  //   } else {
  //     setFilteredTableData(tableData);
  //   }
  // }, [search, mode, client, engagement]);

  const debouncedFilteredData = useDebounce(fetchFilterTableData, 500, [search, mode, client, engagement, tableData]);

  useEffect(() => {
    debouncedFilteredData(tableData);
  }, [debouncedFilteredData]);

  // useEffect(() => {
  //   mode
  //     ? setFilteredTableData(
  //         tableData.filter((tableData) => tableData.client_name === client && tableData.domain_name === mode)
  //       )
  //     : client
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.client_name === client))
  //     : setFilteredTableData(tableData.filter((tableData) => tableData.process_type === type));
  // }, [client]);

  // useEffect(() => {
  //   client
  //     ? setFilteredTableData(
  //         tableData.filter((tableData) => tableData.domain_name === mode && tableData.client_name === client)
  //       )
  //     : mode
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.domain_name === mode))
  //     : setFilteredTableData(tableData.filter((tableData) => tableData.process_type === type));
  // }, [mode]);

  // useEffect(() => {
  //   setFilteredTableData(
  //     tableData.filter((tableData) => tableData.workflow_name.toLowerCase().startsWith(search.toLowerCase()))
  //   );
  // }, [search]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ width: '100%' }}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearch('');
    setClient('');
    setEngagement('');
    setMode('');
    setPage(1);
    // fetchTableData();
    setFilteredTableData(tableData);
  };

  const data = {
    search,
    handleSearch,
    handleOnChange,
    region: mode,
    handleRegion: handleMode,
    handleClearFilter,
    handleStatus: handleClient,
    status: client,
    handleEngagement,
    engagement,
    firstFilterText: WORKFLOW_PAGE.workflowFilterByDomain,
    secondFilterText: WORKFLOW_PAGE.workflowFilterByClient,
    thirdFilterText: WORKFLOW_PAGE.workflowFilterByEngagement,
    clearFilterText: WORKFLOW_PAGE.workflowClearFilterButton,
    firstFilterValues: {
      filterData: [...new Set(tableData.map((item) => item.domain_name))],
    },
    secondFilterValues: {
      filterData: mode
        ? [...new Set(tableData.filter((item) => item.domain_name === filtered_domain).map((item) => item.client_name))]
        : [...new Set(tableData.map((item) => item.client_name))],
    },
    isFirstFilterVisible: true,
    isSecondFilterVisible: mode,
    isThirdFilterVisible: client,
    thirdFilterValues: {
      filterData: client
        ? [
            ...new Set(
              tableData
                .filter((item) => item.client_name === filtered_workflow_client)
                .map((item) => item.engagement_name)
            ),
          ]
        : [...new Set(tableData.map((item) => item.engagement_name))],
    },
  };

  return (
    <>
      <Grid container direction="row" display="flex">
        <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
          {WORKFLOW_PAGE.workflowPageTitle}
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
            onClick={handleModal}
          >
            <MdAdd />
            Add New Workflow
          </Button>
        </Grid>
      </Grid>
      <AddWorkflowModal handleModal={handleModal} open={openModal} fetchTableData={fetchTableData} processType={type} />
      <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#47D7AC',
            },
          }}
          sx={{ '& .MuiTab-root.Mui-selected': { color: '#00043C' } }}
        >
          <Tab label="All Workflows" {...a11yProps(0)} sx={{ fontWeight: 'bold' }} />
          <Tab label="Batch Workflow" {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
          <Tab label="Real Time Workflow" {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
        </Tabs>
      </Box>
      <SearchAndFilter data={data} key="workflow-page" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <TabPanel value={value} index={0}>
          {filteredTableData.length === 0 ? (
            !loading && (
              <Paper elevation={3} sx={{ height: '330px', marginTop: '10px' }}>
                <ClientNoData
                  title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
                  text={WORKFLOW_PAGE.noWorkflowToReview}
                  addBtnText={WORKFLOW_PAGE.addNewWorkflowButton}
                  handleOnClick={handleModal}
                  isAddBtn
                />
              </Paper>
            )
          ) : (
            <AccordionTable
              data={filteredTableData}
              type="all"
              fetchTableData={fetchTableData}
              rowsPerPage={rowsPerPage}
              page={page}
              processType={processType}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {filteredTableData.filter((tableData) => tableData.execution_mode === 'Batch').length === 0 ? (
            !loading && (
              <Paper elevation={3} sx={{ height: '330px', marginTop: '10px' }}>
                <ClientNoData
                  title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
                  text={WORKFLOW_PAGE.noWorkflowToReview}
                  addBtnText={WORKFLOW_PAGE.addNewWorkflowButton}
                  handleOnClick={handleModal}
                  isAddBtn
                />
              </Paper>
            )
          ) : (
            <AccordionTable
              data={filteredTableData.filter((tableData) => tableData.execution_mode === 'Batch')}
              type="batch"
              fetchTableData={fetchTableData}
              rowsPerPage={rowsPerPage}
              page={page}
              processType={processType}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {filteredTableData.filter((tableData) => tableData.execution_mode === 'Real Time').length === 0 ? (
            !loading && (
              <Paper elevation={3} sx={{ height: '330px', marginTop: '10px' }}>
                <ClientNoData
                  title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
                  text={WORKFLOW_PAGE.noWorkflowToReview}
                  addBtnText={WORKFLOW_PAGE.addNewWorkflowButton}
                  handleOnClick={handleModal}
                  isAddBtn
                />
              </Paper>
            )
          ) : (
            <AccordionTable
              data={filteredTableData.filter((tableData) => tableData.execution_mode === 'Real Time')}
              type="realTime"
              fetchTableData={fetchTableData}
              rowsPerPage={rowsPerPage}
              page={page}
              processType={processType}
            />
          )}
        </TabPanel>
        {loading && <LoadingIcon />}
      </Box>
      <PaginationFooter tableData={filteredTableData} page={page} handleChangePage={handleChangePage} />
    </>
  );
}
