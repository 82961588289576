import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Stack,
  IconButton,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { FaUserCircle } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { MdArrowBackIosNew } from 'react-icons/md';
import useStyles from './MDMStyles';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} {...props} />)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const PotentialMatches = ({ handleIndividualRecords, row }) => {
  const [expanded, setExpanded] = useState('panel1');

  const classes = useStyles();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Stack direction="row" my={1} alignItems="center">
        <IconButton size="small" aria-label="back" onClick={handleIndividualRecords}>
          <MdArrowBackIosNew />
        </IconButton>
        <FaUserCircle size="65" color={grey[300]} />
        <Stack direction="column" ml={1}>
          <Typography variant="body1" display="block" fontWeight={600}>
            {row.name}
          </Typography>
          <Typography variant="caption" display="block">
            {row.address}
          </Typography>
          <Typography variant="caption" component="div">
            <Chip label="Individual" color="primary" size="small" sx={{ mr: 1, fontSize: 12 }} />
            Entity ID: {row.id}
          </Typography>
        </Stack>
      </Stack>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>Attributes</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Attribute Values</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Frist Name
              </TableCell>
              <TableCell>
                {row.sources.map((item) => (
                  <Typography variant="body2">{item.firstName[0].value}</Typography>
                ))}
              </TableCell>
            </TableRow>
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Gender
              </TableCell>
              <TableCell>
                {row.sources.map((item) => (
                  <Typography variant="body2">{item.gender[0].value}</Typography>
                ))}
              </TableCell>
            </TableRow>
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Phone
              </TableCell>
              <TableCell>
                {row.sources.map((item) =>
                  item.phone.map((ele) => (
                    <Accordion expanded={expanded === ele} onChange={handleChange(ele)}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        {ele.type[0].value} {ele.number[0].value}
                      </AccordionSummary>
                      <AccordionDetails sx={{ ml: 3 }}>
                        <Typography variant="body2" gutterBottom>
                          Type: {ele.type[0].value}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Number: {ele.number[0].value}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Country Code: {ele.countryCode[0].value}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Formatted Number: {ele.formatted_number[0].value}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Area Code: {ele.area_code}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Local Number: {ele.local_number}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Validation Status: {ele.validation_status}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Line Type: {ele.line_type}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Format Mask: {ele.format_mask}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Digit Count: {ele.digit_count}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Geo Area: {ele.geo_area}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Geo Country: {ele.geo_country}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}
              </TableCell>
            </TableRow>
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Address
              </TableCell>
              <TableCell>
                {row.sources.map((item) =>
                  item.address.map((ele) => (
                    <Accordion expanded={expanded === ele} onChange={handleChange(ele)}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        {ele.address_line_1[0].value}, {ele.city[0].value}, {ele.state[0].value}
                      </AccordionSummary>
                      <AccordionDetails sx={{ ml: 3 }}>
                        <Typography variant="body2" gutterBottom>
                          Address Line 1: {ele.address_line_1[0].value}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          City: {ele.city[0].value}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          State: {ele.state[0].value}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Country: {ele.country[0].value}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Zip5: {ele.zip5[0].value}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Zip4: {ele.zip4[0].value}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Verification Status: {ele.verification_status}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Verification Status Details: {ele.verification_status_details}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          AVC: {ele.avc}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Latitude: {ele.latitude}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Longitude: {ele.longitude}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}
              </TableCell>
            </TableRow>
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Email
              </TableCell>
              <TableCell>
                {row.sources.map((item) =>
                  item.email.map((ele) => (
                    <Accordion expanded={expanded === ele} onChange={handleChange(ele)}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        {ele.type[0].value} {ele.email[0].value}
                      </AccordionSummary>
                      <AccordionDetails sx={{ ml: 3 }}>
                        <Typography variant="body2" gutterBottom>
                          Type: {ele.type[0].value}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Email: {ele.email[0].value}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          Domain: {ele.domain}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Domain Type: {ele.domain}_type
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Username: {ele.username}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Validation Status: {ele.validation_status}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}
              </TableCell>
            </TableRow>
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Marital Status
              </TableCell>
              <TableCell>
                {row.sources.map((item) => (
                  <Typography variant="body2" gutterBottom>
                    {item.maritalStatus[0].value}
                  </Typography>
                ))}
              </TableCell>
            </TableRow>
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Last Updated
              </TableCell>
              <TableCell>
                {row.sources.map((item) => (
                  <Typography variant="body2" gutterBottom>
                    {item.dob}
                  </Typography>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PotentialMatches;
