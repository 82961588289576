import { Backdrop, Box, CircularProgress } from '@mui/material';
import React from 'react';
import LoaderIcon from './LoaderIcon';

export default function LoadingIcon({ size }) {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open="true">
      {/* <CircularProgress color="inherit" /> */}
      <LoaderIcon />
    </Backdrop>
  );
}
