import { Progress } from 'antd';
import React, { useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import '../SingleValueCard.css';

function PercentageValue({ content, toolsVisible, percentageShowType, colorCode }) {
  const percentType = ['progress', 'profit', 'loss'];

  const initialType = percentType.includes(percentageShowType) ? percentageShowType : percentType[0];
  const [showType, setShowType] = useState(initialType);
  const [color, setColor] = useState(colorCode);

  const handleArrowExchange = () => {
    setShowType((prevType) => {
      const currentIndex = percentType.indexOf(prevType);
      const nextIndex = (currentIndex + 1) % percentType.length;
      return percentType[nextIndex];
    });
  };

  const handleColorChange = (prop) => {
    setColor(prop);
  };

  return (
    <>
      <span className="progress-circle">
        {showType === 'progress' && (
          <Progress type="circle" percent={Number(content).toFixed(2)} size={70} strokeColor={color} />
        )}
        {showType === 'profit' && (
          <span style={{ marginBottom: '16px' }}>
            {Number(content).toFixed(2)}%
            <FaArrowUp style={{ color, marginLeft: '5px' }} /> &nbsp;&nbsp;
          </span>
        )}

        {showType === 'loss' && (
          <span style={{ marginBottom: '16px' }}>
            {content}%
            <FaArrowDown style={{ color, marginLeft: '5px' }} /> &nbsp;&nbsp;
          </span>
        )}

        {toolsVisible && (
          <>
            {/* <span
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'row',
                gap: '2px',
                color: 'lightgrey',
                left: '10px',
                bottom: '10px',
              }}
            >
              <FaCircle size={10} color="#FF4D4F" onClick={() => handleColorChange('#FF4D4F')} />
              <FaCircle size={10} color="#52C41A" onClick={() => handleColorChange('#52C41A')} />
              <FaCircle size={10} color="#1677FF" onClick={() => handleColorChange('#1677FF')} />
            </span> */}
            {/* <span
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                color: 'lightgrey',
                right: '18%',
                bottom: '25%',
              }}
            >
             
              <CgArrowsExchangeV className="ArrowsExchange" onClick={handleArrowExchange} />
            </span> */}
          </>
        )}
      </span>
    </>
  );
}

export default PercentageValue;
