import React, { useContext, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ONBOARD } from '../utils/commonConstants';
import { WorkflowContext } from '../contexts/WorkflowProvider';

const WorkflowNavigateSection = ({
  value,
  handleChangeIndex,
  loading,
  tabType,
  callDataProcessorFn,
  callCreatePreprocessorFn,
  callCreateRuleEngineFn,
  callRunPreprocessorFn,
  handleClickOpenRuleEngineModal,
  runDataProcessorWorkflow,
  processType,
}) => {
  const navigate = useNavigate();
  const { workflow, isDisabled } = useContext(WorkflowContext);
  const [cloudEnv, setcloudEnv] = useState('DATABRICKS');

  const val = workflow.process_type === 'Data Ingestion' ? 2 : 1;

  return (
    <Grid container direction="row" display="flex" mt={1}>
      <Grid item xs={6} direction="row" justifyContent="flex-start">
        <Button
          size="small"
          type="submit"
          variant="contained"
          sx={{
            marginRight: '7px',
            marginTop: '7px',
            backgroundColor: '#ffffff',
            '&:hover': { backgroundColor: '#ffffff' },
            color: '#00043C',
            border: '1px solid black',
          }}
          onClick={() => navigate(`/workflow-${processType}`)}
        >
          {CLIENT_ONBOARD.cancelButton}
        </Button>
        {value !== 0 && (
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
            onClick={(e) => handleChangeIndex(e, value - val)}
            disabled={
              workflow.process_type === 'Modelling & Analytics' ||
              workflow.process_type === 'XPerience360' ||
              workflow.process_type === 'Customer Segmentation' ||
              (workflow.process_type === 'Data Quality' && value === 1)
            }
          >
            {CLIENT_ONBOARD.previousButton}
          </Button>
        )}
        {value !== 2 && (
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
            onClick={(e) => handleChangeIndex(e, value + val)}
          >
            skip
          </Button>
        )}
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        <FormControl>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={workflow.cloudEnvironment}
            style={{ width: '150px', marginTop: 8, marginRight: 10 }}
            disabled
          >
            <MenuItem value={workflow.cloudEnvironment}>{workflow.cloudEnvironment}</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          size="small"
          type="submit"
          variant="outlined"
          loading={loading}
          sx={{
            marginTop: '7px',
            backgroundColor: '#ffffff',
            '&:hover': { backgroundColor: '#ffffff' },
            color: '#00043C',
            border: '1px solid black',
          }}
          onClick={() => {
            tabType === 'preprocessor'
              ? callRunPreprocessorFn()
              : tabType === 'ruleengine'
              ? handleClickOpenRuleEngineModal()
              : runDataProcessorWorkflow();
          }}
          disabled={isDisabled}
        >
          Run
        </LoadingButton>
        {value !== 2 && (
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              marginLeft: '8px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
            onClick={() => {
              tabType === 'preprocessor' ? callCreatePreprocessorFn() : callCreateRuleEngineFn();
            }}
            disabled={isDisabled}
          >
            Save
          </Button>
        )}
        {value !== 2 && (
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginRight: '7px',
              marginTop: '7px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#ffffff' },
              color: '#00043C',
              border: '1px solid black',
            }}
            onClick={(e) => handleChangeIndex(e, value + val)}
          >
            Next
          </Button>
        )}
        {value === 2 && (
          <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{
              marginTop: '7px',
              marginRight: '7px',
              marginLeft: '8px',
              backgroundColor: '#00043C',
              '&:hover': { backgroundColor: '#00043C' },
            }}
            onClick={() => callDataProcessorFn()}
            disabled={isDisabled}
          >
            Save & Submit
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default WorkflowNavigateSection;
