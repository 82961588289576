import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

// export const getClientByUserGroup = async (user_group) => {
//   try {
//     const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
//     return await axios.get(`${BASEURL}/user-mgmt/get-pending-user`, {
//          headers: { Authorization: `Bearer ${parseToken}` },
//       params: { user_group },
//     });
//   } catch (error) {
//     if (error.response.status === 401) {
//         await logoutApi();
//     }
//     throw error;
//   }
// };

export const getManagementUserDetails = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/user-mgmt/get-user-details`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getManagementUserDetailsByUserGroup = async (userGroup) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/user-mgmt/get-user-details-by-user-group`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { userGroup },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
