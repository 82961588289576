import React, { useContext, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Menu,
  MenuItem,
  Fade,
  Box,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { TfiMoreAlt } from 'react-icons/tfi';
import { createStyles, withStyles } from '@mui/styles';
import { DateConverter } from '../../utils/DateConverter';
import RedChip from '../../reusable-component/RedChip';
import GreenChip from '../../reusable-component/GreenChip';
import YellowChip from '../../reusable-component/YellowChip';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import ViewWorkflow from '../view-workflow/ViewWorkflow';
import { getWorkflowByUserGroupAndEngagementIdApi } from "../../api's/WorkflowApi";
import DeleteModal from '../../reusable-component/DeleteModal';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { AuthContext } from '../../contexts/AuthProvider';
import AccessModal from '../../reusable-component/AccessModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
    textAlign: 'center',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function EngagementTable({ tableData, fetchTableData, currentPage, setPage }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [page, setPage] = useState(1);
  const [engagementId, setEngagementId] = useState('');
  const [openWorkflow, setOpenWorkflow] = useState(false);
  const [workflowTableData, setWorkflowTableData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const rowsPerPage = 5;
  const open = Boolean(anchorEl);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const { userRole, userGroup } = useContext(AuthContext);

  const handleClick = (id, event) => {
    setEngagementId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenWorkflow = () => {
    setAnchorEl(null);
    setOpenWorkflow(true);
    fetchWorkflowTableData();
  };

  const handleCloseWorkflow = () => {
    setOpenWorkflow(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const version = JSON.parse(sessionStorage.getItem('version'));

  const fetchWorkflowTableData = async () => {
    setLoading(true);
    try {
      const { data, status } = await getWorkflowByUserGroupAndEngagementIdApi(userGroup, engagementId);

      if (status === 200) {
        if (version === '1.0') {
          const filterByVersion = data.filter(
            (item) => item.process_type === 'Data Ingestion' || item.process_type === 'Data Transformation'
          );
          setWorkflowTableData(filterByVersion);
        } else {
          setWorkflowTableData(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleOpenDeleteModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenDeleteModal(true);
      handleClose();
    }
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    handleClose();
  };

  const navigateToEngagement = async (row) => {
    setLoading(true);
    try {
      const response = await getWorkflowByUserGroupAndEngagementIdApi(userGroup, row.engagement_id);
      if (response.status === 200) {
        navigate('/engagement/view_details', {
          state: {
            engagement_id: row.engagement_id,
            engagement_name: row.engagement_name,
            client_name: row.client_name,
            domain_name: row.domain_name,
            workflow_count: row.workflow_count,
            workflow_name: row.workflow_name,
            execution_mode: row.execution_mode,
            created_by: row.created_by,
            created_at: row.created_at,
            status: row.status,
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  return (
    <Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          style={{ minWidth: 650, borderSpacing: '0px 4px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Engagement Name</StyledTableCell>
              <StyledTableCell>Total Workflow</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow key={row.engagement_id}>
                  <StyledTableCell>
                    {row.status === 'approved' ? (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          navigateToEngagement(row);
                        }}
                        sx={{ textDecoration: 'none', color: '#1976D2' }}
                      >
                        {row.engagement_name}
                      </Link>
                    ) : (
                      row.engagement_name
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row.workflow_count}</StyledTableCell>
                  <StyledTableCell>{row.created_by}</StyledTableCell>
                  <StyledTableCell>{DateConverter(row.created_at)}</StyledTableCell>
                  <StyledTableCell>
                    {row.status === 'approved' ? (
                      <GreenChip title="Approved" />
                    ) : row.status === 'pending' ? (
                      <YellowChip title="Pending" />
                    ) : (
                      <RedChip title="Rejected" />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <>
                      <Button
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => handleClick(row.engagement_id, e)}
                      >
                        <TfiMoreAlt />
                      </Button>
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                      >
                        {row.status === 'approved' && <MenuItem onClick={handleOpenWorkflow}>View Workflow</MenuItem>}
                        <MenuItem onClick={handleOpenDeleteModal}>Delete Engagement</MenuItem>
                      </Menu>
                    </>
                    <ViewWorkflow
                      openWorkflow={openWorkflow}
                      handleCloseWorkflow={handleCloseWorkflow}
                      tableData={workflowTableData}
                      engagementName={row.engagement_name}
                      fetchWorkflowTableData={fetchWorkflowTableData}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        fetchData={fetchTableData}
        type="Engagement"
        id={engagementId}
      />
      <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
      <PaginationFooter tableData={tableData} page={currentPage} handleChangePage={handleChangePage} />
      {loading && <LoadingIcon />}
    </Box>
  );
}
