import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { DateConverter } from '../../utils/DateConverter';
import RedChip from '../../reusable-component/RedChip';
import GreenChip from '../../reusable-component/GreenChip';
import YellowChip from '../../reusable-component/YellowChip';
import PaginationFooter from '../../reusable-component/PaginationFooter';
import BasicMenu from '../../reusable-component/BasicMenu';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { getWorkflowById } from "../../api's/WorkflowApi";
import { getDataProcessor, getNodeStatus } from "../../api's/DataProcessorApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
    textAlign: 'center',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

export default function WorkflowTable({ tableData, fetchWorkflowTableData, currentPage, setPage }) {
  // const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const navigateToWorkflow = async (row) => {
    sessionStorage.removeItem('allNodes');
    sessionStorage.removeItem('node');
    sessionStorage.removeItem('elementCount');
    sessionStorage.removeItem('saved_node');
    sessionStorage.removeItem('edges');
    setLoading(true);
    const response = await getWorkflowById(row.workflow_id);

    let result, nodeStatus;
    try {
      result = await getDataProcessor(row.workflow_id, row.execution_mode);
      nodeStatus = await getNodeStatus(
        response.data.engagement.client.client_name,
        response.data.engagement.engagement_name,
        response.data.workflow_name,
        response.data.cloudEnvironment
      );
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      if (result) {
        let elemCount = 0;

        const newSavedElement = [];

        result.data.nodes.forEach((el) => {
          el['nodeId'] = `${el.nodeId}`;
          elemCount++;
          newSavedElement.push(el.id);
          el.nodeId;
        });

        sessionStorage.setItem('allNodes', JSON.stringify(result.data.form_data));
        sessionStorage.setItem('elementCount', elemCount);
        sessionStorage.setItem('node', JSON.stringify(result.data.nodes));
        sessionStorage.setItem('edges', JSON.stringify(result.data.edges));
        sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
        nodeStatus && sessionStorage.setItem('nodeStatus', JSON.stringify(nodeStatus.data));
      }

      const type =
        row?.process_type === 'Data Quality'
          ? 'data-quality'
          : row?.process_type === 'Data Copy'
          ? 'data-copy'
          : row?.process_type === 'Data Transformation'
          ? 'data-transformation'
          : row?.process_type === 'Reporting'
          ? 'reporting'
          : row?.process_type === 'Data Ingestion'
          ? 'ingestion'
          : row?.process_type === 'Modelling & Analytics'
          ? 'modelling-analytics'
          : row?.process_type === 'XPerience360'
          ? 'xperience360'
          : row?.process_type === 'Customer Segmentation'
          ? 'customer-segmentation'
          : 'data';

      navigate(`/workflow-${type}/onboarding`, {
        state: {
          workflow_name: row.workflow_name,
          client_name: row.client_name,
          engagement_name: row.engagement_name,
          execution_mode: row.execution_mode,
          created_by: row.created_by,
          created_at: row.created_at,
          status: row.status,
          workflow_data: response.data,
        },
      });
    }
    setLoading(false);
  };

  return (
    <Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          style={{ minWidth: 650, borderSpacing: '0px 4px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Workflow Name</StyledTableCell>
              <StyledTableCell>Engagement Name</StyledTableCell>
              <StyledTableCell>Execution Mode</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow key={row.workflow_id}>
                  <StyledTableCell>
                    {row.status === 'approved' ? (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          navigateToWorkflow(row, setLoading);
                        }}
                        sx={{ textDecoration: 'none', color: '#1976D2' }}
                      >
                        {row.workflow_name}
                      </Link>
                    ) : (
                      row.workflow_name
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row.engagement_name}</StyledTableCell>
                  <StyledTableCell>{row.execution_mode}</StyledTableCell>
                  <StyledTableCell>{row.created_by}</StyledTableCell>
                  <StyledTableCell>{DateConverter(row.created_at)}</StyledTableCell>
                  <StyledTableCell>
                    {row.status === 'approved' ? (
                      <GreenChip title="Approved" />
                    ) : row.status === 'pending' ? (
                      <YellowChip title="Pending" />
                    ) : (
                      <RedChip title="Rejected" />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <BasicMenu row={row} type="Workflow" fetchData={fetchWorkflowTableData} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationFooter tableData={tableData} page={currentPage} handleChangePage={handleChangePage} />
      {loading && <LoadingIcon />}
    </Box>
  );
}
