import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FiMaximize, FiMinimize } from 'react-icons/fi';
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { downloadFilesOfS3 } from "../../api's/DataProcessorApi";
import { updateTileTitle } from "../../api's/ReportingApi";
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import ReportLLM from './ReportLLM';

function ViewTable({ props, rg_id, path, handleDeleteTile, title, setIsViewTable, fileName }) {
  console.log('props: ', props);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);
  // const [isFullScreen, setIsFullScreen] = useState(false);
  // const [title, setTitle] = useState(title);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const nameRegex = /^[a-zA-Z0-9 ]{1,35}$/;
  const titleInputRef = useRef(null);

  // const handleEditTile = async () => {
  //   setIsEditingTitle(false);
  //   if (title.trim() === '') {
  //     setTitle(initialTitle);
  //     return;
  //   }
  //   if (!title.match(nameRegex)) {
  //     setTitle(initialTitle);
  //     enqueueSnackbar('NO SPECIAL CHARACTER ALLOWED!', {
  //       variant: 'error',
  //       autoHideDuration: 3000,
  //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
  //     });
  //     return;
  //   }

  //   try {
  //     const response = await updateTileTitle(rg_id, title);
  //   } catch (error) {
  //     setTitle(initialTitle);
  //     enqueueSnackbar('Error while saving Title!', {
  //       variant: 'error',
  //       autoHideDuration: 3000,
  //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (isEditingTitle && titleInputRef.current) {
  //     titleInputRef.current.focus();
  //   }
  // }, [isEditingTitle]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setIsEditingTitle(true);
    }
  };

  const handleSetData = () => {
    const headers = [
      { field: 'id', headerName: 'Id', width: 100 },
      { field: props.xaxis_name, headerName: props.xaxis_name, width: 150 },
      { field: props.yaxis_name, headerName: props.yaxis_name, width: 150 },
    ];
    // data?.headers?.map((el, i) => {
    //   headers.push({
    //     field: el.title,
    //     headerName: el.dataIndex,
    //     width: 150,
    //   });
    // });
    setColumns(headers);

    const values = [];
    const xaxisData = props.data.xaxis_values;
    const yaxisData = props.data.yaxis_values;
    for (let i = 0; i < xaxisData.length; i++) {
      values.push({
        id: i + 1,
        [props.xaxis_name]: xaxisData[i],
        [props.yaxis_name]: yaxisData[i],
      });
    }
    // data?.data?.map((el, i) => {
    //   values.push({
    //     // id: uuidv4(),
    //     id: i + 1,
    //     ...el,
    //   });
    // });
    setRows(values);
  };

  const handleFullScreenModal = () => {
    // setIsFullScreen(!isFullScreen);
    setIsViewTable(false);
  };

  useEffect(() => {
    handleSetData();
  }, []);

  const handleDownloadFile = async () => {
    enqueueSnackbar('File Downloading Started.', {
      variant: 'warning',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    const sampleCSVLocation = `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${
      path.split('/')[2]
    }/Data_Processor/SampleRecords/${path.split('/')[5]}/Output/${fileName}`;

    const payload = {
      file_path: sampleCSVLocation,
      client_id: workflow.engagement.client.client_id,
      aws_config_type: AWS_CONFIG_TYPE,
    };
    try {
      await downloadFilesOfS3(payload);
      enqueueSnackbar('File Downloaded.', {
        variant: 'success',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Internal Server Error.', {
        variant: 'warning',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const fullscreenCSS = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%', // Use full viewport height
    zIndex: '1000', // Ensure it's on top
    backgroundColor: 'white',
    padding: '16px 50px 50px 50px',
    // overflowY: 'auto',
  };

  return (
    <Box sx={fullscreenCSS}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '0px',
          padding: '0 15px 0 0',
          backgroundColor: '#EFF5FB',
        }}
      >
        <span style={{ color: '#777', fontFamily: 'Raleway, sans-serif', alignSelf: 'center', margin: '0 0 0 15px' }}>
          {/* {isEditingTitle ? (
            <input
              type="text"
              value={title}
              ref={titleInputRef}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={() => handleEditTile()}
            />
          ) : ( */}
          {/*  <div>{title !== '' ? title : `Analysis of ${data?.yaxis_name} by ${data?.xaxis_name}`}</div> */}
          <div>
            Data set of <b>{title}</b>
          </div>
          {/* )} */}
        </span>

        <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          {/* <IconButton
            edge="end"
            onClick={() => {
              setIsEditingTitle(true);
            }}
            aria-label="fullscreen"
            sx={{ fontSize: '1rem' }}
          >
            <FaEdit />
          </IconButton> */}
          <IconButton edge="end" onClick={handleFullScreenModal} aria-label="fullscreen" sx={{ fontSize: '1rem' }}>
            <IoMdClose />
          </IconButton>
          {/* <IconButton edge="end" onClick={handleDownloadFile} aria-label="fullscreen" sx={{ fontSize: '1rem' }}>
            <BsDownload />
          </IconButton> */}
          {/* <IconButton
            edge="end"
            onClick={() => {
              // handleDeleteTile(rg_id);
              // setIsFullScreen(false);
            }}
            aria-label="close"
            sx={{ fontSize: '1rem' }}
          >
            <FaTrashAlt />
          </IconButton> */}
        </div>
      </div>

      <DataGrid
        rows={rows}
        columns={columns}
        sx={{
          // '& .MuiDataGrid-columnHeaders': {
          //   backgroundColor: '#1976d2', // Change this to your desired color
          //   color: '#ffffff', // Change this to your desired text color
          // },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#EFF5FB', // Change this to your desired color
            // color: '#ffffff', // Change this to your desired text color
            // marginTop: isFullScreen ? '-2em' : '',
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[15]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default ViewTable;
