export const dataConvertor = (value) => {
  const dataInKB = value / 1024;
  let answer = '';
  if (dataInKB < 1) {
    answer = `${value.toFixed(2)} B`;
  } else {
    const dataInMB = dataInKB / 1024;
    if (dataInMB < 1) {
      answer = `${dataInKB.toFixed(2)} KB`;
    } else {
      const dataInGB = dataInMB / 1024;
      if (dataInGB < 1) {
        answer = `${dataInMB.toFixed(2)} MB`;
      } else {
        answer = `${dataInGB.toFixed(2)} GB`;
      }
    }
  }
  return answer;
};
