import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Divider from '@mui/material/Divider';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddClientDetailEngagement from '../../../components/Client/AddClientDetailEngagement';
import { CLIENT_ONBOARD } from '../../../utils/commonConstants';
import ClientSubmittedModal from '../../../components/Client/ClientSubmittedModal';
import useStyles from './styles';
import { createClientApi } from "../../../api's/ClientApi";
import { createEngagementApi } from "../../../api's/EngagementApi";
import { AuthContext } from '../../../contexts/AuthProvider';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import { getAllDomainsApi } from "../../../api's/DomainApi";
import AccessModal from '../../../reusable-component/AccessModal';

const ClientOnboard = () => {
  const navigate = useNavigate();
  const { userId } = useContext(AuthContext);
  const [clientIcon, setClientIcon] = useState(false);
  const [engagementIcon, setEngagementIcon] = useState(false);
  const [visible, setVisible] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [clientName, setClientName] = useState('');
  const [engagementName, setEngagementName] = useState('');
  const [dataRegion, setDataRegion] = useState('');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [clientNameError, setClientNameError] = useState('');
  const [engagementNameError, setEngagementNameError] = useState('');
  const [fetchedDomainNames, setFetchedDomainNames] = useState([]);
  const [fetchedDomain, setFetchedDomain] = useState([]);
  const [domain, setDomain] = useState({});
  const [domainNameError, setDomainNameError] = useState('');
  const [dataRegionError, setDataRegionError] = useState('');
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { userRole, domainId, domainName, userGroup } = useContext(AuthContext);

  const classes = useStyles();
  // const nameRegex = /^\S{1,35}$/;
  const nameRegex = /^[a-zA-Z0-9 ]{1,35}$/;

  const handleVisible = () => {
    if (clientName.trim() === '' || !domain.domain_name || dataRegion === '') {
      if (dataRegion === '') {
        setDataRegionError('Data Region is required.');
      } else {
        setDataRegionError('');
      }

      if (clientName.trim() === '') {
        setClientNameError('Client Name is required.');
      } else if (!clientName.match(nameRegex)) {
        setClientNameError(`NO SPECIAL CHARACTER ALLOWED.`);
      } else {
        setClientNameError('');
      }

      if (!domain.domain_name) {
        setDomainNameError('Domain Name is required.');
      } else {
        setDomainNameError('');
      }

      enqueueSnackbar('Please complete all fields !', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });

      return;
    }

    setVisible(!visible);
    setClientIcon(!clientIcon);
  };

  const fetchAllDomain = async () => {
    try {
      const response = await getAllDomainsApi();

      if (response.status === 200) {
        const approvedDomains = response.data.filter((el) => el.status === 'approved');
        setFetchedDomainNames(approvedDomains.map((el) => el.domain_name));
        setFetchedDomain(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 domain not found !', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (engagementName) {
      setEngagementIcon(!engagementIcon);
      const user_id = userId;
      let data = {
        client_name: clientName,
        data_region: dataRegion,
      };
      try {
        const clientResponse = await createClientApi(user_id, domain.domain_id, data);
        if (clientResponse.status === 201) {
          data = {
            engagement_name: engagementName,
            engagement_type: 'daily',
            user_id,
            client_id: clientResponse.data.client_id,
          };
          const engagementResponse = await createEngagementApi(data);
          if (engagementResponse.status === 201) {
            setSubmit(true);
            enqueueSnackbar('Client added successfully!', {
              variant: 'success',
              autoHideDuration: 3000,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }
        }
      } catch (error) {
        console.log(error);
        if (error.response?.status === 409) {
          enqueueSnackbar('Client or Engagement with the same name already exist.', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (error.response.status === 403) {
          enqueueSnackbar('You have only Read Permission !!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar(error.response.data || 'Internal Server Error!!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
    } else if (!engagementName) {
      setEngagementNameError('Engagement Name is required.');
      enqueueSnackbar('Please complete all fields !', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoading(false);
  };

  const handleChange = (event, source) => {
    switch (source) {
      case 'clientName': {
        const newValue = event.target.value;
        setClientName(newValue);

        if (newValue.trim() === '') {
          setClientNameError('Client Name is required.');
        } else if (!newValue.match(nameRegex)) {
          setClientNameError(`Invalid Client Name Format "${newValue}". NO SPECIAL CHARACTER ALLOWED.`);
        } else {
          setClientNameError('');
        }
        break;
      }
      case 'engagementName': {
        const newValue = event.target.value;

        setEngagementName(event.target.value);
        if (newValue.trim() === '') {
          setEngagementNameError('Engagement Name is required.');
        } else if (!newValue.match(nameRegex)) {
          setEngagementNameError(`Invalid Engagement Name Format "${newValue}". NO SPECIAL CHARACTER ALLOWED.`);
        } else {
          setEngagementNameError('');
        }
        break;
      }
      case 'domain': {
        setDomain(fetchedDomain.filter((el) => el.domain_name === event.target.value)[0]);
        setDomainNameError('');
        break;
      }
      case 'dataRegion': {
        setDataRegion(event.target.value);
        setDataRegionError('');
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    fetchAllDomain();
  }, []);

  const handleOpenModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      handleSubmit();
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  return (
    <Box>
      <Box>
        <Typography variant="h5" component="h5" className={classes.pageTitle}>
          {CLIENT_ONBOARD.clientOnboardTitle}
        </Typography>
        <Typography fontSize={11} className={classes.pageText}>
          {CLIENT_ONBOARD.clientOnboardText}
        </Typography>
      </Box>
      <Stack direction="row">
        <AddClientDetailEngagement text="1" clientIcon={clientIcon} engagementIcon={engagementIcon} />
        <Divider className={classes.divider} />
        <AddClientDetailEngagement text="2" clientIcon={clientIcon} engagementIcon={engagementIcon} />
      </Stack>
      <Stack direction="row">
        <Typography variant="p" component="p" fontSize={12}>
          {CLIENT_ONBOARD.clientDetails}
        </Typography>
        <Typography variant="p" component="p" fontSize={12} ml={10.8}>
          {CLIENT_ONBOARD.engagementDetail}
        </Typography>
      </Stack>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="p" component="p" className={classes.paperTitle}>
          {visible ? CLIENT_ONBOARD.clientDetails : CLIENT_ONBOARD.engagementDetail}
        </Typography>
        <Typography fontSize={11} className={classes.pageText}>
          {visible ? CLIENT_ONBOARD.clientDetailText : CLIENT_ONBOARD.engagementDetailText}
        </Typography>
        <Typography variant="p" component="p" className={classes.formInputField}>
          {visible ? CLIENT_ONBOARD.formInputClientName : CLIENT_ONBOARD.formInputEngagementName}
        </Typography>
        {visible ? (
          <TextField
            size="small"
            required
            id="clientName"
            name="clientName"
            autoComplete="clientName"
            value={clientName}
            className={classes.formTextField}
            error={!!clientNameError}
            helperText={clientNameError}
            onChange={(e) => handleChange(e, 'clientName')}
          />
        ) : (
          <TextField
            size="small"
            required
            id="engagementName"
            name="engagementName"
            autoComplete="engagementName"
            value={engagementName}
            className={classes.formTextField}
            error={!!engagementNameError}
            helperText={engagementNameError}
            onChange={(e) => handleChange(e, 'engagementName')}
          />
        )}

        {visible ? (
          <Typography variant="p" component="p" className={classes.formInputField}>
            {CLIENT_ONBOARD.formInputDomain}
          </Typography>
        ) : null}

        {visible ? (
          <FormControl className={classes.formControl} error>
            <Select
              labelId="domain"
              id="demo-simple-select-helper"
              value={domain.domain_name}
              error={!!domainNameError}
              helperText={domainNameError}
              onChange={(e) => handleChange(e, 'domain')}
              fullWidth
              className={classes.formSelect}
              required
            >
              {fetchedDomainNames.map((el) => (
                <MenuItem value={el}>{el}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{domainNameError}</FormHelperText>
          </FormControl>
        ) : null}

        {visible ? (
          <Typography variant="p" component="p" className={classes.formInputField}>
            {CLIENT_ONBOARD.formInputDataRegion}
          </Typography>
        ) : null}

        {visible ? (
          <FormControl className={classes.formControl} error>
            <Select
              labelId="accessRights"
              id="demo-simple-select-helper"
              value={dataRegion}
              error={!!dataRegionError}
              helperText={dataRegionError}
              onChange={(e) => handleChange(e, 'dataRegion')}
              fullWidth
              className={classes.formSelect}
              required
            >
              <MenuItem value={'us-east-1'}>us-east-1</MenuItem>
            </Select>
            <FormHelperText>{dataRegionError}</FormHelperText>
          </FormControl>
        ) : null}
      </Paper>
      <Grid container direction="row" display="flex" mt={1}>
        <Grid item xs={6} direction="row" justifyContent="flex-start">
          {visible ? null : (
            <Button size="small" variant="contained" className={classes.previousAndCancelBtn} onClick={handleVisible}>
              {CLIENT_ONBOARD.previousButton}
            </Button>
          )}
          <Button
            size="small"
            type="submit"
            variant="contained"
            className={classes.previousAndCancelBtn}
            onClick={() => navigate('/client')}
          >
            {CLIENT_ONBOARD.cancelButton}
          </Button>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          {visible ? (
            <Button
              size="small"
              type="submit"
              variant="contained"
              className={classes.saveAndNextBtn}
              onClick={handleVisible}
            >
              {CLIENT_ONBOARD.saveAndNextButton}
            </Button>
          ) : (
            <Button
              size="small"
              type="submit"
              variant="contained"
              className={classes.saveAndNextBtn}
              onClick={handleOpenModal}
            >
              {CLIENT_ONBOARD.submitButton}
            </Button>
            // <SaveAndSubmitBtn text={CLIENT_ONBOARD.submitButton} onCLick={handleSubmit} />
          )}
          {submit ? <ClientSubmittedModal /> : null}
        </Grid>
      </Grid>
      <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
      {loading && <LoadingIcon />}
    </Box>
  );
};

export default ClientOnboard;
