import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MdAdd } from 'react-icons/md';
import { AuthContext } from '../contexts/AuthProvider';
import ClientNoData from '../components/Client/ClientNoData';
import { CLIENT_PAGE, DOMAIN_PAGE } from '../utils/commonConstants';
import { getAllClientsApi, getClientByUserGroup } from "../api's/ClientApi";
import ClientTable from '../components/dashboard-component/ClientTable';
import PageHeader from '../reusable-component/PageHeader';
import SearchAndFilter from '../reusable-component/SearchAndFilter';
import LoadingIcon from '../reusable-component/LoadingIcon';
import LLMTable from '../components/dashboard-component/LLMTable';
import { getAllDomainsApi, getDomainByUserGroup } from "../api's/DomainApi";
import AccessModal from '../reusable-component/AccessModal';
import AddEngagementModal from '../reusable-component/AddEngagementModal';
import AddDomainModal from '../reusable-component/AddDomainModal';

const LLMPage = () => {
  const [region, setRegion] = useState('');
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  //   const [tableData, setTableData] = useState([]);
  //   const [filteredTableData, setFilteredTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userRole, userGroup } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [page, setPage] = useState(1);

  const tableData = [
    {
      LLM_id: 'llm-1',
      LLM_name: 'OpenAI ChatGPT',
      LLM_title_name: 'ChatGPT',
      created_on: 'Mon Nov 06 2023',
    },
    {
      LLM_id: 'llm-2',
      LLM_name: 'AWS Bedrock',
      LLM_title_name: 'Bedrock',
      created_on: 'Mon Nov 06 2023',
    },
  ];
  const filteredTableData = [
    {
      LLM_id: 'llm-1',
      LLM_name: 'OpenAI ChatGPT',
      LLM_title_name: 'ChatGPT',
      created_on: 'Mon Nov 06 2023',
    },
    {
      LLM_id: 'llm-2',
      LLM_name: 'AWS Bedrock',
      LLM_title_name: 'Bedrock',
      created_on: 'Mon Nov 06 2023',
    },
  ];

  const handleSearch = () => {
    console.log(search);
    // setFilteredTableData(tableData.filter((tableData) => tableData.domain_name.startsWith(search)));
  };

  const handleRegion = (event) => {
    setRegion(event.target.value);
  };

  const handleStatus = (event) => {
    sessionStorage.setItem('filtered_domain_status', event.target.value);
    setStatus(event.target.value);
  };

  const handleOnChange = (event) => {
    sessionStorage.setItem('filtered_domain_search', event.target.value);
    setSearch(event.target.value);
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem('filtered_domain_status');
    sessionStorage.removeItem('filtered_domain_search');
    setRegion('');
    setStatus('');
    setSearch('');
    // setFilteredTableData(tableData);
    setPage(1);
  };

  //   const fetchFilterTableData = (responseTableData) => {
  //     const filtered_status = sessionStorage.getItem('filtered_domain_status') || '';
  //     const filtered_search = sessionStorage.getItem('filtered_domain_search') || '';
  //     const filtered_search_lowercase = filtered_search.toLowerCase() || '';
  //     if (filtered_status !== '' || filtered_search !== '') {
  //       const result_exist =
  //         filtered_status && filtered_search
  //           ? responseTableData.filter(
  //               (data) =>
  //                 data.status === filtered_status && data.domain_name.toLowerCase().startsWith(filtered_search_lowercase)
  //             )
  //           : filtered_status
  //           ? responseTableData.filter((data) => data.status === filtered_status)
  //           : filtered_search
  //           ? responseTableData.filter((data) => data.domain_name.toLowerCase().startsWith(filtered_search_lowercase))
  //           : null;
  //       // result_exist.sort((a, b) => {
  //       //   return a.domain_name.localeCompare(b.domain_name);
  //       // });
  //       setFilteredTableData(result_exist);
  //     } else {
  //       setFilteredTableData(responseTableData);
  //     }
  //     setPage(1);
  //   };

  //   const fetchTableData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = userRole === 'SUPER_USER' ? await getAllDomainsApi() : await getDomainByUserGroup(userGroup);
  //       // const response = await getAllDomainsApi();

  //       if (response.status === 200) {
  //         // response.data.sort((a, b) => {
  //         //   return a.domain_name.localeCompare(b.domain_name);
  //         // });
  //         setTableData(response.data);
  //         fetchFilterTableData(response.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setTableData([]);
  //       setFilteredTableData([]);
  //       if (error.response?.status === 500) {
  //         enqueueSnackbar('500 Internal Server Error!', {
  //           variant: 'error',
  //           autoHideDuration: 3000,
  //           anchorOrigin: { vertical: 'top', horizontal: 'right' },
  //         });
  //       } else if (error.response?.status === 404) {
  //         enqueueSnackbar('404 Domain not found!', {
  //           variant: 'error',
  //           autoHideDuration: 3000,
  //           anchorOrigin: { vertical: 'top', horizontal: 'right' },
  //         });
  //       } else {
  //         enqueueSnackbar('Something went wrong!', {
  //           variant: 'error',
  //           autoHideDuration: 3000,
  //           anchorOrigin: { vertical: 'top', horizontal: 'right' },
  //         });
  //       }
  //       setLoading(false);
  //     }
  //     setLoading(false);
  //   };

  //   useEffect(() => {
  //     fetchTableData();
  //   }, []);

  //   useEffect(() => {
  //     const filtered_status = sessionStorage.getItem('filtered_domain_status') || '';
  //     const filtered_search = sessionStorage.getItem('filtered_domain_search') || '';
  //     setStatus(filtered_status);
  //     setSearch(filtered_search);
  //     if (filtered_status !== '' || filtered_search !== '') {
  //       fetchFilterTableData(tableData);
  //     } else {
  //       setFilteredTableData(tableData);
  //     }
  //   }, [search, status]);

  // useEffect(() => {
  //   region
  //     ? setFilteredTableData(
  //         tableData.filter((tableData) => tableData.status === status && tableData.region === region)
  //       )
  //     : status
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.status === status))
  //     : setFilteredTableData(tableData);
  // }, [status]);

  // useEffect(() => {
  //   status
  //     ? setFilteredTableData(
  //         tableData.filter((tableData) => tableData.region === region && tableData.status === status)
  //       )
  //     : region
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.region === region))
  //     : setFilteredTableData(tableData);
  // }, [region]);

  // useEffect(() => {
  //   setFilteredTableData(
  //     tableData.filter((tableData) => tableData.domain_name.toLowerCase().startsWith(search.toLowerCase()))
  //   );
  // }, [search]);

  const data = {
    search,
    handleSearch,
    handleOnChange,
    region,
    handleRegion,
    handleClearFilter,
    handleStatus,
    status,
    firstFilterText: DOMAIN_PAGE.domainFilterByRegion,
    secondFilterText: DOMAIN_PAGE.domainFilterByStatus,
    clearFilterText: DOMAIN_PAGE.domainClearFilterButton,
    firstFilterValues: {
      first: 'us-east-1',
      second: 'us-east-2',
    },
    secondFilterValues: {
      filterData: [...new Set(tableData.map((item) => item.status))],
    },
    isFirstFilterVisible: false,
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    if (userRole === 'READER' || userRole === 'EXECUTOR') {
      handleAccessModal();
    } else {
      setOpenModal(true);
    }
  };

  return (
    <Box>
      <Grid container direction="row" display="flex">
        <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
          All LLM
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          {/* <Button
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
            onClick={handleOpenModal}
          >
            <MdAdd />
            {DOMAIN_PAGE.addNewDomainButton}
          </Button> */}
        </Grid>
      </Grid>
      {filteredTableData.length !== 0 ? (
        <LLMTable tableData={filteredTableData} currentPage={page} setPage={setPage} />
      ) : (
        !loading && (
          <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
            <ClientNoData
              title={DOMAIN_PAGE.domainPageNoDataToDisplay}
              text={DOMAIN_PAGE.noDomainToReview}
              addBtnText={DOMAIN_PAGE.addNewDomainButton}
              handleOnClick={handleOpenModal}
              isAddBtn
            />
          </Paper>
        )
      )}

      <AddDomainModal handleCloseModal={handleCloseModal} openModal={openModal} />
      <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
      {loading && <LoadingIcon />}
    </Box>
  );
};

export default LLMPage;
