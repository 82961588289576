import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import ReactFlowPannel from '../components/react-flow/ReactFlowPannel';
import WorkflowNavigateSection from './WorkflowNavigateSection';
import Preprocessor from '../components/preprocessor/Preprocessor';
import { WorkflowContext } from '../contexts/WorkflowProvider';
import Realtime from '../components/realtime/Realtime';
import RuleTableSectionNew from '../components/ruleEngine/RuleTableSectionNew';
import RunModal from '../components/ruleEngine/RunModal';
import { getDataProcessor, runDataProcessorApi } from "../api's/DataProcessorApi";
import convertToRequiredFormat from '../components/react-flow/JsonConverter';
import { getWorkflowById } from "../api's/WorkflowApi";
import { fetchExecutionListApi, updateExecution } from "../api's/ExecutionApi";
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { AuthContext } from '../contexts/AuthProvider';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function WorkflowTabs({ processType }) {
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);
  const { userId } = useContext(AuthContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isTabDisabled, setTabIsDisabled] = useState({
    preprocessor: false,
    ruleEngine: false,
    dataStreaming: true,
  });

  const [openRuleEngineModal, setOpenRuleEngineModal] = React.useState(false);

  const handleClickOpenRuleEngineModal = () => {
    setOpenRuleEngineModal(true);
  };

  const handleCloseRuleEngineModal = () => {
    setOpenRuleEngineModal(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (e, index) => {
    setValue(index);
  };

  const dataProcessorFnRef = useRef(null);

  const callDataProcessorFn = () => {
    if (dataProcessorFnRef?.current) {
      const getNodeData = JSON.parse(sessionStorage.getItem('saved_node')) || [];

      const getElementCount = parseInt(sessionStorage.getItem('elementCount') || 0, 10);

      const updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

      if (!getNodeData) {
        enqueueSnackbar('Please enter and submit some data for the dragged steps!!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        // return;
      } else if (getNodeData.length !== getElementCount) {
        enqueueSnackbar('All nodes are not submitted. Please submit all nodes! ', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        // return;
      } else if (updateNodes.length !== 0) {
        enqueueSnackbar('Schema has been updated please validate the schema!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        // saveFormData(e);
        dataProcessorFnRef.current();
        navigate(`/workflow-${processType}`);
      }
    }
  };

  const preprocessorFnRef = useRef(null);

  const callCreatePreprocessorFn = () => {
    preprocessorRef.current.preprocessorFnRef();
  };

  const runPreprocessorFnRef = useRef(null);

  const callRunPreprocessorFn = () => {
    setLoading(true);
    preprocessorRef.current.runPreprocessorFnRef();
    setLoading(false);
  };

  const preprocessorRef = useRef({ preprocessorFnRef, runPreprocessorFnRef });

  const ruleEngineFnRef = useRef(null);

  const callCreateRuleEngineFn = () => {
    ruleEngineFnRef.current();
  };

  const pad2 = (n) => {
    return n < 10 ? `0${n}` : n;
  };

  const runDataProcessorWorkflow = async () => {
    setLoading(true);
    const result = await getDataProcessor(workflow.workflow_id, workflow.execution_mode);
    const fetchedWorkflow = await getWorkflowById(workflow.workflow_id);
    const executionList = await fetchExecutionListApi(workflow.workflow_id);
    const workflowData = fetchedWorkflow.data;

    const dataProcessorJson = convertToRequiredFormat(result.data.form_data, '', '');

    const matchAndMerge = dataProcessorJson.steps.some((item) => item.method_name === 'MatchAndMerge')
      ? dataProcessorJson.steps.filter((item) => item.method_name === 'MatchAndMerge')
      : dataProcessorJson;

    const updatedDataProcessorJson = {
      name: '',
      transformer_execution_details: {},
      spark_config: {},
      udf: {},
      steps: matchAndMerge,
    };

    if (result.status === 200 && updatedDataProcessorJson) {
      let trackingId = '';
      const date = new Date();
      trackingId = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(
        date.getDate()
      )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(date.getMilliseconds())}`;

      const batch_table_id = `${workflowData.engagement.client.client_name}_${workflowData.engagement.engagement_name}_${workflowData.workflow_name}_dataProcessor_${trackingId}`;

      const meta = executionList.data.find((ele) => ele.workflow_type === 'Data Processor');
      meta.workflow_type = 'Data Processor';
      meta.execution_engine = workflowData.connectionName;
      meta.executed_by_id = userId;
      meta.execution_id = batch_table_id.replaceAll(' ', '');

      const data = {
        client_id: workflowData.engagement.client.client_id,
        engagement_id: workflowData.engagement.engagement_id,
        engagement_name: workflowData.engagement.engagement_name,
        workflow_id: workflowData.workflow_id,
        workflow_name: workflowData.workflow_name,
        dataProcessorJson: dataProcessorJson.steps.some((item) => item.method_name === 'MatchAndMerge')
          ? updatedDataProcessorJson
          : dataProcessorJson,
        ui_time: trackingId,
        execution_id: batch_table_id.replaceAll(' ', ''),
        cloudEnvironment: workflowData.cloudEnvironment,
      };

      try {
        await updateExecution(meta, workflowData.workflow_id);
        await runDataProcessorApi(data);
        enqueueSnackbar('Workflow Running!', {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } catch (error) {
        if (error.response.status === 403) {
          enqueueSnackbar('You have only Read Permission !!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar(error.response.data || 'Internal Server Error', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (workflow.process_type === 'Data Quality') {
      setValue(1);
    } else if (
      workflow.process_type === 'Modelling & Analytics' ||
      workflow.process_type === 'XPerience360' ||
      workflow.process_type === 'Customer Segmentation' ||
      workflow.process_type === 'Data Copy'
    ) {
      setValue(2);
    } else {
      setValue(0);
    }
  }, [workflow]);

  useEffect(() => {
    switch (workflow.process_type) {
      case 'Data Ingestion':
        setTabIsDisabled({
          ...isTabDisabled,
          preprocessor: false,
          ruleEngine: true,
        });
        break;
      case 'Data Quality':
        setTabIsDisabled({
          ...isTabDisabled,
          preprocessor: true,
          ruleEngine: false,
        });
        break;

      case 'Modelling & Analytics':
      case 'XPerience360':
        setTabIsDisabled({
          ...isTabDisabled,
          preprocessor: true,
          ruleEngine: true,
        });
        break;

      default:
        setTabIsDisabled({
          ...isTabDisabled,
          preprocessor: false,
          ruleEngine: false,
        });
    }
  }, [workflow.process_type, isTabDisabled.ruleEngine, isTabDisabled.preprocessor]);

  return (
    <>
      {processType !== 'modelling-analytics' &&
        processType !== 'xperience360' &&
        processType !== 'customer-segmentation' && (
          <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="white"
                textColor="white"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{
                  color: '#000',
                  '& button': { backgroundColor: '#eee' },
                  '& button:focus': { color: 'white', backgroundImage: 'linear-gradient(to right, #4880EC, #019CAD)' },
                  '& button.Mui-selected': {
                    color: 'white',
                    backgroundImage: 'linear-gradient(to right, #4880EC, #019CAD)',
                  },
                }}
              >
                {workflow.execution_mode === 'Batch' ? (
                  processType === 'data-quality' || processType === 'data-copy' ? (
                    <></>
                  ) : (
                    <Tab
                      label="Preprocessor"
                      {...a11yProps(0)}
                      disabled={isTabDisabled.preprocessor}
                      sx={{
                        '&.Mui-disabled': {
                          color: '#a4a4a4',
                        },
                      }}
                    />
                  )
                ) : (
                  <Tab
                    label="Data Streaming"
                    {...a11yProps(0)}
                    disabled={isTabDisabled.dataStreaming}
                    sx={{
                      '&.Mui-disabled': {
                        color: '#a4a4a4',
                      },
                    }}
                  />
                )}
                {processType === 'Ingestion' || processType === 'data-copy' ? (
                  <></>
                ) : (
                  <Tab
                    label="Rule Engine"
                    {...a11yProps(1)}
                    disabled={isTabDisabled.ruleEngine}
                    sx={{
                      '&.Mui-disabled': {
                        color: '#a4a4a4',
                      },
                    }}
                  />
                )}

                <Tab label="Data Processor" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <>
              {!isTabDisabled.preprocessor && workflow.execution_mode === 'Batch' ? (
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <Preprocessor ref={preprocessorRef} />
                  </Box>

                  <WorkflowNavigateSection
                    handleChangeIndex={handleChangeIndex}
                    value={value}
                    tabType="preprocessor"
                    callCreatePreprocessorFn={callCreatePreprocessorFn}
                    callRunPreprocessorFn={callRunPreprocessorFn}
                    processType={processType}
                  />
                </TabPanel>
              ) : (
                isTabDisabled.dataStreaming && (
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <Box sx={{ width: '100%', height: '100%' }}>
                      <Realtime />
                    </Box>
                    <WorkflowNavigateSection
                      handleChangeIndex={handleChangeIndex}
                      value={value}
                      tabType="realtime"
                      // callCreatePreprocessorFn={callCreatePreprocessorFn}
                      processType={processType}
                    />
                  </TabPanel>
                )
              )}
              {!isTabDisabled.ruleEngine && (
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <RuleTableSectionNew ref={ruleEngineFnRef} />
                  <WorkflowNavigateSection
                    handleChangeIndex={handleChangeIndex}
                    value={value}
                    tabType="ruleengine"
                    callCreateRuleEngineFn={callCreateRuleEngineFn}
                    handleClickOpenRuleEngineModal={handleClickOpenRuleEngineModal}
                    processType={processType}
                  />
                </TabPanel>
              )}
              <TabPanel value={value} index={2} dir={theme.direction}>
                <ReactFlowPannel ref={dataProcessorFnRef} setLoading={setLoading} />
                <WorkflowNavigateSection
                  handleChangeIndex={handleChangeIndex}
                  value={value}
                  callDataProcessorFn={callDataProcessorFn}
                  loading={loading}
                  runDataProcessorWorkflow={runDataProcessorWorkflow}
                  processType={processType}
                />
              </TabPanel>
            </>

            <RunModal
              workflowId={workflow.workflow_id}
              openRuleEngineModal={openRuleEngineModal}
              handleCloseRuleEngineModal={handleCloseRuleEngineModal}
            />
          </Box>
        )}
      {processType === 'modelling-analytics' && (
        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
          <AppBar position="static">
            <Tabs
              value={value}
              // onChange={handleChange}
              indicatorColor="white"
              textColor="white"
              variant="fullWidth"
              aria-label="full width tabs example"
              sx={{
                color: '#fff',
                '& button': { backgroundColor: 'linear-gradient(to right, #4880EC, #019CAD)' },
                '& button:focus': { color: 'white', backgroundImage: 'linear-gradient(to right, #4880EC, #019CAD)' },
                '& button.Mui-selected': {
                  color: 'white',
                  backgroundImage: 'linear-gradient(to right, #4880EC, #019CAD)',
                },
              }}
            >
              {workflow.workflow_name === 'Model_Train' ? (
                <Tab label="Model Training" {...a11yProps(2)} />
              ) : workflow.workflow_name === 'Model_Predict' ? (
                <Tab label="Model Predection" {...a11yProps(2)} />
              ) : (
                <Tab label="Feature Engineering" {...a11yProps(2)} />
              )}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ReactFlowPannel ref={dataProcessorFnRef} setLoading={setLoading} />
            <WorkflowNavigateSection
              handleChangeIndex={handleChangeIndex}
              value={value}
              callDataProcessorFn={callDataProcessorFn}
              loading={loading}
              runDataProcessorWorkflow={runDataProcessorWorkflow}
              processType={processType}
            />
          </TabPanel>
          <RunModal
            workflowId={workflow.workflow_id}
            openRuleEngineModal={openRuleEngineModal}
            handleCloseRuleEngineModal={handleCloseRuleEngineModal}
          />
        </Box>
      )}
      {processType === 'xperience360' && (
        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
          <AppBar position="static">
            <Tabs
              value={value}
              // onChange={handleChange}
              indicatorColor="white"
              textColor="white"
              variant="fullWidth"
              aria-label="full width tabs example"
              sx={{
                color: '#fff',
                '& button': { backgroundColor: 'linear-gradient(to right, #4880EC, #019CAD)' },
                '& button:focus': { color: 'white', backgroundImage: 'linear-gradient(to right, #4880EC, #019CAD)' },
                '& button.Mui-selected': {
                  color: 'white',
                  backgroundImage: 'linear-gradient(to right, #4880EC, #019CAD)',
                },
              }}
            >
              <Tab label="XPerience360" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ReactFlowPannel ref={dataProcessorFnRef} setLoading={setLoading} />
            <WorkflowNavigateSection
              handleChangeIndex={handleChangeIndex}
              value={value}
              callDataProcessorFn={callDataProcessorFn}
              loading={loading}
              runDataProcessorWorkflow={runDataProcessorWorkflow}
              processType={processType}
            />
          </TabPanel>
          <RunModal
            workflowId={workflow.workflow_id}
            openRuleEngineModal={openRuleEngineModal}
            handleCloseRuleEngineModal={handleCloseRuleEngineModal}
          />
        </Box>
      )}

      {processType === 'customer-segmentation' && (
        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
          <AppBar position="static">
            <Tabs
              value={value}
              // onChange={handleChange}
              indicatorColor="white"
              textColor="white"
              variant="fullWidth"
              aria-label="full width tabs example"
              sx={{
                color: '#fff',
                '& button': { backgroundColor: 'linear-gradient(to right, #4880EC, #019CAD)' },
                '& button:focus': { color: 'white', backgroundImage: 'linear-gradient(to right, #4880EC, #019CAD)' },
                '& button.Mui-selected': {
                  color: 'white',
                  backgroundImage: 'linear-gradient(to right, #4880EC, #019CAD)',
                },
              }}
            >
              <Tab label="Customer Segmentation" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ReactFlowPannel ref={dataProcessorFnRef} setLoading={setLoading} />
            <WorkflowNavigateSection
              handleChangeIndex={handleChangeIndex}
              value={value}
              callDataProcessorFn={callDataProcessorFn}
              loading={loading}
              runDataProcessorWorkflow={runDataProcessorWorkflow}
              processType={processType}
            />
          </TabPanel>
          <RunModal
            workflowId={workflow.workflow_id}
            openRuleEngineModal={openRuleEngineModal}
            handleCloseRuleEngineModal={handleCloseRuleEngineModal}
          />
        </Box>
      )}
    </>
  );
}
