import React, { useState, useEffect, memo, useCallback, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Typography,
  Toolbar,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  ButtonGroup,
  TextField,
  MenuItem,
  Grid,
  TableContainer,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FormControlLabel,
  Autocomplete,
  Modal,
  Tooltip,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import InputField from '../../../../reusable-component/InputField';
import LoadingIcon from '../../../../reusable-component/LoadingIcon';

import {
  fetchSnowflakeColumns,
  fetchSnowFlakeDatabase,
  fetchSnowFlakeSchema,
  fetchSnowflakeTables,
} from "../../../../api's/SnowflakeApi";
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const INITIALSTATE = {
  path: '',
  format: '',
  persist: false,
  alias: '',
  snowflake_mode: '',
  snowflake_db_table: '',
  persist_type: '',
  partition: false,
  overwrite: false,
  df: '',
  p_key: '',
  index: '',
  database: '',
  tablename: '',
  db_type: '',
  mode: '',
  schema: '',
  distinct_rows: false,
  sample_view: true,
};

const NodeModal_SnowFlake = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { workflow } = useContext(WorkflowContext);

  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [tableName, setTableName] = useState([]);
  const [toggleType, setToggleType] = useState('SnowFlake');
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [OracleDatabases, setOracleDatabases] = useState([]);
  const [OracleSchema, setOracleSchema] = useState([]);
  const [OracleTables, setOracleTables] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { header: obj.header, alias: obj.alias, checked: true };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, header: obj.header, alias: obj.alias };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, header: obj.header, alias: obj.alias };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, checked: false, alias: '' };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          alias: '',
          checked: true,
          type: n.type ? n.type : 'string',
          deleted: n.deleted ? n.deleted : false,
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });
    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);
      setFetchedHeader(newHeader);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setToggleType(node.toggleType);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    setToggleType('SnowFlake');
    getLocalData();
  }, [nodeId]);

  const sendFormData = {
    nodeId,
    nodeName,
    formField,
    disabled: false,
    step_name: nodeName,
  };

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setHeaderName(node.headerName);
            setToggleType(node.toggleType);

            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = newHeader.findIndex((x) => x.header === item.header);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            newHeader.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.header === el.header);

              if (index === -1) {
                nonExistingHeader.push({
                  ...el,
                  header: el.alias ? el.alias : el.header,
                  alias: el.alias ? '' : el.alias,
                });
              }
            });

            setFetchedHeader(nonExistingHeader);
          }
        });
      } else {
        setFetchedHeader(newHeader);
      }
    }

    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  useEffect(() => {
    if (store) {
      setTableName(
        store.map((node, i) => {
          if (node.nodeName !== 'Write') {
            return node.formField.alias;
          }
        })
      );
    }
  }, []);

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const regex = /^s3:\/\//i;

    if (toggleType === 'S3' && !regex.test(formField.path)) {
      enqueueSnackbar('S3 path is invalid!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    // let y_axis = 0;
    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    fetchedHeader.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField: {
        ...formField,
        index:
          formField.index.toLowerCase() ||
          `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`.toLowerCase(),
      },
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      toggleType,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleChangeType = (event) => {
    setToggleType(event.target.value);
  };

  const getSnowFlakeDatabase = async () => {
    try {
      const response = await fetchSnowFlakeDatabase();
      if (response.status === 200) {
        const db = [];
        response.data.forEach((el) => {
          db.push(el.name);
        });
        setOracleDatabases(db);
      }
    } catch (error) {
      enqueueSnackbar('Databases not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getSnowFlakeSchema = async (database) => {
    try {
      const response = await fetchSnowFlakeSchema(database);
      if (response.status === 200) {
        const schema = [];
        response.data.forEach((el) => {
          schema.push(el.name);
        });
        setOracleSchema(schema);
      }
    } catch (error) {
      enqueueSnackbar('Tables not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getSnowflakeTables = async (dbname, schema) => {
    try {
      const response = await fetchSnowflakeTables(dbname, schema);
      if (response.status === 200) {
        const tables = [];
        response.data.forEach((el) => {
          tables.push(el.name);
        });
        setOracleTables(tables);
      }
    } catch (error) {
      enqueueSnackbar('Tables not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getSnowflakeTableColumns = async () => {
    const response = await fetchSnowflakeColumns(formField.database, formField.schema, formField.tablename);
    if (response.status === 200) {
      const cols = [];
      response.data.forEach((ele) => {
        cols.push({
          header: ele,
          alias: '',
          checked: true,
          type: 'string',
          deleted: false,
        });
      });
      console.log(cols);
      setFetchedHeader(cols);
    }
  };
  useEffect(() => {
    if (formField.db_type === 'snowflake') {
      getSnowFlakeDatabase();
    }

    if (formField.db_type === 'snowflake' && formField.database) {
      getSnowFlakeSchema(formField.database);
    }

    if (formField.db_type === 'snowflake' && formField.database && formField.schema) {
      getSnowflakeTables(formField.database, formField.schema);
    }
    if (formField.db_type === 'snowflake' && formField.database && formField.schema && formField.tablename) {
      getSnowflakeTableColumns();
    }
  }, [formField]);

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '70%', marginLeft: '30%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar color="inherit" sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  color="inherit"
                  onClick={refreshAllState}
                  aria-label="close"
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>


            {toggleType === 'SnowFlake' && (
              <>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Database Type"
                      size="small"
                      value={formField.db_type}
                      onChange={(event) => {
                        setFormField({
                          ...formField,
                          db_type: event.target.value,
                        });
                      }}
                      disabled={disableForm}
                      style={{ width: '100%', marginTop: '15px' }}
                    >
                      {/* <MenuItem value="Oracle">Oracle</MenuItem> */}
                      <MenuItem value="snowflake">SnowFlake</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      name="df"
                      label="DF"
                      value={formField.df}
                      onChange={handleInputChange}
                      size="small"
                      sx={{ mt: 2 }}
                      disabled={disableForm}
                      required
                      fullWidth
                      InputProps={{
                        style: {
                          fontFamily: "'EB Garamond', serif ",
                          fontWeight: 600,
                        },
                      }}
                      InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                    >
                      {tableName.map((node, i) => {
                        if (node !== undefined) {
                          return (
                            <MenuItem value={node} key={i}>
                              {node}
                            </MenuItem>
                          );
                        }
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    {formField.db_type && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        name="database"
                        value={formField.database}
                        disabled={disableForm}
                        onChange={(event, newValue) => {
                          setFormField({
                            ...formField,
                            database: newValue,
                          });
                        }}
                        options={OracleDatabases}
                        style={{ width: '100%', marginTop: '15px' }}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Databases" />}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {formField.database && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        name="schema"
                        value={formField.schema}
                        disabled={disableForm}
                        onChange={(event, newValue) => {
                          setFormField({
                            ...formField,
                            schema: newValue,
                          });
                        }}
                        options={OracleSchema}
                        style={{ width: '100%', marginTop: '15px' }}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Schemas" />}
                      />
                    )}
                  </Grid>

                  {/* <Grid item xs={4}>
                    {formField.schema && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        name="tablename"
                        value={formField.tablename}
                        disabled={disableForm}
                        onChange={(event, newValue) => {
                          setFormField({
                            ...formField,
                            tablename: newValue,
                          });
                        }}
                        options={OracleTables}
                        style={{ width: '100%', marginTop: '15px' }}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Tables" />}
                      />
                    {formField.schema && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        name="tablename"
                        value={formField.tablename}
                        disabled={disableForm}
                        onChange={(event, newValue) => {
                          setFormField({
                            ...formField,
                            tablename: newValue,
                          });
                        }}
                        options={OracleTables}
                        style={{ width: '100%', marginTop: '15px' }}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Tables" />}
                      />
                    )}
                  </Grid> */}
                </Grid>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="snowflake_mode"
                      variant="outlined"
                      value={formField.snowflake_mode}
                      onChange={handleInputChange}
                      name="snowflake_mode"
                      size="small"
                      fullWidth
                      sx={{ mt: 2 }}
                      disabled={disableForm}
                      required
                      InputProps={{
                        style: {
                          fontWeight: 600,
                        },
                      }}
                    >
                      <MenuItem value="append">append</MenuItem>
                      <MenuItem value="overwrite">overwrite</MenuItem>
                      <MenuItem value="error">error</MenuItem>
                      <MenuItem value="ignore">ignore</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      name="snowflake_db_table"
                      label="db_table"
                      value={formField.snowflake_db_table}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                      required
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={formField.sample_view}
                          onChange={handleChecked}
                          disabled={disableForm}
                        />
                      }
                      label="Sample View"
                      sx={{ pt: 2 }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {toggleType === 'SnowFlake' && formField.tablename && (
              <>
                {fetchedHeader.length > 0 && (
                  <TableContainer sx={{ maxHeight: 300 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              color="primary"
                              disabled={disableForm}
                              checked={fetchedHeader.length === headerName.length}
                              onChange={handleSelectAllClick}
                            />
                            Select Columns
                          </TableCell>
                          <TableCell>Columns</TableCell>
                          <TableCell>Alias</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fetchedHeader.map((row, i) => {
                          const isItemSelected = isSelected(row.header);
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={i}
                              selected={isItemSelected}
                            >
                              <TableCell>
                                <Checkbox
                                  checked={isItemSelected}
                                  disabled={disableForm}
                                  onChange={(event) =>
                                    handleHeaderChange({
                                      header: row.header,
                                      alias: '',
                                      checked: event.target.checked,
                                      type: row.type ? row.type : 'string',
                                      deleted: row.deleted ? row.deleted : false,
                                    })
                                  }
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </TableCell>
                              <TableCell>{row.header}</TableCell>

                              <TableCell>
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  value={row.header}
                                  disabled={disableForm}
                                  onChange={(event, newValue) =>
                                    handleHeaderChange({
                                      header: row.header,
                                      alias: newValue.textContent,
                                      checked: true,
                                      type: row.type ? row.type : 'string',
                                      deleted: row.deleted ? row.deleted : false,
                                    })
                                  }
                                  options={headers}
                                  sx={{ width: 200 }}
                                  size="small"
                                  renderInput={(params) => <TextField {...params} label="Attributes" />}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            )}
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeModal_SnowFlake;
