import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Typography,
  Toolbar,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  ButtonGroup,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  FormLabel,
  TableContainer,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Modal,
  Tooltip,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LoadingIcon from '../../../../reusable-component/LoadingIcon';
import InputField from '../../../../reusable-component/InputField';
import { fetchPostgresDatabase, fetchPostgresTables, fetchPostgresSchema } from "../../../../api's/DataProcessorApi";
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';
import { AWS_CONFIG_TYPE } from '../../../../BaseUrl';
import generateAlias from '../../../../utils/generateAlias';

const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NodeModal_Embedding = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { enqueueSnackbar } = useSnackbar();
  // const { client } = useContext(ClientContext);
  // const { batch } = useContext(BatchContext);
  // const { Job } = useContext(JobContext);
  const { workflow } = useContext(WorkflowContext);
  const INITIALSTATE = {
    path: '',
    format: '',
    persist: false,
    alias: generateAlias(nodeName, nodeId),
    snowflake_mode: '',
    snowflake_db_table: '',
    persist_type: '',
    partition: false,
    overwrite: false,
    df: '',
    p_key: '',
    index: '',
    database: '',
    tablename: '',
    db_type: '',
    mode: '',
    schema: '',
    distinct_rows: false,
    sample_view: false,
    domainName: workflow?.engagement?.client?.depDomainEntity?.domain_name,
    clientName: workflow.engagement.client.client_name,
    batchName: workflow.engagement.engagement_name,
    workflow_name: workflow.workflow_name,
    subdomain: '',
    vectorstore: '',
  };
  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [tableName, setTableName] = useState([]);
  const [toggleType, setToggleType] = useState('Database');
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [OracleDatabases, setOracleDatabases] = useState([]);
  const [OracleSchema, setOracleSchema] = useState([]);
  const [OracleTables, setOracleTables] = useState([]);
  const [tempHeader, setTempHeader] = useState([]);

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const handleRefreshModalOpen = () => setRefreshModalOpen(true);
  const handleRefreshModalClose = () => setRefreshModalOpen(false);

  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { header: obj.header, alias: obj.alias, checked: true };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, header: obj.header, alias: obj.alias };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, header: obj.header, alias: obj.alias };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return { ...object, checked: false, alias: '' };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          alias: '',
          checked: true,
          type: n.type ? n.type : 'string',
          deleted: n.deleted ? n.deleted : false,
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });
    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));
  const getLocalData = () => {
    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;
      let isReadFromFolder = false;
      let ReadFromFolderPath;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            console.log(node.formField.alias);
            prev_nodeName = node.step_name;
            console.log(prev_nodeName);
            if (prev_nodeName === 'Read From Folder') {
              isReadFromFolder = true;
              ReadFromFolderPath = node.formField.path;
              console.log('inside condition');
            }
            console.log(node.formField.path);
          }
        });
      });

      setFormField({
        ...formField,
        df: prev_alias,
        path: isReadFromFolder ? ReadFromFolderPath : '',
      });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);
      setFetchedHeader(newHeader);

      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          setToggleType(node.toggleType);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  useEffect(() => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    setToggleType('Database');
    getLocalData();
  }, [nodeId]);

  const refreshAllState = () => {
    handleRefreshModalOpen();

    let updateNodes = JSON.parse(sessionStorage.getItem('updatedNodes')) || [];

    let count = 0;

    setNodes((nds) =>
      nds.map((node) => {
        if (count <= updateNodes.length && node.id === updateNodes[count]) {
          node.position.x -= 0.1;
          count++;
        }

        return node;
      })
    );

    updateNodes = updateNodes.filter((el) => el !== nodeId);

    sessionStorage.setItem('updatedNodes', JSON.stringify(updateNodes));

    const findSrcNodeId = [];
    edges.forEach((el) => {
      if (el.target === nodeId) {
        findSrcNodeId.push(el.source);
      }
    });

    if (findSrcNodeId.length === 0) {
      setFormField(INITIALSTATE);
      setDisableForm(false);
      setFetchedHeader([]);
      setHeaderName([]);

      enqueueSnackbar('No source node is connected !!!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefreshModalClose();
      return;
    }

    let nodesExist = false;

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        nodesExist = true;
      }
    });

    if (store && findSrcNodeId) {
      let header = [];
      let prev_alias;
      let prev_nodeName;

      findSrcNodeId.forEach((item, i) => {
        store.forEach((node) => {
          if (node.nodeId === item) {
            header.push(...node.headerName);
            prev_alias = node.formField.alias;
            prev_nodeName = node.step_name;
          }
        });
      });

      setFormField({ ...formField, df: prev_alias });

      const newArr = [];

      if (prev_nodeName === 'Join') {
        header.forEach((el) => {
          const exist = el.header.split('.').length;

          if (exist === 2) {
            const head = el.header.split('.')[1];
            newArr.push({ ...el, header: el.alias ? el.alias : head });
          }
        });
      }
      if (newArr.length > 0) {
        header = newArr;
      }

      const uniqueHeader = [];

      const uniqueArray = header.filter((element) => {
        const isDuplicate = uniqueHeader.includes(element.header);

        if (!isDuplicate) {
          uniqueHeader.push(element.header);
          return true;
        }

        return false;
      });

      const head = [];

      const newHeader = uniqueArray.map((el) => {
        return { ...el, header: el.alias ? el.alias : el.header, alias: el.alias ? '' : el.alias };
      });

      newHeader.forEach((el) => {
        head.push({ label: el.header });
      });

      setHeaders(head);

      if (nodesExist) {
        store.forEach((node) => {
          if (node.nodeId === nodeId) {
            setFormField(node.formField);
            setHeaderName(node.headerName);
            setToggleType(node.toggleType);

            const nonExistingHeader = [];
            node.fetchedHeader.forEach((item) => {
              const index = newHeader.findIndex((x) => x.header === item.header);

              if (index !== -1) {
                nonExistingHeader.push(item);
              }
            });

            newHeader.forEach((el) => {
              const index = nonExistingHeader.findIndex((x) => x.header === el.header);

              if (index === -1) {
                nonExistingHeader.push({
                  ...el,
                  header: el.alias ? el.alias : el.header,
                  alias: el.alias ? '' : el.alias,
                });
              }
            });

            setFetchedHeader(nonExistingHeader);
          }
        });
      } else {
        setFetchedHeader(newHeader);
      }
    }

    enqueueSnackbar('Data Refreshed!!!', {
      variant: 'Success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleRefreshModalClose();
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
  };

  useEffect(() => {
    if (store) {
      setTableName(
        store.map((node, i) => {
          if (node.nodeName !== 'Write') {
            return node.formField.alias;
          }
        })
      );
    }
  }, []);

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    const regex = /^s3:\/\//i;

    if (toggleType === 'S3' && !regex.test(formField.path)) {
      enqueueSnackbar('S3 path is invalid!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    // let y_axis = 0;
    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        // y_axis = `${parseInt(el.position.y, 10)}`;
        el.data.label = formField.alias;
      }
    });

    const newHeaderName = [];

    fetchedHeader.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    setHeaderName(newHeaderName);

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField: {
        ...formField,
        index:
          formField.index.toLowerCase() ||
          `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`.toLowerCase(),
        // domain: workflow?.engagement?.client?.depDomainEntity?.domain_name,
        // client: workflow?.engagement?.client?.client_name,
        // engagement: workflow?.engagement?.engagement_name
      },
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
      toggleType,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);
    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const handleChangeType = (event) => {
    setToggleType(event.target.value);
  };

  const getPostgresDatabase = async () => {
    try {
      const response = await fetchPostgresDatabase(AWS_CONFIG_TYPE);
      if (response.status === 200) {
        const db = [];
        response.data.forEach((el) => {
          db.push(el.label);
        });
        setOracleDatabases(db);
      }
    } catch (error) {
      enqueueSnackbar('Databases not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getPostgresSchema = async (database) => {
    try {
      const response = await fetchPostgresSchema(database, AWS_CONFIG_TYPE);
      if (response.status === 200) {
        const schema = [];
        response.data.forEach((el) => {
          schema.push(el.label);
        });
        setOracleSchema(schema);
      }
    } catch (error) {
      enqueueSnackbar('Tables not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getPostgresTables = async (dbname, schema) => {
    try {
      const response = await fetchPostgresTables(dbname, schema, AWS_CONFIG_TYPE);
      if (response.status === 200) {
        const tables = [];
        response.data.forEach((el) => {
          tables.push(el.label);
        });
        setOracleTables(tables);
      }
    } catch (error) {
      enqueueSnackbar('Tables not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (formField.db_type === 'postgres') {
      getPostgresDatabase();
    }

    if (formField.db_type === 'postgres' && formField.database) {
      getPostgresSchema(formField.database);
    }

    if (formField.db_type === 'postgres' && formField.database && formField.schema) {
      getPostgresTables(formField.database, formField.schema);
    }
  }, [formField]);

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  const descriptions = {
    'all-MiniLM-L6-v2': 'It is a lightweight transformer model designed for efficient sentence embeddings that maps sentences & paragraphs to a 384 dimensional dense vector space. It is part of the Sentence Transformers library, and licensed under the Apache 2.0 license.',
    'paraphrase-multilingual-MiniLM-L12-v2': ' This model is designed to produce sentence embeddings for paraphrase detection in over 50 languages, having been trained on parallel data. It belongs to the Sentence Transformers library and is licensed under the Apache 2.0 license.',
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: '70%', marginLeft: '30%' }}
      >
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <Tooltip title="Refresh Schema" placement="left">
                <IconButton
                  disabled={disableForm}
                  edge="start"
                  onClick={refreshAllState}
                  aria-label="close"
                  sx={{ color: '#00043C' }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button size="small" variant="outlined" className="outlined-button-color" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '20px' }}>
            {/* <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="target_name"
                    value={toggleType}
                    onChange={handleChangeType}
                  >
                    <FormControlLabel value="S3" control={<Radio />} label="S3" />
                    <FormControlLabel value="Open Search" control={<Radio />} label="Open Search" />
                    <FormControlLabel value="Database" control={<Radio />} label="Database" />
                    <FormControlLabel value="SnowFlake" control={<Radio />} label="SnowFlake" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid> */}
            {toggleType === 'Open Search' && (
              <Grid container spacing={2} sx={{ m: 1 }}>
                {/* <Grid item xs={4}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Distinct Rows"
                    size="small"
                    value={formField.distinct_rows}
                    onChange={(event) => {
                      setFormField({
                        ...formField,
                        distinct_rows: event.target.value,
                      });
                    }}
                    disabled={disableForm}
                    style={{ width: '100%', marginTop: '15px' }}
                  >
                    <MenuItem value="true">true</MenuItem>
                    <MenuItem value="false">false</MenuItem>
                  </TextField>
                </Grid> */}
                {/* <Grid item xs={4}>
                  <TextField
                    select
                    name="df"
                    label="DF"
                    value={formField.df}
                    onChange={handleInputChange}
                    size="small"
                    sx={{ mt: 2 }}
                    disabled={disableForm}
                    required
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "'EB Garamond', serif ",
                        fontWeight: 600,
                      },
                    }}
                    InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                  >
                    {tableName.map((node, i) => {
                      if (node !== undefined) {
                        return (
                          <MenuItem value={node} key={i}>
                            {node}
                          </MenuItem>
                        );
                      }
                    })}
                  </TextField>
                </Grid> */}
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="p_key"
                    value={formField.p_key}
                    disabled={disableForm}
                    onChange={(event, newValue) => {
                      setFormField({
                        ...formField,
                        p_key: newValue.label,
                      });
                    }}
                    options={headers}
                    style={{ width: '100%', marginTop: '15px' }}
                    size="small"
                    renderInput={(params) => <TextField {...params} label="Primary Key" />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    name="index"
                    label="Index"
                    value={
                      formField.index.toLowerCase() ||
                      `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`.toLowerCase()
                    }
                    onChange={handleInputChange}
                    size="small"
                    disabled={disableForm}
                    required
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            )}

            {toggleType === 'Database' && (
              <>
                <Grid Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Embedding Model"
                      size="small"
                      required
                      value={formField.db_type}
                      onChange={(event) => {
                        setFormField({
                          ...formField,
                          db_type: event.target.value,
                        });
                      }}
                      disabled={disableForm}
                      style={{ width: '100%', marginTop: '15px' }}
                    >
                      {/* <MenuItem value="Oracle">Oracle</MenuItem> */}
                      <MenuItem value="all-MiniLM-L6-v2">all-MiniLM-L6-v2</MenuItem>
                      <MenuItem value="paraphrase-multilingual-MiniLM-L12-v2">
                        paraphrase-multilingual-MiniLM-L12-v2
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" sx={{ marginTop: '15px' }}>
                      {descriptions[formField.db_type] || 'Select a model to see its description.'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      name="domain"
                      label="Domain"
                      size="small"
                      value={formField.domainName}
                      onChange={handleInputChange}
                      disabled
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="client"
                      label="Client"
                      size="small"
                      value={formField.clientName}
                      onChange={handleInputChange}
                      disabled
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="engagement"
                      label="Engagement"
                      size="small"
                      value={formField.batchName}
                      onChange={handleInputChange}
                      disabled
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="workflow"
                      label="Workflow"
                      size="small"
                      value={formField.workflow_name}
                      onChange={handleInputChange}
                      disabled
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="subdomain"
                      label="Subdomain"
                      size="small"
                      required
                      value={formField.subdomain}
                      onChange={(event) => {
                        const value = event.target.value;
                        const isValid = /^[a-zA-Z0-9_]*$/.test(value);
                        if (isValid || value === '') {
                          setFormField({
                            ...formField,
                            subdomain: value.trim(),
                          });
                        }
                      }}
                      disabled={disableForm}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      name="vectorstore"
                      label="Vector Store"
                      value="FAISS"
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      disabled
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={formField.sample_view}
                          onChange={handleChecked}
                          disabled={disableForm}
                        />
                      }
                      label="Sample View"
                      sx={{ p: 2 }}
                    />
                  </Grid> */}
                </Grid>
                {/* <Grid item xs={4}>
                  {formField.db_type && (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      name="database"
                      value={formField.database}
                      disabled={disableForm}
                      onChange={(event, newValue) => {
                        setFormField({
                          ...formField,
                          database: newValue,
                        });
                      }}
                      options={OracleDatabases}
                      style={{ width: '100%', marginTop: '15px' }}
                      size="small"
                      renderInput={(params) => <TextField {...params} label="Databases" />}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {formField.database && (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      name="schema"
                      value={formField.schema}
                      disabled={disableForm}
                      onChange={(event, newValue) => {
                        setFormField({
                          ...formField,
                          schema: newValue,
                        });
                      }}
                      options={OracleSchema}
                      style={{ width: '100%', marginTop: '15px' }}
                      size="small"
                      renderInput={(params) => <TextField {...params} label="Schemas" />}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {formField.schema && (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      name="tablename"
                      value={formField.tablename}
                      disabled={disableForm}
                      onChange={(event, newValue) => {
                        setFormField({
                          ...formField,
                          tablename: newValue,
                        });
                      }}
                      options={OracleTables}
                      style={{ width: '100%', marginTop: '15px' }}
                      size="small"
                      renderInput={(params) => <TextField {...params} label="Tables" />}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {formField.tablename && (
                    <InputField
                      name="mode"
                      label="Mode"
                      value={formField.mode}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                      required
                      style={{ width: '100%' }}
                    />
                  )}
                </Grid> */}
              </>
            )}

            {toggleType === 'SnowFlake' && (
              <>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="snowflake_mode"
                      variant="outlined"
                      value={formField.snowflake_mode}
                      onChange={handleInputChange}
                      name="snowflake_mode"
                      size="small"
                      fullWidth
                      sx={{ mt: 2 }}
                      disabled={disableForm}
                      required
                      InputProps={{
                        style: {
                          fontWeight: 600,
                        },
                      }}
                    >
                      <MenuItem value="append">append</MenuItem>
                      <MenuItem value="overwrite">overwrite</MenuItem>
                      <MenuItem value="error">error</MenuItem>
                      <MenuItem value="ignore">ignore</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      name="snowflake_db_table"
                      label="db_table"
                      value={formField.snowflake_db_table}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                      required
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <InputField
                      name="alias"
                      label="Alias"
                      value={formField.alias}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                      required
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid> */}
              </>
            )}
            {toggleType === 'S3' && (
              <>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Distinct Rows"
                      size="small"
                      value={formField.distinct_rows}
                      onChange={(event) => {
                        setFormField({
                          ...formField,
                          distinct_rows: event.target.value,
                        });
                      }}
                      disabled={disableForm}
                      style={{ width: '100%', marginTop: '15px' }}
                    >
                      <MenuItem value="true">true</MenuItem>
                      <MenuItem value="false">false</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      name="df"
                      label="DF"
                      value={formField.df}
                      onChange={handleInputChange}
                      size="small"
                      sx={{ mt: 2 }}
                      disabled={disableForm}
                      required
                      fullWidth
                      InputProps={{
                        style: {
                          fontFamily: "'EB Garamond', serif ",
                          fontWeight: 600,
                        },
                      }}
                      InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                    >
                      {tableName.map((node, i) => {
                        if (node !== undefined) {
                          return (
                            <MenuItem value={node} key={i}>
                              {node}
                            </MenuItem>
                          );
                        }
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Format"
                      variant="outlined"
                      value={formField.format}
                      onChange={handleInputChange}
                      name="format"
                      size="small"
                      fullWidth
                      sx={{ mt: 2 }}
                      disabled={disableForm}
                      required
                      InputProps={{
                        style: {
                          fontWeight: 600,
                        },
                      }}
                    >
                      <MenuItem value="txt">txt</MenuItem>
                      <MenuItem value="doc">doc</MenuItem>
                      <MenuItem value="png">png</MenuItem>
                      <MenuItem value="csv">csv</MenuItem>
                      <MenuItem value="gif">gif</MenuItem>
                      <MenuItem value="streaming">streaming</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Overwrite"
                      variant="outlined"
                      value={formField.overwrite}
                      name="overwrite"
                      onChange={handleInputChange}
                      sx={{ mt: 2 }}
                      fullWidth
                      size="small"
                      disabled={disableForm}
                      required
                    >
                      <MenuItem value>true</MenuItem>
                      <MenuItem value={false}>false</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <InputField
                      name="alias"
                      label="Alias"
                      value={formField.alias}
                      onChange={handleInputChange}
                      size="small"
                      disabled={disableForm}
                      required
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    {formField.format !== 'streaming' && (
                      <InputField
                        name="path"
                        label="File Path"
                        value={formField.path}
                        onChange={handleInputChange}
                        size="small"
                        disabled={disableForm}
                        required
                        style={{ width: '100%' }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Partition"
                      variant="outlined"
                      value={formField.partition}
                      name="partition"
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mt: 2 }}
                      size="small"
                      disabled={disableForm}
                      required
                    >
                      <MenuItem value>true</MenuItem>
                      <MenuItem value={false}>false</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Persist"
                      variant="outlined"
                      value={formField.persist}
                      name="persist"
                      onChange={handleInputChange}
                      sx={{ mt: 2 }}
                      size="small"
                      disabled={disableForm}
                      required
                      fullWidth
                      InputProps={{
                        style: {
                          fontFamily: "'EB Garamond', serif ",
                          fontWeight: 600,
                        },
                      }}
                      InputLabelProps={{ style: { fontFamily: "'EB Garamond', serif " } }}
                    >
                      <MenuItem value>true</MenuItem>
                      <MenuItem value={false}>false</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    {formField.persist === true && (
                      <TextField
                        id="outlined-basic"
                        select
                        label="Persist Type"
                        variant="outlined"
                        value={formField.persist_type}
                        name="persist_type"
                        onChange={handleInputChange}
                        sx={{ mt: 2 }}
                        size="small"
                        disabled={disableForm}
                        required
                        fullWidth
                        InputProps={{
                          style: {
                            fontFamily: "'EB Garamond', serif ",
                            fontWeight: 600,
                          },
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "'EB Garamond', serif " },
                        }}
                      >
                        <MenuItem value="DISK_ONLY">DISK_ONLY</MenuItem>
                        <MenuItem value="DISK_ONLY_2">DISK_ONLY_2</MenuItem>
                        <MenuItem value="MEMORY_ONLY">MEMORY_ONLY</MenuItem>
                        <MenuItem value="MEMORY_ONLY_2">MEMORY_ONLY_2</MenuItem>
                        <MenuItem value="MEMORY_AND_DISK">MEMORY_AND_DISK</MenuItem>
                        <MenuItem value="MEMORY_AND_DISK_2">MEMORY_AND_DISK_2</MenuItem>
                      </TextField>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {toggleType === 'S3' ||
              toggleType === 'Open Search' ||
              (toggleType === 'Database' && (
                <>
                  {fetchedHeader.length > 0 && (
                    <TableContainer sx={{ maxHeight: 300 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                color="primary"
                                disabled={disableForm}
                                checked={fetchedHeader.length === headerName.length}
                                onChange={handleSelectAllClick}
                              />
                              Select Columns
                            </TableCell>
                            <TableCell>Columns</TableCell>
                            <TableCell>Alias</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fetchedHeader.map((row, i) => {
                            const isItemSelected = isSelected(row.header);
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={i}
                                selected={isItemSelected}
                              >
                                <TableCell>
                                  <Checkbox
                                    checked={isItemSelected}
                                    disabled={disableForm}
                                    onChange={(event) =>
                                      handleHeaderChange({
                                        header: row.header,
                                        alias: '',
                                        checked: event.target.checked,
                                        type: row.type ? row.type : 'string',
                                        deleted: row.deleted ? row.deleted : false,
                                      })
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                </TableCell>
                                <TableCell>{row.header}</TableCell>

                                <TableCell>
                                  <TextField
                                    name="alias"
                                    label="Alias"
                                    value={row.alias}
                                    disabled={disableForm}
                                    onChange={(e) =>
                                      handleHeaderChange({
                                        header: row.header,
                                        alias: e.target.value,
                                        checked: true,
                                        type: row.type ? row.type : 'string',
                                        deleted: row.deleted ? row.deleted : false,
                                      })
                                    }
                                    size="small"
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </>
              ))}
          </div>
        </form>
      </Dialog>
      <Modal open={refreshModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleLoading}>
          <LoadingIcon />
        </Box>
      </Modal>
    </div>
  );
};

export default NodeModal_Embedding;
