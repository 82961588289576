import React, { useState, memo, useEffect, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Box,
  IconButton,
  MenuItem,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  TableContainer,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  Autocomplete,
  Switch,
  FormControlLabel,
  Modal,
  Paper,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Select, Radio } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import InputField from '../../../../reusable-component/InputField';
import LoadingIcon from '../../../../reusable-component/LoadingIcon';
import { getJsonData, getCsvData } from "../../../../api's/RuleEngineApi";
import {
  AllFilesOfS3,
  AllFoldersOfS3,
  fetchOracleDatabase,
  fetchOracleTables,
  fetchOracleColumn,
  getFolderHeaderList,
  getDataProcessor,
} from "../../../../api's/DataProcessorApi";
import { getEngagementByUserGroupAndClientIdApi } from "../../../../api's/EngagementApi";
import { getAllClientsApi, getClientByUserGroup } from "../../../../api's/ClientApi";
import { getWorkflowByUserGroupAndEngagementIdApi } from "../../../../api's/WorkflowApi";
import { BUCKET_NAME } from '../../../../constants/Constant';
import { WorkflowContext } from '../../../../contexts/WorkflowProvider';
import { AuthContext } from '../../../../contexts/AuthProvider';
import BASEURL, { AWS_CONFIG_TYPE } from '../../../../BaseUrl';
import { getFiles } from "../../../../api's/OpenApi";
import { getDirectoryData, getOpenaiData, updateOpenaiData } from "../../../../api's/ChatGPTApi";
import TreeViewDirectory from '../../../../reusable-component/TreeViewDirectory';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const RowLevelOperation = {
  ignoreblanklines: false,
  skipheaders: false,
  columnshift: false,
  junkrecords: false,
  linebreak: false,
  delimiter: ',',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const NodeModalCopyDoc = ({ open, handleClose, nodeId, nodeName, nodes, edges, changeNodeName, setNodes }) => {
  const { workflow: workflowInfo } = useContext(WorkflowContext);

  const INITIALSTATE = {
    path: '',
    format: 'csv',
    action: '',
    persist: false,
    alias: '',
    persist_type: '',
    database: '',
    delimiter: ',',
    tablename: '',
    ignoreblanklines: false,
    skipheaders: false,
    columnshift: false,
    junkrecords: false,
    linebreak: false,
    distinct_rows: false,
    clientId: '',
    clientName: workflowInfo.engagement.client.client_name,
    batchName: workflowInfo.engagement.engagement_name,
    tableNameRead: workflowInfo.workflow_name,
    dataSetName: 'English',
    trackingId: '',
    innerPath: '',
    file: '',
    dataTypeRead: 'source',
    pathTypeFile: 'filepath',
    pagination: false,
    lowerBound: '',
    upperBound: '',
    CDC: false,
    partitionColumn: '',
    numPartitions: '',
    sample_view: false,
    priority: '',
  };
  const { enqueueSnackbar } = useSnackbar();
  const [apiType, setApiType] = useState('azure');
  const [apiBase, setApiBase] = useState('https://depchatgpt.openai.azure.com/');
  const [apiVersion, setApiVersion] = useState('2022-12-01');
  const [apiKey, setApiKey] = useState('214e75806da6403589886c59f1a8a92c');
  const [loading, setLoading] = useState(false);
  const [selectedFilePath, setSelectedFilePath] = useState('');
  const { userRole, userGroup } = useContext(AuthContext);
  const [client, setClient] = useState();
  const [engagement, setEngagement] = useState();
  const [workflowData, setWorkflowData] = useState();
  const [workflow, setWorkflow] = useState();
  const [fullPath, setFullPath] = useState(null);
  const [locationPath, setlocationPath] = useState('TalkToDB');
  const [pdfFile, setPdfFile] = useState('');
  const [AllClient, setAllClient] = useState([]);
  const [AllBatch, setAllBatch] = useState([]);
  const [AllTable, setAllTable] = useState([]);
  const [WriteTable, setWriteTable] = useState([]);
  const [IngestionWriteTable, setIngestionWriteTable] = useState([]);
  const [path, setPath] = useState(null);
  const [formField, setFormField] = useState(INITIALSTATE);
  const [disableForm, setDisableForm] = useState(false);
  const [headerName, setHeaderName] = useState([]);
  const [fetchedHeader, setFetchedHeader] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [flowbuilderRowLevelOperation, setflowbuilderRowLevelOperation] = useState(RowLevelOperation);
  const [isDataLoad, setisDataLoad] = useState(false);
  const [tempHeader, setTempHeader] = useState([]);
  const [tempDataSet, setTempDataSet] = useState('');
  const [OracleDatabases, setOracleDatabases] = useState([]);
  const [OracleTables, setOracleTables] = useState([]);
  const [errors, setErrors] = useState({ train: '', test: '' });

  const handleAutoFetch = async (value) => {
    try {
      let str = value.replace(/^[^/]*\//, '');
      if (str.endsWith('/')) {
        str = str.slice(0, -1);
      }
      const data = {
        filepath: str,
        cloudEnvironment: workflow.cloudEnvironment,
      };
      const response = await getDirectoryData(data);
      if (response.status === 200) {
        const csvObject = response.data.find((obj) => obj.filename.endsWith('.csv'));
        const csvPath = AWS_CONFIG_TYPE
          ? `s3://dep-qa/${csvObject.filepath}`
          : `https://depazuredev.blob.core.windows.net/dep-develop/${csvObject.filepath}`;
        // const new_path = csvObject.filepath.replace(/\/[^/]+$/, '');
        setPath(str);
        setFormField({
          ...formField,
          path: csvPath,
        });
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      // handleResetForm();
    }
  };

  useEffect(() => {
    if (formField.path) {
      fetchFileHeader('refernce');
    }
  }, [formField.path]);

  const handleOnChange = (event, source) => {
    switch (source) {
      case 'language': {
        setFormField({
          ...formField,
          // path: `${formField.clientName}/${formField.batchName}/${formField.tableNameRead}/TalkToDocument/${event.target.value}`,
          dataSetName: event.target.value,
        });
        break;
      }
      case 'client': {
        setClient(event.target.value);
        setFormField({
          ...formField,
          clientName: event.target.value,
        });
        break;
      }
      case 'engagement': {
        setEngagement(event.target.value);
        setFormField({
          ...formField,
          batchName: event.target.value,
        });
        break;
      }
      case 'workflow': {
        const foundWorkflow = AllTable.find((item) => item.workflow_id === event.target.value.split('_____')[0]);
        const str = `${foundWorkflow.workflow_id}_____${foundWorkflow.execution_mode}`;
        setWorkflowData(str);
        setWorkflow(foundWorkflow);
        setFormField({
          ...formField,
          tableNameRead: event.target.value,
        });
        break;
      }
      case 'locationPath': {
        setlocationPath(event.target.value);
        break;
      }
      case 'pdfFile': {
        setPdfFile(event.target.value);
        break;
      }
      default:
        break;
    }
  };

  const handleOnChangeDataSet = (event, source) => {
    switch (source) {
      case 'writealias': {
        setFormField({
          ...formField,
          dataSetName: event.target.value.split('_____')[0],
          innerPath: event.target.value,
        });
        break;
      }
      default:
        break;
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const getFieldsData = async () => {
    setLoading(true);
    try {
      const response = await getOpenaiData('db');
      if (response.status === 200) {
        setApiType(response.data.api_type);
        setApiBase(response.data.api_base);
        setApiVersion(response.data.api_version);
        setApiKey(response.data.api_key);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const getClient = async () => {
    const response = userRole === 'SUPER_USER' ? await getAllClientsApi() : await getClientByUserGroup(userGroup);
    if (response.status === 200) {
      const clients = response.data.filter((el) => el.status === 'approved');
      setAllClient(clients);
      // setAllTable([]);
    }
  };

  const getBatch = async (clientName) => {
    const sp = clientName.split('_____');
    const response = await getEngagementByUserGroupAndClientIdApi(userGroup, sp[0]);
    if (response.status === 200) {
      const engagements = response.data.filter((el) => el.status === 'approved');
      setAllBatch(engagements);
    }
  };

  const getTable = async (batchName) => {
    try {
      const sp1 = batchName.split('_____');
      const response = await getWorkflowByUserGroupAndEngagementIdApi(userGroup, sp1[0]);
      if (response.status === 200) {
        const workflows = response.data.filter((el) => el.status === 'approved');
        setAllTable(workflows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWriteAlias = async (workflow) => {
    try {
      const sp1 = workflow.split('_____');
      const response = await getDataProcessor(sp1[0], sp1[1]);
      if (response.status === 200) {
        const data_set1 = response.data.form_data.filter((el) => el.step_name === 'Write');
        const data_set2 = response.data.form_data.filter((el) => el.step_name === 'Ingestion Write');
        if (data_set2.length > 0) {
          setIngestionWriteTable(data_set2[0].sorting);
          setWriteTable([]);
        } else {
          setIngestionWriteTable([]);
          setWriteTable(data_set1);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClient();
    // setFormField({
    //   ...formField,
    //   clientName: workflowInfo.engagement.client.client_name,
    //   batchName: workflowInfo.engagement.engagement_name,
    //   tableNameRead: workflowInfo.workflow_name,
    //   dataSetName: 'English',
    // });
  }, []);

  // console.log(formField);
  // useEffect(() => {
  //   if (formField.clientName) {
  //     getBatch(formField.clientName);
  //     // setFormField({ ...formField, batchName: '', tableNameRead: '', dataSetName: '', trackingId: '', file: '' });
  //   }
  // }, [formField.clientName]);

  // useEffect(() => {
  //   if (formField.batchName) {
  //     getTable(formField.batchName);
  //     setFormField({ ...formField, trackingId: '', file: '' });
  //   }
  // }, [formField.batchName]);

  // useEffect(() => {
  //   if (formField.tableNameRead) {
  //     getWriteAlias(formField.tableNameRead);
  //     setFormField({ ...formField, trackingId: '', file: '' });
  //   }
  // }, [formField.tableNameRead]);

  // useEffect(() => {
  //   if (formField.dataSetName) {
  //     handleAutoFetch(formField.dataSetName);
  //   }
  // }, [formField.dataSetName]);

  const [addColumnModalOpen, setAddColumnModalOpen] = useState(false);
  const handleAddColumnModalOpen = () => setAddColumnModalOpen(true);
  const handleAddColumnModalClose = () => setAddColumnModalOpen(false);

  const handleHeaderChange = (obj) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === obj.header;
    });

    if (selectedIndex === -1 && obj.checked) {
      setHeaderName([...headerName, obj]);

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              header: obj.header,
              alias: obj.alias,
              checked: true,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && obj.checked) {
      setHeaderName((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              header: obj.header,
              alias: obj.alias,
              cast_datatype: obj.cast_datatype,
              attribute_type: obj.attribute_type,
              default_value: obj.default_value,
            };
          }

          return object;
        })
      );
    }

    if (selectedIndex !== -1 && !obj.checked) {
      setHeaderName((current) =>
        current.filter((object) => {
          return object.header !== obj.header;
        })
      );

      setFetchedHeader((current) =>
        current.map((object) => {
          if (object.header === obj.header) {
            return {
              ...object,
              checked: false,
              alias: '',
              cast_datatype: 'string',
              attribute_type: 'static',
              default_value: '',
            };
          }

          return object;
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fetchedHeader.map((n) => {
        const all = {
          header: n.header,
          alias: n.alias,
          checked: true,
          cast_datatype: 'string',
          attribute_type: 'static',
          default_value: '',
        };
        return all;
      });
      setHeaderName(newSelecteds);
      return;
    }
    setHeaderName([]);
  };

  const isSelected = (name) => {
    const selectedIndex = headerName.findIndex((object) => {
      return object.header === name;
    });

    return selectedIndex !== -1;
  };

  const store = JSON.parse(sessionStorage.getItem('allNodes'));

  const getLocalData = () => {
    if (store) {
      store.forEach((node) => {
        if (node.nodeId === nodeId) {
          setFormField(node.formField);
          setisDataLoad(true);
          setHeaderName(node.headerName);
          setTempHeader(node.headerName);
          setFetchedHeader(node.fetchedHeader);
          if (node.disabled) {
            setDisableForm(node.disabled);
          }
        }
      });
    }
  };

  const validate = () => {
    if (formField.pathTypeFile === 'filepath' && !formField.path.includes('.csv')) {
      setErrors({ ...errors, filepath: 'Enter valid file path' });
    } else if (formField.pathTypeFile === 'outputpath' && formField.path.includes('.csv')) {
      setErrors({ ...errors, outputpath: 'Enter valid folder path' });
    } else {
      setErrors({ filepath: '', outputpath: '' });
    }
  };

  useEffect(() => {
    validate();
  }, [formField.path, errors.filepath, errors.outputpath]);

  useEffect(() => {
    setFormField(INITIALSTATE);
    setflowbuilderRowLevelOperation(RowLevelOperation);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    getLocalData();
  }, [nodeId]);

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    if (
      name === 'ignoreblanklines' ||
      name === 'linebreak' ||
      name === 'skipheaders' ||
      name === 'columnshift' ||
      name === 'junkrecords' ||
      name === 'pagination'
    ) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormField(INITIALSTATE);
    setDisableForm(false);
    setFetchedHeader([]);
    setHeaderName([]);
    setAllBatch([]);
    setAllTable([]);
    setWriteTable([]);
  };

  const compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.header === element_2.header &&
            element_1.tableAlias === element_2.tableAlias &&
            element_1.alias === element_2.alias &&
            element_1.checked === element_2.checked
        )
      )
    );
  };

  const handleFormsubmit = async (e) => {
    e.preventDefault();

    // const regex = AWS_CONFIG_TYPE === true ? /^s3:\/\//i : /^https:\/\//i;

    // if (!regex.test(formField.path)) {
    //   if (AWS_CONFIG_TYPE) {
    //     enqueueSnackbar('S3 path is invalid!', {
    //       variant: 'error',
    //       autoHideDuration: 3000,
    //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //     });
    //   } else {
    //     enqueueSnackbar('Doc gen2 path is invalid!', {
    //       variant: 'error',
    //       autoHideDuration: 3000,
    //       anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //     });
    //   }
    //   return;
    // }

    const newHeaderName = [];

    headerName.forEach((item) => {
      newHeaderName.push({ ...item, tableAlias: formField.alias });
    });

    let equalArray;
    if (tempHeader.length > 0) {
      equalArray = compareTwoArrayOfObjects(tempHeader, newHeaderName);
    }

    const connectedNodes = [];

    if (!equalArray && tempHeader.length > 0) {
      edges.forEach((el) => {
        if (el.source === nodeId) connectedNodes.push(el.target);
      });

      let count = 0;

      setNodes((nds) =>
        nds.map((node) => {
          if (count <= connectedNodes.length && node.id === connectedNodes[count]) {
            node.position.x += 0.1;
            count++;
          }

          return node;
        })
      );
    }

    sessionStorage.setItem('updatedNodes', JSON.stringify(connectedNodes));

    setHeaderName(newHeaderName);

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');

    if (getAllNodes.length > 0) {
      const newFormData = getAllNodes.filter((el) => el.nodeId !== nodeId);
      sessionStorage.setItem('allNodes', JSON.stringify(newFormData));
    }

    let y_axis;

    nodes.forEach((el) => {
      if (nodeId === el.id) {
        y_axis = parseInt(el.position.x, 10);
        el.data.label = formField.alias;
      }
    });

    const sendFormData = {
      y_axis,
      nodeId,
      nodeName,
      formField,
      disabled: true,
      step_name: nodeName,
      headerName: newHeaderName,
      fetchedHeader,
    };

    changeNodeName(nodes);

    setDisableForm(true);

    sessionStorage.setItem('node', JSON.stringify(nodes));
    sessionStorage.setItem('edges', JSON.stringify(edges));

    const fetchNodesData = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    fetchNodesData.push(sendFormData);

    sessionStorage.setItem('allNodes', JSON.stringify(fetchNodesData));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node') || '[]');
    getElements.push(nodeId);
    sessionStorage.setItem('saved_node', JSON.stringify(getElements));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setDisableForm(false);

    store.forEach((node) => {
      if (node.nodeId === nodeId) {
        setTempHeader(headerName);
      }
    });

    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes'));

    getAllNodes.forEach((el) => {
      if (el.nodeId === nodeId) {
        el['disabled'] = false;
      }
    });

    sessionStorage.setItem('allNodes', JSON.stringify(getAllNodes));

    const getElements = JSON.parse(sessionStorage.getItem('saved_node'));

    const newSavedElement = getElements.filter((el) => el !== nodeId);

    sessionStorage.setItem('saved_node', JSON.stringify(newSavedElement));
  };

  const fetchFileHeader = async (dataType) => {
    if (dataType === 'refernce') {
      const regex =
        workflow.cloudEnvironment === 'AWS'
          ? /^s3:\/\/.*csv$/
          : workflow.cloudEnvironment === 'AZURE'
          ? /^https:\/\/.*csv$/
          : workflow.cloudEnvironment === 'SNOWFLAKE'
          ? /.*csv$/
          : '';

      if (!regex.test(formField.path)) {
        if (workflow.cloudEnvironment === 'AWS') {
          enqueueSnackbar('S3 path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (workflow.cloudEnvironment === 'AZURE') {
          enqueueSnackbar('Doc gen2 path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (workflow.cloudEnvironment === 'SNOWFLAKE') {
          enqueueSnackbar('snowflake path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        return;
      }
    }
    setLoadingBtn(true);

    let data = {};
    if (dataType === 'refernce') {
      const pathArr = formField.path.split('/');
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      (workflow.cloudEnvironment === 'AWS' || workflow.cloudEnvironment === 'AZURE') && pathArr.shift(0);
      workflow.cloudEnvironment === 'AZURE' && pathArr.shift(0);
      const newPath = pathArr.join('/');
      data = {
        path: newPath,
        workflow_id: workflow.workflow_id,
      };
    } else {
      const newPath = `${formField.trackingId}${formField.file}`;
      data = {
        path: newPath,
        workflow_id: workflow.workflow_id,
      };
    }

    try {
      const response = await getCsvData(data);
      if (response.status === 200) {
        const header = [];

        response.data.forEach((el) => {
          header.push({
            header: el,
            alias: '',
            checked: false,
            tableAlias: '',
            cast_datatype: 'string',
            default_value: '',
            attribute_type: 'static',
          });
        });

        setFetchedHeader(header);
        setHeaderName([]);
        setTempHeader([]);
      }
    } catch (error) {
      enqueueSnackbar('Path does not exist! Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setLoadingBtn(false);
  };

  const getOracleDatabase = async (format) => {
    try {
      const response = await fetchOracleDatabase();
      if (response.status === 200) {
        const db = [];
        response.data.forEach((el) => {
          db.push(el.label);
        });
        setOracleDatabases(db);
      }
    } catch (error) {
      enqueueSnackbar('Databases not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };
  const getOracleTables = async (database) => {
    try {
      const response = await fetchOracleTables(database);
      if (response.status === 200) {
        const tables = [];
        response.data.forEach((el) => {
          tables.push(el.label);
        });
        setOracleTables(tables);
      }
    } catch (error) {
      enqueueSnackbar('Tables not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (formField.format === 'oracle') {
      getOracleDatabase(formField.format);
    }

    if (formField.format === 'oracle' && formField.database) {
      getOracleTables(formField.database);
    }
  }, [formField]);

  const handleChecked = (event) => {
    setFormField({ ...formField, sample_view: event.target.checked });
  };

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition} style={{ width: '70%', marginLeft: '30%' }}>
        <form autoComplete="off" onSubmit={handleFormsubmit}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close" sx={{ color: '#00043C' }}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: '#00043C' }} variant="h6" component="div">
                {nodeName}
              </Typography>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleResetForm}
                  disabled={disableForm}
                  className="outlined-button-color"
                >
                  Clear
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!disableForm}
                  className="outlined-button-color"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button type="submit" size="small" variant="contained" disabled={disableForm} className="button-color">
                  Submit
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>
          <div style={{ margin: '20px 40px' }}>
            <TreeViewDirectory
              setSelectedFilePath={setSelectedFilePath}
              formField={formField}
              setFormField={setFormField}
              disableForm={disableForm}
              setDisableForm={setDisableForm}
            />
            <TextField
              id="filepath"
              name="path"
              label="Read Full Path"
              value={formField.path}
              // onChange={handleInputChange}
              sx={{ margin: '20px 0' }}
              size="small"
              disabled
              fullWidth
              // disabled={disableForm}
              required
              // error={errors.filepath}
              // helperText={errors.filepath}
            />
            <Grid container mt={0} spacing={2}>
              <Grid item xs={2.5} md={3}>
                <TextField
                  id="filepath"
                  name="client"
                  label="Client"
                  value={formField.clientName}
                  size="small"
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={2.5} md={3}>
                {' '}
                <TextField
                  id="filepath"
                  name="engagement"
                  label="Engagement"
                  value={formField.batchName}
                  size="small"
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={2.5} md={3}>
                <TextField
                  id="filepath"
                  name="workflow"
                  label="Workflow"
                  value={formField.tableNameRead}
                  // onChange={handleInputChange}
                  size="small"
                  disabled
                  // disabled={disableForm}
                  required
                  // error={errors.filepath}
                  // helperText={errors.filepath}
                />
              </Grid>
              <Grid item xs={2.5} md={3}>
                <TextField
                  id="outlined-select-client"
                  select
                  required
                  label="Language"
                  name="clientName"
                  value={formField.dataSetName}
                  disabled={disableForm}
                  fullWidth
                  onChange={(e) => handleOnChange(e, 'language')}
                  size="small"
                >
                  <MenuItem value={`English`}>English</MenuItem> <MenuItem value={`Arabic`}>Arabic</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <TextField
              id="filepath"
              name="innerpath"
              label="Write Full Path"
              value={`${formField.clientName}/${formField.batchName}/${formField.tableNameRead}/TalkToDocument/${formField.dataSetName}`}
              // onChange={handleInputChange}
              sx={{ margin: '20px 0' }}
              size="small"
              disabled
              fullWidth
              // disabled={disableForm}
              required
              // error={errors.filepath}
              // helperText={errors.filepath}
            />

            {/* <InputField
              name="alias"
              label="Alias"
              value={formField.alias}
              onChange={handleInputChange}
              size="small"
              disabled={disableForm}
              required
            />
             <Grid container mt={0.5} mb={2} spacing={2}>
              {formField.pathTypeFile === 'filepath' && (
                <>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: '100%',
                      }}
                    >
                      <TextField
                        id="filepath"
                        name="path"
                        label="Full Path"
                        // value={
                        //   workflow && workflow.cloudEnvironment === 'AWS'
                        //     ? formField.path.replace(/^s3:\/\/dep-qa\/|\/[^/]+$/g, '')
                        //     : formField.path.replace(
                        //         /^https:\/\/depazuredev\.blob\.core\.windows\.net\/dep-develop\/|\/[^/]+$/g,
                        //         ''
                        //       )
                        // }
                        value={formField.path}
                        onChange={handleInputChange}
                        size="small"
                        // disabled
                        disabled={disableForm}
                        required
                        // error={errors.filepath}
                        // helperText={errors.filepath}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid> */}
            {/* <Grid container mt={1} spacing={2}>
              <Grid item xs={2.5} md={3}>
                <TextField
                  id="outlined-select-client"
                  select
                  required
                  label="Client"
                  name="clientName"
                  value={formField.clientName}
                  disabled={disableForm}
                  onChange={(e) => handleOnChange(e, 'client')}
                  fullWidth
                  size="small"
                >
                  {AllClient &&
                    AllClient.map((ele) => {
                      return (
                        <MenuItem key={ele.client_id} value={`${ele.client_id}_____${ele.client_name}`}>
                          {ele.client_name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={2.5} md={3}>
                <TextField
                  id="outlined-select-batch"
                  select
                  required
                  label="Engagement"
                  name="batchName"
                  value={formField.batchName}
                  disabled={disableForm}
                  onChange={(e) => handleOnChange(e, 'engagement')}
                  fullWidth
                  size="small"
                >
                  {AllBatch &&
                    AllBatch.map((ele) => {
                      return (
                        <MenuItem key={ele.engagement_id} value={`${ele.engagement_id}_____${ele.engagement_name}`}>
                          {ele.engagement_name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={2.5} md={3}>
                <TextField
                  id="tableNameRead"
                  select
                  required
                  label="Workflow"
                  name="tableNameRead"
                  value={formField.tableNameRead}
                  disabled={disableForm}
                  onChange={(e) => handleOnChange(e, 'workflow')}
                  fullWidth
                  size="small"
                >
                  {AllTable &&
                    AllTable.map((ele) => {
                      return (
                        <MenuItem key={ele.workflow_id} value={`${ele.workflow_id}_____${ele.execution_mode}`}>
                          {ele.workflow_name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={2.5} md={3}>
                <TextField
                  id="dataSetName"
                  select
                  required
                  label="Data Set"
                  name="dataSetName"
                  value={formField.innerPath}
                  disabled={disableForm}
                  onChange={(e) => handleOnChangeDataSet(e, 'writealias')}
                  fullWidth
                  size="small"
                >
                  {WriteTable &&
                    WriteTable.map((ele) => {
                      return (
                        <MenuItem key={ele.formField.alias} value={`${ele.formField.path}_____${ele.formField.alias}`}>
                          {ele.formField.alias}
                        </MenuItem>
                      );
                    })}
                  {IngestionWriteTable &&
                    IngestionWriteTable.map((ele) => {
                      return (
                        <MenuItem key={ele.dataFrame} value={`${ele.file_path}_____${ele.dataFrame}`}>
                          {ele.dataFrame}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
            </Grid> */}

            {/* {loadingBtn && (
              <Box>
                <LoadingIcon />
              </Box>
            )} */}

            {/* {!loadingBtn && fetchedHeader.length > 0 && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formField.sample_view}
                      onChange={handleChecked}
                      disabled={disableForm}
                    />
                  }
                  label="Sample View"
                  sx={{ pl: 1 }}
                />

                <TableContainer mt={2} sx={{ maxHeight: 300 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={fetchedHeader.length === headerName.length}
                            onChange={handleSelectAllClick}
                            disabled={disableForm}
                          />
                          Select Columns
                        </TableCell>
                        <TableCell>Columns</TableCell>
                        <TableCell>Datatype</TableCell>
                        <TableCell>Alias</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fetchedHeader.map((row, i) => {
                        const isItemSelected = isSelected(row.header);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={i}
                            selected={isItemSelected}
                          >
                            <TableCell>
                              <Checkbox
                                checked={isItemSelected}
                                disabled={disableForm}
                                onChange={(event) =>
                                  handleHeaderChange({
                                    header: row.header,
                                    alias: '',
                                    checked: event.target.checked,
                                    cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                    default_value: row.default_value,
                                    attribute_type: row.attribute_type,
                                  })
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            </TableCell>
                            <TableCell>{row.header}</TableCell>

                            <TableCell>
                              <TextField
                                id="standard-select-currency"
                                select
                                label="Cast Datatype"
                                name="cast_datatype"
                                disabled={disableForm}
                                value={row.cast_datatype ? row.cast_datatype : 'string'}
                                onChange={(event) =>
                                  handleHeaderChange({
                                    header: row.header,
                                    alias: row.alias,
                                    checked: true,
                                    cast_datatype: event.target.value,
                                    default_value: row.default_value,
                                    attribute_type: row.attribute_type,
                                  })
                                }
                                style={{ width: '100%' }}
                                size="small"
                              >
                                <MenuItem value="int">Integer</MenuItem>
                                <MenuItem value="string">String</MenuItem>
                                <MenuItem value="float">Float</MenuItem>
                                <MenuItem value="timestamp">Timestamp</MenuItem>
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="alias"
                                label="Alias"
                                value={row.alias}
                                disabled={disableForm}
                                onChange={(e) =>
                                  handleHeaderChange({
                                    header: row.header,
                                    alias: e.target.value,
                                    checked: true,
                                    cast_datatype: row.cast_datatype ? row.cast_datatype : 'string',
                                    default_value: row.default_value,
                                    attribute_type: row.attribute_type,
                                  })
                                }
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )} */}
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default memo(NodeModalCopyDoc);
