import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Table as AntdTable, Select, Empty } from 'antd';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { AiFillEdit, AiFillSave, AiOutlineCloseCircle } from 'react-icons/ai';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import { convertToPascalCase } from '../../utils/stringConversion';

const groupOptions = ['NA', 'Regroup', 'Ungroup'];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  handleChangeGroup,
  onInputChange,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber onChange={(e) => onInputChange(e, record.key, dataIndex)} />
    ) : // : inputType === 'typeSelect' && dataIndex === 'parent_id' ? (
    //   <Select
    //     // style={{ zIndex: 2000 }}
    //     onChange={(e) => {
    //       handleChangeParentIds(e, record);
    //     }}
    //     getPopupContainer={(node) => node.parentNode}
    //   >
    //     {parentIds.map((item) => {
    //       return (
    //         <Select.Option key={item} value={item}>
    //           {item}
    //         </Select.Option>
    //       );
    //     })}
    //   </Select>
    // )

    inputType === 'typeSelect' && dataIndex === 'select_grouping' ? (
      <Select
        onChange={(e) => {
          handleChangeGroup(e, record);
        }}
        getPopupContainer={(node) => node.parentNode}
      >
        {groupOptions.map((item) => {
          return (
            <Select.Option key={item} value={item} defaultValue="NA">
              {item}
            </Select.Option>
          );
        })}
      </Select>
    ) : (
      <Input onChange={(e) => onInputChange(e, record.key, dataIndex)} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const MergeManager = ({
  disableForm,
  mergeManagerData,
  setMergeManagerData,
  loading,
  mergeManagerKeys,
  setMergeManagerKeys,
}) => {
  const [form] = Form.useForm();
  const { setMergeManager } = useContext(WorkflowContext);

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const handleChangeParentIds = (value, record) => {
    // console.log('record', record);
  };

  const handleChangeGroup = (value, record) => {
    // console.log('grouping', record);
  };

  const onInputChange = (e, index, dataIndex) => {
    const newData = [...mergeManagerData];
    newData[index][dataIndex] = e.target.value;
    setMergeManagerData(newData);
  };

  const edit = (record) => {
    form.setFieldsValue({
      bus_unit_id: '',
      phone_no: '',
      cust_bill_to_address: '',
      bill_postalcd: '',
      unique_id: '',
      cust_address: '',
      index: '',
      source: '',
      is_phone_no_valid: '',
      ship_postalcd: '',
      exception_message: '',
      src_sale_amt: '',
      cust_postalcd: '',
      score: '',
      duns_no: '',
      src_sale_currency: '',
      src_sale_date: '',
      parent_id: '',
      cust_ship_to_address: '',
      is_email_valid: '',
      cust_name: '',
      customer_type_name: '',
      email: '',
      country: '',
      product: '',
      quantity: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    setMergeManagerKeys((prev) => [...new Set([...prev, key])]);
    try {
      const row = await form.validateFields();
      const newData = [...mergeManagerData];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setMergeManagerData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setMergeManagerData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {
    setMergeManager(mergeManagerData.filter((item) => mergeManagerKeys.includes(item.key)));
  }, [mergeManagerKeys, editingKey]);

  const handleEditableColumn = (item) => {
    if (item === 'select_grouping' || item === 'reviewed_by' || item === 'group') return true;
    return false;
  };

  const actionColumn = {
    title: 'Action',
    dataIndex: 'action',
    fixed: 'right',
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Stack direction="row" alignItems="center">
          <Tooltip title="Save">
            <IconButton size="small" color="success" onClick={() => save(record.key)}>
              <AiFillSave />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton size="small" color="error" onClick={cancel}>
              <AiOutlineCloseCircle />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Tooltip title="Edit">
          <span>
            <IconButton
              size="small"
              color="primary"
              disabled={editingKey !== '' || disableForm}
              onClick={() => edit(record)}
            >
              <AiFillEdit />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
  };

  const antdColumns =
    mergeManagerData?.length > 0
      ? Object.keys(mergeManagerData[0])
          .filter((item) => item !== 'key')
          .map((ele, index, array) => ({
            width: index === array.length - 1 ? 0 : 170,
            title: convertToPascalCase(ele),
            dataIndex: ele,
            editable: handleEditableColumn(ele),
            render: (text) => {
              return {
                props: {
                  style: ele === 'exception_message' ? { color: 'red' } : {}, // Apply red color if exception_message is not empty
                },
                children: text,
              };
            },
          }))
      : [];

  const updatedColumns = [...antdColumns, { ...actionColumn }];

  const sumOfWidths = updatedColumns.reduce((total, item) => total + (item.width || 0), 0) + 220;

  const mergedColumns = updatedColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          inputType: col.dataIndex === 'parent_id' || col.dataIndex === 'select_grouping' ? 'typeSelect' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          handleChangeGroup,
          onInputChange,
        };
      },
    };
  });

  return (
    <Form form={form} component={false}>
      <Box display="flex" flexDirection="column">
        <Typography variant="body2" sx={{ m: 1, ml: 'auto' }}>
          Total Records: {mergeManagerData?.length ?? 0}
        </Typography>
        <AntdTable
          size="small"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={mergeManagerData}
          columns={mergedColumns}
          loading={mergeManagerData?.length <= 0 && loading ? !loading && <Empty /> : false}
          pagination={{
            onChange: cancel,
          }}
          scroll={{
            x: sumOfWidths,
            y: 300,
          }}
        />
      </Box>
    </Form>
  );
};

export default MergeManager;
