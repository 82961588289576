import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { authenticateUserApi, createUserApi } from "../api's/UserApi";
import gt_logo from '../assets/images/gt_logo_3.png';
import nagarro_logo from '../assets/images/nagarro_logo.jpg';
import { AWS_CONFIG_TYPE, AWS_UI_URL, AZURE_UI_URL, SNOWFLAKE_UI_URL } from '../BaseUrl';
import { AuthContext } from '../contexts/AuthProvider';
import { LOGIN_FORM } from '../utils/commonConstants';
import themeStyles from '../utils/theme';
import LoadingIcon from './LoadingIcon';
import './LoginForm.css';
import useStyles from './styles';

const response = {
  data: {
    username: 'Dep_admin',
    userGroup: 'Group1',
    userRole: 'Admin',
    version: '1.0',
  },
};

const nameRegex = /^\S{1,35}$/;

export default function LoginForm() {
  const [visible, setVisible] = useState(true);
  const [approval, setApproval] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formField, setFormField] = useState({
    username: '',
    email: '',
    password: '',
    access_rights: 'READER',
    user_group: 'Group1',
    full_name: '',
  });
  const [cloudValue, setCloudValue] = React.useState(AWS_CONFIG_TYPE ? 'aws' : 'azure');
  const [checked, setChecked] = useState(false);

  const [userNameError, setUserNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSetCloud = (event) => {
    // setCloudValue(event.target.value);
    if (event.target.value === 'aws') {
      window.open(AWS_UI_URL, '_blank');
    } else if (event.target.value === 'snowflake') {
      // window.location.href = 'http://dep-snowpark-react-frontend-env.us-east-1.elasticbeanstalk.com/';
      window.open(SNOWFLAKE_UI_URL, '_blank');
    } else {
      // window.location.href = 'https://as-dep-frontend.azurewebsites.net/';
      window.open(AZURE_UI_URL, '_blank');
    }
  };
  const { setUser, setUserRole, setToken, setUserId, setUserGroup, isDep, setIsDep, setLLMName, setLLMId } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const themeClasses = themeStyles();
  // const theme = process.env.REACT_APP_THEME;
  const theme = 'nagarro';

  const classes = useStyles();

  const handleChange = (event) => {
    setChecked(event.target.checked);

    if (event.target.checked) {
      sessionStorage.setItem('dep', JSON.stringify(false));
      setIsDep(false);
    } else {
      sessionStorage.setItem('dep', JSON.stringify(true));
      setIsDep(true);
    }
  };

  useEffect(() => {
    setUserNameError('');
    setPasswordError('');
  }, []);

  const createUser = async (data) => {
    try {
      const response = await createUserApi(data);
      if (response.status === 200) {
        enqueueSnackbar(`User Created Successfully`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } catch (error) {
      if (error.response?.status === 409) {
        enqueueSnackbar('User already exist.', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response.status === 500) {
        enqueueSnackbar(error.response.data || 'Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const authenticateUser = async (data) => {
    try {
      setLoading(true);
      const response = await authenticateUserApi(data);
      if (response.status === 200) {
        sessionStorage.setItem('jwtToken', JSON.stringify(response.data.token));
        sessionStorage.setItem('id', JSON.stringify(response.data.user_id));
        sessionStorage.setItem('access_right', JSON.stringify(response.data.role));
        sessionStorage.setItem('user', JSON.stringify(response.data.username));
        sessionStorage.setItem('full_name', JSON.stringify(response.data.full_name));
        sessionStorage.setItem('userGroup', JSON.stringify(response.data.user_group));
        sessionStorage.setItem('version', JSON.stringify('2.0'));
        sessionStorage.setItem('LLMId', AWS_CONFIG_TYPE ? 'llm-2' : 'llm-1');
        sessionStorage.setItem('LLMName', AWS_CONFIG_TYPE ? 'Bedrock' : 'ChatGPT');
        setUser(response.data.username);
        setUserRole(response.data.role);
        setToken(response.data.token);
        setUserId(response.data.user_id);
        setUserGroup(response.data.user_group);
        setLLMId('llm-1');
        setLLMName('ChatGPT');
        setLoading(false);
        navigate('/dashboard');
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 500) {
        enqueueSnackbar(error.response.data || 'Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }

      if (error.response?.status === 401) {
        const str = 'UserDetailsService returned null, which is an interface contract violation';
        enqueueSnackbar(
          error.response.data === str ? 'Credentials are invalid !!' : error.response.data || 'Unauthorized!',
          {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (visible) {
      if (formField.username === '' || formField.password === '') {
        const username = formField.username;
        const password = formField.password;
        enqueueSnackbar(
          username === '' && password === ''
            ? `Credentials cannot be empty !`
            : username === ''
            ? 'Username cannot be empty !'
            : 'Password cannot be empty !',
          {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );

        return;
      }
      const userDetails = {
        username: formField.username,
        password: formField.password,
      };

      authenticateUser(userDetails);
    } else {
      if (formField.username === '' || formField.password === '' || formField.email === '' || formField.full_name === '') {
        enqueueSnackbar(`Please enter all details.`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        return;
      }

      setApproval(true);
      const userDetails = {
        username: formField.username,
        password: formField.password,
        email: formField.email,
        role: formField.access_rights,
        user_group: formField.user_group,
        full_name: formField.full_name,
      };

      createUser(userDetails);
      setVisible(true);
    }
  };

  const handleVisible = () => {
    setVisible(!visible);
    setFormField({
      username: '',
      email: '',
      password: '',
      access_rights: 'READER',
      user_group: 'Group1',
      full_name: '',
    });
    setUserNameError('');
    setPasswordError('');
  };

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    switch (name) {
      case 'username':
        if (value.trim() === '') {
          setUserNameError(`Username is required.`);
        } else if (!value.match(nameRegex)) {
          setUserNameError(`Invalid Username Format "${value}". NO SPACE ALLOWED.`);
        } else {
          setUserNameError('');
        }
        break;
      case 'password':
        if (value === '') {
          setPasswordError(`Password is required.`);
        } else {
          setPasswordError('');
        }
        break;
      default:
    }

    setFormField({
      ...formField,
      [name]: value,
    });
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} className={classes.formComponent}>
      {visible ? (
        <div className="login-radio-form">
          <RadioGroup row name="row-radio-buttons-group" value={cloudValue} onChange={handleSetCloud}>
            <FormControlLabel
              value="aws"
              control={
                <Radio
                  sx={{
                    color: '#00043c',
                    '&.Mui-checked': {
                      color: '#00043c',
                    },
                  }}
                />
              }
              label="AWS"
            />

            <FormControlLabel
              value="azure"
              control={
                <Radio
                  sx={{
                    color: '#00043c',
                    '&.Mui-checked': {
                      color: '#00043c',
                    },
                  }}
                />
              }
              label="Azure"
            />
            <FormControlLabel
              value="snowflake"
              control={
                <Radio
                  sx={{
                    color: '#00043c',
                    '&.Mui-checked': {
                      color: '#00043c',
                    },
                  }}
                />
              }
              label="Snowflake"
            />
            {/* <FormControlLabel value="disabled" disabled control={<Radio />} label="other" /> */}
          </RadioGroup>
        </div>
      ) : null}
      <Box className="nagarro-logo">
        <img
          src={theme === 'gt' ? gt_logo : theme === 'nagarro' ? nagarro_logo : null}
          alt="login"
          className={themeClasses[`${theme}LogoImg`]}
        />
      </Box>
      {!approval ? (
        <Box>
          {/* <Typography variant="h5" component="h5" className={classes.formTextField}>
            {visible ? LOGIN_FORM.formLabelDefault : LOGIN_FORM.formLabelFreeSignIn}
          </Typography>
          <Typography variant="p" gutterBottom component="p" className={classes.formLabel}>
            {visible ? LOGIN_FORM.formLabelDefaultText : LOGIN_FORM.formLabelText}
          </Typography> */}
          <Typography variant="p" component="p" className={classes.formFirstTextField}>
            {LOGIN_FORM.formInputUserName}
          </Typography>

          <TextField
            size="small"
            required
            fullWidth
            id="username"
            name="username"
            value={formField.username}
            error={!!userNameError}
            helperText={userNameError}
            autoComplete="username"
            onChange={handleInputChange}
            className={classes.inputTextField}
          />

{visible ? null : (
            <Typography variant="p" component="p">
              {LOGIN_FORM.formInputFullName}
            </Typography>
          )}

          {visible ? null : (
            <TextField
              size="small"
              required
              fullWidth
              id="full_name"
              name="full_name"
              type="text"
              autoComplete="full_name"
              onChange={handleInputChange}
              value={formField.full_name}
              className={classes.inputTextField}
            />
          )}

          {visible ? null : (
            <Typography variant="p" component="p">
              {LOGIN_FORM.formInputEmail}
            </Typography>
          )}

          {visible ? null : (
            <TextField
              size="small"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              onChange={handleInputChange}
              value={formField.email}
              className={classes.inputTextField}
            />
          )}
          <Typography direction="row" variant="p" component="p">
            {LOGIN_FORM.formInputPassword}
          </Typography>
          {/* <Stack direction="row">
            <Typography direction="row" style={{ width: '50%' }} variant="p" component="p">
              {LOGIN_FORM.formInputPassword}
            </Typography>
            {!visible ? null : (
              <Typography style={{ width: '50%', textAlign: 'right' }} variant="p" component="p">
                {LOGIN_FORM.formForgotPassword}
              </Typography>
            )}
          </Stack> */}

          <TextField
            size="small"
            required
            fullWidth
            name="password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            onChange={handleInputChange}
            value={formField.password}
            error={!!passwordError}
            helperText={passwordError}
            autoComplete="current-password"
            className={classes.inputTextField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {showPassword ? (
                    <FaEye onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                  ) : (
                    <FaEyeSlash onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                  )}
                </InputAdornment>
              ),
              inputProps: {
                list: 'rfc',
              },
            }}
          />

          {!visible ? null : (
            <>
              <Typography variant="p" component="p">
                {LOGIN_FORM.formForgotPassword}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                  }
                  label="XPerience360"
                  sx={{ color: checked ? 'black' : 'secondary' }}
                />
              </FormGroup>
            </>
          )}
          {visible ? null : (
            <Typography variant="p" component="p">
              USER GROUP
            </Typography>
          )}
          {visible ? null : (
            <FormControl className={classes.accessRightsForm}>
              <Select
                labelId="accessRights"
                id="demo-simple-select-helper"
                name="user_group"
                value={formField.user_group}
                onChange={handleInputChange}
                fullWidth
                style={{ height: '40px' }}
                className={classes.inputTextField}
              >
                <MenuItem value={'Group1'}>Group 1</MenuItem>
                <MenuItem value={'Group2'}>Group 2</MenuItem>
                <MenuItem value={'Group3'}>Group 3</MenuItem>
                <MenuItem value={'Group4'}>Group 4</MenuItem>
                <MenuItem value={'Group5'}>Group 5</MenuItem>
              </Select>
            </FormControl>
          )}

          {visible ? null : (
            <Typography variant="p" component="p">
              {LOGIN_FORM.formInputAccessRights}
            </Typography>
          )}
          {visible ? null : (
            <FormControl className={classes.accessRightsForm}>
              <Select
                labelId="accessRights"
                id="demo-simple-select-helper"
                name="access_rights"
                value={formField.access_rights}
                onChange={handleInputChange}
                fullWidth
                style={{ height: '40px' }}
              >
                <MenuItem value={'READER'}>{LOGIN_FORM.formInputAccessRightsOptionReadOnly}</MenuItem>
                <MenuItem value={'EXECUTOR'}>{LOGIN_FORM.formInputAccessRightsOptionReadAndExecuter}</MenuItem>
                <MenuItem value={'ADMIN'}>{LOGIN_FORM.formInputAccessRightsOptionAdmin}</MenuItem>
              </Select>
            </FormControl>
          )}
          {visible ? null : (
            <Box>
              {LOGIN_FORM.formTermsAndCondition}
              <Link href="#" variant="body2" className={classes.formLink} onClick={handleVisible}>
                {LOGIN_FORM.formTermsOfService}
              </Link>
              {LOGIN_FORM.and}
              <Link href="#" variant="body2" className={classes.formLink} onClick={handleVisible}>
                {LOGIN_FORM.privacyPolicy}
              </Link>
            </Box>
          )}
          <Box sx={{ mt: 3, mb: 2 }}>
            {visible ? (
              <Button
                size="small"
                type="submit"
                fullWidth
                variant="contained"
                className={themeClasses[`${theme}ThemeColor`]}
              >
                {LOGIN_FORM.formSignIn}
              </Button>
            ) : (
              <Button
                size="small"
                type="submit"
                fullWidth
                variant="contained"
                // sx={{ backgroundColor: '#623c74', '&:hover': { backgroundColor: '#623c74' } }}
                className={themeClasses[`${theme}ThemeColor`]}
              >
                {LOGIN_FORM.formCreateAccount}
              </Button>
            )}
          </Box>
          <Grid style={{ marginTop: '-8px' }}>
            <Grid
              item
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {visible ? (
                <Box>
                  {LOGIN_FORM.notHavingAccount}
                  <Link href="#" variant="body2" className={classes.formLink} onClick={handleVisible}>
                    {LOGIN_FORM.formSignUp}
                  </Link>
                </Box>
              ) : (
                <Box>
                  {LOGIN_FORM.havingAccount}
                  <Link href="#" variant="body2" className={classes.formLink} onClick={handleVisible}>
                    {LOGIN_FORM.formSignIn}
                  </Link>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box mt={8}>
          <Typography id="modal-modal-title" variant="h6" component="h1" sx={{ color: 'black' }}>
            Account approval in process!
          </Typography>
          <Typography fontSize={13} variant="p" component="p" gutterBottom id="modal-modal-description">
            You will be notified via email once your account is approved. We appreciate your patience! &emsp;
            <Link href="/" variant="body2" className={classes.formLink}>
              Click Here
            </Link>
          </Typography>
        </Box>
      )}
      {loading && <LoadingIcon />}
    </Box>
  );
}
