import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(NavLink)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  list-style: none;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    background: transparent linear-gradient(90deg, #47d7ac 0%, #4a90e200 100%) 0% 0% no-repeat padding-box;
    color: #fff !important;
    opacity: 1 !important;
    border-left: 4px solid #47d7ac;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  color: #fff !important;
  margin-left: 16px;
`;

const DropdownLink = styled(NavLink)`
  padding: 10px 15px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 14px;

  &:hover {
    background: transparent linear-gradient(90deg, #47d7ac 0%, #4a90e200 100%) 0% 0% no-repeat padding-box;
    cursor: pointer;
    color: #fff !important;
    opacity: 1 !important;
  }
`;

const SideBarSubMenu = ({ item, isOpen, level }) => {
  const [subnav, setSubnav] = useState(false);
  const [subnav_l2, setSubnav_l2] = useState(false);
  const [mdmSubnav, setMdmSubnav] = useState(false);
  const [subnavMA, setSubnavMA] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  const showSubnav_l2 = () => setSubnav_l2(!subnav_l2);
  const showMdmSubnav = () => setMdmSubnav(!mdmSubnav);
  const showSubnavMA = () => setSubnavMA(!subnavMA);

  return (
    <>
      {level === 0 && (
        <>
          <SidebarLink
            to={item.path}
            onClick={item.subNav && showSubnav}
            className={({ isActive }) => (isActive ? 'active-style' : 'none')}
          >
            <div>
              {item.icon}
              <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>{item.name}</SidebarLabel>
            </div>
            <div>{item.subNav && subnav ? item.dropDownOpened : item.subNav ? item.dropDownClosed : null}</div>
          </SidebarLink>
        </>
      )}
      {level === 1 && (
        <>
          <SidebarLink
            to={item.path}
            onClick={item.subNav && showSubnav}
            className={({ isActive }) => (isActive ? 'active-style' : 'none')}
          >
            <div>
              {item.icon}
              <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>{item.name}</SidebarLabel>
            </div>
            <div>{item.subNav && subnav ? item.dropDownOpened : item.subNav ? item.dropDownClosed : null}</div>
          </SidebarLink>
          {subnav &&
            item.subNav.map((item, index) => {
              return (
                <DropdownLink
                  to={item.path}
                  key={index}
                  className={({ isActive }) => (isActive ? 'active-style' : 'none')}
                  style={{ paddingLeft: isOpen ? '2rem' : '1rem' }}
                >
                  {item.icon}
                  <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>{item.name}</SidebarLabel>
                </DropdownLink>
              );
            })}
        </>
      )}
      {level === 2 && (
        <>
          <SidebarLink
            to={item.path}
            onClick={item.subNav && showSubnav}
            className={({ isActive }) => (isActive ? 'active-style' : 'none')}
          >
            <div>
              {item.icon}
              <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>{item.name}</SidebarLabel>
            </div>
            <div>{item.subNav && subnav ? item.dropDownOpened : item.subNav ? item.dropDownClosed : null}</div>
          </SidebarLink>
          {subnav &&
            item.subNav.map((ele, index) => {
              if (ele.level === 0) {
                return (
                  <DropdownLink
                    to={ele.path}
                    key={index}
                    className={({ isActive }) => (isActive ? 'active-style' : 'none')}
                    style={{ paddingLeft: isOpen ? '2rem' : '1rem' }}
                  >
                    {ele.icon}
                    <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>{ele.name}</SidebarLabel>
                  </DropdownLink>
                );
              }
              if (ele.level === 1 && ele.name === 'Modelling & Analytics') {
                return (
                  <>
                    <SidebarLink
                      to={ele.path}
                      onClick={ele.subNav && showSubnavMA}
                      className={({ isActive }) => (isActive ? 'active-style' : 'none')}
                    >
                      <div style={{ paddingLeft: '1rem' }}>
                        {ele.icon}
                        <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>{ele.name}</SidebarLabel>
                      </div>
                      <div>{ele.subNav && subnavMA ? ele.dropDownOpened : ele.subNav ? ele.dropDownClosed : null}</div>
                    </SidebarLink>
                    {subnavMA &&
                      ele.subNav.map((item2, index) => {
                        return (
                          <DropdownLink
                            to={item2.path}
                            key={index}
                            className={({ isActive }) => (isActive ? 'active-style' : 'none')}
                            style={{ paddingLeft: isOpen ? '3rem' : '2rem' }}
                          >
                            {item2.icon}
                            <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>
                              {item2.name}
                            </SidebarLabel>
                          </DropdownLink>
                        );
                      })}
                  </>
                );
              }
              if (ele.level === 1 && ele.name === 'Insights') {
                return (
                  <>
                    <SidebarLink
                      to={ele.path}
                      onClick={ele.subNav && showSubnav_l2}
                      className={({ isActive }) => (isActive ? 'active-style' : 'none')}
                    >
                      <div style={{ paddingLeft: '1rem' }}>
                        {ele.icon}
                        <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>{ele.name}</SidebarLabel>
                      </div>
                      <div>{ele.subNav && subnav_l2 ? ele.dropDownOpened : ele.subNav ? ele.dropDownClosed : null}</div>
                    </SidebarLink>
                    {subnav_l2 &&
                      ele.subNav.map((item2, index) => {
                        return (
                          <DropdownLink
                            to={item2.path}
                            key={index}
                            className={({ isActive }) => (isActive ? 'active-style' : 'none')}
                            style={{ paddingLeft: isOpen ? '3rem' : '2rem' }}
                          >
                            {item2.icon}
                            <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>
                              {item2.name}
                            </SidebarLabel>
                          </DropdownLink>
                        );
                      })}
                  </>
                );
              }
              if (ele.level === 1 && ele.name === 'XPerience360') {
                return (
                  <>
                    <SidebarLink
                      to={ele.path}
                      onClick={ele.subNav && showMdmSubnav}
                      className={({ isActive }) => (isActive ? 'active-style' : 'none')}
                    >
                      <div style={{ paddingLeft: '1rem' }}>
                        {ele.icon}
                        <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>{ele.name}</SidebarLabel>
                      </div>
                      <div>{ele.subNav && mdmSubnav ? ele.dropDownOpened : ele.subNav ? ele.dropDownClosed : null}</div>
                    </SidebarLink>
                    {mdmSubnav &&
                      ele.subNav.map((item2, index) => {
                        return (
                          <DropdownLink
                            to={item2.path}
                            key={index}
                            className={({ isActive }) => (isActive ? 'active-style' : 'none')}
                            style={{ paddingLeft: isOpen ? '3rem' : '2rem' }}
                          >
                            {item2.icon}
                            <SidebarLabel style={{ display: isOpen ? 'inline-block' : 'none' }}>
                              {item2.name}
                            </SidebarLabel>
                          </DropdownLink>
                        );
                      })}
                  </>
                );
              }
            })}
        </>
      )}
    </>
  );
};

export default SideBarSubMenu;
