import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom/dist';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { createStyles, withStyles } from '@mui/styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import { getSnowflakeLogsData } from "../../../api's/LogsApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'none !important',
    color: '#8798AD',
    padding: '10px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '15px',
    '&:first-of-type': {
      maxWidth: '30vw',
      wordBreak: 'break-all',
    },
    textAlign: 'center',
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid red',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px #0000000A',
      borderRadius: '5px !important',
    },
  })
)(TableRow);

const SnowflakeLogs = ({ headerTableData }) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const rowsPerPage = 20;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    const fetchSnowflakeDetailedLogs = async () => {
      try {
        setLoading(true);
        const response = await getSnowflakeLogsData(headerTableData.prefix);
        if (response.status === 200) {
          setTableData(response.data.data);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.status === 500) {
          enqueueSnackbar('500 Internal Server Error!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (error.response?.status === 404) {
          enqueueSnackbar('404 No Logs found!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else {
          enqueueSnackbar('Something went wrong!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
      setLoading(false);
    };

    fetchSnowflakeDetailedLogs();
  }, []);

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        startIcon={<MdArrowBackIosNew />}
        onClick={() => navigate(-1)}
        sx={{ color: '#43425D', border: '1px solid #43425D', background: '#fff', mb: 4 }}
      >
        Back
      </Button>

      <TableContainer>
        <Table
          aria-label="simple table"
          style={{ minWidth: 650, borderSpacing: '0px 4px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>LOGS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length <= 0
              ? !loading && 'No logs found'
              : tableData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.STATUS}</StyledTableCell>
                    <StyledTableCell>{row.LOGS}</StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <PaginationFooter tableData={tableData} page={page} handleChangePage={handleChangePage} /> */}
      {loading && <LoadingIcon />}
    </Box>
  );
};

export default SnowflakeLogs;
