import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const saveInChainWorkflowApi = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/inchain-workflows`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
      //   params: { domain_id },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getAllInChainWorkflowApi = async () => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/inchain-workflows`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};
