import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import AdminPage from '../pages/AdminPage';
import ApprovalPage from '../pages/ApprovalPage';
import ClientParent from '../pages/onboard/client/ClientParent';
import ClientPage from '../pages/ClientPage';
import ConnectionsPage from '../pages/ConnectionsPage';
import DashboardPage from '../pages/DashboardPage';
import EngagementPage from '../pages/EngagementPage';
import ManagementPage from '../pages/ManagementPage';
import WorkflowPage from '../pages/WorkflowPage';
import WorkflowParent from '../pages/onboard/workflow/WorkflowParent';
import ViewLogs from '../components/view-logs/ViewLogs';
import DetailedLogs from '../components/logs/DetailedLogs';
import ChatGPT from '../components/chat_gpt/ChatGPT';
import EmrLogCluster from '../components/logs/emr-logs/EmrLogCluster';
import Login from '../pages/Login';
import LogDetails from '../components/logs/azure-logs/LogDetails';
import Sidebar from '../components/sidebar/Sidebar';
import PrivateRoute from './PrivateRoute';
import ChatGPTQuery from '../components/chat_gpt/ChatGPTQuery';
import RenderHtml from '../components/RenderHtml';
import UploadFiles from '../components/chat_gpt/UploadFiles';
import AboutModal from '../components/AboutModal';
import LogsAnalyticsData from '../components/logs/azure-logs/LogsAnalyticsData';
import SnowflakeLogs from '../components/logs/snowflake-logs/SnowflakeLogs';
import DomainPage from '../pages/DomainPage';
import DomainDetails from '../pages/domain-details/DomainDetails';
import ClientDetails from '../pages/client-details/ClientDetails';
import EngagementDetails from '../pages/engagement-details/EngagementDetails';
import ChatGPTFs from '../components/chat_gpt/ChatGPTFs';
import LLMPage from '../pages/LLMPage';
import ReportingPage from '../pages/ReportingPage';
import ChatGPTDocumentBot from '../components/chat_gpt/ChatGPTDocumentBot';

export default function Router() {
  const location = useLocation();

  return useRoutes([
    { path: '/', element: <Login /> },
    {
      path: '/dashboard',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <DashboardPage /> }],
        },
      ],
    },
    {
      path: '/domain',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <DomainPage /> },
            { path: 'view_details', element: <DomainDetails headerTableData={location.state} /> },
            // { path: 'onboarding', element: <ClientParent /> },
          ],
        },
      ],
    },
    {
      path: '/client',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <ClientPage /> },
            { path: 'view_details', element: <ClientDetails headerTableData={location.state} /> },
            { path: 'onboarding', element: <ClientParent /> },
          ],
        },
      ],
    },
    {
      path: '/workflow-data',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage /> },
            { path: 'onboarding', element: <WorkflowParent headerTableData={location.state} /> },
            { path: 'logs/data-processor', element: <ViewLogs headerTableData={location.state} /> },
            { path: 'logs/data-processor/details', element: <LogDetails headerTableData={location.state} /> },
            { path: 'logs/rule-engine', element: <ViewLogs headerTableData={location.state} /> },
            { path: 'logs/rule-engine/details', element: <LogDetails headerTableData={location.state} /> },
            { path: 'logs/detailed-logs', element: <DetailedLogs headerTableData={location.state} /> },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} />,
            },
            { path: 'logs/emr-logs/:batch/:job/:cluster', element: <EmrLogCluster /> },
            { path: 'profiling', element: <RenderHtml /> },
          ],
        },
      ],
    },
    {
      path: '/workflow-data-copy',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="data-copy" /> },
            {
              path: 'onboarding',
              element: <WorkflowParent headerTableData={location.state} processType="data-copy" />,
            },
            {
              path: 'logs/data-processor',
              element: <ViewLogs headerTableData={location.state} processType="data-copy" />,
            },
            {
              path: 'logs/data-processor/details',
              element: <LogDetails headerTableData={location.state} processType="data-copy" />,
            },
            {
              path: 'logs/rule-engine',
              element: <ViewLogs headerTableData={location.state} processType="data-copy" />,
            },
            {
              path: 'logs/rule-engine/details',
              element: <LogDetails headerTableData={location.state} processType="data-copy" />,
            },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} processType="data-copy" />,
            },
            {
              path: 'logs/snowflake-logs/detailed-logs',
              element: <SnowflakeLogs headerTableData={location.state} />,
            },
            {
              path: 'logs/detailed-logs',
              element: <DetailedLogs headerTableData={location.state} processType="data-copy" />,
            },
            { path: 'logs/emr-logs/:batch/:job/:cluster', element: <EmrLogCluster processType="data-copy" /> },
          ],
        },
      ],
    },
    {
      path: '/workflow-ingestion',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="Ingestion" /> },
            {
              path: 'onboarding',
              element: <WorkflowParent headerTableData={location.state} processType="Ingestion" />,
            },
            {
              path: 'logs/data-processor',
              element: <ViewLogs headerTableData={location.state} processType="Ingestion" />,
            },
            {
              path: 'logs/data-processor/details',
              element: <LogDetails headerTableData={location.state} processType="Ingestion" />,
            },
            {
              path: 'logs/rule-engine',
              element: <ViewLogs headerTableData={location.state} processType="Ingestion" />,
            },
            {
              path: 'logs/rule-engine/details',
              element: <LogDetails headerTableData={location.state} processType="Ingestion" />,
            },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} processType="Ingestion" />,
            },
            {
              path: 'logs/snowflake-logs/detailed-logs',
              element: <SnowflakeLogs headerTableData={location.state} />,
            },
            {
              path: 'logs/detailed-logs',
              element: <DetailedLogs headerTableData={location.state} processType="Ingestion" />,
            },
            { path: 'logs/emr-logs/:batch/:job/:cluster', element: <EmrLogCluster processType="Ingestion" /> },
          ],
        },
      ],
    },
    {
      path: '/workflow-data-transformation',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="data-transformation" /> },
            {
              path: 'onboarding',
              element: <WorkflowParent headerTableData={location.state} processType="data-transformation" />,
            },
            {
              path: 'logs/data-processor',
              element: <ViewLogs headerTableData={location.state} processType="data-transformation" />,
            },
            {
              path: 'logs/data-processor/details',
              element: <LogDetails headerTableData={location.state} processType="data-transformation" />,
            },
            {
              path: 'logs/rule-engine',
              element: <ViewLogs headerTableData={location.state} processType="data-transformation" />,
            },
            {
              path: 'logs/rule-engine/details',
              element: <LogDetails headerTableData={location.state} processType="data-transformation" />,
            },
            {
              path: 'logs/detailed-logs',
              element: <DetailedLogs headerTableData={location.state} processType="data-transformation" />,
            },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} processType="data-transformation" />,
            },
            {
              path: 'logs/snowflake-logs/detailed-logs',
              element: <SnowflakeLogs headerTableData={location.state} />,
            },
            {
              path: 'logs/emr-logs/:batch/:job/:cluster',
              element: <EmrLogCluster processType="data-transformation" />,
            },
          ],
        },
      ],
    },
    {
      path: '/workflow-reporting',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="reporting" /> },
            {
              path: 'onboarding',
              element: <ReportingPage  headerTableData={location.state}  />,
            },
          ],
        },
      ],
    },
    {
      path: '/workflow-data-quality',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="data-quality" /> },
            {
              path: 'onboarding',
              element: <WorkflowParent headerTableData={location.state} processType="data-quality" />,
            },
            {
              path: 'logs/data-processor',
              element: <ViewLogs headerTableData={location.state} processType="data-quality" />,
            },
            {
              path: 'logs/data-processor/details',
              element: <LogDetails headerTableData={location.state} processType="data-quality" />,
            },
            {
              path: 'logs/rule-engine',
              element: <ViewLogs headerTableData={location.state} processType="data-quality" />,
            },
            {
              path: 'logs/rule-engine/details',
              element: <LogDetails headerTableData={location.state} processType="data-quality" />,
            },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} processType="data-quality" />,
            },
            {
              path: 'logs/detailed-logs',
              element: <DetailedLogs headerTableData={location.state} processType="data-quality" />,
            },
            { path: 'logs/emr-logs/:batch/:job/:cluster', element: <EmrLogCluster processType="data-quality" /> },
          ],
        },
      ],
    },
    {
      path: '/workflow-modelling-analytics',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="modelling-analytics" /> },
            {
              path: 'onboarding',
              element: <WorkflowParent headerTableData={location.state} processType="modelling-analytics" />,
            },
            {
              path: 'logs/data-processor',
              element: <ViewLogs headerTableData={location.state} processType="modelling-analytics" />,
            },
            {
              path: 'logs/data-processor/details',
              element: <LogDetails headerTableData={location.state} processType="modelling-analytics" />,
            },
            {
              path: 'logs/rule-engine',
              element: <ViewLogs headerTableData={location.state} processType="modelling-analytics" />,
            },
            {
              path: 'logs/rule-engine/details',
              element: <LogDetails headerTableData={location.state} processType="modelling-analytics" />,
            },
            {
              path: 'logs/detailed-logs',
              element: <DetailedLogs headerTableData={location.state} processType="modelling-analytics" />,
            },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} processType="modelling-analytics" />,
            },
            {
              path: 'logs/emr-logs/:batch/:job/:cluster',
              element: <EmrLogCluster processType="modelling-analytics" />,
            },
          ],
        },
      ],
    },
    {
      path: '/workflow-llm',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="llm" /> },
            {
              path: 'onboarding',
              element: <WorkflowParent headerTableData={location.state} processType="llm" />,
            },
            {
              path: 'logs/data-processor',
              element: <ViewLogs headerTableData={location.state} processType="llm" />,
            },
            {
              path: 'logs/data-processor/details',
              element: <LogDetails headerTableData={location.state} processType="llm" />,
            },
            {
              path: 'logs/rule-engine',
              element: <ViewLogs headerTableData={location.state} processType="llm" />,
            },
            {
              path: 'logs/rule-engine/details',
              element: <LogDetails headerTableData={location.state} processType="llm" />,
            },
            {
              path: 'logs/detailed-logs',
              element: <DetailedLogs headerTableData={location.state} processType="llm" />,
            },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} processType="llm" />,
            },
            {
              path: 'logs/emr-logs/:batch/:job/:cluster',
              element: <EmrLogCluster processType="llm" />,
            },
          ],
        },
      ],
    },
    {
      path: '/workflow-xperience360',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="xperience360" /> },
            {
              path: 'onboarding',
              element: <WorkflowParent headerTableData={location.state} processType="xperience360" />,
            },
            {
              path: 'logs/data-processor',
              element: <ViewLogs headerTableData={location.state} processType="xperience360" />,
            },
            {
              path: 'logs/data-processor/details',
              element: <LogDetails headerTableData={location.state} processType="xperience360" />,
            },
            {
              path: 'logs/rule-engine',
              element: <ViewLogs headerTableData={location.state} processType="xperience360" />,
            },
            {
              path: 'logs/rule-engine/details',
              element: <LogDetails headerTableData={location.state} processType="xperience360" />,
            },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} processType="xperience360" />,
            },
            {
              path: 'logs/detailed-logs',
              element: <DetailedLogs headerTableData={location.state} processType="xperience360" />,
            },
            { path: 'logs/emr-logs/:batch/:job/:cluster', element: <EmrLogCluster processType="xperience360" /> },
          ],
        },
      ],
    },
    {
      path: '/workflow-customer-segmentation',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <WorkflowPage processType="customer-segmentation" /> },
            {
              path: 'onboarding',
              element: <WorkflowParent headerTableData={location.state} processType="customer-segmentation" />,
            },
            {
              path: 'logs/data-processor',
              element: <ViewLogs headerTableData={location.state} processType="customer-segmentation" />,
            },
            {
              path: 'logs/data-processor/details',
              element: <LogDetails headerTableData={location.state} processType="customer-segmentation" />,
            },
            {
              path: 'logs/rule-engine',
              element: <ViewLogs headerTableData={location.state} processType="customer-segmentation" />,
            },
            {
              path: 'logs/rule-engine/details',
              element: <LogDetails headerTableData={location.state} processType="customer-segmentation" />,
            },
            {
              path: 'logs/log-analytics/detailed-logs',
              element: <LogsAnalyticsData headerTableData={location.state} processType="customer-segmentation" />,
            },
            {
              path: 'logs/detailed-logs',
              element: <DetailedLogs headerTableData={location.state} processType="customer-segmentation" />,
            },
            {
              path: 'logs/emr-logs/:batch/:job/:cluster',
              element: <EmrLogCluster processType="customer-segmentation" />,
            },
          ],
        },
      ],
    },
    {
      path: '/engagement',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [
            { path: '', element: <EngagementPage /> },
            { path: 'view_details', element: <EngagementDetails headerTableData={location.state} /> },
          ],
        },
      ],
    },
    {
      path: '/approval',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <ApprovalPage /> }],
        },
      ],
    },
    {
      path: '/llm',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <LLMPage /> }],
        },
      ],
    },
    {
      path: '/user-management',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <ManagementPage /> }],
        },
      ],
    },
    {
      path: '/chat-gpt-query',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar title="chatGptQuery" />,
          children: [{ path: '', element: <ChatGPT /> }],
        },
      ],
    },
    {
      path: '/chat-gpt-file-system',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar title="chatGptfileSystem" />,
          children: [{ path: '', element: <ChatGPTFs /> }],
        },
      ],
    },
    {
      path: '/chat-gpt',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar title="chatGpt" />,
          children: [{ path: '', element: <ChatGPTQuery /> }],
        },
      ],
    },
    {
      path: '/chat-gpt-document-bot',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <ChatGPTDocumentBot /> }],
        },
      ],
    },
    {
      path: '/upload-files',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <UploadFiles /> }],
        },
      ],
    },
    {
      path: '/admin-pannel',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <AdminPage /> }],
        },
      ],
    },
    {
      path: '/connections',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <ConnectionsPage /> }],
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    {
      path: '/about',
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <Sidebar />,
          children: [{ path: '', element: <AboutModal /> }],
        },
      ],
    },
  ]);
}
