import { Chip } from '@mui/material';
import React from 'react';

const RedChip = ({ title }) => {
  return (
    <Chip
      label={title}
      size="small"
      style={{
        color: '#CB0000',
        fontSize: '10px',
        backgroundColor: '#FFE7E0',
        fontWeight: 'bold',
      }}
    />
  );
};

export default RedChip;
