import React, { useContext, useState, useEffect, forwardRef } from 'react';
import { Box, Button, FormControl, IconButton, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MdDelete } from 'react-icons/md';
import useStyles from './styles';
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import { createPreprocessorApi, getPreprocessorById, runPreProcessorApi } from "../../api's/PreprocessorApi";
import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import { updateExecution } from "../../api's/ExecutionApi";
import { AuthContext } from '../../contexts/AuthProvider';

const Preprocessor = forwardRef((props, ref) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useContext(AuthContext);

  const { workflow, setIsDisabled } = useContext(WorkflowContext);
  const [fileInput, setFileInput] = useState('');
  const [extension, setExtension] = useState('ZIP');

  const [value, setValue] = useState([
    {
      fileInput: '',
      extension: 'ZIP',
    },
  ]);

  const handleAdd = () => {
    const newfield = { fileInput: '', extension: 'ZIP' };
    setValue([...value, newfield]);
  };

  const handleDelete = (i) => {
    const deleteValue = [...value];
    deleteValue.splice(i, 1);
    setValue(deleteValue);
  };

  const handleChange = (event, index) => {
    const data = [...value];
    data[index][event.target.name] = event.target.value;
    setValue(data);
    // if (event.target.value.length <= 0) {
    //   setIsDisabled(true);
    // }
  };

  const createPreprocessor = async () => {
    const regex = AWS_CONFIG_TYPE === true ? /^s3:\/\//i : /^https:\/\//i;

    let fileValid = true;
    value.map(async (data, i) => {
      if (!regex.test(data.fileInput)) {
        if (AWS_CONFIG_TYPE) {
          enqueueSnackbar('One of S3 path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else {
          enqueueSnackbar('One of ADLS gen2 path is invalid!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        fileValid = false;
      }
    });

    if (!fileValid) return;

    const data = {
      id: `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`,
      // extension,
      file_destination: `${workflow.engagement.client.client_name}/${workflow.engagement.engagement_name}/${workflow.workflow_name}/Pre_Processor/Output`,
      // file_input: fileInput,
      workflow_id: workflow.workflow_id,
      client_name: workflow.engagement.client.client_name,
      engagement_name: workflow.engagement.engagement_name,
      workflow_name: workflow.workflow_name,
      file_details: value,
      user_id: userId,
    };

    try {
      const response = await createPreprocessorApi(data);
      if (response.status === 201) {
        enqueueSnackbar('Saved Successfully!', {
          variant: 'Success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 403) {
        enqueueSnackbar('You dont have permission!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 500) {
        enqueueSnackbar(error.response.data || 'Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  ref.current.preprocessorFnRef = createPreprocessor;

  const pad2 = (n) => (n < 10 ? `0${n}` : n);

  async function runPreprocessor() {
    const id = `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`;

    let preprocessor;
    try {
      preprocessor = await getPreprocessorById(id);
    } catch (error) {
      enqueueSnackbar('404 Preprocessor not found!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    if (preprocessor.status === 200) {
      let trackingId = '';
      const date = new Date();
      trackingId = `${date.getFullYear().toString()}${pad2(date.getMonth() + 1).toString()}${pad2(
        date.getDate()
      )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(date.getSeconds())}${pad2(date.getMilliseconds())}`;

      const execution_id = `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}_preProcessor_${trackingId}`;

      const data = {
        workflow_id: workflow.workflow_id,
        execution_id,
        aws_config_type: AWS_CONFIG_TYPE,
        jsonArray: preprocessor.data.file_details,
        cloudEnvironment: workflow.cloudEnvironment,
      };

      const meta = {
        execution_id: execution_id.replaceAll(' ', ''),
        executed_by_id: userId,
        execution_engine: workflow.connectionName,
        workflow_type: 'Pre Processor',
      };
      try {
        await updateExecution(meta, workflow.workflow_id);

        const response = await runPreProcessorApi(data);

        if (response.status === 200) {
          enqueueSnackbar('Preprocessor is running!', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      } catch (error) {
        if (error.response.status === 403) {
          enqueueSnackbar('You have only Read Permission !!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar(error.response.data || 'Internal Server Error!!', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
    }
  }

  ref.current.runPreprocessorFnRef = runPreprocessor;

  const fetchPreprocessor = async () => {
    const id = `${workflow.engagement.client.client_name}_${workflow.engagement.engagement_name}_${workflow.workflow_name}`;
    console.log(id);
    try {
      const response = await getPreprocessorById(id);

      if (response.status === 200) {
        // setFileInput(response.data.file_input);
        // setExtension(response.data.extension);
        setValue(response.data.file_details);
      }
    } catch (error) {
      console.log(error);
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    fetchPreprocessor();
  }, []);

  useEffect(() => {
    if (value.map((item) => item.fileInput.length).includes(0)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [value]);

  return (
    <Paper elevation={3} className={classes.paper}>
      {value.map((data, i) => (
        <Box
          component="form"
          key={i}
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            variant="outlined"
            name="fileInput"
            label="Landing Zone Path"
            value={data.fileInput}
            className={classes.formTextField}
            onChange={(e) => handleChange(e, i)}
            required
            size="small"
            InputProps={{
              style: {
                fontWeight: 600,
                fontFamily: "'Roboto Slab', serif",
              },
            }}
            InputLabelProps={{
              style: { fontFamily: "'Roboto Slab', serif" },
            }}
          />
          <FormControl className={classes.formControl}>
            <Select
              labelId="select-file-extension"
              id="demo-simple-select-helper"
              name="extension"
              value={data.extension}
              onChange={(e) => handleChange(e, i)}
              fullWidth
              size="small"
              placeholder="File Extension"
              className={classes.formSelect}
              required
            >
              <MenuItem value={'ZIP'}>ZIP</MenuItem>
              <MenuItem value={'PDF'}>PDF</MenuItem>
              <MenuItem value={'TXT'}>TXT</MenuItem>
            </Select>
          </FormControl>
          {i !== 0 && (
            <IconButton aria-label="expand row" size="large" onClick={() => handleDelete(i)}>
              <MdDelete />
            </IconButton>
          )}
        </Box>
      ))}
      {/* <Typography variant="p" component="p" className={classes.formInputField}>
        Landing Zone Path
      </Typography>
      <TextField
        size="small"
        required
        id="clientName"
        name="clientName"
        autoComplete="clientName"
        value={fileInput}
        className={classes.formTextField}
        onChange={(e) => setFileInput(e.target.value)}
      />

      <Typography variant="p" component="p" className={classes.formInputField}>
        File Extension
      </Typography> */}
      {/* <FormControl className={classes.formControl}>
        <Select
          labelId="accessRights"
          id="demo-simple-select-helper"
          value={extension}
          onChange={(e) => setExtension(e.target.value)}
          fullWidth
          className={classes.formSelect}
          required
        >
          <MenuItem value={'ZIP'}>ZIP</MenuItem>
          <MenuItem value={'PDF'}>PDF</MenuItem>
        </Select>
      </FormControl> */}
      <Button
        variant="contained"
        sx={{
          display: 'flex',
          alignContent: 'flex-start',
          backgroundColor: '#00043C',
          '&:hover': { backgroundColor: '#00043C' },
          marginLeft: '6px',
        }}
        onClick={handleAdd}
      >
        Add
      </Button>
    </Paper>
  );
});

export default Preprocessor;
