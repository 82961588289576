// domain-feature

import React from 'react';
import './dashboardcards.css';
import { Col, Row } from 'antd';

const DashboardCards = ({ data, setTableType, tableType }) => {
  return (
    <>
      <div
        className="cards"
        style={{ boxShadow: `${tableType === data.type ? '10px 5px 20px #707070' : ''}` }}
        onClick={(e) => setTableType(data.type)}
        aria-hidden="true"
      >
        <Row>
          <Col span={24}>
            <div className="data-type">{data.type}</div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div className="cards-data-total cards-circle">
              <div className="cards-data-title">{data.total}</div>
              <div className="cards-data-total-text">Total</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="cards-data-approved cards-circle">
              <div className="cards-data-title">{data.approved}</div>
              <div className="cards-data-total-text">Approved</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="cards-data-pending cards-circle">
              <div className="cards-data-title">{data.pending}</div>
              <div className="cards-data-total-text">Pending</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
    // <div
    //   className="card"
    //   style={{ border: `${tableType === data.type ? '2px solid #13294B' : ''}` }}
    //   onClick={(e) => setTableType(data.type)}
    //   aria-hidden="true"
    // >
    //   <b>{data.type}</b>
    //   <div
    //     className="cards-comp total"
    //     style={{
    //       backgroundColor: `${
    //         data.type === 'Clients'
    //           ? '#d0d1ff'
    //           : data.type === 'Engagements'
    //           ? '#E5F3FF'
    //           : data.type === 'Workflows'
    //           ? '#fcefb4'
    //           : '#DFF2DF'
    //       }`,
    //     }}
    //   >
    //     <span className="card-title">{data.total}</span> Total {data.type}
    //   </div>
    //   <div className="cards-comp approved">
    //     <span className="card-title">{data.approved}</span> Approved {data.type}
    //   </div>
    //   <div className="cards-comp pending">
    //     <span className="card-title">{data.pending}</span> Pending {data.type}
    //   </div>
    // </div>
  );
};

export default DashboardCards;
