import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { makeStyles } from '@mui/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ArticleIcon from '@mui/icons-material/Article';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoadingIcon from '../../../reusable-component/LoadingIcon';
import { getContainerLogList } from "../../../api's/LogsApi";
import { getWorkflowById } from "../../../api's/WorkflowApi";

const useStyles = makeStyles({
  rightComponent: {
    flex: 4,
    overflowY: 'scroll',
    backgroundColor: '#e9ecef !important',
    maxHeight: '88vh',
    minHeight: '88vh',
    marginTop: '10px',
    marginRight: '10px',
    marginLeft: '10px',
    borderRadius: '10px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#a4a4a4',
      borderRadius: '10px',
    },
  },
});

const LogsList = ({ headerTableData, processType }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [folders, setfolders] = useState([]);
  const [files, setfiles] = useState([]);
  const [breadcrum, setBreadcurm] = useState([]);
  const [loadingIcon, setLoadingIcon] = useState(false);

  const getLogList = async (prefix) => {
    setLoadingIcon(true);
    try {
      const response = await getContainerLogList(prefix);

      if (response.status === 200) {
        const arrFolder = [];
        const arrFile = [];
        response.data.map((el) => {
          if (el.isDirectory) {
            arrFolder.push(el);
          } else {
            arrFile.push(el);
          }
        });
        setfolders(arrFolder);
        setfiles(arrFile);
      }
    } catch (error) {
      setfolders([]);
      setfiles([]);
    }
    setLoadingIcon(false);
  };

  const getLogDetails = async (prefix, workflow_name) => {
    if (location.pathname.includes('rule-engine')) {
      navigate(`/workflow-${processType}/logs/rule-engine/details`, {
        state: {
          workflow_id: headerTableData.workflow_id,
          workflow_name: headerTableData.workflow_name,
          client_name: headerTableData.client_name,
          engagement_name: headerTableData.engagement_name,
          execution_mode: headerTableData.execution_mode,
          created_by: headerTableData.created_by,
          created_at: headerTableData.created_at,
          status: headerTableData.status,
          prefix,
          from: 'rule-engine',
        },
      });
    } else {
      navigate(`/workflow-${processType}/logs/data-processor/details`, {
        state: {
          workflow_id: headerTableData.workflow_id,
          workflow_name: headerTableData.workflow_name,
          client_name: headerTableData.client_name,
          engagement_name: headerTableData.engagement_name,
          execution_mode: headerTableData.execution_mode,
          created_by: headerTableData.created_by,
          created_at: headerTableData.created_at,
          status: headerTableData.status,
          prefix,
          from: 'data-processor',
        },
      });
    }
  };

  const fetchWorkflow = async () => {
    const response = await getWorkflowById(headerTableData.workflow_id);

    if (response.status === 200) {
      const data = response.data;
      let value;
      if (location.pathname.includes('rule-engine')) {
        value = `RuleEngineLogs/${data.engagement.client.client_name}/${data.engagement.engagement_name}/${data.workflow_name}`;
      } else {
        value = `DataProcessorLogs/${data.engagement.client.client_name}/${data.engagement.engagement_name}/${data.workflow_name}`;
      }
      const label = `${data.engagement.client.client_name}/${data.engagement.engagement_name}/${data.workflow_name}`;

      setBreadcurm([{ label, value, isDirectory: true }]);
      getLogList(value);
    }
  };

  useEffect(() => {
    fetchWorkflow();
  }, []);

  const prevBreadcrum = (index, prefix) => {
    const arr = breadcrum;
    arr.splice(index + 1);
    setBreadcurm(arr);
    getLogList(prefix);
  };
  return (
    <>
      <Box className={classes.rightComponent}>
        <Box
          sx={{
            m: 2,
            backgroundColor: '#fff',
            borderRadius: '10px',
            p: 2,
          }}
        >
          {/* <Link to={prevPage}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <ChevronLeftIcon fontSize="small" /> Back
            </div>
          </Link> */}
          <Breadcrumb style={{ marginBottom: '20px' }}>
            {breadcrum.map((el, i) => {
              return (
                <Breadcrumb.Item key={i}>
                  <a
                    role="button"
                    tabIndex={i}
                    onClick={() => {
                      console.log(i, el);
                      //   getLogList(el.value);
                      prevBreadcrum(i, el.value);
                    }}
                    onKeyDown={() => {
                      console.log(i, el);
                      //   getLogList(el.value);
                      prevBreadcrum(i, el.value);
                    }}
                  >
                    {el.label}
                  </a>
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
          {loadingIcon && <LoadingIcon />}
          {!loadingIcon && folders.length === 0 && files.length === 0 && <h2>No Logs Found !!!</h2>}
          {files &&
            files.map((file, i) => {
              return (
                <a
                  key={i}
                  download
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    getLogDetails(file.value, headerTableData.workflow_name);
                    // console.log(`https://cdep.s3.amazonaws.com/${subfolders.value}${file.label}`);
                  }}
                  onKeyDown={() => {
                    getLogDetails(file.value, headerTableData.workflow_name);
                    // console.log(`https://cdep.s3.amazonaws.com/${subfolders.value}${file.label}`);
                  }}
                >
                  <a
                    style={{
                      padding: '10px',
                      borderRadius: '5px',
                      backgroundColor: '#F6F8E4',
                      marginBottom: '10px',
                      cursor: 'pointer',
                      color: '#000',
                      display: 'flex',
                      alignItems: 'center',
                      // textDecoration: 'none',
                    }}
                    /* eslint-disable react/jsx-no-target-blank */
                  >
                    {/* <a href={`https://cdep.s3.amazonaws.com/${subfolders.value}${file.label}`} target="_blank"> */}
                    <ArticleIcon />
                    {file.label}
                    {/* </a> */}
                  </a>
                </a>
              );
            })}

          {folders &&
            folders.map((folder, i) => {
              return (
                <a
                  key={i}
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    getLogList(folder.value);
                    setBreadcurm((prev) => [...prev, folder]);
                  }}
                  onKeyDown={() => {
                    getLogList(folder.value);
                    setBreadcurm((prev) => [...prev, folder]);
                  }}
                  style={{
                    padding: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#F6F8E4',
                    marginBottom: '10px',
                    cursor: 'pointer',
                    color: '#aaa',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FolderIcon />
                  {folder.label}
                </a>
              );
            })}
        </Box>
      </Box>
    </>
  );
};

export default LogsList;
