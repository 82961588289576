import React from 'react';
import SideBarSubMenu from './SideBarSubMenu';

const SideBarLevel = ({ item, isOpen }) => {
  return (
    <>
      {item.level === 0 && <SideBarSubMenu item={item} isOpen={isOpen} level={0} />}
      {item.level === 1 && (
        <>
          <SideBarSubMenu item={item} isOpen={isOpen} level={1} />
        </>
      )}
      {item.level === 2 && <SideBarSubMenu item={item} isOpen={isOpen} level={2} />}
    </>
  );
};

export default SideBarLevel;
