import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Typography, Box } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';
import { Row, Col } from 'antd';
import { GrTable } from 'react-icons/gr';
import { HiOutlineTableCells } from 'react-icons/hi2';
import { IoBarChartSharp } from 'react-icons/io5';
import { ImTable2 } from 'react-icons/im';
import { BsFillBarChartLineFill } from 'react-icons/bs';
import { FaChartPie } from 'react-icons/fa';
import { RiLineChartLine } from 'react-icons/ri';
import { AuthContext } from '../contexts/AuthProvider';
import { WorkflowContext } from '../contexts/WorkflowProvider';
import { upsertTileSequence } from "../api's/ReportingApi";

export default function RearrangeModal({
  dataset,
  sequence,
  handleCloseModal,
  openRearrangeModal,
  getAllCharts,
  handleOnSaveSequence,
}) {
  const { userId } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [tileValue, setTileValue] = useState('graph');
  const [newSequence, setNewSequence] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  //   console.log('data: ', data);
  const [selectReportIdObj, setSelectReportIdObj] = useState('');
  const [selectGraphIdObj, setSelectGraphIdObj] = useState('');
  const [tileTitle, setTileTitle] = useState('');
  const [selectChart, setSelectChart] = useState();
  const ChartsList = ['Line Chart', 'Bar Chart', 'Pie Chart', 'Histograms'];
  const { workflow } = useContext(WorkflowContext);
  const [data, setData] = useState([]);

  const nameRegex = /^[a-zA-Z0-9 ]{1,35}$/;
  //   console.log({ newSequence });
  const handleOnCancel = () => {
    handleCloseModal();
    setSelectChart('');
    setSelectGraphIdObj('');
    setTileTitle('');
    setSelectReportIdObj('');
  };

  useEffect(() => {
    if (dataset) {
      // const newData = [...dataset];
      // setData(newData);
      // setData((dataset) => dataset.filter((tile) => tile.chart_type !== 'Stats'));
      const newData = dataset.filter((tile) => tile.chart_type !== 'Stats');
      setData(newData);
    }
  }, [dataset]);

  useEffect(() => {});

  const handleSetCloud = (event) => {
    setTileValue(event.target.value);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    const sourceIndex = e.dataTransfer.getData('text/plain');
    const newData = [...data];
    const [draggedItem] = newData.splice(sourceIndex, 1);
    newData.splice(targetIndex, 0, draggedItem);
    setData(newData);
  };

  // console.log('data: ', data);

  return (
    <>
      <Dialog
        open={openRearrangeModal}
        onClose={handleOnCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ color: '#43425D', display: 'flex', justifyContent: 'center' }}>Rearrange Tiles</DialogTitle>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', padding: '0 16px' }}>
          {data
            // ?.filter((item) => item.chart_type !== 'Stats')
            // ?
            .map((item, index) => (
              <div
                key={index}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, index)}
                style={{
                  backgroundColor: '#f6f6f6',
                  border: '1px solid grey',
                  borderRadius: '5px',
                  padding: '5px',

                  // margin: '5px',
                  cursor: 'grab',
                }}
              >
                {/* {item} */}

                <div key={item?.id} id={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginLeft: '10px', marginRight: '15px' }}>
                    {item?.chart_type === 'Bar Chart' && <IoBarChartSharp style={{ color: '#3498DB ' }} />}
                    {item?.chart_type === 'Pie Chart' && <FaChartPie style={{ color: 'brown' }} />}
                    {item?.chart_type === 'Table' && <ImTable2 style={{ color: '#1E8449' }} />}
                    {item?.chart_type === 'Line Chart' && <RiLineChartLine style={{ color: '#5B2C6F' }} />}
                    {item?.chart_type === 'Histograms' && <BsFillBarChartLineFill style={{ color: '#FF7F50' }} />}
                  </div>

                  <div>
                    <h4>{item?.title}</h4>
                    <h6>{item?.chart_type === 'Table' ? 'Report' : item?.chart_type}</h6>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <DialogActions>
          <Button
            onClick={handleOnCancel}
            variant="outlined"
            sx={{ textTransform: 'capitalize', color: '#43425D', border: '1px solid #43425D' }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleOnSaveSequence(null, data);
              handleOnCancel();
            }}
            variant="contained"
            sx={{ backgroundColor: '#00043C', textTransform: 'capitalize', '&:hover': { backgroundColor: '#00043C' } }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
