import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { Image, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { BsTextIndentLeft, BsTextIndentRight } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import { Outlet } from 'react-router-dom';
import UploadModal from '../chat_gpt/UploadModal';

import { AWS_CONFIG_TYPE } from '../../BaseUrl';
import large_icon from '../../assets/images/large_icon.png';
import small_icon from '../../assets/images/small_icon.png';
import { AuthContext } from '../../contexts/AuthProvider';
import { ChatGPTContext } from '../../contexts/ChatGPTProvider';
import { menuItem, menuItemV1 } from '../../data/SidebarMenuItem';
import AWS_LOGO from '../../images/logoAWS.png';
import AZURE_LOGO from '../../images/logoAzure.png';
import themeStyles from '../../utils/theme';
import AddConnectionModal from '../chat_gpt/AddConnectionModal';
import SideBarLevel from './SideBarLevel';
import SideBarSubMenu from './SideBarSubMenu';
import './sidebar.css';

const settings = ['Logout'];
const AWS = 'AWS';
const AZURE = 'AZURE';
const SNOWFLAKE = 'SNOWFLAKE';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme?.palette?.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const Sidebar = ({ title }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleIcon = () => setIsOpen(!isOpen);

  const themeClasses = themeStyles();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openConnectionModal, setOpenConnectionModal] = useState(false);
  const { userRole, clientName, logoutUser, isDep } = useContext(AuthContext);
  const { chatGPTModel, setChatGPTModel, chatGPTContext, setChatGPTContext, file, setFile } =
    useContext(ChatGPTContext);

  // const theme = process.env.REACT_APP_THEME;
  const theme = clientName === 'DEP' ? 'nagarro' : clientName === 'GT' ? 'gt' : null;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logoutUser();
  };

  const handleContextChange = (e) => {
    setChatGPTContext(e.target.value);
    setFile('All');
  };

  const handleUploadPDF = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenAddNewConnectionModal = () => {
    setOpenConnectionModal(true);
  };

  const handleCloseAddNewConnectionModal = () => {
    setOpenConnectionModal(false);
  };

  const version = JSON.parse(sessionStorage.getItem('version'));

  const handleMenuItem = () => {
    if (version === '2.0') {
      if (userRole === 'SUPER_USER' || userRole === 'ADMIN') {
        // return menuItem.map((item, index) => <SideBarSubMenu item={item} key={index} isOpen={isOpen} />);
        return menuItem.map((item, index) => <SideBarLevel item={item} key={index} isOpen={isOpen} />);
      }
      return menuItem.map(
        (item, index) => item.name !== 'Admin Console' && <SideBarLevel item={item} key={index} isOpen={isOpen} />
      );
    }
    return menuItemV1.map((item, index) => <SideBarSubMenu item={item} key={index} isOpen={isOpen} />);
  };

  const renderMenuItem = handleMenuItem();

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    if (windowSize.width < 769) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="container">
      <div style={{ width: isOpen ? '240px' : '60px' }} className="sidebar">
        <div
          className="top-section"
          style={{
            padding: isOpen ? '20px 30px 10px 15px' : ' 20px 10px',
            position: 'fixed',
            zIndex: 9999,
            backgroundColor: '#00043C',
            width: isOpen ? '220px' : '50px',
          }}
        >
          <div className="logo">
            {isOpen ? <img src={large_icon} alt="logo" width="100" /> : <img src={small_icon} alt="logo" width="30" />}
          </div>
        </div>
        {/* {menuItem.map((item, index) =>
          userRole === 'Admin' ? (
            <SideBarSubMenu item={item} key={index} isOpen={isOpen} />
          ) : (
            item.name !== 'Admin Console' && <SideBarSubMenu item={item} key={index} isOpen={isOpen} />
          )
        )} */}
        <div className="sidebar-menu-block">
          <div className="sidebar-menu-content">{renderMenuItem}</div>
        </div>

        <footer
          style={{
            position: 'fixed',
            bottom: 0,
            padding: '20px 0 20px 20px',
            fontSize: '12px',
            fontWeight: 'bold',
            zIndex: 11,
            width: isOpen ? '200px' : '50px',
            backgroundColor: '#00043C',
            borderRadius: '0 0 200px 0',
          }}
        >
          {` DEP v${version}`}
        </footer>
        {/* {menuItem.map(
          (item, index) =>
            (item.name === 'Talk to Document' || item.name === 'Talk to DB') && (
              <SideBarSubMenu item={item} key={index} isOpen={isOpen} />
            )
        )} */}
        {/* {menuItem.map((el, index) => (
          <NavLink to={el.path} key={index} className="link" activeClassName="active">
            <div className="icon">{el.icon}</div>
            <div className="link-text" style={{ display: isOpen ? 'block' : 'none' }}>
              {el.name}
            </div>
          </NavLink>
        ))} */}
      </div>
      <main className="main" style={{ marginLeft: isOpen ? '240px' : '60px' }}>
        <div className="topbar">
          <div className="toggle">
            {isOpen ? (
              <BsTextIndentRight onClick={toggleIcon} style={{ fontWeight: '600' }} />
            ) : (
              <BsTextIndentLeft onClick={toggleIcon} style={{ fontWeight: '600' }} />
            )}
          </div>
          <h1>{isDep ? `Data Engineering Platform` : 'XPerience360'}</h1>
          <div className="avatar">
            {/* <div className="notification">
              <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                <Badge variant="dot" color="error">
                  <FaBell size={18} style={{ color: '#a4a4a4' }} />
                </Badge>
              </IconButton>
            </div> */}
            {/* {clientName === 'DEP' && (
              <Select
                labelId="model"
                id="model"
                value={chatGPTModel}
                onChange={(e) => setChatGPTModel(e.target.value)}
                fullWidth
                size="small"
                // className={classes.formSelect}
                required
              >
                <MenuItem value={'text-davinci-002'}>text-davinci-002</MenuItem>
                <MenuItem value={'text-davinci-003'}>text-davinci-003</MenuItem>
                <MenuItem value={'gpt-35-turbo'}>gpt-35-turbo</MenuItem>
              </Select>
            )} */}
            {/* {clientName === 'GT' && (
              <>
                <FormControl sx={{ minWidth: 220, mr: 2 }}>
                  <Select
                    labelId="context"
                    id="context"
                    value={chatGPTContext}
                    onChange={handleContextChange}
                    fullWidth
                    size="small"
                    // className={classes.formSelect}
                    required
                  >
                    <MenuItem value={'Setting Up Presence'}>Setting Up Presence</MenuItem>
                    <MenuItem value={'GST Compendium'}>GST Compendium</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 220, mr: 2 }}>
                  <Select
                    labelId="file"
                    id="file"
                    value={file}
                    onChange={(e) => setFile(e.target.value)}
                    fullWidth
                    size="small"
                    // className={classes.formSelect}
                    required
                  >
                    {chatGPTContext === 'Setting Up Presence'
                      ? settingUpPresence.map((item) => (
                          <MenuItem key={item.id} value={item.value}>
                            {item.value}
                          </MenuItem>
                        ))
                      : gstCompendium.map((item) => (
                          <MenuItem key={item.id} value={item.value}>
                            {item.value}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </>
            )} */}

            {title === 'chatGptQuery' && (
              <Button
                size="small"
                type="submit"
                variant="contained"
                sx={{
                  marginRight: '8px',
                  backgroundColor: '#00043C',
                  '&:hover': { backgroundColor: '#00043C' },
                  color: '#ffffff',
                }}
                onClick={handleOpenAddNewConnectionModal}
              >
                New Connection
              </Button>
            )}
            

            <Tooltip title="Cloud">
              <div className="topbar-cloud-image">
                <Image height={18} src={AWS_CONFIG_TYPE ? AWS_LOGO : AZURE_LOGO} preview={false} />
                {/* <Image height={18} src={AZURE_LOGO} preview={false} /> */}
                {/* <Image height={18} src={SNOWFLAKE_LOGO} preview={false} /> */}

                {/* <Image height={22} src={false ? AWS_LOGO : true ? AZURE_LOGO : SNOWFLAKE_LOGO} preview={false} /> */}
              </div>
            </Tooltip>
            {/* <Tooltip title="Cloud">
              <div className="topbar-cloud">{false ? AWS : true ? AZURE : SNOWFLAKE}</div>
            </Tooltip> */}

            <Divider orientation="vertical" flexItem />
            <div style={{ margin: '0 8px 0 15px' }}>
              {/* <GreenChip title={JSON.parse(sessionStorage.getItem('user'))} /> */}
              <Tag color="#87d068" style={{ padding: '0 10px 2px 10px', fontWeight: 'bold' }}>
                {JSON.parse(sessionStorage.getItem('full_name')) || ''}
                {/* {''} */}
              </Tag>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="user-icon">
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>
                      <FaUserCircle size={25} />
                    </Avatar>

                    {/* <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <FaUserCircle size={35} />
                    </StyledBadge> */}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center" onClick={handleLogout}>
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </div>
        <section style={{ padding: '20px' }}>
          <Outlet />
        </section>
        {openModal && <UploadModal openModal={openModal} handleCloseModal={handleCloseModal} />}
        {openConnectionModal && (
          <AddConnectionModal open={setOpenConnectionModal} close={handleCloseAddNewConnectionModal} />
        )}
      </main>
    </div>
  );
};

export default Sidebar;
