import { Stack } from '@mui/material';
import React from 'react';
import Plot from 'react-plotly.js';

const InsightsFetchGraphs = ({ fetchedGraphs }) => {
  return (
    <Stack direction="row" flexWrap="wrap">
      {fetchedGraphs
        .filter((item) => item.graph_type === 'bar' && item.barmode !== 'stack')
        .map((item) => (
          <Plot
            data={[
              {
                x: item.data?.xaxis_values,
                y: item.data?.yaxis_values,
                type: item.graph_type,
              },
            ]}
            layout={{
              width: 580,
              height: 480,
              title: { text: item.title, font: { size: 14 } },
              xaxis: {
                // autotypenumbers: 'strict',
                // tickmode: 'array',
                // tickvals: item.data?.xaxis_values,
                // ticktext: item.data?.xaxis_values,
                title: {
                  text: item.xaxis_name,
                  font: { size: 12 },
                },
              },
              yaxis: {
                title: {
                  text: item.yaxis_name,
                  font: { size: 12 },
                },
              },
              bargap: 0.3,
              // xaxis: { fixedrange: true },
              // yaxis: { fixedrange: true },
            }}
            config={{ displayModeBar: false }}
          />
        ))}
      {fetchedGraphs
        .filter((item) => item.graph_type === 'heatmap')
        .map((item) => (
          <Plot
            data={[
              {
                x: item.data?.xaxis_values,
                y: item.data?.yaxis_values,
                z: item.data?.zaxis_values,
                type: item.graph_type,
                colorscale: 'Viridis',
                hoverongaps: false,
                hoverinfo: 'none',
                hovertemplate: `${item.xaxis_name}: %{x}<br>${item.yaxis_name}: %{y}<br>${item.zaxis_name}: %{z}<extra></extra>`,
              },
            ]}
            layout={{
              width: 580,
              height: 480,
              title: { text: item.title, font: { size: 14 } },
              xaxis: {
                title: {
                  text: item.xaxis_name,
                  font: { size: 12 },
                },
              },
              yaxis: {
                title: {
                  text: item.yaxis_name,
                  font: { size: 12 },
                },
              },
              // xaxis: { fixedrange: true },
              // yaxis: { fixedrange: true },
            }}
            config={{ responsive: true, displayModeBar: false }}
          />
        ))}
      {fetchedGraphs
        .filter((item) => item.graph_type === 'scatter')
        .map((item) => (
          <Plot
            data={[
              {
                x: item.data?.xaxis_values,
                y: item.data?.yaxis_values,
                type: item.graph_type,
              },
            ]}
            layout={{
              width: 580,
              height: 480,
              title: { text: item.title, font: { size: 14 } },
              xaxis: {
                // autotypenumbers: 'strict',
                // tickmode: 'array',
                // tickvals: item.data?.xaxis_values,
                // ticktext: item.data?.xaxis_values,
                title: {
                  text: item.xaxis_name,
                  font: { size: 12 },
                },
              },
              yaxis: {
                title: {
                  text: item.yaxis_name,
                  font: { size: 12 },
                },
              },
              bargap: 0.3,
              // xaxis: { fixedrange: true },
              // yaxis: { fixedrange: true },
            }}
            config={{ displayModeBar: false }}
          />
        ))}
      {fetchedGraphs
        .filter((element) => element.barmode === 'stack')
        .map((graphData) => (
          <div key={graphData.id}>
            <Plot
              data={graphData.traces.map((trace) => ({
                x: trace.xaxis_values,
                y: trace.yaxis_values,
                name: trace.name,
                type: trace.graph_type,
              }))}
              layout={{
                title: graphData.title,
                xaxis: { title: graphData.xaxis_name },
                yaxis: { title: graphData.yaxis_name },
                barmode: graphData.barmode,
              }}
              config={{ displayModeBar: false }}
            />
          </div>
        ))}
    </Stack>
  );
};
export default InsightsFetchGraphs;
