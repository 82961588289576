import React, { useContext, useEffect, useState } from 'react';
import { Tree } from 'antd';
import { FolderFilled } from '@ant-design/icons';
import { useSnackbar } from 'notistack';
import { getAllClientsApi, getClientByUserGroup } from "../api's/ClientApi";
import { AuthContext } from '../contexts/AuthProvider';
import { getDirectoryData } from "../api's/ChatGPTApi";
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { WorkflowContext } from '../contexts/WorkflowProvider';

const baseAzureUrl = 'https://depazuredev.blob.core.windows.net/dep-develop';

const TreeViewDirectory = ({
  setSelectedFilePath,
  formField,
  setFormField,
  disableForm,
  setDisableForm,
  domainAndClient,
  setLoading
}) => {
  const { DirectoryTree } = Tree;
  const { enqueueSnackbar } = useSnackbar();
  //   const treeData = [
  //     {
  //       title: 'parent 0',
  //       key: '0-0',
  //       children: [
  //         {
  //           title: 'leaf 0-0',
  //           key: '0-0-0',
  //           isLeaf: true,
  //         },
  //         {
  //           title: 'leaf 0-1',
  //           key: '0-0-1',
  //           isLeaf: true,
  //         },
  //       ],
  //     },
  //     {
  //       title: 'parent 1',
  //       key: '0-1',
  //       children: [
  //         {
  //           title: 'leaf 1-0',
  //           key: '0-1-0',
  //           isLeaf: true,
  //         },
  //         {
  //           title: 'leaf 1-1',
  //           key: '0-1-1',
  //           isLeaf: true,
  //         },
  //       ],
  //     },
  //     {
  //       title: 'parent 2',
  //       key: '0-2',
  //       children: [],
  //     },
  //   ];
  const [treeData, setTreeData] = useState([]);
  const [allClient, setAllClient] = useState('');
  const { userRole, userGroup } = useContext(AuthContext);
  const { workflow, setWorkflow } = useContext(WorkflowContext);

  const onSelect = (keys, info) => {
    // DON'T REMOVE THIS COMMENT //  console.log('Trigger Select', keys, info);
    if (info.node.isLeaf === true && !disableForm) {
      // console.log(info.node.filepath);
      setSelectedFilePath(info.node.filepath);
      const lastSlashIndex = info.node.filepath.lastIndexOf('/');
      const pathWithoutFile = info.node.filepath.substring(0, lastSlashIndex);
      setFormField({
        ...formField,
        path: domainAndClient ? `${baseAzureUrl}/${pathWithoutFile}/`: info.node.filepath,
        innerPath: AWS_CONFIG_TYPE ? `${info.node.filepath}` : `dep-develop/${info.node.filepath}`,
      });
    }
    
    if (info.node.isLeaf === false && !disableForm) {
      setSelectedFilePath(info.node.filepath);
      setFormField({
        ...formField,

        path: `${baseAzureUrl}/${info.node.filepath}/`,
        innerPath: AWS_CONFIG_TYPE ? `${info.node.filepath}` : `dep-develop/${info.node.filepath}`,
      });
    }
  };

  const onExpand = (keys, info) => {
    // DON'T REMOVE THIS COMMENT // console.log('Trigger Expand', keys, info);
    if (info.expanded === true) {
      handleGetChildren(info.node.key, info.node.filepath);
    }
  };

  const handleGetChildren = async (key, filepath) => {
    const newTreeData = [...treeData];

    const data = {
      filepath,
      cloudEnvironment: workflow.cloudEnvironment,
    };
    setLoading(true);
    const response = await getDirectoryData(data);

    const updateTreeDataRecursively = (dataToUpdate, keyToUpdate, newData) => {
      for (let i = 0; i < dataToUpdate.length; i++) {
        if (dataToUpdate[i].key === keyToUpdate) {
          dataToUpdate[i].children = newData.map((item, idx) => ({
            key: `${keyToUpdate}-${idx}`,
            title: item.filename,
            filepath: item.filepath,
            isLeaf: !item.folder,
            icon: item.folder ? <FolderFilled style={{ color: '#ADD8E6' }} /> : null,
            children: [],
            ...item,
          }));
        } else if (dataToUpdate[i].children) {
          updateTreeDataRecursively(dataToUpdate[i].children, keyToUpdate, newData);
        }
      }
    };

    updateTreeDataRecursively(newTreeData, key, response.data);
    setTreeData(newTreeData);
    setLoading(false);
  };

  const getClient = async () => {
    const response = userRole === 'SUPER_USER' ? await getAllClientsApi() : await getClientByUserGroup(userGroup);
    if (response.status === 200) {
      const clients = response.data.filter((el) => el.status === 'approved');
      setAllClient(clients);
      const newTreeData = clients.map((client, index) => ({
        title: client.client_name,
        key: index.toString(),
        isLeaf: false,
        filepath: client.client_name,
        children: [],
        icon: <FolderFilled style={{ color: '#ADD8E6' }} />,
      }));
      setTreeData(newTreeData);
      // console.log({ clients });
    }
  };

  const showParentFolder = async (filepath) => {
    const data = {
      filepath,
      cloudEnvironment: workflow.cloudEnvironment,
    };
    try {
      setLoading(true);
      const response = await getDirectoryData(data);
      if (response.status === 200) {
        const engagements = response?.data?.map((item) => {
          const parts = item.filepath?.split('/');
          return parts.length > 2 ? parts[2] : null;
        });

        const newTreeData = engagements.map((engagement, index) => ({
          title: engagement,
          key: index.toString(),
          isLeaf: false,
          filepath: `${filepath}/${engagement}`,
          children: [],
          icon: <FolderFilled style={{ color: '#ADD8E6' }} />,
        }));
        setTreeData(newTreeData);
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar('Path does not exist!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    domainAndClient ? showParentFolder(domainAndClient) : getClient();
  }, []);

  return (
    <>
      <DirectoryTree
        style={{ fontSize: '18px' }}
        multiple
        defaultExpandAll={false}
        onSelect={onSelect}
        onExpand={onExpand}
        treeData={treeData}
      />
    </>
  );
};
export default TreeViewDirectory;
