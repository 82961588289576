export function convertToCamelCase(str) {
  return str
    .split(' ')
    .map((word, index) => {
      // If it is the first word make sure to lowercase all the chars.
      if (index === 0) {
        return word.toLowerCase();
      }
      // If it is not the first word only upper case the first char and lowercase the rest.
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

export function convertToPascalCase(str) {
  if (/^[\p{L}\d]+$/iu.test(str)) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str
    .replace(/([\p{L}\d])([\p{L}\d]*)/giu, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())
    .replace(/[^\p{L}\d]/giu, ' ')
    .trim();
}
