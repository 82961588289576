import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { IoBarChartSharp } from 'react-icons/io5';
import { FaBoxes, FaChartPie } from 'react-icons/fa';
import { ImTable2 } from 'react-icons/im';
import { RiLineChartLine } from 'react-icons/ri';
import { BiSolidDoughnutChart } from 'react-icons/bi';
import { BsFillBarChartLineFill } from 'react-icons/bs';
import { TableChart } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import {
  getGraphPlotDataByEngagementIdWorkflowId,
  getReportsDataByEngagementIdWorkflowId,
  saveTilesData,
} from "../api's/ReportingApi";
import { AuthContext } from '../contexts/AuthProvider';
import { WorkflowContext } from '../contexts/WorkflowProvider';
import { getWorkflowByUserGroupAndEngagementIdApi } from "../api's/WorkflowApi";

export default function AddChartsModal({ handleCloseModal, openModal, getAllCharts, handleOnSaveSequence, workflow }) {
  const { userId, userGroup } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [tileValue, setTileValue] = useState('graph');
  const [selectReportId, setSelectReportId] = useState('');
  const [selectGraphId, setSelectGraphId] = useState('');
  const [tileTitle, setTileTitle] = useState('');
  const [selectChart, setSelectChart] = useState();
  const [selectReport, setSelectReport] = useState();
  const ChartsList = ['Line Chart', 'Bar Chart', 'Pie Chart', 'Doughnut Chart', 'Histograms'];
  const ReportList = ['Table', 'Stats'];
  const [selectWorkflow, setSelectWorkflow] = useState();
  const [graphIdList, setGraphIdList] = useState([]);
  const [reportIdList, setReportIdList] = useState([]);
  const [reportData, setReportData] = useState();
  // const { workflow } = useContext(WorkflowContext);
  const [workflowList, setWorkflowList] = useState([]);
  const nameRegex = /^[a-zA-Z0-9 ]{1,35}$/;

  const handleOnChange = (event, source) => {
    const newValue = event.target.value;
    switch (source) {
      case 'selectId': {
        setSelectGraphId(newValue);
        break;
      }
      case 'selectReportId': {
        setSelectReportId(newValue);
        break;
      }
      case 'charts': {
        setSelectChart(newValue);
        break;
      }
      case 'reports': {
        setSelectReport(newValue);
        break;
      }
      case 'tileTitle': {
        setTileTitle(newValue);
        break;
      }
      case 'workflow': {
        setSelectWorkflow(newValue);
        getAllGraphIdList(newValue?.workflow_id);
        getAllReportIdNodeIdMapping(newValue?.workflow_id);
        break;
      }
      default:
        break;
    }
  };
  console.log({ workflow });

  const handleOnClickAdd = async () => {
    if (tileTitle.trim() === '') {
      enqueueSnackbar(`Title should not be blank!`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    if (!tileTitle.match(nameRegex)) {
      enqueueSnackbar(`No Special Character Allowed!`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    if ((tileValue === 'graph' && selectGraphId === '') || (tileValue === 'report' && selectReportId === '')) {
      enqueueSnackbar(`Please Select ID!`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    if ((tileValue === 'graph' && selectChart === '') || (tileValue === 'reports' && selectReport === '')) {
      enqueueSnackbar(`Please Select Chart!`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    try {
      const workflowName = selectWorkflow.workflow_name;
      const engagementName = workflow?.engagement?.engagement_name;
      const clientName = workflow?.engagement?.client?.client_name;
      const domainName = workflow?.engagement?.client?.depDomainEntity?.domain_name;

      const graph_path = `${clientName}/${engagementName}/${workflowName}/MDM/CustomerSegmentation/${selectGraphId}`;

      const parts = selectReportId.split('_');
      const nodeNumber = parts[parts.length - 1];

      console.log({ nodeNumber });
      const report_path = `${domainName}/${clientName}/${engagementName}/${workflowName}/Output/Write_${nodeNumber}`;

      const payload = {
        tile_type: tileValue,
        tile_title: tileTitle,
        path: tileValue === 'graph' ? graph_path : report_path,
        chart_type: tileValue === 'graph' ? selectChart : selectReport,
        tile_id: tileValue === 'graph' ? selectGraphId : selectReportId,
        report_workflow_id: tileValue === 'graph' ? workflow.workflow_id : reportData?.workflow_id,
        tile_property: 'general',
        client: {
          client_id: workflow?.engagement?.client?.client_id,
        },
        engagement: {
          engagement_id: workflow?.engagement?.engagement_id,
        },
        workflow: {
          workflow_id: workflow?.workflow_id,
        },
      };
      const response = await saveTilesData(payload);
      if (response.status === 201) {
        enqueueSnackbar(`Tile Saved !`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        if (selectReport === 'Stats') {
          getAllCharts();
        } else {
          handleOnSaveSequence(response.data, null);
        }
        handleOnCancel();
      }
    } catch (error) {
      if (error.response?.status === 409) {
        enqueueSnackbar(`Already exist !`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar(`Something went wrong Add Charts!`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const handleOnCancel = () => {
    handleCloseModal();
    setSelectChart('');
    setSelectGraphId('');
    setTileTitle('');
    setSelectReportId('');
    setSelectReport('');
  };

  const handleSetCloud = (event) => {
    setTileValue(event.target.value);
  };

  const getWorkflowList = async () => {
    try {
      const response = await getWorkflowByUserGroupAndEngagementIdApi(userGroup, workflow?.engagement?.engagement_id);
      if (response.status === 200) {
        // const workflowNames = response.data?.map((workflow) => workflow.workflow_name);
        const filteredWorkflow = response.data?.filter((workflow) => workflow.process_type !== 'Reporting');
        setWorkflowList(filteredWorkflow);
      }
    } catch (error) {
      enqueueSnackbar('Error while fetching workflow name!', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const getAllGraphIdList = async (workflowId) => {
    try {
      const data = {
        workflow_id: workflowId,
        engagement_id: workflow?.engagement?.engagement_id,
      };

      const response = await getGraphPlotDataByEngagementIdWorkflowId(data);
      if (response.status === 200) {
        const allGraphIds = [];
        response.data[0].graph_plots_json.forEach((plot) => {
          plot.graph_id.forEach((id) => {
            allGraphIds.push(id);
          });
        });
        setGraphIdList(allGraphIds);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Graph Id Not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error Reporting Page!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const getAllReportIdNodeIdMapping = async (workflowId) => {
    try {
      const data = {
        workflow_id: workflowId,
        engagement_id: workflow?.engagement?.engagement_id,
      };

      const response = await getReportsDataByEngagementIdWorkflowId(data);
      if (response.status === 200) {
        setReportData(response.data[0]);
        setReportIdList(response.data[0]?.report_nodes_json?.reporting_id);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        enqueueSnackbar('404 Report Id Not Found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      if (error.response?.status === 500) {
        enqueueSnackbar('Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  // useEffect(() => {
  //   if (selectWorkflow?.workflow_id && workflow?.engagement?.engagement_id) {
  //     getAllGraphIdList();
  //     getAllReportIdNodeIdMapping();
  //   }
  // }, [selectWorkflow]);

  useEffect(() => {
    getWorkflowList();
  }, []);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleOnCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ color: '#43425D' }}> Add Tile </DialogTitle>
        <DialogContent>
          <Box component="form" justifyContent="space-between" gap="10px" alignItems="end" width="450px">
            <div>
              <RadioGroup row name="row-radio-buttons-group" value={tileValue} onChange={handleSetCloud}>
                <FormControlLabel
                  value="graph"
                  control={
                    <Radio
                      sx={{
                        color: '#00043c',
                        '&.Mui-checked': {
                          color: '#00043c',
                        },
                      }}
                    />
                  }
                  label="Graphs/Charts"
                />

                <FormControlLabel
                  value="report"
                  control={
                    <Radio
                      sx={{
                        color: '#00043c',
                        '&.Mui-checked': {
                          color: '#00043c',
                        },
                      }}
                    />
                  }
                  label="Reports"
                />
              </RadioGroup>
            </div>
            <div>
              <TextField
                id="tileTitle"
                required
                label="Title"
                name="tileTitle"
                value={tileTitle}
                onChange={(e) => handleOnChange(e, 'tileTitle')}
                fullWidth
                size="small"
                sx={{ mt: 2 }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <TextField
                id="selectWorkflowId"
                select
                required
                label="Select Workflow"
                name="selectWorkflowId"
                value={selectWorkflow?.workflow_name}
                onChange={(e) => handleOnChange(e, 'workflow')}
                fullWidth
                size="small"
                sx={{ mt: 2, paddingRight: '8px' }}
              >
                {workflowList?.map((ele) => {
                  return (
                    <MenuItem key={ele.workflow_id} value={ele}>
                      {ele?.workflow_name}
                    </MenuItem>
                  );
                })}
              </TextField>
              {tileValue === 'graph' ? (
                <TextField
                  id="graphsReportsDropdown"
                  select
                  required
                  label="Select Graph Name"
                  name="graphsReportsDropdown"
                  value={selectGraphId}
                  onChange={(e) => handleOnChange(e, 'selectId')}
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                >
                  {graphIdList &&
                    graphIdList.map((ele) => {
                      return (
                        <MenuItem key={ele.id} value={ele}>
                          {ele}
                        </MenuItem>
                      );
                    })}
                </TextField>
              ) : (
                <TextField
                  id="reportsDropdown"
                  select
                  required
                  label="Select Report Name"
                  name="reportsDropdown"
                  value={selectReportId}
                  onChange={(e) => handleOnChange(e, 'selectReportId')}
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                >
                  {reportIdList?.map((ele, index) => {
                    return (
                      <MenuItem key={ele.id} value={ele}>
                        {ele}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </div>
            {tileValue === 'graph' ? (
              <div>
                <TextField
                  id="chartsGraphDropdown"
                  select
                  required
                  label="Select Chart"
                  name="chartsGraphDropdown"
                  value={selectChart}
                  onChange={(e) => handleOnChange(e, 'charts')}
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                >
                  {ChartsList &&
                    ChartsList.map((ele) => {
                      return (
                        <MenuItem key={ele} value={ele}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {ele === 'Bar Chart' && (
                              <IoBarChartSharp style={{ color: '#3498DB ', marginRight: '10px' }} />
                            )}
                            {ele === 'Pie Chart' && <FaChartPie style={{ color: 'brown', marginRight: '10px' }} />}
                            {ele === 'Doughnut Chart' && (
                              <BiSolidDoughnutChart style={{ color: '#ef71ff', marginRight: '8px' }} size={18} />
                            )}
                            {ele === 'Line Chart' && (
                              <RiLineChartLine style={{ color: '#5B2C6F', marginRight: '10px' }} />
                            )}
                            {ele === 'Histograms' && (
                              <BsFillBarChartLineFill style={{ color: '#FF7F50', marginRight: '10px' }} />
                            )}
                            {ele}
                          </div>
                        </MenuItem>
                      );
                    })}
                </TextField>
              </div>
            ) : (
              <div>
                {/* <div style={{ marginRight: '15px' }}>
                  <ImTable2 style={{ color: '#1E8449' }} />
                </div> */}
                {/* <TextField
                  id="chartsGraphReportDropdown"
                  required
                  label="Select Chart"
                  name="chartsGraphReportDropdown"
                  value="Tables"
                  fullWidth
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ImTable2 style={{ color: '#1E8449' }} />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  sx={{ mt: 2 }}
                /> */}
                <TextField
                  id="chartsGraphDropdown"
                  select
                  required
                  label="Select Chart"
                  name="chartsGraphDropdown"
                  value={selectReport}
                  onChange={(e) => handleOnChange(e, 'reports')}
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                >
                  {ReportList?.map((ele) => {
                    return (
                      <MenuItem key={ele} value={ele}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {ele === 'Table' && <ImTable2 style={{ color: '#1E8449', marginRight: '10px' }} />}
                          {ele === 'Stats' && <FaBoxes style={{ color: '#3498DB', marginRight: '10px' }} />}
                          {ele}
                        </div>
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 2 }}>
          <Button
            onClick={handleOnCancel}
            variant="outlined"
            sx={{ textTransform: 'capitalize', color: '#43425D', border: '1px solid #43425D' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOnClickAdd}
            variant="contained"
            sx={{ backgroundColor: '#00043C', textTransform: 'capitalize', '&:hover': { backgroundColor: '#00043C' } }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
