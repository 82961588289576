import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() =>
    sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null
  );
  const [userGroup, setUserGroup] = useState(() =>
    sessionStorage.getItem('userGroup') ? JSON.parse(sessionStorage.getItem('userGroup')) : null
  );
  const [userId, setUserId] = useState(() =>
    sessionStorage.getItem('id') ? JSON.parse(sessionStorage.getItem('id')) : null
  );

  const [userRole, setUserRole] = useState(() =>
    sessionStorage.getItem('access_right') ? JSON.parse(sessionStorage.getItem('access_right')) : null
  );
  const [domainId, setDomainId] = useState(() =>
    sessionStorage.getItem('domainId') ? JSON.parse(sessionStorage.getItem('domainId')) : null
  );
  const [domainName, setDomainName] = useState(() =>
    sessionStorage.getItem('domainName') ? JSON.parse(sessionStorage.getItem('domainName')) : null
  );
  const [LLMName, setLLMName] = useState(() =>
    sessionStorage.getItem('LLMName') ? sessionStorage.getItem('LLMName') : null
  );
  const [LLMId, setLLMId] = useState(() => (sessionStorage.getItem('LLMId') ? sessionStorage.getItem('LLMId') : null));
  const [userDomainType, setUserDomainType] = useState(() =>
    sessionStorage.getItem('userDomainType') ? JSON.parse(sessionStorage.getItem('userDomainType')) : null
  );
  const [token, setToken] = useState(() =>
    sessionStorage.getItem('jwtToken') ? JSON.parse(sessionStorage.getItem('jwtToken')) : null
  );

  const [isDep, setIsDep] = useState(() =>
    sessionStorage.getItem('dep') ? JSON.parse(sessionStorage.getItem('dep')) : true
  );

  const handleRemoveFilteredSessions = () => {
    const filterPrefixes = [
      'data_',
      'data-quality_',
      'data-transformation_',
      'Ingestion_',
      'modelling-analytics_',
      'xperience360_',
      'customer-segmentation_',
    ];
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (filterPrefixes.some((prefix) => key.startsWith(prefix))) {
        sessionStorage.removeItem(key);
        i--;
      }
    }
  };

  const logoutUser = () => {
    setUser(null);
    setUserRole(null);
    setDomainId(null);
    setDomainName(null);
    setLLMName(null);
    setLLMId(null);
    setUserDomainType(null);
    setToken(null);
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('access_right');
    sessionStorage.removeItem('domainId');
    sessionStorage.removeItem('domainName');
    sessionStorage.removeItem('LLMId');
    sessionStorage.removeItem('LLMName');
    sessionStorage.removeItem('userDomainType');
    sessionStorage.removeItem('isLabelVisible');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('version');
    handleRemoveFilteredSessions();
    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userGroup,
        setUserGroup,
        userId,
        setUserId,
        userRole,
        setUserRole,
        logoutUser,
        domainId,
        setDomainId,
        domainName,
        setDomainName,
        LLMId,
        setLLMId,
        LLMName,
        setLLMName,
        userDomainType,
        setUserDomainType,
        token,
        setToken,
        isDep,
        setIsDep,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
