import React, { useContext, useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../contexts/AuthProvider';
import ClientNoData from '../components/Client/ClientNoData';
import { CLIENT_PAGE } from '../utils/commonConstants';
import {
  getAllClientsApi,
  getClientByUserGroup,
  getClientByUserGroupAndDomainIdApi,
  getAllClientByDomainIdApi,
} from "../api's/ClientApi";
import ClientTable from '../components/dashboard-component/ClientTable';
import PageHeader from '../reusable-component/PageHeader';
import SearchAndFilter from '../reusable-component/SearchAndFilter';
import LoadingIcon from '../reusable-component/LoadingIcon';
import useDebounce from '../hooks/useDebounce';

const ClientPage = () => {
  const [region, setRegion] = useState('');
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userRole, domainId, domainName, userGroup } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  const handleSearch = () => {
    console.log(search);
    // setFilteredTableData(tableData.filter((tableData) => tableData.client_name.startsWith(search)));
  };

  const handleRegion = (event) => {
    sessionStorage.setItem('filtered_domain', event.target.value);
    setRegion(event.target.value);
  };

  const handleStatus = (event) => {
    sessionStorage.setItem('filtered_client_status', event.target.value);
    setStatus(event.target.value);
  };

  const handleOnChange = (event) => {
    sessionStorage.setItem('filtered_client_search', event.target.value);
    setSearch(event.target.value);
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem('filtered_client_status');
    sessionStorage.removeItem('filtered_client_search');
    sessionStorage.removeItem('filtered_domain');
    setRegion('');
    setSearch('');
    setStatus('');
    setPage(1);
    setFilteredTableData(tableData);
  };

  const fetchFilterTableData = (responseTableData) => {
    const filtered_domain = sessionStorage.getItem('filtered_domain') || '';
    const filtered_status = sessionStorage.getItem('filtered_client_status') || '';
    const filtered_search = sessionStorage.getItem('filtered_client_search') || '';
    const filtered_search_lowercase = filtered_search.toLowerCase() || '';

    const filteredData = responseTableData.filter((data) => {
      return (
        (!filtered_status || data.status === filtered_status) &&
        (!filtered_search || data.client_name.toLowerCase().startsWith(filtered_search_lowercase)) &&
        (!filtered_domain || data.domain_name === filtered_domain)
      );
    });

    setFilteredTableData(filteredData);
    setPage(1);
  };

  const fetchTableData = async () => {
    setLoading(true);
    try {
      const response = await getAllClientsApi();
      if (response.status === 200) {
        // const filtered_client_status = sessionStorage.getItem('filtered_client_status') || '';
        // const filtered_domain = sessionStorage.getItem('filtered_domain') || '';
        // if (filtered_client_status !== '' || filtered_domain !== '') {
        //   const domain_exist =
        //     filtered_client_status && filtered_domain
        //       ? response.data.filter(
        //           (data) => data.status === filtered_client_status && data.domain_name === filtered_domain
        //         )
        //       : filtered_client_status
        //       ? response.data.filter((data) => data.status === filtered_client_status)
        //       : response.data.filter((data) => data.domain_name === filtered_domain);
        //   if (domain_exist.length > 0) {
        //     setStatus(filtered_client_status);
        //     setRegion(filtered_domain);

        //     setTableData(response.data);
        //     setFilteredTableData(domain_exist);
        //   } else {
        //     handleClearFilter();
        //   }
        // } else {
        //   setTableData(response.data);
        //   setFilteredTableData(response.data);
        // }
        // response.data.sort((a, b) => {
        //   return a.client_name.localeCompare(b.client_name);
        // });
        setTableData(response.data);
        fetchFilterTableData(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Client not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  // useEffect(() => {
  //   const filtered_domain = sessionStorage.getItem('filtered_domain') || '';
  //   const filtered_status = sessionStorage.getItem('filtered_client_status') || '';
  //   const filtered_search = sessionStorage.getItem('filtered_client_search') || '';
  //   setStatus(filtered_status);
  //   setSearch(filtered_search);
  //   setRegion(filtered_domain);
  //   if (filtered_status !== '' || filtered_search !== '' || filtered_domain !== '') {
  //     fetchFilterTableData(tableData);
  //   } else {
  //     setFilteredTableData(tableData);
  //   }
  // }, [search, status, region]);

  useEffect(() => {
    const filtered_domain = sessionStorage.getItem('filtered_domain') || '';
    const filtered_status = sessionStorage.getItem('filtered_client_status') || '';
    const filtered_search = sessionStorage.getItem('filtered_client_search') || '';
    setStatus(filtered_status);
    setSearch(filtered_search);
    setRegion(filtered_domain);
  }, []);

  const debouncedFilteredData = useDebounce(fetchFilterTableData, 500, [search, status, region, tableData]);

  useEffect(() => {
    debouncedFilteredData(tableData);
  }, [debouncedFilteredData]);

  // useEffect(() => {
  //   region
  //     ? setFilteredTableData(
  //         tableData.filter((tableData) => tableData.status === status && tableData.domain_name === region)
  //       )
  //     : status
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.status === status))
  //     : setFilteredTableData(tableData);
  // }, [status]);

  // useEffect(() => {
  //   status
  //     ? setFilteredTableData(
  //         tableData.filter((tableData) => tableData.domain_name === region && tableData.status === status)
  //       )
  //     : region
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.domain_name === region))
  //     : setFilteredTableData(tableData);
  // }, [region]);

  // useEffect(() => {
  //   setFilteredTableData(
  //     tableData.filter((tableData) => tableData.client_name.toLowerCase().startsWith(search.toLowerCase()))
  //   );
  // }, [search]);

  const data = {
    search,
    handleSearch,
    handleOnChange,
    region,
    handleRegion,
    handleClearFilter,
    handleStatus,
    status,
    firstFilterText: CLIENT_PAGE.clientFilterByDomain,
    secondFilterText: CLIENT_PAGE.clientFilterByStatus,
    clearFilterText: CLIENT_PAGE.clientClearFilterButton,
    firstFilterValues: {
      filterData: [...new Set(tableData.map((item) => item.domain_name))],
    },
    secondFilterValues: {
      filterData: [...new Set(tableData.map((item) => item.status))],
    },
    isFirstFilterVisible: true,
    isSecondFilterVisible: region,
    isThirdFilterVisible: false,
  };

  return (
    <Box>
      {/* <div>
        Selected ID: {domainId}
        <br />
        Selected Name: {domainName}
        <br />
        Role: {userRole}
      </div> */}
      <PageHeader
        title={CLIENT_PAGE.clientPageTitle}
        btnText={CLIENT_PAGE.addNewClientButton}
        route="/client/onboarding"
      />
      {tableData.length !== 0 ? <SearchAndFilter data={data} /> : null}

      {filteredTableData.length !== 0 ? (
        <ClientTable
          tableData={filteredTableData}
          fetchTableData={fetchTableData}
          currentPage={page}
          setPage={setPage}
        />
      ) : (
        !loading && (
          <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
            <ClientNoData
              title={CLIENT_PAGE.clientPageNoDataToDisplay}
              text={CLIENT_PAGE.noClientToReview}
              addBtnText={CLIENT_PAGE.addNewClientButton}
              addBtnRoute="/client/onboarding"
              isRoute
            />
          </Paper>
        )
      )}
      {loading && <LoadingIcon />}
    </Box>
  );
};

export default ClientPage;
