import { createContext, useState } from 'react';

export const WorkflowContext = createContext(null);

const WorkflowProvider = ({ children }) => {
  const [workflow, setWorkflow] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [mergeManager, setMergeManager] = useState([]);
  const [dataManager, setDataManager] = useState([]);

  return (
    <WorkflowContext.Provider
      value={{
        workflow,
        setWorkflow,
        isDisabled,
        setIsDisabled,
        mergeManager,
        setMergeManager,
        dataManager,
        setDataManager,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

export default WorkflowProvider;
