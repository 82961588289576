import React, { useEffect } from 'react';
import { MenuItem, TextField, Grid, Box, FormControl, InputLabel, Select } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getAllModalApi } from "../api's/ChatGPTApi";
import {
  getAllClientApi,
  getAllDomainsApi,
  getAllEngagementApi,
  getAllSubDomainApi,
  getAllWorkflowApi,
} from "../api's/TalkToDocumentBotApi";

function DocumentBotHeader({ formField, setFormField, setLoading }) {
  const { enqueueSnackbar } = useSnackbar();
  const handleOnChange = (event, source) => {
    switch (source) {
      case 'model': {
        setFormField({ ...formField, modelName: event.target.value });
        break;
      }
      case 'domain': {
        setFormField({
          ...formField,
          domain: event.target.value,
          client: '',
          engagement: '',
          workflow: '',
          subDomain: '',
        });
        break;
      }
      case 'client': {
        setFormField({ ...formField, client: event.target.value, engagement: '', workflow: '', subDomain: '' });
        break;
      }
      case 'engagement': {
        setFormField({ ...formField, engagement: event.target.value, workflow: '', subDomain: '' });
        break;
      }
      case 'workflow': {
        setFormField({ ...formField, workflow: event.target.value, subDomain: '' });
        break;
      }
      case 'subDomain': {
        setFormField({ ...formField, subDomain: event.target.value });
        break;
      }

      case 'language': {
        setFormField({ ...formField, language: event.target.value });
        break;
      }
      default:
        break;
    }
  };

  const getDomain = async () => {
    setLoading(true);
    try {
      const response = await getAllDomainsApi();
      if (response.status === 200) {
        setFormField({ ...formField, domainList: response.data.domains });
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar('Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const getClient = async (domain) => {
    setLoading(true);
    try {
      const response = await getAllClientApi(domain);
      if (response.status === 200) {
        setFormField({ ...formField, clientList: response.data.clients });
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar('Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
  };

  const getEngagement = async (domain, client) => {
    setLoading(true);
    try {
      const response = await getAllEngagementApi(domain, client);
      if (response.status === 200) {
        setFormField({ ...formField, engagementList: response.data.batches });
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar('Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const getWorkflow = async (domain, client, engagement) => {
    setLoading(true);
    try {
      const response = await getAllWorkflowApi(domain, client, engagement);
      if (response.status === 200) {
        setFormField({ ...formField, workflowList: response.data.jobs });
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar('Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const getSubDomain = async (domain, client, engagement, workflow) => {
    setLoading(true);
    try {
      const response = await getAllSubDomainApi(domain, client, engagement, workflow);
      if (response.status === 200) {
        setFormField({ ...formField, subDomainList: response.data.subdomains });
      }
    } catch (error) {
      enqueueSnackbar('Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const getModel = async () => {
    setLoading(true);
    try {
      const response = await getAllModalApi();
      if (response.status === 200) {
        setFormField({ ...formField, modelList: response.data });
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar('Internal Server Error', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    // getDomain();
    getModel();
  }, []);

  useEffect(() => {
    if (formField?.modelName) {
      getDomain();
    }
  }, [formField?.modelName]);

  useEffect(() => {
    if (formField?.domain) {
      getClient(formField.domain);
    }
  }, [formField?.domain]);

  useEffect(() => {
    if (formField?.client) {
      getEngagement(formField.domain, formField.client);
    }
  }, [formField?.client]);

  useEffect(() => {
    if (formField?.engagement) {
      getWorkflow(formField.domain, formField.client, formField.engagement);
    }
  }, [formField?.engagement]);

  useEffect(() => {
    if (formField?.workflow) {
      getSubDomain(formField.domain, formField.client, formField.engagement, formField.workflow);
    }
  }, [formField?.workflow]);

  return (
    <Box display="flex" justifyContent="space-between">
      <Grid container spacing={1.5}>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-modal"
            select
            required
            label="Model"
            name="modelName"
            value={formField.modelName}
            onChange={(e) => handleOnChange(e, 'model')}
            fullWidth
            size="small"
          >
            {formField?.modelList &&
              formField.modelList?.map((ele) => (
                <MenuItem key={ele.modalId} value={ele.modalName}>
                  {ele.modalDisplayName}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-domain"
            select
            required
            label="Domain"
            name="domainName"
            value={formField?.domain}
            onChange={(e) => handleOnChange(e, 'domain')}
            fullWidth
            size="small"
          >
            {formField?.domainList &&
              formField?.domainList?.map((ele, i) => (
                <MenuItem key={i} value={ele}>
                  {ele}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-modal"
            select
            required
            label="Client"
            name="clientName"
            value={formField?.client}
            onChange={(e) => handleOnChange(e, 'client')}
            fullWidth
            size="small"
          >
            {formField?.clientList &&
              formField.clientList?.map((ele, i) => (
                <MenuItem key={i} value={ele}>
                  {ele}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-modal"
            select
            required
            label="Engagement"
            name="engagement"
            value={formField?.engagement}
            onChange={(e) => handleOnChange(e, 'engagement')}
            sx={{ width: '95%' }}
            size="small"
          >
            {formField?.engagementList &&
              formField.engagementList?.map((ele, i) => (
                <MenuItem key={i} value={ele}>
                  {ele}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-modal"
            select
            required
            label="WorkFlow"
            name="workflow"
            value={formField?.workflow}
            onChange={(e) => handleOnChange(e, 'workflow')}
            fullWidth
            size="small"
          >
            {formField?.workflowList &&
              formField.workflowList?.map((ele, i) => (
                <MenuItem key={i} value={ele}>
                  {ele}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={2.5} md={3}>
          <TextField
            id="outlined-select-modal"
            select
            required
            label="SubDomain"
            name="subDomain"
            value={formField?.subDomain}
            onChange={(e) => handleOnChange(e, 'subDomain')}
            fullWidth
            size="small"
          >
            {formField?.subDomainList &&
              formField.subDomainList?.map((ele, i) => (
                <MenuItem key={i} value={ele}>
                  {ele}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
      <Box>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label" style={{ backgroundColor: 'white' }}>
            Language
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formField.language}
            label="Language"
            size="small"
            onChange={(e) => handleOnChange(e, 'language')}
            sx={{ maxWidth: '100px' }}
          >
            <MenuItem value={'English'}>English</MenuItem>
            <MenuItem value={'Arabic'}>Arabic</MenuItem>
            <MenuItem value={'French'}>French</MenuItem>
            <MenuItem value={'German'}>German</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default DocumentBotHeader;
