import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  Tabs,
  Tab,
  TextField,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { useSnackbar } from 'notistack';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { FaUserCircle } from 'react-icons/fa';
import { MdArrowBackIosNew } from 'react-icons/md';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import PotentialMatches from './PotentialMatches';
import { getGroupRecordsApi } from "../../api's/DataProcessorApi";
import { WorkflowContext } from '../../contexts/WorkflowProvider';
import group_records from '../../mock/group_records.json';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import { convertToPascalCase } from '../../utils/stringConversion';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} {...props} />)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const GroupRecords = ({ handleSource, selectedRow, sources, selectedSource }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [expanded, setExpanded] = useState('panel1');
  const [selectedId, setSelectedId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [showPotentialMatch, setShowPotentialMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupRecords, setGroupRecords] = useState([]);
  const [tableData, setTableData] = useState([]);

  const { workflow } = useContext(WorkflowContext);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    setSelectedId(id);
    // console.log('id', id);
  };

  const handleOK = (id) => {
    setIsEdit(false);
    setSelectedId(id);
  };

  const handlePotentialMatch = () => {
    setShowPotentialMatch(!showPotentialMatch);
  };

  const fetchGroupRecords = async () => {
    setLoading(true);
    const data = {
      client_name: workflow.engagement.client.client_name,
      engagement_name: workflow.engagement.engagement_name,
      workflow_name: workflow.workflow_name,
      // client_name: 'Test_client',
      // engagement_name: 'Test_engagement',
      // workflow_name: 'TestWorkflow_name',
      parent_id: selectedRow.parent_id,
    };
    try {
      const response = await getGroupRecordsApi(data);
      if (response.status === 200) {
        setGroupRecords(response.data);
        setTableData(Object.entries(response.data.grouping_records));
      }
    } catch (error) {
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Group Records not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchGroupRecords();
  }, []);

  const handleChipColor = (ele) => {
    if (selectedSource.length > 0) {
      if (selectedSource === ele.source) {
        return sources.find((item) => item.source === ele.source)?.color ?? '#e0e0e0';
      }
      return '#fafafa';
    }
    return sources.find((item) => item.source === ele.source)?.color ?? '#e0e0e0';
  };

  function getRuleType(attribute) {
    const getAllNodes = JSON.parse(sessionStorage.getItem('allNodes') || '[]');
    return getAllNodes
      .find((item) => item.nodeName === 'Fuzzy Match')
      .fetchedHeader.find((item) => item.header === attribute)?.merge_rule;
  }

  return (
    <>
      <Stack direction="row" my={1} alignItems="center">
        <IconButton size="small" aria-label="back" onClick={handleSource}>
          <MdArrowBackIosNew />
        </IconButton>
        <FaUserCircle size="65" color={grey[300]} />
        <Stack direction="column" ml={1}>
          <Typography variant="body1" display="block" fontWeight={600}>
            {selectedRow.cust_name || selectedRow.PatientName}
          </Typography>
          <Typography variant="caption" display="block">
            {selectedRow.cust_ship_to_address}
          </Typography>
          <Typography variant="caption" component="div">
            <Chip label="Individual" color="primary" size="small" sx={{ mr: 1, fontSize: 12 }} />
            Entity ID: {groupRecords.parent_id ?? '--'}
          </Typography>
        </Stack>
      </Stack>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>Attributes</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Rule Type</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Count</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Attribute Values</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Golden Record</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .filter((item) => item[0] !== 'index' && item[0] !== 'parent_id')
              .map((item) => (
                <>
                  <TableRow component="th" scope="row" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{convertToPascalCase(item[0])}</TableCell>
                    <TableCell>{getRuleType(item[0]) ?? '--'}</TableCell>
                    <TableCell>{item[1]?.length}</TableCell>
                    <TableCell>
                      {item[1]?.map(
                        (ele, index) =>
                          // <TextField
                          //   id={ele.unique_id}
                          //   variant="filled"
                          //   value={ele.value}
                          //   onChange={(e) => handleOnChange(e, index, item[0], ele.unique_id)}
                          // />
                          ele.value !== 'null' && (
                            <Chip
                              label={ele.value}
                              size="small"
                              sx={{
                                mr: 1,
                                mb: 0.3,
                                fontSize: 12,
                                bgcolor: handleChipColor(ele),
                              }}
                            />
                          )
                      )}
                    </TableCell>
                    <TableCell>{selectedRow[item[0]]}</TableCell>
                  </TableRow>

                  {/* <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{item[1].length}</TableCell>
                  </TableRow> */}
                </>
              ))}
          </TableBody>
        </Table>
        {loading && <LoadingIcon />}
      </TableContainer>
      {/* <PotentialMatches row={row} /> */}
    </>
  );
};

export default GroupRecords;
