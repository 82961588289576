import { Box, Button, Paper, Tab, Tabs, Typography } from '@mui/material';
import MuiGrid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { getAllClientByDomainIdApi, getClientByUserGroupAndDomainIdApi } from "../../api's/ClientApi";
import { getWorkflowByUserGroupAndClientIdApi } from "../../api's/WorkflowApi";
import ClientNoData from '../../components/Client/ClientNoData';
import WorkflowTable from '../../components/Client/WorkflowTable';
import ClientTable from '../../components/dashboard-component/ClientTable';
import { AuthContext } from '../../contexts/AuthProvider';
import useDebounce from '../../hooks/useDebounce';
import AccessModal from '../../reusable-component/AccessModal';
import AddEngagementModal from '../../reusable-component/AddEngagementModal';
import AddWorkflowModal from '../../reusable-component/AddWorkflowModal';
import LoadingIcon from '../../reusable-component/LoadingIcon';
import PageHeader from '../../reusable-component/PageHeader';
import PageHeaderTable from '../../reusable-component/PageHeaderTable';
import SearchAndFilter from '../../reusable-component/SearchAndFilter';
import { DateConverter } from '../../utils/DateConverter';
import { CLIENT_PAGE, WORKFLOW_PAGE } from '../../utils/commonConstants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DomainDetails({ headerTableData }) {
  const [mode, setMode] = useState('');
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [workflowTableData, setWorkflowTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filteredWorkflowTableData, setFilteredWorkflowTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openWorkflowModal, setOpenWorkflowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const { userRole, domainId, domainName, userGroup } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  // const {
  //   state: { clientRow = [] },
  // } = useLocation();

  const handleOpenModal = () => {
    if (userRole === 'READER') {
      handleAccessModal();
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleWorkflowModal = () => {
    if (userRole === 'READER') {
      handleAccessModal();
    } else {
      setOpenWorkflowModal(!openWorkflowModal);
    }
  };

  const handleSearch = () => {
    console.log('Hii');
  };

  const handleMode = (event) => {
    setMode(event.target.value);
  };

  const handleStatus = (event) => {
    sessionStorage.setItem('filtered_domaindetails_status', event.target.value);
    setStatus(event.target.value);
  };

  const handleOnChange = (event) => {
    sessionStorage.setItem('filtered_domaindetails_search', event.target.value);
    setSearch(event.target.value);
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem('filtered_domaindetails_status');
    sessionStorage.removeItem('filtered_domaindetails_search');
    setMode('');
    setStatus('');
    setSearch('');
    setPage(1);
    setFilteredTableData(tableData);
  };

  const handleAccessModal = () => {
    setOpenAccessModal(!openAccessModal);
  };

  const fetchFilterTableData = (responseTableData) => {
    const filtered_status = sessionStorage.getItem('filtered_domaindetails_status') || '';
    const filtered_search = sessionStorage.getItem('filtered_domaindetails_search') || '';
    const filtered_search_lowercase = filtered_search.toLowerCase() || '';

    const filteredData = responseTableData.filter((data) => {
      return (
        (!filtered_status || data.status === filtered_status) &&
        (!filtered_search || data.client_name.toLowerCase().startsWith(filtered_search_lowercase))
      );
    });
    setFilteredTableData(filteredData);
    setPage(1);
  };

  const fetchTableData = async () => {
    setLoading(true);
    const response =
      userRole === 'SUPER_USER'
        ? await getAllClientByDomainIdApi(domainId)
        : await getClientByUserGroupAndDomainIdApi(userGroup, headerTableData.domain_id);

    // const response = await getClientByUserGroupAndDomainIdApi(userGroup, headerTableData.domain_id);
    try {
      if (response.status === 200) {
        setTableData(response.data);
        fetchFilterTableData(response.data);
        // setTableData(response.data);
        // setFilteredTableData(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Client not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
    // fetchWorkflowTableData();
  }, []);

  useEffect(() => {
    const filtered_status = sessionStorage.getItem('filtered_domaindetails_status') || '';
    const filtered_search = sessionStorage.getItem('filtered_domaindetails_search') || '';
    setStatus(filtered_status);
    setSearch(filtered_search);
  }, []);

  const debouncedFilteredData = useDebounce(fetchFilterTableData, 500, [search, status, tableData]);

  useEffect(() => {
    debouncedFilteredData(tableData);
  }, [debouncedFilteredData]);

  // useEffect(() => {
  //   status
  //     ? setFilteredTableData(tableData.filter((tableData) => tableData.status === status))
  //     : setFilteredTableData(tableData);
  // }, [status, tableData]);

  // useEffect(() => {
  //   setFilteredTableData(tableData.filter((tableData) => tableData.client_name.startsWith(search)));
  // }, [search, tableData]);

  // useEffect(() => {
  //   mode
  //     ? setFilteredWorkflowTableData(
  //         workflowTableData.filter((tableData) => tableData.execution_mode === mode && tableData.status === status)
  //       )
  //     : status
  //     ? setFilteredWorkflowTableData(workflowTableData.filter((tableData) => tableData.status === status))
  //     : setFilteredWorkflowTableData(workflowTableData);
  // }, [status, workflowTableData]);

  // useEffect(() => {
  //   status
  //     ? setFilteredWorkflowTableData(
  //         workflowTableData.filter((tableData) => tableData.execution_mode === mode && tableData.status === status)
  //       )
  //     : mode
  //     ? setFilteredWorkflowTableData(workflowTableData.filter((tableData) => tableData.execution_mode === mode))
  //     : setFilteredWorkflowTableData(workflowTableData);
  // }, [mode, workflowTableData]);

  // useEffect(() => {
  //   setFilteredWorkflowTableData(workflowTableData.filter((tableData) => tableData.workflow_name.startsWith(search)));
  // }, [search, workflowTableData]);

  const version = JSON.parse(sessionStorage.getItem('version'));

  const fetchWorkflowTableData = async () => {
    setLoading(true);
    const response = await getWorkflowByUserGroupAndClientIdApi(userGroup, headerTableData.client_id);
    try {
      if (response.status === 200) {
        if (version === '1.0') {
          const filterByVersion = response.data.filter(
            (item) => item.process_type === 'Data Ingestion' || item.process_type === 'Data Transformation'
          );
          setWorkflowTableData(filterByVersion);
          setFilteredWorkflowTableData(filterByVersion);
          // fetchWorkflowTableData(filterByVersion);
        } else {
          setWorkflowTableData(response.data);
          setFilteredWorkflowTableData(response.data);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        enqueueSnackbar('500 Internal Server Error!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (error.response?.status === 404) {
        enqueueSnackbar('404 Workflow not found!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
    setLoading(false);
  };

  const Grid = styled(MuiGrid)(({ theme }) => ({
    width: '100%',
    ...theme.typography.body1,
    '& [role="separator"]': {
      margin: theme.spacing(0, 2),
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setMode('');
    setStatus('');
    setSearch('');
  };

  const data = {
    search,
    handleSearch,
    handleOnChange,
    region: mode,
    handleRegion: handleMode,
    handleClearFilter,
    handleStatus,
    status,
    firstFilterText: CLIENT_PAGE.clientFilterByMode,
    secondFilterText: CLIENT_PAGE.clientFilterByStatus,
    clearFilterText: CLIENT_PAGE.clientClearFilterButton,
    firstFilterValues: {
      first: 'Real Time',
      second: 'Batch',
    },
    secondFilterValues: {
      filterData: ['approved', 'pending', 'rejected'],
    },
    isFirstFilterVisible: value === 1,
    isSecondFilterVisible: true,
    isThirdFilterVisible: false,
  };

  const headerData = {
    firstHeader: 'DOMAIN',
    secondHeader: 'TOTAL CLIENT',
    thirdHeader: 'CREATED BY',
    fourthHeader: 'CREATED ON',
    fifthHeader: 'STATUS',
    firstHeaderText: headerTableData.domain_name,
    secondHeaderText: tableData.length,
    // secondHeaderText: headerTableData.client_count,
    thirdHeaderText: 'Depadmin',
    fourthHeaderText: DateConverter(headerTableData.creation_date),
    fifthHeaderText: headerTableData.status,
    route: '/domain',
  };

  return (
    <Box>
      <PageHeaderTable data={headerData} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#47D7AC',
            },
          }}
          sx={{ '& .MuiTab-root.Mui-selected': { color: '#00043C' } }}
        >
          <Tab label={`Clients(${tableData.length})`} {...a11yProps(0)} sx={{ fontWeight: 'bold' }} />
          {/* <Tab label={`Workflows(${workflowTableData.length})`} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} /> */}
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          {/* <Grid container direction="row" display="flex">
            <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
              {CLIENT_PAGE.clientPageTitle}
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end"> */}
          <PageHeader
            title={CLIENT_PAGE.clientPageTitle}
            btnText={CLIENT_PAGE.addNewClientButton}
            route="/client/onboarding"
          />
          {/* </Grid>
          </Grid> */}
          {tableData.length > 0 && <SearchAndFilter data={data} />}
          {filteredTableData.length !== 0 ? (
            <ClientTable
              tableData={filteredTableData}
              fetchTableData={fetchTableData}
              currentPage={page}
              setPage={setPage}
            />
          ) : (
            !loading && (
              <Paper elevation={3} sx={{ height: '350px', marginTop: '15px' }}>
                <ClientNoData
                  title={CLIENT_PAGE.clientPageNoDataToDisplay}
                  text={CLIENT_PAGE.noClientToReview}
                  addBtnText={CLIENT_PAGE.addNewClientButton}
                  addBtnRoute="/client/onboarding"
                  isRoute
                />
              </Paper>
            )
          )}
          {loading && <LoadingIcon />}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container direction="row" display="flex">
            <Grid item xs={6} direction="row" justifyContent="flex-start" style={{ color: '#43425D' }}>
              {WORKFLOW_PAGE.workflowPageTitle}
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#00043C', '&:hover': { backgroundColor: '#00043C' } }}
                onClick={handleWorkflowModal}
              >
                <MdAdd />
                Add New Workflow
              </Button>
            </Grid>
          </Grid>
          <AddWorkflowModal
            handleModal={handleWorkflowModal}
            open={openWorkflowModal}
            fetchTableData={fetchWorkflowTableData}
            processType="Ingestion"
          />
          {workflowTableData.length > 0 && <SearchAndFilter data={data} />}
          {filteredWorkflowTableData.length !== 0 ? (
            <WorkflowTable tableData={filteredWorkflowTableData} fetchWorkflowTableData={fetchWorkflowTableData} />
          ) : (
            <Paper elevation={3} sx={{ height: '330px', marginTop: '10px' }}>
              <ClientNoData
                title={WORKFLOW_PAGE.workflowPageNoDataToDisplay}
                text={WORKFLOW_PAGE.noWorkflowToReview}
                addBtnText={WORKFLOW_PAGE.addNewWorkflowButton}
                handleOnClick={handleWorkflowModal}
                isAddBtn
              />
            </Paper>
          )}
          {loading && <LoadingIcon />}
        </TabPanel>
        <AddEngagementModal handleCloseModal={handleCloseModal} openModal={openModal} fetchData={fetchTableData} />
        <AccessModal openAccessModal={openAccessModal} handleAccessModal={handleAccessModal} />
      </Box>
    </Box>
  );
}
