// const BASEURL = 'https://dev-middleware.dataengplatform.com/api'; // Dev
// export const BACKEND_VDE_BASEURL = 'http://20.127.233.133:3000'; // VDE URL for local run
export const BACKEND_VDE_BASEURL = 'https://depllm.eastus.cloudapp.azure.com'; // VDE URL for deployed
// const BASEURL = 'https://depbackend.azurewebsites.net/api'; // Demo
const BASEURL = 'http://dep-middleware-env.eba-pe3djn2d.us-east-1.elasticbeanstalk.com/api';

// const BASEURL = 'http://localhost:5000/api';  // localhost

// const BASEURL = process.env.REACT_APP_API_URI;

// export const AWS_CONFIG_TYPE = process.env.REACT_APP_AWS_CONFIG_TYPE === 'true';
export const AWS_CONFIG_TYPE = true;
// export const AWS_CONFIG_TYPE = false;

export const OPEN_AI_URL = 'https://depgenopenai.eastus.cloudapp.azure.com';

export const AWS_UI_URL = 'https://nagarro.dataenterpriseplatform.click/';
export const AZURE_UI_URL = 'https://dataengplatform.com/';
export const SNOWFLAKE_UI_URL = 'http://dep-snowpark-react-frontend-env.us-east-1.elasticbeanstalk.com/';

export default BASEURL;
