import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  formComponent: {
    fontSize: '12px',
    color: '#8798AD',
  },
  // nagarroLogoImg: {
  //   // width: "170px",
  //   // height: "40px",
  //   width: "170px",
  //   height: "60px"
  // },
  formTextField: {
    marginTop: '30px !important',
    textAlign: 'left',
    color: '#13294B',
  },
  formFirstTextField: {
    marginTop: '30px !important',
    textAlign: 'left',
  },
  formLink: {
    color: '#7045B9',
    fontSize: '12px',
  },
  formLabel: {
    marginBottom: '16px !important',
    fontSize: '14px',
  },
  inputTextField: {
    marginBottom: '10px !important',
  },
  accessRightsForm: {
    width: '100%',
  },
});

export default useStyles;
