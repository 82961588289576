import axios from 'axios';
import BASEURL from '../BaseUrl';
import { logoutApi } from './AuthApi';

export const getCsvData = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/rule-engine/get-csv-data`, {
      params: { path: data.path, workflow_id: data.workflow_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const createCsvToJson = async (client_id, batch, filename, data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/table-rule/create-csv-to-json/${client_id}/${batch}/${filename}`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const createS3Path = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/table-rule/add-s3-path`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getS3TableRules = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/table-rule/get-s3-table-rules`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchTableRules = async (client_id, batch) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/table-rule/get-table-rule/${client_id}/${batch}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    } else {
      return -1;
    }
    throw error;
  }
};

export const fetchTableRulesWithStatus = async (client_id, batch) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/table-rule/get-table-rule-with-status/${client_id}/${batch}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    } else {
      return -1;
    }
    throw error;
  }
};

export const fetchStatusForTableruleApi = async (tablerule_id, execution_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/table-rule/get-table-rule-status/${tablerule_id}/${execution_id}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    } else {
      return -1;
    }
    throw error;
  }
};

export const executeWholeRules = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(
      `${BASEURL}/rule-engine/execute-rule-engine/${data.batch}/${data.batch_id}/${data.client_id}/${data.client_name}/${data.execution_id}`,
      { headers: { Authorization: `Bearer ${parseToken}` } }
    );
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const UpdateRuleEngine = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.put(`${BASEURL}/rule-engine/update-rule-engine`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const createRuleEngineNew = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/rule-engine/create-rule-engine`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const createMetadata = async (client_id, metadata) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/rule-engine/create-rule-engine-metadata`, metadata, {
      params: { client_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const storeTableRuleJson = async (workflow_id, data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/rule-engine/rule-engine-json`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { workflow_id },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const executeRuleEngine = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/rule-engine/execute-rule-engine`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getRuleEngineStatus = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/table-rule/get-status`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchTableRuleById = async (tableId) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/table-rule/get-rule-by-id/${tableId}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
export const fetchTableRule = async (client_id, batch_name, tableName) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/table-rule/getRules/${client_id}/${batch_name}/${tableName}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
export const fetchTableRuleNew = async (workflow_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/rule-engine/get-rule-engine`, {
      params: { workflow_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchSingleTableRuleNew = async (workflow_id, filename) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/rule-engine/get-rule-engine-by-filename`, {
      params: { workflow_id, filename },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getRuleEngineListApi = async (workflow_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/rule-engine/get-rule-engine-list`, {
      params: { workflow_id },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const deleteRuleEngine = async (workflow_id, filename, aws_config_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.delete(`${BASEURL}/rule-engine/delete-rule-engine`, {
      params: { workflow_id, filename, aws_config_type },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const listS3 = async (client_id, prefix) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/table-rule/get-list-s3/${client_id}`, {
      params: { prefix },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const fetchRuleTableByBatchName = async (clientId, batchName) => {
  try {
    let result = null;
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    const response = await axios.get(`${BASEURL}/table-rule/get-table-rule/${clientId}/${batchName}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (response.status === 200) {
      result = response.data[0];
    }

    return result;
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const storeTableRealTimeRuleJson = async (clientId, streamName, tablename, data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/table-rule/table-real-rule-json/${clientId}/${streamName}/${tablename}`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const deleteTableRule = async (clientId, batchname, tablename) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.delete(`${BASEURL}/table-rule/delete-table-rule/${clientId}/${batchname}/${tablename}`, {
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const AllFoldersOfS3 = async (client_id, engagement_name, workflow_name, aws_config_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/rule-engine/get-folder-list`, {
      params: {
        client_id,
        engagement_name,
        workflow_name,
        aws_config_type,
      },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const AllFilesOfS3 = async (client_id, prefix, aws_config_type) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/rule-engine/get-files-list`, {
      params: { prefix, client_id, aws_config_type },
      headers: { Authorization: `Bearer ${parseToken}` },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }
    throw error;
  }
};

export const getJsonData = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/rule-engine/get-json-data`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { path: data.path, workflow_id: data.workflow_id },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getRuleEngineList = async (workflow_id) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.get(`${BASEURL}/rule-engine/get-rule-engine-list`, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { workflow_id },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};

export const getTxtData = async (data) => {
  try {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));
    return await axios.post(`${BASEURL}/rule-engine/get-txt-file-data`, data, {
      headers: { Authorization: `Bearer ${parseToken}` },
      params: { client_id: data.client_id, path: data.path, aws_config_type: data.aws_config_type },
    });
  } catch (error) {
    if (error.response.status === 401) {
      await logoutApi();
    }

    throw error;
  }
};
