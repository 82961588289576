import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import CloseIcon from '@mui/icons-material/Close';

const Feedback = ({ openModal, handleModal, type, comment, handleComment, handleUpdateVote, answerId }) => {
  const commentPlaceholder =
    type === 'liked'
      ? 'What do you like about the response?'
      : 'What was the issue with the response? How could it be improved?';

  return (
    <Dialog
      open={openModal}
      onClose={() => handleModal(type)}
      aria-labelledby="feedback-dialog-title"
      aria-describedby="feedback-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="feedback-dialog-title">
        <Stack direction="row" alignItems="center" justifyContent="start" sx={{ gap: '10px' }}>
          <IconButton aria-label={type} size="small" sx={{ background: type === 'liked' ? '#D9FCCA' : '#FCE1D6', mr:1 }}>
            {type === 'liked' ? (
              <ThumbUpOffAltIcon fontSize="large" sx={{ color: '#1E8449' }} />
            ) : (
              <ThumbDownOffAltIcon fontSize="large" sx={{ color: '#A93226' }} />
            )}
          </IconButton>
          Provide additional feedback
          {handleModal ? (
            <IconButton
              aria-label="close"
              onClick={() => handleModal(type)}
              color="inherit"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TextField
          value={comment}
          onChange={handleComment}
          placeholder={commentPlaceholder}
          multiline
          autoFocus
          margin="dense"
          id="comment"
          type="text"
          fullWidth
          variant="outlined"
          rows={4}
        />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 2 }}>
        <Button
          variant="outlined"
          onClick={() => handleUpdateVote(type, answerId)}
          autoFocus
          sx={{ textTransform: 'capitalize', color: '#424949', borderColor: '#D5D8DC' }}
        >
          Submit feedback
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Feedback;
