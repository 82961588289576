import axios from "axios";
import BASEURL from "./BaseUrl";



const getChatgptUrl = async () => {
    const parseToken = JSON.parse(sessionStorage.getItem('jwtToken'));

    const res1 = await axios.get(`${BASEURL}/openai/get-openai-url`, {
        headers: { Authorization: `Bearer ${parseToken}` },
    });
    if (res1.status === 200) {
        return res1.data.query_url
    }
}
export const CHATGPT_URL = getChatgptUrl();
