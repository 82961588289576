import React from 'react';
import Plot from 'react-plotly.js';
import { Stack } from '@mui/material';

const Insights = ({ sourceData, goldenRecords, badRecordsCount }) => {
  const plotSourceData = sourceData.map((item) => ({
    x: ['Sources'],
    y: [item.count],
    name: item.source,
    type: 'bar',
    width: 0.2,
    marker: {
      color: item.color,
    },
  }));

  const highestSrcSaleAmt = goldenRecords.golden_records.sort((a, b) => b.src_sale_amt - a.src_sale_amt).slice(0, 10);

  const extractMonthYear = (date) => {
    const [day, month, year] = date.split('-').map(Number);
    return { month, year };
  };

  const generatePlotData = (data) => {
    return data.reduce((plotData, entry) => {
      const { src_sale_date, src_sale_amt } = entry;
      const { month, year } = extractMonthYear(src_sale_date);

      plotData[year] = plotData[year] || {};
      plotData[year][month] = (plotData[year][month] || 0) + parseFloat(src_sale_amt);

      return plotData;
    }, {});
  };

  const generatePlot = (plotData) => {
    const years = Object.keys(plotData);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);

    return years.map((year) => {
      const values = months.map((month) => plotData[year]?.[month] || 0);

      return {
        x: months.map((month) => `${month}/${year}`),
        y: values,
        type: 'bar',
        name: year,
      };
    });
  };

  const plotData1 = generatePlotData(highestSrcSaleAmt);
  const plotTraces = generatePlot(plotData1);

  const postalCodes = highestSrcSaleAmt.map((entry) => entry.cust_postalcd);
  const saleAmounts = highestSrcSaleAmt.map((entry) => parseFloat(entry.src_sale_amt));

  return (
    <Stack direction="row" flexWrap="wrap">
      <Plot
        data={[
          {
            x: ['Golden records', 'Records that failed validation'],
            y: [goldenRecords.total_objects, badRecordsCount],
            width: 0.5,
            type: 'bar',
          },
        ]}
        layout={{
          width: 440,
          height: 350,
          title: { text: 'Overview', font: { size: 14 } },
          barmode: 'stack',
          yaxis: {
            title: { text: 'No. Of Records', font: { size: 12 } },
          },
          // xaxis: { fixedrange: true },
          // yaxis: { fixedrange: true },
        }}
        config={{ displayModeBar: false }}
      />
      <Plot
        data={plotSourceData}
        layout={{
          width: 400,
          height: 350,
          title: {
            text: 'Individuals by Source Type',
            font: {
              size: 14,
            },
          },

          barmode: 'stack',
        }}
        config={{ displayModeBar: false }}
      />

      <Plot
        data={[
          {
            x: postalCodes,
            y: saleAmounts,
            type: 'bar',
          },
        ]}
        layout={{
          width: 450,
          height: 350,
          title: { text: 'Highest Revenue By Postal Code', font: { size: 14 } },
          barmode: 'stack',
          xaxis: {
            tickangle: -45,
            autotypenumbers: 'strict',
            tickmode: 'array',
            tickvals: postalCodes,
            ticktext: postalCodes,
            title: {
              text: 'Customer Postal Code',
              font: { size: 12 },
            },
          },
          yaxis: {
            title: {
              text: 'Sales Amount',
              font: { size: 12 },
            },
          },
          bargap: 0.3,
          // xaxis: { fixedrange: true },
          // yaxis: { fixedrange: true },
        }}
        config={{ displayModeBar: false }}
      />

      <Plot
        data={plotTraces}
        layout={{
          width: 450,
          height: 350,
          title: { text: 'Sales by Month', font: { size: 14 } },
          xaxis: {
            tickangle: -45,
            title: { text: 'Month/Year', font: { size: 12 } },
          },
          yaxis: {
            title: {
              text: 'Total Sales Amount',
              font: {
                size: 12,
              },
            },
          },
        }}
        config={{ displayModeBar: false }}
      />
      <Plot
        data={[
          {
            values: [19, 26, 55, 20, 35],
            labels: ['Premium Customer', 'Average Customer', 'Age Group', 'High Buying'],
            type: 'pie',
          },
        ]}
        layout={{
          height: 400,
          width: 500,
          title: {
            text: 'Customer By Type',
            font: {
              size: 14,
            },
          },
        }}
        config={{ displayModeBar: false }}
      />
    </Stack>
  );
};

export default Insights;
